import { QUERY_KEY, MUTATION_KEY } from "constants/globalConstants";
import UserService from "apiService/permissions";
import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";

const useUpdatePermission = (setShowEditForm) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.UPDATE_PERMISSION],
    (data) => UserService.updatePermission(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.GET_PERMISSIONS);
        setShowEditForm(false)
        toast.success('Permissions updated successfully')
      },
      onError: (error) => {
        toast.error(error.response.data.message)
    },
    }
  );
};

export default useUpdatePermission