import React, { useState } from "react";
import {
  Box,
  Text,
  Flex,
  Tabs,
  TabList,
  Icon,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import Chart from "react-apexcharts";
import Card from "components/card/Card.js";
import { RiBarChartFill } from "react-icons/ri";
import { AiOutlineBars } from "react-icons/ai";
import EntityCardView from "./entityCardView";

const EntityPerformanceTrend = ({ entityId, entityName }) => {
  const colorCode = ["#26AFCD", "#763BC0", "#446FC3"];
  const colorDowntime = "#DC5D5D";
  const colorUptime = "#3DCA78";
  const colorSchedule = "#135CC9";
  const colorBlackout = "#7F7E95";
  const [toggleCardMode, setToggleCardMode] = useState(true);

  const handleToggleMode = () => {
    if (toggleCardMode) {
      setToggleCardMode(false);
    } else {
      setToggleCardMode(true);
    }
  };
  const barChartDataConsumption = [
    {
      name: "Downtime",
      data: [
        40, 47, 33, 0, 32, 35, 36, 32, 38, 40, 37, 33, 39, 32, 35, 36, 32, 38,
        39, 32, 35, 36, 32, 38, 39, 32, 35, 36, 32, 120,
      ],
    },
    {
      name: "Uptime",
      data: [
        40, 37, 33, 79, 32, 35, 36, 32, 38, 40, 37, 33, 39, 32, 35, 36, 32, 38,
        39, 32, 35, 36, 32, 38, 39, 32, 35, 36, 32, 0,
      ],
    },
    {
      name: "Schedlue Downtime",
      data: [
        40, 37, 33, 39, 32, 35, 36, 32, 38, 40, 37, 33, 39, 32, 35, 36, 32, 38,
        39, 32, 35, 36, 32, 38, 39, 32, 35, 36, 32, 0,
      ],
    },
    {
      name: "Blackout",
      data: [
        40, 37, 63, 39, 32, 35, 36, 32, 38, 40, 37, 33, 39, 32, 35, 36, 32, 38,
        39, 32, 35, 36, 32, 38, 39, 32, 35, 36, 32, 40,
      ],
    },
  ];

  const barChartOptionsConsumption = {
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
      onDatasetHover: {
        style: {
          fontSize: "12px",
          fontFamily: undefined,
        },
      },
      theme: "dark",
    },
    xaxis: {
      categories: [
        "1 May",
        "2 May",
        "3 May",
        "4 May",
        "5 May",
        "6 May",
        "7 May",
        "8 May",
        "9 May",
      ],
      show: true,
      labels: {
        show: true,
        style: {
          colors: "#A3AED0",
          fontSize: "10px",
          fontWeight: "500",
          width: "5px",
          wordWrap: "break-word",
        },
      },
      title: {
        text: "Days",
        style: {
          color: "#9EA2A9",
          fontSize: "13px",
        },
      },
    },
    yaxis: {
      show: true,
      categories: ["0", "10", "20", "30", "40", "50", "60"],
      color: "black",
      labels: {
        show: true,
        style: {
          colors: "#A3AED0",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      title: {
        text: "Percentage",
        style: {
          color: "#9EA2A9",
          fontSize: "13px",
        },
      },
    },

    grid: {
      borderColor: "rgba(163, 174, 208, 0.3)",
      show: true,
      yaxis: {
        lines: {
          show: false,
          opacity: 0.5,
        },
      },
      row: {
        opacity: 0.5,
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      type: "solid",
      colors: [colorDowntime, colorUptime, colorSchedule, colorBlackout],
    },
    legend: {
      show: true,
    },
    stroke: {
      colors: ["transparent"],
      width: 0,
    },
    colors: [colorDowntime, colorUptime, colorSchedule, colorBlackout],
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 0,
        columnWidth: "100%",
      },
    },
  };
  return (
    <Box mt="30px">
      <Flex justifyContent="space-between">
        <Text fontSize="26px" fontWeight="bold" color="ndmsColors.blue.100">
          Performance Trend
        </Text>
        <Flex>
          <Box
            bg={toggleCardMode ? "#4318FF" : "#EAEDF2"}
            width="38px"
            height="35px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius="6px"
            cursor="pointer"
            onClick={handleToggleMode}
            mx="10px"
          >
            <Icon
              as={AiOutlineBars}
              width="20px"
              height="20px"
              color={toggleCardMode ? "white" : "#9EA2A9"}
            />
          </Box>
          <Box
            bg={toggleCardMode ? "#EAEDF2" : "#4318FF"}
            width="38px"
            height="35px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            cursor="pointer"
            onClick={handleToggleMode}
            borderRadius="6px"
          >
            <Icon
              as={RiBarChartFill}
              width="20px"
              height="20px"
              color={toggleCardMode ? "#9EA2A9" : "white"}
            />
          </Box>
        </Flex>
      </Flex>
      <Tabs mt="30px">
        <TabList>
          <Tab
            _selected={{
              color: "ndmsColors.blue.100",
              boxShadow: "none",
              borderBottom: "1px solid #4318FF",
            }}
            color="#828282"
            fontSize="18px"
            fontWeight="bold"
          >
            <Flex align="center">Percentage Downtime</Flex>
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel px={0}>
            <EntityCardView
              colorCode={colorCode}
              toggleCardMode={toggleCardMode}
              entityId={entityId}
              entityName={entityName}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>

      {/* <Card mt="20px" pb="30px" height="320px" width="1000px">
        <Flex pt="10px">
          <Box
            cursor="pointer"
            px="20px"
            py="5px"
            border="1px solid #4318FF"
            borderRadius="6px"
            minWidth="160px"
          >
            <Text
              color="ndmsColors.purple.200"
              fontWeight="bold"
              fontSize="14px"
            >
              Percentage-wise
            </Text>
          </Box>
          <Box
            cursor="pointer"
            px="20px"
            py="5px"
            ml="10px"
            border={`1px solid #A0AEC0`}
            borderRadius="6px"
            minWidth="160px"
          >
            <Text
              color="ndmsColors.gray.200"
              fontWeight="bold"
              fontSize="14px"
              textAlign="center"
            >
              Hour-wise
            </Text>
          </Box>
        </Flex>
        <Chart
          options={barChartOptionsConsumption}
          series={barChartDataConsumption}
          type="bar"
          height="90%"
          width="100%"
        />
      </Card> */}
    </Box>
  );
};

export default EntityPerformanceTrend;
