import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Box,
  Flex,
  Text,
  InputGroup,
  InputRightElement,
  ModalCloseButton,
  useColorModeValue,
  Button,
} from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";
import useGenerateCsvReport from "hooks/slaReport/useGenerateCsvReport";
import dayjs from "dayjs";
import { useParams } from "react-router";
import EndDateFilter from "views/admin/slaReport/endDateFilter";
import { Input } from "@chakra-ui/input";
import useGenerateScheduleReport from "hooks/slaReport/useGenerateScheduleReport";
import DatePicker from "react-datepicker";

const HistoryDownloadModal = (props) => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const {
    isOpen,
    onClose,
    onOpen,
    tabType,
    finalRef,
    nodeName,
    nodeTypeId,
    nodeTypeName,
  } = props;

  const entityName = useParams()?.name;
  const entityId = useParams()?.id;
  const nodeId = useParams()?.nodeId;
  const [enabled, setEnabled] = useState(false);
  const buttonHover = useColorModeValue("brand.600", "brand.400");

  const handleModalClose = () => {
    onClose();
    window.close();
  };

  const {
    data: csvReportData,
    isLoading,
    refetch,
    isFetching,
  } = useGenerateCsvReport(
    enabled,
    entityId,
    entityName,
    nodeTypeId ? nodeTypeId : null,
    nodeTypeName ? nodeTypeName : "all",
    nodeId ? nodeId : null,
    nodeName ? nodeName : "all",
    dayjs(startDate).format("YYYY-MM-DD"),
    dayjs(endDate).format("YYYY-MM-DD"),
    true,
    true,
    true,
    true,
    handleModalClose,
    setEnabled
  );

  useEffect(() => {
    if (enabled) {
      refetch();
    }
  }, [enabled]);

  const handleCsvDownload = () => {
    setEnabled(true);
  };

  return (
    <Box borderRadius="3xl">
      <Modal
        isOpen={isOpen}
        onClose={handleModalClose}
        blockScrollOnMount={false}
        finalFocusRef={finalRef}
        size="sm"
        isCentered
      >
        <ModalOverlay />
        <ModalContent borderRadius="20px">
          <ModalHeader>
            <Flex justify="center" direction="column" mt="30px" width="100%">
              {/* <Flex mt="15px" flexDirection="column">
                <Text
                  color="ndmsColors.gray.2100"
                  fontSize="16px"
                  fontWeight="bold"
                  mr="10px"
                >
                  Entity
                </Text>
                <Input
                  fontSize="13px"
                  width="100%"
                  height="2.5rem"
                  id="user_type"
                  size="lg"
                  mr="10px"
                  color="#2B3674"
                  fontWeight="bold"
                  value={entityName}
                  placeholder={entityName}
                />
              </Flex> */}
              <Flex justify="center" direction="column" flexDirection="column">
                <Box mt="26px" mr="20px">
                  <Flex direction="column">
                    <Flex>
                      <Text
                        color="ndmsColors.gray.2100"
                        fontSize="14px"
                        fontWeight="bold"
                      >
                        Start Date
                      </Text>
                    </Flex>
                    <Flex alignItems="center">
                      <DatePicker
                        wrapperClassName="date-picker"
                        selected={startDate}
                        dateFormat="MMMM d, yyyy"
                        onChange={(date) => setStartDate(date)}
                        customInput={
                          <InputGroup>
                            <Input
                              minWidth="335px"
                              placeholder="From"
                              value={dayjs(startDate).format("DD/MM/YYYY")}
                            />
                            <InputRightElement
                              children={<CalendarIcon color="#4318FF" />}
                            />
                          </InputGroup>
                        }
                      />
                    </Flex>
                  </Flex>
                </Box>
                <Box mt="26px" mr="20px">
                  <Flex direction="column">
                    <Flex>
                      <Text
                        color="ndmsColors.gray.2100"
                        fontSize="14px"
                        fontWeight="bold"
                      >
                        End Date
                      </Text>
                    </Flex>
                    <Flex alignItems="center">
                      <DatePicker
                        selected={endDate}
                        dateFormat="MMMM d, yyyy"
                        onChange={(date) => setEndDate(date)}
                        customInput={
                          <InputGroup>
                            <Input
                              minWidth="335px"
                              placeholder="From"
                              value={dayjs(endDate).format("DD/MM/YYYY")}
                            />
                            <InputRightElement
                              children={<CalendarIcon color="#4318FF" />}
                            />
                          </InputGroup>
                        }
                      />
                    </Flex>
                  </Flex>
                </Box>
              </Flex>
              <Flex mt="20px">
                <Button
                  variant="brand"
                  color="white"
                  _hover={{ bg: isLoading ? "blue" : buttonHover }}
                  ml={1}
                  mt={2}
                  size="lg"
                  mb={10}
                  isLoading={isLoading || isFetching}
                  onClick={handleCsvDownload}
                >
                  <Text color={"white"}>Save</Text>
                </Button>
              </Flex>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default HistoryDownloadModal;
