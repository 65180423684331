import request from "utils";

const getEntityClientList = async (id, pageSize, currentPage) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/get-clientapp?entity_id=${id}&page_size=${pageSize}&page=${currentPage}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const addEntityClient = async (apiData) => {
  const { data } = await request.post(
    `${process.env.REACT_APP_BASE_URL}/add-clientapp/`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const editEntityClient = async (apiData) => {
  const { data } = await request.put(
    `${process.env.REACT_APP_BASE_URL}/update-clientapp/`,
    apiData,
    { headers: { Authorization: localStorage.getItem("token") } }
  );
  return data;
};

const deleteEntityClient = async (id) => {
  const { data } = await request.delete(
    `${process.env.REACT_APP_BASE_URL}/delete-clientapp/${id}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const onBoardClient = async (apiData) => {
  const { data } = await request.put(
    `${process.env.REACT_APP_BASE_URL}/onboard_client`,
    apiData,
    { headers: { Authorization: localStorage.getItem("token") } }
  );
  return data;
};

const createMasterConfig = async (apiData) => {
  const { data } = await request.post(
    `${process.env.REACT_APP_BASE_URL}/create-config`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const createClientConfig = async (apiData) => {
  const { data } = await request.post(
    `${process.env.REACT_APP_BASE_URL}/create-config-store`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getMasterConfigList = async () => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/view-config`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getClientConfigList = async () => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/retrive-config`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const editMasterConfig = async (apiData) => {
  const { data } = await request.put(
    `${process.env.REACT_APP_BASE_URL}/update-config`,
    apiData,
    { headers: { Authorization: localStorage.getItem("token") } }
  );
  return data;
};

const editClientConfig = async (apiData) => {
  const { data } = await request.put(
    `${process.env.REACT_APP_BASE_URL}/update-config-store`,
    apiData,
    { headers: { Authorization: localStorage.getItem("token") } }
  );
  return data;
};

const deleteMasterConfig = async (id) => {
  const { data } = await request.delete(
    `${process.env.REACT_APP_BASE_URL}/delete-config/${id}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const deleteClientConfig = async (id) => {
  const { data } = await request.delete(
    `${process.env.REACT_APP_BASE_URL}/delete-config-store/${id}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const downloadInstallClient = async () => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/url_for_install_script`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const checkLicense = async (apiData) => {
  const { data } = await request.post(
    `${process.env.REACT_APP_BASE_URL}/check_license`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const CrudApiService = {
  getEntityClientList,
  addEntityClient,
  editEntityClient,
  deleteEntityClient,
  createMasterConfig,
  getMasterConfigList,
  getClientConfigList,
  createClientConfig,
  editClientConfig,
  deleteClientConfig,
  onBoardClient,
  editMasterConfig,
  deleteMasterConfig,
  downloadInstallClient,
  checkLicense
};

export default CrudApiService;
