import React from "react";
import Chart from "react-apexcharts";
import Card from "components/card/Card.js";

const MyLineChart = () => {
  const lineChartDataTotalSpent = [
    {
      data: [
        [1, 1],
        [2, 4],
        [3, 1],
        [4, 3],
        [5, 4],
        [6, 1],
      ],
      type: "line",
    },
    {
      data: [
        [1, 3],
        [2, 5],
        [3, 2],
        [4, 1],
        [5, 3],
        [6, 2],
      ],
      type: "line",
    },
    {
        data: [
          [1, 4],
          [2, 9],
          [3, 8],
          [4, 9],
          [5, 8],
          [6, 7],
        ],
        type: "line",
      },
      {
        data: [
          [1, 9],
          [2, 15],
          [3, 20],
          [4, 10],
          [5, 3],
          [6, 2],
        ],
        type: "line",
      },
    
  ];

  const lineChartOptionsTotalSpent = {
    chart: {
      id: "1",
      animations: {
        enabled: false,
      },
      stacked: false,
      zoom: {
        type: "x",
        enabled: true,
      },
      toolbar: {
        autoSelected: "zoom",
        show: false,
      },
      background: "#fff",
    },
    annotations: {
      points: [],
    },
    fill: {
      type: "solid",
      opacity: [0.35, 1],
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    xaxis: {
      categories: [0, 1, 2, 3, 4, 5, 6, 7],
    },
    yaxis: [
      {
        axisTicks: { show: true },
        axisBorder: { show: true },
        labels: {
          show: true,
          //minWidth: 140
        },
        tooltip: { enabled: false },
      },
    ],
    stroke: {
      width: 1.5,
    },
    legend: {
      show: false,
    },
    tooltip: {
      shared: true,
      x: {
        format: "dd MMM - HH : mm ",
      },
    },
  };

  return (
    <Card
      p="20px"
      align="center"
      height="max-content"
      direction="column"
      w="max-content"
      ml="20px"
    >
      <Chart
        options={lineChartOptionsTotalSpent}
        series={lineChartDataTotalSpent}
        type="line"
        height="180px"
        width="300px"
      />
    </Card>
  );
};

export default MyLineChart;
