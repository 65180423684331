import React from "react";
import { useDrag } from "react-dnd";
import { Text } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import NodeForm from "./nodeForm";

const NodeZoomCard = ({
  id,
  name,
  index,
  groupId,
  style,
  setShowZoom,
  draggedObject,
  savedObject,
  setDraggedObject,
  setSavedObject,
}) => {
  const [{ isDragging }, dragRef] = useDrag({
    type: "card",
    item: { id, name },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  return (
    <div ref={dragRef} style={style}>
      <Card
        borderWidth="1px"
        borderRadius="lg"
        draggable
        style={{
          width: "200px",
          boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
          display: "flex",
          //alignItems: "center",
          //marginRight: "15px",
          zIndex: 2,
        }}
      >
        <Text fontWeight="bold" fontSize="14px">
          {name}
        </Text>
        <NodeForm
          draggedObject={draggedObject}
          index={index}
          nodeTypeId={id}
          groupId={groupId}
          savedObject={savedObject}
          setShowZoom={setShowZoom}
          setDraggedObject={setDraggedObject}
          setSavedObject={setSavedObject}
        />
      </Card>
    </div>
  );
};

export default NodeZoomCard;
