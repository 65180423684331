import { QUERY_KEY } from "constants/globalConstants";
import ApiService from "apiService";
import { useQuery } from "react-query";
import toast from "react-hot-toast";

const useExportNodes = (enabled, entityId, setEnabled) => {
  return useQuery(
    [QUERY_KEY.EXPORT_NODE],
    () => ApiService.exportNodes(entityId),
    {
      retry: false,
      enabled: enabled,
      onSuccess: (data) => {
        const tempLink = document.createElement("a");
        tempLink.href = data.data;
        enabled && tempLink.click();
        enabled && toast.success("file exported successfully");
        setEnabled(false);
      },
      onError: (error) => {
        toast.error(error.response.data.message);
        setEnabled(false);
      },
    }
  );
};

export default useExportNodes;
