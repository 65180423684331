import { Box, Flex, Spinner, Skeleton, Text } from "@chakra-ui/react";
import Card from "components/card/Card.js";

const ApproveDowntimeLoader = ({ show }) => {
  return (
    <Box pt="30px">
      <Flex justifyContent="space-between" mb="40px">
        <Flex align="center">
          <Text
            fontSize={"16px"}
            //mb="5px"
            color="#707EAE"
          >
            Status :
          </Text>
          <Skeleton ml="10px" width="200px" height="40px" />
          <Text fontSize={"16px"} ml="15px" color="#707EAE">
            Entity :
          </Text>
          <Skeleton ml="10px" width="200px" height="40px" />
          {show ? (
            <>
              <Text fontSize={"16px"} ml="15px" color="#707EAE">
                Node Type :
              </Text>
              <Skeleton ml="10px" width="200px" height="40px" />
            </>
          ) : null}
        </Flex>
      </Flex>
      <Card w="100%" h="300px" px="0px">
        <Flex direction="column" mt="80px" align="center" justify="center">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Flex>
      </Card>
    </Box>
  );
};

export default ApproveDowntimeLoader;
