import { QUERY_KEY, MUTATION_KEY } from "constants/globalConstants";
import ApiService from "apiService";
import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";

const useImageUpload = (setSelectedUrl, setFileId, name, handleModalClose) => {
  //const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.UPLOAD_IMAGE],
    (data) => ApiService.uploadImage(data, name),
    {
      onSuccess: (data) => {
        //queryClient.invalidateQueries(QUERY_KEY.GET_CLIENT_LIST);
        setSelectedUrl(data.data.file_url)
        setFileId(data.data.id)
        handleModalClose()
        toast.success('Image uploaded successfully')
      },
      onError: (error) => {
        toast.error(error.response.data.message)
    },
    }
  );
};

export default useImageUpload