import React from "react";
import Chart from "react-apexcharts";

const EmptyPieChart = ({ height }) => {
  const Options = {
    labels: ["No data"],
    colors: ["#E5E5E5"],
    chart: {
      width: "150px",
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    hover: { mode: null },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: false,
            total: {
              show: false,
              label: "Total nodes",
              color: "navy blue",
            },
          },
        },
      },
    },
    series: [10],
    fill: {
      colors: ["#E5E5E5"],
    },
    tooltip: {
      enabled: false,
      theme: "dark",
    },
  };

  const Data = [10];
  return (
    <Chart
      height={height ? height : "220px"}
      w="100%"
      type="donut"
      series={Data}
      options={Options}
    />
  );
};

export default EmptyPieChart;
