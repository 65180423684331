import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Flex,
  Divider,
  Icon,
  Button,
  Skeleton,
  useDisclosure,
  useColorModeValue,
} from "@chakra-ui/react";
import AddTaskModal from "components/modal/addTaskModal";
import Card from "components/card/Card.js";
import "./tms.css";
import { AiOutlineExclamationCircle } from "react-icons/ai";
import { BsCheckCircleFill } from "react-icons/bs";
import { MdCancel } from "react-icons/md";
import TicketModuleHooks from "hooks/ticketModule/ticketModuleHooks";
import EmptyCard from "components/card/EmptyCard";
import dayjs from "dayjs";
import { getDateAndTime, getDate } from "Permissions";
import TmsConfirmationModal from "components/modal/tmsConfirmationModal";

const TmsFollowup = ({ ticketId, isClosed }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const buttonHover = useColorModeValue("brand.600", "brand.400");
  const [taskId, setTaskId] = useState(null);
  const {
    isOpen: isConfOpen,
    onOpen: onConfOpen,
    onClose: onConfClose,
  } = useDisclosure();

  const {
    isOpen: isCancOpen,
    onOpen: onCancOpen,
    onClose: onCancClose,
  } = useDisclosure();

  const {
    data: taskData,
    isLoading: isTaskLoading,
    taskError,
    refetch,
    isFetching: isTaskFetching,
  } = TicketModuleHooks.useTicketTasks(ticketId);
  const { mutate: completeTask, isLoading: isCompLoading } =
    TicketModuleHooks.useTaskComplete(ticketId, onConfClose);
  const { mutate: cancelTask, isLoading: isCancLoading } =
    TicketModuleHooks.useTaskCancel(ticketId, onCancClose);

  useEffect(() => {
    refetch();
  }, [ticketId]);

  const onCompleteConf = () => {
    completeTask(taskId);
  };
  const onCancelConf = () => {
    cancelTask(taskId);
  };

  const onComplete = (id) => {
    setTaskId(id);
    onConfOpen();
  };
  const onCancel = (id) => {
    setTaskId(id);
    onCancOpen();
  };

  return (
    <Box mt="5px">
      <Flex justifyContent="space-between" alignItems="center">
        <Text fontSize="16px" fontWeight="bold">
          Tasks
        </Text>
        {isClosed ? null : (
          <>
            <Button
              onClick={() => onOpen()}
              variant="brand"
              size="xs"
              borderRadius="6px"
            >
              + Add Task
            </Button>
          </>
        )}
      </Flex>
      <Card
        border="1px solid #EAEDF2"
        mt="20px"
        maxHeight="500px"
        overflowY="auto"
        borderRadius="10px"
        mb="30px"
      >
        {isTaskLoading || isTaskFetching ? (
          <Skeleton width="100%" height="500px" />
        ) : taskData?.data?.length > 0 ? (
          <>
            {taskData?.data?.map((task, index) => (
              <>
                {index === 0 ? null : <Divider my="20px" />}
                <Box>
                  <Flex alignItems="center">
                    {task?.status === 1 ? (
                      <Icon
                        as={BsCheckCircleFill}
                        width="24px"
                        height="24px"
                        color="green"
                        pr="5px"
                      />
                    ) : null}
                    {task?.status === -1 ? (
                      <Icon
                        as={MdCancel}
                        width="26px"
                        height="26px"
                        color="#E04F5F"
                        pr="5px"
                      />
                    ) : null}
                    <Text fontWeight="600" fontSize="16px">
                      {task?.title}
                    </Text>
                  </Flex>
                  {task?.status === 0 && task?.due_since ? (
                    <Box
                      bg="#FFECF0"
                      mt="5px"
                      py="10px"
                      pl="10px"
                      borderRadius="6px"
                    >
                      <Flex alignItems="center">
                        <Icon
                          as={AiOutlineExclamationCircle}
                          width="20px"
                          height="15px"
                          color="#F56185"
                        />

                        <Text
                          fontWeight="600"
                          pl="5px"
                          color="#F56185"
                          fontSize="14px"
                        >
                          {`Overdue since ${task?.due_since} ${
                            task?.due_since === 1 ? "day" : "days"
                          } ago`}
                        </Text>
                      </Flex>
                    </Box>
                  ) : null}
                  <Box pt="10px">
                    <Text
                      fontWeight="500"
                      color="ndmsColors.gray.1900"
                      fontSize="12px"
                    >
                      Scheduled at
                    </Text>
                    <Text fontWeight="500" fontSize="14px">
                      {getDate(task?.scheduled_at)}
                    </Text>
                  </Box>
                  <Box pt="10px">
                    <Text
                      fontWeight="500"
                      color="ndmsColors.gray.1900"
                      fontSize="12px"
                    >
                      Description
                    </Text>
                    <Text fontSize="12px">{task?.description}</Text>
                  </Box>
                  <Box pt="10px">
                    <Text
                      color="ndmsColors.gray.2200"
                      fontWeight="500"
                      fontSize="12px"
                    >
                      Created by:
                    </Text>
                    <Flex>
                      <Text fontSize="14px" fontWeight="500">
                        {task?.created_by?.user_fname}{" "}
                        {task?.created_by?.user_lname}
                      </Text>
                      <Text
                        fontSize="14px"
                        ml="10px"
                        fontWeight="500"
                        color="ndmsColors.gray.2200"
                      >
                        ({task?.created_by?.user_role})
                      </Text>
                    </Flex>
                    <Text
                      fontSize="12px"
                      fontWeight="500"
                      color="ndmsColors.gray.2200"
                    >
                      {getDateAndTime(task?.created_at)}
                    </Text>
                  </Box>
                  {task?.status === 0 ? null : (
                    <Box pt="10px">
                      <Text
                        color="ndmsColors.gray.2200"
                        fontWeight="500"
                        fontSize="12px"
                      >
                        {task?.status === 1 ? "Completed by" : "Cancelled by"}
                      </Text>
                      <Flex>
                        <Text fontSize="14px" fontWeight="500">
                          {task?.status_updated_by?.user_fname}{" "}
                          {task?.status_updated_by?.user_lname}
                        </Text>
                        <Text
                          fontSize="14px"
                          ml="10px"
                          fontWeight="500"
                          color="ndmsColors.gray.2200"
                        >
                          ({task?.status_updated_by?.user_role})
                        </Text>
                      </Flex>
                      <Text
                        fontSize="12px"
                        fontWeight="500"
                        color="ndmsColors.gray.2200"
                      >
                        {getDateAndTime(task?.status_updated_at)}
                      </Text>
                    </Box>
                  )}
                  {task?.status === 0 ? (
                    <Flex pt="15px">
                      <Button
                        variant="brand"
                        size="sm"
                        borderRadius="6px"
                        isLoading={isCompLoading}
                        _hover={{ bg: isCompLoading ? "blue" : buttonHover }}
                        onClick={() => onComplete(task.id)}
                      >
                        Mark as complete
                      </Button>
                      <Button
                        color="#4F46BA"
                        borderRadius="6px"
                        borderColor="#4F46BA"
                        bg="white"
                        size="sm"
                        ml="10px"
                        isLoading={isCancLoading}
                        variant="outline"
                        onClick={() => onCancel(task.id)}
                      >
                        Cancel
                      </Button>
                    </Flex>
                  ) : null}
                </Box>
              </>
            ))}
          </>
        ) : (
          <Flex height="250px" justifyContent="center">
            <EmptyCard label="No task added" font="16px" size="40px" />
          </Flex>
        )}
      </Card>
      <AddTaskModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        ticketId={ticketId}
      />
      <TmsConfirmationModal
        isOpen={isConfOpen}
        onOpen={onConfOpen}
        onClose={onConfClose}
        onConfirmation={onCompleteConf}
        isLoading={isCompLoading}
        text={`Are you sure you want to mark this task as complete?`}
      />
      <TmsConfirmationModal
        isOpen={isCancOpen}
        onOpen={onCancOpen}
        onClose={onCancClose}
        onConfirmation={onCancelConf}
        isLoading={isCancLoading}
        text={`Are you sure you want to mark this task as cancelled?`}
      />
    </Box>
  );
};

export default TmsFollowup;
