import React, { useState } from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Heading,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import Card from "components/card/Card.js";
import ConfirmPassword from "./confirmPassword";
// Custom components
import DefaultAuth from "layouts/auth/Default";
// Assets
import illustration from "assets/img/auth/auth.png";
import useForgotPassword from "hooks/useForgotPassword";

function ForgotPassword() {
  const mainBranding = localStorage.getItem("main_branding");
  // Chakra color mode

  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [email, setEmail] = useState("");
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const buttonHover = useColorModeValue("brand.600", "brand.400");
  const { mutate: forgotPassword, isLoading } = useForgotPassword(
    setShowConfirmPassword
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    forgotPassword({ email: data.email });
    setEmail(data.email);
  };

  return (
    <Box
      bg="#2B3674"
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="relative"
    >
      <Flex direction="column" alignItems="center">
        <Text fontSize="45px" color="white" fontWeight="bold">
          {mainBranding}
        </Text>
        <Text fontSize="20px" pb="20px" color="white" fontWeight="300">
          Network Device Monitoring System
        </Text>
        <Card
          width="450px"
          height="max-content"
          borderRadius="none"
          boxShadow="10px"
        >
          {showConfirmPassword ? (
            <ConfirmPassword email={email} />
          ) : (
            <Flex
              maxW={{ base: "100%", md: "max-content" }}
              w="100%"
              mx={{ base: "auto", lg: "0px" }}
              me="auto"
              h="100%"
              alignItems="start"
              justifyContent="center"
              mb={{ base: "30px", md: "60px" }}
              px={{ base: "25px", md: "0px" }}
              mt={{ base: "10px", md: "20px" }}
              flexDirection="column"
            >
              <Box me="auto">
                <Heading color={textColor} fontSize="36px" mb="10px">
                  Password Assist
                </Heading>
                <Text
                  mb="36px"
                  ms="4px"
                  color={textColorSecondary}
                  fontWeight="400"
                  fontSize="md"
                >
                  Enter your registered email address to reset password
                </Text>
              </Box>
              <Flex
                zIndex="2"
                direction="column"
                w={{ base: "100%", md: "420px" }}
                maxW="100%"
                background="transparent"
                borderRadius="15px"
                mx={{ base: "auto", lg: "unset" }}
                me="auto"
                mb={{ base: "20px", md: "auto" }}
              >
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Box mb="14px">
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                    >
                      Email<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <Controller
                      name="email"
                      rules={{ required: true }}
                      control={control}
                      render={({ field }) => (
                        <Input
                          variant="auth"
                          fontSize="sm"
                          ms={{ base: "0px", md: "0px" }}
                          type="email"
                          placeholder="mail@simmmple.com"
                          mb="10px"
                          fontWeight="500"
                          size="lg"
                          {...field}
                        />
                      )}
                    />
                    {errors.email?.type === "required" && (
                      <span style={{ color: "red" }}>email is required</span>
                    )}
                  </Box>
                  <Button
                    fontSize="sm"
                    variant="brand"
                    fontWeight="500"
                    w="100%"
                    h="50"
                    mb="10px"
                    //mt="10px"
                    type="submit"
                    _hover={{ bg: isLoading ? "blue" : buttonHover }}
                    isLoading={isLoading}
                  >
                    Submit
                  </Button>
                </form>
                <Flex
                  flexDirection="column"
                  justifyContent="center"
                  alignItems="start"
                  maxW="100%"
                  mt="0px"
                >
                  <Text
                    color={textColorDetails}
                    fontWeight="400"
                    fontSize="14px"
                  >
                    Remember your password?
                    <NavLink to="/auth/sign-up">
                      <Text
                        color={textColorBrand}
                        as="span"
                        ms="5px"
                        fontWeight="500"
                      >
                        Log in
                      </Text>
                    </NavLink>
                  </Text>
                </Flex>
              </Flex>
            </Flex>
          )}
        </Card>
      </Flex>
    </Box>
  );
}

export default ForgotPassword;
