import React, { useState, useEffect } from "react";
import {
  Text,
  Flex,
  InputGroup,
  Input,
  InputRightElement,
  Button,
  Skeleton,
  Select,
  useColorModeValue,
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { CalendarIcon } from "@chakra-ui/icons";
import Card from "components/card/Card.js";
import { getDate, getApiDate } from "Permissions";
import TicketModuleHooks from "hooks/ticketModule/ticketModuleHooks";

const TmsFilters = ({ data, onApply, otherString, setOtherString }) => {
  const {
    isLoading: isTypeLoading,
    error: typeError,
    data: typeData,
    refetch: typeRefetch,
  } = TicketModuleHooks.useTypeList();

  const {
    isLoading: isCreatedLoading,
    error: createdError,
    data: createdData,
    refetch: createdRefetch,
  } = TicketModuleHooks.useTicketCreatedByList();

  useEffect(() => {
    typeRefetch();
    createdRefetch();
  }, [window.location.pathname]);

  const [type, setType] = useState(null);
  const [assign, setAssign] = useState(null);
  const [createdBy, setCreatedBy] = useState(null);
  const [priority, setPriority] = useState(null);
  const [createdDate, setCreatedDate] = useState(null);
  const [followUpDate, setFollowUpDate] = useState(null);
  const buttonHover = useColorModeValue("brand.600", "brand.400");

  const generateSelectOptions = () => {
    return data?.data?.map((data) => {
      return (
        <option
          value={data.id}
        >{`${data.user_fname} ${data.user_lname}`}</option>
      );
    });
  };

  const generateCreatedOptions = () => {
    return createdData?.data?.map((data) => {
      return (
        <option
          value={data.id}
        >{`${data.user_fname} ${data.user_lname}`}</option>
      );
    });
  };

  const generateTypeOptions = () => {
    return typeData?.data?.map((data) => {
      return <option value={data.slug}>{data.name} </option>;
    });
  };

  const handlePriorityChange = (e) => {
    setPriority(e.target.value);
    if (otherString.includes("&ticket_priority")) {
      setOtherString(
        otherString
          .replace(`&ticket_priority=${priority}`, "")
          .concat(`&ticket_priority=${e.target.value}`)
      );
    } else {
      setOtherString(otherString.concat(`&ticket_priority=${e.target.value}`));
    }
  };

  const handleTypeChange = (e) => {
    setType(e.target.value);
    if (otherString.includes("&ticket_type")) {
      setOtherString(
        otherString
          .replace(`&ticket_type=${type}`, "")
          .concat(`&ticket_type=${e.target.value}`)
      );
    } else {
      setOtherString(otherString.concat(`&ticket_type=${e.target.value}`));
    }
  };

  const handleCreateDate = (date) => {
    setCreatedDate(date);
    if (otherString.includes("&created_on")) {
      setOtherString(
        otherString
          .replace(`&created_on=${getApiDate(createdDate)}`, "")
          .concat(`&created_on=${getApiDate(date)}`)
      );
    } else {
      setOtherString(otherString.concat(`&created_on=${getApiDate(date)}`));
    }
  };

  const handleFollowUpDate = (date) => {
    setFollowUpDate(date);
    if (otherString.includes("&scheduled_at")) {
      setOtherString(
        otherString
          .replace(`&scheduled_at=${getApiDate(followUpDate)}`, "")
          .concat(`&scheduled_at=${getApiDate(date)}`)
      );
    } else {
      setOtherString(otherString.concat(`&scheduled_at=${getApiDate(date)}`));
    }
  };

  const handleAssignChange = (e) => {
    setAssign(e.target.value);
    if (otherString.includes("&assigned_to")) {
      setOtherString(
        otherString
          .replace(`&assigned_to=${assign}`, "")
          .concat(`&assigned_to=${e.target.value}`)
      );
    } else {
      setOtherString(otherString.concat(`&assigned_to=${e.target.value}`));
    }
  };

  const handleCreatedChange = (e) => {
    setCreatedBy(e.target.value);
    if (otherString.includes("&created_by")) {
      setOtherString(
        otherString
          .replace(`&created_by=${createdBy}`, "")
          .concat(`&created_by=${e.target.value}`)
      );
    } else {
      setOtherString(otherString.concat(`&created_by=${e.target.value}`));
    }
  };

  const onClearAll = () => {
    setOtherString("");
    setPriority("Choose Priority");
    setType("Choose Type");
    setCreatedBy("Choose Agent");
    setCreatedDate(null);
    setFollowUpDate(null);
    setAssign("Choose Agent");
    onApply();
  };

  return (
    <Card
      border="1px solid #EAEDF2"
      borderRadius="10px"
      height="max-content"
      width="max-content"
    >
      <Text
        fontSize="18px"
        fontWeight={type === "Choose Type" || type === null ? "bold" : "normal"}
      >
        Filters
      </Text>
      <Flex direction="column" mt="15px">
        <Text fontSize="14px" fontWeight="500">
          Ticket type
        </Text>
        <Select
          bg="white"
          fontSize="14px"
          //mt="20px"
          minWidth="240px"
          height="40px"
          id="user_type"
          size="lg"
          color={
            type === "Choose Type" || type === null
              ? "ndmsColors.gray.2200"
              : "black"
          }
          fontWeight={
            type === "Choose Type" || type === null ? "bold" : "normal"
          }
          mt="5px"
          //placeholder="Choose Type"
          value={type}
          onChange={handleTypeChange}
        >
          <option selected disabled hidden value={null}>
            Choose Type
          </option>
          {generateTypeOptions()}
        </Select>
      </Flex>
      <Flex direction="column" mt="10px">
        <Text fontSize="14px" fontWeight="500">
          Priority
        </Text>
        <Select
          bg="white"
          fontSize="14px"
          //mt="20px"
          minWidth="240px"
          height="40px"
          id="user_type"
          size="lg"
          color={
            priority === "Choose Priority" || priority === null
              ? "ndmsColors.gray.2200"
              : "black"
          }
          fontWeight={
            type === "Choose Priority" || type === null ? "bold" : "normal"
          }
          mt="5px"
          //placeholder="Choose Priority"
          value={priority}
          onChange={handlePriorityChange}
        >
          <option selected disabled hidden value={null}>
            Choose Priority
          </option>
          <option value={1}>Low</option>
          <option value={2}>Medium</option>
          <option value={3}>High</option>
        </Select>
      </Flex>

      <Flex direction="column" mt="10px">
        <Text fontSize="14px" fontWeight="500">
          Created by
        </Text>
        <Select
          bg="white"
          fontSize="14px"
          //mt="20px"
          minWidth="240px"
          height="40px"
          id="user_type"
          size="lg"
          color={
            createdBy === "Choose Agent" || createdBy === null
              ? "ndmsColors.gray.2200"
              : "black"
          }
          fontWeight={
            type === "Choose Agent" || type === null ? "bold" : "normal"
          }
          mt="5px"
          value={createdBy}
          onChange={handleCreatedChange}
        >
          <option selected disabled hidden value={null}>
            Choose Agent
          </option>
          {generateCreatedOptions()}
        </Select>
      </Flex>
      <Flex direction="column" mt="10px">
        <Text fontSize="14px" fontWeight="500">
          Created on
        </Text>
        <DatePicker
          wrapperClassName="date-picker"
          selected={createdDate}
          value={createdDate}
          onChange={handleCreateDate}
          customInput={
            <InputGroup minWidth="240px">
              <Input
                placeholder="Choose Date"
                fontWeight={
                  type === "Choose Date" || type === null ? "bold" : "normal"
                }
                value={getDate(createdDate)}
              />
              <InputRightElement zIndex={0} children={<CalendarIcon />} />
            </InputGroup>
          }
        />
      </Flex>
      <Flex direction="column" mt="10px">
        <Text fontSize="14px" fontWeight="500">
          Assigned to
        </Text>
        <Select
          bg="white"
          fontSize="15px"
          //mt="20px"
          minWidth="240px"
          height="40px"
          id="user_type"
          size="lg"
          mt="5px"
          color={
            assign === "Choose Agent" || assign === null
              ? "ndmsColors.gray.2200"
              : "black"
          }
          fontWeight={
            type === "Choose Agent" || type === null ? "bold" : "normal"
          }
          value={assign}
          onChange={handleAssignChange}
        >
          <option selected disabled hidden value={null}>
            Choose Agent
          </option>
          {generateSelectOptions()}
        </Select>
      </Flex>
      <Flex direction="column" mt="10px">
        <Text fontSize="14px" fontWeight="500">
          Tasks due on
        </Text>
        <DatePicker
          wrapperClassName="date-picker"
          selected={followUpDate}
          value={followUpDate}
          onChange={handleFollowUpDate}
          customInput={
            <InputGroup minWidth="240px">
              <Input
                placeholder="Choose Date"
                fontWeight={
                  type === "Choose Date" || type === null ? "bold" : "normal"
                }
                value={getDate(followUpDate)}
              />
              <InputRightElement zIndex={0} children={<CalendarIcon />} />
            </InputGroup>
          }
        />
      </Flex>
      <Flex direction="column" alignItems="center" mb="20px">
        <Button
          variant="brand"
          borderRadius="8px"
          mt="30px"
          width="100%"
          onClick={onApply}
          _hover={buttonHover}
          disabled={otherString?.length > 0 ? false : true}
        >
          Apply
        </Button>

        <Button
          variant="outline"
          width="70%"
          mt="10px"
          borderRadius="8px"
          colorScheme="#2B3674"
          onClick={onClearAll}
        >
          <Text color={"#2B3674"}>Clear All</Text>
        </Button>
      </Flex>
    </Card>
  );
};

export default TmsFilters;
