import { QUERY_KEY, MUTATION_KEY } from "constants/globalConstants";
import ApiService from "apiService";
import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";

const useDeleteClient = (onClose) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.DELETE_CLIENT],
    (id) => ApiService.deleteClient(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.GET_CLIENT_LIST);
        onClose();
        toast.success("Customer deleted successfully");
      },
      onError: (error) => {
        toast.error(error.response.data.Message);
      },
    }
  );
};

export default useDeleteClient;
