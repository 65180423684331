import { QUERY_KEY, MUTATION_KEY } from "constants/globalConstants";
import CrudApiService from "apiService/crudApiService";
import DashboardService from "apiService/dashboardService";
import { useMutation, useQueryClient, useQuery } from "react-query";
import { useHistory } from "react-router";
import toast from "react-hot-toast";

const useTicketUserList = () => {
  return useQuery(
    QUERY_KEY.GET_TICKET_USER_LIST,
    () => DashboardService.getTicketUserList(),
    { retry: false }
  );
};

const useTicketCreatedByList = () => {
  return useQuery(
    QUERY_KEY.GET_TICKET_CREATED_BY_LIST,
    () => DashboardService.getTicketCreatedByList(),
    { retry: false }
  );
};

const useAllTicketStatus = () => {
  return useQuery(
    QUERY_KEY.GET_ALL_TICKET_STATUS,
    () => DashboardService.getAllTicketStatus(),
    { retry: false }
  );
};

const useAllTicketStatusWithId = (id, setEditData) => {
  return useQuery(
    QUERY_KEY.GET_ALL_TICKET_STATUS_WITH_ID,
    () => DashboardService.getAllTicketStatusWithId(id),
    {
      retry: false,
      onSuccess: (data) => {
        setEditData(data?.data);
      },
    }
  );
};

const useAllTicketReasonWithId = (id, setEditData) => {
  return useQuery(
    QUERY_KEY.GET_TICKET_REASON_WITH_ID,
    () => DashboardService.getTicketReasonWithId(id),
    {
      retry: false,
      onSuccess: (data) => {
        setEditData(data?.data);
      },
    }
  );
};

const useTicketTabWithId = (id, setEditData) => {
  return useQuery(
    QUERY_KEY.GET_TICKET_TAB_WITH_ID,
    () => DashboardService.getTicketTabWithId(id),
    {
      retry: false,
      onSuccess: (data) => {
        setEditData(data?.data);
      },
    }
  );
};

const useTicketCount = () => {
  return useQuery(
    QUERY_KEY.GET_TICKET_COUNT,
    () => DashboardService.getTicketCount(),
    { retry: false }
  );
};

const useTicketTrends = () => {
  return useQuery(
    QUERY_KEY.GET_TICKET_TRENDS,
    () => DashboardService.getTicketTrends(),
    { retry: false }
  );
};

const useTicketCategory = () => {
  return useQuery(
    QUERY_KEY.GET_TICKET_CATEGORY,
    () => DashboardService.getTicketCategory(),
    { retry: false }
  );
};

const useTypeList = () => {
  return useQuery(
    QUERY_KEY.GET_TYPE_LIST,
    () => DashboardService.getTypeList(),
    { retry: false }
  );
};

const useTypeListWithId = (id, setEditData) => {
  return useQuery(
    QUERY_KEY.GET_TYPE_LIST_WITH_ID,
    () => DashboardService.getTypeListWithId(id),
    {
      retry: false,
      onSuccess: (data) => {
        setEditData(data?.data);
      },
    }
  );
};

const useTicketTabs = (setTabArrayData) => {
  return useQuery(
    QUERY_KEY.GET_TICKET_TABS,
    () => DashboardService.getTicketTabs(),
    {
      retry: false,
      onSuccess: (data) => {
        setTabArrayData(data);
      },
    }
  );
};

const useTicketTabsCrud = (setTabArrayData) => {
  return useQuery(
    QUERY_KEY.GET_TICKET_TABS_CRUD,
    () => DashboardService.getTicketTabsCrud(),
    {
      retry: false,
      onSuccess: (data) => {
        setTabArrayData(data);
      },
    }
  );
};

const useTicketMemberList = (setSearchResult) => {
  return useQuery(
    QUERY_KEY.GET_TICKET_MEMBER_LIST,
    () => DashboardService.getTicketMemberList(),
    {
      retry: false,
      onSuccess: (data) => {
        setSearchResult(data?.data[0]?.result);
      },
    }
  );
};

const useTicketTaskCount = () => {
  return useQuery(
    QUERY_KEY.GET_TICKET_TASK_COUNT,
    () => DashboardService.getTicketTaskCount(),
    { retry: false }
  );
};

const useTicketReason = (id) => {
  return useQuery(
    QUERY_KEY.GET_TICKET_REASON,
    () => DashboardService.getTicketReason(id),
    { retry: false }
  );
};

const useTicketTasks = (id) => {
  return useQuery(
    QUERY_KEY.GET_TICKET_TASKS,
    () => DashboardService.getTicketTasks(id),
    { retry: false }
  );
};

const useTicketStatus = () => {
  return useQuery(
    QUERY_KEY.GET_TICKET_STATUS,
    () => DashboardService.getTicketStatus(),
    { retry: false }
  );
};

const useTicketClosedStatus = () => {
  return useQuery(
    QUERY_KEY.GET_TICKET_STATUS_CLOSED,
    () => DashboardService.getTicketStatusClosed(),
    { retry: false }
  );
};

const useTicketComments = (id) => {
  return useQuery(
    QUERY_KEY.GET_TICKET_COMMENTS,
    () => DashboardService.getTicketComments(id),
    { retry: false }
  );
};

const useNodeList = (id) => {
  return useQuery(
    QUERY_KEY.GET_NODE_LIST,
    () => DashboardService.getNodeList(id),
    { retry: false }
  );
};

const useTicketDetails = (id) => {
  return useQuery(
    QUERY_KEY.GET_TICKET_DETAILS,
    () => DashboardService.getTicketDetails(id),
    { retry: false }
  );
};

const useCreateComment = (id, setShowInput) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.ADD_TICKET_COMMENT],
    (data) => DashboardService.addTicketComments(id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.GET_TICKET_COMMENTS);
        setShowInput(false);
        toast.success("Comment added successfully");
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

const useCreateTask = (id, onClose) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.ADD_TICKET_TASK],
    (data) => DashboardService.createTask(id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.GET_TICKET_TASKS);
        onClose();
        toast.success("Task added successfully");
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

const useTicketTypes = (params, otherString) => {
  var queryString = Object.keys(params)
    .map((key) => key + "=" + params[key])
    .join("&");

  return useQuery(
    QUERY_KEY.GET_TICKET_TYPES,
    () => DashboardService.getTicketTypes(queryString, otherString),
    {
      retry: false,
    }
  );
};

const useTicketTypesForNode = (id) => {
  return useQuery(
    QUERY_KEY.GET_TICKET_TYPES_FOR_NODE,
    () => DashboardService.getTicketTypesForNode(id),
    {
      retry: false,
    }
  );
};

const useTaskComplete = (ticketId, onConfClose) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.TICKET_COMPLETE],
    (taskId) => DashboardService.completeTask(ticketId, taskId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.GET_TICKET_TASKS);
        onConfClose();
        toast.success("task marked as completed");
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

const useUpdateAction = (ticketId) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.UPDATE_ACTION],
    (data) => DashboardService.updateAction(ticketId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.GET_TICKET_DETAILS);
        toast.success("Action updated successfully");
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

const useMarkAsClosed = (ticketId, handleModalClose, onConfClose) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.MARK_AS_CLOSED],
    (data) => DashboardService.markAsClosed(ticketId, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.GET_TICKET_DETAILS);
        queryClient.invalidateQueries(QUERY_KEY.GET_TICKET_TASKS);
        handleModalClose();
        onConfClose();
        toast.success("Ticket closed successfully");
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

const useDeleteTicketType = (onClose) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.DELETE_TICKET_TYPE],
    (id) => DashboardService.deleteTicketType(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.GET_TYPE_LIST);
        onClose();
        toast.success("Ticket type deleted successfully");
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

const useDeleteTicketStatus = (onClose) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.DELETE_TICKET_STATUS],
    (id) => DashboardService.deleteTicketStatus(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.GET_ALL_TICKET_STATUS);
        onClose();
        toast.success("Ticket status deleted successfully");
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

const useDeleteTicketReason = (onClose) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.DELETE_TICKET_REASON],
    (id) => DashboardService.deleteTicketReason(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.GET_TICKET_REASON);
        onClose();
        toast.success("Ticket reason deleted successfully");
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

const useDeleteTicketTabs = (onClose) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.DELETE_TICKET_TABS],
    (id) => DashboardService.deleteTicketTabs(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.GET_TICKET_TABS_CRUD);
        onClose();
        toast.success("Ticket tab deleted successfully");
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

const useTaskCancel = (ticketId, onCancClose) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.TICKET_CANCEL],
    (taskId) => DashboardService.cancelTask(ticketId, taskId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.GET_TICKET_TASKS);
        onCancClose();
        toast.success("task marked as cancelled");
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

const useCreateTicket = (handleModalClose) => {
  const history = useHistory();
  return useMutation(
    [MUTATION_KEY.CREATE_TICKET],
    (data) => DashboardService.createTicket(data),
    {
      onSuccess: (data) => {
        history.push(`/admin/ticket-details/${data.data.id}`);

        handleModalClose();

        toast.success("Ticket created successfully");
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

const useDeleteEntityClient = (onClose) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.DELETE_ENTITY_CLIENT],
    (id) => CrudApiService.deleteEntityClient(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.GET_ENTITY_CLIENT_LIST);
        onClose();
        toast.success("Client deactivated successfully");
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

const useAssignTicket = (id, setShowAssignUi, setTabIndex, onConfClose) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.ASSIGN_TICKET],
    (data) => DashboardService.assignTicket(id, data),
    {
      onSuccess: () => {
        setShowAssignUi(null);
        //window.location.reload();
        setTabIndex(0);
        onConfClose();
        toast.success("Ticket assigned successfully");
        queryClient.invalidateQueries(QUERY_KEY.GET_TICKET_TYPES);
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

const useAssignTicketOnNode = (id, setShowAssignUi, onConfClose) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.ASSIGN_TICKET],
    (data) => DashboardService.assignTicket(id, data),
    {
      onSuccess: () => {
        setShowAssignUi(null);
        onConfClose();
        toast.success("Ticket assigned successfully");
        queryClient.invalidateQueries(QUERY_KEY.GET_TICKET_TYPES_FOR_NODE);
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

const useAssignTicketSummary = (id, handleModalClose, onConfClose) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.ASSIGN_TICKET],
    (data) => DashboardService.assignTicket(id, data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.GET_TICKET_DETAILS);
        handleModalClose();
        onConfClose();
        toast.success("Ticket assigned successfully");
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

const useAddType = () => {
  const queryClient = useQueryClient();
  const history = useHistory();
  return useMutation(
    [MUTATION_KEY.ADD_TICKET_TYPE],
    (data) => DashboardService.addTicketType(data),
    {
      onSuccess: (data) => {
        history.push(`/admin/ticket-crud/ticket-crud-types`);
        queryClient.invalidateQueries(QUERY_KEY.GET_TYPE_LIST);
        toast.success("Ticket type created successfully");
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

const useEditType = (id) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  return useMutation(
    [MUTATION_KEY.EDIT_TICKET_TYPE],
    (data) => DashboardService.editTicketType(data, id),
    {
      onSuccess: (data) => {
        history.push(`/admin/ticket-crud/ticket-crud-types`);
        queryClient.invalidateQueries(QUERY_KEY.GET_TYPE_LIST);
        toast.success("Ticket type updated successfully");
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

const useEditStatus = (id) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  return useMutation(
    [MUTATION_KEY.EDIT_TICKET_STATUS],
    (data) => DashboardService.editTicketStatus(data, id),
    {
      onSuccess: (data) => {
        history.push(`/admin/ticket-crud/ticket-status`);
        queryClient.invalidateQueries(QUERY_KEY.GET_ALL_TICKET_STATUS);
        toast.success("Ticket status updated successfully");
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

const useEditReason = (id) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  return useMutation(
    [MUTATION_KEY.EDIT_TICKET_REASON],
    (data) => DashboardService.editTicketReason(data, id),
    {
      onSuccess: (data) => {
        history.push(`/admin/ticket-crud/ticket-reason`);
        queryClient.invalidateQueries(QUERY_KEY.GET_TICKET_REASON);
        toast.success("Ticket reason updated successfully");
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

const useEditTab = (id) => {
  const queryClient = useQueryClient();
  const history = useHistory();
  return useMutation(
    [MUTATION_KEY.EDIT_TICKET_TAB],
    (data) => DashboardService.editTicketTab(data, id),
    {
      onSuccess: (data) => {
        history.push(`/admin/ticket-crud/ticket-tabs`);
        queryClient.invalidateQueries(QUERY_KEY.GET_TICKET_TABS_CRUD);
        toast.success("Ticket tab updated successfully");
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

const useAddTicketStatus = () => {
  const queryClient = useQueryClient();
  const history = useHistory();
  return useMutation(
    [MUTATION_KEY.ADD_TICKET_STATUS],
    (data) => DashboardService.addTicketStatus(data),
    {
      onSuccess: (data) => {
        history.push(`/admin/ticket-crud/ticket-status`);
        queryClient.invalidateQueries(QUERY_KEY.GET_ALL_TICKET_STATUS);
        toast.success("Ticket status created successfully");
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

const useAddTicketReason = () => {
  const queryClient = useQueryClient();
  const history = useHistory();
  return useMutation(
    [MUTATION_KEY.ADD_TICKET_REASON],
    (data) => DashboardService.addTicketReason(data),
    {
      onSuccess: (data) => {
        history.push(`/admin/ticket-crud/ticket-reason`);
        queryClient.invalidateQueries(QUERY_KEY.GET_TICKET_REASON);
        toast.success("Ticket reason created successfully");
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

const useAddTicketTabs = () => {
  const queryClient = useQueryClient();
  const history = useHistory();
  return useMutation(
    [MUTATION_KEY.ADD_TICKET_TABS],
    (data) => DashboardService.addTicketTabs(data),
    {
      onSuccess: (data) => {
        history.push(`/admin/ticket-crud/ticket-tabs`);
        queryClient.invalidateQueries(QUERY_KEY.GET_TICKET_TABS_CRUD);
        toast.success("Ticket tabs created successfully");
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

const useOnBoardClient = (onClose) => {
  //const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.ONBOARD_CLIENT],
    (data) => CrudApiService.onBoardClient(data),
    {
      onSuccess: () => {
        //queryClient.invalidateQueries(QUERY_KEY.GET_ENTITY_CLIENT_LIST);
        onClose();
        toast.success("Client onboarded successfully");
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

const useCheckLicense = (setLicenseData) => {
  return useMutation(
    [MUTATION_KEY.CHECK_LICENSE],
    (data) => CrudApiService.checkLicense(data),
    {
      onSuccess: (data) => {
        setLicenseData(data.data.sample_env);
        toast.success("License generated successfully");
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

const TicketModuleHooks = {
  useTicketUserList,
  useTicketCount,
  useTicketTaskCount,
  useTicketTypes,
  useCreateTicket,
  useTicketTrends,
  useTypeList,
  useNodeList,
  useTicketStatus,
  useCreateTask,
  useTicketTabs,
  useTicketCategory,
  useTicketDetails,
  useCreateComment,
  useTicketComments,
  useDeleteEntityClient,
  useMarkAsClosed,
  useAssignTicket,
  useTicketTasks,
  useTicketReason,
  useAddType,
  useAssignTicketSummary,
  useUpdateAction,
  useTaskComplete,
  useTaskCancel,
  useDeleteTicketType,
  useTicketClosedStatus,
  useOnBoardClient,
  useEditStatus,
  useTicketTabWithId,
  useTicketMemberList,
  useDeleteTicketStatus,
  useTicketCreatedByList,
  useCheckLicense,
  useAllTicketStatus,
  useTicketTypesForNode,
  useAddTicketStatus,
  useAddTicketReason,
  useAssignTicketOnNode,
  useDeleteTicketReason,
  useAddTicketTabs,
  useAllTicketStatusWithId,
  useAllTicketReasonWithId,
  useTicketTabsCrud,
  useEditReason,
  useEditTab,
  useDeleteTicketTabs,
  useTypeListWithId,
  useEditType,
};

export default TicketModuleHooks;
