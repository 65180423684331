import { QUERY_KEY, MUTATION_KEY } from "constants/globalConstants";
import DashboardApiService from "apiService/dashboardApiService";
import { useMutation, useQuery } from "react-query";
import toast from "react-hot-toast";

const useGenerateScheduleReport = (
  scheduleEnabled,
  entityTypeId,
  entityTypeName,
  nodeTypeId,
  nodeTypeName,
  nodeId,
  nodeIdName,
  startDate,
  endDate,
  uptimeChecked,
  scheduleChecked,
  effectiveChecked,
  showTimeChecked,
  onClose,
  setScheduleEnabled
) => {
  return useQuery(
    QUERY_KEY.GET_SCHEDULE_CSV_REPORT,
    () =>
      DashboardApiService.getScheduleCsvReport(
        entityTypeId,
        entityTypeName,
        nodeTypeId,
        nodeTypeName,
        nodeId,
        nodeIdName,
        startDate,
        endDate,
        uptimeChecked,
        scheduleChecked,
        effectiveChecked,
        showTimeChecked
      ),
    {
      retry: false,
      enabled: scheduleEnabled,
      onSuccess: (res) => {
        scheduleEnabled &&
          window.open(res?.data, "_blank", "noopener,noreferrer");
        setScheduleEnabled(false);
        onClose();
      },
      onError: (err) => {
        toast.error(err.response.data.message);
        setScheduleEnabled(false);
      },
    }
  );
};
export default useGenerateScheduleReport;
