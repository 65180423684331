import { Box, Text } from "@chakra-ui/layout";
import dayjs from "dayjs";
import DownTimeListTable from "components/table/downTimeListTable";

function SlaTicketTable(props) {
  const {
    reportData,
    selectedTicketType,
    currentPage = 1,
    totalPage = 1,
  } = props;

  const columnsData = [
    {
      Header: "ID",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row?.ticket_id}
          </Text>
        );
      },
    },
    {
      Header: "Entity",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row?.entity}
          </Text>
        );
      },
    },
    {
      Header: "Node Type",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row?.node_type}
          </Text>
        );
      },
    },
    {
      Header: "Node Name",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row?.device_name || "-"}
          </Text>
        );
      },
    },

    {
      Header: "Status",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row?.status || "-"}
          </Text>
        );
      },
    },
    {
      Header: "Created At",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row?.created_at || "-"}
          </Text>
        );
      },
    },
    {
      Header: "Resolved On",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row.end_at || "-"}
          </Text>
        );
      },
    },
    {
      Header: "Resolved By",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row?.resolved_by || "-"}
          </Text>
        );
      },
    },
    {
      Header: "Resolution",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row?.resolution || "-"}
          </Text>
        );
      },
    },
  ];

  return (
    <Box pt="25px">
      <DownTimeListTable
        tableData={reportData}
        columnsData={columnsData}
        currentPage={currentPage}
        totalPage={totalPage}
      />
    </Box>
  );
}

export default SlaTicketTable;
