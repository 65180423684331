import Icon from "@chakra-ui/icon";
import { Flex, Text } from "@chakra-ui/layout";
import Card from "components/card/Card";
import { BlackoutIcon } from "components/icons/Icons";
import React from "react";
import dayjs from "dayjs";

const BlackoutCard = ({ time }) => {
  return (
    <Card
      bg="ndmsColors.black.200"
      color="ndmsColors.black.100"
      opacity="0.7"
      mt="58px"
      mb="56px"
      p="15px"
    >
      <Flex justifyContent="center" alignItems="center" flexDirection="column">
        <Text fontSize="20px" fontWeight="bold" mr="18px">
          <Icon
            as={BlackoutIcon}
            width="20px"
            height="20px"
            color="inherit"
            mr="8px"
            fontWeight="bold"
          />
          Entity Wide Blackout
        </Text>
        <Text color="ndmsColors.gray.250" fontSize="14px" fontWeight="500">
          Last node was available on{" "}
          {`${dayjs(time).format("DD MMM YYYY")}, ${dayjs(time).format(
            "hh:mm A"
          )}`}
        </Text>
      </Flex>
    </Card>
  );
};

export default BlackoutCard;
