import { Box, Text } from "@chakra-ui/layout";
import DownTimeListTable from "components/table/downTimeListTable";

function SlaSummaryTable(props) {
  const { blackoutReportData, currentPage = 1, totalPage = 1 } = props;

  const getColor = (value) => {
    switch (value) {
      case "Schedule Down":
        return "#135CC9";
      case "NOPD Down":
        return "#F0AE4C";
      case "UP":
        return " #3DCA78";
      default:
        return "red";
    }
  };

  const columnsData = [
    {
      Header: "Sr. No.",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row?.node_type_id}
          </Text>
        );
      },
    },
    {
      Header: "Tag",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row?.node_type_name}
          </Text>
        );
      },
    },
    {
      Header: "Nodes",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row?.total_device}
          </Text>
        );
      },
    },
    {
      Header: "Total UP Time ",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row?.uptime_percentage}%
          </Text>
        );
      },
    },
    {
      Header: "Total Down Time",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row?.downtime_percentage}%
          </Text>
        );
      },
    },
    {
      Header: "Scheduled Downtime",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row?.schedule_downtime_percentage}%
          </Text>
        );
      },
    },
    {
      Header: "Total NOPD Duration",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row?.nopd_percentage}%
          </Text>
        );
      },
    },
    {
      Header: "Effective Downtime",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row?.effective_downtime_percentage}%
          </Text>
        );
      },
    },
    {
      Header: "Effective UP Time",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row?.effective_uptime_percentage}%
          </Text>
        );
      },
    },
  ];

  return (
    <Box pt="25px">
      {blackoutReportData?.length > 0 && (
        <DownTimeListTable
          tableData={blackoutReportData}
          columnsData={columnsData}
          currentPage={currentPage}
          totalPage={totalPage}
        />
      )}
    </Box>
  );
}

export default SlaSummaryTable;
