import React from "react";
import Card from "components/card/Card.js";
import { Text, Flex } from "@chakra-ui/react";
import dayjs from "dayjs";

const NodeKpiData = ({ data }) => {
  return (
    <>
      <Card>
        <Flex justifyContent="space-between">
          <Card bg="#FBFCFF" border="2px solid #ECEFF9" width="max-content">
            <Flex direction="column" py="5px" px="10px">
              <Text color="#2B3674" fontWeight="bold" fontSize="16px">
                MTTR
              </Text>
              <Text color="#8F9BC3" fontWeight="bold" fontSize="16px">
                {data?.mttr} hr
              </Text>
            </Flex>
          </Card>
          <Card bg="#FBFCFF" border="2px solid #ECEFF9" width="max-content">
            <Flex direction="column" py="5px" px="10px">
              <Text color="#2B3674" fontWeight="bold" fontSize="16px">
                MTBF
              </Text>
              <Text color="#8F9BC3" fontWeight="bold" fontSize="16px">
                {data?.mtbf} hr
              </Text>
            </Flex>
          </Card>
          <Card bg="#FBFCFF" border="2px solid #ECEFF9" width="max-content">
            <Flex direction="column" py="5px" px="10px">
              <Text color="#2B3674" fontWeight="bold" fontSize="16px">
                Last Downtime
              </Text>
              <Text color="#8F9BC3" fontWeight="bold" fontSize="16px">
                {data?.last_downtime === "0"
                  ? "not available"
                  : `${dayjs(data?.last_downtime).format(
                      "DD/MM/YYYY"
                    )}, ${dayjs(data?.last_downtime).format("hh:mm A")}`}
              </Text>
            </Flex>
          </Card>
          <Card bg="#FBFCFF" border="2px solid #ECEFF9" width="max-content">
            <Flex direction="column" py="5px" px="10px">
              <Text color="#4318FF" fontWeight="bold" fontSize="16px">
                Next Schedule Downtime
              </Text>
              <Text color="#4318FF" fontWeight="bold" fontSize="16px">
                {data?.next_occure_time
                  ? `${dayjs(data?.next_occure_time).format(
                      "DD/MM/YYYY"
                    )}, ${dayjs(data?.next_occure_time).format("hh:mm A")}`
                  : "not available"}
              </Text>
            </Flex>
          </Card>
        </Flex>
      </Card>
    </>
  );
};

export default NodeKpiData;
