import axios from "axios";
import Config from "config";
import request from "utils";

const MY_URL = "https://48e8-202-160-167-98.in.ngrok.io";

const createRole = async (apiData) => {
  const { data } = await request.post(
    `${process.env.REACT_APP_BASE_URL}/create-role`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getRoles = async (pageSize, currentPage) => {
  const { data } = await request.get(
    pageSize === undefined && currentPage === undefined
      ? `${process.env.REACT_APP_BASE_URL}/get-roles?pagination=false`
      : `${process.env.REACT_APP_BASE_URL}/get-roles?page_size=${pageSize}&page=${currentPage}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const deleteRole = async (id) => {
  const { data } = await request.delete(
    `${process.env.REACT_APP_BASE_URL}/delete-role/${id}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const updateRole = async (apiData) => {
  const { data } = await request.put(
    `${process.env.REACT_APP_BASE_URL}/update-role`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const updateUser = async (apiData) => {
  const { data } = await request.put(
    `${process.env.REACT_APP_BASE_URL}/update-user`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getUserList = async (pageSize, currentPage) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/get-user-list?page_size=${pageSize}&page=${currentPage}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const createUser = async (apiData) => {
  const { data } = await request.post(
    `${process.env.REACT_APP_BASE_URL}/create-user`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const createPermission = async (apiData) => {
  const { data } = await request.post(
    `${process.env.REACT_APP_BASE_URL}/add-permission`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const updatePermission = async (apiData) => {
  const { data } = await request.put(
    `${process.env.REACT_APP_BASE_URL}/update-permission`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getPermission = async (pageSize, currentPage) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/get-permission?page_size=${pageSize}&page=${currentPage}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const deletePermission = async (id) => {
  const { data } = await request.delete(
    `${process.env.REACT_APP_BASE_URL}/delete-permission/${id}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const deleteUser = async (id) => {
  const { data } = await request.delete(
    `${process.env.REACT_APP_BASE_URL}/delete-user/${id}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getUserPermissions = async () => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/get-user-permissions`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getGroupPermission = async (pageSize, currentPage) => {
  const { data } = await request.get(
    pageSize === undefined && currentPage === undefined
      ? `${process.env.REACT_APP_BASE_URL}/permission_group?pagination=false`
      : `${process.env.REACT_APP_BASE_URL}/permission_group?page_size=${pageSize}&page=${currentPage}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getGroupWisePermission = async () => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/get_group_permissions`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const updateGroupPermission = async (apiData) => {
  const { data } = await request.put(
    `${process.env.REACT_APP_BASE_URL}/permission_group`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const createGroupPermission = async (apiData) => {
  const { data } = await request.post(
    `${process.env.REACT_APP_BASE_URL}/permission_group`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const deleteGroupPermission = async (id) => {
  const { data } = await request.delete(
    `${process.env.REACT_APP_BASE_URL}/permission_group/${id}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const UserService = {
  createRole,
  getRoles,
  createUser,
  getUserList,
  updateUser,
  updateRole,
  updatePermission,
  deleteRole,
  getPermission,
  createPermission,
  deletePermission,
  deleteUser,
  getUserPermissions,
  getGroupPermission,
  updateGroupPermission,
  getGroupWisePermission,
  createGroupPermission,
  deleteGroupPermission,
};

export default UserService;
