import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Box,
  Flex,
  Text,
  ModalCloseButton,
  Button,
  //Select,
} from "@chakra-ui/react";

const TmsConfirmationModal = ({
  isOpen,
  onClose,
  onOpen,
  text,
  onConfirmation,
  isLoading,
  handleModalClose,
}) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Box borderRadius="3xl">
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        blockScrollOnMount={false}
        size="sm"
        isCentered
      >
        <ModalOverlay />
        <ModalContent borderRadius="20px">
          <ModalHeader>
            <Flex justify="center" direction="column" align="center" mt="30px">
              <Text color="#A3AED0">{text}</Text>
              <Flex mt="20px">
                <Button
                  bg="#4318FF"
                  _hover={{ bg: "#2B3674" }}
                  mx={3}
                  mt={2}
                  mb={10}
                  size="lg"
                  isLoading={isLoading}
                  onClick={onConfirmation}
                >
                  <Text color={"#FFFFFF"}>Yes</Text>
                </Button>
                <Button
                  variant="outline"
                  colorScheme="#2B3674"
                  _hover={{ bg: "#F0F8FF" }}
                  mx={3}
                  mt={2}
                  size="lg"
                  mb={10}
                  onClick={handleClose}
                >
                  <Text color={"#2B3674"}>No</Text>
                </Button>
              </Flex>
            </Flex>
          </ModalHeader>

          <ModalCloseButton />
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default TmsConfirmationModal;
