import { StarIcon } from "@chakra-ui/icons";
import { Flex, Icon, Select, Text } from "@chakra-ui/react";
import TicketModuleHooks from "hooks/ticketModule/ticketModuleHooks";
import { useEffect } from "react";

const TicketFilter = ({
  setSelectedTicketType,
  setType,
  priority,
  type,
  entity,
  setpriority,
  setEntity,
  entityData,
}) => {
  const { data: typeData } = TicketModuleHooks.useTypeList();
  const getSlug = () => {
    setSelectedTicketType(
      typeData?.data?.filter((data) => data.id === type)[0]
    );
  };
  const generateTypeOptions = () => {
    return typeData?.data?.map((data) => {
      return <option value={data.id}>{data.name}</option>;
    });
  };

  useEffect(() => {
    if (type) {
      getSlug();
    }
  }, [type]);
  return (
    <Flex alignItems="center" mt="26px" mr="20px">
      <Flex alignItems="center" mr="20px">
        <Flex>
          <Text color="ndmsColors.gray.2100" fontSize="14px" fontWeight="bold">
            Ticket Type
          </Text>
          <Icon as={StarIcon} w="5px" h="5px" color="red !important" />
        </Flex>

        <Select
          fontSize="13px"
          width="198px"
          height="2.5rem"
          ml="14px"
          id="user_type"
          size="lg"
          mr="10px"
          color="#2B3674"
          fontWeight="bold"
          value={type}
          onChange={(e) => {
            setType(e.target.value);
          }}
        >
          <option selected disabled hidden value={null}>
            Choose Type
          </option>
          {generateTypeOptions()}
        </Select>
      </Flex>

      <Flex alignItems="center" mr="20px">
        <Flex>
          <Text color="ndmsColors.gray.2100" fontSize="14px" fontWeight="bold">
            Entity
          </Text>
        </Flex>
        <Select
          fontSize="13px"
          width="198px"
          height="2.5rem"
          ml="14px"
          id="user_type"
          size="lg"
          mr="10px"
          color="#2B3674"
          fontWeight="bold"
          value={entity}
          onChange={(e) => {
            setEntity(e.target.value);
          }}
        >
          <option value="0">Select Entity</option>
          {entityData?.data?.map((list) => (
            <option key={list?.id} value={list?.id}>
              {list?.name}
            </option>
          ))}
        </Select>
      </Flex>
      <Flex alignItems="center" mr="20px">
        <Flex>
          <Text color="ndmsColors.gray.2100" fontSize="14px" fontWeight="bold">
            Priority
          </Text>
        </Flex>
        <Select
          fontSize="13px"
          width="198px"
          height="2.5rem"
          ml="14px"
          id="user_type"
          size="lg"
          mr="10px"
          color="#2B3674"
          fontWeight="bold"
          value={priority}
          onChange={(e) => {
            setpriority(e.target.value);
          }}
        >
          <option selected disabled hidden value={null}>
            Choose Priority
          </option>
          <option value={1}>Low</option>
          <option value={2}>Medium</option>
          <option value={3}>High</option>
        </Select>
      </Flex>
    </Flex>
  );
};

export default TicketFilter;
