import { QUERY_KEY } from "constants/globalConstants";
import ApiService from "apiService";
import { useQuery } from "react-query";

const useClientList = (pageSize, currentPage) => {
  return useQuery(
    QUERY_KEY.GET_CLIENT_LIST,
    () => ApiService.getClientList(pageSize, currentPage),
    { retry: false }
  );
};

export default useClientList;
