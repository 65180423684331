import { QUERY_KEY } from "constants/globalConstants";
import ApiService from "apiService";
import { useQuery } from "react-query";

const useNodeTypes = (pageSize, currentPage) => {
  return useQuery(
    QUERY_KEY.GET_NODE_TYPE,
    () => ApiService.getNodeType(pageSize, currentPage),
    { retry: false }
  );
};

export default useNodeTypes;
