import React from "react";
import {
  Box,
  Text,
  Divider,
  Circle,
  Icon,
  Flex,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
} from "@chakra-ui/react";
import GoogleMapReact from "google-map-react";
import dayjs from "dayjs";
import { FaMapMarkerAlt } from "react-icons/fa";

const Map = ({ data }) => {
  const getBackgroundColor = (status) => {
    if (status === "syncing") {
      return "#3DCA78";
    } else {
      return "#E23A63";
    }
  };

  const defaultProps = {
    center: {
      lat: 25.445,
      lng: 81.8469,
    },
    zoom: 14,
  };

  const Marker = ({
    text,
    bg,
    type,
    school,
    actNode,
    totNode,
    status,
    sync,
  }) => (
    <Box width="max-content">
      <Popover cursor="pointer" border="none">
        <PopoverTrigger>
          <Box position="relative">
            <Icon
              as={FaMapMarkerAlt}
              width="28px"
              height="40px"
              color="inherit"
            />
            <Circle
              position="absolute"
              top={0}
              left={5}
              size="10px"
              bg={getBackgroundColor(status)}
              color="white"
            ></Circle>
          </Box>
        </PopoverTrigger>
        <PopoverContent>
          <PopoverBody>
            <Flex alignItems="center">
              <Circle size="8px" bg={getBackgroundColor(status)} />
              <Text
                fontSize="12px"
                fontWeight="bold"
                pl="5px"
                color={getBackgroundColor(status)}
              >
                {status}
              </Text>
            </Flex>
            {sync ? (
              <Text fontSize="12px" color="ndmsColors.gray.1900">
                Last Synced on:{" "}
                {`${dayjs(sync).format("DD MMM YYYY")} at ${dayjs(sync).format(
                  "hh:mm a"
                )}`}
              </Text>
            ) : null}
            <Flex alignItems="center" justifyContent="space-between">
              <Text fontSize="14px" mt="10px" fontWeight="500">
                {school}
              </Text>
              <Circle size="15px" bg="ndmsColors.gray.1300" color="black">
                <Text fontWeight="500">{">"}</Text>
              </Circle>
            </Flex>
            <Text
              fontSize="12px"
              color="ndmsColors.gray.1900"
              mt="2px"
              mb="5px"
              fontWeight="500"
            >
              {text}
            </Text>
            <Divider mt="10px" />
            <Flex mt="10px">
              <Text fontSize="12px">Active Nodes: {actNode} </Text>
              <Box mx="5px" height="20px">
                <Divider
                  color="ndmsColors.gray.450"
                  borderColor
                  orientation="vertical"
                />
              </Box>
              <Text fontSize="12px">Inactive Nodes: {totNode} </Text>
            </Flex>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );

  const getMapBounds = (map, maps, places) => {
    if (map && places.length > 0) {
      const bounds = new maps.LatLngBounds();

      places.forEach((place) => {
        place.Address.lat !== null &&
          place.Address.long !== null &&
          place.Address.lat !== 0 &&
          place.Address.long !== 0 &&
          place.Address.lat >= -90 &&
          place.Address.long <= 90 &&
          place.Address.long >= -180 &&
          place.Address.long <= 180 &&
          bounds.extend({ lat: place.Address.lat, lng: place.Address.long });
      });
      return bounds;
    }
  };

  // Re-center map when resizing the window
  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, "idle", () => {
      maps.event.addDomListener(window, "resize", () => {
        map.fitBounds(bounds);
      });
    });
  };

  const handleApiLoaded = (map, maps, places) => {
    // use map and maps objects
    // Get bounds by our places
    const bounds = getMapBounds(map, maps, places);

    // Fit map to bounds
    map.fitBounds(bounds);

    bindResizeListener(map, maps, bounds);
  };

  return (
    <GoogleMapReact
      bootstrapURLKeys={{
        key: "AIzaSyBHW12GcimaOknFTm6ldxrx8NT24yzecKc",
      }}
      center={defaultProps.center}
      zoom={defaultProps.zoom}
      yesIWantToUseGoogleMapApiInternals
      onGoogleApiLoaded={({ map, maps }) =>
        handleApiLoaded(map, maps, data?.data[0]?.result)
      }
      //resetBoundsOnResize
    >
      {data?.data[0]?.result?.map((mark, index) => (
        <Marker
          key={index}
          lat={mark.Address.lat}
          lng={mark.Address.long}
          text={`${mark.Address.city}, ${mark.Address.state}`}
          type={mark.name}
          school={mark.name}
          actNode={mark.healthy_nodes}
          totNode={mark.unhealthy_nodes}
          status={mark.status}
          sync={mark.last_sync_at}
        />
      ))}
    </GoogleMapReact>
  );
};

export default Map;
