import React, { useState, useEffect } from "react";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import { Box, Flex, Text } from "@chakra-ui/react";

const MultiSelectCheckbox = ({
  IdData,
  selectedNodeIds,
  setSelectedNodeIds,
  groupData,
}) => {
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  useEffect(() => {
    if (selectedNodeIds.length === IdData.length) {
      setSelectAllChecked(true);
    } else {
      setSelectAllChecked(false);
    }
  }, [selectedNodeIds.length, IdData.length]);

  const handleAllChecked = (id) => (event) => {
    const idArray = [];
    const myData = IdData.filter((f) => f.group_id === id);
    if (event.target.checked) {
      for (let i = 0; i < myData.length; i++) {
        idArray.push({
          id: myData[i].id,
          name: myData[i].name,
          group_id: myData[i].group_id,
        });
      }
      setSelectedNodeIds(selectedNodeIds.concat(idArray));
    } else {
      setSelectedNodeIds((current) =>
        current.filter((element) => {
          return element.group_id !== id;
        })
      );
    }
  };

  const handleCheckChildElement = (data) => (event) => {
    const idArray = [];
    if (event.target.checked) {
      idArray.push({
        id: data.id,
        name: data.name,
        group_id: data.group_id,
      });
      setSelectedNodeIds(selectedNodeIds.concat(idArray));
    } else {
      setSelectedNodeIds((current) =>
        current.filter((element) => {
          return element.id !== data.id;
        })
      );
    }
  };

  const handleGroupChecked = (id) => {
    return selectedNodeIds.some(function (el) {
      return el.group_id === id;
    });
  };

  const handleOptionChecked = (id) => {
    return selectedNodeIds.some(function (el) {
      return el.id === id;
    });
  };

  const handleSelectAll = (event) => {
    const idArray = [];
    if (event.target.checked) {
      for (let i = 0; i < IdData.length; i++) {
        idArray.push({
          id: IdData[i].id,
          name: IdData[i].name,
          group_id: IdData[i].group_id,
        });
      }
      setSelectedNodeIds(selectedNodeIds.concat(idArray));
    } else {
      setSelectedNodeIds([]);
    }
  };

  const MultiValue = (props) => {
    let labelToBeDisplayed = `${props.data.name}, `;
    return (
      <components.MultiValue {...props}>
        <span>{labelToBeDisplayed}</span>
      </components.MultiValue>
    );
  };

  const customStyles = {
    control: (styles) => ({
      ...styles,
      minHeight: "2.5rem",
      borderColor: "#DCE3EB !important",
      scrollBehavior: "smooth",
    }),
    placeholder: (styles) => {
      return {
        ...styles,
        color: "#9AA6CF",
        fontSize: "17px",
        paddingLeft: "5px",
      };
    },
    option: (styles, { isFocused, isSelected }) => ({
      ...styles,
      color: "black",
      background: isFocused ? "white" : isSelected ? "white" : "white",
      zIndex: 1,
    }),
    valueContainer: (styles) => {
      return {
        ...styles,
        maxHeight: "30px",
      };
    },
    multiValueRemove: (base) => ({ ...base, display: "none" }),
  };

  const Option = (props) => {
    return (
      <Box>
        {props.innerProps.id.includes("option-0") ? (
          <div>
            <components.Option {...props}>
              <div>
                <Flex alignItems="center">
                  <input
                    type="checkbox"
                    onChange={handleSelectAll}
                    value={"select all"}
                    checked={selectAllChecked}
                  />{" "}
                  <Text pl="5px" fontWeight="bold">
                    Select All
                  </Text>
                </Flex>
                {groupData.data.map((item, index) => (
                  <div key={index.id}>
                    {item.node.length > 0 ? (
                      <Flex alignItems="center">
                        <input
                          type="checkbox"
                          onChange={handleAllChecked(item.id)}
                          value={item.id}
                          checked={handleGroupChecked(item.id)}
                        />{" "}
                        <Text pl="5px" fontWeight="bold">
                          {item.name}
                        </Text>
                      </Flex>
                    ) : null}
                    <ul style={{ paddingLeft: "20px", listStyle: "none" }}>
                      {IdData.filter((data) => data.group_id === item.id).map(
                        (dat, findex) => {
                          return (
                            <li key={dat.id}>
                              <input
                                onChange={handleCheckChildElement(dat)}
                                type="checkbox"
                                checked={handleOptionChecked(dat.id)}
                                value={`${dat.id}`}
                              />{" "}
                              {dat.name}
                            </li>
                          );
                        }
                      )}
                    </ul>
                  </div>
                ))}
              </div>
            </components.Option>
          </div>
        ) : null}
      </Box>
    );
  };
  return (
    <ReactSelect
      options={IdData}
      isMulti
      closeMenuOnSelect={false}
      hideSelectedOptions={false}
      isClearable={false}
      components={{
        Option,
        MultiValue,
      }}
      styles={customStyles}
      allowSelectAll={true}
      value={selectedNodeIds}
    />
  );
};

export default MultiSelectCheckbox;
