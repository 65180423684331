import { QUERY_KEY } from "constants/globalConstants";
import ApiService from "apiService";
import { useQuery } from "react-query";

const useGroup = (id, traceableDevices = true) => {
  return useQuery(
    QUERY_KEY.GET_GROUP,
    () =>
      traceableDevices
        ? ApiService.getGroup(id)
        : ApiService.getUntraceableGroup(id),
    {
      retry: false,
    }
  );
  //     if (traceableDevices === "yes") {
  //   } else {
  //     return useQuery(
  //       QUERY_KEY.GET_GROUP,
  //       () => ApiService.getUntraceableGroup(id),
  //       { retry: false }
  //     );
  //   }
};

export default useGroup;
