import { QUERY_KEY, MUTATION_KEY } from "constants/globalConstants";
import { useMutation, useQueryClient, useQuery } from "react-query";
import toast from "react-hot-toast";
import DashboardApiService from "apiService/dashboardApiService";

const useNopdBreakupList = (
  pageSize,
  currentPage,
  status,
  entityId,
  nodeTypeId
) => {
  return useQuery(
    QUERY_KEY.GET_NOPD_BREAKUP_LIST,
    () =>
      DashboardApiService.getNopdBreakupList(
        pageSize,
        currentPage,
        status,
        entityId,
        nodeTypeId
      ),
    { retry: false }
  );
};

const useEntityNopdBreakupList = (pageSize, currentPage, status, entityId) => {
  return useQuery(
    QUERY_KEY.GET_ENTITY_NOPD_BREAKUP_LIST,
    () =>
      DashboardApiService.getEntityNopdBreakupList(
        pageSize,
        currentPage,
        status,
        entityId
      ),
    { retry: false }
  );
};

const useCreateNopdBreakup = (handleModalClose) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.CREATE_NOPD_BREAKUP],
    (data) => DashboardApiService.createNopdBreakupList(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.NODE_LOG_DETAILS);
        handleModalClose();
        toast.success("NOPD added successfully");
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

const useCreateEntityNopdBreakup = (handleModalClose) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.CREATE_ENTITY_NOPD_BREAKUP],
    (data) => DashboardApiService.createEntityNopdBreakup(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.NODE_LOG_DETAILS);
        handleModalClose();
        toast.success("NOPD added successfully");
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

const useUpdateNopdStatus = (handleModalClose) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.UPDATE_NOPD_STATUS],
    (data) => DashboardApiService.updateNopdBreakupStatus(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.GET_NOPD_BREAKUP_LIST);
        handleModalClose();
        toast.success("status updated successfully");
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

const useEntityUpdateNopdStatus = (handleModalClose) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.UPDATE_ENTITY_NOPD_STATUS],
    (data) => DashboardApiService.updateEntityNopdBreakupStatus(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.GET_ENTITY_NOPD_BREAKUP_LIST);
        handleModalClose();
        toast.success("status updated successfully");
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

const useUpdateNopdBreakupList = (handleModalClose) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.UPDATE_NOPD_BREAKUP_LIST],
    (data) => DashboardApiService.updateNopdBreakupList(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.GET_NOPD_BREAKUP_LIST);
        handleModalClose();
        toast.success("nopd updated successfully");
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

const useUpdateEntityNopdBreakupList = (handleModalClose) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.UPDATE_ENTITY_NOPD_BREAKUP_LIST],
    (data) => DashboardApiService.updateEntityNopdBreakupList(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.GET_ENTITY_NOPD_BREAKUP_LIST);
        handleModalClose();
        toast.success("nopd updated successfully");
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

const ApproveDowntimeHooks = {
  useNopdBreakupList,
  useCreateNopdBreakup,
  useUpdateNopdStatus,
  useEntityNopdBreakupList,
  useUpdateNopdBreakupList,
  useCreateEntityNopdBreakup,
  useUpdateEntityNopdBreakupList,
  useEntityUpdateNopdStatus,
};

export default ApproveDowntimeHooks;
