import React, { useEffect, useState } from "react";
import { Box, Text } from "@chakra-ui/layout";
import { Select } from "@chakra-ui/select";
import { Flex } from "@chakra-ui/react";

function PerformanceFilter(props) {
  //   const [selectedNodeType, setSelectedNodeType] = useState(null);

  //   const { nodeTypeList, setNodeTypeId } = props;

  //   const onHandleChange = (e) => {
  //     setSelectedNodeType(e.target.value);
  //   };

  //   useEffect(() => {
  //     setNodeTypeId(selectedNodeType);
  //   }, [selectedNodeType]);

  return (
    <Box>
      <Flex alignItems="center">
        <Select
          bg="#FFFFFF"
          fontSize="15px"
          width="198px"
          height="2.5rem"
          id="user_type"
          size="lg"
          color="#7B818A"
          fontWeight="bold"
          mr="12px"
          // value={selectedNodeType}
          // onChange={onHandleChange}
          placeholder="Performance"
        >
          {/* {nodeTypeList?.data?.map((list) => (
              <option key={list.id} value={list.id}>
                {list.name}
              </option>
            ))} */}
          <option>very good</option>
        </Select>
      </Flex>
    </Box>
  );
}

export default PerformanceFilter;
