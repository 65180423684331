import { MUTATION_KEY } from "constants/globalConstants";
import ApiService from "apiService";
import { useMutation } from "react-query";
import toast from "react-hot-toast";
import { useHistory } from "react-router";

const useRegenerateToken = (refetch) => {
  const history = useHistory();
  return useMutation(
    [MUTATION_KEY.REGENERATE_TOKEN],
    (data) => ApiService.regenerateToken(data),
    {
      onSuccess: (data) => {
        localStorage.setItem("token", data?.data?.AccessToken);
        refetch();
        window.location.reload();
      },
      onError: (error) => {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        history.push("/auth/sign-in");
        window.location.reload();
        toast.error(error.response.data.message);
      },
    }
  );
};

export default useRegenerateToken;
