/*eslint-disable*/
import React from "react";
import {
  Flex,
  Link,
  Box,
  List,
  ListItem,
  Text,
  Button,
  useColorMode,
  useColorModeValue,
} from "@chakra-ui/react";

export default function Footer() {
  const textColor = useColorModeValue("gray.400", "white");
  const { toggleColorMode } = useColorMode();
  const footerBranding = localStorage.getItem("footer_branding");
  return (
    <Flex
      zIndex="3"
      flexDirection={{
        base: "column",
        xl: "row",
      }}
      alignItems={{
        base: "center",
        xl: "end",
      }}
      position="relative"
      justifyContent="space-between"
      px={{ base: "30px", md: "50px" }}
      pb="30px"
    >
      {/* <Text
        color={textColor}
        textAlign={{
          base: "center",
          xl: "start",
        }}
        mb={{ base: "20px", xl: "0px" }}
      >
        {" "}
        &copy; {1900 + new Date().getYear()}
        <Text as="span" fontWeight="500" ms="4px">
          Horizon UI. All Rights Reserved. Made with love by
          <Link
            mx="3px"
            color={textColor}
            href="https://www.simmmple.com"
            target="_blank"
            fontWeight="700"
          >
            Simmmple!
          </Link>
        </Text>
      </Text> */}
      {footerBranding?.toLowerCase() === "show" && (
        <List
          display="flex"
          position="absolute"
          right={0}
          pr={{ base: "30px", md: "50px" }}
        >
          <ListItem>
            <Box>
              <Text
                color={textColor}
                textAlign={{
                  base: "center",
                  xl: "end",
                }}
              >
                Powered by Auriga IT
              </Text>
            </Box>
          </ListItem>
        </List>
      )}
    </Flex>
  );
}
