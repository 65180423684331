import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Circle,
  Flex,
  InputGroup,
  Divider,
  Tooltip,
  Select,
  Input,
  InputRightElement,
  Tabs,
  Icon,
  TabList,
  Skeleton,
  TabPanels,
  Tab,
  useDisclosure,
  TabPanel,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { useHistory } from "react-router";
import TmsLineChart from "./tmsLineChart";
import TicketDonut from "./ticketDonut";
import { SearchIcon } from "@chakra-ui/icons";
import TmsMembersTable from "components/table/tmsMembersTable";
import TicketModuleHooks from "hooks/ticketModule/ticketModuleHooks";
import EmptyCard from "components/card/EmptyCard";
import "./tms.css";
import { getDashboardPermissions } from "Permissions";

const Tms = () => {
  const history = useHistory();
  const [tabArrayData, setTabArrayData] = useState([]);
  const [searchResult, setSearchResult] = useState([]);
  const {
    data: countData,
    isLoading: isCountLoading,
    error: countError,
    refetch: countRefetch,
    isFetching: isCountFetching,
  } = TicketModuleHooks.useTicketCount();
  const {
    data: trendsData,
    isLoading: isTrendsLoading,
    error: trendsError,
    refetch: trendsRefetch,
    isFetching: isTrendsFetching,
  } = TicketModuleHooks.useTicketTrends();
  const {
    data: taskData,
    isLoading: isTaskLoading,
    error: taskError,
  } = TicketModuleHooks.useTicketTaskCount();
  const {
    data: categoryData,
    isLoading: isCategoryLoading,
    error: categoryError,
    refetch: categoryRefetch,
    isFetching: isCategoryFetching,
  } = TicketModuleHooks.useTicketCategory();
  const {
    data: membersData,
    isLoading: isMembersLoading,
    error: membersError,
    refetch: membersRefetch,
    isFetching: isMembersFetching,
  } = TicketModuleHooks.useTicketMemberList(setSearchResult);
  const {
    data: tabsData,
    isLoading: isTabsLoading,
    error: tabsError,
    refetch,
    isFetching,
  } = TicketModuleHooks.useTicketTabs(setTabArrayData);

  const [tabIndex, setTabIndex] = useState(0);

  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    countRefetch();
    trendsRefetch();
    countRefetch();
    categoryRefetch();
    membersRefetch();
    refetch();
  }, [window.location.pathname]);

  useEffect(() => {
    if (
      searchValue?.trim()?.length > 0 &&
      membersData?.data[0]?.result?.length > 0 &&
      !isMembersLoading
    ) {
      const newTableData = membersData?.data[0]?.result.filter((value) =>
        value.user_fname
          .concat(" ")
          .concat(value.user_lname)
          .toLowerCase()
          .includes(searchValue?.trim()?.toLowerCase())
      );
      setSearchResult(newTableData);
    } else {
      setSearchResult(membersData?.data[0]?.result);
    }
  }, [searchValue]);

  const columnsData = [
    {
      Header: "Name",
      accessor: (row) => {
        return (
          <Box>
            <Tooltip label={`${row.user_fname} ${row.user_lname}`}>
              <Text
                color={"ndmsColors.gray.1900"}
                fontSize="12px"
                width="100px"
                overflow="hidden"
                whiteSpace="nowrap"
                fontWeight="500"
                textOverflow="ellipsis"
              >
                {`${row.user_fname} ${row.user_lname}`}
              </Text>
            </Tooltip>
          </Box>
        );
      },
    },
    {
      Header: "Roles",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.gray.1900"} fontSize="12px" fontWeight="500">
            {row.user_role}
          </Text>
        );
      },
    },
    {
      Header: "Tickets",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.gray.1900"} fontSize="12px" fontWeight="500">
            {row.total}
          </Text>
        );
      },
    },
    {
      Header: "Contact Details",
      accessor: (row) => {
        return (
          <Box>
            <Text
              color={"ndmsColors.gray.1900"}
              fontSize="12px"
              width="300px"
              overflow="hidden"
              whiteSpace="nowrap"
              fontWeight="500"
              textOverflow="ellipsis"
            >
              {row.user_email}
            </Text>
          </Box>
        );
      },
    },
  ];

  const getCount = (label) => {
    switch (label) {
      case "Open":
        return countData?.data[0]?.active;
      case "Unassigned":
        return countData?.data[0]?.unassigned;
      case "In Process":
        return countData?.data[0]?.in_process;
      default:
        return countData?.data[0]?.active;
    }
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Text fontSize="20px" fontWeight="bold" mb="20px">
        Ticket Overview
      </Text>
      <Flex>
        {getDashboardPermissions("ticketing.ticket.view") ? (
          <>
            {isTabsLoading || isFetching ? (
              <>
                {[...Array(3)].map((arr) => (
                  <>
                    <Card
                      mr="10px"
                      borderRadius="6px"
                      border="1px solid #EAEDF2"
                    >
                      <Skeleton width="100%" height="50px" />
                    </Card>
                  </>
                ))}
              </>
            ) : (
              <>
                {tabArrayData?.data
                  ?.sort((a, b) => {
                    return a.show_order - b.show_order;
                  })
                  ?.map((tab, index) => (
                    <>
                      {tab?.label.toLowerCase() === "open" ||
                      tab?.label.toLowerCase() === "unassigned" ||
                      tab?.label.toLowerCase() === "in process" ? (
                        <Card
                          mr="10px"
                          borderRadius="6px"
                          border="1px solid #EAEDF2"
                          cursor="pointer"
                          onClick={() =>
                            history.push({
                              pathname: `/admin/ticket-types`,
                              state: { data: index },
                            })
                          }
                        >
                          <Text color="ndmsColors.gray.1900" fontSize="12px">
                            {tab?.label}
                          </Text>
                          <Flex
                            alignItems="center"
                            justifyContent="space-between"
                          >
                            {isCountLoading || isCountFetching ? (
                              <Skeleton height="20px" width="30px" />
                            ) : (
                              <Text fontSize="20px" fontWeight="bold">
                                {getCount(tab?.label)}
                              </Text>
                            )}
                            <Text fontSize="20px">{">"}</Text>
                          </Flex>
                        </Card>
                      ) : null}
                    </>
                  ))}
              </>
            )}
          </>
        ) : (
          <>
            <Card mr="10px" borderRadius="6px" border="1px solid #EAEDF2">
              <Text color="ndmsColors.gray.1900" fontSize="12px">
                Open
              </Text>
              <Flex alignItems="center" justifyContent="space-between">
                {isCountLoading || isCountFetching ? (
                  <Skeleton height="20px" width="30px" />
                ) : (
                  <Text fontSize="20px" fontWeight="bold">
                    {countData?.data[0]?.active}
                  </Text>
                )}
              </Flex>
            </Card>
            <Card mr="10px" borderRadius="6px" border="1px solid #EAEDF2">
              <Text color="ndmsColors.gray.1900" fontSize="12px">
                In Process
              </Text>
              <Flex alignItems="center" justifyContent="space-between">
                {isCountLoading || isCountFetching ? (
                  <Skeleton height="20px" width="30px" />
                ) : (
                  <Text fontSize="20px" fontWeight="bold">
                    {countData?.data[0]?.in_process}
                  </Text>
                )}
              </Flex>
            </Card>
            <Card mr="10px" borderRadius="6px" border="1px solid #EAEDF2">
              <Text color="ndmsColors.gray.1900" fontSize="12px">
                Unassigned
              </Text>
              <Flex alignItems="center" justifyContent="space-between">
                {isCountLoading || isCountFetching ? (
                  <Skeleton height="20px" width="30px" />
                ) : (
                  <Text fontSize="20px" fontWeight="bold">
                    {countData?.data[0]?.unassigned}
                  </Text>
                )}
              </Flex>
            </Card>
          </>
        )}

        <Card mr="10px" borderRadius="6px" border="1px solid #EAEDF2">
          <Text color="ndmsColors.gray.1900" fontSize="12px">
            Tasks due Today
          </Text>
          <Flex alignItems="center" justifyContent="space-between">
            {isCountLoading || isCountFetching ? (
              <Skeleton height="20px" width="30px" />
            ) : (
              <Text fontSize="20px" fontWeight="bold">
                {countData?.data[0]?.due_today}
              </Text>
            )}
          </Flex>
        </Card>
        <Card mr="10px" borderRadius="6px" border="1px solid #EAEDF2">
          <Text color="ndmsColors.gray.1900" fontSize="12px">
            Tasks Overdue
          </Text>
          <Flex alignItems="center" justifyContent="space-between">
            {isCountLoading || isCountFetching ? (
              <Skeleton height="20px" width="30px" />
            ) : (
              <Text fontSize="20px" fontWeight="bold">
                {countData?.data[0]?.overdue}
              </Text>
            )}
          </Flex>
        </Card>
      </Flex>
      <Flex justifyContent="space-between" alignItems="center" mt="30px">
        <Text fontSize="20px" fontWeight="bold">
          Ticket Trends
        </Text>
        <Select
          bg="white"
          fontSize="15px"
          width="200px"
          height="40px"
          id="user_type"
          size="lg"
          disabled={true}
          //ml="10px"
          color="#2C3774"
          fontWeight="bold"
          //value={status}
          //onChange={(e) => setStatus(e.target.value)}
        >
          {/* <option value="active">Today</option>
            <option value="inactive">Last 7 days</option> */}
          <option value="expired">Last 30 days</option>
        </Select>
      </Flex>
      <Card
        mt="20px"
        pt="0px"
        //pb="30px"
        height="250px"
        width="100%"
        border="1px solid #EAEDF2"
        borderRadius="10px"
      >
        {isTrendsLoading || isTrendsFetching ? (
          <Skeleton mt="20px" height="210px" width="100%" />
        ) : (
          <TmsLineChart data={trendsData} />
        )}
      </Card>
      <Flex mt="10px">
        <Flex direction="column" width="35%">
          <Text fontSize="20px" fontWeight="bold" mt="30px" mb="20px">
            Ticket Category
          </Text>
          <Card pt="5px" border="1px solid #EAEDF2" borderRadius="10px">
            {isCategoryLoading || isCategoryFetching ? (
              <Skeleton width="100%" height="500px" />
            ) : (
              <Tabs pt="0px" onChange={(index) => setTabIndex(index)}>
                <TabList>
                  <Tab
                    _selected={{
                      color: "ndmsColors.blue.100",
                      boxShadow: "none",
                      borderBottom: "3px solid #4318FF",
                    }}
                    color="#828282"
                    fontSize="18px"
                    fontWeight="bold"
                  >
                    Assigned ({categoryData?.data?.total_assigned})
                  </Tab>
                  <Tab
                    _selected={{
                      color: "ndmsColors.blue.100",
                      boxShadow: "none",
                      borderBottom: "3px solid #4318FF",
                    }}
                    color="#828282"
                    fontSize="18px"
                    fontWeight="bold"
                  >
                    Unassigned ({categoryData?.data?.total_unassigned})
                  </Tab>
                </TabList>

                <TabPanels>
                  <TabPanel pl="0px">
                    <TicketDonut
                      data={categoryData}
                      tableTabData={categoryData?.data?.ticket_type}
                      label="Pending"
                      otherLabel="In Process"
                      totalLabel="Total Assigned"
                      value={categoryData?.data?.total_in_process}
                      total={categoryData?.data?.total_pending}
                      viewTotal={categoryData?.data?.total_assigned}
                      tabIndex={tabIndex}
                    />
                  </TabPanel>
                  <TabPanel pl="0px">
                    <TicketDonut
                      data={categoryData}
                      tableTabData={categoryData?.data?.ticket_type}
                      label="Unassigned"
                      otherLabel="Assigned"
                      totalLabel="Total Open"
                      value={categoryData?.data?.total_assigned}
                      total={categoryData?.data?.total_unassigned}
                      viewTotal={categoryData?.data?.total_open_ticket}
                      tabIndex={tabIndex}
                    />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            )}
          </Card>
        </Flex>
        <Flex direction="column" width="65%" pl="20px">
          <Flex justifyContent="space-between" alignItems="center">
            <Text fontSize="20px" fontWeight="bold" mt="30px" mb="20px">
              Team Members (
              {membersData?.data?.length === 0
                ? 0
                : membersData?.data[0]?.result?.length}
              )
            </Text>
            <InputGroup bg="white" width="max-content" height="max-content">
              <Input
                onChange={(e) => setSearchValue(e.target.value)}
                value={searchValue}
                placeholder="Search team members"
              />
              <InputRightElement
                children={<SearchIcon color="ndmsColors.gray.2200" />}
              />
            </InputGroup>
          </Flex>
          <Card
            border="1px solid #EAEDF2"
            overflow="auto"
            height="250px"
            borderRadius="10px"
            flexGrow="1"
          >
            {isMembersLoading || isMembersFetching ? (
              <Skeleton height="200px" width="100%" />
            ) : (
              <>
                {searchResult?.length > 0 ? (
                  <TmsMembersTable
                    columnsData={columnsData}
                    tableData={searchResult}
                  />
                ) : (
                  <Flex alignItems="center" my="auto" justifyContent="center">
                    <EmptyCard
                      font="20px"
                      size="70px"
                      color="ndmsColors.gray.100"
                    />
                  </Flex>
                )}
              </>
            )}
          </Card>
        </Flex>
      </Flex>
      <Flex>
        {/* <Flex direction="column" width="60%" pl="20px">
          <Text fontSize="20px" fontWeight="bold" mt="30px" mb="20px">
            To-do list
          </Text>
          <Card border="1px solid #EAEDF2" borderRadius="10px">
            <Flex>
              <Icon
                as={BsCheckCircleFill}
                width="20px"
                height="15px"
                color="green"
                mt="5px"
              />
              <Flex direction="column" pl="5px">
                <Text fontWeight="600">
                  Follow Up call with Simon Rathore at 3 PM
                </Text>
                <Text fontSize="12px" color="ndmsColors.gray.1900">
                  One way at Bada Naya Gaanv, Bundi
                </Text>
              </Flex>
            </Flex>

            <Divider my="10px" />
            <Flex>
              <Icon
                as={BsCheckCircleFill}
                width="20px"
                height="15px"
                color="green"
                mt="5px"
              />
              <Flex direction="column" pl="5px">
                <Text fontWeight="600">
                  Follow Up call with Simon Rathore at 3 PM
                </Text>
                <Text fontSize="12px" color="ndmsColors.gray.1900">
                  One way at Bada Naya Gaanv, Bundi
                </Text>
              </Flex>
            </Flex>
          </Card>
        </Flex> */}
      </Flex>
    </Box>
  );
};

export default Tms;
