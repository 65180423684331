import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Input,
  Flex,
  FormLabel,
  Text,
  ModalCloseButton,
  Button,
  //Select,
  useColorModeValue,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import useUpdateTemplateNode from "hooks/useUpdateTemplateNode";

function TemplateEditModal({
  isOpen,
  onClose,
  onOpen,
  templateData,
  data,
  groupData,
  setShowCreateGroup,
}) {
  const {
    handleSubmit,
    control,
    resetField,
    formState: { errors },
  } = useForm();
  const textColor = useColorModeValue("navy.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const buttonHover = useColorModeValue("brand.600", "brand.400");

  //const { mutate: addNode } = useAddNode(groupData[1]?.id, setShowCreateGroup);

  const handleModalClose = () => {
    resetField("name");
    resetField("device_id");
    resetField("ip_address");
    onClose();
  };

  console.log(templateData);

  const { mutate: updateTemplateNode, isLoading } =
    useUpdateTemplateNode(handleModalClose);

  const onSubmit = (data) => {
    const apiData = [
      {
        id: templateData?.id,
        name: data.name,
        node_type_id: templateData?.node_type_id,
        template_id: templateData?.template_id,
      },
    ];
    updateTemplateNode(apiData);
  };
  return (
    <>
      <Modal
        isOpen={isOpen}
        blockScrollOnMount={false}
        onClose={handleModalClose}
        size="sm"
      >
        <ModalOverlay />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContent>
            <ModalHeader>
              <Flex w="100%" mt="15px">
                <Flex direction="column" width="100%">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Name<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <div style={{ marginBottom: "10px" }}>
                    <Controller
                      name={`name`}
                      rules={{ required: true }}
                      control={control}
                      defaultValue={templateData?.name}
                      render={({ field }) => (
                        <Input
                          fontSize="sm"
                          ms={{ base: "0px", md: "0px" }}
                          type="name"
                          placeholder="Enter Name"
                          //mb="24px"
                          fontWeight="500"
                          size="lg"
                          {...field}
                        />
                      )}
                    />
                    {errors.name?.type === "required" && (
                      <span style={{ color: "red" }}>name is required</span>
                    )}
                  </div>
                </Flex>
              </Flex>
            </ModalHeader>
            <Button
              bg="#4318FF"
              _hover={{ bg: isLoading ? "blue" : buttonHover }}
              ml={5}
              mt={2}
              mb={10}
              variant="brand"
              width="120px"
              type="submit"
              isLoading={isLoading}
            >
              <Text color={"#FFFFFF"}>Save</Text>
            </Button>
            <ModalCloseButton />
          </ModalContent>
        </form>
      </Modal>
    </>
  );
}

export default TemplateEditModal;
