import React, { useState, useEffect } from "react";
import { CalendarIcon } from "@chakra-ui/icons";
import {
  Drawer,
  DrawerBody,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  Circle,
  Box,
  Input,
  Flex,
  Icon,
  Checkbox,
  Select as SingleSelect,
  FormLabel,
  InputGroup,
  InputRightElement,
  Text,
  Button,
  DrawerCloseButton,
  useColorModeValue,
} from "@chakra-ui/react";
import { YEAR_ARRAY } from "../../constants/globalConstants";
import MultiSelectCheckBox from "../multiSelectCheckboxComponet";
import useEditScheduleDowntime from "hooks/useEditScheduleDowntime";
import { useForm, Controller } from "react-hook-form";
import { DOWNTIME } from "constants/globalConstants";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import Select from "react-select";
import toast from "react-hot-toast";
import { BiUpArrow, BiDownArrow } from "react-icons/bi";
import useGroup from "hooks/useGroup";

const DowntimeDrawer = ({
  entityId,
  isOpen,
  onOpen,
  onClose,
  nodeTypeData,
  data,
  editData,
  setValue,
}) => {
  const {
    handleSubmit,
    control,
    resetField,
    formState: { errors },
    clearErrors,
  } = useForm();
  const { data: groupData } = useGroup(entityId);
  const [selectedDevices, setSelectedDevices] = useState([]);
  const [selectedDeviceType, setSelectedDeviceType] = useState();
  const [duration, setDuration] = useState("0 days, 0 hours");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [fromTime, setFromTime] = useState(null);
  const [toTime, setToTime] = useState(null);
  const [fromStartTime, setFromStartTime] = useState(null);
  const [fromEndTime, setFromEndTime] = useState(null);
  const [selectedNodeIds, setSelectedNodeIds] = useState([]);
  const [days, setDays] = useState(editData?.days);
  const [allNodeChecked, setAllNodeChecked] = useState(false);
  const [indexId, setIndexId] = useState(1);
  const buttonHover = useColorModeValue("brand.600", "brand.400");

  const handleModalClose = () => {
    onClose();
    resetField("downtime_type");
    resetField("starts_at");
    resetField("ends_at");
    resetField("starts_fixed");
    resetField("ends_fixed");
    resetField("repeat_till");
    editData ? setSelectedDevices(editData?.node_type) : setSelectedDevices([]);
    setDuration("0 days, 0 hours");
    editData ? setDays(editData?.days) : setDays([]);
    editData
      ? setSelectedDeviceType(editData?.downtime_type)
      : setSelectedDeviceType(null);
    setIndexId(1);
    editData ? setSelectedNodeIds(editData?.nodes) : setSelectedNodeIds([]);
    clearErrors();
    setFromStartTime(null);
    setFromEndTime(null);
    //reset(data)
  };

  const { mutate: scheduleDowntime, isLoading } =
    useEditScheduleDowntime(handleModalClose);

  useEffect(() => {
    setSelectedNodeIds([]);
    setSelectedDevices([]);
  }, [allNodeChecked]);

  useEffect(() => {
    resetField("starts_at");
    resetField("ends_at");
    setDays([]);
  }, [selectedDeviceType]);

  useEffect(() => {
    setSelectedDeviceType(editData?.downtime_type);
    setSelectedDevices(editData?.node_type);
    setSelectedNodeIds(editData?.nodes);
    setTimeout(() => (setDays(editData?.days), 1000));
    if (editData?.downtime_type === "fixed") {
      checkForDurationEdit(editData?.starts_at, editData?.ends_at);
    }
    if (editData?.nodes?.length === IdData?.length) {
      setAllNodeChecked(true);
    }
    setFromStartTime(editData?.starts_at);
    setFromEndTime(editData?.ends_at);
  }, [editData, isOpen]);

  const brandStars = useColorModeValue("brand.500", "brand.400");

  const generateSelectOptions = () => {
    return DOWNTIME?.map((state) => {
      return <option value={state.value}>{state.label}</option>;
    });
  };

  const formatData = () => {
    let myArray = [];
    if (groupData?.data?.length > 0) {
      for (let i = 0; i < groupData?.data.length; i++) {
        myArray = myArray.concat(groupData.data[i].node);
      }
    }
    return myArray;
  };

  const IdData = formatData();

  const generateDeviceOptions = () => {
    return data?.map((device) => {
      return <option value={device.id}>{device.label}</option>;
    });
  };

  const dateIsValid = (date) => {
    return !Number.isNaN(new Date(date).getTime());
  };

  const getDefaultDate = (dateValue) => {
    return dateIsValid(new Date(dateValue)) ? new Date(dateValue) : "";
  };

  const onError = (errors) => {
    console.log("errors", errors);
  };

  const getNodeId = () => {
    if (selectedNodeIds.length > 0) {
      const arr = [];
      for (let i = 0; i < selectedNodeIds.length; i++) {
        arr.push(selectedNodeIds[i].id);
      }
      return arr;
    } else {
      return [];
    }
  };

  const onSubmit = (data) => {
    const selectedDays =
      days?.length > 0
        ? days.sort(function (a, b) {
            return a - b;
          })
        : [];
    if (selectedDeviceType === "fixed") {
      const hours = dayjs(`${data?.ends_fixed} ${data?.ends_fixed}`).diff(
        dayjs(`${data?.starts_fixed} ${data?.starts_fixed}`),
        "hour",
        true
      );
      if (hours < 0) {
        toast.error("end time cannot be before start time");
        return;
      } else {
        scheduleDowntime({
          entity_id: entityId,
          node_ids: getNodeId(),
          node_type_ids: selectedDevices?.map((device) => device.id),
          downtime_type: selectedDeviceType,
          id: editData?.id,
          data: {
            starts_at: dayjs(data?.starts_fixed).format("YYYY-MM-DD HH:mm:ss"),
            ends_at: dayjs(data?.ends_fixed).format("YYYY-MM-DD HH:mm:ss"),
            days: selectedDays,
          },
          include_all_node_type: allNodeChecked,
        });
      }
    } else {
      const today = dayjs(new Date()).format("YYYY-MM-DD");
      const hours = dayjs(
        `${today} ${data.ends_at ? data.ends_at : editData?.ends_at}`
      ).diff(
        dayjs(
          `${today} ${data.starts_at ? data.starts_at : editData?.starts_at}`
        ),
        "hour",
        true
      );
      if (hours < 0) {
        toast.error("end time cannot be before start time");
        return;
      } else if (data?.repeat_till) {
        scheduleDowntime({
          entity_id: entityId,
          node_ids: getNodeId(),
          node_type_ids: selectedDevices?.map((device) => device.id),
          downtime_type: selectedDeviceType,
          repeat_till: dayjs(data?.repeat_till).format("YYYY-MM-DD HH:mm:ss"),
          id: editData?.id,
          data: {
            starts_at: data.starts_at ? data.starts_at : editData?.starts_at,
            ends_at: data.ends_at ? data.ends_at : editData?.ends_at,
            days: selectedDays,
          },
          include_all_node_type: allNodeChecked,
        });
      } else {
        scheduleDowntime({
          entity_id: entityId,
          node_ids: getNodeId(),
          node_type_ids: selectedDevices?.map((device) => device.id),
          downtime_type: selectedDeviceType,
          id: editData?.id,
          data: {
            starts_at: data.starts_at ? data.starts_at : editData?.starts_at,
            ends_at: data.ends_at ? data.ends_at : editData?.ends_at,
            days: selectedDays,
          },
          include_all_node_type: allNodeChecked,
        });
      }
    }
  };

  const getDaysList = () => {
    switch (selectedDeviceType) {
      case "weekly":
        return 7;
      case "monthly":
        return 31;
      default:
        return 31;
    }
  };

  const checkForTimeDuration = () => {
    const today = dayjs(new Date()).format("YYYY-MM-DD");
    const hours = dayjs(`${today} ${fromEndTime}`).diff(
      dayjs(`${today} ${fromStartTime}`),
      "hour",
      true
    );
    if (hours < 0) {
      toast.error("end time cannot be before start time");
      return;
    }
  };

  const checkForDurationEdit = (startDate, endDate) => {
    const fromDate = dayjs(startDate).format("YYYY-MM-DD");
    const fromTime = dayjs(startDate).format("HH:mm:ss");
    const toDate = dayjs(endDate).format("YYYY-MM-DD");
    const toTime = dayjs(endDate).format("HH:mm:ss");
    let day = 0;
    let validhour = 0;
    const hours = dayjs(`${toDate} ${toTime}`).diff(
      dayjs(`${fromDate} ${fromTime}`),
      "hour",
      true
    );
    if (isNaN(hours)) {
      setDuration("0 Days, 0 hours");
    }
    if (hours < 0) {
      toast.error("to time cannot be before from time");
      return;
    }
    if (hours > 24) {
      day = hours / 24;
      validhour = hours % 24;
      setDuration(
        `${parseInt(JSON.stringify(day), 10)} days, ${validhour} hours`
      );
    } else {
      setDuration(`0 days, ${parseInt(JSON.stringify(hours), 10)} hours`);
    }
  };

  const checkForDuration = () => {
    let day = 0;
    let validhour = 0;
    const hours = dayjs(`${toDate} ${toTime}`).diff(
      dayjs(`${fromDate} ${fromTime}`),
      "hour",
      true
    );
    if (isNaN(hours)) {
      setDuration("0 Days, 0 hours");
    }
    if (hours < 0) {
      toast.error("to time cannot be before from time");
      return;
    }
    if (hours > 24) {
      day = hours / 24;
      validhour = hours % 24;
      setDuration(
        `${parseInt(JSON.stringify(day), 10)} days, ${validhour} hours`
      );
    } else {
      setDuration(`0 days, ${parseInt(JSON.stringify(hours), 10)} hours`);
    }
  };

  const handleTypeChange = (event) => {
    setSelectedDeviceType(event.target.value);
  };

  useEffect(() => {
    if (fromDate && fromTime && toDate && toTime) {
      checkForDuration();
    }
  }, [fromDate, fromTime, toDate, toTime]);

  useEffect(() => {
    checkForTimeDuration();
  }, [fromStartTime, fromEndTime]);

  const handleDays = (d) => {
    if (days?.includes(d)) {
      setDays((days) =>
        days.filter((day) => {
          return day !== d;
        })
      );
    } else {
      setDays((days) => [...days, d]);
    }
  };

  const getValueForDay = (num) => {
    const d = new Date();
    const year = d.getFullYear();
    switch (true) {
      case num === 1:
        return 0;
      case num === 2:
        return 31;
      case num === 3:
        return year % 4 === 0 ? 60 : 59;
      case num === 4:
        return year % 4 === 0 ? 91 : 90;
      case num === 5:
        return year % 4 === 0 ? 121 : 120;
      case num === 6:
        return year % 4 === 0 ? 152 : 151;
      case num === 7:
        return year % 4 === 0 ? 182 : 181;
      case num === 8:
        return year % 4 === 0 ? 213 : 212;
      case num === 9:
        return year % 4 === 0 ? 244 : 243;
      case num === 10:
        return year % 4 === 0 ? 274 : 273;
      case num === 11:
        return year % 4 === 0 ? 305 : 304;
      case num === 12:
        return year % 4 === 0 ? 335 : 334;
      default:
        return 31;
    }
  };

  const handleYearDays = (month, value) => {
    const d = value + getValueForDay(month);
    if (days?.includes(d)) {
      setDays((days) =>
        days.filter((day) => {
          return day !== d;
        })
      );
    } else {
      setDays((days) => [...days, d]);
    }
  };

  const handleIndex = (value, type) => {
    if (type === "up") {
      setIndexId(value + 1);
    } else {
      setIndexId(value - 1);
    }
  };

  const getDefaultTime = (time) => {
    const date = dayjs(new Date()).format("MM/DD/YYYY").concat(" ", time);
    return dayjs(date).format("HH:mm");
  };

  const getDaysLabelValue = (day) => {
    if (selectedDeviceType === "weekly") {
      switch (day) {
        case 1:
          return "Mon";
        case 2:
          return "Tue";
        case 3:
          return "Wed";
        case 4:
          return "Thu";
        case 5:
          return "Fri";
        case 6:
          return "Sat";
        case 7:
          return "Sun";
        default:
          return "Sun";
      }
    } else {
      return day;
    }
  };

  return (
    <>
      <Drawer
        isOpen={isOpen}
        placement="right"
        onClose={handleModalClose}
        size={"lg"}
        //finalFocusRef={btnRef}
      >
        <DrawerOverlay />
        <DrawerContent position="relative">
          <DrawerCloseButton mt="20px" />
          <DrawerHeader mt="10px" ml="10px">
            <Text fontSize={"24px"} color="#2B3674" fontWeight="bold">
              Scheduled Downtime for Devices
            </Text>
          </DrawerHeader>
          <DrawerBody>
            <form
              onSubmit={handleSubmit(onSubmit, onError(errors))}
              style={{ width: "100%" }}
            >
              <Checkbox
                isChecked={allNodeChecked}
                onChange={(e) => setAllNodeChecked(e.target.checked)}
                pl="10px"
                pb="20px"
                borderColor="#422AFB"
              >
                Include All Node Types
              </Checkbox>
              <Flex>
                {allNodeChecked ? null : (
                  <Flex direction="column" width="100%" pl="10px" pb="15px">
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="700"
                      color={"#2B3674"}
                      mb="8px"
                    >
                      Node Type<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <div style={{ marginBottom: "10px" }}>
                      <Select
                        id="user_type"
                        size="lg"
                        placeholder="Select Device"
                        value={selectedDevices}
                        options={nodeTypeData?.data[0]?.result}
                        getOptionLabel={(data) => data["name"]}
                        getOptionValue={(data) => data["id"]}
                        isMulti
                        onChange={setSelectedDevices}
                      >
                        {generateDeviceOptions()}
                      </Select>
                      {errors.name?.type === "required" && (
                        <span style={{ color: "red" }}>device is required</span>
                      )}
                    </div>
                  </Flex>
                )}

                <Flex
                  direction="column"
                  width={allNodeChecked ? "50%" : "100%"}
                  pl="10px"
                >
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="700"
                    color={"#2B3674"}
                    isRequired={true}
                    display="flex"
                  >
                    Downtime<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <div style={{ marginBottom: "24px" }}>
                    <SingleSelect
                      id="user_type"
                      size="md"
                      placeholder="Select Downtime"
                      value={selectedDeviceType}
                      onChange={handleTypeChange}
                      //defaultValue={editData?.type}
                      //{...field}
                    >
                      {generateSelectOptions()}
                    </SingleSelect>

                    {/* {errors.downtime_type?.type === "required" && (
                      <span style={{ color: "red" }}>type is required</span>
                    )} */}
                  </div>
                </Flex>
                {allNodeChecked ? null : (
                  <Box pb="20px" width="100%" pl="10px">
                    <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="700"
                      color={"#2B3674"}
                      display="flex"
                    >
                      Select Nodes
                    </FormLabel>
                    <MultiSelectCheckBox
                      IdData={IdData}
                      selectedNodeIds={selectedNodeIds}
                      setSelectedNodeIds={setSelectedNodeIds}
                      groupData={groupData}
                    />
                  </Box>
                )}
              </Flex>
              {selectedDeviceType === "monthly" ? (
                <Flex
                  align="center"
                  borderRadius="10px"
                  py="10px"
                  mt="10px"
                  mb="30px"
                  ml="10px"
                  px="10px"
                  bg="#E5E5E5"
                >
                  <Text px="5px" fontWeight="bold" color={"#2B3674"}>
                    This downtime will occur on the exact date selected. If a
                    date does not appear in a given month, downtime will also
                    not be scheduled.
                  </Text>
                </Flex>
              ) : null}
              {selectedDeviceType !== "fixed" ? (
                <>
                  <Flex direction="column" width="100%" pl="10px">
                    {selectedDeviceType === "daily" ? null : (
                      <FormLabel
                        ms="4px"
                        fontSize="sm"
                        fontWeight="700"
                        color={"#2B3674"}
                        isRequired={true}
                        display="flex"
                      >
                        Select Days
                        <Text color={brandStars}>*</Text>
                      </FormLabel>
                    )}

                    {selectedDeviceType === "yearly" ? (
                      <Flex
                        flexWrap="wrap"
                        height="200px"
                        px="10px"
                        borderRadius="10px"
                        py="2px"
                        overflow="hidden"
                        position="relative"
                      >
                        {YEAR_ARRAY?.map((year, index) => (
                          <>
                            {indexId === index + 1 ? (
                              <Flex py="10px">
                                <Flex direction="column" alignItems="center">
                                  <Flex items="cemter">
                                    {indexId === 12 ? null : (
                                      <Circle
                                        size="20px"
                                        bg="#2B3674"
                                        mt="5px"
                                        mr="10px"
                                        cursor="pointer"
                                        onClick={() =>
                                          handleIndex(index + 1, "up")
                                        }
                                        rotate="180"
                                      >
                                        <Icon
                                          as={BiUpArrow}
                                          width="10px"
                                          height="20px"
                                          color="white"
                                        />
                                      </Circle>
                                    )}
                                    <Text fontSize="20px" color="#2B3674">
                                      {year?.name}
                                    </Text>
                                    {indexId === 1 ? null : (
                                      <Circle
                                        size="20px"
                                        bg="#2B3674"
                                        ml="10px"
                                        mt="5px"
                                        cursor="pointer"
                                        onClick={() =>
                                          handleIndex(index + 1, "down")
                                        }
                                        rotate="180"
                                      >
                                        <Icon
                                          as={BiDownArrow}
                                          width="10px"
                                          height="20px"
                                          color="white"
                                        />
                                      </Circle>
                                    )}
                                  </Flex>
                                  <Flex wrap="wrap">
                                    {[...Array(year?.days)].map((el, index) => (
                                      <Circle
                                        size="40px"
                                        bg={
                                          days?.includes(
                                            index +
                                              1 +
                                              getValueForDay(year?.value)
                                          )
                                            ? "#2B3674"
                                            : "#E5E5E5"
                                        }
                                        cursor="pointer"
                                        my="5px"
                                        mr="10px"
                                        onClick={() =>
                                          handleYearDays(year?.value, index + 1)
                                        }
                                      >
                                        <Text
                                          color={
                                            days?.includes(
                                              index +
                                                1 +
                                                getValueForDay(year?.value)
                                            )
                                              ? "white"
                                              : "#2B3674"
                                          }
                                        >
                                          {index + 1}
                                        </Text>
                                      </Circle>
                                    ))}
                                  </Flex>
                                </Flex>
                              </Flex>
                            ) : null}
                          </>
                        ))}
                      </Flex>
                    ) : (
                      <>
                        {selectedDeviceType === "daily" ? null : (
                          <Flex
                            flexWrap="wrap"
                            maxHeight="300px"
                            px="10px"
                            borderRadius="10px"
                            py="2px"
                            overflow="auto"
                            border="1px solid #E5E5E5"
                          >
                            {[...Array(getDaysList())].map((el, index) => (
                              <Circle
                                size={
                                  selectedDeviceType === "weekly"
                                    ? "40px"
                                    : "30px"
                                }
                                bg={
                                  days?.includes(index + 1)
                                    ? "#2B3674"
                                    : "#E5E5E5"
                                }
                                cursor="pointer"
                                my="5px"
                                mr="10px"
                                onClick={() => handleDays(index + 1)}
                              >
                                <Text
                                  color={
                                    days?.includes(index + 1)
                                      ? "white"
                                      : "#2B3674"
                                  }
                                >
                                  {getDaysLabelValue(index + 1)}
                                </Text>
                              </Circle>
                            ))}
                          </Flex>
                        )}
                      </>
                    )}
                  </Flex>
                  <Flex
                    direction="column"
                    width="100%"
                    pl="10px"
                    mt={selectedDeviceType === "daily" ? "10px" : "20px"}
                  >
                    <Flex>
                      <Box>
                        <FormLabel
                          ms="4px"
                          fontSize="sm"
                          fontWeight="700"
                          color={"ndmsColors.blue.100"}
                          isRequired={true}
                          display="flex"
                        >
                          Start Time
                          <Text color={brandStars}>*</Text>
                        </FormLabel>
                        <Controller
                          name="starts_at"
                          rules={{ required: false }}
                          control={control}
                          defaultValue={getDefaultTime(editData?.starts_at)}
                          render={({ field }) => (
                            <Input
                              fontSize="sm"
                              minWidth="170px"
                              ms={{ base: "0px", md: "0px" }}
                              type="time"
                              //value={getDefaultTime(editData?.starts_at)}
                              placeholder="Format Example: 05:30 Am"
                              //mb="24px"
                              fontWeight="500"
                              size="md"
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.target.value);
                                setFromStartTime(e.target.value);
                              }}
                              //{...field}
                            />
                          )}
                        />
                        {errors.starts_at?.type === "required" && (
                          <span style={{ color: "red" }}>
                            start time is required
                          </span>
                        )}
                      </Box>

                      <Box ml="20px">
                        <FormLabel
                          ms="4px"
                          fontSize="sm"
                          fontWeight="700"
                          color={"ndmsColors.blue.100"}
                          isRequired={true}
                          display="flex"
                        >
                          End Time
                          <Text color={brandStars}>*</Text>
                        </FormLabel>
                        <Controller
                          name="ends_at"
                          rules={{ required: false }}
                          control={control}
                          defaultValue={getDefaultTime(editData?.ends_at)}
                          // defaultValue={getDefaultTime(editData?.ends_at)}
                          render={({ field }) => (
                            <Input
                              fontSize="sm"
                              minWidth="170px"
                              ms={{ base: "0px", md: "0px" }}
                              type="time"
                              // value={getDefaultTime(editData?.ends_at)}
                              placeholder="Format Example: 05:30 Pm"
                              //mb="24px"
                              fontWeight="500"
                              size="md"
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.target.value);
                                setFromEndTime(e.target.value);
                              }}
                              //{...field}
                            />
                          )}
                        />
                        {errors.ends_at?.type === "required" && (
                          <span style={{ color: "red" }}>
                            end time is required
                          </span>
                        )}
                      </Box>

                      <Box ml="20px">
                        <FormLabel
                          ms="4px"
                          fontSize="sm"
                          fontWeight="700"
                          color={"#2B3674"}
                          display="flex"
                        >
                          Repeat Till
                        </FormLabel>
                        <Flex>
                          <Box>
                            <Controller
                              name={`repeat_till`}
                              rules={{ required: false }}
                              control={control}
                              defaultValue={
                                editData?.repeat_till
                                  ? getDefaultDate(editData?.repeat_till)
                                  : null
                              }
                              render={({ field }) => (
                                <DatePicker
                                  wrapperClassName="date-picker"
                                  showTimeSelect
                                  selectMultiple={true}
                                  customInput={
                                    <InputGroup>
                                      <Input
                                        minWidth="170px"
                                        placeholder="From"
                                        fontSize="14px"
                                        value={
                                          field.value
                                            ? `${field?.value?.toLocaleDateString()}, ${field.value.toLocaleTimeString(
                                                "en-US"
                                              )}`
                                            : ""
                                        }
                                      />
                                      <InputRightElement
                                        children={
                                          <CalendarIcon color="#4318FF" />
                                        }
                                      />
                                    </InputGroup>
                                  }
                                  onChange={(date) => {
                                    field.onChange(date);
                                  }}
                                  selected={field.value}
                                  //minDate={new Date()}
                                  dateFormat="MMMM d, yyyy h:mm aa"
                                />
                              )}
                            />
                          </Box>
                        </Flex>
                      </Box>
                    </Flex>
                  </Flex>
                </>
              ) : (
                <>
                  <Flex direction="column" width="100%" pl="10px">
                    <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="700"
                      color={"#2B3674"}
                      isRequired={true}
                      display="flex"
                    >
                      Schedule Date and Time
                      <Text color={brandStars}>*</Text>
                    </FormLabel>
                    <Flex width="100%">
                      <Box mb="24px">
                        <Controller
                          name="starts_fixed"
                          rules={{ required: false }}
                          control={control}
                          defaultValue={getDefaultDate(editData?.starts_at)}
                          render={({ field }) => (
                            <DatePicker
                              wrapperClassName="date-picker"
                              showTimeSelect
                              selectMultiple={true}
                              minDate={new Date()}
                              customInput={
                                <InputGroup>
                                  <Input
                                    minWidth="300px"
                                    placeholder="From"
                                    value={
                                      field.value
                                        ? `${field?.value?.toLocaleDateString()}, ${field.value.toLocaleTimeString(
                                            "en-US"
                                          )}`
                                        : ""
                                    }
                                  />
                                  <InputRightElement
                                    children={<CalendarIcon color="#4318FF" />}
                                  />
                                </InputGroup>
                              }
                              onChange={(date) => {
                                field.onChange(date);
                                setFromDate(dayjs(date).format("YYYY-MM-DD"));
                                setFromTime(dayjs(date).format("HH:mm:ss"));
                              }}
                              selected={field.value}
                              //minDate={new Date()}
                              dateFormat="MMMM d, yyyy h:mm aa"
                            />
                          )}
                        />
                        {errors.starts_fixed?.type === "required" && (
                          <span style={{ color: "red" }}>
                            start date is required
                          </span>
                        )}
                      </Box>
                      <Box mb="24px" ml="10px">
                        <Controller
                          name="ends_fixed"
                          rules={{ required: false }}
                          control={control}
                          defaultValue={getDefaultDate(editData?.ends_at)}
                          render={({ field }) => (
                            <DatePicker
                              wrapperClassName="date-picker"
                              showTimeSelect
                              customInput={
                                <InputGroup>
                                  <Input
                                    minWidth="300px"
                                    placeholder="To"
                                    value={
                                      field.value
                                        ? `${field.value.toLocaleDateString()}, ${field.value.toLocaleTimeString(
                                            "en-US"
                                          )}`
                                        : ""
                                    }
                                  />
                                  <InputRightElement
                                    children={<CalendarIcon color="#4318FF" />}
                                  />
                                </InputGroup>
                              }
                              onChange={(date) => {
                                field.onChange(date);
                                setToDate(dayjs(date).format("YYYY-MM-DD"));
                                setToTime(dayjs(date).format("HH:mm:ss"));
                              }}
                              selected={field.value}
                              minDate={new Date()}
                              dateFormat="MMMM d, yyyy h:mm aa"
                            />
                          )}
                        />
                        {errors.ends_fixed?.type === "required" && (
                          <span style={{ color: "red" }}>
                            end date is required
                          </span>
                        )}
                      </Box>
                    </Flex>
                  </Flex>

                  <Flex direction="column" width="50%" pl="10px">
                    <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="700"
                      color={"#2B3674"}
                      isRequired={true}
                      display="flex"
                    >
                      Duration Slot
                      <Text color={brandStars}>*</Text>
                    </FormLabel>
                    <Flex
                      align="center"
                      borderRadius="10px"
                      py="10px"
                      mb="20px"
                      pl="10px"
                      bg="#E5E5E5"
                    >
                      <Text px="5px" fontWeight="bold" color={"#2B3674"}>
                        {duration}
                      </Text>
                    </Flex>
                  </Flex>
                </>
              )}
              <Flex
                position="absolute"
                left="0"
                bottom="0"
                pt="10px"
                mb="20px"
                ml="30px"
              >
                <Button
                  variant="brand"
                  type="submit"
                  colorScheme="blue"
                  size="md"
                  width="120px"
                  _hover={{ bg: isLoading ? "blue" : buttonHover }}
                  isLoading={isLoading}
                >
                  Save
                </Button>
                <Button
                  variant="outline"
                  ml={5}
                  size="md"
                  onClick={handleModalClose}
                >
                  <Text color="#3311DB">Cancel</Text>
                </Button>
              </Flex>
            </form>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default DowntimeDrawer;
