import { QUERY_KEY, MUTATION_KEY } from "constants/globalConstants";
import DashboardApiService from "apiService/dashboardApiService";
import { useMutation, useQuery, useQueryClient } from "react-query";
import toast from "react-hot-toast";

// const useScheduleReportDetails = (setSlaPerformanceMetrics, setShowReport) => {
//   const queryClient = useQueryClient();
//   return useMutation(
//     [MUTATION_KEY.SCHEDULE_DETAIL],
//     (data) => DashboardApiService.postScheduleDownTimeDetail(data),
//     {
//       onSuccess: (data) => {
//         queryClient.invalidateQueries(data);
//         setSlaPerformanceMetrics(data);
//         setShowReport(true);
//       },
//       onError: (error) => {
//         toast.error(error?.response?.data?.message);
//       },
//     }
//   );
// };

const useScheduleReportDetails = (
  scheduleEnabled,
  entityTypeId,
  entityTypeName,
  nodeTypeId,
  nodeTypeName,
  nodeId,
  nodeIdName,
  startDate,
  endDate,
  scheduleChecked,
  showTimeChecked,
  setSlaPerformanceMetrics,
  setShowReport,
  setScheduleEnabled
) => {
  return useQuery(
    QUERY_KEY.GET_SCHEDULE_DOWNTIME_REPORT,
    () =>
      DashboardApiService.getScheduleDowntimeReport(
        entityTypeId,
        entityTypeName,
        nodeTypeId,
        nodeTypeName,
        nodeId,
        nodeIdName,
        startDate,
        endDate,
        scheduleChecked,
        showTimeChecked
      ),
    {
      retry: false,
      enabled: scheduleEnabled,
      onSuccess: (res) => {
        setSlaPerformanceMetrics(res);
        setShowReport(true);
        setScheduleEnabled(false);
      },
      onError: (err) => {
        toast.error(err.response.data.message);
        setScheduleEnabled(false);
      },
    }
  );
};

export default useScheduleReportDetails;
