import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Text,
  Button,
  Tooltip,
  Menu,
  Input,
  MenuButton,
  IconButton,
  MenuList,
  MenuItem,
  Icon,
  useDisclosure,
  Flex,
} from "@chakra-ui/react";
import { MdEdit } from "react-icons/md";
import Card from "components/card/Card.js";
import { AddIcon } from "@chakra-ui/icons";
import { AddGroupMenu } from "components/icons/Icons";
import CreateTemplateModal from "./createTemplateModal";
import CopyGroupModal from "./copyGroupModal";
import NodeModal from "components/modal/nodeModal";
import { GrNetworkDrive } from "react-icons/gr";
import useUpdateGroup from "hooks/useUpdateGroup";
import useDeleteGroup from "hooks/useDeleteGroup";
import DeleteModal from "components/modal/deleteModal";

const ExistingGroup = ({
  group,
  index,
  nodeData,
  entityId,
  individualGroupData,
  setShowCardDetails,
  traceableDevices,
  setTraceableDevice,
  setIndividualGroupData,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isDelModalOpen,
    onOpen: onDelModalOpen,
    onClose: onDelModalClose,
  } = useDisclosure();
  const [edit, setEdit] = useState(false);
  const [groupName, setGroupName] = useState(group.name);
  const [deleteId, setDeleteId] = useState(null);
  const outsideRef = useRef();
  const { mutate: updateGroup } = useUpdateGroup(group.id);
  const { mutate: deleteGroup } = useDeleteGroup(onDelModalClose);
  const {
    isOpen: isNodeOpen,
    onOpen: onNodeOpen,
    onClose: onNodeClose,
  } = useDisclosure();
  const {
    isOpen: isCopyOpen,
    onOpen: onCopyOpen,
    onClose: onCopyClose,
  } = useDisclosure();

  const handleClickOutside = (e) => {
    if (!outsideRef.current.contains(e.target)) {
      setEdit(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });
  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      setEdit(false);
      updateGroup({ name: groupName, order: group.order, entity_id: entityId });
    }
  };
  const handleDeleteGroup = () => {
    onDelModalOpen();
    setDeleteId(group.id);
  };

  const checkForDeviceNumber = (nodeName) => {
    const nodeVal = group?.node || group?.untraceable_node;

    if (nodeVal?.length > 0) {
      let count = 0;
      nodeVal.map((item, key) => {
        if (item?.node_type_id === nodeName?.id) {
          count = count + 1;
        }
      });
      return count;
    }
  };
  const handleCardDetails = () => {
    setIndividualGroupData(group);
    setShowCardDetails(true);
  };
  return (
    <>
      <Card
        width="30%"
        mr="20px"
        mb="20px"
        style={{
          paddingTop: "5px",
          paddingRight: "0px",
          boxShadow: "0px 12px 30px rgba(112, 144, 176, 0.12)",
          borderRadius: "15px",
          cursor: "pointer",
        }}
      >
        <Flex direction="column">
          <Flex align="center" justify="space-between">
            <Tooltip hasArrow label="Rename" bg="#2B3674">
              <div onClick={() => setEdit(true)} ref={outsideRef}>
                {edit ? (
                  <form onKeyDown={onKeyDownHandler}>
                    <Input
                      type="text"
                      id="fname"
                      name="fname"
                      size="sm"
                      marginRight="10px"
                      value={groupName}
                      onChange={(event) => setGroupName(event.target.value)}
                    />
                    {/* <input type="text" id="fname" name="fname" value={groupName} onChange={(event) => setGroupName(event.target.value)} /> */}
                  </form>
                ) : (
                  <Text
                    fontSize={"15px"}
                    marginRight="10px"
                    color="#2B3674"
                    fontWeight="bold"
                  >
                    {groupName}
                  </Text>
                )}
              </div>
            </Tooltip>

            <Flex align="center">
              <Button
                w="35px"
                h="30px"
                borderRadius="7px"
                ml="20px"
                mr="2px"
                onClick={handleCardDetails}
              >
                {" "}
                <Icon color="secondaryGray.500" as={MdEdit} w="17px" h="17px" />
              </Button>
              <Button
                w="35px"
                h="30px"
                bg="#F4F7FE"
                borderRadius="7px"
                mr="2px"
                onClick={() => onNodeOpen()}
              >
                {" "}
                <Icon as={AddIcon} w={3} h={3} color="#4318FF" />
              </Button>
              <Menu>
                <MenuButton
                  _hover={{ bg: "white" }}
                  _expanded={{ bg: "white" }}
                  width="3px"
                  as={IconButton}
                  icon={<AddGroupMenu />}
                />
                <MenuList>
                  {/* <MenuItem onClick={() => onUseModalOpen()}>Use Template</MenuItem> */}
                  <MenuItem onClick={() => onOpen()}>Save as template</MenuItem>
                  <MenuItem onClick={handleDeleteGroup}>Delete Group</MenuItem>
                  <MenuItem onClick={() => onCopyOpen()}>Copy Group</MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          </Flex>
          <Flex pt="10px" wrap="wrap">
            {nodeData?.map((node, index) => (
              <>
                {checkForDeviceNumber(node) > 0 ? (
                  <Flex mr="10px" align="center">
                    {/* <GrNetworkDrive color="red" /> */}
                    <Icon
                      color="secondaryGray.500"
                      as={GrNetworkDrive}
                      w="17px"
                      h="17px"
                      opacity="0.4"
                    />
                    <Box pl="5px">
                      <Text fontSize={"12px"} color="#8694BF" pl="2px">
                        {`${node?.name} : ${checkForDeviceNumber(node)}`}
                      </Text>
                    </Box>
                  </Flex>
                ) : null}
              </>
            ))}
          </Flex>
        </Flex>
      </Card>
      <CreateTemplateModal
        isOpen={isOpen}
        group={group}
        onOpen={onOpen}
        onClose={onClose}
        entityId={entityId}
      />
      <CopyGroupModal
        isOpen={isCopyOpen}
        group={group}
        onOpen={onCopyOpen}
        onClose={onCopyClose}
        entityId={entityId}
      />
      <NodeModal
        isOpen={isNodeOpen}
        onOpen={onNodeOpen}
        onClose={onNodeClose}
        data={nodeData}
        nodeTypeName={"mi"}
        traceableDevices={traceableDevices}
        setTraceableDevice={setTraceableDevice}
        groupData={group}
        groupId={group?.id}
        entityId={entityId}
        setShowCreateGroup={false}
      />
      <DeleteModal
        onOpen={onDelModalOpen}
        isOpen={isDelModalOpen}
        onClose={onDelModalClose}
        id={deleteId}
        deleteItem={deleteGroup}
      />
    </>
  );
};

export default ExistingGroup;
