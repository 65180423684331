import { Box } from "@chakra-ui/layout";
import EmptyPieChart from "components/charts/emptyPieChart";
import React from "react";
import Chart from "react-apexcharts";

function ApexChart(props) {
  const { upTime } = props;
  const colorUptime = "#3DCA78";
  const colorTotal = "#EAEDF2";
 
  const pieChartOptions = {
    labels: [
      "Uptime",
    ],
    colors: [colorUptime, colorTotal],
    chart: {
      width: "150px",
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    hover: { mode: null },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "22px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              color: undefined,
              offsetY: -10,
              formatter: function (val) {
                return val;
              },
            },
            value: {
              show: true,
              fontSize: "16px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
              color: undefined,
              offsetY: 16,
              formatter: function (val) {
                return val;
              },
            },
            total: {
              show: true,
              label: "Uptime (%)",
              color: "#2B3674",
              fontSize: "14px",
              formatter: function () {
                return upTime;
              },
            },
          },
        },
      },
    },

    series: [
      upTime, 100
    ],
    fill: {
      colors: [colorUptime, colorTotal],
    },
    tooltip: {
      enabled: true,
      theme: "dark",
    },
  };

  const pieChartData = [
    parseInt(upTime),
    100-parseInt(upTime),
  ];

  return (
    <Box>
      {upTime === 0 ? (
        <EmptyPieChart />
      ) : (
        <Chart
          height="220px"
          w="100%"
          type="donut"
          series={pieChartData}
          options={pieChartOptions}
        />
      )}
    </Box>
  );
}

export default ApexChart;
