import React from "react";
import Card from "components/card/Card.js";
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Select,
  Input,
  Text,
  Icon,
  useColorModeValue,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import { STATE_NAMES } from "constants/globalConstants";
import useEditEntity from "hooks/entityList/useEditEntity";
import useSavedForm from "hooks/useSavedForm";
import { ArrowBackIcon } from "@chakra-ui/icons";
import EditDynamicForm from "./editDynamicForm";

function EditEntityForm({ setShowEditForm, editData }) {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  let activeColor = useColorModeValue("gray.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const buttonHover = useColorModeValue("brand.600", "brand.400");
  const {
    isLoading: isSaveLoading,
    error: saveError,
    data: savedFormData,
  } = useSavedForm();
  // const { isLoading, error, data } = useIndividualEntity(id);
  const { mutate: editEntity, isLoading: isEditLoading } = useEditEntity(
    setShowEditForm,
    editData.id
  );

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const generateStateOptions = () => {
    return STATE_NAMES?.map((state) => {
      return <option value={state.value}>{state.label}</option>;
    });
  };

  const getMultipleSelectValues = (array) => {
    var result = [];
    for (var i = 0; i < array.length; i += 1) {
      result.push(array[i]["value"]);
    }
    return result;
  };

  const onSubmit = (data) => {
    let metaData = [];
    if (data?.meta?.length > 0) {
      data?.meta?.map((data) => {
        Object.keys(data).forEach((key) => {
          metaData.push({
            label: key,
            value: Array.isArray(data[key])
              ? getMultipleSelectValues(data[key])
              : data[key],
            entity_field_id: savedFormData.data.filter(
              (formData) => formData.label === key
            )[0].id,
            type: savedFormData.data.filter(
              (formData) => formData.label === key
            )[0].type,
          });
        });
      });
    }
    editEntity({
      name: data.name,
      slug: data.slug,
      Address: {
        long: data.long,
        lat: data.lat,
        city: data.city,
        pincode: data.pincode,
        state: data.state,
      },
      meta: metaData,
    });
  };

  return (
    <Box w="100%" pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card>
        <Flex
          maxW={{ base: "100%" }}
          w="100%"
          mx={{ base: "auto", lg: "0px" }}
          me="auto"
          h="100%"
          alignItems="start"
          justifyContent="center"
          mb={{ base: "30px", md: "30px" }}
          px={{ base: "25px", md: "0px" }}
          mt={{ base: "20px", md: "20px" }}
          flexDirection="column"
        >
          <Flex alignItems="center" mb="24px" ms="4px">
            <Box
              onClick={() => setShowEditForm(false)}
              cursor="pointer"
              pr="10px"
            >
              <Icon as={ArrowBackIcon} w={6} h={6} />
            </Box>
            <Box me="auto">
              <Text color={activeColor} fontWeight="bold" fontSize="24px">
                Edit Entity
              </Text>
            </Box>
          </Flex>
          <Flex
            direction="column"
            w="100%"
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: "auto", lg: "unset" }}
            me="auto"
            mb={{ base: "20px", md: "auto" }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Flex w="100%">
                <Flex direction="column" width="100%" pl="10px">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Name<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <div style={{ marginBottom: "24px" }}>
                    <Controller
                      name="name"
                      rules={{
                        required: true,
                        maxLength: 50,
                        validate: (value) => {
                          return !!value.trim();
                        },
                      }}
                      control={control}
                      defaultValue={editData?.name || ""}
                      render={({ field }) => (
                        <Input
                          fontSize="sm"
                          ms={{ base: "0px", md: "0px" }}
                          type="name"
                          placeholder="Enter your name"
                          //mb="24px"
                          fontWeight="500"
                          size="lg"
                          {...field}
                        />
                      )}
                    />
                    {errors.name?.type === "required" && (
                      <span style={{ color: "red" }}>name is required</span>
                    )}
                    {errors.name?.type === "validate" && (
                      <span style={{ color: "red" }}>name is required</span>
                    )}
                    {errors.name?.type === "maxLength" && (
                      <span style={{ color: "red" }}>
                        max length exceeded which is 50
                      </span>
                    )}
                  </div>
                </Flex>
                <Flex direction="column" width="100%" pl="10px">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Slug<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <div style={{ marginBottom: "24px" }}>
                    <Controller
                      name="slug"
                      rules={{
                        required: true,
                        maxLength: 50,
                        validate: (value) => {
                          return !!value.trim();
                        },
                      }}
                      control={control}
                      defaultValue={editData?.slug || ""}
                      render={({ field }) => (
                        <Input
                          fontSize="sm"
                          ms={{ base: "0px", md: "0px" }}
                          type="name"
                          placeholder="Enter slug"
                          disabled={true}
                          //mb="24px"
                          fontWeight="500"
                          size="lg"
                          {...field}
                        />
                      )}
                    />
                    {errors.slug?.type === "required" && (
                      <span style={{ color: "red" }}>slug is required</span>
                    )}
                    {errors.slug?.type === "validate" && (
                      <span style={{ color: "red" }}>slug is required</span>
                    )}
                    {errors.slug?.type === "maxLength" && (
                      <span style={{ color: "red" }}>
                        max length exceeded which is 50
                      </span>
                    )}
                  </div>
                </Flex>
              </Flex>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="lg"
                fontWeight="bold"
                color={textColor}
                mt="8px"
                mb="16px"
                ml="10px"
              >
                Address
              </FormLabel>
              <Flex w="100%">
                <Flex direction="column" width="100%" pl="10px">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Latitude
                  </FormLabel>
                  <div style={{ marginBottom: "24px" }}>
                    <Controller
                      name="lat"
                      rules={{ required: false }}
                      control={control}
                      defaultValue={editData?.Address?.lat || ""}
                      render={({ field }) => (
                        <Input
                          fontSize="sm"
                          ms={{ base: "0px", md: "0px" }}
                          type="name"
                          placeholder="Enter Latitude"
                          //mb="24px"
                          fontWeight="500"
                          size="lg"
                          {...field}
                        />
                      )}
                    />
                  </div>
                </Flex>
                <Flex direction="column" width="100%" pl="10px">
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    isRequired={false}
                    display="flex"
                  >
                    Longitude
                  </FormLabel>
                  <div style={{ marginBottom: "24px" }}>
                    <Controller
                      name="long"
                      rules={{ required: false }}
                      control={control}
                      defaultValue={editData?.Address?.long || ""}
                      render={({ field }) => (
                        <Input
                          fontSize="sm"
                          placeholder="Enter Longitude"
                          size="lg"
                          type="name"
                          {...field}
                        />
                      )}
                    />
                  </div>
                </Flex>
              </Flex>
              <Flex w="100%">
                <Flex direction="column" width="100%" pl="10px">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    City<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <div style={{ marginBottom: "24px" }}>
                    <Controller
                      name="city"
                      rules={{
                        required: true,
                        maxLength: 50,
                        validate: (value) => {
                          return !!value.trim();
                        },
                      }}
                      control={control}
                      defaultValue={editData?.Address?.city || ""}
                      render={({ field }) => (
                        <Input
                          fontSize="sm"
                          ms={{ base: "0px", md: "0px" }}
                          type="name"
                          placeholder="Enter your City"
                          //mb="24px"
                          fontWeight="500"
                          size="lg"
                          {...field}
                        />
                      )}
                    />
                    {errors.city?.type === "required" && (
                      <span style={{ color: "red" }}>city is required</span>
                    )}
                    {errors.city?.type === "validate" && (
                      <span style={{ color: "red" }}>city is required</span>
                    )}
                    {errors.pincode?.type === "maxLength" && (
                      <span style={{ color: "red" }}>
                        max length exceeded which is 50
                      </span>
                    )}
                  </div>
                </Flex>
                <Flex direction="column" width="100%" pl="10px">
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    isRequired={true}
                    display="flex"
                  >
                    State<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <div style={{ marginBottom: "24px" }}>
                    <Controller
                      name="state"
                      rules={{ required: true }}
                      control={control}
                      defaultValue={editData?.Address?.state}
                      render={({ field }) => (
                        <Select
                          id="user_type"
                          size="lg"
                          placeholder="Select State"
                          {...field}
                          fontSize="16px"
                        >
                          {generateStateOptions()}
                        </Select>
                      )}
                    />
                    {errors.state?.type === "required" && (
                      <span style={{ color: "red" }}>state is required</span>
                    )}
                  </div>
                </Flex>
              </Flex>
              <Flex w="50%">
                <Flex direction="column" width="100%" pl="10px">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Pin Code<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <div style={{ marginBottom: "24px" }}>
                    <Controller
                      name="pincode"
                      rules={{ required: true, maxLength: 6 }}
                      control={control}
                      defaultValue={editData?.Address?.pincode || ""}
                      render={({ field }) => (
                        <Input
                          fontSize="sm"
                          ms={{ base: "0px", md: "0px" }}
                          type="number"
                          placeholder="Enter Pin Code"
                          //mb="24px"
                          fontWeight="500"
                          size="lg"
                          {...field}
                        />
                      )}
                    />
                    {errors.pincode?.type === "required" && (
                      <span style={{ color: "red" }}>Pin Code is required</span>
                    )}
                    {errors.pincode?.type === "maxLength" && (
                      <span style={{ color: "red" }}>
                        max length exceeded which is 6
                      </span>
                    )}
                  </div>
                </Flex>
              </Flex>
              {editData?.meta?.length > 0 ? (
                <EditDynamicForm
                  control={control}
                  errors={errors}
                  savedFormData={savedFormData}
                  apiData={editData?.meta}
                />
              ) : null}
              <Flex w="40%" pl="10px">
                <Button
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  w="90%"
                  h="50"
                  mb="24px"
                  mr="20px"
                  isLoading={isEditLoading}
                  type="submit"
                  _hover={{ bg: isEditLoading ? "blue" : buttonHover }}
                >
                  Submit
                </Button>
                <Button
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  w="90%"
                  h="50"
                  mb="24px"
                  onClick={() => setShowEditForm(false)}
                >
                  Cancel
                </Button>
              </Flex>
            </form>
          </Flex>
        </Flex>
      </Card>
    </Box>
  );
}

export default EditEntityForm;
