import { QUERY_KEY, MUTATION_KEY } from "constants/globalConstants";
import ApiService from "apiService";
import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";

const useCopyGroup = (onClose) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.COPY_RESOURCE],
    (data) => ApiService.copyResource(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.GET_GROUP);
        toast.success('Group copied successfully')
        onClose()
      },
      onError: (error) => {
        toast.error(error.response.data.message)
    },
    }
  );
};

export default useCopyGroup