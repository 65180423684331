import DashboardApiService from "apiService/dashboardApiService";
import { QUERY_KEY } from "constants/globalConstants";
import toast from "react-hot-toast";
import { useQuery } from "react-query";

const useTicketReportDetail = (
  ticketReportEnabled,
  setTicketReportEnabled,
  type_id,
  entityId = "",
  priority = "",
  startDate,
  endDate,
  jsonResponse = "0",
  setSlaPerformanceMetrics,
  setShowReport,
  onClose = false
) => {
  return useQuery(
    QUERY_KEY.GET_TICKET_REPORT,
    () =>
      DashboardApiService.getTicketReport(
        type_id,
        entityId,
        priority,
        startDate,
        endDate,
        jsonResponse
      ),
    {
      retry: false,
      enabled: ticketReportEnabled,
      onSuccess: (res) => {
        console.log(jsonResponse);
        if (jsonResponse === 1) {
          setSlaPerformanceMetrics(res);
          setShowReport(true);
          setTicketReportEnabled(false);
        } else {
          ticketReportEnabled &&
            window.open(res?.data, "_blank", "noopener,noreferrer");

          onClose();
        }
      },
      onError: (err) => {
        toast.error(err.response.data.message);
        setTicketReportEnabled(false);
      },
    }
  );
};

export default useTicketReportDetail;
