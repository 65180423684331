import { MUTATION_KEY } from "constants/globalConstants";
import { useMutation } from "react-query";
import toast from "react-hot-toast";
import ApiService from "apiService";

const useForgotPassword = (setShowConfirmPassword) => {
  return useMutation(
    [MUTATION_KEY.FORGOT_PASSWORD],
    (data) => ApiService.forgotPassword(data),
    {
      onSuccess: () => {
        toast.success('otp sent to your registered email address');
        setShowConfirmPassword(true)
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

export default useForgotPassword;