import React, { useState } from "react";
import Card from "components/card/Card.js";
import {
  Box,
  Button,
  Flex,
  FormLabel,
  InputRightElement,
  InputGroup,
  Input,
  Text,
  useDisclosure,
  useColorModeValue,
} from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";
import DatePicker from "react-datepicker";
import { useForm, Controller } from "react-hook-form";
import { Router, useHistory } from "react-router";
import useEditClient from "hooks/useEditClient";
import EntityClientHooks from "hooks/entityClient/entityClientHooks";

function EditClientList({ setShowEditForm, editData, entityId, entityName }) {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  let activeColor = useColorModeValue("gray.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const buttonHover = useColorModeValue("brand.600", "brand.400");
  const { mutate: editEntityClient, isLoading: isEditLoading } =
    EntityClientHooks.useUpdateEntityClient(setShowEditForm);
  const history = useHistory();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const dateIsValid = (date) => {
    return !Number.isNaN(new Date(date).getTime());
  };

  const getDefaultDate = (dateValue) => {
    return dateIsValid(new Date(dateValue)) ? new Date(dateValue) : "";
  };

  const onSubmit = (data) => {
    editEntityClient({
      name: data.name,
      version: data.version,
      type: data.type,
      installed_date: data.installed_date,
      installed_by: data.installed_by,
      installed_location: data.installed_location,
      entity_id: entityId,
      id: editData?.id,
    });
  };

  const handleCancel = () => {
    history.push(`/admin/entity-client-list/${entityId}/${entityName}`);
    setShowEditForm(false);
  };

  return (
    <>
      <Box w="100%" pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Card>
          <Flex
            maxW={{ base: "100%" }}
            w="100%"
            mx={{ base: "auto", lg: "0px" }}
            me="auto"
            h="100%"
            alignItems="start"
            justifyContent="center"
            mb={{ base: "30px", md: "30px" }}
            px={{ base: "25px", md: "0px" }}
            mt={{ base: "20px", md: "20px" }}
            flexDirection="column"
          >
            <Box me="auto">
              <Text
                mb="24px"
                ms="4px"
                color={activeColor}
                fontWeight="bold"
                fontSize="24px"
              >
                Edit Entity App
              </Text>
            </Box>
            <Flex
              direction="column"
              w="100%"
              maxW="100%"
              background="transparent"
              borderRadius="15px"
              mx={{ base: "auto", lg: "unset" }}
              me="auto"
              mb={{ base: "20px", md: "auto" }}
            >
              <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
                <Flex w="100%">
                  <Flex direction="column" width="100%" pl="10px">
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                    >
                      Name<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <div style={{ marginBottom: "24px" }}>
                      <Controller
                        name="name"
                        rules={{ required: true }}
                        control={control}
                        defaultValue={editData?.name}
                        render={({ field }) => (
                          <Input
                            fontSize="sm"
                            ms={{ base: "0px", md: "0px" }}
                            type="name"
                            placeholder="Enter your name"
                            //mb="24px"
                            fontWeight="500"
                            size="lg"
                            {...field}
                          />
                        )}
                      />
                      {errors.name?.type === "required" && (
                        <span style={{ color: "red" }}>name is required</span>
                      )}
                    </div>
                  </Flex>
                  <Flex direction="column" width="100%" pl="10px">
                    <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      display="flex"
                    >
                      Type
                    </FormLabel>
                    <div style={{ marginBottom: "24px" }}>
                      <Controller
                        name="type"
                        rules={{ required: false }}
                        control={control}
                        defaultValue={editData?.type}
                        render={({ field }) => (
                          <Input
                            fontSize="sm"
                            placeholder="Enter type"
                            size="lg"
                            type="name"
                            {...field}
                          />
                        )}
                      />
                      {errors.type?.type === "required" && (
                        <span style={{ color: "red" }}>type is required</span>
                      )}
                    </div>
                  </Flex>
                </Flex>
                <Flex w="100%">
                  <Flex direction="column" width="100%" pl="10px">
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                    >
                      Version
                    </FormLabel>
                    <div style={{ marginBottom: "24px" }}>
                      <Controller
                        name="version"
                        rules={{ required: false }}
                        control={control}
                        defaultValue={editData?.version}
                        render={({ field }) => (
                          <Input
                            fontSize="sm"
                            ms={{ base: "0px", md: "0px" }}
                            type="name"
                            placeholder="Enter version"
                            //mb="24px"
                            fontWeight="500"
                            size="lg"
                            {...field}
                          />
                        )}
                      />
                      {errors.version?.type === "required" && (
                        <span style={{ color: "red" }}>
                          version is required
                        </span>
                      )}
                    </div>
                  </Flex>
                  <Flex direction="column" width="100%" pl="10px">
                    <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      display="flex"
                    >
                      Installed Date
                    </FormLabel>
                    <div style={{ marginBottom: "24px" }}>
                      <Controller
                        name="installed_date"
                        rules={{ required: false }}
                        control={control}
                        defaultValue={getDefaultDate(editData?.installed_date)}
                        render={({ field }) => (
                          <DatePicker
                            wrapperClassName="date-picker"
                            customInput={
                              <InputGroup>
                                <Input
                                  fontSize="sm"
                                  placeholder="Select Date"
                                  size="lg"
                                  value={
                                    field.value
                                      ? field.value.toLocaleDateString()
                                      : ""
                                  }
                                />
                                <InputRightElement
                                  children={<CalendarIcon mt="5px" />}
                                />
                              </InputGroup>
                            }
                            onChange={(date) => field.onChange(date)}
                            selected={field.value}
                          />
                        )}
                      />
                      {errors.state?.type === "required" && (
                        <span style={{ color: "red" }}>state is required</span>
                      )}
                    </div>
                  </Flex>
                </Flex>
                <Flex w="100%">
                  <Flex direction="column" width="100%" pl="10px">
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                    >
                      Installed by
                    </FormLabel>
                    <div style={{ marginBottom: "24px" }}>
                      <Controller
                        name="installed_by"
                        rules={{ required: false }}
                        control={control}
                        defaultValue={editData?.installed_by}
                        render={({ field }) => (
                          <Input
                            fontSize="sm"
                            ms={{ base: "0px", md: "0px" }}
                            type="name"
                            placeholder="Person who installed client"
                            //mb="24px"
                            fontWeight="500"
                            size="lg"
                            {...field}
                          />
                        )}
                      />
                      {errors.installed_by?.type === "required" && (
                        <span style={{ color: "red" }}>name is required</span>
                      )}
                    </div>
                  </Flex>
                  <Flex direction="column" width="100%" pl="10px">
                    <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      display="flex"
                    >
                      Installed Location
                    </FormLabel>
                    <div style={{ marginBottom: "24px" }}>
                      <Controller
                        name="installed_location"
                        rules={{ required: false }}
                        control={control}
                        defaultValue={editData?.installed_location}
                        render={({ field }) => (
                          <Input
                            fontSize="sm"
                            placeholder="Enter Installed Location"
                            size="lg"
                            type="name"
                            {...field}
                          />
                        )}
                      />
                      {errors.email?.type === "required" && (
                        <span style={{ color: "red" }}>email is required</span>
                      )}
                    </div>
                  </Flex>
                </Flex>
                <Flex width="40%" pl="10px">
                  <Button
                    fontSize="sm"
                    variant="brand"
                    fontWeight="500"
                    w="90%"
                    h="50"
                    mb="24px"
                    mr="20px"
                    type="submit"
                    isLoading={isEditLoading}
                    _hover={{ bg: isEditLoading ? "blue" : buttonHover }}
                  >
                    Submit
                  </Button>
                  <Button
                    fontSize="sm"
                    variant="brand"
                    fontWeight="500"
                    w="90%"
                    h="50"
                    mb="24px"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Flex>
              </form>
            </Flex>
          </Flex>
        </Card>
      </Box>
    </>
  );
}

export default EditClientList;
