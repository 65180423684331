import React from "react";
import { NavLink } from "react-router-dom";
import Card from "components/card/Card.js";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
// Custom components
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import useLogin from "hooks/useLogin";

function SignIn() {
  const mainBranding = localStorage.getItem("main_branding");
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const textColorBrand = useColorModeValue("brand.500", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const buttonHover = useColorModeValue("brand.600", "brand.400");
  const { mutate: loginUser, isLoading } = useLogin();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const onSubmit = (data) => {
    loginUser({ email: data.email, password: data.password });
  };
  return (
    <Box
      bg="#2B3674"
      height="100vh"
      display="flex"
      justifyContent="center"
      alignItems="center"
      position="relative"
    >
      <Flex direction="column" alignItems="center">
        <Text fontSize="45px" color="white" fontWeight="bold">
          {mainBranding}
        </Text>
        <Text fontSize="20px" pb="20px" color="white" fontWeight="300">
          Network Device Monitoring System
        </Text>
        <Card width="450px" height="440px" borderRadius="none" boxShadow="10px">
          <Flex
            maxW={{ base: "100%", md: "max-content" }}
            w="100%"
            mx={{ base: "auto", lg: "0px" }}
            me="auto"
            h="100%"
            alignItems="start"
            justifyContent="center"
            mb={{ base: "30px", md: "60px" }}
            px={{ base: "25px", md: "0px" }}
            mt={{ base: "10px", md: "20px" }}
            flexDirection="column"
          >
            <Box me="auto" pb="20px">
              <Heading color={textColor} fontSize="36px">
                Log In
              </Heading>
            </Box>
            <Flex
              zIndex="2"
              direction="column"
              w={{ base: "100%", md: "420px" }}
              maxW="100%"
              background="transparent"
              borderRadius="15px"
              mx={{ base: "auto", lg: "unset" }}
              me="auto"
              mb={{ base: "10px", md: "auto" }}
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box mb="14px">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Email or User Name<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <Controller
                    name="email"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <Input
                        variant="auth"
                        fontSize="sm"
                        ms={{ base: "0px", md: "0px" }}
                        type="text"
                        placeholder="Enter email or user name"
                        mb="10px"
                        fontWeight="500"
                        size="lg"
                        {...field}
                      />
                    )}
                  />
                  {errors.email?.type === "required" && (
                    <span style={{ color: "red" }}>email is required</span>
                  )}
                </Box>
                <Box mb="14px">
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    isRequired={true}
                    display="flex"
                  >
                    Password<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <InputGroup size="md">
                    <Controller
                      name="password"
                      rules={{ required: true }}
                      control={control}
                      render={({ field }) => (
                        <Input
                          fontSize="sm"
                          placeholder="Min. 8 characters"
                          size="lg"
                          mb="10px"
                          type={show ? "text" : "password"}
                          variant="auth"
                          {...field}
                        />
                      )}
                    />

                    <InputRightElement
                      display="flex"
                      alignItems="center"
                      mt="4px"
                    >
                      <Icon
                        color={textColorSecondary}
                        _hover={{ cursor: "pointer" }}
                        as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                        onClick={handleClick}
                      />
                    </InputRightElement>
                  </InputGroup>
                  {errors.password?.type === "required" && (
                    <span style={{ color: "red" }}>password is required</span>
                  )}
                </Box>
                <Flex justifyContent="flex-end" align="center" mb="24px">
                  <NavLink to="/auth/forgot-password">
                    <Text
                      color={textColorBrand}
                      fontSize="sm"
                      w="124px"
                      fontWeight="500"
                    >
                      Forgot password?
                    </Text>
                  </NavLink>
                </Flex>
                <Button
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  w="100%"
                  h="50"
                  //mt="10px"
                  type="submit"
                  _hover={{ bg: isLoading ? "blue" : buttonHover }}
                  isLoading={isLoading}
                >
                  Log In
                </Button>
              </form>
            </Flex>
          </Flex>
        </Card>
      </Flex>
      <Text position="absolute" right={0} mr="20px" bottom={0} color="white">
        {/* Powered by Auriga IT */}
      </Text>
    </Box>
  );
}

export default SignIn;
