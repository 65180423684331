import React, { useState, useEffect } from "react";
import { Box, Text, Icon, Skeleton, Flex, Badge } from "@chakra-ui/react";
import { EditIcon } from "@chakra-ui/icons";
import Card from "components/card/Card.js";
import NodeDashboardTabs from "./nodeDashboardTabs";
import { useParams } from "react-router";
import useNodeDetails from "hooks/nodeDashboard/useNodeDetails";
import dayjs from "dayjs";

const NodeDashboard = () => {
  const entityId = useParams()?.id;
  const nodeId = useParams()?.nodeId;
  const {
    isLoading,
    error,
    data: nodeDashboardData,
    refetch,
  } = useNodeDetails(entityId, nodeId);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    refetch();
  }, [entityId, nodeId]);
  const [status, setStatus] = useState("online");

  if (isLoading) {
    return (
      <Box pt={{ base: "130px", md: "70px", xl: "70px" }} pb="50px">
        <Skeleton height="60px" width="200px" />
        <Card width="100%" mt="20px" height="max-content" mb="0px">
          <Flex mb="10px">
            <Text fontSize="16px" fontWeight="bold" color="#2B3674">
              General
            </Text>
            <Flex pl="25px" alignItems="center" cursor="pointer">
              <Icon as={EditIcon} width="17px" height="17px" color="#2500BC" />
              <Text pl="5px" fontSize="16px" fontWeight="bold" color="#2B3674">
                Edit
              </Text>
            </Flex>
          </Flex>
          <hr />
          <Skeleton height="200px" width="1000px" />
        </Card>
      </Box>
    );
  }

  return (
    <Box pt={{ base: "130px", md: "70px", xl: "70px" }} pb="50px">
      <Text fontSize="34px" fontWeight="bold" color="#2B3674">
        {nodeDashboardData?.data?.name}
      </Text>
      <Flex>
        {nodeDashboardData?.data?.status === "online" ? (
          <Badge
            border="1px solid #CEFAE5"
            borderRadius="16px"
            bg="#CEFAE5"
            color="#219653"
          >
            {nodeDashboardData?.data?.status}
          </Badge>
        ) : (
          <Badge
            border="1px solid white"
            borderRadius="16px"
            bg="red"
            color="white"
          >
            {nodeDashboardData?.data?.status}
          </Badge>
        )}
        {nodeDashboardData?.data?.last_sync ? (
          <Text color="#707EAE" pl="20px">
            {`last sycn
              ${dayjs(nodeDashboardData?.data?.last_sync).format("DD/MM/YYYY")},
              ${dayjs(nodeDashboardData?.data?.last_sync).format("hh:mm a")}`}
          </Text>
        ) : null}
      </Flex>
      <Card width="100%" mt="20px" height="max-content" mb="0px">
        <Flex mb="10px">
          <Text fontSize="16px" fontWeight="bold" color="#2B3674">
            General
          </Text>
          {/* <Flex pl="25px" alignItems="center" cursor="pointer">
            <Icon as={EditIcon} width="17px" height="17px" color="#2500BC" />
            <Text pl="5px" fontSize="16px" fontWeight="bold" color="#2B3674">
              Edit
            </Text>
          </Flex> */}
        </Flex>
        <hr />
        <Flex wrap="wrap" width="100%" mt="10px">
          <Flex direction="column" width="25%" py="10px">
            <Text fontSize="14px" fontWeight="500" color="#343B46">
              Name
            </Text>
            <Text fontSize="12px" fontWeight="500" color="#7B818A">
              {nodeDashboardData?.data?.name}
            </Text>
          </Flex>
          <Flex direction="column" width="25%" py="10px">
            <Text fontSize="14px" fontWeight="500" color="#343B46">
              Group Name
            </Text>
            <Text fontSize="12px" fontWeight="500" color="#7B818A">
              {nodeDashboardData?.data?.group_name}
            </Text>
          </Flex>
          <Flex direction="column" width="25%" py="10px">
            <Text fontSize="14px" fontWeight="500" color="#343B46">
              Longitude
            </Text>
            <Text fontSize="12px" fontWeight="500" color="#7B818A">
              {nodeDashboardData?.data?.entity?.Address?.long}
            </Text>
          </Flex>
          {/* <Flex direction="column" width="25%" py="10px">
            <Text fontSize="14px" fontWeight="500" color="#343B46">
              Installed at
            </Text>
            <Text fontSize="12px" fontWeight="500" color="#7B818A">
              12,July 2022, 12:02PM
            </Text>
          </Flex> */}
          <Flex direction="column" width="25%" py="10px">
            <Text fontSize="14px" fontWeight="500" color="#343B46">
              Type
            </Text>
            <Text fontSize="12px" fontWeight="500" color="#7B818A">
              {nodeDashboardData?.data?.node_type_name}
            </Text>
          </Flex>
          <Flex direction="column" width="25%" py="10px">
            <Text fontSize="14px" fontWeight="500" color="#343B46">
              Entity
            </Text>
            <Text fontSize="12px" fontWeight="500" color="#7B818A">
              {nodeDashboardData?.data?.entity?.name}
            </Text>
          </Flex>
          <Flex direction="column" width="25%" py="10px">
            <Text fontSize="14px" fontWeight="500" color="#343B46">
              Latitude
            </Text>
            <Text fontSize="12px" fontWeight="500" color="#7B818A">
              {nodeDashboardData?.data?.entity?.Address?.lat}
            </Text>
          </Flex>
          {/* <Flex direction="column" width="25%" py="10px">
            <Text fontSize="14px" fontWeight="500" color="#343B46">
              Hardware
            </Text>
            <Text fontSize="12px" fontWeight="500" color="#7B818A">
              HP
            </Text>
          </Flex> */}
          <Flex direction="column" width="25%" py="10px">
            <Text fontSize="14px" fontWeight="500" color="#343B46">
              IP Address
            </Text>
            <Text fontSize="12px" fontWeight="500" color="#7B818A">
              {nodeDashboardData?.data?.ip_address}
            </Text>
          </Flex>
          <Flex direction="column" width="25%" py="10px">
            <Text fontSize="14px" fontWeight="500" color="#343B46">
              Location
            </Text>
            <Text fontSize="12px" fontWeight="500" color="#7B818A">
              {nodeDashboardData?.data?.entity?.Address?.city}
            </Text>
          </Flex>
          <Flex direction="column" width="25%" py="10px">
            <Text fontSize="14px" fontWeight="500" color="#343B46">
              Address
            </Text>
            <Text fontSize="12px" fontWeight="500" color="#7B818A">
              {`
                ${nodeDashboardData?.data?.entity?.Address?.city},
                ${nodeDashboardData?.data?.entity?.Address?.state}`}
            </Text>
          </Flex>
        </Flex>
      </Card>
      <NodeDashboardTabs
        status={status}
        entityId={entityId}
        nodeName={nodeDashboardData?.data?.name}
        nodeId={nodeId}
        nodeTypeId={nodeDashboardData?.data?.node_type_id}
        nodeTypeName={nodeDashboardData?.data?.node_type_name}
      />
    </Box>
  );
};

export default NodeDashboard;
