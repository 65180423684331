import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Input,
  InputGroup,
  InputRightElement,
  Flex,
  FormLabel,
  Text,
  ModalCloseButton,
  Button,
  //Select,
  useColorModeValue,
  Select,
  Textarea,
} from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import ApproveDowntimeHooks from "hooks/approvedDowntime/approveDowntimeHooks";

function EditDowntimeApproveModal({
  isOpen,
  onClose,
  onOpen,
  breakupReasonData = {},
  isNodeLevel,
  editData,
}) {
  const {
    handleSubmit,
    control,
    resetField,
    formState: { errors },
  } = useForm();
  const textColor = useColorModeValue("navy.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const handleModalClose = () => {
    resetField("start_at");
    resetField("end_at");
    resetField("breakup_reason_id");
    resetField("notes");
    onClose();
  };

  const { mutate: updateNopd, isLoading } =
    ApproveDowntimeHooks.useUpdateNopdBreakupList(handleModalClose);

  const { mutate: updateEntityNopd, isLoading: isEntityNopdUploadLoading } =
    ApproveDowntimeHooks.useUpdateEntityNopdBreakupList(handleModalClose);

  const generateStateOptions = () => {
    return breakupReasonData?.map((state) => {
      return <option value={state.id}>{state.breakup_type}</option>;
    });
  };

  const onSubmit = (data) => {
    if (isNodeLevel) {
      updateNopd({
        id: editData?.id,
        downtime_range_id: editData?.downtime_range_id,
        notes: data.notes ? data.notes : editData?.notes,
        breakup_reason_id: data.breakup_reason_id,
        start_at: data.start_at
          ? dayjs(data.start_at).format("YYYY-MM-DD HH:mm")
          : editData?.start_at,
        end_at: data.end_at
          ? dayjs(data.end_at).format("YYYY-MM-DD HH:mm")
          : editData?.end_at,
      });
    } else {
      updateEntityNopd({
        entity_id: editData?.entity_id,
        entity_breakup_id: editData?.id,
        breakup_reason_id: data.breakup_reason_id,
        notes: data.notes ? data.notes : editData?.notes,
        start_at: data.start_at
          ? dayjs(data.start_at).format("YYYY-MM-DD HH:mm")
          : editData?.start_at,
        end_at: data.end_at
          ? dayjs(data.end_at).format("YYYY-MM-DD HH:mm")
          : editData?.end_at,
      });
    }
  };

  const dateIsValid = (date) => {
    return !Number.isNaN(new Date(date).getTime());
  };

  const getDefaultDate = (dateValue) => {
    return dateIsValid(new Date(dateValue)) ? new Date(dateValue) : "";
  };

  const onError = (errors) => {
    console.log("errors", errors);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        blockScrollOnMount={false}
        onClose={handleModalClose}
        size="lg"
      >
        <ModalOverlay />
        <form onSubmit={handleSubmit(onSubmit, onError(errors))}>
          <ModalContent>
            <ModalHeader>
              <Flex direction="column" w="100%" mt="15px">
                <Flex direction="column" width="100%">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Start Date<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <div style={{ marginBottom: "10px" }}>
                    <Controller
                      name={`start_at`}
                      rules={{ required: false }}
                      control={control}
                      defaultValue={getDefaultDate(editData?.start_at)}
                      render={({ field }) => (
                        <DatePicker
                          wrapperClassName="date-picker"
                          showTimeInput
                          timeInputLabel="Time:"
                          //selectMultiple={true}
                          customInput={
                            <InputGroup>
                              <Input
                                minWidth="300px"
                                placeholder="From"
                                value={
                                  field.value
                                    ? `${field?.value?.toLocaleDateString()}, ${field.value.toLocaleTimeString(
                                        "en-US"
                                      )}`
                                    : ""
                                }
                              />
                              <InputRightElement
                                children={<CalendarIcon color="#4318FF" />}
                              />
                            </InputGroup>
                          }
                          onChange={(date) => {
                            field.onChange(date);
                          }}
                          selected={field.value}
                          minDate={
                            editData?.range_start_at !== "0"
                              ? new Date(editData?.range_start_at)
                              : new Date()
                          }
                          maxDate={
                            editData?.range_end_at !== "0"
                              ? new Date(editData?.range_end_at)
                              : new Date()
                          }
                          dateFormat="MMMM d, yyyy h:mm aa"
                        />
                      )}
                    />
                    {errors.starts_at?.type === "required" && (
                      <span style={{ color: "red" }}>
                        start date is required
                      </span>
                    )}
                  </div>
                </Flex>
                <Flex direction="column" width="100%" pt="10px">
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    End Date<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <div style={{ marginBottom: "10px" }}>
                    <Controller
                      name={`end_at`}
                      rules={{ required: false }}
                      control={control}
                      defaultValue={getDefaultDate(editData?.end_at)}
                      render={({ field }) => (
                        <DatePicker
                          wrapperClassName="date-picker"
                          showTimeInput
                          timeInputLabel="Time:"
                          selectMultiple={true}
                          customInput={
                            <InputGroup>
                              <Input
                                minWidth="300px"
                                placeholder="To"
                                value={
                                  field.value
                                    ? `${field?.value?.toLocaleDateString()}, ${field.value.toLocaleTimeString(
                                        "en-US"
                                      )}`
                                    : ""
                                }
                              />
                              <InputRightElement
                                children={<CalendarIcon color="#4318FF" />}
                              />
                            </InputGroup>
                          }
                          onChange={(date) => {
                            field.onChange(date);
                          }}
                          selected={field.value}
                          minDate={
                            editData?.range_start_at !== "0"
                              ? new Date(editData?.range_start_at)
                              : new Date()
                          }
                          maxDate={
                            editData?.range_end_at !== "0"
                              ? new Date(editData?.range_end_at)
                              : new Date()
                          }
                          dateFormat="MMMM d, yyyy h:mm aa"
                        />
                      )}
                    />
                    {errors.ends_at?.type === "required" && (
                      <span style={{ color: "red" }}>end date is required</span>
                    )}
                  </div>
                </Flex>
                <Flex direction="column" width="100%" pt="10px">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="14px"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Approved Downtime Reason<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <div style={{ marginBottom: "10px" }}>
                    <Controller
                      name="breakup_reason_id"
                      rules={{ required: true }}
                      control={control}
                      render={({ field }) => (
                        <Select
                          id="breakup_reason_id"
                          size="lg"
                          defaultValue={editData?.breakup_reason?.id}
                          fontSize="14px"
                          color={
                            field.value === "Reason" ||
                            field.value === null ||
                            field.value === undefined
                              ? "ndmsColors.gray.2200"
                              : "black"
                          }
                          fontWeight={
                            field.value === "Reason" ||
                            field.value === null ||
                            field.value === undefined
                              ? "bold"
                              : "normal"
                          }
                          {...field}
                        >
                          <option
                            selected
                            disabled
                            hidden
                            value={null}
                          ></option>
                          {generateStateOptions()}
                        </Select>
                      )}
                    />
                    {errors.user_id?.type === "required" && (
                      <span style={{ color: "red" }}>
                        Please select approved downtime reason
                      </span>
                    )}
                  </div>
                </Flex>
                <Flex direction="column" width="100%" pt="10px">
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Notes
                  </FormLabel>
                  <div style={{ marginBottom: "24px" }}>
                    <Controller
                      name="notes"
                      control={control}
                      rules={{ required: false }}
                      defaultValue={editData?.notes || ""}
                      render={({ field }) => (
                        <Textarea
                          fontSize="sm"
                          placeholder="Enter Notes"
                          size="lg"
                          type="name"
                          {...field}
                        />
                      )}
                    />
                  </div>
                </Flex>
              </Flex>
            </ModalHeader>
            <Button
              bg="#4318FF"
              _hover={{ bg: "#2B3674" }}
              mx={3}
              mt={2}
              mb={10}
              type="submit"
              isLoading={isLoading || isEntityNopdUploadLoading}
            >
              <Text color={"#FFFFFF"}>Save</Text>
            </Button>
            <ModalCloseButton />
          </ModalContent>
        </form>
      </Modal>
    </>
  );
}

export default EditDowntimeApproveModal;
