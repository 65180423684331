export const QUERY_KEY = {
  GET_ELITE_LIST: "GET_ELITE_LIST",
  GET_CLIENT_LIST: "GET_CLIENT_LIST",
  GET_BREAKUP_REASON_LIST: "GET_BREAKUP_REASON_LIST",
  GET_UNTRACABLE_NODE_TYPE_LIST: "GET_UNTRACABLE_NODE_TYPE_LIST",
  GET_INDIVIDUAL_ENTITY: "GET_INDIVIDUAL_ENTITY",
  GET_GROUP: "GET_GROUP",
  GET_NODE_TYPE: "GET_NODE_TYPE",
  GET_NODE: "GET_NODE",
  GET_TEMPLATE: "GET_TEMPLATE",
  GET_SAVED_FORM: "GET_SAVED_FORM",
  GET_NODE_FILTER: "GET_NODE_FILTER",
  GET_NODE_ENTITY_FILTER: "GET_NODE_ENTITY_FILTER",
  GET_NODE_TABLE_DATA: "GET_NODE_TABLE_DATA",
  GET_TICKET_REPORT: "GET_TICKET_REPORT",
  GET_SCHEDULE_DOWNTIME: "GET_SCHEDULE_DOWNTIME",
  GET_SCHEDULE_DOWNTIME_FOR_SINGLE_DEVICE:
    "GET_SCHEDULE_DOWNTIME_FOR_SINGLE_DEVICE",
  GET_GROUP_LIST: "GET_GROUP_LIST",
  GET_ENTITY_DASHBOARD_LIST: "GET_ENTITY_DASHBOARD_LIST",
  GET_ENTITY_DASHBOARD_CARD: "GET_ENTITY_DASHBOARD_CARD",
  GET_ROLES: "GET_ROLES",
  GET_USER_LIST: "GET_USER_LIST",
  GET_PERMISSIONS: "GET_PERMISSIONS",
  GET_LIVE_DASHBOARD_NODE_DATA: " GET_LIVE_DASHBOARD_NODE_DATA",
  GET_ENTITY_NODE_FILTER: "GET_ENTITY_NODE_FILTER",
  NODE_DETAILS: "NODE_DETAILS",
  NODE_DONUT_DETAILS: "NODE_DONUT_DETAILS",
  NODE_BAR_DETAILS: "NODE_BAR_DETAILS",
  GET_PERMISSION_GROUP: "GET_PERMISSION_GROUP",
  GET_EXPORT: "GET_EXPORT",
  GET_TICKET_USER_LIST: "GET_TICKET_USER_LIST",
  GET_GROUP_WISE_PERMISSION: "GET_GROUP_WISE_PERMISSION",
  GET_CLIENT_NODES_WORKING: "GET_CLIENT_NODES_WORKING",
  GET_CLIENT_PERCENTAGE_UPTIME: "GET_CLIENT_PERCENTAGE_UPTIME",
  GET_CLIENT_DOWNTIME: "GET_CLIENT_DOWNTIME",
  GET_CLIENT_BREAKDOWN: "GET_CLIENT_BREAKDOWN",
  GET_MTTR: "GET_MTTR",
  GET_MTBF: "GET_MTBF",
  GET_ALL_ELITE_LIST: "GET_ALL_ELITE_LIST",
  GET_NEW_PERMISSIONS: "GET_NEW_PERMISSIONS",
  GET_ENTITY_LIST_NAME: "GET_ENTITY_LIST_NAME",
  GET_ENTITY_CLIENT_LIST: "GET_ENTITY_CLIENT_LIST",
  GET_SCHEDULE_DOWNTIME_LIST: "GET_SCHEDULE_DOWNTIME_LIST",
  GET_ENTITY_DASHBOARD_DETAILS: "GET_ENTITY_DASHBOARD_DETAILS",
  GET_ENTITY_META_DATA: "GET_ENTITY_META_DATA",
  GET_ENTITY_NODE_TYPE_PERFORMANCE: "GET_ENTITY_NODE_TYPE_PERFORMANCE",
  GET_ENTITY_GROUP_PERFORMANCE: "GET_ENTITY_GROUP_PERFORMANCE",
  GET_ENTITY_NODE_PERFORMANCE: "GET_ENTITY_NODE_PERFORMANCE",
  NODE_LOG_DETAILS: "NODE_LOG_DETAILS",
  NODE_MTTR_MTBF_DETAILS: "NODE_MTTR_MTBF_DETAILS",
  GET_NODE_TYPE_ID: "GET_NODE_TYPE_ID",
  EXPORT_NODE: "EXPORT_NODE",
  GET_NODE_TYPE_LIST: "GET_NODE_TYPE_LIST",
  GET_TIME_LOG: "GET_TIME_LOG",
  GET_NOPD_BREAKUP_LIST: "GET_NOPD_BREAKUP_LIST",
  GET_ENTITY_NOPD_BREAKUP_LIST: "GET_ENTITY_NOPD_BREAKUP_LIST",
  GET_SLA_REPORT: "GET_SLA_REPORT",
  GET_SCHEDULE_DOWNTIME_REPORT: "GET_SCHEDULE_DOWNTIME_REPORT",
  GET_BLACKOUT_REPORT: "GET_BLACKOUT_REPORT",
  GET_MASTER_CONFIG_LIST: "GET_MASTER_CONFIG_LIST",
  GET_CLIENT_CONFIG_LIST: "GET_CLIENT_CONFIG_LIST",
  GET_DOWNLOAD_INSTALL_CLIENT: "GET_DOWNLOAD_INSTALL_CLIENT",
  GET_CSV_REPORT: "GET_CSV_REPORT",
  GET_TICKET_STATUS: "GET_TICKET_STATUS",
  GET_SCHEDULE_CSV_REPORT: "GET_SCHEDULE_CSV_REPORT",
  GET_BLACKOUT_CSV_REPORT: "GET_BLACKOUT_CSV_REPORT",
  GET_TICKET_TYPES: "GET_TICKET_TYPES",
  GET_TICKET_DETAILS: "GET_TICKET_DETAILS",
  GET_TICKET_COUNT: "GET_TICKET_COUNT",
  GET_TICKET_TASK_COUNT: "GET_TICKET_TASK_COUNT",
  GET_TICKET_COMMENTS: "GET_TICKET_COMMENTS",
  GET_NODE_LIST: "GET_NODE_LIST",
  GET_TICKET_MEMBER_LIST: "GET_TICKET_MEMBER_LIST",
  GET_TICKET_TRENDS: "GET_TICKET_TRENDS",
  GET_TYPE_LIST: "GET_TYPE_LIST",
  GET_TICKET_TASKS: "GET_TICKET_TASKS",
  GET_TICKET_CREATED_BY_LIST: "GET_TICKET_CREATED_BY_LIST",
  GET_TICKET_CATEGORY: "GET_TICKET_CATEGORY",
  GET_TICKET_TABS: "GET_TICKET_TABS",
  GET_TICKET_REASON: "GET_TICKET_REASON",
  GET_TICKET_STATUS_CLOSED: "GET_TICKET_STATUS_CLOSED",
  GET_ALL_TICKET_STATUS: "GET_ALL_TICKET_STATUS",
  GET_TYPE_LIST_WITH_ID: "GET_TYPE_LIST_WITH_ID",
  GET_TICKET_TYPES_FOR_NODE: "GET_TICKET_TYPES_FOR_NODE",
  GET_ALL_TICKET_STATUS_WITH_ID: "GET_ALL_TICKET_STATUS_WITH_ID",
  GET_TICKET_REASON_WITH_ID: "GET_TICKET_REASON_WITH_ID",
  GET_TICKET_TAB_WITH_ID: "GET_TICKET_TAB_WITH_ID",
  GET_TICKET_TABS_CRUD: "GET_TICKET_TABS_CRUD",
};

export const MUTATION_KEY = {
  ADD_CLIENT: "ADD_CLIENT",
  DELETE_CLIENT: "DELETE_CLIENT",
  ADD_GROUP: "ADD_GROUP",
  UPDATE_GROUP: "UPDATE_GROUP",
  UPDATE_NODE: "UPDATE_NODE",
  DELETE_ENTITY: "DELETE_ENTITY",
  DELETE_NODE_TYPE: "DELETE_NODE_TYPE",
  ADD_ENTITY: "ADD_ENTITY",
  ADD_NODE_TYPE: "ADD_NODE_TYPE",
  EDIT_NODE_TYPE: "EDIT_NODE_TYPE",
  EDIT_CLIENT: "EDIT_CLIENT",
  DELETE_GROUP: "DELETE_GROUP",
  ADD_NODE: "ADD_NODE",
  EDIT_ENTITY: "EDIT_ENTITY",
  CREATE_USER: "CREATE_USER",
  LOGIN_USER: "LOGIN_USER",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  CONFIRM_PASSWORD: "CONFIRM_PASSWORD",
  ADD_TEMPLATE: "ADD_TEMPLATE",
  COPY_RESOURCE: "COPY_RESOURCE",
  DELETE_TEMPLATE: "DELETE_TEMPLATE",
  UPDATE_TEMPLATE: "UPDATE_TEMPLATE",
  UPDATE_TEMPLATE_NODE: "UPDATE_TEMPLATE",
  ADD_TEMPLATE_NODE: "ADD_TEMPLATE_NODE",
  DELETE_TEMPLATE_NODE: "DELETE_TEMPLATE_NODE",
  SAVE_FORM: "SAVE_FORM",
  DELETE_FORM: "DELETE_FORM",
  UPDATE_FORM: "UPDATE_FORM",
  SCHEDULE_DOWNTIME: "SCHEDULE_DOWNTIME",
  EDIT_SCHEDULE_DOWNTIME: "EDIT_SCHEDULE_DOWNTIME",
  IMPORT_NODE: "IMPORT_NODE",
  CREATE_ROLE: "CREATE_ROLE",
  DELETE_ROLE: "DELETE_ROLE",
  TICKET_COMPLETE: "TICKET_COMPLETE",
  TICKET_CANCEL: "TICKET_CANCEL",
  EDIT_TICKET_REASON: "EDIT_TICKET_REASON",
  EDIT_TICKET_TAB: "EDIT_TICKET_TAB",
  UPLOAD_IMAGE: "UPLOAD_IMAGE",
  UPDATE_ROLE: "UPDATE_ROLE",
  UPDATE_USER: "UPDATE_USER",
  UPDATE_PERMISSION: "UPDATE_PERMISSION",
  DELETE_PERMISSION: "DELETE_PERMISSION",
  DELETE_USER: "DELETE_USER",
  GENERATE_LICENSE: "GENERATE_LICENSE",
  UPDATE_GROUP_PERMISSION: "UPDATE_GROUP_PERMISSION",
  CREATE_GROUP_PERMISSION: "CREATE_GROUP_PERMISSION",
  DELETE_GROUP_PERMISSION: "DELETE_GROUP_PERMISSION",
  DELETE_ENTITY_CLIENT: "DELETE_ENTITY_CLIENT",
  CREATE_ENTITY_CLIENT: "CREATE_ENTITY_CLIENT",
  CREATE_SLA_REPORT: "CREATE_SLA_REPORT",
  CREATE_CSV_REPORT: "CREATE_CSV_REPORT",
  SCHEDULE_DETAIL: "SCHEDULE_DETAIL",
  BLACKOUT_DETAIL: "BLACKOUT_DETAIL",
  CREATE_SCHEDULE_REPORT: "CREATE_SCHEDULE_REPORT",
  CREATE_BLACKOUT_REPORT: "CREATE_BLACKOUT_REPORT",
  CREATE_NOPD_BREAKUP: "CREATE_NOPD_BREAKUP",
  CREATE_ENTITY_NOPD_BREAKUP: "CREATE_ENTITY_NOPD_BREAKUP",
  REGENERATE_TOKEN: "REGENERATE_TOKEN",
  UPDATE_NOPD_STATUS: "UPDATE_NOPD_STATUS",
  UPDATE_NOPD_BREAKUP_LIST: "UPDATE_NOPD_BREAKUP_LIST",
  UPDATE_ENTITY_NOPD_BREAKUP_LIST: "UPDATE_ENTITY_NOPD_BREAKUP_LIST",
  UPDATE_ENTITY_NOPD_STATUS: "UPDATE_ENTITY_NOPD_STATUS",
  CREATE_MASTER_CONFIG: "CREATE_MASTER_CONFIG",
  DELETE_MASTER_CONFIG: "DELETE_MASTER_CONFIG",
  UPDATE_MASTER_CONFIG: "UPDATE_MASTER_CONFIG",
  CREATE_CLIENT_CONFIG: "CREATE_CLIENT_CONFIG",
  DELETE_CLIENT_CONFIG: "DELETE_CLIENT_CONFIG",
  UPDATE_CLIENT_CONFIG: "UPDATE_CLIENT_CONFIG",
  ONBOARD_CLIENT: "ONBOARD_CLIENT",
  CHECK_LICENSE: "CHECK_LICENSE",
  CREATE_TICKET: "CREATE_TICKET",
  ASSIGN_TICKET: "ASSIGN_TICKET",
  ADD_TICKET_TASK: "ADD_TICKET_TASK",
  UPDATE_ACTION: "UPDATE_ACTION",
  MARK_AS_CLOSED: "MARK_AS_CLOSED",
  ADD_TICKET_COMMENT: "ADD_TICKET_COMMENT",
  ADD_TICKET_TYPE: "ADD_TICKET_TYPE",
  DELETE_TICKET_TYPE: "DELETE_TICKET_TYPE",
  ADD_TICKET_STATUS: "ADD_TICKET_STATUS",
  DELETE_TICKET_STATUS: "DELETE_TICKET_STATUS",
  ADD_TICKET_REASON: "ADD_TICKET_REASON",
  DELETE_TICKET_REASON: "DELETE_TICKET_REASON",
  ADD_TICKET_TABS: "ADD_TICKET_TABS",
  DELETE_TICKET_TABS: "DELETE_TICKET_TABS",
  EDIT_TICKET_TYPE: "EDIT_TICKET_TYPE",
};

export const WEEK_ARRAY = [
  { name: "MONDAY", value: 1 },
  { name: "TUESDAY", value: 2 },
  { name: "WEDNESDAY", value: 3 },
  { name: "THURSDAY", value: 4 },
  { name: "FRIDAY", value: 5 },
  { name: "SATURDAY", value: 6 },
  { name: "SUNDAY", value: 7 },
];

const d = new Date();
const year = d.getFullYear();

export const YEAR_ARRAY = [
  { name: "January", value: 1, days: 31 },
  { name: "February", value: 2, days: year % 4 === 0 ? 29 : 28 },
  { name: "March", value: 3, days: 31 },
  { name: "April", value: 4, days: 30 },
  { name: "May", value: 5, days: 31 },
  { name: "June", value: 6, days: 30 },
  { name: "July", value: 7, days: 31 },
  { name: "August", value: 8, days: 31 },
  { name: "September", value: 9, days: 30 },
  { name: "October", value: 10, days: 31 },
  { name: "November", value: 11, days: 30 },
  { name: "December", value: 12, days: 31 },
];

export const NO_OF_COPY = [
  {
    label: "1",
    value: 1,
  },
  {
    label: "2",
    value: 2,
  },
  {
    label: "3",
    value: 3,
  },
  {
    label: "4",
    value: 4,
  },
  {
    label: "5",
    value: 5,
  },
  {
    label: "6",
    value: 6,
  },
  {
    label: "7",
    value: 7,
  },
  {
    label: "8",
    value: 8,
  },
  {
    label: "9",
    value: 9,
  },
  {
    label: "10",
    value: 10,
  },
  {
    label: "11",
    value: 11,
  },
  {
    label: "12",
    value: 12,
  },
  {
    label: "13",
    value: 13,
  },
  {
    label: "14",
    value: 14,
  },
  {
    label: "15",
    value: 15,
  },
  {
    label: "16",
    value: 16,
  },
  {
    label: "17",
    value: 17,
  },
  {
    label: "18",
    value: 18,
  },
  {
    label: "19",
    value: 19,
  },
  {
    label: "20",
    value: 20,
  },
];

export const DOWNTIME = [
  {
    label: "Fixed",
    value: "fixed",
  },
  {
    label: "Daily",
    value: "daily",
  },
  {
    label: "Weekly",
    value: "weekly",
  },
  {
    label: "Monthly",
    value: "monthly",
  },
  {
    label: "Yearly",
    value: "yearly",
  },
];

export const STATE_NAMES = [
  {
    label: "Andhra Pradesh",
    value: "Andhra Pradesh",
  },
  {
    label: "Arunachal Pradesh",
    value: "Arunachal Pradesh",
  },
  {
    label: "Assam",
    value: "Assam",
  },
  {
    label: "Bihar",
    value: "Bihar",
  },
  {
    label: "Chhattisgarh",
    value: "Chhattisgarh",
  },
  {
    label: "Goa",
    value: "Goa",
  },
  {
    label: "Gujarat",
    value: "Gujarat",
  },
  {
    label: "Haryana",
    value: "Haryana",
  },
  {
    label: "Himachal Pradesh",
    value: "Himachal Pradesh",
  },
  {
    label: "Jammu and Kashmir",
    value: "Jammu and Kashmir",
  },
  {
    label: "Jharkhand",
    value: "Jharkhand",
  },
  {
    label: "Karnataka",
    value: "Karnataka",
  },
  {
    label: "Kerala",
    value: "Kerala",
  },
  {
    label: "Madhya Pradesh",
    value: "Madhya Pradesh",
  },
  {
    label: "Maharashtra",
    value: "Maharashtra",
  },
  {
    label: "Manipur",
    value: "Manipur",
  },
  {
    label: "Meghalaya",
    value: "Meghalaya",
  },
  {
    label: "Mizoram",
    value: "Mizoram",
  },
  {
    label: "Nagaland",
    value: "Nagaland",
  },
  {
    label: "Odisha",
    value: "Odisha",
  },
  {
    label: "Punjab",
    value: "Punjab",
  },
  {
    label: "Rajasthan",
    value: "Rajasthan",
  },
  {
    label: "Sikkim",
    value: "Sikkim",
  },
  {
    label: "Tamil Nadu",
    value: "Tamil Nadu",
  },
  {
    label: "Telangana",
    value: "Telangana",
  },
  {
    label: "Tripura",
    value: "Tripura",
  },
  {
    label: "Uttar Pradesh",
    value: "Uttar Pradesh",
  },
  {
    label: "Uttarakhand",
    value: "Uttarakhand",
  },
  {
    label: "West Bengal",
    value: "West Bengal",
  },
];

export const FORM_FIELDS = [
  {
    label: "Text",
    value: "text",
  },
  {
    label: "Email",
    value: "email",
  },
  {
    label: "Number",
    value: "number",
  },
  {
    label: "Phone",
    value: "phone",
  },
  {
    label: "Date",
    value: "date",
  },
  {
    label: "Time",
    value: "time",
  },
  {
    label: "Checkbox",
    value: "checkbox",
  },
  {
    label: "Select",
    value: "select",
  },
  {
    label: "Multiple Select",
    value: "multi-select",
  },
  {
    label: "Radio",
    value: "radio",
  },
  {
    label: "Paragraph",
    value: "paragraph",
  },
  {
    label: "Heading",
    value: "heading",
  },
];
