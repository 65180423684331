import { MUTATION_KEY } from "constants/globalConstants";
import { useMutation } from "react-query";
import toast from "react-hot-toast";
import ApiService from "apiService";
import { useHistory } from "react-router-dom";

const useConfirmPassword = () => {
  const history = useHistory()
  return useMutation(
    [MUTATION_KEY.CONFIRM_PASSWORD],
    (data) => ApiService.confirmPassword(data),
    {
      onSuccess: () => {
        toast.success('password changed successfully');
        history.push('/auth/sign-in')
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

export default useConfirmPassword;