import React from "react";
import Chart from "react-apexcharts";
import { Box, Text, Flex, Divider } from "@chakra-ui/react";
import EmptyCard from "components/card/EmptyCard";

const TmsLineChart = ({ data }) => {
  const lineChartDataTotalSpent = [
    {
      data: data?.data?.created,
      type: "line",
      name: "Created",
    },
    // {
    //   data: data?.data?.canceled,
    //   type: "line",
    //   name: "Canceled",
    // },
    // {
    //   data: data?.data?.resolved,
    //   type: "line",
    //   name: "Resolved",
    // },
  ];

  const lineChartOptionsTotalSpent = {
    chart: {
      id: "1",
      animations: {
        enabled: false,
      },
      stacked: false,
      zoom: {
        enabled: false,
      },
      toolbar: {
        autoSelected: "zoom",
        show: false,
      },

      background: "#fff",
    },
    annotations: {
      points: [],
    },
    fill: {
      type: "solid",
      opacity: [0.35, 1],
    },
    dataLabels: {
      enabled: false,
    },
    markers: {
      size: 0,
    },
    xaxis: {
      type: "datetime",
    },
    yaxis: [
      {
        axisTicks: { show: true },
        axisBorder: { show: true },
        labels: {
          show: true,
          //minWidth: 140
        },
        tooltip: { enabled: false },
      },
    ],
    stroke: {
      width: 3,
    },
    legend: {
      show: false,
    },
    tooltip: {
      shared: true,
      x: {
        format: "dd MMM yyyy",
      },
    },
  };

  return (
    <Flex mt="10px" alignItems="center">
      <Box width="90%">
        {data?.data?.created?.length > 0 ? (
          <Chart
            options={lineChartOptionsTotalSpent}
            series={lineChartDataTotalSpent}
            type="line"
            height="200px"
            width="100%"
          />
        ) : (
          <EmptyCard font="20px" size="70px" color="ndmsColors.gray.100" />
        )}
      </Box>
      <Box width="10%" ml="25px" mt="20px">
        <Flex>
          <Flex direction="column">
            <Box>
              <Text
                color="ndmsColors.gray.1900"
                fontWeight="500"
                fontSize="12px"
              >
                Total Created
              </Text>
              <Text fontSize="20px" fontWeight="bold">
                {data?.data?.total_created}
              </Text>
            </Box>

            <Divider my="28px" width="80px" />

            <Text color="ndmsColors.gray.1900" fontWeight="500" fontSize="12px">
              Total Closed
            </Text>
            <Text fontSize="20px" fontWeight="bold">
              {data?.data?.total_closed}
            </Text>
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};

export default TmsLineChart;
