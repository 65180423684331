import { Button } from "@chakra-ui/button";
import Icon from "@chakra-ui/icon";
import { Text, Flex } from "@chakra-ui/layout";
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/popover";
import Card from "components/card/Card";
import React, { useEffect, useState } from "react";
import { AiOutlineRight } from "react-icons/ai";
import { GoPrimitiveDot } from "react-icons/go";
import { GrNetworkDrive } from "react-icons/gr";
import { useHistory } from "react-router";
import EntireNodeLiveDashboard from "./entireNodeLiveDashboard";
import AccordionToggleLiveDashboard from "./accordionToggleLiveDashboard";
import { MdOutlineBrowserNotSupported } from "react-icons/md";
import { getDashboardPermissions } from "Permissions";
import { node } from "stylis";
import LiveDashboardGroupCardsLoader from "./liveDashboardGroupCardsLoader";

function LiveDashboardToggleViewCards(props) {
  const {
    liveDashboardData,
    liveDashboardCardLoading,
    isFetchingLiveDashboard,
    filteredData,
    entityId,
    blackout,
    entityName,
    loading,
  } = props;

  const [totalNodes, setTotalNodes] = useState(null);
  const [showAllData, setShowAllData] = useState(false);
  const [showMoreValue, setShowMoreValue] = useState();
  const [hiddenGroupLength, setHiddenGroupLength] = useState(null);
  const history = useHistory();
  const arrayLength = liveDashboardData?.data?.length;

  const getBackgroundColor = (value) => {
    switch (value) {
      case "0":
        return "ndmsColors.red.100";
      case "1":
        return "ndmsColors.green.100";
      case "2":
        return "#135CC9";
      default:
        return "ndmsColors.gray.700";
    }
  };

  const getValue = (array) => {
    const length = [];
    array?.map((arr) => length.push(arr.value.length));
    return length.reduce((a, b) => a + b, 0);
  };

  useEffect(() => {
    const arr = [];
    liveDashboardData?.data?.length > 0 &&
      liveDashboardData?.data?.map((value) =>
        value?.node_details?.map((nodeData) => {
          nodeData?.value?.map((nodeValues) => {
            arr.push(nodeValues);
          });
        })
      );
    setTotalNodes(arr.length);
  }, []);

  const getStatus = (value) => {
    switch (value) {
      case "0":
        return "Offline";
      case "1":
        return "Online";
      case "2":
        return "Schedule Down";
      default:
        return "IP not mapped";
    }
  };

  const handleShowAll = (value) => {
    setShowAllData(true);
    setShowMoreValue(value);
  };

  const newData =
    filteredData?.length > 0 &&
    filteredData?.filter((items) => items?.node_details?.length === 0);

  useEffect(() => {
    setHiddenGroupLength(newData?.length);
  }, [newData]);

  if (loading) {
    return <LiveDashboardGroupCardsLoader arrayLength={arrayLength} />;
  }

  return (
    <>
      {liveDashboardData?.data?.length > 0 ? (
        !showAllData ? (
          <>
            <Flex mt="20px" justifyContent="flex-start" flexWrap="wrap">
              {filteredData?.length > 0 &&
                filteredData?.map(
                  (value, idx) =>
                    value.node_details.length > 0 && (
                      <Card
                        ml="20px"
                        bg="ndmsColors.black.100"
                        borderRadius="15px"
                        maxWidth="310px"
                        mb="12px"
                        minHeight="229px"
                        alignItems="center"
                        key={idx}
                      >
                        <Flex
                          align="center"
                          justifyContent="space-between"
                          pb="9px"
                          width="100%"
                          borderBottom="1px solid #F1F1F1"
                        >
                          <Text
                            fontSize="18px"
                            pt="3px"
                            color="ndmsColors.blue.100"
                            fontWeight="bold"
                          >
                            {value.group_name}
                          </Text>
                          <Text
                            color="ndmsColors.gray.300"
                            fontSize="12px"
                            fontWeight="bold"
                          >
                            Total Nodes : {getValue(value?.node_details)}
                          </Text>
                        </Flex>

                        <Flex
                          width="100%"
                          flexWrap="wrap"
                          mt="8px"
                          color="ndmsColors.gray.700"
                          fontSize="12px"
                        >
                          {value?.node_details?.map((nodeData, nIndex) =>
                            nodeData?.value?.map(
                              (nodeValues, index) =>
                                index < 8 && (
                                  <>
                                    <Popover>
                                      <PopoverTrigger>
                                        <Flex
                                          borderRadius="50px"
                                          bg="ndmsColors.gray.50"
                                          alignItems="center"
                                          mt="8px"
                                          width="120px"
                                          p="6px"
                                          mr="10px"
                                          maxHeight="29px"
                                          position="relative"
                                          key={index}
                                          cursor="pointer"
                                        >
                                          <Icon
                                            as={GoPrimitiveDot}
                                            width="18px"
                                            height="18px"
                                            color={getBackgroundColor(
                                              nodeValues.status
                                            )}
                                          />
                                          <Text m="0 8px 0px 2px">
                                            {" "}
                                            {nodeData.node_type_name}{" "}
                                          </Text>

                                          <Icon
                                            position="absolute"
                                            right={2}
                                            as={AiOutlineRight}
                                            color="ndmsColors.gray.700"
                                          />
                                          {!blackout && (
                                            <PopoverContent
                                              width="197px"
                                              background="rgba(0, 0, 0, 0.88)"
                                            >
                                              <PopoverArrow
                                                boxShadow="none !important"
                                                backgroundColor="rgba(0, 0, 0, 0.88) !important"
                                              />
                                              <PopoverCloseButton color="ndmsColors.black.100" />
                                              <PopoverBody
                                                size="12px"
                                                color="ndmsColors.black.100"
                                                fontWeight="700"
                                              >
                                                {getDashboardPermissions(
                                                  "node_dashboard.view"
                                                ) ? (
                                                  <Flex
                                                    alignItems="center"
                                                    cursor="pointer"
                                                    onClick={() =>
                                                      history.push(
                                                        `/admin/node-dashboard/${entityId}/${entityName}/node_id=${nodeValues?.node_id}`
                                                      )
                                                    }
                                                  >
                                                    <Icon
                                                      fill="ndmsColors.black.100"
                                                      as={GrNetworkDrive}
                                                      w="17px"
                                                      h="17px"
                                                      mr="10px"
                                                    />
                                                    <Text>
                                                      {nodeValues?.node_name}
                                                    </Text>
                                                  </Flex>
                                                ) : (
                                                  <Flex alignItems="center">
                                                    <Icon
                                                      fill="ndmsColors.black.100"
                                                      as={GrNetworkDrive}
                                                      w="17px"
                                                      h="17px"
                                                      mr="10px"
                                                    />
                                                    <Text>
                                                      {nodeValues?.node_name}
                                                    </Text>
                                                  </Flex>
                                                )}

                                                <Text
                                                  size="14px"
                                                  fontWeight="400"
                                                >
                                                  {" "}
                                                  {nodeValues?.ip_address}
                                                </Text>
                                                <Flex
                                                  size="14px !important"
                                                  color={getBackgroundColor(
                                                    nodeValues.status
                                                  )}
                                                  fontWeight="400"
                                                >
                                                  <Icon
                                                    as={GoPrimitiveDot}
                                                    width="18px"
                                                    height="18px"
                                                    color={getBackgroundColor(
                                                      nodeValues.status
                                                    )}
                                                  />
                                                  {getStatus(nodeValues.status)}
                                                </Flex>
                                              </PopoverBody>
                                            </PopoverContent>
                                          )}
                                        </Flex>
                                      </PopoverTrigger>
                                    </Popover>
                                  </>
                                )
                            )
                          )}
                          {getValue(value?.node_details) > 8 ? (
                            <Button
                              borderRadius="50px"
                              bg="ndmsColors.gray.50"
                              alignItems="center"
                              mt="8px"
                              p="6px"
                              maxHeight="29px"
                              disabled={blackout ? true : false}
                              onClick={() => {
                                handleShowAll(value);
                              }}
                              width="92px"
                            >
                              + {getValue(value?.node_details) - 8} more
                            </Button>
                          ) : (
                            ""
                          )}
                        </Flex>
                      </Card>
                    )
                )}
            </Flex>
            <AccordionToggleLiveDashboard
              filteredData={filteredData}
              getValue={getValue}
            />
          </>
        ) : (
          <EntireNodeLiveDashboard
            entityId={entityId}
            showMoreValue={showMoreValue}
            setShowMoreValue={setShowMoreValue}
            setShowAllData={setShowAllData}
          />
        )
      ) : (
        <Flex
          direction="column"
          justify="center"
          align="center"
          marginLeft="300px"
          marginTop="100px"
          width="400px"
        >
          <Icon
            as={MdOutlineBrowserNotSupported}
            width="70px"
            height="70px"
            color="ndmsColors.blue.100"
          />
          <Text fontSize={"22px"} color="ndmsColors.blue.100" fontWeight="bold">
            No Data Found!!
          </Text>
        </Flex>
      )}
    </>
  );
}

export default LiveDashboardToggleViewCards;
