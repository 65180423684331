import React, { useEffect, useState } from "react";
import { AddIcon } from "@chakra-ui/icons";
import Select from "react-select";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Input,
  Icon,
  Flex,
  FormLabel,
  Text,
  ModalCloseButton,
  Button,
  //Select,
  useColorModeValue,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import { CctvIcon } from "components/icons/Icons";
import useAddTemplateNode from "hooks/useAddTemplateNode";

function TemplateModal({
  isOpen,
  onClose,
  onOpen,
  data,
  groupData,
  templateData,
  groupId,
  entityId,
}) {
  const {
    handleSubmit,
    control,
    resetField,
    reset,
    formState: { errors },
  } = useForm();
  const textColor = useColorModeValue("navy.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [formValues, setFormValues] = useState([]);

  const handleModalClose = () => {
    onClose();
    setSelectedNodes([]);
    setFormValues(null);
    resetField("name", "node_type_id", "_id");
    reset(data);
  };

  const { mutate: addTemplateNode } = useAddTemplateNode();

  const generateSelectOptions = () => {
    return data?.map((data) => {
      return <option value={data.id}>{data.name}</option>;
    });
  };

  useEffect(() => {
    if (selectedNodes?.length > 0) {
      selectedNodes?.map((node) =>
        setFormValues([...formValues, { name: "", _id: node?.id }])
      );
    }
  }, [selectedNodes]);

  let addFormFields = (id) => {
    const updatedFormValues = formValues.push({
      name: "",
      node_type_id: "",
      _id: id,
    });
    setFormValues([...formValues, updatedFormValues]);
  };

  const onSubmit = (data, errors) => {
    const org = Object.keys(data).reduce(function (res, v) {
      return res.concat(data[v]);
    }, []);
    const mainData = org.filter(Boolean);
    mainData.forEach((object, index) => {
      object.template_id = templateData?.id;
      object.order = index + 1;
    });
    //const apiData = mainData.filter(Boolean)
    const apiData = mainData.filter((data) => {
      return data.name !== undefined;
    });

    addTemplateNode(apiData);
  };
  const onError = (errors) => console.log(errors);
  return (
    <>
      <Modal
        isOpen={isOpen}
        blockScrollOnMount={false}
        onClose={handleModalClose}
        size="3xl"
      >
        <ModalOverlay />
        <form onSubmit={handleSubmit(onSubmit, onError)}>
          <ModalContent>
            <ModalHeader>
              <Flex direction="column" width="50%">
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Select Device<Text color={brandStars}>*</Text>
                </FormLabel>
                <div style={{ marginBottom: "10px" }}>
                  <Select
                    id="user_type"
                    size="lg"
                    placeholder="Select Device"
                    value={selectedNodes}
                    options={data}
                    getOptionLabel={(data) => data["name"]}
                    getOptionValue={(data) => data["id"]}
                    isMulti
                    onChange={setSelectedNodes}
                  >
                    {generateSelectOptions()}
                  </Select>
                  {errors.name?.type === "required" && (
                    <span style={{ color: "red" }}>device is required</span>
                  )}
                </div>
              </Flex>
              {selectedNodes?.length > 0 &&
                selectedNodes?.map((selNode, selIndex) => (
                  <>
                    <Flex align="center">
                      <CctvIcon />
                      <Text color={"#1B2559"} mt="15px" ml="10px">
                        {selNode?.name}
                      </Text>
                    </Flex>
                    {formValues.map((element, index) => {
                      return element?._id === selNode?.id ? (
                        <>
                          <Flex w="100%" mt="15px">
                            <Flex direction="column" width="100%">
                              <FormLabel
                                display="flex"
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                mb="8px"
                              >
                                Name<Text color={brandStars}>*</Text>
                              </FormLabel>
                              <div style={{ marginBottom: "10px" }}>
                                <Controller
                                  name={`${selNode.name}[${index}].name`}
                                  rules={{ required: true }}
                                  control={control}
                                  render={({ field }) => (
                                    <Input
                                      fontSize="sm"
                                      ms={{ base: "0px", md: "0px" }}
                                      type="name"
                                      placeholder="Enter Name"
                                      //mb="24px"
                                      fontWeight="500"
                                      size="lg"
                                      {...field}
                                    />
                                  )}
                                />
                                {errors.name?.type === "required" && (
                                  <span style={{ color: "red" }}>
                                    name is required
                                  </span>
                                )}
                              </div>
                            </Flex>

                            <Flex direction="column" width="100%" pl="10px">
                              <div
                                style={{
                                  marginBottom: "24px",
                                  display: "none",
                                }}
                              >
                                <Controller
                                  name={`${selNode.name}[${index}].node_type_id`}
                                  //rules={{ required: true }}
                                  control={control}
                                  defaultValue={selNode.id}
                                  render={({ field }) => (
                                    <Input
                                      fontSize="sm"
                                      placeholder="Enter node Id"
                                      size="lg"
                                      type="name"
                                      {...field}
                                    />
                                  )}
                                />
                              </div>
                            </Flex>
                          </Flex>
                        </>
                      ) : null;
                    })}

                    <Button
                      bg="#F4F7FE"
                      width="140px"
                      onClick={() => addFormFields(selNode.id)}
                    >
                      <Icon as={AddIcon} w={3} h={3} color="#4318FF" />
                      <Text color={"#4318FF"} pl="5px">
                        Add More
                      </Text>
                    </Button>
                  </>
                ))}
            </ModalHeader>
            <Button
              bg="#4318FF"
              _hover={{ bg: "#2B3674" }}
              ml={5}
              mt={2}
              mb={10}
              width="120px"
              type="submit"
            >
              <Text color={"#FFFFFF"}>Save</Text>
            </Button>
            <ModalCloseButton />
          </ModalContent>
        </form>
      </Modal>
    </>
  );
}

export default TemplateModal;
