import React, { useState } from "react";
import Card from "components/card/Card.js";
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Input,
  Text,
  RadioGroup,
  Radio,
  Select,
  Stack,
  useColorModeValue,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { useForm, Controller } from "react-hook-form";
import { CalendarIcon } from "@chakra-ui/icons";
import DatePicker from "react-datepicker";
import useUpdateUser from "hooks/user/useUpdateUser";

function EditUserForm({ setShowEditForm, roleData, clientData, editData }) {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  let activeColor = useColorModeValue("gray.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const buttonHover = useColorModeValue("brand.600", "brand.400");
  const { mutate: editUser, isLoading } = useUpdateUser(setShowEditForm);
  const isSuperUser = localStorage.getItem("is_superuser") === "true";
  const [superAdmin, setSuperAdmin] = useState(null);

  const {
    handleSubmit,
    control,
    formState: { errors },
    getValues,
  } = useForm();
  const onSubmit = (data) => {
    editUser({
      id: editData?.id,
      client_id: data?.client_id,
      user_fname: data.user_fname,
      user_lname: data.user_lname,
      user_gender: data.user_gender,
      user_role: roleData?.filter(
        (role) => role.id === parseInt(data.user_role_id)
      )[0].role_type,
      user_role_id: data.user_role_id,
      is_superuser: parseInt(data.is_superuser),
      handle_tickets: data.handle_tickets,
      is_supervisor: data.is_supervisor,
    });
  };

  const generateSelectOptions = () => {
    return roleData?.map((data) => {
      return <option value={data.id}>{data.role_type}</option>;
    });
  };

  const generateClientOptions = () => {
    return clientData?.map((data) => {
      return <option value={data.id}>{data.name}</option>;
    });
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card>
        <Flex
          maxW={{ base: "100%" }}
          w="100%"
          mx={{ base: "auto", lg: "0px" }}
          me="auto"
          h="100%"
          alignItems="start"
          justifyContent="center"
          mb={{ base: "30px", md: "30px" }}
          px={{ base: "25px", md: "0px" }}
          mt={{ base: "20px", md: "20px" }}
          flexDirection="column"
        >
          <Box me="auto">
            <Text
              mb="24px"
              ms="4px"
              color={activeColor}
              fontWeight="bold"
              fontSize="24px"
            >
              Edit User
            </Text>
          </Box>
          <Flex
            direction="column"
            w={{ base: "100%" }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: "auto", lg: "unset" }}
            me="auto"
            mb={{ base: "20px", md: "auto" }}
          >
            <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
              {isSuperUser ? (
                <Flex direction="column" width="100%">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Is Super Admin<Text color={brandStars}>*</Text>
                  </FormLabel>

                  <Box mb="24px">
                    <Controller
                      name="is_superuser"
                      rules={{ required: isSuperUser }}
                      control={control}
                      defaultValue={JSON.stringify(editData?.is_superuser)}
                      render={({ field }) => (
                        <RadioGroup {...field}>
                          <Stack direction="row">
                            <Radio value="1" borderColor="#422AFB" pr="20px">
                              Yes
                            </Radio>
                            <Radio value="0" borderColor="#422AFB" pr="20px">
                              No
                            </Radio>
                          </Stack>
                        </RadioGroup>
                      )}
                    />
                    {errors.is_superuser?.type === "required" && (
                      <span style={{ color: "red" }}>
                        super user is required
                      </span>
                    )}
                  </Box>
                </Flex>
              ) : null}
              <Flex w="100%">
                <Flex direction="column" width="100%">
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    isRequired={true}
                    display="flex"
                  >
                    Role Type<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <div style={{ marginBottom: "24px" }}>
                    <Controller
                      name="user_role_id"
                      rules={{ required: true }}
                      control={control}
                      defaultValue={editData?.user_role_id}
                      render={({ field }) => (
                        <Select
                          id="user_type"
                          size="lg"
                          fontSize="14px"
                          placeholder="Select Role"
                          {...field}
                        >
                          {generateSelectOptions()}
                        </Select>
                      )}
                    />
                    {errors.role_id?.type === "required" && (
                      <span style={{ color: "red" }}>role is required</span>
                    )}
                  </div>
                </Flex>
                {isSuperUser ? (
                  <Flex direction="column" width="100%" pl="10px">
                    <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      isRequired={true}
                      display="flex"
                    >
                      Client<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <div style={{ marginBottom: "24px" }}>
                      <Controller
                        name="client_id"
                        rules={{ required: isSuperUser }}
                        control={control}
                        defaultValue={editData?.client_id}
                        render={({ field }) => (
                          <Select
                            id="user_type"
                            size="lg"
                            fontSize="14px"
                            placeholder="Select Client"
                            {...field}
                          >
                            {generateClientOptions()}
                          </Select>
                        )}
                      />
                      {errors.client_id?.type === "required" && (
                        <span style={{ color: "red" }}>client is required</span>
                      )}
                    </div>
                  </Flex>
                ) : null}
              </Flex>
              <Flex w="100%">
                <Flex direction="column" width="100%">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    First Name<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <div style={{ marginBottom: "24px" }}>
                    <Controller
                      name="user_fname"
                      rules={{ required: true }}
                      control={control}
                      defaultValue={editData?.user_fname}
                      render={({ field }) => (
                        <Input
                          fontSize="sm"
                          ms={{ base: "0px", md: "0px" }}
                          type="user_fname"
                          placeholder="Enter first name"
                          //mb="24px"
                          fontWeight="500"
                          size="lg"
                          {...field}
                        />
                      )}
                    />
                    {errors.user_fname?.type === "required" && (
                      <span style={{ color: "red" }}>
                        first name is required
                      </span>
                    )}
                  </div>
                </Flex>
                <Flex direction="column" width="100%" pl="10px">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Last Name<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <div style={{ marginBottom: "24px" }}>
                    <Controller
                      name="user_lname"
                      rules={{ required: true }}
                      control={control}
                      defaultValue={editData?.user_lname}
                      render={({ field }) => (
                        <Input
                          fontSize="sm"
                          ms={{ base: "0px", md: "0px" }}
                          type="user_lname"
                          placeholder="Enter last name"
                          //mb="24px"
                          fontWeight="500"
                          size="lg"
                          {...field}
                        />
                      )}
                    />
                    {errors.user_lname?.type === "required" && (
                      <span style={{ color: "red" }}>
                        last name is required
                      </span>
                    )}
                  </div>
                </Flex>
              </Flex>
              <Flex w="50%">
                <Flex direction="column" width="100%">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Email<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <div style={{ marginBottom: "24px" }}>
                    <Controller
                      name="user_email"
                      rules={{ required: true }}
                      control={control}
                      defaultValue={editData?.user_email}
                      render={({ field }) => (
                        <Input
                          fontSize="sm"
                          ms={{ base: "0px", md: "0px" }}
                          type="email"
                          placeholder="Enter Email"
                          isDisabled={true}
                          //mb="24px"
                          fontWeight="500"
                          size="lg"
                          {...field}
                        />
                      )}
                    />
                    {errors.user_email?.type === "required" && (
                      <span style={{ color: "red" }}>email is required</span>
                    )}
                  </div>
                </Flex>
              </Flex>

              <Flex>
                <Flex direction="column" width="100%">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Gender<Text color={brandStars}>*</Text>
                  </FormLabel>

                  <div style={{ marginBottom: "24px" }}>
                    <Controller
                      name="user_gender"
                      rules={{ required: true }}
                      control={control}
                      defaultValue={editData?.user_gender}
                      render={({ field }) => (
                        <RadioGroup {...field}>
                          <Stack direction="row">
                            <Radio value="m" borderColor="#422AFB" pr="20px">
                              Male
                            </Radio>
                            <Radio value="f" borderColor="#422AFB" pr="20px">
                              Female
                            </Radio>
                          </Stack>
                        </RadioGroup>
                      )}
                    />
                    {errors.user_gender?.type === "required" && (
                      <span style={{ color: "red" }}>Gender is required</span>
                    )}
                  </div>
                </Flex>

                {getValues("is_superuser") === 0 ? null : (
                  <Flex direction="column" width="100%">
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                    >
                      Handle Tickets
                    </FormLabel>

                    <div style={{ marginBottom: "24px" }}>
                      <Controller
                        name="handle_tickets"
                        rules={{ required: false }}
                        control={control}
                        defaultValue={editData?.handles_tickets?.toString()}
                        render={({ field }) => (
                          <RadioGroup {...field}>
                            <Stack direction="row">
                              <Radio
                                value={"true"}
                                borderColor="#422AFB"
                                pr="20px"
                              >
                                Yes
                              </Radio>
                              <Radio
                                value={"false"}
                                borderColor="#422AFB"
                                pr="20px"
                              >
                                No
                              </Radio>
                            </Stack>
                          </RadioGroup>
                        )}
                      />
                    </div>
                  </Flex>
                )}

                <Flex direction="column" width="100%">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Is Super Visor
                  </FormLabel>

                  <div style={{ marginBottom: "24px" }}>
                    <Controller
                      name="is_supervisor"
                      rules={{ required: false }}
                      control={control}
                      defaultValue={editData?.is_supervisor?.toString()}
                      render={({ field }) => (
                        <RadioGroup {...field}>
                          <Stack direction="row">
                            <Radio
                              value={"true"}
                              borderColor="#422AFB"
                              pr="20px"
                            >
                              Yes
                            </Radio>
                            <Radio
                              value={"false"}
                              borderColor="#422AFB"
                              pr="20px"
                            >
                              No
                            </Radio>
                          </Stack>
                        </RadioGroup>
                      )}
                    />
                  </div>
                </Flex>
              </Flex>

              <Flex w="40%">
                <Button
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  w="90%"
                  h="50"
                  mb="24px"
                  mr="20px"
                  type="submit"
                  isLoading={isLoading}
                  _hover={{ bg: isLoading ? "blue" : buttonHover }}
                >
                  Submit
                </Button>
                <Button
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  w="90%"
                  h="50"
                  mb="24px"
                  onClick={() => setShowEditForm(false)}
                >
                  Cancel
                </Button>
              </Flex>
            </form>
          </Flex>
        </Flex>
      </Card>
    </Box>
  );
}

export default EditUserForm;
