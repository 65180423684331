import { QUERY_KEY, MUTATION_KEY } from "constants/globalConstants";
import DashboardApiService from "apiService/dashboardApiService";
import { useMutation, useQuery, useQueryClient } from "react-query";
import toast from "react-hot-toast";

// const useBlackoutReportDetails = (setSlaPerformanceMetrics, setShowReport) => {
//   const queryClient = useQueryClient();
//   return useMutation(
//     [MUTATION_KEY.BLACKOUT_DETAIL],
//     (data) => DashboardApiService.postBlackoutDetail(data),
//     {
//       onSuccess: (data) => {
//         queryClient.invalidateQueries(data);
//         setSlaPerformanceMetrics(data);
//         setShowReport(true);
//       },
//       onError: (error) => {
//         toast.error(error?.response?.data?.message);
//       },
//     }
//   );
// };

const useBlackoutReportDetails = (
  blackoutEnabled,
  entityTypeId,
  entityTypeName,
  startDate,
  endDate,
  showTimeChecked,
  setSlaPerformanceMetrics,
  setShowReport,
  setBlackoutEnabled
) => {
  return useQuery(
    QUERY_KEY.GET_BLACKOUT_REPORT,
    () =>
      DashboardApiService.getBlackoutReport(
        entityTypeId,
        entityTypeName,
        startDate,
        endDate,
        showTimeChecked
      ),
    {
      retry: false,
      enabled: blackoutEnabled,
      onSuccess: (res) => {
        setSlaPerformanceMetrics(res);
        setShowReport(true);
        setBlackoutEnabled(false);
      },
      onError: (err) => {
        toast.error(err.response.data.message);
        setBlackoutEnabled(false);
      },
    }
  );
};

export default useBlackoutReportDetails;
