import { QUERY_KEY, MUTATION_KEY } from "constants/globalConstants";
import ApiService from "apiService";
import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";

const useUntraceableNode = (handleModalClose) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.ADD_NODE],
    (data) => ApiService.adduntraceableNode(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.GET_NODE);
        queryClient.invalidateQueries(QUERY_KEY.GET_GROUP);
        //setShowCreateGroup(false)
        toast.success("Node added successfully");
        handleModalClose();
      },
      onError: (error) => {
        error.response.data.message
          ? toast.error(error.response.data.message)
          : toast.error("internal server error");
      },
    }
  );
};

export const useUpdateUntraceableNode = (handleModalClose) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.UPDATE_NODE],
    (data) => ApiService.updateTraceableNode(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.GET_GROUP);
        toast.success("Node updated successfully");
        handleModalClose();
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

export const useDeleteUntraceableNode = (onDelClose) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.DELETE_CLIENT],
    (id) => ApiService.deleteTraceableNode(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.GET_GROUP);
        onDelClose();
        toast.success("Node deleted successfully");
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

export default useUntraceableNode;
