import { QUERY_KEY, MUTATION_KEY } from "constants/globalConstants";
import DashboardApiService from "apiService/dashboardApiService";
import { useMutation, useQuery } from "react-query";
import toast from "react-hot-toast";

const useGenerateSummaryReport = (
  summaryEnabled,
  startDate,
  endDate,
  onClose,
  setSummaryEnabled
) => {
  return useQuery(
    QUERY_KEY.GET_BLACKOUT_CSV_REPORT,
    () => DashboardApiService.getSummaryReportCsv(startDate, endDate),
    {
      retry: false,
      enabled: summaryEnabled,
      onSuccess: (res) => {
        summaryEnabled &&
          window.open(res?.data, "_blank", "noopener,noreferrer");
        setSummaryEnabled(false);
        onClose();
      },
      onError: (err) => {
        toast.error(err.response.data.message);
        setSummaryEnabled(false);
      },
    }
  );
};
export default useGenerateSummaryReport;
