import React, { useState } from "react";
import Card from "components/card/Card.js";
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Input,
  Text,
  useDisclosure,
  useColorModeValue,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import ClientConfigHooks from "hooks/masterConfig/clientConfigHooks";
import { useHistory } from "react-router-dom";

function EditClientConfiguration({ setShowEditForm, editData }) {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  let activeColor = useColorModeValue("gray.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const history = useHistory();
  const buttonHover = useColorModeValue("brand.600", "brand.400");
  const { mutate: updateConfig, isLoading: isUpdateLoading } =
    ClientConfigHooks.useUpdateClientConfig(setShowEditForm);
  const { mutate: submitConfig, isLoading: isSubmitLoading } =
    ClientConfigHooks.useCreateClientConfig(setShowEditForm);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    if (editData?.id) {
      updateConfig({
        id: editData?.id,
        key: editData.key,
        value: data.value,
        key_label: data.key_label,
      });
    } else {
      submitConfig({
        key: editData.key,
        value: data.value,
        key_label: data.key_label,
      });
    }
  };

  const handleCancel = () => {
    history.push(`/admin/client-configuration`);
    setShowEditForm(false);
  };

  return (
    <>
      <Box w="100%" pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Card>
          <Flex
            maxW={{ base: "100%" }}
            w="100%"
            mx={{ base: "auto", lg: "0px" }}
            me="auto"
            h="100%"
            alignItems="start"
            justifyContent="center"
            mb={{ base: "30px", md: "30px" }}
            px={{ base: "25px", md: "0px" }}
            mt={{ base: "20px", md: "20px" }}
            flexDirection="column"
          >
            <Box me="auto">
              <Text
                mb="24px"
                ms="4px"
                color={activeColor}
                fontWeight="bold"
                fontSize="24px"
              >
                Edit Configuration
              </Text>
            </Box>
            <Flex
              direction="column"
              w="100%"
              maxW="100%"
              background="transparent"
              borderRadius="15px"
              mx={{ base: "auto", lg: "unset" }}
              me="auto"
              mb={{ base: "20px", md: "auto" }}
            >
              <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
                <Flex w="100%">
                  {/* <Flex direction="column" width="100%" pl="10px">
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                    >
                      Key<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <div style={{ marginBottom: "24px" }}>
                      <Controller
                        name="key"
                        rules={{
                          required: true,
                          validate: (value) => {
                            return !!value.trim();
                          },
                        }}
                        control={control}
                        defaultValue={editData?.key}
                        render={({ field }) => (
                          <Input
                            fontSize="sm"
                            ms={{ base: "0px", md: "0px" }}
                            type="name"
                            isDisabled={true}
                            placeholder="Enter key"
                            //mb="24px"
                            fontWeight="500"
                            size="lg"
                            {...field}
                          />
                        )}
                      />
                      {errors.key && (
                        <span style={{ color: "red" }}>key is required</span>
                      )}
                    </div>
                  </Flex> */}

                  <Flex direction="column" width="100%" pl="10px">
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                    >
                      Key Label<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <div style={{ marginBottom: "24px" }}>
                      <Controller
                        name="key_label"
                        rules={{
                          required: true,

                          validate: (value) => {
                            return !!value.trim();
                          },
                        }}
                        control={control}
                        defaultValue={editData?.key_label}
                        render={({ field }) => (
                          <Input
                            fontSize="sm"
                            ms={{ base: "0px", md: "0px" }}
                            type="name"
                            placeholder="Enter Key Label"
                            //mb="24px"
                            disabled
                            fontWeight="500"
                            size="lg"
                            {...field}
                          />
                        )}
                      />
                      {errors.key_label && (
                        <span style={{ color: "red" }}>
                          key label is required
                        </span>
                      )}
                    </div>
                  </Flex>
                  <Flex direction="column" width="100%" pl="10px">
                    <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      display="flex"
                    >
                      Value<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <div style={{ marginBottom: "24px" }}>
                      <Controller
                        name="value"
                        rules={{
                          required: true,
                          validate: (value) => {
                            return !!value.toString().trim();
                          },
                        }}
                        control={control}
                        defaultValue={editData?.value}
                        render={({ field }) => (
                          <Input
                            fontSize="sm"
                            placeholder="Enter Value"
                            size="lg"
                            type="name"
                            {...field}
                          />
                        )}
                      />
                      {errors.value && (
                        <span style={{ color: "red" }}>value is required</span>
                      )}
                    </div>
                  </Flex>
                </Flex>
                {/* <Flex w="50%" alignItems="center"></Flex> */}

                <Flex width="40%" pt="20px" pl="10px">
                  <Button
                    fontSize="sm"
                    variant="brand"
                    fontWeight="500"
                    w="90%"
                    h="50"
                    mb="24px"
                    mr="20px"
                    _hover={{
                      bg:
                        isSubmitLoading || isUpdateLoading
                          ? "blue"
                          : buttonHover,
                    }}
                    isLoading={isSubmitLoading || isUpdateLoading}
                    type="submit"
                  >
                    Submit
                  </Button>
                  <Button
                    fontSize="sm"
                    variant="brand"
                    fontWeight="500"
                    w="90%"
                    h="50"
                    mb="24px"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Flex>
              </form>
            </Flex>
          </Flex>
        </Card>
      </Box>
    </>
  );
}

export default EditClientConfiguration;
