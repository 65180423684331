import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Input,
  Icon,
  Textarea,
  Flex,
  InputGroup,
  InputRightElement,
  FormLabel,
  Text,
  ModalCloseButton,
  Button,
  //Select,
  useColorModeValue,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import useUpdateNode from "hooks/useUpdateNode";
import DatePicker from "react-datepicker";
import { CalendarIcon } from "@chakra-ui/icons";
import { getDate, getApiDate } from "Permissions";
import TicketModuleHooks from "hooks/ticketModule/ticketModuleHooks";

function AddTaskModal({
  isOpen,
  onOpen,
  onClose,
  nodeData,
  ticketId,
  data,
  groupData,
  entityId,
  setShowCreateGroup,
}) {
  const {
    handleSubmit,
    control,
    resetField,
    clearErrors,
    formState: { errors },
  } = useForm();
  const textColor = useColorModeValue("navy.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const buttonHover = useColorModeValue("brand.600", "brand.400");
  const handleModalClose = () => {
    resetField("title");
    resetField("description");
    resetField("scheduled_at");
    clearErrors();
    onClose();
  };

  const { mutate: createTask, isLoading } = TicketModuleHooks.useCreateTask(
    ticketId,
    handleModalClose
  );

  const onSubmit = (data) => {
    createTask({
      title: data.title,
      description: data.description,
      scheduled_at: getApiDate(data.scheduled_at),
    });
  };
  return (
    <>
      <Modal
        isOpen={isOpen}
        blockScrollOnMount={false}
        onClose={handleModalClose}
        size="sm"
      >
        <ModalOverlay />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContent>
            <ModalHeader>
              <Flex w="100%" mt="15px" direction="column" alignItems="center">
                <Text>Add Task</Text>
                <Flex direction="column" width="100%">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Title<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <div style={{ marginBottom: "10px" }}>
                    <Controller
                      name={`title`}
                      rules={{
                        required: true,
                        validate: (value) => {
                          return !!value.trim();
                        },
                      }}
                      control={control}
                      defaultValue={nodeData?.name}
                      render={({ field }) => (
                        <Input
                          fontSize="sm"
                          ms={{ base: "0px", md: "0px" }}
                          type="name"
                          placeholder="Enter Title"
                          //mb="24px"
                          fontWeight="500"
                          size="lg"
                          {...field}
                        />
                      )}
                    />
                    {errors.title && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        title is required
                      </span>
                    )}
                  </div>
                </Flex>
                <Flex direction="column" width="100%" pt="10px">
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    isRequired={true}
                    display="flex"
                  >
                    Description<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <div style={{ marginBottom: "20px" }}>
                    <Controller
                      name="description"
                      rules={{
                        required: true,
                        validate: (value) => {
                          return !!value.trim();
                        },
                      }}
                      control={control}
                      defaultValue={nodeData?.ip_address}
                      render={({ field }) => (
                        <Textarea
                          fontSize="sm"
                          placeholder="Enter Description"
                          size="lg"
                          defaultValue={nodeData?.ip_address || ""}
                          type="name"
                          {...field}
                        />
                      )}
                    />
                    {errors.description && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        description is required
                      </span>
                    )}
                  </div>
                </Flex>
                <Flex direction="column" width="100%">
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    isRequired={true}
                    display="flex"
                  >
                    Scheduled At<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <div style={{ marginBottom: "10px" }}>
                    <Controller
                      name={`scheduled_at`}
                      rules={{ required: true }}
                      control={control}
                      render={({ field }) => (
                        <DatePicker
                          wrapperClassName="date-picker"
                          customInput={
                            <InputGroup>
                              <Input
                                minWidth="300px"
                                placeholder="From"
                                value={
                                  field.value ? `${getDate(field?.value)}` : ""
                                }
                              />
                              <InputRightElement
                                children={<CalendarIcon color="#4318FF" />}
                              />
                            </InputGroup>
                          }
                          onChange={(date) => {
                            field.onChange(date);
                          }}
                          selected={field.value}
                          minDate={new Date()}
                          dateFormat="MMMM d, yyyy"
                        />
                      )}
                    />
                    {errors.scheduled_at?.type === "required" && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        scheduled at is required
                      </span>
                    )}
                  </div>
                </Flex>
              </Flex>
            </ModalHeader>
            <Button
              bg="#4318FF"
              variant="brand"
              ml={5}
              mb={10}
              type="submit"
              width="120px"
              isLoading={isLoading}
              _hover={{ bg: isLoading ? "blue" : buttonHover }}
            >
              <Text color={"#FFFFFF"}>Save</Text>
            </Button>
            <ModalCloseButton />
          </ModalContent>
        </form>
      </Modal>
    </>
  );
}

export default AddTaskModal;
