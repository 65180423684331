import { QUERY_KEY, MUTATION_KEY } from "constants/globalConstants";
import DashboardApiService from "apiService/dashboardApiService";
import { useMutation, useQueryClient, useQuery } from "react-query";
import toast from "react-hot-toast";

const useEntityDashboardDetails = (id, time) => {
  return useQuery(
    QUERY_KEY.GET_ENTITY_DASHBOARD_DETAILS,
    () => DashboardApiService.getEntityDashboardDetails(id, time),
    { retry: false }
  );
};

const useEntityMetaData = (id, time) => {
  return useQuery(
    QUERY_KEY.GET_ENTITY_META_DATA,
    () => DashboardApiService.getEntityMetaData(id, time),
    { retry: false }
  );
};

const useEntityNodetypePerformance = (id, dir, time) => {
  return useQuery(
    QUERY_KEY.GET_ENTITY_NODE_TYPE_PERFORMANCE,
    () => DashboardApiService.getEntityNodetypePerformance(id, dir, time),
    { retry: false }
  );
};

const useEntityGroupPerformance = (id, dir, time) => {
  return useQuery(
    QUERY_KEY.GET_ENTITY_GROUP_PERFORMANCE,
    () => DashboardApiService.getEntityGroupPerformance(id, dir, time),
    { retry: false }
  );
};

const useEntityNodePerformance = (id, dir, time) => {
  return useQuery(
    QUERY_KEY.GET_ENTITY_NODE_PERFORMANCE,
    () => DashboardApiService.getEntityNodePerformance(id, dir, time),
    { retry: false }
  );
};

const EntityDashboardHooks = {
  useEntityDashboardDetails,
  useEntityMetaData,
  useEntityNodetypePerformance,
  useEntityGroupPerformance,
  useEntityNodePerformance,
};

export default EntityDashboardHooks;
