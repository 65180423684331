import React from "react";
import {
  Box,
  Text,
  Button,
  Flex,
  Center,
  Divider,
  Skeleton,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import "../../admin/layoutPlanning";
import {
  UpIcon,
  DownIcon,
  GridIconDark,
  ListIconLight,
} from "components/icons/Icons";

const GroupLoader = () => {
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card bg="rgba(67, 24, 255, 0.02)" height="835px">
        <Flex direction="column" justify="center">
          <Flex align="center" justify="space-between">
            <Text
              fontSize={"26px"}
              mb="12px"
              ml="10px"
              color="#2B3674"
              fontWeight="bold"
            >
              My Groups
            </Text>
            <Flex>
              <Button
                fontSize="sm"
                fontWeight="500"
                w="132px"
                h="50"
                mb="24px"
                mr="20px"
                type="submit"
                bg="#FFFFFF"
              >
                <DownIcon />
                <Text fontSize={"16px"} color="#4318FF" fontWeight="bold">
                  Export
                </Text>
              </Button>
              <Button
                fontSize="sm"
                fontWeight="500"
                w="132px"
                h="50"
                mb="24px"
                mr="20px"
                type="submit"
                bg="#FFFFFF"
              >
                <UpIcon />
                <Text fontSize={"16px"} color="#4318FF" fontWeight="bold">
                  Import
                </Text>
              </Button>
              <Button
                fontSize="sm"
                variant="brand"
                fontWeight="500"
                w="151px"
                h="50"
                mb="24px"
                mr="20px"
              >
                <Text fontSize={"16px"} color="#FFFFFF" fontWeight="bold">
                  Create Group
                </Text>
              </Button>
              <Flex
                align="center"
                justify="space-around"
                height="40px"
                bg="white"
                width="80px"
                mt="5px"
                border="1px solid #DEDEDE"
                borderRadius="6px"
                cursor="pointer"
              >
                <Box pl="5px" pt="2px">
                  <GridIconDark />
                </Box>
                <Center height="40px">
                  <Divider orientation="vertical" />
                </Center>
                <Box pr="10px">
                  <ListIconLight />
                </Box>
              </Flex>
            </Flex>
          </Flex>
          <Flex pt="20px" style={{ flexWrap: "wrap" }}>
            {[...Array(9)].map((e, i) => (
              <Card width="30%" height="100px" mr="20px" mb="30px">
                <Skeleton height="60px" />
              </Card>
            ))}
          </Flex>
        </Flex>
      </Card>
    </Box>
  );
};

export default GroupLoader;
