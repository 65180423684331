import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Select,
  Input,
  Flex,
  FormLabel,
  Text,
  ModalCloseButton,
  useColorModeValue,
  Button,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import useAddTemplate from "hooks/useAddTemplate";
import useTemplate from "hooks/useTemplate";
import useCopyResource from "hooks/useCopyResource";
import { NO_OF_COPY } from "constants/globalConstants";

const CreateTemplateModal = ({ onClose, isOpen, onOpen, entityId }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const { mutate: addTemplate } = useAddTemplate(onClose);
  const { mutate: copyResource, isLoading: isCopyLoading } =
    useCopyResource(onClose);
  const { isLoading, error, data } = useTemplate();
  const textColor = useColorModeValue("navy.700", "white");
  const buttonHover = useColorModeValue("brand.600", "brand.400");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const generateCopyOptions = () => {
    return NO_OF_COPY?.map((copy) => {
      return <option value={copy.value}>{copy.label}</option>;
    });
  };
  const onSubmit = (data) => {
    copyResource({
      template_id: data.template_id,
      count: data.count,
      type: "Template_request",
      entity_id: entityId,
    });
    //addTemplate({name: data.name, order: 1})
  };

  const getTemplateOptions = () => {
    return data?.data[0]?.result?.map((data) => {
      return <option value={data.id}>{data.name}</option>;
    });
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        blockScrollOnMount={false}
        size="xl"
      >
        <ModalOverlay />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContent>
            <Flex mt="50px" pl="10px">
              <Flex direction="column" width="100%" pr="10px">
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Select Template<Text color={brandStars}>*</Text>
                </FormLabel>
                <div style={{ marginBottom: "10px" }}>
                  <Controller
                    name="template_id"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <Select
                        id="user_type"
                        size="lg"
                        fontSize="16px"
                        placeholder="Select Template"
                        {...field}
                      >
                        {getTemplateOptions()}
                      </Select>
                    )}
                  />
                  {errors.name?.type === "required" && (
                    <span style={{ color: "red" }}>device is required</span>
                  )}
                </div>
              </Flex>
              <Flex direction="column" width="100%" pr="10px">
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  No of Copy<Text color={brandStars}>*</Text>
                </FormLabel>
                <div style={{ marginBottom: "10px" }}>
                  <Controller
                    name="count"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <Select
                        id="user_type"
                        size="lg"
                        fontSize="16px"
                        placeholder="Select No of Copy"
                        {...field}
                      >
                        {generateCopyOptions()}
                      </Select>
                    )}
                  />
                  {errors.name?.type === "required" && (
                    <span style={{ color: "red" }}>device is required</span>
                  )}
                </div>
              </Flex>
            </Flex>
            <Button
              variant="brand"
              isLoading={isCopyLoading}
              mx={3}
              mt={5}
              width="180px"
              mb={10}
              _hover={{ bg: isLoading ? "blue" : buttonHover }}
              type="submit"
            >
              <Text color={"#FFFFFF"}>Use Template</Text>
            </Button>
            <ModalCloseButton />
          </ModalContent>
        </form>
      </Modal>
    </>
  );
};

export default CreateTemplateModal;
