import React, { useState } from "react";
import {
  Button,
  Flex,
  FormLabel,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import useAddNode from "hooks/useAddNode";

function NodeForm({
  setShowZoom,
  draggedObject,
  savedObject,
  setSavedObject,
  setDraggedObject,
  nodeTypeId,
  index,
  groupId,
}) {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  let activeColor = useColorModeValue("gray.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [formData, setFormData] = useState(null);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const { mutate: addNode } = useAddNode(groupId);
  const onSubmit = (data) => {
    addNode({
      name: data.name,
      node_type_id: nodeTypeId,
      ip_address: data.ip_address,
      order: index ? index + 1 : 1,
      group_id: groupId,
    });
    setSavedObject((savedObject) =>
      !savedObject.includes(data) ? [...savedObject, data] : savedObject
    );
    setDraggedObject(draggedObject.filter((item) => item.name === data.name));
    setShowZoom(false);
  };
  return (
    <div>
      <Flex
        zIndex="2"
        direction="column"
        w={{ base: "100%", md: "120px" }}
        maxW="100%"
        background="transparent"
        borderRadius="15px"
        //mx={{ base: "auto", lg: "unset" }}
        me="auto"
        mb={{ base: "20px", md: "auto" }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormLabel
            display="flex"
            //ms="4px"
            fontSize="xs"
            fontWeight="500"
            color={textColor}
            mb="4px"
          >
            Name<Text color={brandStars}>*</Text>
          </FormLabel>
          <div style={{ marginBottom: "10px" }}>
            <Controller
              name="name"
              rules={{ required: true }}
              control={control}
              render={({ field: { value, onChange } }) => (
                <Input
                  fontSize="xs"
                  ms={{ base: "0px", md: "0px" }}
                  width="150px"
                  type="name"
                  placeholder="Enter your name"
                  //mb="24px"
                  fontWeight="500"
                  size="xs"
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            {errors.name?.type === "required" && (
              <span style={{ color: "red" }}>name is required</span>
            )}
          </div>
          <FormLabel
            display="flex"
            ms="4px"
            fontSize="xs"
            fontWeight="500"
            color={textColor}
            mb="4px"
          >
            Ip Address<Text color={brandStars}>*</Text>
          </FormLabel>
          <div style={{ marginBottom: "10px" }}>
            <Controller
              name="ip_address"
              rules={{ required: true }}
              control={control}
              render={({ field: { value, onChange } }) => (
                <Input
                  fontSize="xs"
                  width="150px"
                  ms={{ base: "0px", md: "0px" }}
                  type="name"
                  placeholder="Enter Ip Address"
                  //mb="24px"
                  fontWeight="500"
                  size="xs"
                  value={value}
                  onChange={onChange}
                />
              )}
            />
            {errors.ip_address?.type === "required" && (
              <span style={{ color: "red" }}>ip address is required</span>
            )}
          </div>

          <Flex>
            <Button colorScheme="blue" size="xs" type="submit">
              Save
            </Button>
            <Button variant="outline" size="xs" ml={3}>
              Cancel
            </Button>
          </Flex>
        </form>
      </Flex>
    </div>
  );
}

export default NodeForm;
