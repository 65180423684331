import React from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import { Route, Switch, Redirect } from "react-router-dom";
import AuthLayout from "layouts/auth";
import AdminLayout from "layouts/admin";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";
import { BrowserRouter, useHistory } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider, QueryCache } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import PageNotFound from "layouts/errorPages/404";
import ServerError from "layouts/errorPages/500";
import PrivateRoute from "ProtectedRoute";
import PdfReport from "views/report";
import axios from "axios";
import AddTicketTypes from "views/admin/ticketCruds/ticketTypes/addTicketTypes";

const twentyFourHoursInMs = 1000 * 60 * 60 * 24;
var retry = true;

const fetchPermissions = async () => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BASE_URL}/get-user-permissions`,
      {
        headers: { Authorization: localStorage.getItem("token") },
      }
    );
    localStorage.setItem("permissions", res?.data?.data);
    queryClient.refetchQueries();
    retry = false;
  } catch (error) {
    console.log(error);
  }
};

const queryClient = new QueryClient({
  queryCache: new QueryCache({
    onError: (error) => {
      if (error.response.status === 403 && retry) {
        fetchPermissions();
      }
      if (error.response.status === 0) {
        window.location.href = "/admin/error-500-page";
        //fetchPermissions();
      }
    },
    // onSuccess: (data) => {
    //   window.location.href = "/admin/home";
    // },
  }),
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      refetchOnReconnect: false,
      retry: false,
      staleTime: twentyFourHoursInMs,
    },
  },
});

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <ChakraProvider theme={theme}>
      <div>
        <Toaster />
      </div>
      <BrowserRouter>
        <React.StrictMode>
          <Switch>
            {/* <PrivateRoute path={`/auth`} component={AuthLayout} />
              <DndProvider backend={HTML5Backend}>
              <PrivateRoute exact path={`/admin/entity`}><AdminLayout /></PrivateRoute>
              </DndProvider>
              <PrivateRoute path={`/rtl`} component={RTLLayout} /> */}
            <PrivateRoute exact path={`/auth/sign-in`} component={AuthLayout} />
            <PrivateRoute
              exact
              path={`/auth/forgot-password`}
              component={AuthLayout}
            />
            <PrivateRoute exact path={`/admin/home`}>
              <AdminLayout />
            </PrivateRoute>

            <PrivateRoute exact path={`/admin/customer`}>
              <AdminLayout />
            </PrivateRoute>

            <PrivateRoute exact path={`/admin/node-type`}>
              <AdminLayout />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={`/report/pdf-report`}
              component={PdfReport}
            />
            <PrivateRoute exact path={`/admin/layout-planning/:id/:name`}>
              <AdminLayout />
            </PrivateRoute>
            <PrivateRoute exact path={`/admin/schedule-downtime/:id`}>
              <AdminLayout />
            </PrivateRoute>
            <PrivateRoute exact path={`/admin/ticket-management-system`}>
              <AdminLayout />
            </PrivateRoute>
            <PrivateRoute exact path={`/admin/ticket-types`}>
              <AdminLayout />
            </PrivateRoute>
            <PrivateRoute exact path={`/admin/ticket-details/:id`}>
              <AdminLayout />
            </PrivateRoute>
            <PrivateRoute exact path={`/admin/template`}>
              <AdminLayout />
            </PrivateRoute>
            <PrivateRoute exact path={`/admin/entity/:status`}>
              <AdminLayout />
            </PrivateRoute>
            <PrivateRoute exact path={`/admin/entities/:status`}>
              <AdminLayout />
            </PrivateRoute>
            <PrivateRoute exact path={`/admin/entity-dashboard/:id/:name`}>
              <AdminLayout />
            </PrivateRoute>
            <PrivateRoute exact path={`/admin/mclient-dashboard`}>
              <AdminLayout />
            </PrivateRoute>
            <PrivateRoute exact path={`/admin/group-dashboard/:id`}>
              <AdminLayout />
            </PrivateRoute>
            <PrivateRoute
              exact
              path={`/admin/node-dashboard/:id/:name/node_id=:nodeId`}
            >
              <AdminLayout />
            </PrivateRoute>
            <PrivateRoute exact path={`/admin/user`}>
              <AdminLayout />
            </PrivateRoute>
            <Route exact path={`/admin/ticket-crud/ticket-crud-types/add`}>
              <AdminLayout />
            </Route>

            <Route exact path={`/admin/ticket-crud/ticket-crud-types/edit/:id`}>
              <AdminLayout />
            </Route>

            <Route exact path={`/admin/ticket-crud/ticket-crud-types`}>
              <AdminLayout />
            </Route>

            <Route exact path={`/admin/ticket-crud/ticket-status/add`}>
              <AdminLayout />
            </Route>

            <Route exact path={`/admin/ticket-crud/ticket-status/edit/:id`}>
              <AdminLayout />
            </Route>

            <Route exact path={`/admin/ticket-crud/ticket-status`}>
              <AdminLayout />
            </Route>

            <Route exact path={`/admin/ticket-crud/ticket-reason/add`}>
              <AdminLayout />
            </Route>

            <Route exact path={`/admin/ticket-crud/ticket-reason/edit/:id`}>
              <AdminLayout />
            </Route>

            <Route exact path={`/admin/ticket-crud/ticket-reason`}>
              <AdminLayout />
            </Route>

            <Route exact path={`/admin/ticket-crud/ticket-tabs/add`}>
              <AdminLayout />
            </Route>

            <Route exact path={`/admin/ticket-crud/ticket-tabs/edit/:id`}>
              <AdminLayout />
            </Route>

            <Route exact path={`/admin/ticket-crud/ticket-tabs`}>
              <AdminLayout />
            </Route>

            <PrivateRoute exact path={`/admin/permissions`}>
              <AdminLayout />
            </PrivateRoute>
            <Route exact path={`/admin/permission-group`}>
              <AdminLayout />
            </Route>
            <Route exact path={`/admin/permission-group/add`}>
              <AdminLayout />
            </Route>
            <Route exact path={`/admin/entity-client-list/:id/:name`}>
              <AdminLayout />
            </Route>
            <Route exact path={`/admin/entity-client-list/:id/:name/add`}>
              <AdminLayout />
            </Route>
            <Route exact path={`/admin/entity-client-list/:id/:name/edit/:id`}>
              <AdminLayout />
            </Route>
            <Route exact path={`/admin/permission-group/edit/:id`}>
              <AdminLayout />
            </Route>
            <PrivateRoute exact path={`/admin/role`}>
              <AdminLayout />
            </PrivateRoute>
            <PrivateRoute exact path={`/admin/live-dashboard/:id/:name`}>
              <AdminLayout />
            </PrivateRoute>
            <PrivateRoute exact path={`/admin/client-dashboard`}>
              <AdminLayout />
            </PrivateRoute>
            <PrivateRoute exact path={`/admin/sync-entity-list`}>
              <AdminLayout />
            </PrivateRoute>
            <PrivateRoute exact path={`/admin/nonSync-entity-list`}>
              <AdminLayout />
            </PrivateRoute>
            <PrivateRoute exact path={`/admin/master-configuration`}>
              <AdminLayout />
            </PrivateRoute>
            <PrivateRoute exact path={`/admin/master-configuration/add`}>
              <AdminLayout />
            </PrivateRoute>
            <PrivateRoute exact path={`/admin/master-configuration/edit`}>
              <AdminLayout />
            </PrivateRoute>
            <PrivateRoute exact path={`/admin/client-configuration`}>
              <AdminLayout />
            </PrivateRoute>
            <PrivateRoute exact path={`/admin/client-configuration/add`}>
              <AdminLayout />
            </PrivateRoute>
            <PrivateRoute exact path={`/admin/client-configuration/edit`}>
              <AdminLayout />
            </PrivateRoute>
            <PrivateRoute exact path={`/admin/install-client/:id/:name`}>
              <AdminLayout />
            </PrivateRoute>
            <PrivateRoute exact path={`/admin/sla-report/:reports`}>
              <AdminLayout />
            </PrivateRoute>
            <PrivateRoute exact path={`/admin/approved-downtimes`}>
              <AdminLayout />
            </PrivateRoute>
            <PrivateRoute exact path={`/admin/install-client-app`}>
              <AdminLayout />
            </PrivateRoute>
            <PrivateRoute exact path={`/admin/page-not-found`}>
              <PageNotFound />
            </PrivateRoute>
            <PrivateRoute exact path={`/admin/error-500-page`}>
              <ServerError />
            </PrivateRoute>
            <Route
              exact
              path={""}
              render={() => <Redirect from="/" to="/admin/home" />}
            />
            {/* <Route component={PageNotFound} /> */}
            {/* <PrivateRoute path="*"><PageNotFound /></PrivateRoute> */}
            {/* <Redirect from="/" to="/auth/sign-in" /> */}
          </Switch>
        </React.StrictMode>
      </BrowserRouter>
    </ChakraProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </QueryClientProvider>,
  document.getElementById("root")
);
