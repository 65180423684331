import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Box,
  Flex,
  Text,
  ModalCloseButton,
  Button,
  //Select,
} from "@chakra-ui/react";
import ApproveDowntimeHooks from "hooks/approvedDowntime/approveDowntimeHooks";

const ConfirmationModal = ({
  isOpen,
  onClose,
  onOpen,
  status,
  isNodeLevel,
  notes,
  id,
  entityStatusId,
  handleModalClose,
}) => {
  const handleClose = () => {
    onClose();
  };

  const { mutate: updateNopdStatus, isLoading } =
    ApproveDowntimeHooks.useUpdateNopdStatus(handleModalClose);

  const { mutate: updateEntityNopdStatus, isLoading: isEntityNopdLoading } =
    ApproveDowntimeHooks.useEntityUpdateNopdStatus(handleModalClose);

  const getText = () => {
    switch (status) {
      case 1:
        return "Approve";
      case 2:
        return "Disapprove";
      default:
        return "Approve";
    }
  };

  const handleStatus = () => {
    if (isNodeLevel) {
      updateNopdStatus({
        id: id,
        status: status,
        review_notes: notes,
      });
    } else {
      updateEntityNopdStatus({
        entity_breakup_id: id,
        status: status,
        review_notes: notes,
        entity_id: entityStatusId,
      });
    }
  };

  return (
    <Box borderRadius="3xl">
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        blockScrollOnMount={false}
        size="lg"
        isCentered
      >
        <ModalOverlay />
        <ModalContent borderRadius="20px">
          <ModalHeader>
            <Flex justify="center" direction="column" align="center" mt="30px">
              <Text color="#A3AED0">
                {`Are you sure you want to ${getText(status)} this downtime?`}
              </Text>
              {isNodeLevel ? null : (
                <Text color="#A3AED0" fontSize="18px">
                  it will be applied to all nodes
                </Text>
              )}
              <Flex mt="20px">
                <Button
                  bg="#4318FF"
                  _hover={{ bg: "#2B3674" }}
                  mx={3}
                  mt={2}
                  mb={10}
                  size="lg"
                  isLoading={isLoading || isEntityNopdLoading}
                  onClick={handleStatus}
                >
                  <Text color={"#FFFFFF"}>Yes</Text>
                </Button>
                <Button
                  variant="outline"
                  colorScheme="#2B3674"
                  _hover={{ bg: "#F0F8FF" }}
                  mx={3}
                  mt={2}
                  size="lg"
                  mb={10}
                  onClick={handleClose}
                >
                  <Text color={"#2B3674"}>No</Text>
                </Button>
              </Flex>
            </Flex>
          </ModalHeader>

          <ModalCloseButton />
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ConfirmationModal;
