import { Checkbox } from "@chakra-ui/checkbox";
import { useDisclosure } from "@chakra-ui/hooks";
import Icon from "@chakra-ui/icon";
import { Box, Flex, Text } from "@chakra-ui/layout";
import {
  Alert,
  AlertIcon,
  Button,
  Circle,
  Image,
  Link,
  Select,
} from "@chakra-ui/react";
import { getDashboardPermissions } from "Permissions";
import EmptyCard from "components/card/EmptyCard";
import { DownloadIcon } from "components/icons/Icons";
import DownloadModal from "components/modal/downloadModal";
import dayjs from "dayjs";
import useEntityListName from "hooks/entityList/useEntityListName";
import useBlackoutReportDetails from "hooks/slaReport/useBlackoutReportDetail";
import useNodeTypeId from "hooks/slaReport/useNodeTypeId";
import useNodeTypeList from "hooks/slaReport/useNodeTypeList";
import useScheduleReportDetails from "hooks/slaReport/useScheduleReportDetails";
import useSlaPerformanceReport from "hooks/slaReport/useSlaPerformanceReport";
import useSummaryReportDetail from "hooks/slaReport/useSummaryReportDetail";
import useTicketReportDetail from "hooks/slaReport/useTicketReportDetails";
import useBreakUpReasonList from "hooks/useBreakUpReasonList";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import Card from "../../../components/card/Card";
import TicketFilter from "./TicketFilter";
import EndDateFilter from "./endDateFilter";
import EntityFilter from "./entityFilter";
import NodeIdFilter from "./nodeIdFilter";
import NodeTypeFilter from "./nodeTypeFilter";
import OnlyNodeTypeFilter from "./onlyNodeTypeFilter";
import ScheduleDownChart from "./scheduleDownChart";
import SlaChart from "./slaChart";
import "./slaReport.css";
import SlaSummaryTable from "./slaSummaryTable";
import SlaTicketTable from "./slaTicketTable";
import SlaTimeLogTable from "./slaTimeLogTable";
import StartDateFilter from "./startDateFilter";

function SlaReport() {
  const reportFilterType = useParams()?.reports;
  const {
    isLoading,
    error,
    data,
    refetch: entityListRefetch,
  } = useEntityListName();
  const [priority, setPriority] = useState();
  const [entity, setEntity] = useState();
  const [entityTypeId, setEntityTypeId] = useState(null);
  const [entityTypeName, setEntityTypeName] = useState("all");
  const [breakupReasonID, setBreakupReasonID] = useState(0);
  const [nodeTypeId, setNodeTypeId] = useState(null);
  const [type, setType] = useState();
  const [nodeTypeName, setNodeTypeName] = useState("all");
  const [selectedTicketType, setSelectedTicketType] = useState();
  const [nodeId, setNodeId] = useState(null);
  const [nodeIdName, setNodeIdName] = useState("all");
  const [showReport, setShowReport] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [checked, setChecked] = React.useState(false);
  const [enabled, setEnabled] = useState(false);
  const [summaryEnabled, setSummaryEnabled] = useState(false);
  const [ticketEnabled, setTicketEnabled] = useState(false);
  const [blackoutEnabled, setBlackoutEnabled] = useState(false);
  const [scheduleEnabled, setScheduleEnabled] = useState(false);
  const [uptimeChecked, setUptimeChecked] = useState(true);
  const [scheduleChecked, setScheduleChecked] = useState(true);
  const [effectiveChecked, setEffectiveChecked] = useState(true);
  const [showTimeChecked, setShowTimeChecked] = useState(false);
  const [showBreakupChecked, setShowBreakupChecked] = useState(false);
  const [errorReport, setErrorReport] = useState("");
  const clientLogo = localStorage.getItem("client_logo");
  const [filter, setFilter] = useState([
    "effective_downtime",
    "schedule_downtime",
    "uptime",
    "show_time_logs",
  ]);
  const [enableFlag, setEnableFlag] = useState(false);
  useEffect(() => {
    if (
      entityTypeId == null ||
      nodeTypeId == null ||
      nodeId == null ||
      nodeTypeId == 0 ||
      nodeId == 0 ||
      startDate == "" ||
      endDate == ""
    ) {
      setEnableFlag(false);
    } else {
      setEnableFlag(true);
    }
  }, [entityTypeId, nodeTypeId, nodeId, startDate, endDate]);

  useEffect(() => {
    setNodeTypeId(null);
    setNodeTypeName("all");
    setNodeId(null);
    setNodeIdName("all");
  }, [entityTypeId, entityTypeName]);

  useEffect(() => {
    setShowReport(false);
    setEnabled(false);
    setSummaryEnabled(false);
    setTicketEnabled(false);
    setBlackoutEnabled(false);
    setScheduleEnabled(false);
  }, [
    entityTypeId,
    entityTypeName,
    nodeTypeId,
    nodeId,
    startDate,
    endDate,
    priority,
    type,
    entity,
  ]);

  const {
    data: nodeTypeData,
    refetch: nodeTypeDataRefetch,
    isLoading: isNodeLoading,
    isFetching: isNodeFetching,
  } = useNodeTypeId(entityTypeId, nodeTypeId, enableFlag);

  const { data: breakupReasonData } = useBreakUpReasonList();

  const {
    data: nodeTypeListData,
    refetch: nodeTypeListDataRefetch,
    isLoading: isNodeTypeLoading,
    isFetching: isNodeTypeFetching,
  } = useNodeTypeList(entityTypeId, enableFlag);
  useEffect(() => {
    entityListRefetch();
    setErrorReport(
      `Reports can only be generated for a period of ${localStorage.getItem(
        "report_export_days"
      )} days from today.`
    );
  }, []);

  useEffect(() => {
    nodeTypeDataRefetch();
  }, [entityTypeId, nodeTypeId]);

  useEffect(() => {
    nodeTypeListDataRefetch();
  }, [entityTypeId]);

  const pdfId = "pdf";

  const [slaPerformanceMetrics, setSlaPerformanceMetrics] = useState();
  const [ticketPerformanceMetrics, setTicketPerformanceMetrics] = useState();
  const {
    data: slaReportData,
    refetch,
    isLoading: isReportLoading,
    isFetching: isReportFetching,
  } = useSlaPerformanceReport(
    enabled,
    entityTypeId,
    entityTypeName,
    nodeTypeId,
    nodeTypeName,
    nodeId,
    nodeIdName,
    dayjs(startDate).format("YYYY-MM-DD"),
    dayjs(endDate).format("YYYY-MM-DD"),
    uptimeChecked,
    scheduleChecked,
    effectiveChecked,
    showTimeChecked,
    setSlaPerformanceMetrics,
    setShowReport,
    setEnabled,
    breakupReasonID,
    showBreakupChecked
  );
  const {
    data: blackoutReportData,
    isLoading: isBlackoutReportLoading,
    refetch: blackoutRefetch,
    isFetching: isBlackoutReportFetching,
  } = useBlackoutReportDetails(
    blackoutEnabled,
    entityTypeId,
    entityTypeName,
    dayjs(startDate).format("YYYY-MM-DD"),
    dayjs(endDate).format("YYYY-MM-DD"),
    showTimeChecked,
    setSlaPerformanceMetrics,
    setShowReport,
    setBlackoutEnabled
  );

  const {
    data: summaryReportData,
    isLoading: issummaryReportLoading,
    refetch: summaryReportRefetch,
    isFetching: issummaryReportFetching,
  } = useSummaryReportDetail(
    summaryEnabled,
    setSummaryEnabled,
    dayjs(startDate).format("YYYY-MM-DD"),
    dayjs(endDate).format("YYYY-MM-DD"),
    setSlaPerformanceMetrics,
    setShowReport
  );

  const {
    data: ticketReportData,
    isLoading: isticketReportLoading,
    refetch: ticketRefetch,
    isFetching: isticketReportFetching,
  } = useTicketReportDetail(
    ticketEnabled,
    setTicketEnabled,
    type,
    entity,
    priority,
    dayjs(startDate).format("YYYY-MM-DD"),
    dayjs(endDate).format("YYYY-MM-DD"),
    1,
    setTicketPerformanceMetrics,
    setShowReport,
    false
  );

  const {
    data: scheduleReportData,
    isLoading: isScheduleReportLoading,
    refetch: scheduleRefetch,
    isFetching: isScheduleReportFetching,
  } = useScheduleReportDetails(
    scheduleEnabled,
    entityTypeId,
    entityTypeName,
    nodeTypeId,
    nodeTypeName,
    nodeId,
    nodeIdName,
    dayjs(startDate).format("YYYY-MM-DD"),
    dayjs(endDate).format("YYYY-MM-DD"),
    scheduleChecked,
    showTimeChecked,
    setSlaPerformanceMetrics,
    setShowReport,
    setScheduleEnabled
  );

  const onHandleChange = (e) => {
    setBreakupReasonID(e.target.value);
  };
  // const { mutate: blackoutReportData, isLoading: isBlackoutReportLoading } =
  //   useBlackoutReportDetails(setSlaPerformanceMetrics, setShowReport);
  // const { mutate: scheduleReportData, isLoading: isScheduleReportLoading } =
  //   useScheduleReportDetails(setSlaPerformanceMetrics, setShowReport);

  useEffect(() => {
    if (enabled) {
      refetch();
    }
  }, [enabled]);

  useEffect(() => {
    if (ticketEnabled) {
      ticketRefetch(
        ticketEnabled,
        setTicketEnabled,
        type,
        entity,
        priority,
        dayjs(startDate).format("YYYY-MM-DD"),
        dayjs(endDate).format("YYYY-MM-DD"),
        1,
        setTicketPerformanceMetrics,
        setShowReport,
        false
      );
    }
  }, [ticketEnabled]);

  useEffect(() => {
    if (blackoutEnabled) {
      blackoutRefetch();
    }
  }, [blackoutEnabled]);

  useEffect(() => {
    if (summaryReportData) {
      summaryReportRefetch();
    }
  }, [summaryReportData]);

  useEffect(() => {
    if (scheduleEnabled) {
      scheduleRefetch();
    }
  }, [scheduleEnabled]);

  const getCategories = () => {
    switch (reportFilterType) {
      case "EntityScheduleDowntime":
        return [
          {
            id: 2,
            value: "Schedule Downtime",
            checked: true,
            data: "schedule_downtime",
          },
          {
            id: 4,
            value: "Show Time Logs",
            checked: false,
            data: "show_time_logs",
          },
        ];
      case "EntityBlackoutPeriod":
        return [
          {
            id: 4,
            value: "Show Time Logs",
            checked: false,
            data: "show_time_logs",
          },
        ];
      case "NodeType":
        return [
          { id: 1, value: "Uptime", checked: true, data: "uptime" },
          {
            id: 2,
            value: "Schedule Downtime",
            checked: true,
            data: "schedule_downtime",
          },
          {
            id: 3,
            value: "Effective DownTime",
            checked: true,
            data: "effective_downtime",
          },
        ];

      default:
        return [
          { id: 1, value: "Uptime", checked: true, data: "uptime" },
          {
            id: 2,
            value: "Schedule Downtime",
            checked: true,
            data: "schedule_downtime",
          },
          {
            id: 3,
            value: "Effective DownTime",
            checked: true,
            data: "effective_downtime",
          },
          {
            id: 4,
            value: "Show Time Logs",
            checked: false,
            data: "show_time_logs",
          },
        ];
    }
  };

  useEffect(() => {
    setEntityTypeId("0");
    setEntityTypeName("all");
    setNodeTypeId(null);
    setNodeTypeName("all");
    setNodeId(null);
    setNodeIdName("all");
    getCategories();
    setEnabled(false);
    setSummaryEnabled(false);
    setTicketEnabled(false);
    setBlackoutEnabled(false);
    setShowReport(false);
    setScheduleEnabled(false);
    setUptimeChecked(true);
    setScheduleChecked(true);
    setEffectiveChecked(true);
    setShowTimeChecked(false);
    setSlaPerformanceMetrics();
    setShowBreakupChecked(false);
  }, [reportFilterType]);

  const handleDownload = () => {
    if (showReport) {
      onOpen();
    }
  };

  const getReportName = () => {
    switch (reportFilterType) {
      case "EntityScheduleDowntime":
        return "Entity Schedule Downtime";
      case "EntityNode":
        return "Entity Node";
      case "EntityBlackoutPeriod":
        return "Entity Blackout Period";
      case "EntityNodeType":
        return "Entity Node Type";
      case "NodeType":
        return "Node Type";
      case "NodeTypeSummary":
        return "Node Type Summary";
      case "TicketReport":
        return "Ticket Report";
      default:
        return "Entity";
    }
  };

  const getButton = () => {
    switch (reportFilterType) {
      case "EntityBlackoutPeriod":
        return (
          <Button
            maxWidth="fit-content"
            bg="ndmsColors.purple.200"
            color="#fff"
            borderRadius="6px"
            type="submit"
            my="20px"
            disabled={
              entityTypeId === 0 ||
              entityTypeId === "0" ||
              entityTypeId === null
                ? true
                : false
            }
            _hover={{ bg: "ndmsColors.blue.400" }}
            _active={{ bg: "ndmsColors.blue.400" }}
            isLoading={isBlackoutReportLoading || isBlackoutReportFetching}
            onClick={() => setBlackoutEnabled(true)}
          >
            Generate Report
          </Button>
        );
      case "EntityScheduleDowntime":
        return (
          <Button
            maxWidth="fit-content"
            bg="ndmsColors.purple.200"
            color="#fff"
            borderRadius="6px"
            type="submit"
            my="20px"
            disabled={
              entityTypeId === 0 ||
              entityTypeId === "0" ||
              entityTypeId === null
                ? true
                : false
            }
            _hover={{ bg: "ndmsColors.blue.400" }}
            _active={{ bg: "ndmsColors.blue.400" }}
            isLoading={isScheduleReportLoading || isScheduleReportFetching}
            onClick={() => setScheduleEnabled(true)}
          >
            Generate Report
          </Button>
        );
      case "NodeTypeSummary":
        return (
          <Button
            maxWidth="fit-content"
            bg="ndmsColors.purple.200"
            color="#fff"
            borderRadius="6px"
            type="submit"
            my="20px"
            _hover={{ bg: "ndmsColors.blue.400" }}
            _active={{ bg: "ndmsColors.blue.400" }}
            isLoading={issummaryReportLoading || issummaryReportFetching}
            onClick={() => setSummaryEnabled(true)}
          >
            Generate Report
          </Button>
        );
      case "TicketReport":
        return (
          <Button
            maxWidth="fit-content"
            bg="ndmsColors.purple.200"
            color="#fff"
            borderRadius="6px"
            type="submit"
            my="20px"
            disabled={type ? false : true}
            _hover={{ bg: "ndmsColors.blue.400" }}
            _active={{ bg: "ndmsColors.blue.400" }}
            isLoading={isticketReportFetching || isticketReportLoading}
            onClick={() => setTicketEnabled(true)}
          >
            Generate Report
          </Button>
        );
      case "NodeType":
        return (
          <Button
            maxWidth="fit-content"
            bg="ndmsColors.purple.200"
            color="#fff"
            borderRadius="6px"
            type="submit"
            my="20px"
            disabled={
              nodeTypeId === 0 || nodeTypeId === "0" || nodeTypeId === null
                ? true
                : false
            }
            _hover={{ bg: "ndmsColors.blue.400" }}
            _active={{ bg: "ndmsColors.blue.400" }}
            isLoading={isReportLoading || isReportFetching}
            onClick={() => setEnabled(true)}
          >
            Generate Report
          </Button>
        );
      default:
        return (
          <Button
            maxWidth="fit-content"
            bg="ndmsColors.purple.200"
            color="#fff"
            borderRadius="6px"
            type="submit"
            my="20px"
            disabled={
              entityTypeId === 0 ||
              entityTypeId === "0" ||
              entityTypeId === null
                ? true
                : false
            }
            _hover={{ bg: "ndmsColors.blue.400" }}
            _active={{ bg: "ndmsColors.blue.400" }}
            isLoading={isReportLoading || isReportFetching}
            onClick={() => setEnabled(true)}
          >
            Generate Report
          </Button>
        );
    }
  };

  return (
    <>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Flex justifyContent="space-between" alignItems="center">
          <Box
            color="ndmsColors.blue.100"
            className="noPrint"
            fontWeight="bold"
            fontSize="32px"
          >
            {getReportName()}
          </Box>

          <Flex
            alignItems="center"
            color="ndmsColors.purple.200"
            fill="ndmsColors.purple.200"
            cursor="pointer"
            className="noPrint"
            onClick={handleDownload}
          >
            <Button disabled={!showReport} bg="#F4F7FE">
              <Icon
                as={DownloadIcon}
                w="17px"
                h="20px"
                mr="10px"
                fill="ndmsColors.purple.200 !important
          "
              />
              <Box
                color="ndmsColors.purple.200"
                fontSize="13px"
                fontWeight="bold"
                className="noPrint"
              >
                {" "}
                Export{" "}
              </Box>
            </Button>
          </Flex>
        </Flex>
        <Card mt="30px" className="noPrint">
          <Text
            className="noPrint"
            color="ndmsColors.blue.100"
            fontSize="20px"
            fontWeight="bold"
          >
            Report Parameters
          </Text>
          <Flex className="noPrint" flexWrap="wrap">
            {reportFilterType === "TicketReport" && (
              <TicketFilter
                setSelectedTicketType={setSelectedTicketType}
                type={type}
                setType={setType}
                entityData={data}
                priority={priority}
                setpriority={setPriority}
                entity={entity}
                setEntity={setEntity}
              />
            )}
            {reportFilterType === "NodeType" ||
            reportFilterType === "TicketReport" ||
            reportFilterType === "NodeTypeSummary" ? null : (
              <EntityFilter
                data={data}
                entityTypeId={entityTypeId}
                setEntityTypeId={setEntityTypeId}
                setEntityTypeName={setEntityTypeName}
                entityTypeName={entityTypeName}
                setNodeTypeId={setNodeTypeId}
                setNodeTypeName={setNodeTypeName}
                setNodeId={setNodeId}
                setNodeIdName={setNodeIdName}
                reportFilterType={reportFilterType}
                isLoading={isLoading}
              />
            )}
            {reportFilterType === "EntityPerformance" ||
            reportFilterType === "EntityBlackoutPeriod" ||
            reportFilterType === "NodeTypeSummary" ||
            reportFilterType === "TicketReport" ||
            reportFilterType === "Entity" ? (
              ""
            ) : (
              <>
                {reportFilterType === "NodeType" ? (
                  <OnlyNodeTypeFilter
                    entityTypeId={entityTypeId}
                    nodeTypeId={nodeTypeId}
                    setNodeTypeId={setNodeTypeId}
                    setNodeTypeName={setNodeTypeName}
                    setNodeId={setNodeId}
                    setNodeIdName={setNodeIdName}
                    nodeTypeListData={nodeTypeListData}
                    reportFilterType={reportFilterType}
                    isNodeTypeLoading={isNodeTypeLoading}
                    isNodeTypeFetching={isNodeTypeFetching}
                  />
                ) : (
                  <NodeTypeFilter
                    entityTypeId={entityTypeId}
                    nodeTypeId={nodeTypeId}
                    setNodeTypeId={setNodeTypeId}
                    setNodeTypeName={setNodeTypeName}
                    setNodeId={setNodeId}
                    setNodeIdName={setNodeIdName}
                    nodeTypeListData={nodeTypeListData}
                    reportFilterType={reportFilterType}
                    isNodeTypeLoading={isNodeTypeLoading}
                    isNodeTypeFetching={isNodeTypeFetching}
                  />
                )}
                {reportFilterType === "EntityNodeType" ||
                reportFilterType === "NodeType" ? (
                  ""
                ) : (
                  <NodeIdFilter
                    setNodeId={setNodeId}
                    nodeTypeId={nodeTypeId}
                    nodeTypeData={nodeTypeData}
                    setNodeIdName={setNodeIdName}
                    isNodeLoading={isNodeLoading}
                    isNodeFetching={isNodeFetching}
                    entityTypeId={entityTypeId}
                  />
                )}
              </>
            )}
            <StartDateFilter
              setStartDate={setStartDate}
              startDate={startDate}
            />
            <EndDateFilter setEndDate={setEndDate} endDate={endDate} />
          </Flex>

          <Flex p="20px 20px 0px 0px" alignItems="center">
            {reportFilterType === "EntityBlackoutPeriod" ||
            reportFilterType === "TicketReport" ||
            reportFilterType === "NodeTypeSummary" ? null : (
              <Text
                color="ndmsColors.gray.2100"
                fontSize="14px"
                fontWeight="bold"
              >
                Time Logs Type
              </Text>
            )}

            <>
              {reportFilterType === "EntityScheduleDowntime" ||
              reportFilterType === "NodeTypeSummary" ||
              reportFilterType === "TicketReport" ||
              reportFilterType === "EntityBlackoutPeriod" ? null : (
                <Checkbox
                  onChange={(e) => setUptimeChecked(e.target.checked)}
                  isDisabled={showBreakupChecked}
                  pl="10px"
                  defaultChecked
                  borderColor="#422AFB"
                  mr="50px"
                >
                  Uptime
                </Checkbox>
              )}
              {reportFilterType === "EntityBlackoutPeriod" ||
              reportFilterType === "TicketReport" ||
              reportFilterType === "NodeTypeSummary" ? null : (
                <Checkbox
                  onChange={(e) => setScheduleChecked(e.target.checked)}
                  pl="10px"
                  defaultChecked
                  borderColor="#422AFB"
                  isDisabled={showBreakupChecked}
                  mr="50px"
                >
                  Schedule Downtime
                </Checkbox>
              )}
              {reportFilterType === "EntityScheduleDowntime" ||
              reportFilterType === "NodeTypeSummary" ||
              reportFilterType === "TicketReport" ||
              reportFilterType === "EntityBlackoutPeriod" ? null : (
                <Checkbox
                  onChange={(e) => setEffectiveChecked(e.target.checked)}
                  isDisabled={showBreakupChecked}
                  defaultChecked
                  pl="10px"
                  borderColor="#422AFB"
                  mr="50px"
                >
                  Downtime
                </Checkbox>
              )}

              {/* {getCategories()?.map((val, id) => (
                <Checkbox
                  key={id}
                  onClick={() => setChecked(!checked)}
                  onChange={(e) => handleChecked(e, val)}
                  pl="10px"
                  defaultChecked
                  pb="20px"
                  borderColor="#422AFB"
                  mr="50px"
                >
                  {val.value}
                </Checkbox>
              ))} */}
            </>
          </Flex>
          {reportFilterType === "NodeType" ||
          reportFilterType === "TicketReport" ||
          reportFilterType === "NodeTypeSummary" ? null : (
            <Flex alignItems="center" p="20px 20px 0px 0px">
              <Text
                color="ndmsColors.gray.2100"
                fontSize="14px"
                fontWeight="bold"
              >
                Show Time Logs
              </Text>
              <Checkbox
                onChange={(e) => {
                  setShowTimeChecked(e.target.checked);
                }}
                pl="10px"
                width="max-content"
                borderColor="#422AFB"
                mr="50px"
              />
              {reportFilterType === "Entity" ||
              reportFilterType === "EntityNodeType" ||
              reportFilterType === "EntityNode" ? (
                <>
                  <Text
                    color="ndmsColors.gray.2100"
                    fontSize="14px"
                    fontWeight="bold"
                  >
                    Approved Downtime Breakup
                  </Text>
                  <Checkbox
                    onChange={(e) => setShowBreakupChecked(e.target.checked)}
                    pl="10px"
                    isDisabled={!showTimeChecked}
                    width="max-content"
                    borderColor="#422AFB"
                    mr="50px"
                  />

                  {showBreakupChecked && (
                    <Select
                      fontSize="13px"
                      width="198px"
                      height="2.5rem"
                      ml="14px"
                      id="user_type"
                      size="lg"
                      mr="10px"
                      color="#2B3674"
                      fontWeight="bold"
                      value={breakupReasonID}
                      onChange={onHandleChange}
                    >
                      <option value="0">Select Approved Downtime Reason</option>
                      {breakupReasonData?.data?.map((list) => (
                        <option key={list?.id} value={list?.id}>
                          {list?.breakup_type}
                        </option>
                      ))}
                    </Select>
                  )}
                </>
              ) : null}
            </Flex>
          )}

          <Flex>
            {getButton()}
            {errorReport && (
              <Alert
                width="51%"
                ml="20px"
                mt="20px"
                mb="20px"
                padding="8px 20px"
                borderRadius="10px"
                status="error"
              >
                <AlertIcon />
                {errorReport}
              </Alert>
            )}
          </Flex>
        </Card>
        {showReport && (
          <Card mt="30px" id="pdfId">
            <Flex alignItems="center" justifyContent="space-between" mb="30px">
              <Box
                color="ndmsColors.blue.100"
                fontWeight="bold"
                fontSize="32px"
              >
                {getReportName()}
              </Box>
              <Image
                boxSize="100px"
                src={clientLogo}
                alt="No Logo"
                cursor="pointer"
              />
            </Flex>
            <Text color="ndmsColors.blue.100" fontSize="20px" fontWeight="bold">
              Details
            </Text>
            <Flex
              flexWrap="wrap"
              justifyContent="space-between"
              alignItems="center"
            >
              {reportFilterType === "TicketReport" && (
                <Flex alignItems="center" mt="15px" mr="50px">
                  <Text
                    color="ndmsColors.gray.2100"
                    fontSize="16px"
                    fontWeight="bold"
                    mr="10px"
                  >
                    Ticket Type:
                  </Text>
                  <Text
                    color="ndmsColors.blue.100"
                    fontSize="16px"
                    fontWeight="bold"
                  >
                    {selectedTicketType?.name}
                  </Text>
                </Flex>
              )}
              {reportFilterType === "NodeTypeSummary" ||
              reportFilterType === "TicketReport" ? null : (
                <Flex alignItems="center" mt="15px" mr="50px">
                  <Text
                    color="ndmsColors.gray.2100"
                    fontSize="16px"
                    fontWeight="bold"
                    mr="10px"
                  >
                    Entity:
                  </Text>
                  <Text
                    color="ndmsColors.blue.100"
                    fontSize="16px"
                    fontWeight="bold"
                  >
                    {entityTypeName}
                  </Text>
                </Flex>
              )}

              {reportFilterType === "EntityPerformance" ||
              reportFilterType === "NodeTypeSummary" ||
              reportFilterType === "TicketReport" ||
              reportFilterType === "EntityBlackoutPeriod" ? null : (
                <>
                  <Flex alignItems="center" mt="15px" mr="50px">
                    <Text
                      color="ndmsColors.gray.2100"
                      fontSize="16px"
                      fontWeight="bold"
                      mr="10px"
                    >
                      Node Type:
                    </Text>
                    <Text
                      color="ndmsColors.blue.100"
                      fontSize="16px"
                      fontWeight="bold"
                      textTransform="capitalize"
                    >
                      {nodeTypeName}
                    </Text>
                  </Flex>
                  {reportFilterType === "EntityNodeType" ||
                  reportFilterType === "NodeTypeSummary" ? (
                    ""
                  ) : (
                    <Flex alignItems="center" mt="15px" mr="50px">
                      <Text
                        color="ndmsColors.gray.2100"
                        fontSize="16px"
                        fontWeight="bold"
                        mr="10px"
                      >
                        Node Id:
                      </Text>
                      <Text
                        color="ndmsColors.blue.100"
                        fontSize="16px"
                        fontWeight="bold"
                        textTransform="capitalize"
                      >
                        {nodeIdName}
                      </Text>
                    </Flex>
                  )}
                </>
              )}

              <Flex alignItems="center" mt="15px" mr="50px">
                <Text
                  color="ndmsColors.gray.2100"
                  fontSize="16px"
                  fontWeight="bold"
                  mr="10px"
                >
                  Start Date:
                </Text>
                <Text
                  color="ndmsColors.blue.100"
                  fontSize="16px"
                  fontWeight="bold"
                >
                  {dayjs(startDate)?.format("DD MMM YYYY")}, 12:00 Am
                </Text>
              </Flex>
              <Flex alignItems="center" mt="15px" mr="50px">
                <Text
                  color="ndmsColors.gray.2100"
                  fontSize="16px"
                  fontWeight="bold"
                  mr="10px"
                >
                  End Date:
                </Text>
                <Text
                  color="ndmsColors.blue.100"
                  fontSize="16px"
                  fontWeight="bold"
                >
                  {dayjs(endDate)?.format("DD MMM YYYY")}, 11:59 Pm
                </Text>
              </Flex>
            </Flex>

            <Text
              color="ndmsColors.blue.100"
              fontSize="20px"
              mt="50px"
              fontWeight="bold"
            >
              Performance Metrics
            </Text>

            {!(
              reportFilterType === "EntityScheduleDowntime" ||
              reportFilterType === "EntityBlackoutPeriod" ||
              reportFilterType === "NodeTypeSummary" ||
              reportFilterType === "TicketReport" ||
              reportFilterType === "NodeType"
            ) && (
              <>
                <Flex
                  fontSize="18px"
                  fontWeight="bold"
                  justifyContent="flex-start"
                  alignItems="center"
                  mt="15px"
                  bg="ndmsColors.gray.2300"
                  p="13px 0px 13px 50px"
                >
                  <Text color="ndmsColors.gray.2100" mr="10px">
                    {" "}
                    Total Effective UP Time (%age):
                  </Text>
                  <Text color="ndmsColors.green.400">
                    {" "}
                    {
                      slaPerformanceMetrics?.data[0]?.entity_performance
                        ?.total_effective_uptime_percentage
                    }{" "}
                    %
                  </Text>
                </Flex>
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  mt="10px"
                  pr="100px"
                  pl="50px"
                >
                  <Flex>
                    <Text
                      color="ndmsColors.gray.2100"
                      fontSize="16px"
                      mr="10px"
                    >
                      Total UP Time{" "}
                      {reportFilterType === "NodeTypeSummary" ? "" : "%"}:
                    </Text>
                    <Text color="ndmsColors.blue.100" fontSize="16px">
                      {
                        slaPerformanceMetrics?.data[0]?.entity_performance
                          ?.uptime_percentage
                      }
                    </Text>
                  </Flex>
                </Flex>
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  mt="10px"
                  bg="ndmsColors.gray.350"
                  p="9px 100px 9px 50px"
                >
                  <Flex>
                    <Text
                      color="ndmsColors.gray.2100"
                      fontSize="16px"
                      mr="10px"
                    >
                      Total Down Time{" "}
                      {reportFilterType === "NodeTypeSummary" ? "" : "%"}:
                    </Text>
                    <Text color="ndmsColors.blue.100" fontSize="16px">
                      {
                        slaPerformanceMetrics?.data[0]?.entity_performance
                          ?.total_downtime_percentage
                      }
                    </Text>
                  </Flex>
                  <Flex>
                    <Text
                      color="ndmsColors.gray.2100"
                      fontSize="16px"
                      mr="10px"
                    >
                      Scheduled Downtime (%):
                    </Text>
                    <Text color="ndmsColors.blue.100" fontSize="16px">
                      {
                        slaPerformanceMetrics?.data[0]?.entity_performance
                          ?.total_schedule_downtime_percentage
                      }
                    </Text>
                  </Flex>
                </Flex>
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  mt="10px"
                  pr="100px"
                  pl="50px"
                >
                  {/* <Flex>
                <Text
                  color="ndmsColors.gray.2100"
                  fontSize="16px"
                  // fontWeight="bold"
                  mr="10px"
                >
                  Down Time (NOPD) (Hours):
                </Text>
                <Text color="ndmsColors.blue.100" fontSize="16px">
                  0
                </Text>
              </Flex> */}
                  <Flex>
                    <Text
                      color="ndmsColors.gray.2100"
                      fontSize="16px"
                      mr="10px"
                    >
                      Effective Downtime (%):
                    </Text>
                    <Text color="ndmsColors.blue.100" fontSize="16px">
                      {
                        slaPerformanceMetrics?.data[0]?.entity_performance
                          ?.total_effective_downtime_percentage
                      }
                    </Text>
                  </Flex>
                  <Flex>
                    <Text
                      color="ndmsColors.gray.2100"
                      fontSize="16px"
                      mr="10px"
                    >
                      Total NOPD Duration (%):
                    </Text>
                    <Text color="ndmsColors.blue.100" fontSize="16px">
                      {
                        slaPerformanceMetrics?.data[0]?.entity_performance
                          ?.total_nopd_percentage
                      }
                    </Text>
                  </Flex>
                </Flex>
              </>
            )}
            {reportFilterType === "NodeTypeSummary" ||
            reportFilterType === "NodeType" ? (
              <>
                <Flex
                  fontSize="18px"
                  fontWeight="bold"
                  justifyContent="flex-start"
                  alignItems="center"
                  mt="15px"
                  bg="ndmsColors.gray.2300"
                  p="13px 0px 13px 50px"
                >
                  <Text color="ndmsColors.gray.2100" mr="10px">
                    {" "}
                    Total Effective UP Time (%age):
                  </Text>
                  <Text color="ndmsColors.green.400">
                    {summaryReportData?.data[0]?.node_type_summary_performance
                      ?.total_effective_uptime_percentage ||
                      slaPerformanceMetrics?.data[0]?.entity_performance
                        ?.total_effective_uptime_percentage}{" "}
                    %
                  </Text>
                </Flex>
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  mt="10px"
                  pr="100px"
                  pl="50px"
                >
                  <Flex>
                    <Text
                      color="ndmsColors.gray.2100"
                      fontSize="16px"
                      mr="10px"
                    >
                      Total UP Time :
                    </Text>
                    <Text color="ndmsColors.blue.100" fontSize="16px">
                      {
                        summaryReportData?.data[0]
                          ?.node_type_summary_performance?.uptime_percentage ||
                          slaPerformanceMetrics?.data[0]?.entity_performance
                            ?.total_effective_uptime_percentage}{" "}
                      
                      %
                    </Text>
                  </Flex>
                </Flex>
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  mt="10px"
                  bg="ndmsColors.gray.350"
                  p="9px 100px 9px 50px"
                >
                  <Flex>
                    <Text
                      color="ndmsColors.gray.2100"
                      fontSize="16px"
                      mr="10px"
                    >
                      Total Down Time :
                    </Text>
                    <Text color="ndmsColors.blue.100" fontSize="16px">
                      {summaryReportData?.data[0]?.node_type_summary_performance
                        ?.total_downtime_percentage ||
                        slaPerformanceMetrics?.data[0]?.entity_performance
                          ?.total_downtime_percentage}
                      %
                    </Text>
                  </Flex>
                  <Flex>
                    <Text
                      color="ndmsColors.gray.2100"
                      fontSize="16px"
                      mr="10px"
                    >
                      Scheduled Downtime :
                    </Text>
                    <Text color="ndmsColors.blue.100" fontSize="16px">
                      {summaryReportData?.data[0]?.node_type_summary_performance
                        ?.total_schedule_downtime_percentage ||
                        slaPerformanceMetrics?.data[0]?.entity_performance
                          ?.total_schedule_downtime_percentage}
                      %
                    </Text>
                  </Flex>
                </Flex>
                <Flex
                  justifyContent="space-between"
                  alignItems="center"
                  mt="10px"
                  pr="100px"
                  pl="50px"
                >
                  {/* <Flex>
                <Text
                  color="ndmsColors.gray.2100"
                  fontSize="16px"
                  // fontWeight="bold"
                  mr="10px"
                >
                  Down Time (NOPD) (Hours):
                </Text>
                <Text color="ndmsColors.blue.100" fontSize="16px">
                  0
                </Text>
              </Flex> */}
                  <Flex>
                    <Text
                      color="ndmsColors.gray.2100"
                      fontSize="16px"
                      mr="10px"
                    >
                      Effective Downtime:
                    </Text>
                    <Text color="ndmsColors.blue.100" fontSize="16px">
                      {summaryReportData?.data[0]?.node_type_summary_performance
                        ?.total_effective_downtime_percentage ||
                        slaPerformanceMetrics?.data[0]?.entity_performance
                          ?.total_effective_downtime_percentage}
                      %
                    </Text>
                  </Flex>
                  <Flex>
                    <Text
                      color="ndmsColors.gray.2100"
                      fontSize="16px"
                      mr="10px"
                    >
                      Total NOPD Duration:
                    </Text>
                    <Text color="ndmsColors.blue.100" fontSize="16px">
                      {summaryReportData?.data[0]?.node_type_summary_performance
                        ?.total_nopd_percentage ||
                        slaPerformanceMetrics?.data[0]?.entity_performance
                          ?.total_nopd_percentage}
                      %
                    </Text>
                  </Flex>
                </Flex>
              </>
            ) : (
              ""
            )}

            {reportFilterType === "TicketReport" &&
              ticketReportData?.data?.performance?.length > 0 && (
                <>
                  <Flex
                    fontSize="18px"
                    fontWeight="bold"
                    justifyContent="space-between"
                    alignItems="center"
                    mt="15px"
                    bg="ndmsColors.gray.2300"
                    p="9px 100px 9px 50px"
                  >
                    <Flex>
                      <Text
                        color="ndmsColors.gray.2100"
                        fontSize="16px"
                        mr="10px"
                      >
                        Resolved Ticket (%age) :
                      </Text>
                      <Text color="ndmsColors.blue.100" fontSize="16px">
                        {`${ticketReportData?.data?.performance[0]?.resolved_percentage}%`}
                      </Text>
                    </Flex>
                    <Flex>
                      <Text
                        color="ndmsColors.gray.2100"
                        fontSize="16px"
                        mr="10px"
                      >
                        Average Resolution Time (min) :
                      </Text>
                      <Text color="ndmsColors.blue.100" fontSize="16px">
                        {ticketReportData?.data?.performance[0]
                          ?.average_resolution_time
                          ? `${ticketReportData?.data?.performance[0]?.average_resolution_time} min`
                          : "-"}
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    mt="10px"
                    bg="ndmsColors.gray.350"
                    p="9px 100px 9px 50px"
                  >
                    <Flex>
                      <Text color="ndmsColors.gray.2100" mr="10px">
                        Total Ticket:
                      </Text>
                      <Text color="ndmsColors.green.400">
                        {ticketReportData?.data?.performance[0]?.total_ticket}
                      </Text>
                    </Flex>
                    <Flex>
                      <Text
                        color="ndmsColors.gray.2100"
                        fontSize="16px"
                        mr="10px"
                      >
                        High Priority Ticket:
                      </Text>
                      <Text color="ndmsColors.blue.100" fontSize="16px">
                        {
                          ticketReportData?.data?.performance[0]
                            ?.total_high_priority
                        }
                      </Text>
                    </Flex>
                  </Flex>

                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    mt="10px"
                    bg="ndmsColors.gray.350"
                    p="9px 100px 9px 50px"
                  >
                    <Flex>
                      <Text
                        color="ndmsColors.gray.2100"
                        fontSize="16px"
                        mr="10px"
                      >
                        Resolved Ticket :
                      </Text>
                      <Text color="ndmsColors.blue.100" fontSize="16px">
                        {
                          ticketReportData?.data?.performance[0]
                            ?.total_resolved_ticket
                        }
                      </Text>
                    </Flex>
                    <Flex>
                      <Text
                        color="ndmsColors.gray.2100"
                        fontSize="16px"
                        mr="10px"
                      >
                        Medium Priority Ticket :
                      </Text>
                      <Text color="ndmsColors.blue.100" fontSize="16px">
                        {
                          ticketReportData?.data?.performance[0]
                            ?.total_medium_priority
                        }
                      </Text>
                    </Flex>
                  </Flex>
                  <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    mt="10px"
                    bg="ndmsColors.gray.350"
                    p="9px 100px 9px 50px"
                  >
                    <Flex>
                      <Text
                        color="ndmsColors.gray.2100"
                        fontSize="16px"
                        mr="10px"
                      >
                        Pending Ticket :
                      </Text>
                      <Text color="ndmsColors.blue.100" fontSize="16px">
                        {
                          ticketReportData?.data?.performance[0]
                            ?.total_pending_ticket
                        }
                      </Text>
                    </Flex>
                    <Flex>
                      <Text
                        color="ndmsColors.gray.2100"
                        fontSize="16px"
                        mr="10px"
                      >
                        Low Priority Ticket :
                      </Text>
                      <Text color="ndmsColors.blue.100" fontSize="16px">
                        {
                          ticketReportData?.data?.performance[0]
                            ?.total_low_priority
                        }
                      </Text>
                    </Flex>
                  </Flex>
                </>
              )}

            {reportFilterType === "EntityBlackoutPeriod" && (
              <Flex
                justifyContent="space-between"
                alignItems="center"
                mt="10px"
                pr="100px"
                pl="50px"
              >
                <Flex>
                  <Text color="ndmsColors.gray.2100" fontSize="16px" mr="10px">
                    Total Blackout Hours:
                  </Text>
                  <Text color="ndmsColors.blue.100" fontSize="16px">
                    {
                      slaPerformanceMetrics?.data[0]?.entity_performance
                        ?.total_blackout_time
                    }
                  </Text>
                </Flex>
                <Flex>
                  <Text color="ndmsColors.gray.2100" fontSize="16px" mr="10px">
                    Total Blackout Time (%age):
                  </Text>
                  <Text color="ndmsColors.blue.100" fontSize="16px">
                    {
                      slaPerformanceMetrics?.data[0]?.entity_performance
                        ?.blackout_percentage
                    }
                  </Text>
                </Flex>
              </Flex>
            )}
            {reportFilterType === "EntityScheduleDowntime" && (
              <Flex
                justifyContent="space-between"
                alignItems="center"
                mt="10px"
                pr="100px"
                pl="50px"
              >
                <Flex>
                  <Text color="ndmsColors.gray.2100" fontSize="16px" mr="10px">
                    Total Schedule Downtime Hours:
                  </Text>
                  <Text color="ndmsColors.blue.100" fontSize="16px">
                    {
                      slaPerformanceMetrics?.data[0]?.entity_performance
                        ?.schedule_downtime
                    }
                  </Text>
                </Flex>
                <Flex>
                  <Text color="ndmsColors.gray.2100" fontSize="16px" mr="10px">
                    Total Schedule Down Time (%age):
                  </Text>
                  <Text color="ndmsColors.blue.100" fontSize="16px">
                    {
                      slaPerformanceMetrics?.data[0]?.entity_performance
                        ?.schedule_downtime_percentage
                    }
                  </Text>
                </Flex>
              </Flex>
            )}
            {reportFilterType === "EntityBlackoutPeriod" ? (
              <>
                {filter?.includes("show_time_logs") ? (
                  <Box
                    border="1px solid #B4B9DA"
                    borderRadius="14px"
                    p="30px"
                    mt="19px"
                  >
                    <Text
                      color="ndmsColors.blue.100"
                      fontSize="20px"
                      mt="30px"
                      fontWeight="bold"
                    >
                      Time Logs
                    </Text>
                    {slaPerformanceMetrics?.data[0]?.time_logs?.length > 0 ? (
                      <SlaTimeLogTable
                        blackoutReportData={
                          slaPerformanceMetrics?.data[0]?.time_logs
                        }
                        reportFilterType={reportFilterType}
                        currentPage={1}
                        totalPage={1}
                      />
                    ) : (
                      <Box mt="50px" mb="50px">
                        <EmptyCard />
                      </Box>
                    )}
                  </Box>
                ) : null}
              </>
            ) : !(
                slaPerformanceMetrics === undefined ||
                slaPerformanceMetrics === null
              ) &&
              slaPerformanceMetrics?.data[0]?.node_performance &&
              Object?.values(slaPerformanceMetrics?.data[0]?.node_performance)
                ?.length > 0 ? (
              Object?.values(
                slaPerformanceMetrics?.data[0]?.node_performance
              )?.map((nodes, index) => (
                <>
                  {getDashboardPermissions("node_dashboard.view") ? (
                    <Link
                      href={`/admin/node-dashboard/${entityTypeId}/${entityTypeName}/node_id=${nodes?.node_details?.node_id}`}
                      mt="50px"
                      width="max-content"
                    >
                      <Flex alignItems="center">
                        <Text
                          color="ndmsColors.blue.100"
                          fontSize="20px"
                          fontWeight="bold"
                          pr="10px"
                        >
                          {nodes?.node_details?.node_name}
                        </Text>
                        <Circle
                          size="20px"
                          bg="ndmsColors.gray.1300"
                          color="ndmsColors.blue.100"
                        >
                          {">"}
                        </Circle>
                      </Flex>
                    </Link>
                  ) : (
                    <Text
                      color="ndmsColors.blue.100"
                      fontSize="20px"
                      fontWeight="bold"
                      pr="10px"
                    >
                      {nodes?.node_details?.node_name}
                    </Text>
                  )}
                  <Box
                    border="1px solid #B4B9DA"
                    borderRadius="14px"
                    p="30px"
                    mt="19px"
                  >
                    {reportFilterType !== "EntityScheduleDowntime" && (
                      <SlaChart
                        slaPerformanceMetrics={nodes?.node_details}
                        reportFilterType={reportFilterType}
                      />
                    )}
                    {reportFilterType === "EntityScheduleDowntime" && (
                      <ScheduleDownChart
                        slaPerformanceMetrics={nodes?.node_details}
                        reportFilterType={reportFilterType}
                      />
                    )}
                    {nodes?.node_log?.length > 0 ? (
                      <>
                        <Text
                          color="ndmsColors.blue.100"
                          fontSize="20px"
                          mt="30px"
                          fontWeight="bold"
                          className="break"
                        >
                          Time Logs
                        </Text>
                        {nodes?.node_log?.length > 0 ? (
                          <SlaTimeLogTable
                            slaTimeLogData={nodes?.node_log}
                            currentPage={1}
                            totalPage={1}
                            reportFilterType={reportFilterType}
                          />
                        ) : (
                          <Box mt="50px" mb="50px">
                            <EmptyCard />
                          </Box>
                        )}
                      </>
                    ) : null}
                  </Box>
                </>
              ))
            ) : reportFilterType === "NodeTypeSummary" ? (
              <>
                <Text
                  color="ndmsColors.blue.100"
                  fontSize="20px"
                  mt="30px"
                  fontWeight="bold"
                >
                  Time Logs
                </Text>
                <SlaSummaryTable
                  blackoutReportData={
                    summaryReportData?.data[0]?.summary_detail
                  }
                />
              </>
            ) : reportFilterType === "TicketReport" ? (
              <>
                <Text
                  color="ndmsColors.blue.100"
                  fontSize="20px"
                  mt="30px"
                  fontWeight="bold"
                >
                  Logs
                </Text>
                {ticketReportData?.data?.ticket_data?.length > 0 ? (
                  <SlaTicketTable
                    reportData={ticketReportData?.data?.ticket_data}
                    selectedTicketType={selectedTicketType}
                  />
                ) : (
                  <Box mt="50px" mb="50px">
                    <EmptyCard />
                  </Box>
                )}
              </>
            ) : (
              <Box mt="50px" mb="50px">
                <EmptyCard />
              </Box>
            )}
          </Card>
        )}
      </Box>
      <DownloadModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        id={pdfId}
        type={type}
        selectedTicketType={selectedTicketType}
        ticketReportData={ticketReportData}
        entity={entity}
        priority={priority}
        // ticketRefetch={ticketRefetch}
        reportFilterType={reportFilterType}
        entityTypeId={entityTypeId}
        entityTypeName={entityTypeName}
        nodeTypeId={nodeTypeId}
        nodeTypeName={nodeTypeName}
        nodeId={nodeId}
        nodeIdName={nodeIdName}
        startDate={startDate}
        endDate={endDate}
        uptimeChecked={uptimeChecked}
        scheduleChecked={scheduleChecked}
        effectiveChecked={effectiveChecked}
        showTimeChecked={showTimeChecked}
        filter={filter}
        slaPerformanceMetrics={slaPerformanceMetrics}
      />
    </>
  );
}

export default SlaReport;
