import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Icon,
  Flex,
  Divider,
  TagLabel,
  Accordion,
  Tag,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import EmptyData from "./emptyData";
import { MdEdit } from "react-icons/md";
import { getDashboardPermissions } from "Permissions";
import { MONTH_API_Data } from "./constants";

const MonthlyView = ({ data, handleEdit }) => {
  const formatArray = (num) => {
    const emp = [];
    const myData = data?.data?.filter((dat) => dat.days.includes(num));
    myData.map((my) =>
      emp.push({
        day: num,
        data: [
          {
            id: my.id,
            tabId: parseInt(`${my.id + num}`),
            downtime_type: my.downtime_type,
            status: true,
            is_downtime_active: my.is_downtime_active,
            all_down_nodes_count: my.all_down_nodes_count,
            days: my.days,
            starts_at: my.starts_at,
            ends_at: my.ends_at,
            repeat_till: my.repeat_till,
            last_occured_at: my.last_occured_at,
            next_occurance_start: my.next_occurance_start,
            next_occurance_end: my.next_occurance_start,
            node_type: my.node_type,
            nodes: my.nodes,
          },
        ],
      })
    );
    return emp;
  };

  const dayArray = (num) => {
    const emp = [];
    const myData = data?.data?.filter((dat) => dat.days.includes(num));
    myData.map((my) => emp.push(num));
    return emp;
  };

  const getUniqueDays = () => {
    let myArr = [];
    for (let i = 1; i < 32; i++) {
      myArr = myArr.concat(dayArray(i));
    }
    return [...new Set(myArr)];
  };

  const callMultiple = () => {
    let myArr = [];
    for (let i = 1; i < 32; i++) {
      myArr = myArr.concat(formatArray(i));
    }
    return myArr;
  };

  const filterArray = (pDay) => {
    let mdata = [];
    const arr = callMultiple();
    const newA = arr.filter((ar) => ar.day === pDay);
    for (let i = 0; i < newA.length; i++) {
      mdata = mdata.concat(newA[i].data);
    }
    return [{ day: pDay, data: mdata }];
  };

  const checkNew = () => {
    let myArr = [];
    const nArr = getUniqueDays();
    for (let i = 0; i < nArr.length; i++) {
      myArr = myArr.concat(filterArray(nArr[i]));
    }
    return myArr;
  };

  const MonthData = checkNew();

  const getTime = (time) => {
    const date = dayjs(new Date()).format("MM/DD/YYYY").concat(" ", time);
    return dayjs(date).format("h:mm A");
  };

  const getFilteredNodes = (nodeArray) => {
    let uniqueNode = [];
    for (let i = 0; i < nodeArray.length; i++) {
      uniqueNode.push(nodeArray[i].node_type_name);
    }
    return [...new Set(uniqueNode)];
  };

  const [id, setId] = useState(MonthData[0]?.data[0]?.tabId);

  useEffect(() => {
    setId(MonthData[0]?.data[0]?.tabId);
  }, [data]);
  const [mindex, setIndex] = useState(0);

  const getAbbreviation = (day) => {
    switch (day) {
      case 1:
        return "st";
      case 21:
        return "st";
      case 31:
        return "st";
      case 2:
        return "nd";
      case 22:
        return "nd";
      case 3:
        return "rd";
      case 23:
        return "rd";
      default:
        return "th";
    }
  };

  return (
    <>
      {MonthData?.length > 0 ? (
        <Box mt="20px" position="relative">
          {MonthData.map((mData, sindex) => (
            <Accordion defaultIndex={sindex}>
              <AccordionItem
                p="0px"
                borderStyle="none"
                maxHeight="500px"
                isExpanded={false}
                //borderBottom="1px solid #DCE3EB"
              >
                <h2>
                  <AccordionButton
                    boxShadow="none !important"
                    background="white !important"
                    pl="0px"
                    width="240px"
                    _expanded={{
                      color: "#4318FF",
                      boxShadow: "none",
                      borderStyle: "none",
                    }}
                    onClick={() => setIndex(sindex)}
                  >
                    <Box
                      flex="1"
                      color="#2B3674"
                      fontSize="14px"
                      fontWeight="bold"
                      textAlign="left"
                      pl="0px"
                    >
                      {`${mData.day}${getAbbreviation(mData.day)} (${
                        mData.data.length
                      })`}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel pb={4} pl="0px">
                  <Tabs display="flex">
                    <TabList
                      display="flex"
                      flexDirection="column"
                      borderStyle="none"
                    >
                      {mData.data.map((tab, index) => (
                        <>
                          <Tab
                            width="240px"
                            bg="#F4F7FE"
                            key={index}
                            display="flex"
                            justifyContent="flex-start"
                            color="#707EAE"
                            borderStyle="none"
                            _selected={{
                              color: "#4318FF",
                              bg: "white",
                              boxShadow: "none",
                              position: "none",
                            }}
                            position="relative"
                            py="20px"
                            onClick={() => setId(tab.tabId)}
                          >
                            <Flex flexDirection="column">
                              <Text fontSize="16px" fontWeight="bold">
                                {`${getTime(tab.starts_at)}-${getTime(
                                  tab.ends_at
                                )}`}
                              </Text>
                              <Text
                                color="#9AA6CF"
                                align="left"
                                fontSize="10px"
                              >
                                {tab.all_down_nodes_count} Nodes
                              </Text>
                            </Flex>
                            <Text
                              position="absolute"
                              right={5}
                              color="#343B46"
                              fontSize="20px"
                            >{`>`}</Text>
                          </Tab>
                          <Divider orientation="horizontal" />
                        </>
                      ))}
                    </TabList>
                    <TabPanels>
                      {mData.data.map((tab, index) => (
                        <TabPanel
                          py="0px"
                          key={index}
                          width="70%"
                          position="absolute"
                          top={0}
                        >
                          {id === tab.tabId ? (
                            <>
                              <Flex>
                                <Text
                                  color="#2B3674"
                                  fontSize="22px"
                                  fontWeight="bold"
                                >
                                  {`${getTime(tab.starts_at)}-${getTime(
                                    tab.ends_at
                                  )}`}
                                </Text>
                                {getDashboardPermissions(
                                  "schedule_downtime.update"
                                ) ? (
                                  <Flex
                                    align="center"
                                    pl="20px"
                                    cursor="pointer"
                                    onClick={() => handleEdit(tab)}
                                  >
                                    <Icon
                                      color="#2500BC"
                                      as={MdEdit}
                                      w="15px"
                                      h="15px"
                                    />
                                    <Text
                                      fontSize="14px"
                                      pt="2px"
                                      color="#2500BC"
                                      fontWeight="bold"
                                    >
                                      Edit
                                    </Text>
                                  </Flex>
                                ) : null}
                              </Flex>
                              {tab?.next_occurance_start ? (
                                <Text
                                  color="#179DBA"
                                  py="5px"
                                  fontSize="16px"
                                  fontWeight="bold"
                                >
                                  Next occurence on{" "}
                                  {dayjs(tab?.next_occurance_start).format(
                                    "DD MMMM"
                                  )}{" "}
                                  at{" "}
                                  {dayjs(tab?.next_occurance_start).format(
                                    "h:mm A"
                                  )}
                                </Text>
                              ) : null}
                              <Flex mb="10px">
                                <Tag
                                  size={"sm"}
                                  variant="solid"
                                  bg={
                                    tab?.is_downtime_active
                                      ? "#019F79"
                                      : "#C70000"
                                  }
                                >
                                  <TagLabel textTransform="uppercase">
                                    {tab?.is_downtime_active
                                      ? "active"
                                      : "inactive"}
                                  </TagLabel>
                                </Tag>
                                <Tag
                                  size={"sm"}
                                  variant="solid"
                                  mx="10px"
                                  bg={"#EDF0F4"}
                                >
                                  <TagLabel fontWeight="bold" color={"#707EAE"}>
                                    Monthly
                                  </TagLabel>
                                </Tag>
                                {tab?.expired_at ? (
                                  <Text
                                    color="#707EAE"
                                    py="5px"
                                    fontSize="14px"
                                    fontWeight="500"
                                  >
                                    Expires on{" "}
                                    {dayjs(tab?.expired_at).format("DD MMMM")}{" "}
                                    at {dayjs(tab?.expired_at).format("h:mm A")}
                                  </Text>
                                ) : null}
                              </Flex>
                              {tab?.last_occured_at ? (
                                <Text
                                  color="#707EAE"
                                  pb="20px"
                                  fontSize="12px"
                                  fontWeight="500"
                                >
                                  Last occured on{" "}
                                  {dayjs(tab?.last_occured_at).format(
                                    "DD MMMM"
                                  )}
                                </Text>
                              ) : null}
                              {tab?.node_type.map((node) => (
                                <Accordion allowToggle>
                                  <AccordionItem
                                    p="0px"
                                    borderStyle="none"
                                    borderBottom="1px solid #DCE3EB"
                                  >
                                    <h2>
                                      <AccordionButton
                                        boxShadow="none !important"
                                        background="white !important"
                                        pl="0px"
                                        _expanded={{
                                          color: "#4318FF",
                                          boxShadow: "none",
                                          borderStyle: "none",
                                        }}
                                      >
                                        <Box
                                          flex="1"
                                          color="#2B3674"
                                          fontSize="14px"
                                          fontWeight="bold"
                                          textAlign="left"
                                          pl="0px"
                                        >
                                          {`${node.name} (${node.nodes.length})`}
                                        </Box>
                                        <AccordionIcon />
                                      </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4} pl="0px">
                                      <Flex>
                                        {node.nodes.map((nod) => (
                                          <Tag
                                            size={"sm"}
                                            key={nod.id}
                                            variant="outline"
                                            color="#707EAE"
                                            mr="5px"
                                          >
                                            {nod.name}
                                          </Tag>
                                        ))}
                                      </Flex>
                                    </AccordionPanel>
                                  </AccordionItem>
                                </Accordion>
                              ))}
                              {getFilteredNodes(tab?.nodes)?.map((type) => (
                                <Accordion key={type} allowToggle>
                                  <AccordionItem
                                    p="0px"
                                    borderStyle="none"
                                    borderBottom="1px solid #DCE3EB"
                                  >
                                    <h2>
                                      <AccordionButton
                                        boxShadow="none !important"
                                        background="white !important"
                                        pl="0px"
                                        _expanded={{
                                          color: "#4318FF",
                                          boxShadow: "none",
                                          borderStyle: "none",
                                        }}
                                      >
                                        <Box
                                          flex="1"
                                          color="#2B3674"
                                          fontSize="14px"
                                          fontWeight="bold"
                                          textAlign="left"
                                          pl="0px"
                                        >
                                          {`${type}`}{" "}
                                          {`(${
                                            tab?.nodes.filter(
                                              (arr) =>
                                                arr.node_type_name === type
                                            ).length
                                          })`}
                                        </Box>
                                        <AccordionIcon />
                                      </AccordionButton>
                                    </h2>
                                    <AccordionPanel pb={4} pl="0px">
                                      <Flex wrap="wrap">
                                        {tab.nodes
                                          .filter(
                                            (data) =>
                                              data.node_type_name === type
                                          )
                                          .map((nod) => (
                                            <Tag
                                              size={"sm"}
                                              key={nod.id}
                                              variant="outline"
                                              color="#707EAE"
                                              mr="5px"
                                              my="5px"
                                            >
                                              {nod.name}
                                            </Tag>
                                          ))}
                                      </Flex>
                                    </AccordionPanel>
                                  </AccordionItem>
                                </Accordion>
                              ))}
                            </>
                          ) : null}
                        </TabPanel>
                      ))}
                    </TabPanels>
                  </Tabs>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          ))}
        </Box>
      ) : (
        <EmptyData />
      )}
    </>
  );
};

export default MonthlyView;
