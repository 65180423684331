/* eslint-disable */
import {
  Flex,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import React, { useMemo, useState } from "react";
import { TableUpIcon } from "components/icons/Icons";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
  useExpanded,
} from "react-table";

export default function GroupDashboardTable(props) {
  const { tableData, renderRowSubComponent } = props;

  const columns = useMemo(
    () => [
      {
        Header: "ID",

        accessor: (row) => {
          return (
            <Text color={"#2B3674"} fontSize="sm" fontWeight="700">
              {row.node_id}
            </Text>
          );
        },
      },
      {
        Header: "Device Name",
        accessor: (row) => {
          return (
            <Text color={"#2B3674"} fontSize="sm" fontWeight="700">
              {row.node_name}
            </Text>
          );
        },
      },
      {
        Header: "Device Type",
        accessor: (row) => {
          return (
            <Text color={"#2B3674"} fontSize="sm" fontWeight="700">
              {row.node_type_name}
            </Text>
          );
        },
      },
      {
        Header: "I.P.",
        accessor: (row) => {
          return (
            <Text color={"#2B3674"} fontSize="sm" fontWeight="700">
              {row.ip_address}
            </Text>
          );
        },
      },
      {
        Header: "Status",
        accessor: (row) => {
          return (
            <Text
              color={row.status === "0" ? "#F86689" : "#2B3674"}
              fontSize="sm"
              fontWeight="700"
            >
              {row.status === "0" ? "offline" : "online"}
            </Text>
          );
        },
      },
      {
        Header: " ",
        accessor: (row) => {
          return <TableUpIcon />;
        },
      },
    ],
    []
  );

  //const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const [show, setShow] = useState(null);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
    state: { expanded },
  } = tableInstance;
  initialState.pageSize = 1000;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("secondaryGray.500", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const toggleDetails = (index) => {
    if (show === null) {
      setShow(index);
    } else {
      setShow(null);
    }
  };
  const isOdd = (rowId) => {
    let id = 0;
    const pos = rowId.lastIndexOf(".");
    if (pos === -1) {
      id = parseInt(rowId, 10);
    } else {
      id = parseInt(rowId.substr(pos + 1), 10);
    }
    return (id + 1) % 2 ? true : false;
  };
  return (
    <Table
      {...getTableProps()}
      variant="expanded"
      color="gray.500"
      mb="24px"
      style={{ position: "relative" }}
    >
      <Thead>
        {headerGroups.map((headerGroup, index) => (
          <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
            {headerGroup.headers.map((column, index) => (
              <Th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                color="#2B3674"
                //pe="10px"
                //pr="10px"
                key={index}
                //style={{position: index === 2 && 'absolute', right: index === 2 && 0}}
                borderColor={borderColor}
              >
                <Flex
                  justify="space-evenly"
                  //pr="30px"
                  align="start"
                  fontSize={{ sm: "10px", lg: "12px" }}
                  color="#2B3674"
                  textTransform="capitalize"
                >
                  {column.render("Header")}
                </Flex>
              </Th>
            ))}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {page.map((row, index) => {
          prepareRow(row);
          return (
            <Tr
              {...row.getRowProps()}
              key={index}
              bg={index % 2 === 0 ? "#F4F7FE" : "white"}
              data-expanded={row.depth > 0 ? true : null}
              data-row-odd={isOdd(row.id)}
            >
              {row.cells.map((cell, index) => {
                return (
                  <Td
                    {...cell.getCellProps()}
                    key={index}
                    pt="10px"
                    pb="10px"
                    fontSize={{ sm: "14px" }}
                    cursor="pointer"
                    //minW={{ sm: "150px", md: "100px", lg: "auto" }}
                    borderColor="transparent"
                  >
                    <Flex justify="space-evenly" align="center">
                      {cell.render("Cell")}
                    </Flex>
                  </Td>
                );
              })}
            </Tr>
          );
        })}
      </Tbody>
    </Table>
  );
}
