import { QUERY_KEY, MUTATION_KEY } from "constants/globalConstants";
import DashboardApiService from "apiService/dashboardApiService";
import { useMutation, useQuery, useQueryClient } from "react-query";
import toast from "react-hot-toast";

const useSlaPerformanceReport = (
  enabled,
  entityTypeId,
  entityTypeName,
  nodeTypeId,
  nodeTypeName,
  nodeId,
  nodeIdName,
  startDate,
  endDate,
  uptimeChecked,
  scheduleChecked,
  effectiveChecked,
  showTimeChecked,
  setSlaPerformanceMetrics,
  setShowReport,
  setEnabled,
  breakupReasonID,
  showBreakupChecked
) => {
  return useQuery(
    QUERY_KEY.GET_SLA_REPORT,
    () =>
      DashboardApiService.getSlaPerformanceReport(
        entityTypeId,
        entityTypeName,
        nodeTypeId,
        nodeTypeName,
        nodeId,
        nodeIdName,
        startDate,
        endDate,
        uptimeChecked,
        scheduleChecked,
        effectiveChecked,
        showTimeChecked,
        setSlaPerformanceMetrics,
        breakupReasonID,
        showBreakupChecked
      ),
    {
      retry: false,
      enabled: enabled,
      onSuccess: (res) => {
        setSlaPerformanceMetrics(res);
        setShowReport(true);
        setEnabled(false);
      },
      onError: (err) => {
        toast.error(err.response.data.message);
        setEnabled(false);
      },
    }
  );
};

export default useSlaPerformanceReport;
