import { Flex, Text, Box, Image } from "@chakra-ui/react";
import DownTimeListTable from "components/table/downTimeListTable";
import React from "react";
import { MdEdit } from "react-icons/md";
import Icon from "@chakra-ui/icon";
import LocationFilter from "./locationFilter";
import PerformanceFilter from "./performanceFilter";
import { Button } from "@chakra-ui/button";
import { AddIcon } from "@chakra-ui/icons";
import Card from "components/card/Card";
import useClientNodesWorking from "hooks/clientDashboard/useClientNodesWorking";
import LoadingForm from "components/loader/LoadingForm";
import noGroupImage from "assets/img/layout/noGroup.png";

export default function ClientDashboardList() {
  const { isLoading, data: tableData } = useClientNodesWorking();
  if (isLoading) {
    return (
      <Box mt="20px">
        <LoadingForm />
      </Box>
    );
  }
  const columnsData = [
    {
      Header: "Entity ID",
      accessor: (row) => {
        return (
          <Text color={"#4318FF"} fontSize="12px" fontWeight="700">
            {row?.id}
          </Text>
        );
      },
    },
    {
      Header: "Entity Name",
      accessor: (row) => {
        return (
          <Text color={"#2B3674"} fontSize="12px" fontWeight="700">
            {row?.name}
          </Text>
        );
      },
    },
    {
      Header: "Location",
      accessor: (row) => {
        return (
          <Text color={"#2B3674"} fontSize="12px" fontWeight="700">
            {row?.Address?.city + `, ` + row?.Address?.state}
          </Text>
        );
      },
    },
    {
      Header: "Total Nodes",
      accessor: (row) => {
        return (
          <Text color={"#2B3674"} fontSize="12px" fontWeight="700">
            {row?.total_nodes}
          </Text>
        );
      },
    },
    {
      Header: "Inactive Nodes",
      accessor: (row) => {
        return (
          <Text color={"#EB5757"} fontSize="12px" fontWeight="700">
            {row.unhealthy_nodes}
          </Text>
        );
      },
    },
    {
      Header: " ",
      accessor: (row) => {
        return <Icon fill="#4318FF" as={MdEdit} w="17px" h="17px" />;
      },
    },
  ];

  return (
    <Box pt={{ base: "130px", md: "100px", xl: "100px" }} pb="50px">
      <Flex justifyContent="flex-end" alignItems="center" mb="42px">
        {/* <Text color="#2B3674" fontSize="34px" fontWeight="bold">
          Syncing Entities
        </Text> */}
        <Flex justifyContent="space-between" alignItems="center">
          <LocationFilter />
          <PerformanceFilter />
          <Button
            bg="#4318FF"
            minWidth="104px"
            borderRadius="6px"
            _hover={{ bg: "#3311DB" }}
            _active={{ bg: "#3311DB" }}
          >
            <Icon as={AddIcon} w={3} h={3} color="#FFF" />
            <Text ml="10px" color="#FFF" fontSize="14px">
              {" "}
              Add New{" "}
            </Text>
          </Button>
        </Flex>
      </Flex>
      {tableData?.data[0]?.synced?.length > 0 ? (
        <Card>
          <DownTimeListTable
            tableData={tableData?.data[0]?.synced}
            columnsData={columnsData}
          />
        </Card>
      ) : (
        <Card>
          <Flex
            align="center"
            justify="center"
            mt="200px"
            cursor="pointer"
            mb="150px"
            //onClick={() => setShowAddForm(true)}
          >
            <Flex direction="column" justify="center" align="center">
              <Image src={noGroupImage} width="70px" alt="no-group" />
              <Text fontSize={"22px"} color="#2B3674" fontWeight="bold">
                You Don’t Have Any Entity
              </Text>
            </Flex>
          </Flex>
        </Card>
      )}
    </Box>
  );
}
