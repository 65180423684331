import { QUERY_KEY } from "constants/globalConstants";
import ApiService from "apiService";
import { useQuery } from "react-query";

const useAllEntityList = () => {
  return useQuery(
    QUERY_KEY.GET_ALL_ELITE_LIST,
    () => ApiService.getAllEntityList(),
    { retry: false }
  );
};

export default useAllEntityList;
