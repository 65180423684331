import { Box, Flex, Square } from "@chakra-ui/layout";
import React, { useState } from "react";
import { Text } from "@chakra-ui/layout";
import "../../../assets/css/grid.css";
import { Grid, GridItem, Image } from "@chakra-ui/react";
import {
  Popover,
  PopoverArrow,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  PopoverBody,
} from "@chakra-ui/popover";
import { useHistory } from "react-router";
import noGroupImage from "../../../assets/img/layout/noGroup.png";
import { GrNetworkDrive } from "react-icons/gr";
import Icon from "@chakra-ui/icon";
import { GoPrimitiveDot } from "react-icons/go";
import LiveDashboardGroupCardsLoader from "views/admin/liveDashboard/liveDashboardGroupCardsLoader";
import AccordionLiveDashboard from "./accordionLiveDashboard";
import { GetEmptyBox } from "utils/emptyBox";
import { MdOutlineBrowserNotSupported } from "react-icons/md";
import { getDashboardPermissions } from "Permissions";

function LiveDashboardGroupCards(props) {
  const {
    liveDashboardData,
    liveDashboardCardLoading,
    isFetchingLiveDashboard,
    filteredData,
    entityId,
    entityName,
    blackout,
    loading,
  } = props;
  const [showPopOver, setShowPopOver] = useState(false);
  const history = useHistory();

  const handlePopOver = () => {
    setShowPopOver(true);
  };
  const arrayLength = liveDashboardData?.data?.length;

  const getBackgroundColor = (value) => {
    switch (value) {
      case "0":
        return "#EB5757";
      case "1":
        return "#27AE60";
      case "2":
        return "#135CC9";
      default:
        return "#828282";
    }
  };

  const getStatus = (value) => {
    switch (value) {
      case "0":
        return "Offline";
      case "1":
        return "Online";
      case "2":
        return "Schedule Down";
      default:
        return "IP not mapped";
    }
  };

  if (liveDashboardCardLoading || loading) {
    return <LiveDashboardGroupCardsLoader arrayLength={arrayLength} />;
  }

  if (isFetchingLiveDashboard) {
    return <LiveDashboardGroupCardsLoader arrayLength={arrayLength} />;
  }

  const getValue = (array) => {
    const length = [];
    array?.map((arr) => length.push(arr.value.length));
    return length.reduce((a, b) => a + b, 0);
  };

  return (
    <>
      {liveDashboardData?.data?.length > 0 ? (
        <>
          <Flex direction="column" mt="40px">
            <Grid templateColumns="repeat(3, 1fr)" gap={0}>
              {filteredData?.length > 0 &&
                filteredData?.map(
                  (value, idx) =>
                    value?.node_details?.length > 0 && (
                      <GridItem
                        key={idx}
                        className={`${
                          arrayLength === 2 ? "element-count2" : ""
                        } grid-item`}
                        p={
                          idx < 3 ? "5px 44px 45px 44px" : "35px 44px 45px 44px"
                        }
                      >
                        {" "}
                        <Flex justifyContent="space-between" mb={2}>
                          <Flex direction="column">
                            <Box
                              color="#2B3674"
                              fontSize="18px"
                              fontWeight="bold"
                            >
                              {" "}
                              {value?.group_name}
                            </Box>
                            <Box
                              color="#AEAEAE"
                              fontSize="12px"
                              fontWeight="bold"
                            >
                              {" "}
                              Total Nodes : {getValue(value?.node_details)}
                            </Box>
                          </Flex>
                          {/* <Flex>
            <Box>
              <Button
                bg="#EB5757"
                _hover={{ bg: "#2B3674" }}
                height="20px"
                ml={3}
              >
                <Text fontSize="12px" color={"#FFFFFF"}>
                  Requires Attention
                </Text>
              </Button>
            </Box>
          </Flex> */}
                        </Flex>
                        <Box className="status-grid-wrapper">
                          {value?.node_details?.map((nodeValue, idx) => (
                            <Box key={idx}>
                              <Text
                                color="#333333"
                                fontSize="12px"
                                fontWeight="bold"
                                mb={2}
                              >
                                {nodeValue?.node_type_name}
                              </Text>
                              <Box className="status-grid">
                                {nodeValue?.value?.map((val, id) => (
                                  <Popover key={id}>
                                    <PopoverTrigger>
                                      <Square
                                        size="16px"
                                        bg={getBackgroundColor(val.status)}
                                      ></Square>
                                    </PopoverTrigger>
                                    {!blackout && (
                                      <PopoverContent
                                        width="197px"
                                        background="rgba(0, 0, 0, 0.88)"
                                      >
                                        <PopoverArrow
                                          boxShadow="none !important"
                                          backgroundColor="rgba(0, 0, 0, 0.88) !important"
                                        />
                                        <PopoverCloseButton color="#ffffff" />
                                        <PopoverBody
                                          size="12px"
                                          color="#fff"
                                          fontWeight="700"
                                        >
                                          {getDashboardPermissions(
                                            "node_dashboard.view"
                                          ) ? (
                                            <Flex
                                              alignItems="center"
                                              cursor="pointer"
                                              onClick={() =>
                                                history.push(
                                                  `/admin/node-dashboard/${entityId}/${entityName}/node_id=${val.node_id}`
                                                )
                                              }
                                            >
                                              <Icon
                                                fill="#fff"
                                                as={GrNetworkDrive}
                                                w="17px"
                                                h="17px"
                                                mr="10px"
                                              />
                                              <Text> {val.node_name}</Text>
                                            </Flex>
                                          ) : (
                                            <Flex alignItems="center">
                                              <Icon
                                                fill="#fff"
                                                as={GrNetworkDrive}
                                                w="17px"
                                                h="17px"
                                                mr="10px"
                                              />
                                              <Text> {val.node_name}</Text>
                                            </Flex>
                                          )}
                                          <Text size="12px" fontWeight="400">
                                            {" "}
                                            {val.ip_address}
                                          </Text>
                                          <Text
                                            size="12px"
                                            color={getBackgroundColor(
                                              val.status
                                            )}
                                            fontWeight="400"
                                          >
                                            {" "}
                                            <Icon
                                              as={GoPrimitiveDot}
                                              width="15px"
                                              height="15px"
                                              color={getBackgroundColor(
                                                val.status
                                              )}
                                            />{" "}
                                            {getStatus(val.status)}
                                          </Text>

                                          {/* <Text
                                          size="12px"
                                          color="#2F80ED"
                                          fontWeight="400"
                                        >
                                          {" "}
                                          1 Ticket Raised
                                        </Text> */}
                                        </PopoverBody>
                                      </PopoverContent>
                                    )}
                                  </Popover>
                                ))}
                              </Box>
                            </Box>
                          ))}
                        </Box>
                      </GridItem>
                    )
                )}
              <GetEmptyBox object={arrayLength} />
            </Grid>
          </Flex>

          <AccordionLiveDashboard
            getValue={getValue}
            filteredData={filteredData}
          />
        </>
      ) : (
        <Flex
          direction="column"
          justify="center"
          align="center"
          marginLeft="300px"
          marginTop="100px"
          width="400px"
        >
          <Icon
            as={MdOutlineBrowserNotSupported}
            width="70px"
            height="70px"
            color="ndmsColors.blue.100"
          />
          <Text fontSize={"22px"} color="ndmsColors.blue.100" fontWeight="bold">
            No Data Found!!
          </Text>
        </Flex>
      )}
    </>
  );
}

export default LiveDashboardGroupCards;
