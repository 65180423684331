import React, { useEffect } from "react";
import Card from "components/card/Card.js";
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Skeleton,
  Select,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import TicketModuleHooks from "hooks/ticketModule/ticketModuleHooks";

function AddTicketReason({ setShowAddForm, entityId, entityName }) {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  let activeColor = useColorModeValue("gray.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const buttonHover = useColorModeValue("brand.600", "brand.400");
  const history = useHistory();
  const {
    isLoading: isStatusLoading,
    error,
    data,
    refetch,
    isFetching,
  } = TicketModuleHooks.useTicketClosedStatus();

  useEffect(() => {
    refetch();
  }, []);

  const { mutate: addReason, isLoading: isReasonLoading } =
    TicketModuleHooks.useAddTicketReason();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const generateStatusOptions = () => {
    return data?.data?.map((status) => {
      return <option value={status.id}>{status.name}</option>;
    });
  };

  const onSubmit = (data) => {
    addReason({
      reason: data.reason,
      slug: data.slug,
      status_id: data.status_id,
    });
  };

  const handleCancel = () => {
    history.push(`/admin/ticket-crud/ticket-reason`);
  };

  return (
    <>
      <Box w="100%" pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Card>
          <Flex
            maxW={{ base: "100%" }}
            w="100%"
            mx={{ base: "auto", lg: "0px" }}
            me="auto"
            h="100%"
            alignItems="start"
            justifyContent="center"
            mb={{ base: "30px", md: "30px" }}
            px={{ base: "25px", md: "0px" }}
            mt={{ base: "20px", md: "20px" }}
            flexDirection="column"
          >
            <Box me="auto">
              <Text
                mb="24px"
                ms="4px"
                color={activeColor}
                fontWeight="bold"
                fontSize="24px"
              >
                Add Ticket Reason
              </Text>
            </Box>
            <Flex
              direction="column"
              w="100%"
              maxW="100%"
              background="transparent"
              borderRadius="15px"
              mx={{ base: "auto", lg: "unset" }}
              me="auto"
              mb={{ base: "20px", md: "auto" }}
            >
              <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
                <Flex w="100%">
                  <Flex direction="column" width="100%" pl="10px">
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                    >
                      Reason<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <div style={{ marginBottom: "24px" }}>
                      <Controller
                        name="reason"
                        rules={{
                          required: true,
                          validate: (value) => {
                            return !!value.trim();
                          },
                        }}
                        control={control}
                        render={({ field }) => (
                          <Input
                            fontSize="sm"
                            ms={{ base: "0px", md: "0px" }}
                            type="name"
                            placeholder="Enter Reason"
                            //mb="24px"
                            fontWeight="500"
                            size="lg"
                            {...field}
                          />
                        )}
                      />
                      {errors.reason?.type === "required" && (
                        <span style={{ color: "red" }}>reason is required</span>
                      )}
                      {errors.reason?.type === "validate" && (
                        <span style={{ color: "red" }}>reason is required</span>
                      )}
                    </div>
                  </Flex>
                  <Flex direction="column" width="100%" pl="10px">
                    <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      display="flex"
                    >
                      Slug<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <div style={{ marginBottom: "24px" }}>
                      <Controller
                        name="slug"
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                          <Input
                            fontSize="sm"
                            placeholder="Enter Slug"
                            size="lg"
                            type="name"
                            {...field}
                          />
                        )}
                      />
                      {errors.slug?.type === "required" && (
                        <span style={{ color: "red" }}>slug is required</span>
                      )}
                    </div>
                  </Flex>
                </Flex>

                <Flex direction="column" width="50%" pl="10px">
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    isRequired={true}
                    display="flex"
                  >
                    Status
                  </FormLabel>
                  {isStatusLoading || isFetching ? (
                    <Skeleton width="100%" height="50px" mb="24px" />
                  ) : (
                    <div style={{ marginBottom: "24px" }}>
                      <Controller
                        name="status_id"
                        rules={{ required: false }}
                        control={control}
                        render={({ field }) => (
                          <Select
                            id="user_type"
                            size="lg"
                            fontSize="14px"
                            placeholder="Select Status"
                            {...field}
                          >
                            {generateStatusOptions()}
                          </Select>
                        )}
                      />
                    </div>
                  )}
                </Flex>

                <Flex width="40%" pl="10px">
                  <Button
                    fontSize="sm"
                    variant="brand"
                    fontWeight="500"
                    w="90%"
                    h="50"
                    mb="24px"
                    mr="20px"
                    type="submit"
                    isLoading={isReasonLoading}
                    _hover={{ bg: isReasonLoading ? "blue" : buttonHover }}
                  >
                    Submit
                  </Button>
                  <Button
                    fontSize="sm"
                    variant="brand"
                    fontWeight="500"
                    w="90%"
                    h="50"
                    mb="24px"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Flex>
              </form>
            </Flex>
          </Flex>
        </Card>
      </Box>
    </>
  );
}

export default AddTicketReason;
