import { CalendarIcon } from "@chakra-ui/icons";
import {
  Button,
  Flex,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Skeleton,
  Text,
  //Select,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import TicketModuleHooks from "hooks/ticketModule/ticketModuleHooks";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";
import TmsConfirmationModal from "./tmsConfirmationModal";

function MarkAsClosedModal({
  isOpen,
  onOpen,
  onClose,
  nodeData,
  ticketId,
  data,
  groupData,
  entityId,
  setShowCreateGroup,
}) {
  const {
    handleSubmit,
    control,
    resetField,
    clearErrors,
    formState: { errors },
  } = useForm();
  const {
    isOpen: isConfOpen,
    onOpen: onConfOpen,
    onClose: onConfClose,
  } = useDisclosure();
  const [confData, setConfData] = useState(null);
  const [statusId, setStatusId] = useState(null);
  const textColor = useColorModeValue("navy.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const buttonHover = useColorModeValue("brand.600", "brand.400");
  const [disable, setDisable] = useState(true);

  const handleModalClose = () => {
    resetField("status");
    resetField("reason");
    resetField("status_updated_at");
    setStatusId(null);
    clearErrors();
    onClose();
  };

  const {
    data: statusData,
    isLoading: statusLoading,
    error: statusError,
  } = TicketModuleHooks.useTicketClosedStatus();

  const {
    data: reasonData,
    isLoading: isReasonLoading,
    error: reasonError,
    refetch: reasonRefetch,
    isFetching: isReasonFetching,
  } = TicketModuleHooks.useTicketReason(statusId);

  useEffect(() => {
    if (statusId !== null && statusId !== "") {
      setDisable(false);
      reasonRefetch();
    } else {
      setDisable(true);
    }
  }, [statusId]);

  const { mutate: maskAsClosed, isLoading } = TicketModuleHooks.useMarkAsClosed(
    ticketId,
    handleModalClose,
    onConfClose
  );

  const generateStatusOptions = () => {
    return statusData?.data?.map((status) => {
      return <option value={status?.id}>{status?.name}</option>;
    });
  };

  const generateReasonOptions = () => {
    return reasonData?.data?.map((reason) => {
      return <option value={reason?.id}>{reason?.reason}</option>;
    });
  };

  const onConfirmation = () => {
    maskAsClosed({
      status: confData?.status,
      reason: confData?.reason,
      status_updated_at: confData?.status_updated_at,
    });
  };

  const onSubmit = (data) => {
    onConfOpen();
    setConfData(data);
  };
  return (
    <>
      <Modal
        isOpen={isOpen}
        blockScrollOnMount={false}
        onClose={handleModalClose}
        size="sm"
      >
        <ModalOverlay />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContent>
            <ModalHeader>
              <Flex w="100%" mt="15px" direction="column" alignItems="center">
                <Text>Mark As Closed</Text>
                <Flex direction="column" width="100%">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Status<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <div style={{ marginBottom: "10px" }}>
                    <Controller
                      name={`status`}
                      rules={{ required: true }}
                      control={control}
                      render={({ field }) => (
                        <Select
                          bg="white"
                          fontSize="14px"
                          height="40px"
                          id="user_type"
                          size="lg"
                          color={
                            field.value === "Choose Status" ||
                            field.value === null ||
                            field.value === undefined
                              ? "ndmsColors.gray.2200"
                              : "black"
                          }
                          fontWeight={
                            field.value === "Choose Status" ||
                            field.value === null ||
                            field.value === undefined
                              ? "bold"
                              : "400"
                          }
                          mt="5px"
                          onChange={(e) => {
                            field.onChange(e.target.value);
                            setStatusId(e.target.value);
                          }}
                        >
                          <option selected disabled hidden value={null}>
                            Choose Status
                          </option>
                          {generateStatusOptions()}
                        </Select>
                      )}
                    />
                    {errors.status?.type === "required" && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        status is required
                      </span>
                    )}
                  </div>
                </Flex>
                <Flex direction="column" width="100%" pt="10px">
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    isRequired={true}
                    display="flex"
                  >
                    Reason<Text color={brandStars}>*</Text>
                  </FormLabel>
                  {isReasonLoading || isReasonFetching ? (
                    <Skeleton width="100%" height="40px" />
                  ) : (
                    <div style={{ marginBottom: "20px" }}>
                      <Controller
                        name="reason"
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                          <Select
                            bg="white"
                            fontSize="14px"
                            height="40px"
                            id="user_type"
                            size="lg"
                            disabled={disable}
                            color={
                              field.value === "Choose Reason" ||
                              field.value === null ||
                              field.value === undefined
                                ? "ndmsColors.gray.2200"
                                : "black"
                            }
                            fontWeight={
                              field.value === "Choose Reason" ||
                              field.value === null ||
                              field.value === undefined
                                ? "bold"
                                : "400"
                            }
                            mt="5px"
                            {...field}
                          >
                            <option selected disabled hidden value={null}>
                              Choose Reason
                            </option>
                            {generateReasonOptions()}
                          </Select>
                        )}
                      />
                      {errors.reason?.type === "required" && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          reason is required
                        </span>
                      )}
                    </div>
                  )}
                </Flex>
                {data?.ticket_type_name?.slug == "node_down" ||
                data?.ticket_type_name?.slug === "untrace_node_down" ? (
                  <Flex direction="column" width="100%" pt="10px">
                    <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      isRequired={true}
                      display="flex"
                    >
                      Closed At<Text color={brandStars}>*</Text>
                    </FormLabel>

                    <div style={{ marginBottom: "20px" }}>
                      <Controller
                        name={`status_updated_at`}
                        rules={{ required: false }}
                        control={control}
                        render={({ field }) => (
                          <DatePicker
                            wrapperClassName="date-picker"
                            showTimeSelect
                            selectMultiple={true}
                            customInput={
                              <InputGroup>
                                <Input
                                  minWidth="170px"
                                  placeholder=""
                                  value={
                                    field.value
                                      ? `${field?.value?.toLocaleDateString()}, ${field.value.toLocaleTimeString(
                                          "en-US"
                                        )}`
                                      : ""
                                  }
                                />
                                <InputRightElement
                                  children={<CalendarIcon color="#4318FF" />}
                                />
                              </InputGroup>
                            }
                            onChange={(date) => {
                              field.onChange(date);
                            }}
                            selected={field.value ? field.value : ""}
                            maxDate={new Date()}
                            dateFormat="MMMM d, yyyy h:mm aa"
                          />
                        )}
                      />
                      {errors.reason?.type === "required" && (
                        <span style={{ color: "red", fontSize: "14px" }}>
                          required
                        </span>
                      )}
                    </div>
                  </Flex>
                ) : (
                  ""
                )}
              </Flex>
            </ModalHeader>
            <Button
              bg="#4318FF"
              variant="brand"
              ml={5}
              mb={10}
              type="submit"
              width="120px"
            >
              <Text color={"#FFFFFF"}>Save</Text>
            </Button>
            <ModalCloseButton />
          </ModalContent>
        </form>
      </Modal>
      <TmsConfirmationModal
        isOpen={isConfOpen}
        onOpen={onConfOpen}
        onClose={onConfClose}
        onConfirmation={onConfirmation}
        isLoading={isLoading}
        text={`Are you sure you want to mark this ticket as closed?`}
      />
    </>
  );
}

export default MarkAsClosedModal;
