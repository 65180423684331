import { SearchIcon } from "@chakra-ui/icons";
import React from "react";
import { Input } from "@chakra-ui/react";
import { InputGroup } from "@chakra-ui/input";
import { InputLeftElement } from "@chakra-ui/input";

function SearchData(props) {
  const { setInputText, searchData, blackout } = props;
  const inputHandler = (e) => {
    var lowerCase = e.target.value.toLowerCase();
    setInputText(lowerCase);
  };

  return (
    <InputGroup
      mt="35px"
      width="200px"
      // onClick={searchData}
      onKeyUp={searchData}
    >
      <InputLeftElement
        pointerEvents="none"
        children={<SearchIcon color="gray.500" />}
      />
      <Input
        type="text"
        placeholder="search"
        onChange={inputHandler}
        disabled={blackout ? true : false}
      />
    </InputGroup>
  );
}

export default SearchData;
