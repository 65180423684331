import React from "react";
import Card from "components/card/Card.js";
import { useHistory } from "react-router";
import { Button, Flex, Text } from "@chakra-ui/react";

const ServerError = () => {
  const history = useHistory();
  const mainBranding = localStorage.getItem("main_branding");

  return (
    <Flex
      bg="white"
      align="center"
      justify="center"
      height="100vh"
      position="relative"
    >
      <Text
        position="absolute"
        top="5"
        left="20"
        color="#2B3674"
        fontSize="30px"
        fontWeight="bold"
      >
        {mainBranding}
      </Text>
      <Card
        boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
        direction="column"
        w="600px"
        h="400px"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex direction="column" align="center" mt="20px">
          <Text color="#A3AED0" fontSize="50px" fontWeight="bold">
            500
          </Text>
          <Text color="black" fontSize="30px" fontWeight="bold">
            Internal Server Error
          </Text>
          <Text color="#A3AED0" mt="20px" fontSize="20px">
            The Server has been deserted for a while.
          </Text>
          <Text color="#A3AED0" fontSize="20px">
            Please be patient or try again later.
          </Text>
          <Button
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="151px"
            h="50"
            mt="24px"
            mr="20px"
            onClick={() => history.goBack()}
          >
            <Text fontSize={"16px"} color="#FFFFFF" fontWeight="bold">
              Go Back
            </Text>
          </Button>
        </Flex>
      </Card>
    </Flex>
  );
};

export default ServerError;
