import React, { useState, useEffect } from "react";
import Card from "components/card/Card.js";
import DownTimeListTable from "components/table/downTimeListTable";
// import EntityClientHooks from "hooks/entityClient/entityClientHooks";
import LoadingForm from "components/loader/LoadingForm";
import noGroupImage from "assets/img/layout/noGroup.png";
import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Tooltip,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

// import AddClientList from "./addClientList";
// import EditClientList from "./editClientList";
import { useHistory, useParams } from "react-router-dom";
import DeleteModal from "components/modal/deleteModal";
import { getDashboardPermissions } from "Permissions";
import TicketModuleHooks from "hooks/ticketModule/ticketModuleHooks";
import { AddIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";

function TicketTypes() {
  // Chakra color mode
  const entityId = useParams().id;
  const [currentPage, setCurrentPage] = useState(1);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const { isLoading, error, data, refetch, isFetching } =
    TicketModuleHooks.useTypeList();
  const [deleteId, setDeleteId] = useState(null);
  const [clientId, setClientId] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const history = useHistory();
  const { mutate: deleteTicketType, isLoading: isDeleteLoading } =
    TicketModuleHooks.useDeleteTicketType(onClose);
  const entityName = useParams().name;
  const [editData, setEditData] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showAddForm, showEditForm]);

  //   useEffect(() => {
  //     refetch();
  //   }, [entityId, currentPage]);

  if (isLoading || isFetching) return <LoadingForm />;
  // if (error) return toast.error("internal server error");

  const handleDelete = (id) => {
    setDeleteId(id);
    onOpen();
    //deleteEntity(id);
  };
  const handleEdit = (id) => {
    history.push(`/admin/ticket-crud/ticket-crud-types/edit/${id}`);
  };
  const handleAdd = () => {
    history.push(`/admin/entity-client-list/${entityId}/${entityName}/add`);
    setShowAddForm(true);
  };

  const columnsData = [
    {
      Header: "ID",
      accessor: (row) => {
        return (
          <Text
            color={"ndmsColors.purple.200"}
            fontSize="12px"
            fontWeight="700"
          >
            {row.id}
          </Text>
        );
      },
    },
    {
      Header: "NAME",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row.name}
          </Text>
        );
      },
    },
    {
      Header: "SLUG",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row.slug}
          </Text>
        );
      },
    },
    {
      Header: "   ",
      accessor: "   ",
    },
    {
      Header: "   ",
      accessor: "------",
    },
    {
      Header: "   ",
      accessor: "=======",
    },
    {
      Header: "   ",
      accessor: "++++++++++",
    },
    {
      Header: "ACTION",
      accessor: (row) => {
        return (
          <Flex justifyContent="center" align="center">
            {getDashboardPermissions("ticketing.ticket_type.update") ? (
              <Tooltip hasArrow label="Edit" bg="#2B3674">
                <Flex mr="10px" onClick={() => handleEdit(row.id)}>
                  <Icon
                    as={EditIcon}
                    width="17px"
                    height="17px"
                    color="ndmsColors.purple.200"
                  />{" "}
                </Flex>
              </Tooltip>
            ) : null}
            {getDashboardPermissions("ticketing.ticket_type.delete") ? (
              <Tooltip hasArrow label="Delete" bg="ndmsColors.red.200">
                <Flex onClick={() => handleDelete(row.id)}>
                  <Icon
                    as={DeleteIcon}
                    width="17px"
                    height="17px"
                    color="ndmsColors.red.200"
                  />{" "}
                </Flex>
              </Tooltip>
            ) : null}
          </Flex>
        );
      },
    },
  ];

  return (
    <>
      {/* {showAddForm && (
        <AddClientList
          entityId={entityId}
          entityName={entityName}
          setShowAddForm={setShowAddForm}
        />
      )}
      {showEditForm && (
        <EditClientList
          setShowEditForm={setShowEditForm}
          editData={editData}
          entityId={entityId}
          entityName={entityName}
        />
      )} */}
      {!showAddForm && !showEditForm && (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
          <Flex align="center" justifyContent="flex-end">
            {getDashboardPermissions("ticketing.ticket_type.create") ? (
              <Button
                bg="ndmsColors.purple.200"
                minWidth="104px"
                borderRadius="6px"
                mb="42px"
                _hover={{ bg: "ndmsColors.blue.400" }}
                _active={{ bg: "ndmsColors.blue.400" }}
                onClick={() =>
                  history.push(`/admin/ticket-crud/ticket-crud-types/add`)
                }
                mr="10px"
              >
                <Text ml="10px" color="#FFF" fontSize="14px">
                  {" "}
                  Add Ticket Types
                </Text>
              </Button>
            ) : null}
            {/* <Button
              bg="ndmsColors.purple.200"
              minWidth="104px"
              borderRadius="6px"
              mb="42px"
              _hover={{ bg: "ndmsColors.blue.400" }}
              _active={{ bg: "ndmsColors.blue.400" }}
              onClick={handleAdd}
            >
              <Icon as={AddIcon} w={3} h={3} color="#FFF" />
              <Text ml="10px" color="#FFF" fontSize="14px">
                {" "}
                Add
              </Text>
            </Button> */}
          </Flex>
          <Card
            direction="column"
            w="100%"
            px="0px"
            overflowX={{ sm: "scroll", lg: "hidden" }}
          >
            {data?.data?.length > 0 ? (
              <Flex p="20px" direction="column">
                <DownTimeListTable
                  tableData={data?.data}
                  currentPage={currentPage}
                  columnsData={columnsData}
                  setCurrentPage={setCurrentPage}
                  totalPage={1}
                />
              </Flex>
            ) : (
              <Flex
                align="center"
                justify="center"
                mt="200px"
                cursor="pointer"
                mb="150px"
                //onClick={handleAdd}
              >
                <Flex direction="column" justify="center" align="center">
                  <Image src={noGroupImage} width="70px" alt="no-group" />
                  <Text fontSize={"22px"} color="#2B3674" fontWeight="bold">
                    You Don’t Have Any Ticket Type Added
                  </Text>
                </Flex>
              </Flex>
            )}
          </Card>
        </Box>
      )}
      <DeleteModal
        onOpen={onOpen}
        isOpen={isOpen}
        onClose={onClose}
        id={deleteId}
        deleteItem={deleteTicketType}
        isLoading={isDeleteLoading}
      />
    </>
  );
}

export default TicketTypes;
