import Icon from "@chakra-ui/icon";
import { ArrowBackIcon } from "@chakra-ui/icons";
import { Text, Flex } from "@chakra-ui/layout";
import {
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/popover";
import Card from "components/card/Card";
import React from "react";
import { AiOutlineRight } from "react-icons/ai";
import { GoPrimitiveDot } from "react-icons/go";
import { GrNetworkDrive } from "react-icons/gr";

function EntireNodeLiveDashboard(props) {
  const { showMoreValue, setShowAllData } = props;

  const getBackgroundColor = (value) => {
    switch (value) {
      case "0":
        return "ndmsColors.red.100";
      case "1":
        return "ndmsColors.green.100";
      case "2":
        return "#135CC9";
      default:
        return "ndmsColors.gray.700";
    }
  };

  const getValue = (array) => {
    const length = [];
    array?.map((arr) => length.push(arr.value.length));
    return length.reduce((a, b) => a + b, 0);
  };

  const getStatus = (value) => {
    switch (value) {
      case "0":
        return "Offline";
      case "1":
        return "Online";
      case "2":
        return "Schedule Down";
      default:
        return "IP not mapped";
    }
  };

  return (
    <Flex mt="20px" height="229px" justifyContent="flex-start" flexWrap="wrap">
      <>
        <Card
          ml="20px"
          bg="ndmsColors.black.100"
          borderRadius="15px"
          minHeight="229px"
          alignItems="center"
          marginBottom="12px"
        >
          <Flex
            align="center"
            pb="9px"
            width="100%"
            borderBottom="1px solid #F1F1F1"
            justifyContent="space-between"
          >
            <Flex
              fontSize="18px"
              pt="3px"
              color="ndmsColors.blue.100"
              fontWeight="bold"
              alignItems="center"
            >
              <Flex cursor="pointer" onClick={() => setShowAllData(false)}>
                <Icon as={ArrowBackIcon} w={6} h={6} />
              </Flex>
              {showMoreValue?.group_name}
            </Flex>
            <Text color="ndmsColors.gray.300" fontSize="12px" fontWeight="bold">
              Total Nodes : {getValue(showMoreValue?.node_details)}
            </Text>
          </Flex>

          <Flex
            flexWrap="wrap"
            mt="8px"
            color="ndmsColors.gray.700"
            fontSize="12px"
            width="100%"
          >
            {showMoreValue?.node_details?.map((nodeData) =>
              nodeData?.value?.map((nodeValues, id) => (
                <>
                  <Popover>
                    <PopoverTrigger>
                      <Flex
                        borderRadius="50px"
                        bg="ndmsColors.gray.50"
                        alignItems="center"
                        mt="8px"
                        p="6px"
                        mr="10px"
                        maxHeight="29px"
                        key={id}
                        cursor="pointer"
                      >
                        <Icon
                          as={GoPrimitiveDot}
                          width="18px"
                          height="18px"
                          color="ndmsColors.red.100"
                          color={getBackgroundColor(nodeValues.status)}
                        />
                        <Text m="0 8px 0px 2px"> {nodeValues.node_name} </Text>
                        <Icon as={AiOutlineRight} color="ndmsColors.gray.700" />

                        <PopoverContent
                          width="150px"
                          background="rgba(0, 0, 0, 0.88)"
                        >
                          <PopoverArrow
                            boxShadow="none !important"
                            backgroundColor="rgba(0, 0, 0, 0.88) !important"
                          />
                          <PopoverCloseButton color="ndmsColors.black.100" />
                          <PopoverBody
                            size="12px"
                            color="ndmsColors.black.100"
                            fontWeight="700"
                          >
                            <Flex
                              alignItems="center"
                              cursor="pointer"
                              // onClick={() =>
                              //   history.push(
                              //     `/admin/node-dashboard/${entityId}/node_id=${val.node_id}`
                              //   )
                              // }
                            >
                              <Icon
                                fill="ndmsColors.black.100"
                                as={GrNetworkDrive}
                                w="17px"
                                h="17px"
                                mr="10px"
                              />
                              <Text>{nodeData?.node_type_name}</Text>
                            </Flex>
                            <Flex
                              size="12px"
                              color={getBackgroundColor(nodeValues.status)}
                              fontWeight="400"
                            >
                              {" "}
                              <Icon
                                as={GoPrimitiveDot}
                                width="18px"
                                height="18px"
                                color={getBackgroundColor(nodeValues.status)}
                              />{" "}
                              {getStatus(nodeValues.status)}
                            </Flex>
                          </PopoverBody>
                        </PopoverContent>
                      </Flex>
                    </PopoverTrigger>
                  </Popover>
                </>
              ))
            )}
          </Flex>
        </Card>
      </>
    </Flex>
  );
}

export default EntireNodeLiveDashboard;
