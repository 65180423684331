import { Box, Center, Divider, Flex } from "@chakra-ui/layout";
import {
  GridIconDark,
  GridIconLight,
  ListIconDark,
  ListIconLight,
} from "components/icons/Icons";
import SearchData from "components/search/searchData";
import useGetEntityNodeFilter from "hooks/liveDashboard/useGetEntityNodeFilter";
import useLiveDashboardNodeData from "hooks/liveDashboard/useLiveDashboardNodeData";
import useNodeTypes from "hooks/useNodeTypes";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import LiveDashboardCards from "./LiveDashboardCards";
import BlackoutCard from "./blackoutCard";
import LiveDashboardFilter from "./liveDashboardFilter";
import LiveDashboardGroupCards from "./liveDashboardGroupCards";
import LiveDashboardToggleViewCards from "./liveDashboardToggleViewCards";

function LiveDashboard() {
  const [liveData, setLiveData] = useState(true);
  const [nodeTypeId, setNodeTypeId] = useState(null);
  const [inputText, setInputText] = useState("");
  const [toggleViewMode, setToggleViewMode] = useState(true);
  const [blackout, setBlackout] = useState(false);
  const [loading, setLoading] = useState(false);
  const entityId = parseInt(useParams()?.id);
  const entityName = useParams()?.name;
  const {
    isLoading: entityNodeLoading,
    error: entityNodeError,
    data: entityNodeData,
    refetch: refetchNodeData,
    isFetching: isFetchingNodeData,
  } = useGetEntityNodeFilter(entityId);
  const {
    isLoading: liveDashboardCardLoading,
    error: liveDashboardCardError,
    data: liveDashboardData,
    refetch: refetchDashboardData,
    isFetching: isFetchingLiveDashboard,
  } = useLiveDashboardNodeData(entityId, nodeTypeId);
  const {
    isLoading: nodeTypeLoading,
    error: nodeTypeError,
    data: nodeTypeList,
  } = useNodeTypes();
  const [dashboardCardsData, setDashboardCardsData] = useState(
    liveDashboardData?.data
  );
  const [filteredData, setFilteredData] = useState(liveDashboardData?.data);
  useEffect(() => {
    refetchNodeData();
    refetchDashboardData();
  }, [entityId]);

  useEffect(() => {
    if (entityNodeData?.data?.blackout === 0) {
      setBlackout(false);
    } else if (entityNodeData?.data?.blackout === 1) {
      setBlackout(true);
    }
  }, [entityNodeData]);

  useEffect(() => {
    setDashboardCardsData(liveDashboardData?.data);
    setFilteredData(liveDashboardData?.data);
  }, [liveDashboardData]);

  useEffect(() => {
    if (nodeTypeId !== null) {
      refetchDashboardData();
    }
  }, [nodeTypeId]);

  const searchData = () => {
    if (inputText?.length >= 3 || inputText === "") {
      setLoading(true);
      setTimeout(() => {
        searchValue();
        setLoading(false);
      }, 1000);
    }
  };
  const handleToggleMode = () => {
    if (toggleViewMode) {
      setToggleViewMode(false);
    } else setToggleViewMode(true);
  };
  const searchValue = () => {
    if (inputText !== "" && inputText.length >= 3) {
      const _data = liveDashboardData.data.map((obj) => {
        const filteredItems = obj.node_details.filter((item) => {
          const filteredValue = item.value.filter(
            (i) => i.node_name.toLowerCase().indexOf(inputText) !== -1
          );
          if (
            filteredValue.length ||
            item.node_type_name.toLowerCase().indexOf(inputText) !== -1
          )
            return {
              ...item,
              value: filteredValue,
            };
        });
        // if (
        //   filteredItems.length ||
        //   obj.group_name.toLowerCase().indexOf(inputText) !== -1
        // ) {
        return {
          ...obj,
          node_details: filteredItems,
        };
        // }
      });
      const data = _data.filter((element) => element !== undefined);

      setFilteredData(data);
      setLoading(false);
    } else {
      setFilteredData(liveDashboardData?.data);
      setLoading(false);
    }
  };

  const filterStatusData = (val = "") => {
    if (val) {
      const _data = liveDashboardData.data.map((obj) => {
        const filteredItems = obj.node_details.map((item) => {
          const filteredValue = item.value.filter(
            (i) => i.status.toString() === val.toString()
          );

          return {
            ...item,
            value: filteredValue,
          };
        });

        return {
          ...obj,
          node_details: filteredItems,
        };
      });
      setFilteredData(_data);
    } else {
      setFilteredData(liveDashboardData.data);
    }
  };

  return (
    <Box pb="50px">
      {blackout ? (
        <BlackoutCard time={entityNodeData?.data?.last_blackout_time} />
      ) : (
        ""
      )}
      <Box opacity={blackout ? "0.5" : ""}>
        {liveData ? (
          <>
            <LiveDashboardCards
              entityNodeLoading={entityNodeLoading}
              entityNodeError={entityNodeError}
              entityNodeData={entityNodeData}
              isFetchingNodeData={isFetchingNodeData}
              filterStatusData={filterStatusData}
            />
            <Flex alignItems="center" justifyContent="space-between">
              <LiveDashboardFilter
                nodeTypeLoading={nodeTypeLoading}
                nodeTypeError={nodeTypeError}
                nodeTypeList={nodeTypeList}
                setNodeTypeId={setNodeTypeId}
                nodeTypeId={nodeTypeId}
                blackout={blackout}
              />
              <Flex alignItems="center">
                <Flex
                  align="center"
                  justify="space-around"
                  height="40px"
                  bg="white"
                  width="80px"
                  mt="35px"
                  mr="10px"
                  border="1px solid #DEDEDE"
                  borderRadius="6px"
                  cursor="pointer"
                >
                  <Box pl="5px" pt="2px" onClick={handleToggleMode}>
                    {toggleViewMode ? <GridIconDark /> : <GridIconLight />}
                  </Box>
                  <Center height="40px">
                    <Divider orientation="vertical" />
                  </Center>
                  <Box pr="10px" onClick={handleToggleMode}>
                    {toggleViewMode ? <ListIconLight /> : <ListIconDark />}
                  </Box>
                </Flex>
                <SearchData
                  setInputText={setInputText}
                  searchData={searchData}
                  blackout={blackout}
                />
              </Flex>
            </Flex>

            {toggleViewMode ? (
              <LiveDashboardGroupCards
                liveDashboardData={liveDashboardData}
                liveDashboardCardLoading={liveDashboardCardLoading}
                isFetchingLiveDashboard={isFetchingLiveDashboard}
                filteredData={filteredData}
                entityId={entityId}
                entityName={entityName}
                blackout={blackout}
                loading={loading}
              />
            ) : (
              <LiveDashboardToggleViewCards
                liveDashboardData={liveDashboardData}
                liveDashboardCardLoading={liveDashboardCardLoading}
                isFetchingLiveDashboard={isFetchingLiveDashboard}
                filteredData={filteredData}
                entityId={entityId}
                blackout={blackout}
                entityName={entityName}
                loading={loading}
              />
            )}
          </>
        ) : (
          ""
        )}
      </Box>
    </Box>
  );
}

export default LiveDashboard;
