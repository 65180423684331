/* eslint-disable */
import {
  Flex,
  Progress,
  Table,
  Tbody,
  Text,
  Input,
  Td,
  Box,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import React, { useState, useMemo } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

export default function NodeTable(props) {
  const { columnsData, tableData, register, name } = props;
  const [editInputField, setEditInputField] = useState(null);

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const hideState = { hiddenColumns: ["Node Type Id"] };

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  initialState.pageSize = 1000;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("secondaryGray.500", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  return (
    <Table {...getTableProps()} variant="simple" color="gray.500" mb="24px">
      <Thead>
        {headerGroups.map((headerGroup, index) => (
          <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
            {headerGroup.headers.map((column, index) =>
              column.showHeader ? (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={index}
                  borderColor={borderColor}
                >
                  <Flex
                    //justify="space-evenly"
                    //pr="30px"
                    align="start"
                    color="#2B3674"
                    textTransform="capitalize"
                    style={{ fontWeight: "bold" }}
                  >
                    <Text fontSize="14px !important" fontWeight="bold">
                      {column.render("Header")}
                    </Text>
                  </Flex>
                </Th>
              ) : null
            )}
          </Tr>
        ))}
      </Thead>
      <Tbody {...getTableBodyProps()}>
        {page?.length > 0 ? (
          page.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, j) => {
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={j}
                      fontSize={{ sm: "14px" }}
                      style={{
                        display: columns[j]["showHeader"] ? "" : "none",
                      }}
                      borderColor="transparent"
                    >
                      <Flex align="center">
                        <Box position="relative">
                          {columns[j].Header === "Action" ||
                          columns[j].Header === "Device Type" ? (
                            cell.render("Cell")
                          ) : (
                            <Input
                              type="text"
                              width={
                                columns[j].Header === "Device Id"
                                  ? "100px"
                                  : "200px"
                              }
                              name={`${name}[${index}][${columns[j]["accessor"]}]`}
                              defaultValue={cell.value}
                              {...register(
                                `${name}[${index}][${columns[j]["accessor"]}]`
                              )}
                              //onClick={()=>setEditInputField(cell[j].value)}
                              //innerRef={methods.register}
                            />
                          )}
                          {/* {editInputField === cell[j].value ? 'hey' : ''} */}
                        </Box>
                      </Flex>
                    </Td>
                  );
                })}
              </Tr>
            );
          })
        ) : (
          <Flex align="center">
            <Text pt="10px" pl="10px">
              No Device
            </Text>
          </Flex>
        )}
      </Tbody>
    </Table>
  );
}
