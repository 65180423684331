import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Circle,
  Flex,
  Tabs,
  Icon,
  TabList,
  Skeleton,
  TabPanels,
  Tab,
  useDisclosure,
  TabPanel,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { useParams } from "react-router-dom";
import EntityHistory from "./entityHistory";
import LiveDashboard from "../liveDashboard";
import { useHistory } from "react-router";
import dayjs from "dayjs";
import ScheduleDowntimeList from "../scheduleDowntimeList.js";
import EntityDashboardHooks from "hooks/entityDashboard/entityDashboardHooks";
import { GenerateLicenseIcon } from "components/icons/Icons";
import LicenseModal from "components/modal/licenseModal";
import { EditIcon } from "@chakra-ui/icons";
import { getDashboardPermissions } from "Permissions";

const EntityDashboard = () => {
  const entityId = parseInt(useParams()?.id);
  const entityName = useParams()?.name;
  const history = useHistory();
  const [tabType, setTabType] = useState("");
  const {
    isLoading,
    error,
    data: metaData,
    refetch,
    isFetching,
  } = EntityDashboardHooks.useEntityMetaData(entityId);

  const {
    isOpen: isLicenseOpen,
    onOpen: onLicenseOpen,
    onClose: onLicenseClose,
  } = useDisclosure();

  useEffect(() => {
    refetch();
  }, [entityId]);

  const handleLayout = () => {
    history.push(`/admin/layout-planning/${entityId}/${entityName}`);
  };

  return (
    <Box pt={{ base: "130px", md: "100px", xl: "100px" }} pb="50px">
      {isLoading || isFetching ? (
        <Card py="30px" width="100%">
          <Skeleton height="70px" width="100%" />
        </Card>
      ) : (
        <>
          <Flex justifyContent="flex-end">
            {getDashboardPermissions("entity.layout.view") ? (
              <Flex
                alignItems="center"
                pb="10px"
                cursor="pointer"
                onClick={handleLayout}
                justifyContent="flex-end"
              >
                <Icon
                  as={EditIcon}
                  width="20px"
                  height="15px"
                  color="ndmsColors.purple.200"
                />
                <Text
                  fontWeight="bold"
                  pl="5px"
                  color="#4318FF"
                  fontSize="15px"
                >
                  Layout Planning
                </Text>
              </Flex>
            ) : null}
          </Flex>
          <Card borderRadius="10px" px="25px" py="30px">
            <Flex justifyContent="space-between">
              <Flex direction="column" flexWrap="wrap">
                <Text fontWeight="bold" fontSize="18px">
                  Address
                </Text>
                <Text
                  fontWeight="bold"
                  color="ndmsColors.gray.1900"
                  fontSize="16px"
                >
                  {metaData?.data?.address?.city},
                </Text>
                <Text
                  fontWeight="bold"
                  color="ndmsColors.gray.1900"
                  fontSize="16px"
                >
                  {metaData?.data?.address?.state}
                </Text>
              </Flex>
              <Flex direction="column">
                <Text fontWeight="bold" fontSize="18px">
                  Status
                </Text>
                <Text
                  fontWeight="bold"
                  color="ndmsColors.gray.1900"
                  fontSize="16px"
                >
                  {metaData?.data?.is_sync ? "Syncing" : "Non Syncing"}
                </Text>
              </Flex>
              {metaData?.data?.last_sync_time ? (
                <Flex direction="column">
                  <Text fontWeight="bold" fontSize="18px">
                    Last Synced at
                  </Text>
                  <Text
                    fontWeight="bold"
                    color="ndmsColors.gray.1900"
                    fontSize="16px"
                  >
                    {`${dayjs(metaData?.data?.last_sync_time).format(
                      "DD MMM YYYY"
                    )},`}
                  </Text>
                  <Text
                    fontWeight="bold"
                    color="ndmsColors.gray.1900"
                    fontSize="16px"
                  >
                    {`${dayjs(metaData?.data?.last_sync_time).format(
                      "hh:mm a"
                    )}`}
                  </Text>
                </Flex>
              ) : null}
              <Flex direction="column">
                <Text fontWeight="bold" fontSize="18px">
                  Groups
                </Text>
                <Text
                  fontWeight="bold"
                  color="ndmsColors.gray.1900"
                  fontSize="16px"
                >
                  {metaData?.data?.total_groups}
                </Text>
              </Flex>
              <Flex direction="column">
                <Text fontWeight="bold" fontSize="18px">
                  Node Types
                </Text>
                <Text
                  fontWeight="bold"
                  color="ndmsColors.gray.1900"
                  fontSize="16px"
                >
                  {metaData?.data?.total_nodetypes}
                </Text>
              </Flex>
              <Flex direction="column">
                <Text fontWeight="bold" fontSize="18px">
                  Nodes / Unmapped Nodes
                </Text>
                <Text
                  fontWeight="bold"
                  color="ndmsColors.gray.1900"
                  fontSize="16px"
                >
                  {metaData?.data?.total_nodes} /{" "}
                  {metaData?.data?.unmapped_nodes}
                </Text>
              </Flex>
            </Flex>
          </Card>
        </>
      )}
      <Tabs mt="30px">
        <TabList>
          <Tab
            _selected={{
              color: "ndmsColors.blue.100",
              boxShadow: "none",
              borderBottom: "1px solid #4318FF",
              zIndex: "auto",
            }}
            color="#828282"
            fontSize="18px"
            fontWeight="bold"
          >
            <Flex align="center">
              <Circle size="6px" bg="red" mr="5px" />
              Live
            </Flex>
          </Tab>
          <Tab
            _selected={{
              color: "ndmsColors.blue.100",
              boxShadow: "none",
              borderBottom: "1px solid #4318FF",
              zIndex: "auto",
            }}
            color="#828282"
            fontSize="18px"
            fontWeight="bold"
          >
            History
          </Tab>
          {getDashboardPermissions("schedule_downtime.view") ? (
            <Tab
              _selected={{
                color: "ndmsColors.blue.100",
                boxShadow: "none",
                borderBottom: "1px solid #4318FF",
              }}
              color="#828282"
              fontSize="18px"
              fontWeight="bold"
              onClick={() => setTabType("scheduleDowntime")}
            >
              Scheduled Downtime
            </Tab>
          ) : null}
        </TabList>

        <TabPanels>
          <TabPanel pl="0px">
            <LiveDashboard />
          </TabPanel>
          <TabPanel>
            <EntityHistory
              pl="0px"
              entityId={entityId}
              entityName={entityName}
            />
          </TabPanel>
          <TabPanel>
            <ScheduleDowntimeList pl="0px" tabType={tabType} />
          </TabPanel>
        </TabPanels>
      </Tabs>
      <LicenseModal
        onOpen={onLicenseOpen}
        isOpen={isLicenseOpen}
        onClose={onLicenseClose}
        id={entityId}
        entityName={entityName}
      />
    </Box>
  );
};

export default EntityDashboard;
