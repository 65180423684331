import React from "react";
import {
  Box,
  Text,
  Circle,
  CircularProgress,
  CircularProgressLabel,
  Link,
  Flex,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import dayjs from "dayjs";
import Map from "components/map";

const MapView = ({ data }) => {
  const mapVisibility = localStorage.getItem("map_visibility");
  const getBackgroundColor = (status) => {
    if (status === "syncing") {
      return "#3DCA78";
    } else {
      return "#E23A63";
    }
  };

  return (
    <Flex alignItems="flex-start" mb="50px">
      <Flex
        direction="column"
        width={mapVisibility?.toLowerCase() === "show" ? "50%" : "100%"}
        style={{
          overflowY: "scroll",
          overflowX: "hidden",
          height: "calc(100vh - 200px)",
        }}
      >
        {data?.data[0]?.result?.map((mark, index) => (
          <Card mb="10px" pt="10px" key={index}>
            <Flex width="100%">
              <Flex direction="column" width="100%">
                <Flex alignItems="center" mb="5px">
                  <Flex alignItems="center">
                    <Circle size="8px" bg={getBackgroundColor(mark.status)} />
                    <Text
                      fontSize="12px"
                      fontWeight="bold"
                      pl="5px"
                      color={getBackgroundColor(mark.status)}
                    >
                      {mark?.status}
                    </Text>
                  </Flex>
                  {mark?.last_sync_at ? (
                    <Text
                      fontSize="10px"
                      fontWeight="bold"
                      pl="10px"
                      color={"ndmsColors.gray.1900"}
                    >
                      {`Last Synced :
                      ${dayjs(mark?.last_sync_at).format("DD MMM YYYY")},
                      ${dayjs(mark?.last_sync_at).format("hh:mm a")}`}
                    </Text>
                  ) : null}
                </Flex>

                <Flex justifyContent="space-between" mt="5px">
                  <Flex direction="column">
                    <Text fontWeight="500" width="100px">
                      {mark.name}
                    </Text>
                    <Text
                      fontSize="12px"
                      color="ndmsColors.gray.1900"
                      mt="2px"
                      mb="5px"
                      fontWeight="500"
                    >
                      {`${mark.Address.city}, ${mark.Address.state}`}
                    </Text>
                  </Flex>

                  <Link
                    pt="20px"
                    textAlign="center"
                    href={`/admin/entity-dashboard/${mark.id}/${mark.name}`}
                  >
                    <Circle size="25px" bg="#E5E5E5" color="black">
                      {">"}
                    </Circle>
                  </Link>

                  <Flex direction="column" alignItems="center">
                    {mark.total_nodes > 0 ? (
                      <CircularProgress
                        value={mark?.health_average}
                        size="69px"
                        color="green.400"
                      >
                        <CircularProgressLabel
                          color={"ndmsColors.blue.100"}
                          fontSize="12px"
                          fontWeight="700"
                        >
                          {mark?.health_average?.toFixed(0)}%
                        </CircularProgressLabel>
                      </CircularProgress>
                    ) : (
                      <CircularProgress value={0} size="69px" color="green.400">
                        <CircularProgressLabel
                          color={"ndmsColors.blue.100"}
                          fontSize="12px"
                          fontWeight="700"
                        >
                          0%
                        </CircularProgressLabel>
                      </CircularProgress>
                    )}
                    <Text
                      fontSize="12px"
                      fontWeight="bold"
                      color={"ndmsColors.gray.1900"}
                    >
                      {`${mark.healthy_nodes} / ${mark.total_nodes}`} nodes
                      working{" "}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Flex>
          </Card>
        ))}
      </Flex>
      {mapVisibility?.toLowerCase() === "show" && (
        <Box
          height="calc(100vh - 200px)"
          borderRadius="13px"
          overflow="hidden"
          ml="20px"
          width="48%"
        >
          <Map data={data} />
        </Box>
      )}
    </Flex>
  );
};

export default MapView;
