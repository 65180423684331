import React from "react";
import {
  Box,
  Text,
  Icon,
  Flex,
  Divider,
  TagLabel,
  Accordion,
  Tag,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Tabs,
  Tab,
  TabList,
  TabPanels,
  TabPanel,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import { MdEdit } from "react-icons/md";
import EmptyData from "./emptyData";
import { getDashboardPermissions } from "Permissions";

function OneTimeView({ data, handleEdit }) {
  const getTime = (time) => {
    //const date = dayjs(new Date()).format("MM/DD/YYYY").concat(" ", time);
    return dayjs(time).format("h:mm A");
  };

  const getFilteredNodes = (nodeArray) => {
    let uniqueNode = [];
    for (let i = 0; i < nodeArray.length; i++) {
      uniqueNode.push(nodeArray[i].node_type_name);
    }
    return [...new Set(uniqueNode)];
  };

  return (
    <>
      {data?.data?.length > 0 ? (
        <Tabs display="flex" mt="20px">
          <TabList display="flex" flexDirection="column" borderStyle="none">
            {data?.data?.map((tab, index) => (
              <>
                <Tab
                  width="240px"
                  bg="#F4F7FE"
                  key={index}
                  display="flex"
                  justifyContent="flex-start"
                  color="#707EAE"
                  borderStyle="none"
                  _selected={{
                    color: "#4318FF",
                    bg: "white",
                    boxShadow: "none",
                    position: "none",
                  }}
                  position="relative"
                  py="20px"
                >
                  <Flex flexDirection="column">
                    <Text fontSize="16px" fontWeight="bold">
                      {`${getTime(tab.starts_at)}-${getTime(tab.ends_at)}`}
                    </Text>
                    <Text color="#9AA6CF" align="left" fontSize="10px">
                      {tab.all_down_nodes_count} Nodes
                    </Text>
                  </Flex>
                  <Text
                    position="absolute"
                    right={5}
                    color="#343B46"
                    fontSize="20px"
                  >{`>`}</Text>
                </Tab>
                <Divider orientation="horizontal" />
              </>
            ))}
          </TabList>
          <TabPanels>
            {data?.data?.map((tab, index) => (
              <TabPanel py="0px" key={index}>
                <Flex>
                  <Text color="#2B3674" fontSize="22px" fontWeight="bold">
                    {`${getTime(tab.starts_at)}-${getTime(tab.ends_at)}`}
                  </Text>
                  {getDashboardPermissions("schedule_downtime.update") ? (
                    <Flex
                      align="center"
                      pl="20px"
                      cursor="pointer"
                      onClick={() => handleEdit(tab)}
                    >
                      <Icon color="#2500BC" as={MdEdit} w="15px" h="15px" />
                      <Text
                        fontSize="14px"
                        pt="2px"
                        color="#2500BC"
                        fontWeight="bold"
                      >
                        Edit
                      </Text>
                    </Flex>
                  ) : null}
                </Flex>
                {tab?.next_occurance_start ? (
                  <Text
                    color="#179DBA"
                    py="5px"
                    fontSize="16px"
                    fontWeight="bold"
                  >
                    Next occurence on{" "}
                    {dayjs(tab?.next_occurance_start).format("DD MMMM")} at{" "}
                    {dayjs(tab?.next_occurance_start).format("h:mm A")}
                  </Text>
                ) : null}
                <Flex mb="10px">
                  <Tag
                    size={"sm"}
                    variant="solid"
                    bg={tab?.is_downtime_active ? "#019F79" : "#C70000"}
                  >
                    <TagLabel textTransform="uppercase">
                      {tab?.is_downtime_active ? "active" : "inactive"}
                    </TagLabel>
                  </Tag>
                  <Tag size={"sm"} variant="solid" mx="10px" bg={"#EDF0F4"}>
                    <TagLabel fontWeight="bold" color={"#707EAE"}>
                      One Time
                    </TagLabel>
                  </Tag>
                  {tab?.expired_at ? (
                    <Text
                      color="#707EAE"
                      py="5px"
                      fontSize="14px"
                      fontWeight="500"
                    >
                      Expires on {dayjs(tab?.expired_at).format("DD MMMM")} at{" "}
                      {dayjs(tab?.expired_at).format("h:mm A")}
                    </Text>
                  ) : null}
                </Flex>
                {tab?.last_occured_at ? (
                  <Text
                    color="#707EAE"
                    pb="20px"
                    fontSize="12px"
                    fontWeight="500"
                  >
                    Last occured on{" "}
                    {dayjs(tab?.last_occured_at).format("DD MMMM")}
                  </Text>
                ) : null}
                {tab?.node_type.map((node) => (
                  <Accordion allowMultiple>
                    <AccordionItem
                      p="0px"
                      borderStyle="none"
                      borderBottom="1px solid #DCE3EB"
                    >
                      <h2>
                        <AccordionButton
                          boxShadow="none !important"
                          background="white !important"
                          pl="0px"
                          _expanded={{
                            color: "#4318FF",
                            boxShadow: "none",
                            borderStyle: "none",
                          }}
                        >
                          <Box
                            flex="1"
                            color="#2B3674"
                            fontSize="14px"
                            fontWeight="bold"
                            textAlign="left"
                            pl="0px"
                          >
                            {`${node.name} (${node.nodes.length})`}
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4} pl="0px">
                        <Flex>
                          {node.nodes.map((nod) => (
                            <Tag
                              size={"sm"}
                              key={nod.id}
                              variant="outline"
                              color="#707EAE"
                              mr="5px"
                            >
                              {nod.name}
                            </Tag>
                          ))}
                        </Flex>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                ))}
                {getFilteredNodes(tab?.nodes)?.map((type) => (
                  <Accordion allowMultiple>
                    <AccordionItem
                      p="0px"
                      borderStyle="none"
                      borderBottom="1px solid #DCE3EB"
                    >
                      <h2>
                        <AccordionButton
                          boxShadow="none !important"
                          background="white !important"
                          pl="0px"
                          _expanded={{
                            color: "#4318FF",
                            boxShadow: "none",
                            borderStyle: "none",
                          }}
                        >
                          <Box
                            flex="1"
                            color="#2B3674"
                            fontSize="14px"
                            fontWeight="bold"
                            textAlign="left"
                            pl="0px"
                          >
                            {`${type}`}{" "}
                            {`(${
                              tab?.nodes.filter(
                                (arr) => arr.node_type_name === type
                              ).length
                            })`}
                          </Box>
                          <AccordionIcon />
                        </AccordionButton>
                      </h2>
                      <AccordionPanel pb={4} pl="0px" width="90% !important">
                        <Flex wrap="wrap">
                          {tab.nodes
                            .filter((data) => data.node_type_name === type)
                            .map((nod) => (
                              <Tag
                                size={"sm"}
                                key={nod.id}
                                variant="outline"
                                color="#707EAE"
                                m="5px"
                              >
                                {nod.name}
                              </Tag>
                            ))}
                        </Flex>
                      </AccordionPanel>
                    </AccordionItem>
                  </Accordion>
                ))}
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
      ) : (
        <EmptyData />
      )}
    </>
  );
}

export default OneTimeView;
