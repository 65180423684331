import { QUERY_KEY } from "constants/globalConstants";
import ApiService from "apiService";
import { useQuery } from "react-query";

const useLiveDashboardNodeData = (id, nodeTypeId) => {
   return useQuery(QUERY_KEY.GET_LIVE_DASHBOARD_NODE_DATA, () =>
    ApiService.getLiveDashboardNodeData(id, nodeTypeId)
  );
};

export default useLiveDashboardNodeData;
