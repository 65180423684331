import React, { useEffect, useState } from "react";
import { Box, Text } from "@chakra-ui/layout";
import { Select } from "@chakra-ui/select";
import { Flex, Skeleton } from "@chakra-ui/react";
import Icon from "@chakra-ui/icon";
import { StarIcon } from "@chakra-ui/icons";

function EntityFilter(props) {
  const [entityId, setEntityId] = useState(null);

  const {
    data,
    entityTypeId,
    setEntityTypeId,
    setNodeTypeId,
    setNodeId,
    setEntityTypeName,
    setNodeTypeName,
    setNodeIdName,
    reportFilterType,
    entityTypeName,
    isLoading,
  } = props;

  const onHandleChange = (e) => {
    setEntityId(e.target.value);
    const names = data?.data?.filter(
      (dat) => dat?.id === parseInt(e.target.value, 10)
    )[0];

    setEntityTypeName(names?.name);
  };

  useEffect(() => {
    setEntityTypeId(entityId);
    setNodeTypeId(null);
    setNodeTypeName("all");
    setNodeIdName("all");
    setNodeId(null);
  }, [
    entityId,
    setEntityTypeId,
    setNodeTypeName,
    setNodeTypeId,
    setNodeId,
    setNodeIdName,
  ]);

  useEffect(() => {
    setEntityTypeId(null);
    setEntityId(null);
    setEntityTypeName("all");
  }, [reportFilterType]);

  return (
    <Box mt="26px" mr="20px">
      <Flex alignItems="center">
        <Flex>
          <Text color="ndmsColors.gray.2100" fontSize="14px" fontWeight="bold">
            Entity
          </Text>
          <Icon as={StarIcon} w="5px" h="5px" color="red !important" />
        </Flex>
        {isLoading ? (
          <Skeleton ml="10px" width="200px" height="40px" />
        ) : (
          <Flex alignItems="center">
            <Select
              fontSize="13px"
              width="198px"
              height="2.5rem"
              ml="14px"
              id="user_type"
              size="lg"
              mr="10px"
              color="#2B3674"
              fontWeight="bold"
              value={entityTypeId}
              onChange={onHandleChange}
            >
              <option value="0">Select Entity</option>
              {data?.data?.map((list) => (
                <option key={list?.id} value={list?.id}>
                  {list?.name}
                </option>
              ))}
            </Select>
          </Flex>
        )}
      </Flex>
    </Box>
  );
}

export default EntityFilter;
