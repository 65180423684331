import { QUERY_KEY } from "constants/globalConstants";
import ApiService from "apiService";
import { useQuery } from "react-query";

const useGetEntityNodeFilter = (id) => {
  return useQuery(QUERY_KEY.GET_ENTITY_NODE_FILTER, () =>
    ApiService.getEntityNodeFilter(id)
  );
};

export default useGetEntityNodeFilter;
