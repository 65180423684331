import React from "react";
import { useDrag } from "react-dnd";
import { Text } from "@chakra-ui/react";
import Card from "components/card/Card.js";

const NodeCard = ({ id, name, style }) => {
  const [{ isDragging }, dragRef] = useDrag({
    type: "card",
    item: { id, name },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  return (
    <div ref={dragRef} style={style}>
      <Card
        borderWidth="1px"
        borderRadius="lg"
        draggable
        style={{
          justifyContent: "center",
          boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
          height: "90px",
          width: "100px",
          display: "flex",
          alignItems: "center",
          marginRight: "15px",
          zIndex: 1,
        }}
      >
        <Text ms="4px" fontWeight="bold" fontSize="14px">
          {name}
        </Text>
      </Card>
    </div>
  );
};

export default NodeCard;
