import React from "react";
import Card from "components/card/Card.js";
import { useHistory } from "react-router";
import { Button, Flex, Text } from "@chakra-ui/react";

const PageNotFound = () => {
  const history = useHistory();
  const mainBranding = localStorage.getItem("main_branding");

  return (
    <Flex
      bg="white"
      align="center"
      justify="center"
      height="100vh"
      position="relative"
    >
      <Text
        position="absolute"
        top="5"
        left="20"
        color="#2B3674"
        fontSize="30px"
        fontWeight="bold"
      >
        {mainBranding}
      </Text>
      <Card
        boxShadow="rgba(0, 0, 0, 0.35) 0px 5px 15px"
        direction="column"
        w="600px"
        h="400px"
        px="0px"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Flex direction="column" align="center" mt="20px">
          <Text color="#A3AED0" fontSize="50px" fontWeight="bold">
            404
          </Text>
          <Text color="black" fontSize="30px" fontWeight="bold">
            Page Not Found
          </Text>
          <Text color="#A3AED0" mt="20px" fontSize="20px">
            We couldn't find what you were looking for.
          </Text>
          <Button
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="151px"
            h="50"
            mt="24px"
            mr="20px"
            onClick={() =>
              localStorage.getItem("is_superuser") === "true"
                ? history.push("/admin/user")
                : history.push("/admin/home")
            }
          >
            <Text fontSize={"16px"} color="#FFFFFF" fontWeight="bold">
              Home page
            </Text>
          </Button>
        </Flex>
      </Card>
    </Flex>
  );
};

export default PageNotFound;
