import React, { useEffect } from "react";

// Chakra imports
import { Flex, Text, Icon } from "@chakra-ui/react";

// Custom components
import { HSeparator } from "components/separator/Separator";
import { HamburgerIcon } from "@chakra-ui/icons";

export function SidebarBrand({ isExpanded, setIsExpanded }) {
  //   Chakra color mode
  const mainBranding = localStorage.getItem("main_branding");

  useEffect(() => {
    document.title = mainBranding || "NDMS";
  }, [localStorage.getItem("main_branding")]);

  return (
    <Flex
      align="center"
      position="sticky"
      top={0}
      bg="white"
      zIndex={2}
      direction="column"
    >
      <Flex align="center" justify="space-evenly" width="100%">
        <Text
          fontSize="26px"
          fontWeight="bold"
          display={isExpanded && "none"}
          mr="90px"
          mt="24px"
          ml="24px"
          mb="24px"
          color="#2B3674"
        >
          {mainBranding || "NDMS"}
        </Text>
        <div
          onClick={() => setIsExpanded(!isExpanded)}
          style={{ cursor: "pointer", marginTop: isExpanded && "24px" }}
        >
          <Icon as={HamburgerIcon} width="20px" height="20px" color="inherit" />{" "}
        </div>
      </Flex>
      <HSeparator mb="20px" mt={isExpanded && "20px"} />
    </Flex>
  );
}

export default SidebarBrand;
