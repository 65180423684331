import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/accordion";

import Icon from "@chakra-ui/icon";
import { Box, Flex, Text } from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";
import "../../../assets/css/grid.css";
import { MdOutlineBrowserNotSupported } from "react-icons/md";
import Card from "components/card/Card";

function AccordionToggleLiveDashboard(props) {
  const { filteredData, getValue } = props;
  const [hiddenGroupLength, setHiddenGroupLength] = useState(null);

  const newData =
    filteredData?.length > 0 &&
    filteredData?.filter((items) => items?.node_details?.length === 0);

  useEffect(() => {
    setHiddenGroupLength(newData?.length);
  }, [newData]);

  return (
    <>
      {hiddenGroupLength > 0 ? (
        <>
          {filteredData?.length === hiddenGroupLength ? (
            <Flex
              direction="column"
              justify="center"
              align="center"
              ml="300px"
              mt="50px"
              mb="40px"
              width="400px"
            >
              <Icon
                as={MdOutlineBrowserNotSupported}
                width="70px"
                height="70px"
                color="ndmsColors.gray.700"
              />
              <Text
                fontSize={"18px"}
                color="ndmsColors.gray.700"
                fontWeight="bold"
              >
                Hold on client is not install yet!!
              </Text>
            </Flex>
          ) : (
            ""
          )}
          <Accordion allowToggle ml="10px" p="0px" mt="20px">
            <AccordionItem p="0px" border="none">
              <h1>
                <AccordionButton _focus={{ boxShadow: "none" }}>
                  <Flex justifyContent="center" alignItems="center">
                    <Box
                      textAlign="left"
                      py="5px"
                      color="ndmsColors.gray.700"
                      fontSize="16px"
                      fontWeight="bold"
                      mr="10px"
                    >
                      Hidden ({hiddenGroupLength})
                    </Box>
                    <AccordionIcon mr="10px" color={"ndmsColors.gray.700"} />
                    <Box borderTop="1px dotted #828282" minWidth="830px"></Box>
                  </Flex>
                </AccordionButton>
              </h1>
              <AccordionPanel p="0px" pb={4}>
                <Flex mt="20px" justifyContent="flex-start" flexWrap="wrap">
                  {filteredData?.length > 0 &&
                    filteredData?.map(
                      (value, idx) =>
                        value.node_details.length === 0 && (
                          <>
                            <Card
                              ml="20px"
                              bg="ndmsColors.black.100"
                              borderRadius="15px"
                              maxWidth="310px"
                              mb="12px"
                              minHeight="200px"
                              alignItems="center"
                            >
                              <Flex
                                align="center"
                                justifyContent="space-between"
                                pb="9px"
                                width="100%"
                                borderBottom="1px solid #F1F1F1"
                              >
                                <Text
                                  fontSize="18px"
                                  pt="3px"
                                  color="ndmsColors.blue.100"
                                  fontWeight="bold"
                                >
                                  {value.name}
                                </Text>
                                <Text
                                  color="ndmsColors.gray.300"
                                  fontSize="12px"
                                  fontWeight="bold"
                                >
                                  Total Nodes : {getValue(value?.node_details)}
                                </Text>
                              </Flex>
                              <Flex
                                height="100%"
                                flexDirection="column"
                                justifyContent="center"
                                alignItems="center"
                              >
                                <Icon
                                  as={MdOutlineBrowserNotSupported}
                                  width="40px"
                                  height="40px"
                                  color="ndmsColors.gray.700"
                                />
                                <Text
                                  fontSize="16px"
                                  color="ndmsColors.gray.300"
                                >
                                  No Data
                                </Text>
                              </Flex>
                            </Card>
                          </>
                        )
                    )}
                </Flex>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default AccordionToggleLiveDashboard;
