import { CalendarIcon } from "@chakra-ui/icons";
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Select,
  Text,
  Textarea,
  useColorModeValue,
} from "@chakra-ui/react";
import useEntityListName from "hooks/entityList/useEntityListName";
import TicketModuleHooks from "hooks/ticketModule/ticketModuleHooks";
import useUntraceableNodeTypeList from "hooks/useUntraceableNodeTypeList";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { Controller, useForm } from "react-hook-form";

const CreateTicketDrawer = ({ onClose, isOpen, onOpen, drawerRef }) => {
  const {
    handleSubmit,
    control,
    reset,
    resetField,
    clearErrors,
    formState: { errors },
  } = useForm();
  const [entityId, setEntityId] = useState(null);
  const textColor = useColorModeValue("navy.700", "white");
  const [type, setType] = useState(null);
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const buttonHover = useColorModeValue("brand.600", "brand.400");
  const {
    isLoading: isEntityLoading,
    error: entityError,
    data: entityData,
    refetch: entityRefetch,
  } = useEntityListName();
  const {
    isLoading: isTypeLoading,
    error: typeError,
    data: typeData,
    refetch: typeRefetch,
  } = TicketModuleHooks.useTypeList();
  const {
    isLoading: isNodeLoading,
    error: nodeError,
    data: nodeData,
    refetch: nodeRefetch,
  } = TicketModuleHooks.useNodeList(entityId);

  const { data: untraceablenodeData, refetch: untraceablenodeRefetch } =
    useUntraceableNodeTypeList(entityId);

  useEffect(() => {
    entityRefetch();
    typeRefetch();
  }, [window.location.pathname]);

  const handleModalClose = () => {
    reset();
    resetField("entity_id");
    resetField("ticket_type");
    resetField("created_at");
    setEntityId(null);
    setType(null);
    clearErrors();
    onClose();
  };

  const { mutate: createTicket, isLoading } =
    TicketModuleHooks.useCreateTicket(handleModalClose);
  const [disable, setDisable] = useState(true);

  useEffect(() => {
    if (entityId !== null && entityId !== "") {
      setDisable(false);
      if (getSlug() === "untrace_node_down") {
        untraceablenodeRefetch();
      } else {
        nodeRefetch();
      }
    } else {
      setDisable(true);
    }
  }, [entityId, type]);

  const generateEntityOptions = () => {
    return entityData?.data?.map((data) => {
      return <option value={data.id}>{data.name} </option>;
    });
  };

  const generateNodeOptions = () => {
    if (getSlug() === "untrace_node_down") {
      return untraceablenodeData?.data?.map((data) => {
        return <option value={data.id}>{data.name}</option>;
      });
    } else {
      return nodeData?.data?.map((data) => {
        return (
          <option value={data.id}>
            {data.name} ({data.ip_address})
          </option>
        );
      });
    }
  };

  const generateTypeOptions = () => {
    return typeData?.data?.map((data) => {
      return <option value={data.id}>{data.name}</option>;
    });
  };

  const getSlug = () => {
    return typeData?.data?.filter((data) => data.id === type)[0]?.slug;
  };

  const onSubmit = (data) => {
    console.log(data);
    createTicket({
      ticket_type: data.ticket_type,
      priority: data.priority,
      note: data.note,
      created_at: data.created_at,
      entity_id: data.entity_id,
      node_id: data.node_id,
    });
  };

  return (
    <Drawer
      onClose={handleModalClose}
      isOpen={isOpen}
      size="md"
      position="relative"
      finalFocusRef={drawerRef}
    >
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>Create Ticket</DrawerHeader>
        <DrawerBody>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Flex>
              <Flex direction="column" width="100%">
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="4px"
                >
                  Ticket Type<Text color={brandStars}>*</Text>
                </FormLabel>
                <div style={{ marginBottom: "10px" }}>
                  <Controller
                    name="ticket_type"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <Select
                        id="user_type"
                        size="lg"
                        fontSize="14px"
                        color={
                          field.value === "Choose Type" ||
                          field.value === null ||
                          field.value === undefined
                            ? "ndmsColors.gray.2200"
                            : "black"
                        }
                        fontWeight={
                          field.value === "Choose Type" ||
                          field.value === null ||
                          field.value === undefined
                            ? "bold"
                            : "400"
                        }
                        value={type}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          setType(e.target.value);
                        }}
                      >
                        <option selected disabled hidden value={null}>
                          Choose Type
                        </option>
                        {generateTypeOptions()}
                      </Select>
                    )}
                  />
                  {errors.ticket_type?.type === "required" && (
                    <span style={{ color: "red" }}>type is required</span>
                  )}
                </div>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="4px"
                >
                  Priority<Text color={brandStars}>*</Text>
                </FormLabel>
                <div style={{ marginBottom: "10px" }}>
                  <Controller
                    name="priority"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <Select
                        id="user_type"
                        size="lg"
                        fontSize="14px"
                        color={
                          field.value === "Choose Priority" ||
                          field.value === null ||
                          field.value === undefined
                            ? "ndmsColors.gray.2200"
                            : "black"
                        }
                        fontWeight={
                          field.value === "Choose Priority" ||
                          field.value === null ||
                          field.value === undefined
                            ? "bold"
                            : "400"
                        }
                        {...field}
                      >
                        <option selected disabled hidden value={null}>
                          Choose Priority
                        </option>
                        <option value={1}>Low</option>
                        <option value={2}>Medium</option>
                        <option value={3}>High</option>
                      </Select>
                    )}
                  />
                  {errors.priority?.type === "required" && (
                    <span style={{ color: "red" }}>priority is required</span>
                  )}
                </div>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="4px"
                >
                  Entity<Text color={brandStars}>*</Text>
                </FormLabel>
                <div style={{ marginBottom: "10px" }}>
                  <Controller
                    name="entity_id"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <Select
                        id="user_type"
                        size="lg"
                        fontSize="14px"
                        color={
                          field.value === "Choose Entity" ||
                          field.value === null ||
                          field.value === undefined
                            ? "ndmsColors.gray.2200"
                            : "black"
                        }
                        fontWeight={
                          field.value === "Choose Entity" ||
                          field.value === null ||
                          field.value === undefined
                            ? "bold"
                            : "400"
                        }
                        value={entityId}
                        onChange={(e) => {
                          field.onChange(e.target.value);
                          setEntityId(e.target.value);
                        }}
                        //{...field}
                      >
                        <option selected disabled hidden value={null}>
                          Choose Entity
                        </option>
                        {generateEntityOptions()}
                      </Select>
                    )}
                  />
                  {errors.entity_id?.type === "required" && (
                    <span style={{ color: "red" }}>entity is required</span>
                  )}
                </div>
                {getSlug() === "node_down" ||
                getSlug() === "untrace_node_down" ? (
                  <>
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="4px"
                    >
                      Node{" "}
                      {getSlug() === "node_down" ||
                      getSlug() === "untrace_node_down" ? (
                        <Text color={brandStars}>*</Text>
                      ) : null}
                    </FormLabel>
                    <div style={{ marginBottom: "10px" }}>
                      <Controller
                        name="node_id"
                        rules={{
                          required:
                            getSlug() === "node_down" ||
                            getSlug() === "untrace_node_down"
                              ? true
                              : false,
                        }}
                        control={control}
                        render={({ field }) => (
                          <Select
                            id="user_type"
                            size="lg"
                            fontSize="14px"
                            disabled={disable}
                            color={
                              field.value === "Choose Node" ||
                              field.value === null ||
                              field.value === undefined
                                ? "ndmsColors.gray.2200"
                                : "black"
                            }
                            fontWeight={
                              field.value === "Choose Node" ||
                              field.value === null ||
                              field.value === undefined
                                ? "bold"
                                : "400"
                            }
                            {...field}
                          >
                            <option selected disabled hidden value={null}>
                              Choose Node
                            </option>
                            {generateNodeOptions()}
                          </Select>
                        )}
                      />
                      {errors.node_id?.type === "required" && (
                        <span style={{ color: "red" }}>node is required</span>
                      )}
                    </div>
                  </>
                ) : null}
                {getSlug() === "node_down" ||
                getSlug() === "untrace_node_down" ? (
                  <>
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="4px"
                    >
                      Created at<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <div style={{ marginBottom: "10px" }}>
                      <Controller
                        name={`created_at`}
                        rules={{ required: false }}
                        control={control}
                        render={({ field }) => (
                          <DatePicker
                            wrapperClassName="date-picker"
                            showTimeSelect
                            selectMultiple={true}
                            customInput={
                              <InputGroup>
                                <Input
                                  minWidth="170px"
                                  placeholder=""
                                  value={
                                    field.value
                                      ? `${field?.value?.toLocaleDateString()}, ${field.value.toLocaleTimeString(
                                          "en-US"
                                        )}`
                                      : ""
                                  }
                                />
                                <InputRightElement
                                  children={<CalendarIcon color="#4318FF" />}
                                />
                              </InputGroup>
                            }
                            onChange={(date) => {
                              field.onChange(date);
                            }}
                            selected={field.value ? field.value : ""}
                            maxDate={new Date()}
                            dateFormat="MMMM d, yyyy h:mm aa"
                          />
                        )}
                      />
                      {errors.created_at?.type === "required" && (
                        <span style={{ color: "red" }}>
                          Created at is required
                        </span>
                      )}
                    </div>
                  </>
                ) : (
                  ""
                )}
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="4px"
                >
                  Note<Text color={brandStars}>*</Text>
                </FormLabel>
                <div style={{ marginBottom: "10px" }}>
                  <Controller
                    name="note"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <Textarea
                        fontSize="sm"
                        placeholder="Enter Note"
                        fontWeight={field.value ? "400" : "bold"}
                        size="lg"
                        type="name"
                        {...field}
                      />
                    )}
                  />
                  {errors.note?.type === "required" && (
                    <span style={{ color: "red" }}>note is required</span>
                  )}
                </div>
              </Flex>
            </Flex>
            <DrawerFooter
              position="absolute"
              left="0"
              bottom="0"
              pt="10px"
              //mb="20px"
              //ml="30px"
            >
              <Button
                bg="#4318FF"
                //size="sm"
                borderRadius="6px"
                variant="brand"
                //ml="20px"
                mt={2}
                //mb={10}
                width="140px"
                isLoading={isLoading}
                _hover={{ bg: isLoading ? "blue" : buttonHover }}
                type="submit"
              >
                <Text color={"#FFFFFF"}>Create</Text>
              </Button>
              <Button
                borderRadius="6px"
                variant="outline"
                mt={2}
                ml="20px"
                width="140px"
                borderColor="#4F46BA"
                onClick={handleModalClose}
              >
                <Text color={"#4F46BA"}>Cancel</Text>
              </Button>
            </DrawerFooter>
          </form>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
};

export default CreateTicketDrawer;
