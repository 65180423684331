import React, { useEffect, useState } from "react";
import { Box, Text } from "@chakra-ui/layout";
import { Select } from "@chakra-ui/select";
import { Flex, Skeleton } from "@chakra-ui/react";

function NodeTypeFilter(props) {
  const {
    setNodeTypeId,
    nodeTypeId,
    nodeTypeListData,
    setNodeTypeName,
    setNodeId,
    entityTypeId,
    setNodeIdName,
    isNodeTypeLoading,
    isNodeTypeFetching,
  } = props;
  const [selectedNodeTypeId, setSelectedNodeTypeId] = useState(null);
  const [disabled, setDisabled] = useState(true);

  const onHandleChange = (e) => {
    setSelectedNodeTypeId(e.target.value);
    const names = nodeTypeListData?.data?.filter(
      (dat) => dat?.id === parseInt(e.target.value, 10)
    )[0];
    setNodeTypeName(names?.name);
  };
  // const names = nodeTypeListData?.data?.filter(
  //   (dat) => dat?.id === selectedNodeTypeId
  // )[0];
  // useEffect(() => {
  //   if (names?.name) setNodeTypeName(names?.name);
  // });

  useEffect(() => {
    entityTypeId === null || entityTypeId === 0 || entityTypeId === "0"
      ? setDisabled(true)
      : setDisabled(false);
  }, [entityTypeId, disabled]);

  useEffect(() => {
    //setNodeTypeId(selectedNodeTypeId);
    setNodeTypeId(null);
    setSelectedNodeTypeId(null);
    setNodeIdName("all");
    setNodeId(null);
    setNodeIdName("all");
  }, [entityTypeId]);

  useEffect(() => {
    setNodeTypeId(selectedNodeTypeId);
  }, [selectedNodeTypeId, setNodeTypeId]);

  return (
    <Box mt="26px" mr="20px">
      <Flex alignItems="center">
        <Flex>
          <Text color="ndmsColors.gray.2100" fontSize="14px" fontWeight="bold">
            Node Type
          </Text>
        </Flex>
        {isNodeTypeLoading || isNodeTypeFetching ? (
          <Skeleton ml="10px" width="200px" height="40px" />
        ) : (
          <Flex alignItems="center">
            <Select
              fontSize="13px"
              width="198px"
              height="2.5rem"
              ml="14px"
              id="user_type"
              size="lg"
              mr="10px"
              color="#2B3674"
              disabled={disabled}
              fontWeight="bold"
              value={nodeTypeId}
              onChange={onHandleChange}
            >
              <option value="all">All</option>
              {nodeTypeListData?.data?.map((list) => (
                <option key={list?.id} value={list?.id}>
                  {list?.name}
                </option>
              ))}
            </Select>
          </Flex>
        )}
      </Flex>
    </Box>
  );
}

export default NodeTypeFilter;
