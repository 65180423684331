import { QUERY_KEY } from "constants/globalConstants";
import DashboardService from "apiService/dashboardService";
import { useQuery } from "react-query";

const useClientNodesWorking = (pageSize, currentPage, trendSort) => {
  return useQuery(
    QUERY_KEY.GET_CLIENT_NODES_WORKING,
    () => DashboardService.clientNodesWorking(trendSort),
    { retry: false }
  );
};

export default useClientNodesWorking;
