import React from "react";
import { Box, Skeleton } from "@chakra-ui/react";
import Card from "components/card/Card.js";

const TabLoader = () => {
  return (
    <Box>
      {[...Array(4)]?.map((data) => (
        <Card border="1px solid #EAEDF2" borderRadius="10px" mb="10px">
          <Skeleton height="100px" width="100%" />
        </Card>
      ))}
    </Box>
  );
};

export default TabLoader;
