import React, { useState } from "react";
import { NavLink } from "react-router-dom";
// Chakra imports
import {
  Box,
  Button,
  HStack,
  PinInput,
  PinInputField,
  Flex,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import useConfirmPassword from "hooks/useConfirmPassword";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import toast from "react-hot-toast";

const ConfirmPassword = ({ email }) => {
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const textColorDetails = useColorModeValue("navy.700", "secondaryGray.600");
  const textColorBrand = useColorModeValue("brand.500", "white");
  const buttonHover = useColorModeValue("brand.600", "brand.400");
  const { mutate: confirmPassword, isLoading } = useConfirmPassword();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const [show, setShow] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const handleClick = () => setShow(!show);
  const handleConfirmClick = () => setShowConfirm(!showConfirm);
  const onSubmit = (data) => {
    if (data.new_password === data.confirm_password) {
      confirmPassword({
        email: email,
        code: data.code,
        new_password: data.new_password,
      });
    } else {
      toast.error("passwords did not match");
    }
    // confirmPassword({ email: data.email });
  };

  return (
    <Box>
      <Box me="auto">
        <Heading color={textColor} fontSize="36px" mb="10px">
          Confirm Password
        </Heading>
        <Text
          mb="15px"
          ms="4px"
          color={textColorSecondary}
          fontWeight="400"
          fontSize="md"
        >
          Enter otp sent to your registered email address and new password
        </Text>
      </Box>
      <Flex
        zIndex="2"
        direction="column"
        w={{ base: "100%", md: "420px" }}
        maxW="100%"
        background="transparent"
        borderRadius="15px"
        mx={{ base: "auto", lg: "unset" }}
        me="auto"
        mb={{ base: "20px", md: "auto" }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <Box mb="14px">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Otp<Text color={brandStars}>*</Text>
            </FormLabel>
            <Controller
              name="code"
              rules={{ required: true }}
              control={control}
              render={({ field }) => (
                <HStack>
                  <PinInput {...field}>
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                    <PinInputField />
                  </PinInput>
                </HStack>
              )}
            />
            {errors.code?.type === "required" && (
              <span style={{ color: "red" }}>otp is required</span>
            )}
          </Box>
          <Box mb="5px" pt="10px">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              New Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Controller
                name="new_password"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <Input
                    fontSize="sm"
                    placeholder="Min. 8 characters"
                    size="lg"
                    mb="10px"
                    type={show ? "text" : "password"}
                    variant="auth"
                    {...field}
                  />
                )}
              />

              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            {errors.password?.type === "required" && (
              <span style={{ color: "red" }}>password is required</span>
            )}
          </Box>
          <Box mb="14px">
            <FormLabel
              display="flex"
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              mb="8px"
            >
              Confirm New Password<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size="md">
              <Controller
                name="confirm_password"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <Input
                    fontSize="sm"
                    placeholder="Min. 8 characters"
                    size="lg"
                    mb="10px"
                    type={showConfirm ? "text" : "password"}
                    variant="auth"
                    {...field}
                  />
                )}
              />

              <InputRightElement display="flex" alignItems="center" mt="4px">
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={showConfirm ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleConfirmClick}
                />
              </InputRightElement>
            </InputGroup>
            {errors.password?.type === "required" && (
              <span style={{ color: "red" }}>password is required</span>
            )}
          </Box>
          <Button
            fontSize="sm"
            variant="brand"
            fontWeight="500"
            w="100%"
            h="50"
            mb="10px"
            //mt="10px"
            type="submit"
            _hover={{ bg: isLoading ? "blue" : buttonHover }}
            isLoading={isLoading}
          >
            Submit
          </Button>
        </form>
        <Flex
          flexDirection="column"
          justifyContent="center"
          alignItems="start"
          maxW="100%"
          mt="0px"
        >
          <Text color={textColorDetails} fontWeight="400" fontSize="14px">
            Remember your password?
            <NavLink to="/auth/sign-up">
              <Text color={textColorBrand} as="span" ms="5px" fontWeight="500">
                Log in
              </Text>
            </NavLink>
          </Text>
        </Flex>
      </Flex>
    </Box>
  );
};

export default ConfirmPassword;
