import React from "react";
import {
  Box,
  Tabs,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  useDisclosure,
} from "@chakra-ui/react";
import useEntityList from "hooks/entityList/useEntityList";
import useNodeTypes from "hooks/useNodeTypes";
import NodeApprovedDowntimes from "./nodeApprovedDowntime";
import EntityApprovedDowntimes from "./entityApprovedDowntime";
import useBreakUpReasonList from "hooks/useBreakUpReasonList";

const ApprovedDowntimes = () => {
  const { data: entityData, refetch: entityRefetch } = useEntityList(
    ":status",
    "all",
    1
  );
  const { data: nodeTypeData, refetch: nodeTypeRefetch } = useNodeTypes();

  const { data: breakupReasonData } = useBreakUpReasonList();

  const {
    isOpen: isStatusOpen,
    onOpen: onStatusOpen,
    onClose: onStatusClose,
  } = useDisclosure();

  const generatenEntityOptions = () => {
    return entityData?.data?.map((entity) => {
      return <option value={entity.id}>{entity.name}</option>;
    });
  };

  const generatenNodeTypeOptions = () => {
    return nodeTypeData?.data[0]?.result?.map((nodeType) => {
      return <option value={nodeType.id}>{nodeType.name}</option>;
    });
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "90px" }}>
      <Tabs pt="0px">
        <TabList>
          <Tab
            _selected={{
              color: "ndmsColors.blue.100",
              boxShadow: "none",
              borderBottom: "3px solid #4318FF",
            }}
            color="#828282"
            fontSize="18px"
            fontWeight="bold"
          >
            Node Level
          </Tab>
          <Tab
            _selected={{
              color: "ndmsColors.blue.100",
              boxShadow: "none",
              borderBottom: "3px solid #4318FF",
            }}
            color="#828282"
            fontSize="18px"
            fontWeight="bold"
          >
            Entity Level
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel pl="0px">
            <NodeApprovedDowntimes
              breakupReasonData={breakupReasonData?.data}
            />
          </TabPanel>
          <TabPanel pl="0px">
            <EntityApprovedDowntimes
              breakupReasonData={breakupReasonData?.data}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default ApprovedDowntimes;
