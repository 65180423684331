import React, { useState, useEffect } from "react";
import Card from "components/card/Card.js";
import DownTimeListTable from "components/table/downTimeListTable";
import useGetPermissions from "hooks/permissions/useGetPermissions";
import LoadingForm from "components/loader/LoadingForm";
import noGroupImage from "assets/img/layout/noGroup.png";
import {
  Box,
  Button,
  Tooltip,
  Flex,
  Icon,
  Image,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import AddPermissionsForm from "./addPermissionsForm";
import EditPermissionsForm from "./editPermissionsForm";
import DeleteModal from "components/modal/deleteModal";
import useDeletePermission from "hooks/permissions/useDeletePermission";
import { getDashboardPermissions } from "Permissions";
import useGetPermissionGroup from "hooks/groupPermissions/useGroupPermission";

const Permissions = () => {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const [editData, setEditData] = useState(null);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const {
    isLoading,
    error,
    data: permissionsData,
    refetch,
    isFetching,
  } = useGetPermissions(20, currentPage);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutate: deletePermission } = useDeletePermission(onClose);
  const { data: groupPermissionsData } = useGetPermissionGroup();

  useEffect(() => {
    refetch();
  }, [currentPage]);

  const [deleteId, setDeleteId] = useState(null);

  if (isLoading || isFetching) return <LoadingForm />;
  const handleDelete = (id) => {
    setDeleteId(id);
    onOpen();
  };
  const handleEdit = (row) => {
    setEditData(row);
    setShowEditForm(true);
  };

  const columnsData = [
    {
      Header: "ID",
      accessor: (row) => {
        return (
          <Text
            color={"ndmsColors.purple.200"}
            fontSize="12px"
            fontWeight="700"
          >
            {row.id}
          </Text>
        );
      },
    },
    {
      Header: "NAME",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row.name}
          </Text>
        );
      },
    },
    {
      Header: "GROUP NAME",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row.group_name}
          </Text>
        );
      },
    },
    {
      Header: "SLUG",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row.slug}
          </Text>
        );
      },
    },
    {
      Header: "   ",
      accessor: "   ",
    },
    {
      Header: "   ",
      accessor: "------",
    },

    {
      Header: "ACTION",
      accessor: (row) => {
        return (
          <Flex justifyContent="center" align="center">
            {getDashboardPermissions("permissions.update") ? (
              <Tooltip hasArrow label="Edit" bg="#2B3674">
                <Flex mr="10px" onClick={() => handleEdit(row)}>
                  <Icon
                    as={EditIcon}
                    width="17px"
                    height="17px"
                    color="ndmsColors.purple.200"
                  />{" "}
                </Flex>
              </Tooltip>
            ) : null}
            {getDashboardPermissions("permissions.delete") ? (
              <Tooltip hasArrow label="Delete" bg="ndmsColors.red.200">
                <Flex onClick={() => handleDelete(row.id)}>
                  <Icon
                    as={DeleteIcon}
                    width="17px"
                    height="17px"
                    color="ndmsColors.red.200"
                  />{" "}
                </Flex>
              </Tooltip>
            ) : null}
          </Flex>
        );
      },
    },
  ];

  return (
    <>
      {showAddForm && (
        <AddPermissionsForm
          setShowAddForm={setShowAddForm}
          groupPermissionsData={groupPermissionsData?.data[0]?.result}
        />
      )}
      {showEditForm && (
        <EditPermissionsForm
          setShowEditForm={setShowEditForm}
          editData={editData}
          groupPermissionsData={groupPermissionsData?.data[0]?.result}
        />
      )}
      {!showAddForm && !showEditForm && (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
          <Flex align="center" justifyContent="flex-end">
            <Button
              bg="ndmsColors.purple.200"
              minWidth="104px"
              borderRadius="6px"
              mb="42px"
              _hover={{ bg: "ndmsColors.blue.400" }}
              _active={{ bg: "ndmsColors.blue.400" }}
              onClick={() => setShowAddForm(true)}
            >
              <Icon as={AddIcon} w={3} h={3} color="#FFF" />
              <Text ml="10px" color="#FFF" fontSize="14px">
                {" "}
                Add
              </Text>
            </Button>
          </Flex>
          <Card
            direction="column"
            w="100%"
            px="0px"
            overflowX={{ sm: "scroll", lg: "hidden" }}
          >
            {permissionsData?.data[0]?.result?.length > 0 ? (
              <Flex p="20px" direction="column">
                <DownTimeListTable
                  tableData={permissionsData?.data[0]?.result}
                  columnsData={columnsData}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  totalPage={permissionsData?.data[0]?.total_pages}
                />
              </Flex>
            ) : (
              <Flex
                align="center"
                justify="center"
                mt="200px"
                cursor="pointer"
                mb="150px"
                onClick={() => setShowAddForm(true)}
              >
                <Flex direction="column" justify="center" align="center">
                  <Image src={noGroupImage} width="70px" alt="no-group" />
                  <Text fontSize={"22px"} color="#2B3674" fontWeight="bold">
                    You Don’t Have Any Permission
                  </Text>
                </Flex>
              </Flex>
            )}
          </Card>
        </Box>
      )}
      <DeleteModal
        onOpen={onOpen}
        onClose={onClose}
        isOpen={isOpen}
        id={deleteId}
        deleteItem={deletePermission}
      />
    </>
  );
};

export default Permissions;
