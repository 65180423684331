import { QUERY_KEY, MUTATION_KEY } from "constants/globalConstants";
import DashboardApiService from "apiService/dashboardApiService";
import { useMutation, useQuery } from "react-query";
import toast from "react-hot-toast";

const useGenerateBlackoutReport = (
  blackoutEnabled,
  entityTypeId,
  entityTypeName,
  nodeTypeId,
  nodeTypeName,
  nodeId,
  nodeIdName,
  startDate,
  endDate,
  uptimeChecked,
  scheduleChecked,
  effectiveChecked,
  showTimeChecked,
  onClose,
  setBlackoutEnabled
) => {
  return useQuery(
    QUERY_KEY.GET_BLACKOUT_CSV_REPORT,
    () =>
      DashboardApiService.getBlackoutCsvReport(
        entityTypeId,
        entityTypeName,
        nodeTypeId,
        nodeTypeName,
        nodeId,
        nodeIdName,
        startDate,
        endDate,
        uptimeChecked,
        scheduleChecked,
        effectiveChecked,
        showTimeChecked
      ),
    {
      retry: false,
      enabled: blackoutEnabled,
      onSuccess: (res) => {
        blackoutEnabled &&
          window.open(res?.data, "_blank", "noopener,noreferrer");
        setBlackoutEnabled(false);
        onClose();
      },
      onError: (err) => {
        toast.error(err.response.data.message);
        setBlackoutEnabled(false);
      },
    }
  );
};
export default useGenerateBlackoutReport;
