import { QUERY_KEY } from "constants/globalConstants";
import { useQuery } from "react-query";
import DashboardApiService from "apiService/dashboardApiService";

const useNodeTypeList= (entityId, enableFlag) => {
  return useQuery(
    QUERY_KEY.GET_NODE_TYPE_LIST,
    () => DashboardApiService.getNodeTypeList(entityId),
    { retry: false ,enabled:enableFlag}
  );
};

export default useNodeTypeList;
