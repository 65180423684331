import React from "react";
import Card from "components/card/Card.js";
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Skeleton,
  Select,
  RadioGroup,
  Radio,
  Stack,
  Input,
  Text,
  useDisclosure,
  useColorModeValue,
} from "@chakra-ui/react";
import toast from "react-hot-toast";
import { useForm, Controller } from "react-hook-form";
import TicketModuleHooks from "hooks/ticketModule/ticketModuleHooks";
import { useHistory } from "react-router-dom";

function AddTicketTabs({ setShowAddForm, entityId, entityName }) {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  let activeColor = useColorModeValue("gray.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const buttonHover = useColorModeValue("brand.600", "brand.400");
  const history = useHistory();
  const {
    isLoading: isStatusLoading,
    error,
    data,
    refetch,
    isFetching,
  } = TicketModuleHooks.useAllTicketStatus();

  const { mutate: addTicketTabs, isLoading: isAddTicketLoading } =
    TicketModuleHooks.useAddTicketTabs();

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    if (data.show_order < 0) {
      toast.error("show order should be greater than 0");
    } else {
      addTicketTabs({
        label: data.label,
        status_id: data.status_id,
        assigned: data.assigned === "true" ? true : false,
        is_visible: data.is_visible === "true" ? true : false,
        show_order: data.show_order,
      });
    }
  };

  const generateStatusOptions = () => {
    return data?.data?.map((status) => {
      return <option value={status.id}>{status.name}</option>;
    });
  };

  const handleCancel = () => {
    history.push(`/admin/ticket-crud/ticket-tabs`);
  };

  return (
    <>
      <Box w="100%" pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Card>
          <Flex
            maxW={{ base: "100%" }}
            w="100%"
            mx={{ base: "auto", lg: "0px" }}
            me="auto"
            h="100%"
            alignItems="start"
            justifyContent="center"
            mb={{ base: "30px", md: "30px" }}
            px={{ base: "25px", md: "0px" }}
            mt={{ base: "20px", md: "20px" }}
            flexDirection="column"
          >
            <Box me="auto">
              <Text
                mb="24px"
                ms="4px"
                color={activeColor}
                fontWeight="bold"
                fontSize="24px"
              >
                Add Ticket Tabs
              </Text>
            </Box>
            <Flex
              direction="column"
              w="100%"
              maxW="100%"
              background="transparent"
              borderRadius="15px"
              mx={{ base: "auto", lg: "unset" }}
              me="auto"
              mb={{ base: "20px", md: "auto" }}
            >
              <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
                <Flex w="100%">
                  <Flex direction="column" width="50%" pl="10px">
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                    >
                      Label<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <div style={{ marginBottom: "24px" }}>
                      <Controller
                        name="label"
                        rules={{
                          required: true,
                          validate: (value) => {
                            return !!value.trim();
                          },
                        }}
                        control={control}
                        render={({ field }) => (
                          <Input
                            fontSize="sm"
                            ms={{ base: "0px", md: "0px" }}
                            type="label"
                            placeholder="Enter Label"
                            //mb="24px"
                            fontWeight="500"
                            size="lg"
                            {...field}
                          />
                        )}
                      />
                      {errors.label && (
                        <span style={{ color: "red" }}>label is required</span>
                      )}
                    </div>
                  </Flex>
                  <Flex direction="column" width="50%" pl="10px">
                    <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      isRequired={true}
                      display="flex"
                    >
                      Status<Text color={brandStars}>*</Text>
                    </FormLabel>
                    {isStatusLoading ? (
                      <Skeleton width="100%" height="50px" mb="24px" />
                    ) : (
                      <div style={{ marginBottom: "24px" }}>
                        <Controller
                          name="status_id"
                          rules={{ required: true }}
                          control={control}
                          render={({ field }) => (
                            <Select
                              id="user_type"
                              size="lg"
                              fontSize="14px"
                              placeholder="Select Status"
                              {...field}
                            >
                              {generateStatusOptions()}
                            </Select>
                          )}
                        />
                        {errors.status_id?.type === "required" && (
                          <span style={{ color: "red" }}>
                            status is required
                          </span>
                        )}
                      </div>
                    )}
                  </Flex>
                </Flex>

                <Flex direction="column" width="50%" pl="10px">
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Show Order<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <div style={{ marginBottom: "24px" }}>
                    <Controller
                      name="show_order"
                      rules={{ required: true }}
                      control={control}
                      render={({ field }) => (
                        <Input
                          fontSize="sm"
                          placeholder="Enter Show Order"
                          size="lg"
                          type="number"
                          {...field}
                        />
                      )}
                    />
                    {errors.show_order?.type === "required" && (
                      <span style={{ color: "red" }}>
                        show order is required
                      </span>
                    )}
                  </div>
                </Flex>

                <Flex>
                  <Flex direction="column" width="100%" pl="10px">
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                    >
                      Assigned
                    </FormLabel>

                    <div style={{ marginBottom: "24px" }}>
                      <Controller
                        name="assigned"
                        rules={{ required: false }}
                        control={control}
                        render={({ field }) => (
                          <RadioGroup {...field}>
                            <Stack direction="row">
                              <Radio
                                value={"true"}
                                borderColor="#422AFB"
                                pr="20px"
                              >
                                Yes
                              </Radio>
                              <Radio
                                value={"false"}
                                borderColor="#422AFB"
                                pr="20px"
                              >
                                No
                              </Radio>
                            </Stack>
                          </RadioGroup>
                        )}
                      />
                    </div>
                  </Flex>
                  <Flex direction="column" width="100%" pl="10px">
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                    >
                      Is Visible<Text color={brandStars}>*</Text>
                    </FormLabel>

                    <div style={{ marginBottom: "24px" }}>
                      <Controller
                        name="is_visible"
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                          <RadioGroup {...field}>
                            <Stack direction="row">
                              <Radio
                                value={"true"}
                                borderColor="#422AFB"
                                pr="20px"
                              >
                                Yes
                              </Radio>
                              <Radio
                                value={"false"}
                                borderColor="#422AFB"
                                pr="20px"
                              >
                                No
                              </Radio>
                            </Stack>
                          </RadioGroup>
                        )}
                      />
                    </div>
                    {errors.is_visible?.type === "required" && (
                      <span style={{ color: "red" }}>
                        is visible is required
                      </span>
                    )}
                  </Flex>
                </Flex>

                <Flex width="40%" pl="10px">
                  <Button
                    fontSize="sm"
                    variant="brand"
                    fontWeight="500"
                    w="90%"
                    h="50"
                    mb="24px"
                    mr="20px"
                    type="submit"
                    isLoading={isAddTicketLoading}
                    _hover={{ bg: isAddTicketLoading ? "blue" : buttonHover }}
                  >
                    Submit
                  </Button>
                  <Button
                    fontSize="sm"
                    variant="brand"
                    fontWeight="500"
                    w="90%"
                    h="50"
                    mb="24px"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Flex>
              </form>
            </Flex>
          </Flex>
        </Card>
      </Box>
    </>
  );
}

export default AddTicketTabs;
