/* eslint-disable */
import {
  Flex,
  Progress,
  Table,
  Box,
  Tbody,
  Icon,
  Center,
  Td,
  TableContainer,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import React, { useMemo } from "react";
import { TableUpIcon } from "components/icons/Icons";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import EmptyCard from "components/card/EmptyCard";
import { MdEdit } from "react-icons/md";

export default function TmsMembersTable({ columnsData, tableData }) {
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("secondaryGray.500", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  return (
    <TableContainer>
      <Table
        style={{ overflow: "hidden" }}
        {...getTableProps()}
        variant="simple"
      >
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={index}
                  py="5px"
                  borderColor="#EAEDF2"
                >
                  <Flex
                    //justify="space-evenly"
                    //pr="30px"
                    align="start"
                    color="black"
                    fontSize={{ sm: "10px", lg: "12px" }}
                    textTransform="capitalize"
                  >
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <Tr {...row.getRowProps()} key={index}>
                {row.cells.map((cell, cellIndex) => {
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={cellIndex}
                      py="5px"
                      pr={
                        columns[cellIndex].Header === "Tickets" ? "0px" : "20px"
                      }
                      fontSize={{ sm: "14px" }}
                      borderColor="#EAEDF2"
                      //minW={{ sm: "150px", md: "100px", lg: "auto" }}
                    >
                      <Flex align="center" width={"50px"}>
                        {cell.render("Cell")}
                      </Flex>
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
}
