import React from "react";
import Card from "components/card/Card.js";
import {
  Box,
  Button,
  Flex,
  Icon,
  Spinner,
  Skeleton,
  Text,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";

const LoadingForm = () => {
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Flex align="center" justifyContent="flex-end">
        <Button
          bg="ndmsColors.purple.200"
          minWidth="104px"
          borderRadius="6px"
          mb="42px"
          _hover={{ bg: "ndmsColors.blue.400" }}
          _active={{ bg: "ndmsColors.blue.400" }}
        >
          <Text ml="10px" color="ndmsColors.black.100" fontSize="14px">
            {" "}
            <Skeleton
              minWidth="104px"
              height="20px"
              startColor="white"
              endColor="white"
            />
          </Text>
        </Button>
      </Flex>
      <Card w="100%" h="500px" px="0px">
        <Flex direction="column" mt="200px" align="center" justify="center">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="blue.500"
            size="xl"
          />
        </Flex>
      </Card>
    </Box>
  );
};

export default LoadingForm;
