import React, { useState, useEffect } from "react";
import {
  Select,
  Switch,
  Box,
  Text,
  Center,
  Divider,
  Button,
  MenuButton,
  MenuList,
  Menu,
  MenuItem,
  Flex,
  Image,
  useDisclosure,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import "../../admin/layoutPlanning";
import noGroupImage from "assets/img/layout/noGroup.png";
import CreateGroup from "./createGroup";
import {
  UpIcon,
  DownIcon,
  GridIconDark,
  GridIconLight,
  ListIconDark,
  ListIconLight,
} from "components/icons/Icons";
import useGroup from "hooks/useGroup";
import ExistingGroup from "./existingGroup";
import FileUploadModal from "./fileUploadModal";
import GroupLoader from "./groupLoader";
import useNodeTypes from "hooks/useNodeTypes";
import CardDetails from "./cardDetails";
import { useParams } from "react-router-dom";
import UseTemplateModal from "./useTemplateModal";
import ListGroup from "./listGroup";

import { getLayoutPermissions } from "Permissions";
import useExportNodes from "hooks/useExportNodes";
import LicenseModal from "components/modal/licenseModal";
import { getDashboardPermissions } from "Permissions";
import useUntraceableGroup from "hooks/useUntraceableGroup";

const LayoutPlanning = () => {
  const [showCreateGroup, setShowCreateGroup] = useState(false);
  const [showCardDetails, setShowCardDetails] = useState(false);
  const [enabled, setEnabled] = useState(false);
  const [traceableDevices, setTraceableDevice] = useState(true);
  const [individualGroupData, setIndividualGroupData] = useState(null);
  const [toggleViewMode, setToggleViewMode] = useState(true);
  const entityId = parseInt(useParams()?.id);
  const entityName = useParams()?.name;

  const {
    isLoading,
    error,
    data: groupData,
    isFetching,
    refetch,
  } = useGroup(entityId, traceableDevices);

  const handleChange = (e) => {
    setTraceableDevice(e.target.checked);
  };

  useEffect(() => {
    refetch();
  }, [entityId]);

  useEffect(() => {
    refetch();
  }, [traceableDevices]);

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const {
    isLoading: isNodeLoading,
    error: nodeError,
    data: nodeTypeData,
  } = useNodeTypes();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isLoading: isExportLoading,
    refetch: exportRefetch,
    isFetching: isExportFetching,
  } = useExportNodes(enabled, entityId, setEnabled);
  const {
    isOpen: isUseModalOpen,
    onOpen: onUseModalOpen,
    onClose: onUseModalClose,
  } = useDisclosure();
  const {
    isOpen: isLicenseOpen,
    onOpen: onLicenseOpen,
    onClose: onLicenseClose,
  } = useDisclosure();

  useEffect(() => {
    if (enabled) {
      exportRefetch();
    }
  }, [enabled]);

  if (isLoading) {
    return <GroupLoader />;
  }

  if (isFetching) {
    return <GroupLoader />;
  }

  const user = "superAdmin";

  const getCardLayoutDesign = () => {
    switch (getLayoutPermissions(user)) {
      case "full permissions":
        return (
          <>
            {toggleViewMode ? (
              <Flex pt="20px" style={{ flexWrap: "wrap" }}>
                {groupData?.data?.map((group, index) => (
                  <ExistingGroup
                    group={group}
                    traceableDevices={traceableDevices}
                    setTraceableDevice={setTraceableDevice}
                    index={index}
                    nodeData={nodeTypeData?.data[0]?.result}
                    individualGroupData={individualGroupData}
                    setShowCardDetails={setShowCardDetails}
                    entityId={entityId}
                    setIndividualGroupData={setIndividualGroupData}
                  />
                ))}
              </Flex>
            ) : (
              <Box>
                {groupData?.data?.map((group, index) => (
                  <ListGroup
                    group={group}
                    traceableDevices={traceableDevices}
                    index={index}
                    nodeData={nodeTypeData?.data[0]?.result}
                    setShowCardDetails={setShowCardDetails}
                    entityId={entityId}
                  />
                ))}
              </Box>
            )}
          </>
        );
      case "grid permissions":
        return (
          <Flex pt="20px" style={{ flexWrap: "wrap" }}>
            {groupData?.data?.map((group, index) => (
              <ExistingGroup
                traceableDevices={traceableDevices}
                setTraceableDevice={setTraceableDevice}
                group={group}
                index={index}
                nodeData={nodeTypeData?.data}
                individualGroupData={individualGroupData}
                setShowCardDetails={setShowCardDetails}
                entityId={entityId}
                setIndividualGroupData={setIndividualGroupData}
              />
            ))}
          </Flex>
        );
      case "list permissions":
        return (
          <Box>
            {groupData?.data?.map((group, index) => (
              <ListGroup
                group={group}
                traceableDevices={traceableDevices}
                index={index}
                nodeData={nodeTypeData?.data}
                setShowCardDetails={setShowCardDetails}
                entityId={entityId}
              />
            ))}
          </Box>
        );
      default:
        return (
          <Flex pt="20px" style={{ flexWrap: "wrap" }}>
            {groupData?.data?.map((group, index) => (
              <ExistingGroup
                traceableDevices={traceableDevices}
                setTraceableDevice={setTraceableDevice}
                group={group}
                index={index}
                nodeData={nodeTypeData?.data}
                individualGroupData={individualGroupData}
                setShowCardDetails={setShowCardDetails}
                entityId={entityId}
                setIndividualGroupData={setIndividualGroupData}
              />
            ))}
          </Flex>
        );
    }
  };

  const handleExport = () => {
    setEnabled(true);
  };

  return (
    <>
      {showCreateGroup && (
        <CreateGroup
          setShowCreateGroup={setShowCreateGroup}
          entityId={entityId}
        />
      )}
      {showCardDetails && (
        <CardDetails
          traceableDevices={traceableDevices}
          setShowCardDetails={setShowCardDetails}
          individualGroupData={individualGroupData}
          setIndividualGroupData={setIndividualGroupData}
          entityId={entityId}
        />
      )}
      {!showCreateGroup && !showCardDetails && (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
          <Card
            bg="rgba(67, 24, 255, 0.02)"
            height={toggleViewMode && "max-content"}
          >
            <Flex direction="column" justify="center">
              <Flex align="center" justify="space-between">
                <Text
                  fontSize={"26px"}
                  mb="12px"
                  ml="10px"
                  color="#2B3674"
                  fontWeight="bold"
                >
                  My Groups
                </Text>
                {/* <Flex alignItems="center" justifyContent="space-between">
                  <Text fontSize="18px" fontWeight="bold" color="#2B3674">
                    Traceable Devices
                  </Text>
                  <Select
                    bg="white"
                    fontSize="15px"
                    //mt="20px"
                    width="200px"
                    height="40px"
                    id="user_type"
                    size="lg"
                    ml="10px"
                    color="#2C3774"
                    fontWeight="bold"
                    value={traceableDevices}
                    onChange={handleChange}
                  >
                    <option value="yes">Yes</option>
                    <option value="no">No</option>
                  </Select>
                </Flex> */}
                <Flex>
                  {getDashboardPermissions("nodes.export") ? (
                    <Button
                      fontSize="sm"
                      fontWeight="500"
                      w="132px"
                      h="50"
                      mb="24px"
                      mr="20px"
                      bg="#FFFFFF"
                      // onClick={() =>
                      //   openInNewTab(
                      //     `${process.env.REACT_APP_BASE_URL}/export_nodes?entity_id=${entityId}`
                      //   )
                      // }
                      onClick={handleExport}
                      isLoading={isExportLoading || isExportFetching}
                    >
                      <DownIcon mt="5px" />
                      <Text
                        fontSize={"16px"}
                        ml="6px"
                        color="#4318FF"
                        fontWeight="bold"
                      >
                        Export
                      </Text>
                    </Button>
                  ) : null}

                  {getDashboardPermissions("nodes.import") ? (
                    <Button
                      fontSize="sm"
                      fontWeight="500"
                      w="132px"
                      h="50"
                      mb="24px"
                      mr="20px"
                      type="submit"
                      bg="#FFFFFF"
                      onClick={() => onOpen()}
                    >
                      <UpIcon mt="5px" />
                      <Text
                        fontSize={"16px"}
                        ml="6px"
                        color="#4318FF"
                        fontWeight="bold"
                      >
                        Import
                      </Text>
                    </Button>
                  ) : null}
                  <Menu>
                    <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      gap={2}
                      mr="5px"
                      height="50px"
                    >
                      <Text fontSize="18px" fontWeight="bold" color="#2B3674">
                        Traceable Devices
                      </Text>
                      <Switch
                        id="isChecked"
                        isChecked={traceableDevices}
                        onChange={handleChange}
                        colorScheme="teal"
                        size="100px"
                      />
                    </Flex>
                    <MenuButton
                      as={Button}
                      fontSize="sm"
                      variant="brand"
                      fontWeight="500"
                      w="151px"
                      h="50"
                      mb="24px"
                      mr="20px"
                    >
                      <Text fontSize={"16px"} color="#FFFFFF" fontWeight="bold">
                        Create Group
                      </Text>
                    </MenuButton>
                    <MenuList>
                      <MenuItem onClick={() => onUseModalOpen()}>
                        Use Template
                      </MenuItem>
                      <MenuItem onClick={() => setShowCreateGroup(true)}>
                        Create New
                      </MenuItem>
                    </MenuList>
                  </Menu>
                  {getLayoutPermissions(user) === "full permissions" ? (
                    <Flex
                      align="center"
                      justify="space-around"
                      height="40px"
                      bg="white"
                      width="80px"
                      mt="5px"
                      border="1px solid #DEDEDE"
                      borderRadius="6px"
                      cursor="pointer"
                    >
                      <Box
                        pl="5px"
                        pt="2px"
                        onClick={() => setToggleViewMode(!toggleViewMode)}
                      >
                        {toggleViewMode ? <GridIconDark /> : <GridIconLight />}
                      </Box>
                      <Center height="40px">
                        <Divider orientation="vertical" />
                      </Center>
                      <Box
                        pr="10px"
                        onClick={() => setToggleViewMode(!toggleViewMode)}
                      >
                        {toggleViewMode ? <ListIconLight /> : <ListIconDark />}
                      </Box>
                    </Flex>
                  ) : null}
                </Flex>
              </Flex>
              {groupData?.data?.length > 0 ? (
                getCardLayoutDesign()
              ) : (
                <Flex
                  align="center"
                  justify="center"
                  cursor="pointer"
                  mt="200px"
                  onClick={() => setShowCreateGroup(true)}
                >
                  <Flex direction="column" justify="center" align="center">
                    <Image src={noGroupImage} width="70px" alt="no-group" />
                    <Text fontSize={"22px"} color="#2B3674" fontWeight="bold">
                      You Don’t Have Any Group
                    </Text>
                  </Flex>
                </Flex>
              )}
            </Flex>
          </Card>
          <UseTemplateModal
            isOpen={isUseModalOpen}
            onOpen={onUseModalOpen}
            onClose={onUseModalClose}
            entityId={entityId}
          />
          <FileUploadModal
            isOpen={isOpen}
            onOpen={onOpen}
            onClose={onClose}
            entityId={entityId}
          />
          <LicenseModal
            onOpen={onLicenseOpen}
            isOpen={isLicenseOpen}
            onClose={onLicenseClose}
            id={entityId}
            entityName={entityName}
          />
        </Box>
      )}
    </>
  );
};

export default LayoutPlanning;
