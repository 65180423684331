import { QUERY_KEY, MUTATION_KEY } from "constants/globalConstants";
import ApiService from "apiService";
import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";

const useUpdateGroup = (id) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.UPDATE_GROUP],
    (data) => ApiService.updateGroup(data, id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.GET_GROUP);
        toast.success('Group updated successfully')
      },
      onError: (error) => {
        toast.error(error.response.data.message)
    },
    }
  );
};

export default useUpdateGroup