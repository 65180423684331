// Chakra Imports
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Flex,
  Link,
  Button,
  useDisclosure,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import PropTypes from "prop-types";
import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router";
import "./navbar.css";
import AdminNavbarLinks from "components/navbar/NavbarLinksAdmin";
import { getDashboardPermissions } from "Permissions";
import CreateTicketDrawer from "components/drawer/createTicketDrawer";

export default function AdminNavbar(props) {
  const [scrolled, setScrolled] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const drawerRef = useRef(null);

  useEffect(() => {
    window.addEventListener("scroll", changeNavbar);

    return () => {
      window.removeEventListener("scroll", changeNavbar);
    };
  });

  const entityId = useParams()?.id;
  const entityName = useParams()?.name;
  const status = useParams()?.status;
  const nodeId = useParams()?.nodeId;

  const superAdminRoutes = [
    {
      name: "Customer",
      crumbs: [{ name: "Customer", url: "/admin/customer" }],
    },
    {
      name: "Node Type",
      crumbs: [{ name: "Node Type", url: "/admin/node-type" }],
    },
    {
      name: "Node Dashboard",
      crumbs: [
        { name: "Entity", url: `/admin/entity/:status` },
        {
          name: "Entity Dashboard",
          url: `/admin/entity-dashboard/${entityId}/${entityName}`,
        },
        {
          name: "Node Dashboard",
          url: `/admin/node-dashboard/${entityId}/${entityName}/node_id=${nodeId}`,
        },
      ],
    },
    {
      name: "Permission Group",
      crumbs: [{ name: "Permission Group", url: "/admin/permission-group" }],
    },
    {
      name: "Permissions",
      crumbs: [{ name: "Permissions", url: "/admin/permissions" }],
    },
    {
      name: "Role",
      crumbs: [{ name: "Role", url: "/admin/role" }],
    },
    {
      name: "User",
      crumbs: [{ name: "User", url: "/admin/user" }],
    },
    {
      name: "Configuration",
      crumbs: [{ name: "Configuration", url: "/admin/master-configuration" }],
    },
    {
      name: "Reports",
      crumbs: [{ name: "Reports", url: "" }],
    },
    {
      name: "Entity",
      crumbs: [{ name: "Entity", url: `/admin/entity/:status` }],
    },
    {
      name: "Layout Planning",
      crumbs: [
        { name: "Entity", url: `/admin/entity/:status` },
        {
          name: "Layout Planning",
          url: `/admin/layout-planning/${entityId}/${entityName}`,
        },
      ],
    },
    {
      name: "Schedule Downtime",
      permission: "entity.schedule_downtime.view",
    },
    {
      name: "Node",
      permission: "entity.node.view",
    },
    {
      name: "Node Group Templates",
      crumbs: [{ name: "Node Group Templates", url: "/admin/template" }],
    },
    {
      name: "Approved Downtimes",
      crumbs: [
        { name: "Approved Downtimes", url: "/admin/approved-downtimes" },
      ],
    },
    {
      name: "Entity Dashboard",
      crumbs: [
        { name: "Entity", url: `/admin/entity/:status` },
        {
          name: "Entity Dashboard",
          url: `/admin/entity-dashboard/${entityId}/${entityName}`,
        },
      ],
    },
    {
      name: "Entity Client List",
      crumbs: [
        { name: "Entity", url: `/admin/entity/:status` },
        {
          name: "Entity Client List",
          url: `/admin/entity-client-list/${entityId}/${entityName}`,
        },
      ],
    },
    {
      name: "Install Client",
      crumbs: [
        { name: "Entity", url: `/admin/entity/:status` },
        {
          name: "Entity Client List",
          url: `/admin/entity-client-list/${entityId}/${entityName}`,
        },
        {
          name: "Install Client",
          url: `/admin/install-client/${entityId}/${entityName}`,
        },
      ],
    },
    {
      name: "Entity Client List",
      showInSidebar: false,
    },
    {
      name: "Group Dashboard",
      showInSidebar: false,
    },
    {
      name: "Live Dashboard",
      showInSidebar: false,
    },
    {
      name: "Sign In",
      showInSidebar: false,
    },
    {
      name: "Forgot Password",
      showInSidebar: false,
    },
  ];

  const routes = [
    {
      name: "Home",
      crumbs: [{ name: "Home", url: "/admin/home" }],
    },
    {
      name: "Customer",
      crumbs: [
        { name: "Home", url: "/admin/home" },
        { name: "Customer", url: "/admin/customer" },
      ],
    },
    {
      name: "Node Type",
      crumbs: [
        { name: "Home", url: "/admin/home" },
        { name: "Node Type", url: "/admin/node-type" },
      ],
    },
    {
      name: "Node Dashboard",
      crumbs: [
        { name: "Home", url: "/admin/home" },
        { name: "Entity", url: `/admin/entity/:status` },
        {
          name: "Entity Dashboard",
          url: `/admin/entity-dashboard/${entityId}/${entityName}`,
        },
        {
          name: "Node Dashboard",
          url: `/admin/node-dashboard/${entityId}/${entityName}/node_id=${nodeId}`,
        },
      ],
    },
    {
      name: "Permission Group",
      crumbs: [
        { name: "Home", url: "/admin/home" },
        { name: "Permission Group", url: "/admin/permission-group" },
      ],
    },
    {
      name: "Permissions",
      crumbs: [
        { name: "Home", url: "/admin/home" },
        { name: "Permissions", url: "/admin/permissions" },
      ],
    },
    {
      name: "Role",
      crumbs: [
        { name: "Home", url: "/admin/home" },
        { name: "Role", url: "/admin/role" },
      ],
    },
    {
      name: "User",
      crumbs: [
        { name: "Home", url: "/admin/home" },
        { name: "User", url: "/admin/user" },
      ],
    },
    {
      name: "Configuration",
      crumbs: [
        { name: "Home", url: "/admin/home" },
        { name: "Configuration", url: "/admin/client-configuration" },
      ],
    },
    {
      name: "Reports",
      crumbs: [
        { name: "Home", url: "/admin/home" },
        { name: "Reports", url: "" },
      ],
    },
    {
      name: "Entity",
      crumbs: [
        { name: "Home", url: "/admin/home" },
        { name: "Entity", url: `/admin/entity/:status` },
      ],
    },
    {
      name: "Layout Planning",
      crumbs: [
        { name: "Home", url: "/admin/home" },
        { name: "Entity", url: `/admin/entity/:status` },
        {
          name: "Layout Planning",
          url: `/admin/layout-planning/${entityId}/${entityName}`,
        },
      ],
    },
    {
      name: "Schedule Downtime",
      permission: "entity.schedule_downtime.view",
    },
    {
      name: "Node",
      permission: "entity.node.view",
    },
    {
      name: "Node Group Templates",
      crumbs: [
        { name: "Home", url: "/admin/home" },
        { name: "Node Group Templates", url: "/admin/template" },
      ],
    },
    {
      name: "Approved Downtimes",
      crumbs: [
        { name: "Home", url: "/admin/home" },
        { name: "Approved Downtimes", url: "/admin/approved-downtimes" },
      ],
    },
    {
      name: "Entity Dashboard",
      crumbs: [
        { name: "Home", url: "/admin/home" },
        { name: "Entity", url: `/admin/entity/:status` },
        {
          name: "Entity Dashboard",
          url: `/admin/entity-dashboard/${entityId}/${entityName}`,
        },
      ],
    },
    {
      name: "Entity Client List",
      crumbs: [
        { name: "Home", url: "/admin/home" },
        { name: "Entity", url: `/admin/entity/:status` },
        {
          name: "Entity Client List",
          url: `/admin/entity-client-list/${entityId}/${entityName}`,
        },
      ],
    },
    {
      name: "Install Client",
      crumbs: [
        { name: "Home", url: "/admin/home" },
        { name: "Entity", url: `/admin/entity/:status` },
        {
          name: "Entity Client List",
          url: `/admin/entity-client-list/${entityId}/${entityName}`,
        },
        {
          name: "Install Client",
          url: `/admin/install-client/${entityId}/${entityName}`,
        },
      ],
    },
    {
      name: "Entity Client List",
      showInSidebar: false,
    },
    {
      name: "Group Dashboard",
      showInSidebar: false,
    },
    {
      name: "Live Dashboard",
      showInSidebar: false,
    },
    {
      name: "Sign In",
      showInSidebar: false,
    },
    {
      name: "Forgot Password",
      showInSidebar: false,
    },
  ];

  const { secondary, message, brandText, secondryText, isExpanded } = props;
  // Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
  let mainText = useColorModeValue("navy.700", "white");
  let secondaryText = useColorModeValue("gray.700", "white");
  let navbarPosition = "fixed";
  let navbarFilter = "none";
  let navbarBackdrop = "blur(20px)";
  let navbarShadow = "none";
  let navbarBg = useColorModeValue(
    "rgba(244, 247, 254, 0.2)",
    "rgba(11,20,55,0.5)"
  );
  let navbarBorder = "transparent";
  let secondaryMargin = "0px";
  let paddingX = "15px";
  let gap = "0px";
  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  const singleRoute =
    localStorage.getItem("is_superuser") === "true"
      ? superAdminRoutes?.filter((route) => route?.name === brandText)[0]
      : routes?.filter((route) => route?.name === brandText)[0];

  return (
    <Box
      className="navNoPrint"
      position={navbarPosition}
      boxShadow={navbarShadow}
      bg={navbarBg}
      borderColor={navbarBorder}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      backgroundPosition="center"
      backgroundSize="cover"
      borderRadius="16px"
      borderWidth="1.5px"
      borderStyle="solid"
      transitionDelay="0s, 0s, 0s, 0s"
      transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
      transition-property="box-shadow, background-color, filter, border"
      transitionTimingFunction="linear, linear, linear, linear"
      alignItems={{ xl: "center" }}
      display={secondary ? "block" : "flex"}
      minH="75px"
      justifyContent={{ xl: "center" }}
      lineHeight="25.6px"
      mx="auto"
      mt={secondaryMargin}
      pb="8px"
      right={{ base: "12px", md: "30px", lg: "30px", xl: "30px" }}
      px={{
        sm: paddingX,
        md: "10px",
      }}
      ps={{
        xl: "12px",
      }}
      pt="8px"
      top={{ base: "12px", md: "16px", xl: "18px" }}
      w={{
        base: "calc(100vw - 6%)",
        md: "calc(100vw - 8%)",
        lg: isExpanded ? "100%" : "calc(100vw - 6%)",
        xl: isExpanded ? "93.5%" : "calc(100vw - 350px)",
        "2xl": isExpanded ? "94%" : "calc(100vw - 365px)",
      }}
    >
      <Flex
        w="100%"
        flexDirection={{
          sm: "column",
          md: "row",
        }}
        alignItems={{ xl: "center" }}
        mb={gap}
        className="navNoPrint"
      >
        <Box mb={{ sm: "8px", md: "0px" }}>
          <Breadcrumb>
            {singleRoute?.crumbs?.map((crumb) => (
              <BreadcrumbItem color={secondaryText} fontSize="sm" mb="5px">
                <BreadcrumbLink href={crumb?.url} color={secondaryText}>
                  {crumb?.name}
                </BreadcrumbLink>
              </BreadcrumbItem>
            ))}
          </Breadcrumb>
          {/* Here we create navbar brand, based on route name */}
          {window.location.href.includes("node-dashboard") ? null : (
            <Link
              color={mainText}
              href="#"
              bg="inherit"
              borderRadius="inherit"
              fontWeight="bold"
              fontSize="34px"
              _hover={{ color: { mainText } }}
              _active={{
                bg: "inherit",
                transform: "none",
                borderColor: "transparent",
              }}
              _focus={{
                boxShadow: "none",
              }}
            >
              {window.location.href.includes("node-dashboard")
                ? "Home"
                : secondryText.toLowerCase() === "ticket management system"
                ? localStorage.getItem("tms_text")
                : secondryText}
            </Link>
          )}
        </Box>

        <Flex ms="auto" alignItems="center" w={{ sm: "100%", md: "unset" }}>
          {window.location.href.includes("ticket-types") &&
          getDashboardPermissions("ticketing.ticket.create") ? (
            <Button
              color="#4F46BA"
              mr="20px"
              ref={drawerRef}
              border="1px solid #4F46BA"
              bg="white"
              borderRadius="6px"
              borderColor="none"
              variant="outline"
              onClick={() => onOpen()}
            >
              + Create Ticket
            </Button>
          ) : null}
          <AdminNavbarLinks
            onOpen={props.onOpen}
            logoText={props.logoText}
            secondary={props.secondary}
            fixed={props.fixed}
            scrolled={scrolled}
          />
        </Flex>
      </Flex>
      {secondary ? <Text color="white">{message}</Text> : null}
      <CreateTicketDrawer
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        drawerRef={drawerRef}
      />
    </Box>
  );
}

AdminNavbar.propTypes = {
  brandText: PropTypes.string,
  variant: PropTypes.string,
  secondary: PropTypes.bool,
  fixed: PropTypes.bool,
  onOpen: PropTypes.func,
};
