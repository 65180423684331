import React, { useState, useRef, useEffect } from "react";
import Card from "components/card/Card.js";
import NodeForm from "./nodeForm";
import { useDrag, useDrop, DndProvider } from "react-dnd";
import useGroup from "hooks/useGroup";
import useNodeTypes from "hooks/useNodeTypes";
import NodeCard from "./nodeCard";
import NodeZoomCard from "./nodeZoomCard";
import NodeSavedCard from "./nodeSavedCard";
import { useForm, Controller } from "react-hook-form";
import {
  Box,
  Button,
  Flex,
  Input,
  Icon,
  IconButton,
  useDisclosure,
  Text,
  useColorModeValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,
} from "@chakra-ui/react";
import useAddGroup from "hooks/useAddGroup";
import useDeleteGroup from "hooks/useDeleteGroup";
import { HamburgerIcon } from "@chakra-ui/icons";
import useNode from "hooks/useNode";
import useAddNode from "hooks/useAddNode";

function GroupCard({
  groupName,
  groupId,
  nodeTypeData,
  setDisableAddGroup,
  index,
}) {
  // Chakra color mode
  let activeColor = useColorModeValue("gray.700", "white");
  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();
  const { isLoading, error, data: groupData } = useGroup();
  const [showCard, setShowCard] = useState(false);
  const [groupsName, setGroupsName] = useState("");
  const [nodeName, setNodeName] = useState("");
  // const [disableAddGroup, setDisableAddGroup] = useState(false);
  const [edit, setEdit] = useState(true);
  const [groups, setGroups] = useState([]);
  const [showUi, setShowUi] = useState("");
  const [newId, setId] = useState(null);
  const [draggedObject, setDraggedObject] = useState([]);
  const [savedObject, setSavedObject] = useState([]);
  const [zoom, setZoom] = useState(false);
  const [showZoom, setShowZoom] = useState(false);
  const { mutate: addGroup } = useAddGroup(setId);
  const { mutate: deleteGroup } = useDeleteGroup();
  const {
    isLoading: isNodesLoading,
    error: nodesError,
    data: nodeData,
  } = useNode(groupId);
  const { mutate: addNode } = useAddNode();

  useEffect(() => {
    if (groupName) {
      setEdit(false);
      setGroupsName(groupName);
    }
  }, []);

  useEffect(() => {
    if (newId !== groupId && nodeData?.length > 0) {
      nodeData?.map((node, index) =>
        addNode({
          name: node.name,
          node_type_id: nodeTypeData.length + 1,
          ip_address: node.ip_address,
          order: index ? index + 1 : 1,
          group_id: newId,
        })
      );
    }
  }, [newId]);

  const [{ isOver, didDrop }, dropRef] = useDrop({
    accept: "card",
    //drop: (item)=>setModalOpen(true),
    drop: (item) => {
      // setModalOpen(true)
      //setZoom(true)
      setNodeName(item.name);
      setDraggedObject((draggedObject) =>
        !draggedObject.includes(item) ? [...draggedObject, item] : draggedObject
      );
      setTimeout(() => setShowZoom(true), 1000);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      didDrop: monitor.didDrop(),
    }),
  });

  const addCard = () => {
    groupData?.length === 0 && setDisableAddGroup(true);
    // create a new array with the old values and the new random one
    const newCards = [...groups, Math.floor(Math.random() * 100)];

    setGroups(newCards);
    //setEdit(true)
  };

  const removeCard = (group_id) => {
    deleteGroup(group_id);
  };

  const style = {
    transition: "transform .2s",
    transitionDuration: "500ms",
    transitionDelay: "500ms",
    transform: showZoom && "scale(2)",
  };

  const handleEditGroup = (event) => {
    setGroupsName(event.target.value);
  };

  const handleAddGroup = () => {
    addGroup({ name: groupsName, order: 1 });
    setEdit(false);
  };

  const handleCopyData = () => {
    addGroup({ name: groupsName, order: index + 1 });
  };

  return (
    <>
      <Card mt="15px" h="400px" style={{ position: "relative" }}>
        <Flex alignItems="center" justifyContent="center">
          {edit ? (
            <Flex alignItems="center" justifyContent="center">
              <Controller
                name="groupName"
                rules={{ required: true }}
                control={control}
                render={({ field }) => (
                  <Input
                    fontSize="lg"
                    ms={{ base: "0px", md: "0px" }}
                    width="250px"
                    value={groupsName}
                    {...field}
                    type="name"
                    placeholder="Enter Group name"
                    mr="24px"
                    fontWeight="500"
                    size="lg"
                    onChange={(event) => handleEditGroup(event)}
                  />
                )}
              />
              <Button colorScheme="blue" size="xs" onClick={handleAddGroup}>
                Save
              </Button>
            </Flex>
          ) : (
            <Text
              ms="4px"
              color={activeColor}
              fontWeight="bold"
              fontSize="24px"
              onClick={() => setEdit(true)}
            >
              {groupsName}
            </Text>
          )}
        </Flex>
        <div style={{ height: "200px" }} ref={dropRef}>
          <Flex justify={"center"}>
            {draggedObject.map((drag, index) => (
              <NodeZoomCard
                style={style}
                setShowZoom={setShowZoom}
                setSavedObject={setSavedObject}
                setDraggedObject={setDraggedObject}
                id={drag.id}
                index={nodeData?.length}
                draggedObject={draggedObject}
                savedObject={savedObject}
                name={drag.name}
                groupId={groupId}
              />
            ))}
          </Flex>
          <Flex pt="10px">
            {nodeData?.length > 0 &&
              nodeData?.map((node) => (
                <NodeSavedCard
                  data={node}
                  nodeName={nodeTypeData?.find(
                    (nodeType) => nodeType.id === node.node_type_id
                  )}
                />
              ))}
          </Flex>
          {isOver && <div>Drop Here!</div>}
        </div>
        <div
          style={{
            position: "absolute",
            right: "0",
            top: "0",
            padding: "20px",
            cursor: "pointer",
          }}
        >
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Options"
              icon={<HamburgerIcon />}
              variant="outline"
            />
            <MenuList>
              <MenuItem onClick={() => removeCard(groupId)}>Delete</MenuItem>
              <MenuItem>Copy</MenuItem>
            </MenuList>
          </Menu>
        </div>
        <Button
          fontSize="sm"
          variant="brand"
          fontWeight="500"
          w="10%"
          h="50"
          mb="16px"
          mr="20px"
          style={{ position: "absolute", right: "0", bottom: "0" }}
          onClick={handleCopyData}
        >
          copy
        </Button>
      </Card>
    </>
  );
}

export default GroupCard;
