import React from "react";
import { Box, Text, Circle, Skeleton, Flex, Badge } from "@chakra-ui/react";
import Card from "components/card/Card.js";

const ClientDashboardLoader = () => {
  return (
    <Box pt={{ base: "130px", md: "90px", xl: "90px" }} pb="50px">
      <Flex alignItems="center">
        <Flex direction="column" pt="10px" width="25%">
          <Text fontSize="24px" fontWeight="bold" color="#2B3674">
            Performance Trends
          </Text>
          <Flex mt="20px" direction="column">
            <Card mb="20px" borderRadius="7px">
              <Skeleton height="50px" />
            </Card>
            <Card mb="20px" borderRadius="7px" bg="#EDFFF6">
              <Skeleton height="50px" />
            </Card>
            <Card borderRadius="7px" bg="#FFEBF0">
              <Skeleton height="50px" />
            </Card>
          </Flex>
        </Flex>
        <Flex direction="column" ml="20px" width="75%">
          <Text fontSize="24px" mb="20px" fontWeight="bold" color="#2B3674">
            All Entities
          </Text>

          <Skeleton height="300px" />
        </Flex>
      </Flex>
      <Flex mt="30px" alignItems="center">
        <Badge
          border="1px solid #F86689"
          borderRadius="13px"
          width="100px"
          textTransform="capitalize"
          bg="#FFEBF0"
          color="#F86689"
          height="24px"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Circle mr="10px" size="8px" bg="#F86689" color="white" />
          Live
        </Badge>
        <Text fontSize="26px" pl="10px" fontWeight="bold" color="#2B3674">
          Entity Health
        </Text>
      </Flex>
      <Card
        mt="10px"
        display="flex"
        direction="row"
        height="300px"
        width="100%"
      >
        <Skeleton height="280px" />
      </Card>
    </Box>
  );
};

export default ClientDashboardLoader;
