import { QUERY_KEY, MUTATION_KEY } from "constants/globalConstants";
import UserService from "apiService/permissions";
import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";

const useCreateUser = (setShowAddForm) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.CREATE_USER],
    (data) => UserService.createUser(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.GET_USER_LIST);
        setShowAddForm(false)
        toast.success('User added successfully')
      },
      onError: (error) => {
        toast.error(error.response.data.message)
    },
    }
  );
};

export default useCreateUser