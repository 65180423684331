import React from "react";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import {
  Box,
  Text,
  RadioGroup,
  InputGroup,
  Heading,
  InputRightElement,
  Stack,
  Radio,
  Textarea,
  Select,
  useColorModeValue,
  FormLabel,
  Input,
  Flex,
} from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";
import { Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const EditDynamicForm = ({ control, errors, savedFormData, apiData }) => {
  const textColor = useColorModeValue("navy.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const generateFormOptions = (data) => {
    return data?.map((state) => {
      return <option value={state.value}>{state.label}</option>;
    });
  };
  const generateRadioOptions = (data) => {
    return data?.map((dat) => {
      return (
        <Radio value={dat.value} borderColor="#422AFB" pr="20px">
          {dat.label}
        </Radio>
      );
    });
  };

  const Option = (props) => {
    return (
      <div>
        <components.Option {...props}>
          <input
            type="checkbox"
            checked={props.isSelected}
            onChange={() => null}
          />{" "}
          <label>{props.label}</label>
        </components.Option>
      </div>
    );
  };

  const getDefaultValue = (id) => {
    const valueArray = apiData?.filter(
      (formData) => formData.entity_field_id === id
    )[0]?.value;
    const defaultValueArray = Array.isArray(valueArray) ? valueArray?.map((str, index) => ({ label: str, value: str })) : []
    return defaultValueArray
  };

  const dateIsValid = (date) => {
    return !Number.isNaN(new Date(date).getTime());
  }

  const getDefaultDate = (id) => {
    const dateValue = apiData.filter(
      (formData) => formData.entity_field_id === id
    )[0]?.value;
    return dateIsValid(new Date(dateValue)) ? new Date(dateValue) : ''
  };

  const componentBasedOnFormType = (data, name) => {
    switch (data?.type) {
      case "text":
        return (
          <Flex w="100%">
            <Flex direction="column" width="100%" pl="10px">
              <FormLabel
                display="flex"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                {data.label}
                <Text color={brandStars}>*</Text>
              </FormLabel>
              <div style={{ marginBottom: "24px" }}>
                <Controller
                  name={`${name}.${data.label}`}
                  rules={{ required: data.properties.required }}
                  defaultValue={
                    apiData.filter(
                      (formData) => formData.entity_field_id === data.id
                    )[0]?.value
                  }
                  control={control}
                  render={({ field }) => (
                    <Input
                      fontSize="sm"
                      type={data.properties.subtype}
                      placeholder={`Enter ${data.label}`}
                      //mb="24px"
                      fontWeight="500"
                      size="lg"
                      {...field}
                    />
                  )}
                />
                {errors.plot_no?.type === "required" && (
                  <span style={{ color: "red" }}>plot no is required</span>
                )}
              </div>
            </Flex>
          </Flex>
        );
      case "textarea":
        return (
          <Flex w="100%">
            <Flex direction="column" width="100%" pl="10px">
              <FormLabel
                display="flex"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                {data.label}
                <Text color={brandStars}>*</Text>
              </FormLabel>
              <div style={{ marginBottom: "24px" }}>
                <Controller
                  name={`${name}.${data.label}`}
                  rules={{ required: data.properties.required }}
                  control={control}
                  defaultValue={
                    apiData.filter(
                      (formData) => formData.entity_field_id === data.id
                    )[0]?.value
                  }
                  render={({ field }) => (
                    <Textarea
                      fontSize="sm"
                      type="name"
                      placeholder={`Enter ${data.label}`}
                      //mb="24px"
                      fontWeight="500"
                      size="lg"
                      {...field}
                    />
                  )}
                />
                {errors.plot_no?.type === "required" && (
                  <span style={{ color: "red" }}>plot no is required</span>
                )}
              </div>
            </Flex>
          </Flex>
        );
      case "select":
        return data.multiple ? (
          <Flex direction="column" width="100%" pl="10px">
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              isRequired={true}
              display="flex"
            >
              {data.label}
              <Text color={brandStars}>*</Text>
            </FormLabel>
            <div style={{ marginBottom: "24px" }}>
              <Controller
                name={`${name}.${data.label}`}
                rules={{ required: data.properties.required }}
                defaultValue={getDefaultValue(data.id)}
                control={control}
                render={({ field }) => (
                  <ReactSelect
                    options={data.values}
                    isMulti
                    closeMenuOnSelect={false}
                    hideSelectedOptions={false}
                    components={{
                      Option,
                    }}
                    {...field}
                    //onChange={this.handleChange}
                    allowSelectAll={true}
                    //value={this.state.optionSelected}
                  />
                )}
              />
              {errors.state?.type === "required" && (
                <span style={{ color: "red" }}>state is required</span>
              )}
            </div>
          </Flex>
        ) : (
          <Flex direction="column" width="100%" pl="10px">
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              isRequired={true}
              display="flex"
            >
              {data.label}
              <Text color={brandStars}>*</Text>
            </FormLabel>
            <div style={{ marginBottom: "24px" }}>
              <Controller
                name={`${name}.${data.label}`}
                rules={{ required: data.properties.required }}
                control={control}
                defaultValue={
                  apiData.filter(
                    (formData) => formData.entity_field_id === data.id
                  )[0]?.value
                }
                render={({ field }) => (
                  <Select
                    id="user_type"
                    size="lg"
                    placeholder={`Select ${data.label}`}
                    {...field}
                  >
                    {generateFormOptions(data.values)}
                  </Select>
                )}
              />
              {errors.state?.type === "required" && (
                <span style={{ color: "red" }}>state is required</span>
              )}
            </div>
          </Flex>
        );
      case "date":
        return (
          <Flex direction="column" width="100%" pl="10px">
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              isRequired={true}
              display="flex"
            >
              {data.label}
              <Text color={brandStars}>*</Text>
            </FormLabel>
            <div style={{ marginBottom: "24px" }}>
              <Controller
                name={`${name}.${data.label}`}
                rules={{ required: data.properties.required }}
                defaultValue={getDefaultDate(data.id)}
                control={control}
                render={({ field }) => (
                  <DatePicker
                    wrapperClassName="date-picker"
                    customInput={
                      <InputGroup>
                        <Input
                          placeholder="Select Date"
                          value={
                            field.value ? field.value.toLocaleDateString() : ""
                          }
                        />
                        <InputRightElement children={<CalendarIcon />} />
                      </InputGroup>
                    }
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    minDate={new Date()}
                  />
                )}
              />
              {errors.state?.type === "required" && (
                <span style={{ color: "red" }}>state is required</span>
              )}
            </div>
          </Flex>
        );
      case "radio-group":
        return (
          <Flex direction="column" width="100%" pl="10px">
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              isRequired={true}
              display="flex"
            >
              {data.label}
              <Text color={brandStars}>*</Text>
            </FormLabel>
            <div style={{ marginBottom: "24px" }}>
              <Controller
                name={`${name}.${data.label}`}
                rules={{ required: data.properties.required }}
                defaultValue={
                  apiData.filter(
                    (formData) => formData.entity_field_id === data.id
                  )[0]?.value
                }
                control={control}
                render={({ field }) => (
                  <RadioGroup {...field}>
                    <Stack direction="row">
                      {generateRadioOptions(data.values)}
                    </Stack>
                  </RadioGroup>
                )}
              />
              {errors.state?.type === "required" && (
                <span style={{ color: "red" }}>state is required</span>
              )}
            </div>
          </Flex>
        );
      case "header":
        return (
          <Flex direction="column" width="100%" pl="10px">
            <FormLabel
              ms="4px"
              fontSize="sm"
              fontWeight="500"
              color={textColor}
              isRequired={true}
              display="flex"
            >
              {data.label}
              <Text color={brandStars}>*</Text>
            </FormLabel>
            <div style={{ marginBottom: "24px" }}>
              <Controller
                name={`${name}.${data.label}`}
                rules={{ required: data.properties.required }}
                control={control}
                render={({ field }) => (
                  <Heading as={data.properties.subtype} size="lg">
                    <Input
                      fontSize="sm"
                      type="name"
                      placeholder={`Enter ${data.label}`}
                      //mb="24px"
                      fontWeight="500"
                      size="lg"
                      {...field}
                    />
                  </Heading>
                )}
              />
              {errors.state?.type === "required" && (
                <span style={{ color: "red" }}>state is required</span>
              )}
            </div>
          </Flex>
        );
      default:
        return null;
    }
  };
  return (
    <Box w="100%">
      <FormLabel
        display="flex"
        fontSize="lg"
        fontWeight="bold"
        color={textColor}
        mt="12px"
        mb="16px"
        ml="5px"
      >
        Additional Form
      </FormLabel>
      {savedFormData?.data?.map((data, index) =>
        componentBasedOnFormType(data, `meta[${index}]`)
      )}
    </Box>
  );
};

export default EditDynamicForm;
