import Config from "config";
import axios from "axios";
import React, { useState } from "react";

var token = null;

const API_TIMEOUT = 60 * 1000;

const request = axios.create({
  baseURL: Config.BASE_URL,
  Authorization: token,
});

export default request;
