import { QUERY_KEY, MUTATION_KEY } from "constants/globalConstants";
import ApiService from "apiService";
import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";

const useUpdateNode = (handleModalClose) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.UPDATE_NODE],
    (data) => ApiService.updateNode(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.GET_GROUP);
        toast.success("Node updated successfully");
        handleModalClose();
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

export default useUpdateNode;
