import { QUERY_KEY, MUTATION_KEY } from "constants/globalConstants";
import ApiService from "apiService";
import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";
import { useHistory } from "react-router";

const useAddEntityLayout = () => {
  const queryClient = useQueryClient();
  const history = useHistory();
  return useMutation(
    [MUTATION_KEY.ADD_ENTITY],
    (data) => ApiService.addEntity(data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(QUERY_KEY.GET_ENTITY_LIST);
        history.push(`/admin/layout-planning/${data.data.id}/${data.data.name}`)
        toast.success('Entity added successfully')
      },
      onError: (error) => {
        toast.error(error.response.data.message)
    },
    }
  );
};

export default useAddEntityLayout