import { QUERY_KEY, MUTATION_KEY } from "constants/globalConstants";
import DashboardApiService from "apiService/dashboardApiService";
import { useMutation, useQuery, useQueryClient } from "react-query";
import toast from "react-hot-toast";

// const useSummaryReportDetail = (setSlaPerformanceMetrics, setShowReport) => {
//   const queryClient = useQueryClient();
//   return useMutation(
//     [MUTATION_KEY.BLACKOUT_DETAIL],
//     (data) => DashboardApiService.postBlackoutDetail(data),
//     {
//       onSuccess: (data) => {
//         queryClient.invalidateQueries(data);
//         setSlaPerformanceMetrics(data);
//         setShowReport(true);
//       },
//       onError: (error) => {
//         toast.error(error?.response?.data?.message);
//       },
//     }
//   );
// };

const useSummaryReportDetail = (
  summaryEnabled,
  setSummaryEnabled,
  startDate,
  endDate,
  setSlaPerformanceMetrics,
  setShowReport
) => {
  return useQuery(
    QUERY_KEY.GET_BLACKOUT_REPORT,
    () => DashboardApiService.getSummaryReport(startDate, endDate),
    {
      retry: false,
      enabled: summaryEnabled,
      onSuccess: (res) => {
        setSlaPerformanceMetrics(res);
        setShowReport(true);
        setSummaryEnabled(false);
      },
      onError: (err) => {
        toast.error(err.response.data.message);
        setSummaryEnabled(false);
      },
    }
  );
};

export default useSummaryReportDetail;
