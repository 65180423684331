/* eslint-disable */
import { getDashboardPermissions } from "Permissions";
import { Link, NavLink, useLocation } from "react-router-dom";
// chakra imports
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/accordion";
import {
  Box,
  Flex,
  HStack,
  Icon,
  Text,
  Tooltip,
  useColorModeValue,
} from "@chakra-ui/react";
import { useState } from "react";
import { FaCog } from "react-icons/fa";
import { HiDocumentReport } from "react-icons/hi";
import { useParams } from "react-router";
import "./links.css";

export function SidebarLinks(props) {
  //   Chakra color mode
  let location = useLocation();
  let activeColor = useColorModeValue("gray.700", "white");
  let inactiveColor = useColorModeValue(
    "secondaryGray.600",
    "secondaryGray.600"
  );
  let activeIcon = useColorModeValue("brand.500", "white");
  let textColor = useColorModeValue("secondaryGray.500", "white");
  let brandColor = useColorModeValue("brand.500", "brand.400");
  const [activeLink, setActiveLink] = useState(false);
  const { routes, isExpanded } = props;

  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };
  const reportFilterType = useParams()?.reports;

  const [reportType, setReportType] = useState("");
  const data = [
    { id: 1, value: "Entity", permission: "entity_report.view" },
    {
      id: 2,
      value: "Entity Node Type",
      permission: "entity_report.view",
    },
    {
      id: 3,
      value: "Entity Schedule Downtime",
      permission: "schedule_report.view",
    },
    {
      id: 4,
      value: "Entity Blackout Period",
      permission: "blackout_report.view",
    },
    { id: 5, value: "Entity Node", permission: "entity_report.view" },
    {
      id: 6,
      value: "Node Type",
      permission: "entity_report.view",
    },
    {
      id: 7,
      value: "Node Type Summary",
      permission: "reports.summary_report",
    },
    {
      id: 8,
      value: "Ticket Report",
      permission: "reports.ticket.view",
    },
  ];

  const configurationsData = [
    {
      name: "Customer",
      show: true,
      layout: "/admin",
      //icon: <Icon as={CgProfile} width="20px" height="20px" color="inherit" />,
      path: "customer",
      //component: Client,
      showInSidebar: getDashboardPermissions("customer.view"),
      permission: "customer.view",
      crumbs: [{ name: "Customer", url: "/admin/customer" }],
    },
    {
      name: "Node Type",
      show: true,
      layout: "/admin",
      //icon: <Icon as={MdDevices} width="20px" height="20px" color="inherit" />,
      path: "node-type",
      // component: NodeTypes,
      showInSidebar: getDashboardPermissions("node_type.view"),
      permission: "node_type.view",
      crumbs: [{ name: "Node Type", url: "/admin/node-type" }],
    },
    {
      name: "Node Group Templates",
      show: true,
      layout: "/admin",
      //icon: <Icon as={CgTemplate} width="20px" height="20px" color="inherit" />,
      path: "template",
      //component: Template,
      showInSidebar: getDashboardPermissions("template.view"),
      permission: "template.view",
      crumbs: [{ name: "Node Group Templates", url: "/admin/template" }],
    },
    {
      name: "Entities",
      show: true,
      layout: "/admin",
      // icon: (
      //   <Icon as={RiBuilding3Line} width="20px" height="20px" color="inherit" />
      // ),
      path: "entities/:status",
      //component: EntityDataList,
      showInSidebar: getDashboardPermissions("entity.view"),
      permission: "entity.view",
      crumbs: [{ name: "Entity", url: "/admin/entity" }],
    },
    {
      //name: "Reports",
      name: "Approved Downtimes",
      show: true,
      layout: "/admin",
      // icon: (
      //   <Icon
      //     as={BsChevronDoubleDown}
      //     width="20px"
      //     height="20px"
      //     color="inherit"
      //   />
      // ),
      path: "approved-downtimes",
      //component: ApprovedDowntimes,
      showInSidebar: getDashboardPermissions(
        "node.downtime.view_downtime_breakup"
      ),
      permission: "node.downtime.view_downtime_breakup",
    },
    {
      name: "Settings",
      show: true,
      layout: "/admin",
      //icon: <Icon as={VscUnfold} width="20px" height="20px" color="inherit" />,
      path: "master-configuration",
      //component: MasterConfiguration,
      showInSidebar: true,
      permission: "config.view",
      crumbs: [{ name: "Role", url: "/admin/role" }],
    },
    {
      name: "Settings",
      show: true,
      layout: "/admin",
      //icon: <Icon as={VscUnfold} width="20px" height="20px" color="inherit" />,
      path: "client-configuration",
      //component: ClientConfiguration,
      showInSidebar: true,
      permission: "config_store.view",
      crumbs: [{ name: "Role", url: "/admin/role" }],
    },
    {
      name: "TMS",
      show: true,
      layout: "/admin",
      //icon: <Icon as={VscUnfold} width="20px" height="20px" color="inherit" />,
      path: "tmss-crud",
      //component: ClientConfiguration,
      showInSidebar: true,
      permission: "",
      crumbs: [{ name: "Role", url: "/admin/role" }],
    },
  ];

  const TmsOptions = [
    {
      name: "Ticket Types",
      path: "ticket-crud/ticket-crud-types",
      permission: "ticketing.ticket_type.view",
    },
    {
      name: "Ticket Status",
      path: "ticket-crud/ticket-status",
      permission: "ticketing.ticket_status.view",
    },
    {
      name: "Ticket Reason",
      path: "ticket-crud/ticket-reason",
      permission: "ticketing.status_reason.view",
    },
    {
      name: "Ticket Tabs",
      path: "ticket-crud/ticket-tabs",
      permission: "ticketing.ticket_tab.list_view",
    },
  ];

  const onHandleChange = (value) => {
    setReportType(value);
  };

  const getRoute = (route) => {
    if (route.name === "Ticket Management System") {
      if (getDashboardPermissions("ticketing.dashboard")) {
        return route.layout + "/ticket-management-system";
      } else {
        if (getDashboardPermissions("ticketing.ticket.view")) {
          return route.layout + "/ticket-types";
        }
      }
    } else {
      return route.layout + route.path;
    }
  };

  const getRoutes = (route) => {
    switch (route.name) {
      case "Reports":
        return (
          <>
            {getDashboardPermissions("schedule_report.view") ||
            getDashboardPermissions("blackout_report.view") ||
            getDashboardPermissions("entity_report.view") ? (
              <HStack
                spacing={
                  activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                }
                py="5px"
                ps="10px"
                pr="15px"
                width="100%"
              >
                <Accordion
                  //defaultIndex={[0]}
                  allowMultiple
                  width="100%"
                  borderTop="1px solid transparent"
                  borderBottomWidth="0px !important"
                >
                  <AccordionItem>
                    <AccordionButton
                      display="flex"
                      padding="0px"
                      pb="10px !important"
                      mr="10px"
                      _focus={{ boxShadow: "none" }}
                      _hover={{ bg: "none" }}
                      _expanded={{
                        color: "black !important",
                        boxShadow: "none !important",
                        borderStyle: "none !important",
                      }}
                    >
                      <Box flex="1" textAlign="left" display="flex">
                        {isExpanded ? (
                          <Tooltip hasArrow label="Reports" bg="#2B3674">
                            <Box>
                              <Icon
                                as={HiDocumentReport}
                                width="20px"
                                height="20px"
                                color="#8F9BBA"
                                marginRight="18px !important"
                                marginLeft="-15px !important"
                              />
                            </Box>
                          </Tooltip>
                        ) : (
                          <>
                            <Icon
                              as={HiDocumentReport}
                              width="20px"
                              height="20px"
                              color="#8F9BBA"
                              marginRight="18px !important"
                            />
                            <Text
                              color="#8F9BBA"
                              fontSize={"md"}
                              fontWeight={
                                activeRoute(route.path.toLowerCase())
                                  ? "bold"
                                  : "normal"
                              }
                              margin="0px !important"
                            >
                              Reports
                            </Text>
                          </>
                        )}
                      </Box>
                      <AccordionIcon color="#8F9BBA" />
                    </AccordionButton>
                    {data?.map((list, index) => (
                      <>
                        {getDashboardPermissions(list?.permission) ? (
                          <Link
                            to={`/admin/sla-report/${list?.value
                              .split(" ")
                              .join("")}`}
                          >
                            <AccordionPanel
                              pb={2}
                              onClick={() => onHandleChange(list.value)}
                              bg={
                                reportFilterType ==
                                list?.value.split(" ").join("")
                                  ? "inherit"
                                  : ""
                              }
                              transform={
                                reportFilterType ==
                                list?.value.split(" ").join("")
                                  ? "none"
                                  : ""
                              }
                              borderColor={
                                reportFilterType ==
                                list?.value.split(" ").join("")
                                  ? "transparent"
                                  : ""
                              }
                              color={
                                reportFilterType ==
                                list?.value.split(" ").join("")
                                  ? "#2D3748 !important"
                                  : "#8F9BBA"
                              }
                              fontWeight={
                                reportFilterType ==
                                list?.value.split(" ").join("")
                                  ? "bold !important"
                                  : ""
                              }
                              borderRight={
                                reportFilterType ==
                                list?.value.split(" ").join("")
                                  ? "4px solid #422AFB !important"
                                  : ""
                              }
                              marginRight={
                                reportFilterType ==
                                list?.value.split(" ").join("")
                                  ? "10px !important"
                                  : ""
                              }
                            >
                              {list.value}
                            </AccordionPanel>
                          </Link>
                        ) : null}
                      </>
                    ))}
                  </AccordionItem>
                </Accordion>
              </HStack>
            ) : null}
          </>
        );
      case "Configurations":
        return (
          <>
            {getDashboardPermissions("customer.view") ||
            getDashboardPermissions("node_type.view") ||
            getDashboardPermissions("template.view") ||
            getDashboardPermissions("entity.view") ||
            getDashboardPermissions("node.downtime.view_downtime_breakup") ||
            getDashboardPermissions("config.view") ||
            getDashboardPermissions("config_store.view") ? (
              <HStack
                // spacing={
                //   activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                // }
                ps="10px"
                pb="100px"
                pr="15px"
                width="100%"
              >
                <Accordion
                  defaultIndex={[0]}
                  //allowMultiple
                  allowToggle
                  isExpanded
                  width="100%"
                  borderTop="1px solid transparent"
                  borderBottomWidth="0px !important"
                >
                  <AccordionItem>
                    <AccordionButton
                      display="flex"
                      padding="0px"
                      pb="10px !important"
                      mr="10px"
                      _focus={{ boxShadow: "none" }}
                      _hover={{ bg: "none" }}
                      _expanded={{
                        color: "black !important",
                        boxShadow: "none !important",
                        borderStyle: "none !important",
                      }}
                    >
                      <Box flex="1" textAlign="left" display="flex">
                        {isExpanded ? (
                          <Tooltip hasArrow label="Configurations" bg="#2B3674">
                            <Box>
                              <Icon
                                as={FaCog}
                                width="20px"
                                height="20px"
                                color="#8F9BBA"
                                marginRight="18px !important"
                                marginLeft="-15px !important"
                              />
                            </Box>
                          </Tooltip>
                        ) : (
                          <>
                            <Icon
                              as={FaCog}
                              width="20px"
                              height="20px"
                              color="#8F9BBA"
                              marginRight="18px !important"
                            />
                            <Text
                              color="#8F9BBA"
                              fontSize={"md"}
                              // fontWeight={
                              //   activeRoute(route.path.toLowerCase())
                              //     ? "bold"
                              //     : "normal"
                              // }
                              margin="0px !important"
                              fontWeight="400"
                            >
                              Configurations
                            </Text>
                          </>
                        )}
                      </Box>
                      <AccordionIcon color="#8F9BBA" />
                    </AccordionButton>
                    {configurationsData?.map((list, index) => (
                      <>
                        {getDashboardPermissions(list?.permission) ? (
                          <>
                            <AccordionPanel
                              pb={2}
                              //onClick={() => onHandleChange(list.path)}
                              bg={
                                activeRoute(list?.path?.toLowerCase())
                                  ? "inherit"
                                  : ""
                              }
                              transform={
                                activeRoute(list?.path.toLowerCase())
                                  ? "none"
                                  : ""
                              }
                              borderColor={
                                activeRoute(list?.path?.toLowerCase())
                                  ? "transparent"
                                  : ""
                              }
                              color={
                                activeRoute(list?.path?.toLowerCase())
                                  ? "#2D3748 !important"
                                  : "#8F9BBA"
                              }
                              fontWeight={
                                activeRoute(list?.path?.toLowerCase())
                                  ? "bold !important"
                                  : ""
                              }
                              borderRight={
                                activeRoute(list?.path?.toLowerCase())
                                  ? "4px solid #422AFB !important"
                                  : ""
                              }
                              marginRight={
                                activeRoute(list?.path?.toLowerCase())
                                  ? "10px !important"
                                  : ""
                              }
                            >
                              {list.name === "TMS" ? (
                                <>
                                  {getDashboardPermissions(
                                    "ticketing.ticket_type.view"
                                  ) ||
                                  getDashboardPermissions(
                                    "ticketing.status_reason.view"
                                  ) ||
                                  getDashboardPermissions(
                                    "ticketing.ticket_status.view"
                                  ) ||
                                  getDashboardPermissions(
                                    "ticketing.ticket_tab.list_view"
                                  ) ? (
                                    <Accordion
                                      defaultIndex={[0]}
                                      isExpanded
                                      allowToggle
                                      width="100%"
                                      borderTop="1px solid transparent"
                                      borderBottomWidth="0px !important"
                                    >
                                      <AccordionItem>
                                        <AccordionButton
                                          display="flex"
                                          padding="0px"
                                          pb="10px !important"
                                          mr="10px"
                                          _focus={{ boxShadow: "none" }}
                                          _hover={{ bg: "none" }}
                                          _expanded={{
                                            color: "black !important",
                                            boxShadow: "none !important",
                                            borderStyle: "none !important",
                                          }}
                                        >
                                          <Box
                                            flex="1"
                                            textAlign="left"
                                            display="flex"
                                          >
                                            <>
                                              <Text
                                                color="#8F9BBA"
                                                fontSize={"md"}
                                                margin="0px !important"
                                                fontWeight="400"
                                              >
                                                {localStorage.getItem(
                                                  "tms_text"
                                                ) || "TMS"}
                                              </Text>
                                            </>
                                          </Box>
                                          <AccordionIcon color="#8F9BBA" />
                                        </AccordionButton>
                                        {TmsOptions?.map((list, index) => (
                                          <>
                                            {getDashboardPermissions(
                                              list?.permission
                                            ) ? (
                                              <Link
                                                to={`/admin/${list?.path
                                                  .split(" ")
                                                  .join("")}`}
                                              >
                                                <AccordionPanel
                                                  pb={2}
                                                  // onClick={() =>
                                                  //   alert(
                                                  //     list?.path
                                                  //       .split(" ")
                                                  //       .join("")
                                                  //   )
                                                  // }
                                                  bg={
                                                    activeRoute(
                                                      list?.path?.toLowerCase()
                                                    )
                                                      ? "inherit"
                                                      : ""
                                                  }
                                                  transform={
                                                    activeRoute(
                                                      list?.path?.toLowerCase()
                                                    )
                                                      ? "none"
                                                      : ""
                                                  }
                                                  borderColor={
                                                    activeRoute(
                                                      list?.path?.toLowerCase()
                                                    )
                                                      ? "transparent"
                                                      : ""
                                                  }
                                                  color={
                                                    activeRoute(
                                                      list?.path?.toLowerCase()
                                                    )
                                                      ? "#2D3748 !important"
                                                      : "#8F9BBA"
                                                  }
                                                  fontWeight={
                                                    activeRoute(
                                                      list?.path?.toLowerCase()
                                                    )
                                                      ? "bold !important"
                                                      : ""
                                                  }
                                                  borderRight={
                                                    activeRoute(
                                                      list?.path?.toLowerCase()
                                                    )
                                                      ? "4px solid #422AFB !important"
                                                      : ""
                                                  }
                                                  marginRight={
                                                    activeRoute(
                                                      list?.path?.toLowerCase()
                                                    )
                                                      ? "10px !important"
                                                      : ""
                                                  }
                                                >
                                                  {list.name}
                                                </AccordionPanel>
                                              </Link>
                                            ) : null}
                                          </>
                                        ))}
                                      </AccordionItem>
                                    </Accordion>
                                  ) : null}
                                </>
                              ) : (
                                <Link to={`/admin/${list?.path}`}>
                                  {list.name}
                                </Link>
                              )}
                            </AccordionPanel>
                          </>
                        ) : null}
                      </>
                    ))}
                  </AccordionItem>
                </Accordion>
              </HStack>
            ) : null}
          </>
        );
      default:
        return (
          <NavLink to={getRoute(route)}>
            <HStack
              spacing={activeRoute(route.path.toLowerCase()) ? "22px" : "26px"}
              py="5px"
              ps="10px"
            >
              <Flex w="100%" alignItems="center" justifyContent="center">
                {isExpanded ? (
                  <Tooltip hasArrow label={route.name} bg="#2B3674">
                    <Box
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeIcon
                          : textColor
                      }
                      me="18px"
                    >
                      {route.icon}
                    </Box>
                  </Tooltip>
                ) : (
                  <>
                    <Box
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeIcon
                          : textColor
                      }
                      me="18px"
                    >
                      {route.icon}
                    </Box>
                    <Text
                      me="auto"
                      color={
                        activeRoute(route.path.toLowerCase())
                          ? activeColor
                          : textColor
                      }
                      fontWeight={
                        activeRoute(route.path.toLowerCase())
                          ? "bold"
                          : "normal"
                      }
                    >
                      {route.name.toLowerCase() === "ticket management system"
                        ? localStorage.getItem("tms_text")
                        : route.name}
                    </Text>
                  </>
                )}
              </Flex>
              <Box
                h="36px"
                w="4px"
                bg={
                  activeRoute(route.path.toLowerCase())
                    ? brandColor
                    : "transparent"
                }
                borderRadius="5px"
              />
            </HStack>
          </NavLink>
        );
    }
  };

  const checkForRoutes = (name) => {
    if (
      name === "Customer" ||
      name === "Node Type" ||
      name === "Node Group Templates" ||
      name === "Entities" ||
      name === "Approved Downtimes" ||
      name === "Settings"
    ) {
      return false;
    } else {
      return true;
    }
  };

  // this function creates the links from the secondary accordions (for example auth -> sign-in -> default)
  const createLinks = (routes) => {
    return routes.map((route, index) => {
      if (route.category) {
        return (
          <>
            <Text
              fontSize={"md"}
              color={activeColor}
              fontWeight="bold"
              mx="auto"
              ps={{
                sm: "10px",
                xl: "16px",
              }}
              pt="18px"
              pb="12px"
              key={index}
            >
              {route.name}
            </Text>
            {createLinks(route.items)}
          </>
        );
      } else if (
        route.layout === "/admin" ||
        route.layout === "/auth" ||
        route.layout === "/rtl"
      ) {
        return (
          <>
            {getDashboardPermissions(route.permission) &&
            checkForRoutes(route.name) ? (
              <Box>
                {getRoutes(route)}
                {/* {route.name != "Reports" ? (
                  <NavLink to={getRoute(route)}>
                    <HStack
                      spacing={
                        activeRoute(route.path.toLowerCase()) ? "22px" : "26px"
                      }
                      py="5px"
                      ps="10px"
                    >
                      <Flex
                        w="100%"
                        alignItems="center"
                        justifyContent="center"
                      >
                        {isExpanded ? (
                          <Tooltip hasArrow label={route.name} bg="#2B3674">
                            <Box
                              color={
                                activeRoute(route.path.toLowerCase())
                                  ? activeIcon
                                  : textColor
                              }
                              me="18px"
                            >
                              {route.icon}
                            </Box>
                          </Tooltip>
                        ) : (
                          <>
                            <Box
                              color={
                                activeRoute(route.path.toLowerCase())
                                  ? activeIcon
                                  : textColor
                              }
                              me="18px"
                            >
                              {route.icon}
                            </Box>
                            <Text
                              me="auto"
                              color={
                                activeRoute(route.path.toLowerCase())
                                  ? activeColor
                                  : textColor
                              }
                              fontWeight={
                                activeRoute(route.path.toLowerCase())
                                  ? "bold"
                                  : "normal"
                              }
                            >
                              {route.name}
                            </Text>
                          </>
                        )}
                      </Flex>
                      <Box
                        h="36px"
                        w="4px"
                        bg={
                          activeRoute(route.path.toLowerCase())
                            ? brandColor
                            : "transparent"
                        }
                        borderRadius="5px"
                      />
                    </HStack>
                  </NavLink>
                ) : (
                  <>
                    {getDashboardPermissions("sla_report.view") ? (
                      <HStack
                        spacing={
                          activeRoute(route.path.toLowerCase())
                            ? "22px"
                            : "26px"
                        }
                        py="5px"
                        pb="100px"
                        ps="10px"
                        pr="15px"
                        width="100%"
                      >
                        <Accordion
                          //defaultIndex={[0]}
                          allowMultiple
                          width="100%"
                          borderTop="1px solid transparent"
                          borderBottomWidth="0px !important"
                        >
                          <AccordionItem>
                            <AccordionButton
                              display="flex"
                              padding="0px"
                              pb="10px !important"
                              mr="10px"
                              _focus={{ boxShadow: "none" }}
                              _hover={{ bg: "none" }}
                              _expanded={{
                                color: "black !important",
                                boxShadow: "none !important",
                                borderStyle: "none !important",
                              }}
                            >
                              <Box flex="1" textAlign="left" display="flex">
                                {isExpanded ? (
                                  <Tooltip
                                    hasArrow
                                    label="Reports"
                                    bg="#2B3674"
                                  >
                                    <Box>
                                      <Icon
                                        as={HiDocumentReport}
                                        width="20px"
                                        height="20px"
                                        color="#8F9BBA"
                                        marginRight="18px !important"
                                        marginLeft="-15px !important"
                                      />
                                    </Box>
                                  </Tooltip>
                                ) : (
                                  <>
                                    <Icon
                                      as={HiDocumentReport}
                                      width="20px"
                                      height="20px"
                                      color="#8F9BBA"
                                      marginRight="18px !important"
                                    />
                                    <Text
                                      color="#8F9BBA"
                                      fontSize={"md"}
                                      fontWeight={
                                        activeRoute(route.path.toLowerCase())
                                          ? "bold"
                                          : "normal"
                                      }
                                      margin="0px !important"
                                      fontWeight="400"
                                    >
                                      Reports
                                    </Text>
                                  </>
                                )}
                              </Box>
                              <AccordionIcon color="#8F9BBA" />
                            </AccordionButton>
                            {data?.map((list, index) => (
                              <>
                                {getDashboardPermissions(list?.permission) ? (
                                  <Link
                                    to={`/admin/sla-report/${list?.value
                                      .split(" ")
                                      .join("")}`}
                                  >
                                    <AccordionPanel
                                      pb={2}
                                      onClick={() => onHandleChange(list.value)}
                                      bg={
                                        reportFilterType ==
                                        list?.value.split(" ").join("")
                                          ? "inherit"
                                          : ""
                                      }
                                      transform={
                                        reportFilterType ==
                                        list?.value.split(" ").join("")
                                          ? "none"
                                          : ""
                                      }
                                      borderColor={
                                        reportFilterType ==
                                        list?.value.split(" ").join("")
                                          ? "transparent"
                                          : ""
                                      }
                                      color={
                                        reportFilterType ==
                                        list?.value.split(" ").join("")
                                          ? "#2D3748 !important"
                                          : "#8F9BBA"
                                      }
                                      fontWeight={
                                        reportFilterType ==
                                        list?.value.split(" ").join("")
                                          ? "bold !important"
                                          : ""
                                      }
                                      borderRight={
                                        reportFilterType ==
                                        list?.value.split(" ").join("")
                                          ? "4px solid #422AFB !important"
                                          : ""
                                      }
                                      marginRight={
                                        reportFilterType ==
                                        list?.value.split(" ").join("")
                                          ? "10px !important"
                                          : ""
                                      }
                                    >
                                      {list.value}
                                    </AccordionPanel>
                                  </Link>
                                ) : null}
                              </>
                            ))}
                          </AccordionItem>
                        </Accordion>
                      </HStack>
                    ) : null}
                  </>
                )} */}
              </Box>
            ) : null}
          </>
        );
      }
    });
  };
  //  BRAND
  return createLinks(routes);
}

export default SidebarLinks;
