import React, { useState, useEffect } from "react";
import Card from "components/card/Card.js";
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import useUpdateRole from "hooks/roles/useUpdateRole";
import MultiSelectPermissionBox from "./multiSelectPermissionBox";

function EditRoleForm({ setShowEditForm, editData, permissionsData }) {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  let activeColor = useColorModeValue("gray.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const buttonHover = useColorModeValue("brand.600", "brand.400");
  const { mutate: updateRole, isLoading } = useUpdateRole(setShowEditForm);
  const [selectedPermissions, setSelectedPermissions] = useState([]);

  useEffect(() => {
    setSelectedPermissions(editData?.permissions);
  }, [editData]);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    updateRole({
      role_type: data.role_type,
      slug: data.slug,
      id: editData.id,
      permissions: selectedPermissions,
    });
  };

  const formatData = () => {
    let myArray = [];
    for (let i = 0; i < permissionsData?.length; i++) {
      myArray = myArray.concat(permissionsData[i].permissions);
    }
    return myArray;
  };

  const IdData = formatData();

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card>
        <Flex
          maxW={{ base: "100%", md: "max-content" }}
          w="100%"
          mx={{ base: "auto", lg: "0px" }}
          me="auto"
          h="100%"
          alignItems="start"
          justifyContent="center"
          mb={{ base: "30px", md: "30px" }}
          px={{ base: "25px", md: "0px" }}
          mt={{ base: "20px", md: "20px" }}
          flexDirection="column"
        >
          <Box me="auto">
            <Text
              mb="24px"
              ms="4px"
              color={activeColor}
              fontWeight="bold"
              fontSize="24px"
            >
              Edit Role
            </Text>
          </Box>
          <Flex
            zIndex="2"
            direction="column"
            w={{ base: "100%", md: "420px" }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: "auto", lg: "unset" }}
            me="auto"
            mb={{ base: "20px", md: "auto" }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <Flex direction="column" width="50%">
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Permissions<Text color={brandStars}>*</Text>
                </FormLabel>
                <Box mb="20px" width="420px">
                  <MultiSelectPermissionBox
                    IdData={IdData}
                    groupData={permissionsData}
                    selectedPermissions={selectedPermissions}
                    setSelectedPermissions={setSelectedPermissions}
                  />
                </Box>
              </Flex>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Role<Text color={brandStars}>*</Text>
              </FormLabel>
              <div style={{ marginBottom: "24px" }}>
                <Controller
                  name="role_type"
                  rules={{
                    required: true,
                    validate: (value) => {
                      return !!value.trim();
                    },
                  }}
                  control={control}
                  defaultValue={editData?.role_type}
                  render={({ field }) => (
                    <Input
                      fontSize="sm"
                      ms={{ base: "0px", md: "0px" }}
                      type="name"
                      placeholder="Enter Roll"
                      //mb="24px"
                      fontWeight="500"
                      size="lg"
                      {...field}
                    />
                  )}
                />
                {errors.role_type && (
                  <span style={{ color: "red" }}>role is required</span>
                )}
              </div>

              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Slug<Text color={brandStars}>*</Text>
              </FormLabel>
              <div style={{ marginBottom: "24px" }}>
                <Controller
                  name="slug"
                  rules={{
                    required: true,
                    validate: (value) => {
                      return !!value.trim();
                    },
                  }}
                  control={control}
                  defaultValue={editData?.slug}
                  render={({ field }) => (
                    <Input
                      fontSize="sm"
                      ms={{ base: "0px", md: "0px" }}
                      type="name"
                      placeholder="Enter Slug"
                      //mb="24px"
                      fontWeight="500"
                      size="lg"
                      {...field}
                    />
                  )}
                />
                {errors.slug && (
                  <span style={{ color: "red" }}>slug is required</span>
                )}
              </div>

              <Flex>
                <Button
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  w="90%"
                  h="50"
                  mb="24px"
                  mr="20px"
                  type="submit"
                  _hover={{ bg: isLoading ? "blue" : buttonHover }}
                  isLoading={isLoading}
                >
                  Submit
                </Button>
                <Button
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  w="90%"
                  h="50"
                  mb="24px"
                  onClick={() => setShowEditForm(false)}
                >
                  Cancel
                </Button>
              </Flex>
            </form>
          </Flex>
        </Flex>
      </Card>
    </Box>
  );
}

export default EditRoleForm;
