export const WEEK_API_DATA = {
  status: "SUCCESS",
  data: [
    {
      id: 25,
      type: "weekly",
      status: true,
      starts_at: "15:01:00",
      ends_at: "15:03:00",
      repeat_till: "None",
      last_occured_at: "2022-09-03 15:03:00",
      next_occurance_start: "2022-09-02 15:01:00",
      next_occurance_end: "2022-09-02 15:03:00",
      days: [1, 5, 6],
      node_type: [],
      nodes: [
        {
          id: 1,
          node_type_name: 'laptop',
          name: "laptop1",
        },
      ],
    },
    {
      id: 26,
      type: "weekly",
      status: true,
      starts_at: "15:01:00",
      ends_at: "15:03:00",
      repeat_till: "None",
      last_occured_at: "2022-09-03 15:03:00",
      next_occurance_start: "2022-09-03 15:01:00",
      next_occurance_end: "2022-09-03 15:03:00",
      days: [1, 6],
      node_type: [],
      nodes: [
        {
          id: 1,
          name: "laptop1",
        },
      ],
    },
    {
      id: 27,
      type: "weekly",
      status: true,
      starts_at: "15:01:00",
      ends_at: "15:03:00",
      repeat_till: "None",
      last_occured_at: "2022-08-29 15:03:00",
      next_occurance_start: "2022-09-05 15:01:00",
      next_occurance_end: "2022-09-05 15:03:00",
      days: [1],
      node_type: [],
      nodes: [
        {
          id: 1,
          name: "laptop1",
        },
      ],
    },
    {
      id: 28,
      type: "weekly",
      status: true,
      starts_at: "15:01:00",
      ends_at: "15:03:00",
      repeat_till: "None",
      last_occured_at: "2022-08-29 15:03:00",
      next_occurance_start: "2022-09-02 15:01:00",
      next_occurance_end: "2022-09-02 15:03:00",
      days: [5, 6],
      node_type: [],
      nodes: [
        {
          id: 1,
          name: "laptop1",
        },
      ],
    },
    {
      id: 29,
      type: "weekly",
      status: true,
      starts_at: "15:01:00",
      ends_at: "15:03:00",
      repeat_till: "None",
      last_occured_at: "2022-08-29 15:03:00",
      next_occurance_start: "2022-09-03 15:01:00",
      next_occurance_end: "2022-09-03 15:03:00",
      days: [6],
      node_type: [],
      nodes: [
        {
          id: 1,
          name: "laptop1",
        },
      ],
    },
    {
      id: 37,
      type: "weekly",
      status: true,
      starts_at: "15:01:00",
      ends_at: "15:03:00",
      repeat_till: "None",
      last_occured_at: "2022-08-31 15:03:00",
      next_occurance_start: "2022-08-31 15:01:00",
      next_occurance_end: "2022-08-31 15:03:00",
      days: [2, 3],
      node_type: [],
      nodes: [
        {
          id: 1,
          name: "laptop1",
        },
      ],
    },
  ],
  message: "",
};

export const YEAR_API_DATA = {
  status: "SUCCESS",
  data: [
    {
      id: 18,
      entity_id: 1,
      repeat_till: null,
      starts_at: "11:55:00",
      ends_at: "11:59:00",
      days: [28, 29, 30, 31, 32],
      last_occured_at: "2022-02-01 11:59:00",
      next_occurance_at: "None",
      node_type: [],
      nodes: [
        {
          id: 1,
          name: "laptop1",
          node_type_id: 1,
          ip_address: "192.168.5.4",
          device_id: "LAP54",
          entity_id: 1,
          order: 1,
          slug: "l__p1",
          group_id: 1,
        },
      ],
    },
    {
      id: 20,
      entity_id: 1,
      repeat_till: null,
      starts_at: "15:01:00",
      ends_at: "15:03:00",
      days: [24, 31],
      last_occured_at: "2022-01-31 15:03:00",
      next_occurance_at: "None",
      node_type: [],
      nodes: [
        {
          id: 1,
          name: "laptop1",
          node_type_id: 1,
          ip_address: "192.168.5.4",
          device_id: "LAP54",
          entity_id: 1,
          order: 1,
          slug: "l__p1",
          group_id: 1,
        },
      ],
    },
    {
      id: 21,
      entity_id: 1,
      repeat_till: null,
      starts_at: "15:01:00",
      ends_at: "15:03:00",
      days: [300, 311],
      last_occured_at: "2024-07-06 15:03:00",
      next_occurance_at: "None",
      node_type: [],
      nodes: [
        {
          id: 1,
          name: "laptop1",
          node_type_name: "laptop",
          node_type_id: 1,
          ip_address: "192.168.5.4",
          device_id: "LAP54",
          entity_id: 1,
          order: 1,
          slug: "l__p1",
          group_id: 1,
        },
      ],
    },
    {
      id: 30,
      entity_id: 1,
      repeat_till: null,
      starts_at: "15:01:00",
      ends_at: "15:03:00",
      days: [6, 10],
      last_occured_at: "2022-01-10 15:03:00",
      next_occurance_at: "2023-01-06 15:01:00",
      node_type: [],
      nodes: [
        {
          id: 1,
          name: "laptop1",
          node_type_id: 1,
          ip_address: "192.168.5.4",
          device_id: "LAP54",
          entity_id: 1,
          order: 1,
          slug: "l__p1",
          group_id: 1,
        },
      ],
    },
    {
      id: 31,
      entity_id: 1,
      repeat_till: null,
      starts_at: "15:01:00",
      ends_at: "15:03:00",
      days: [300],
      last_occured_at: "None",
      next_occurance_at: "2022-10-27 15:01:00",
      node_type: [
        {
          id: 1,
          name: "laptop",
          slug: "l__p",
          nodes: [
            {
              id: 4,
              name: "laptop4",
              node_type_id: 1,
              ip_address: "192.168.5.7",
              device_id: "",
              entity_id: 1,
              order: 1,
              slug: "abc",
              group_id: 1,
            },
            {
              id: 5,
              name: "camera node",
              node_type_id: 1,
              ip_address: "192.11.18.16",
              device_id: "1",
              entity_id: 1,
              order: 1,
              slug: "c__e",
              group_id: 1,
            },
            {
              id: 1,
              name: "laptop1",
              node_type_id: 1,
              ip_address: "192.168.5.4",
              device_id: "LAP54",
              entity_id: 1,
              order: 1,
              slug: "l__p1",
              group_id: 1,
            },
            {
              id: 2,
              name: "laptop2",
              node_type_id: 1,
              ip_address: "192.168.5.5",
              device_id: "LAP55",
              entity_id: 1,
              order: 1,
              slug: "l__p1",
              group_id: 1,
            },
          ],
        },
      ],
      nodes: [
        {
          id: 1,
          name: "laptop1",
          node_type_id: 1,
          ip_address: "192.168.5.4",
          device_id: "LAP54",
          entity_id: 1,
          order: 1,
          slug: "l__p1",
          group_id: 1,
          node_type_name: "laptop",
        },
      ],
    },
    {
      id: 32,
      entity_id: 1,
      repeat_till: null,
      starts_at: "15:01:00",
      ends_at: "15:03:00",
      days: [300],
      last_occured_at: "None",
      next_occurance_at: "2022-10-27 15:01:00",
      node_type: [],
      nodes: [
        {
          id: 1,
          name: "laptop1",
          node_type_id: 1,
          ip_address: "192.168.5.4",
          device_id: "LAP54",
          entity_id: 1,
          order: 1,
          slug: "l__p1",
          group_id: 1,
        },
      ],
    },
    {
      id: 33,
      entity_id: 1,
      repeat_till: null,
      starts_at: "15:01:00",
      ends_at: "15:03:00",
      days: [300],
      last_occured_at: "None",
      next_occurance_at: "2022-10-27 15:01:00",
      node_type: [],
      nodes: [
        {
          id: 1,
          name: "laptop1",
          node_type_id: 1,
          ip_address: "192.168.5.4",
          device_id: "LAP54",
          entity_id: 1,
          order: 1,
          slug: "l__p1",
          group_id: 1,
        },
      ],
    },
    {
      id: 34,
      entity_id: 1,
      repeat_till: null,
      starts_at: "15:01:00",
      ends_at: "15:03:00",
      days: [300],
      last_occured_at: "None",
      next_occurance_at: "2022-10-27 15:01:00",
      node_type: [],
      nodes: [
        {
          id: 1,
          name: "laptop1",
          node_type_id: 1,
          ip_address: "192.168.5.4",
          device_id: "LAP54",
          entity_id: 1,
          order: 1,
          slug: "l__p1",
          group_id: 1,
        },
      ],
    },
    {
      id: 35,
      entity_id: 1,
      repeat_till: null,
      starts_at: "15:01:00",
      ends_at: "15:03:00",
      days: [300],
      last_occured_at: "2022-06-26 15:03:00",
      next_occurance_at: "2022-10-27 15:01:00",
      node_type: [],
      nodes: [
        {
          id: 1,
          name: "laptop1",
          node_type_id: 1,
          ip_address: "192.168.5.4",
          device_id: "LAP54",
          entity_id: 1,
          order: 1,
          slug: "l__p1",
          group_id: 1,
        },
      ],
    },
    {
      id: 38,
      entity_id: 1,
      repeat_till: null,
      starts_at: "15:01:00",
      ends_at: "15:03:00",
      days: [300],
      last_occured_at: "2022-06-26 15:03:00",
      next_occurance_at: "2022-10-27 15:01:00",
      node_type: [],
      nodes: [
        {
          id: 1,
          name: "laptop1",
          node_type_id: 1,
          ip_address: "192.168.5.4",
          device_id: "LAP54",
          entity_id: 1,
          order: 1,
          slug: "l__p1",
          group_id: 1,
        },
      ],
    },
    {
      id: 39,
      entity_id: 1,
      repeat_till: null,
      starts_at: "15:01:00",
      ends_at: "15:03:00",
      days: [300],
      last_occured_at: "2024-06-25 15:03:00",
      next_occurance_at: "2022-10-27 15:01:00",
      node_type: [],
      nodes: [
        {
          id: 1,
          name: "laptop1",
          node_type_id: 1,
          ip_address: "192.168.5.4",
          device_id: "LAP54",
          entity_id: 1,
          order: 1,
          slug: "l__p1",
          group_id: 1,
        },
      ],
    },
    {
      id: 40,
      entity_id: 1,
      repeat_till: null,
      starts_at: "15:01:00",
      ends_at: "15:03:00",
      days: [300],
      last_occured_at: "2024-06-25 15:03:00",
      next_occurance_at: "2022-10-27 15:01:00",
      node_type: [],
      nodes: [
        {
          id: 1,
          name: "laptop1",
          node_type_id: 1,
          ip_address: "192.168.5.4",
          device_id: "LAP54",
          entity_id: 1,
          order: 1,
          slug: "l__p1",
          group_id: 1,
        },
      ],
    },
    {
      id: 41,
      entity_id: 1,
      repeat_till: null,
      starts_at: "15:01:00",
      ends_at: "15:03:00",
      days: [300],
      last_occured_at: "2022-06-26 15:03:00",
      next_occurance_at: "2022-10-27 15:01:00",
      node_type: [],
      nodes: [
        {
          id: 1,
          name: "laptop1",
          node_type_id: 1,
          ip_address: "192.168.5.4",
          device_id: "LAP54",
          entity_id: 1,
          order: 1,
          slug: "l__p1",
          group_id: 1,
        },
      ],
    },
    {
      id: 90,
      entity_id: 1,
      repeat_till: null,
      starts_at: "16:23:00",
      ends_at: "16:25:00",
      type:'yearly',
      days: [1, 3, 5, 7, 300],
      last_occured_at: "2022-10-27 16:25:00",
      next_occurance_at: "2022-10-27 16:23:00",
      node_type: [
        {
          id: 1,
          name: "laptop",
          slug: "l__p",
          nodes: [
            {
              id: 4,
              name: "laptop4",
              node_type_id: 1,
              ip_address: "192.168.5.7",
              device_id: "",
              entity_id: 1,
              order: 1,
              slug: "abc",
              group_id: 1,
            },
            {
              id: 5,
              name: "camera node",
              node_type_id: 1,
              ip_address: "192.11.18.16",
              device_id: "1",
              entity_id: 1,
              order: 1,
              slug: "c__e",
              group_id: 1,
            },
            {
              id: 1,
              name: "laptop1",
              node_type_id: 1,
              ip_address: "192.168.5.4",
              device_id: "LAP54",
              entity_id: 1,
              order: 1,
              slug: "l__p1",
              group_id: 1,
            },
            {
              id: 2,
              name: "laptop2",
              node_type_id: 1,
              ip_address: "192.168.5.5",
              device_id: "LAP55",
              entity_id: 1,
              order: 1,
              slug: "l__p1",
              group_id: 1,
            },
          ],
        },
      ],
      nodes: [
        {
          id: 1,
          name: "laptop1",
          node_type_id: 1,
          ip_address: "192.168.5.4",
          device_id: "LAP54",
          entity_id: 1,
          order: 1,
          slug: "l__p1",
          group_id: 1,
          node_type_name: "laptop",
        },
        {
          id: 2,
          name: "laptop2",
          node_type_id: 1,
          ip_address: "192.168.5.4",
          device_id: "LAP54",
          entity_id: 1,
          order: 1,
          slug: "l__p1",
          group_id: 1,
          node_type_name: "laptop",
        },
        {
          id: 3,
          name: "deaktop1",
          node_type_id: 1,
          ip_address: "192.168.5.4",
          device_id: "LAP54",
          entity_id: 1,
          order: 1,
          slug: "l__p1",
          group_id: 1,
          node_type_name: "desktop",
        },
      ],
    },
  ],
  message: "",
};

export const MONTH_API_Data = {
  status: "SUCCESS",
  data: [
    {
      id: 13,
      entity_id: 1,
      repeat_till: null,
      starts_at: "10:15:00",
      ends_at: "10:20:00",
      days: [29],
      last_occured_at: "2022-08-29 10:20:00",
      next_occurance_at: "None",
      node_type: [],
      nodes: [
        {
          id: 1,
          name: "laptop1",
          node_type_id: 1,
          ip_address: "192.168.5.4",
          device_id: "LAP54",
          node_type_name:'laptop',
          entity_id: 1,
          order: 1,
          slug: "l__p1",
          group_id: 1,
        },
      ],
    },
    {
      id: 14,
      entity_id: 1,
      repeat_till: null,
      starts_at: "10:15:00",
      ends_at: "10:20:00",
      days: [29],
      last_occured_at: "2022-08-29 10:20:00",
      next_occurance_at: "2022-09-29 10:20:00",
      node_type: [],
      nodes: [
        {
          id: 1,
          name: "laptop1",
          node_type_id: 1,
          ip_address: "192.168.5.4",
          device_id: "LAP54",
          entity_id: 1,
          order: 1,
          slug: "l__p1",
          group_id: 1,
        },
      ],
    },
    {
      id: 17,
      entity_id: 1,
      repeat_till: null,
      starts_at: "14:24:00",
      ends_at: "14:26:00",
      days: [29, 30],
      last_occured_at: "2022-08-28 14:26:00",
      next_occurance_at: "2022-08-30 14:24:00",
      node_type: [],
      nodes: [
        {
          id: 1,
          name: "laptop1",
          node_type_id: 1,
          ip_address: "192.168.5.4",
          device_id: "LAP54",
          entity_id: 1,
          order: 1,
          slug: "l__p1",
          group_id: 1,
        },
      ],
    },
    {
      id: 19,
      entity_id: 1,
      repeat_till: null,
      starts_at: "15:01:00",
      ends_at: "15:03:00",
      days: [24, 31],
      last_occured_at: "2022-08-31 15:03:00",
      next_occurance_at: "None",
      node_type: [],
      nodes: [
        {
          id: 1,
          name: "laptop1",
          node_type_id: 1,
          node_type_name:'laptop',
          ip_address: "192.168.5.4",
          device_id: "LAP54",
          entity_id: 1,
          order: 1,
          slug: "l__p1",
          group_id: 1,
        },
      ],
    },
    {
      id: 23,
      entity_id: 1,
      repeat_till: null,
      type: 'monthly',
      starts_at: "15:01:00",
      ends_at: "15:03:00",
      days: [1, 2, 3],
      last_occured_at: "2022-08-03 15:03:00",
      next_occurance_at: "2022-09-01 15:01:00",
      node_type: [],
      nodes: [
        {
          id: 1,
          name: "laptop1",
          node_type_id: 1,
          node_type_name:'laptop',
          ip_address: "192.168.5.4",
          device_id: "LAP54",
          entity_id: 1,
          order: 1,
          slug: "l__p1",
          group_id: 1,
        },
      ],
    },
    {
      id: 24,
      entity_id: 1,
      repeat_till: null,
      starts_at: "15:01:00",
      ends_at: "15:03:00",
      days: [31],
      last_occured_at: "None",
      next_occurance_at: "2022-08-31 15:01:00",
      node_type: [],
      nodes: [
        {
          id: 1,
          name: "laptop1",
          node_type_id: 1,
          ip_address: "192.168.5.4",
          device_id: "LAP54",
          entity_id: 1,
          order: 1,
          slug: "l__p1",
          group_id: 1,
        },
      ],
    },
    {
      id: 36,
      entity_id: 1,
      repeat_till: null,
      starts_at: "15:01:00",
      ends_at: "15:03:00",
      days: [30],
      last_occured_at: "2022-08-30 15:03:00",
      next_occurance_at: "2022-09-30 15:01:00",
      node_type: [],
      nodes: [
        {
          id: 1,
          name: "laptop1",
          node_type_id: 1,
          ip_address: "192.168.5.4",
          device_id: "LAP54",
          entity_id: 1,
          order: 1,
          slug: "l__p1",
          group_id: 1,
        },
      ],
    },
    {
      id: 42,
      entity_id: 1,
      repeat_till: null,
      starts_at: "15:01:00",
      ends_at: "15:03:00",
      days: [1, 2, 31],
      last_occured_at: "2022-09-01 15:03:00",
      next_occurance_at: "2022-09-02 15:01:00",
      node_type: [],
      nodes: [
        {
          id: 1,
          name: "laptop1",
          node_type_id: 1,
          ip_address: "192.168.5.4",
          device_id: "LAP54",
          entity_id: 1,
          order: 1,
          slug: "l__p1",
          group_id: 1,
        },
      ],
    },
    {
      id: 43,
      entity_id: 1,
      repeat_till: null,
      starts_at: "18:00:34",
      ends_at: "18:00:36",
      days: [1, 2, 20],
      last_occured_at: "2022-09-20 18:00:36",
      next_occurance_at: "2022-09-02 18:00:34",
      node_type: [],
      nodes: [
        {
          id: 1,
          name: "laptop1",
          node_type_id: 1,
          ip_address: "192.168.5.4",
          device_id: "LAP54",
          entity_id: 1,
          order: 1,
          slug: "l__p1",
          group_id: 1,
        },
      ],
    },
    {
      id: 44,
      entity_id: 1,
      repeat_till: null,
      starts_at: "18:00:40",
      ends_at: "18:00:43",
      days: [1, 2, 20],
      last_occured_at: "2022-09-20 18:00:43",
      next_occurance_at: "2022-09-02 18:00:40",
      node_type: [],
      nodes: [
        {
          id: 1,
          name: "laptop1",
          node_type_id: 1,
          ip_address: "192.168.5.4",
          device_id: "LAP54",
          entity_id: 1,
          order: 1,
          slug: "l__p1",
          group_id: 1,
        },
      ],
    },
    {
      id: 45,
      entity_id: 1,
      repeat_till: null,
      starts_at: "18:40:00",
      ends_at: "18:43:00",
      days: [1, 2, 20],
      last_occured_at: "2022-09-20 18:43:00",
      next_occurance_at: "2022-09-01 18:40:00",
      node_type: [],
      nodes: [
        {
          id: 1,
          name: "laptop1",
          node_type_id: 1,
          ip_address: "192.168.5.4",
          device_id: "LAP54",
          entity_id: 1,
          order: 1,
          slug: "l__p1",
          group_id: 1,
        },
      ],
    },
    {
      id: 46,
      entity_id: 1,
      repeat_till: null,
      starts_at: "10:16:00",
      ends_at: "10:20:00",
      days: [1, 2, 20],
      last_occured_at: "2022-09-20 10:20:00",
      next_occurance_at: "2022-09-02 11:14:00",
      node_type: [],
      nodes: [
        {
          id: 1,
          name: "laptop1",
          node_type_id: 1,
          ip_address: "192.168.5.4",
          device_id: "LAP54",
          entity_id: 1,
          order: 1,
          slug: "l__p1",
          group_id: 1,
        },
      ],
    },
    {
      id: 88,
      entity_id: 1,
      repeat_till: null,
      starts_at: "16:23:00",
      ends_at: "16:25:00",
      days: [1, 3, 5, 7],
      last_occured_at: "2022-09-07 16:25:00",
      next_occurance_at: "2022-10-01 16:23:00",
      node_type: [
        {
          id: 1,
          name: "laptop",
          slug: "l__p",
          nodes: [
            {
              id: 4,
              name: "laptop4",
              node_type_id: 1,
              ip_address: "192.168.5.7",
              device_id: "",
              entity_id: 1,
              order: 1,
              slug: "abc",
              group_id: 1,
            },
            {
              id: 5,
              name: "camera node",
              node_type_id: 1,
              ip_address: "192.11.18.16",
              device_id: "1",
              entity_id: 1,
              order: 1,
              slug: "c__e",
              group_id: 1,
            },
            {
              id: 1,
              name: "laptop1",
              node_type_id: 1,
              ip_address: "192.168.5.4",
              device_id: "LAP54",
              entity_id: 1,
              order: 1,
              slug: "l__p1",
              group_id: 1,
            },
            {
              id: 2,
              name: "laptop2",
              node_type_id: 1,
              ip_address: "192.168.5.5",
              device_id: "LAP55",
              entity_id: 1,
              order: 1,
              slug: "l__p1",
              group_id: 1,
            },
          ],
        },
      ],
      nodes: [
        {
          id: 1,
          name: "laptop1",
          node_type_id: 1,
          ip_address: "192.168.5.4",
          device_id: "LAP54",
          entity_id: 1,
          order: 1,
          slug: "l__p1",
          group_id: 1,
        },
      ],
    },
    {
      id: 89,
      entity_id: 1,
      repeat_till: null,
      starts_at: "16:23:00",
      ends_at: "16:25:00",
      days: [1, 3, 5, 7],
      last_occured_at: "2022-09-07 16:25:00",
      next_occurance_at: "2022-10-01 16:23:00",
      node_type: [
        {
          id: 1,
          name: "laptop",
          slug: "l__p",
          nodes: [
            {
              id: 4,
              name: "laptop4",
              node_type_id: 1,
              ip_address: "192.168.5.7",
              device_id: "",
              entity_id: 1,
              order: 1,
              slug: "abc",
              group_id: 1,
            },
            {
              id: 5,
              name: "camera node",
              node_type_id: 1,
              ip_address: "192.11.18.16",
              device_id: "1",
              entity_id: 1,
              order: 1,
              slug: "c__e",
              group_id: 1,
            },
            {
              id: 1,
              name: "laptop1",
              node_type_id: 1,
              ip_address: "192.168.5.4",
              device_id: "LAP54",
              entity_id: 1,
              order: 1,
              slug: "l__p1",
              group_id: 1,
            },
            {
              id: 2,
              name: "laptop2",
              node_type_id: 1,
              ip_address: "192.168.5.5",
              device_id: "LAP55",
              entity_id: 1,
              order: 1,
              slug: "l__p1",
              group_id: 1,
            },
          ],
        },
      ],
      nodes: [
        {
          id: 1,
          name: "laptop1",
          node_type_id: 1,
          ip_address: "192.168.5.4",
          device_id: "LAP54",
          entity_id: 1,
          order: 1,
          slug: "l__p1",
          group_id: 1,
        },
      ],
    },
  ],
  message: "",
};

export const ONE_TIME_DATA = {
  status: "SUCCESS",
  data: [
    {
      id: 66,
      entity_id: 1,
      repeat_till: null,
      type:'fixed',
      starts_at: "16:09:00",
      ends_at: "16:11:00",
      days: [],
      last_occured_at: "2022-10-27 16:25:00",
      next_occurance_at: "2022-09-09 16:09:00",
      node_type: [],
      nodes: [
        {
          id: 1,
          name: "laptop1",
          node_type_id: 1,
          ip_address: "192.168.5.4",
          device_id: "LAP54",
          entity_id: 1,
          node_type_name: "laptop",
          order: 1,
          slug: "l__p1",
          group_id: 1,
        },
      ],
    },
    {
      id: 67,
      entity_id: 1,
      repeat_till: null,
      starts_at: "16:09:00",
      ends_at: "16:11:00",
      days: [],
      last_occured_at: "2022-10-27 16:25:00",
      next_occurance_at: "2022-09-09 16:09:00",
      node_type: [],
      nodes: [
        {
          id: 1,
          name: "laptop1",
          node_type_id: 1,
          ip_address: "192.168.5.4",
          device_id: "LAP54",
          entity_id: 1,
          node_type_name: "laptop",
          order: 1,
          slug: "l__p1",
          group_id: 1,
        },
      ],
    },
    {
      id: 68,
      entity_id: 1,
      repeat_till: null,
      starts_at: "16:09:00",
      ends_at: "16:11:00",
      days: [],
      last_occured_at: "2022-10-27 16:25:00",
      next_occurance_at: "2022-09-09 16:09:00",
      node_type: [],
      nodes: [
        {
          id: 1,
          name: "laptop1",
          node_type_id: 1,
          ip_address: "192.168.5.4",
          device_id: "LAP54",
          entity_id: 1,
          node_type_name: "laptop",
          order: 1,
          slug: "l__p1",
          group_id: 1,
        },
      ],
    },
    {
      id: 69,
      entity_id: 1,
      repeat_till: null,
      starts_at: "16:09:00",
      ends_at: "16:11:00",
      days: [],
      last_occured_at: "2022-10-27 16:25:00",
      next_occurance_at: "2022-09-09 16:09:00",
      node_type: [],
      nodes: [
        {
          id: 1,
          name: "laptop1",
          node_type_id: 1,
          ip_address: "192.168.5.4",
          device_id: "LAP54",
          node_type_name: "laptop",
          entity_id: 1,
          order: 1,
          slug: "l__p1",
          group_id: 1,
        },
      ],
    },
    {
      id: 70,
      entity_id: 1,
      repeat_till: null,
      starts_at: "16:09:00",
      ends_at: "16:11:00",
      days: [],
      last_occured_at: "2022-10-27 16:25:00",
      next_occurance_at: "2022-09-09 16:09:00",
      node_type: [],
      nodes: [
        {
          id: 1,
          name: "laptop1",
          node_type_id: 1,
          ip_address: "192.168.5.4",
          device_id: "LAP54",
          entity_id: 1,
          node_type_name: "laptop",
          order: 1,
          slug: "l__p1",
          group_id: 1,
        },
      ],
    },
  ],
  message: "",
};

export const DAILY_DATA = {
    status: "SUCCESS",
    data: [
      {
        id: 66,
        entity_id: 1,
        type: 'daily',
        repeat_till: null,
        starts_at: "16:09:00",
        ends_at: "16:11:00",
        days: [],
        last_occured_at: "2022-10-27 16:25:00",
        next_occurance_at: "2022-09-09 16:09:00",
        node_type: [],
        nodes: [
          {
            id: 1,
            name: "laptop1",
            node_type_id: 1,
            ip_address: "192.168.5.4",
            device_id: "LAP54",
            entity_id: 1,
            node_type_name: "laptop",
            order: 1,
            slug: "l__p1",
            group_id: 1,
          },
        ],
      },
      {
        id: 67,
        entity_id: 1,
        repeat_till: null,
        starts_at: "16:09:00",
        ends_at: "16:11:00",
        days: [],
        last_occured_at: "2022-10-27 16:25:00",
        next_occurance_at: "2022-09-09 16:09:00",
        node_type: [],
        nodes: [
          {
            id: 1,
            name: "laptop1",
            node_type_id: 1,
            ip_address: "192.168.5.4",
            device_id: "LAP54",
            entity_id: 1,
            node_type_name: "laptop",
            order: 1,
            slug: "l__p1",
            group_id: 1,
          },
        ],
      },
      {
        id: 68,
        entity_id: 1,
        repeat_till: null,
        starts_at: "16:09:00",
        ends_at: "16:11:00",
        days: [],
        last_occured_at: "2022-10-27 16:25:00",
        next_occurance_at: "2022-09-09 16:09:00",
        node_type: [],
        nodes: [
          {
            id: 1,
            name: "laptop1",
            node_type_id: 1,
            ip_address: "192.168.5.4",
            device_id: "LAP54",
            entity_id: 1,
            node_type_name: "laptop",
            order: 1,
            slug: "l__p1",
            group_id: 1,
          },
        ],
      },
      {
        id: 69,
        entity_id: 1,
        repeat_till: null,
        starts_at: "16:09:00",
        ends_at: "16:11:00",
        days: [],
        last_occured_at: "2022-10-27 16:25:00",
        next_occurance_at: "2022-09-09 16:09:00",
        node_type: [],
        nodes: [
          {
            id: 1,
            name: "laptop1",
            node_type_id: 1,
            ip_address: "192.168.5.4",
            device_id: "LAP54",
            node_type_name: "laptop",
            entity_id: 1,
            order: 1,
            slug: "l__p1",
            group_id: 1,
          },
        ],
      },
      {
        id: 70,
        entity_id: 1,
        repeat_till: null,
        starts_at: "16:09:00",
        ends_at: "16:11:00",
        days: [],
        last_occured_at: "2022-10-27 16:25:00",
        next_occurance_at: "2022-09-09 16:09:00",
        node_type: [],
        nodes: [
          {
            id: 1,
            name: "laptop1",
            node_type_id: 1,
            ip_address: "192.168.5.4",
            device_id: "LAP54",
            entity_id: 1,
            node_type_name: "laptop",
            order: 1,
            slug: "l__p1",
            group_id: 1,
          },
        ],
      },
    ],
    message: "",
  };