import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Input,
  Flex,
  FormLabel,
  Text,
  ModalCloseButton,
  useColorModeValue,
  Button,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import useAddTemplate from "hooks/useAddTemplate";

const CreateTemplateModal = ({ onClose, isOpen, onOpen, group, entityId }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const { mutate: addTemplate, isLoading } = useAddTemplate(onClose, entityId);
  const textColor = useColorModeValue("navy.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const buttonHover = useColorModeValue("brand.600", "brand.400");
  const onSubmit = (data) => {
    addTemplate({ name: data.name, group_id: group?.id });
  };
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        blockScrollOnMount={false}
        size="lg"
      >
        <ModalOverlay />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContent>
            <Flex mt="50px" pl="10px">
              <Flex direction="column" width="100%" pr="10px">
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Name<Text color={brandStars}>*</Text>
                </FormLabel>
                <div style={{ marginBottom: "10px" }}>
                  <Controller
                    name="name"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <Input
                        fontSize="sm"
                        placeholder="Enter Template Name"
                        size="lg"
                        type="name"
                        {...field}
                      />
                    )}
                  />
                  {errors.name?.type === "required" && (
                    <span style={{ color: "red" }}>device is required</span>
                  )}
                </div>
              </Flex>
            </Flex>
            <Button
              variant="brand"
              mx={3}
              mt={3}
              mb={10}
              width="180px"
              type="submit"
              _hover={{ bg: isLoading ? "blue" : buttonHover }}
              isLoading={isLoading}
            >
              <Text color={"#FFFFFF"}>Save As Template</Text>
            </Button>
            <ModalCloseButton />
          </ModalContent>
        </form>
      </Modal>
    </>
  );
};

export default CreateTemplateModal;
