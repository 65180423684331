import React from "react";
import { Box, Text } from "@chakra-ui/layout";
import DownTimeListTable from "components/table/downTimeListTable";
import dayjs from "dayjs";

function SlaTimeLogTable(props) {
  const {
    slaTimeLogData,
    reportFilterType,
    blackoutReportData,
    currentPage,
    totalPage,
  } = props;

  const getColor = (value) => {
    switch (value) {
      case "Schedule Down":
        return "#135CC9";
      case "NOPD Down":
        return "#F0AE4C";
      case "UP":
        return " #3DCA78";
      default:
        return "red";
    }
  };

  const columnSchedule = [
    {
      Header: "From",
      accessor: (row) => {
        return (
          <Text
            color={"ndmsColors.blue.100"}
            pl={0}
            fontSize="12px"
            fontWeight="700"
          >
            {`${dayjs(row?.start_at).format("DD/MM/YYYY")}, ${dayjs(
              row?.start_at
            ).format("hh:mm a")}`}
          </Text>
        );
      },
    },
    {
      Header: "To",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {`${dayjs(row?.end_at).format("DD/MM/YYYY")}, ${dayjs(
              row?.end_at
            ).format("hh:mm a")}`}
          </Text>
        );
      },
    },
    {
      Header: "Duration",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row?.duration}
          </Text>
        );
      },
    },
  ];

  const columnsData = [
    {
      Header: "From",
      accessor: (row) => {
        return (
          <Text
            color={"ndmsColors.blue.100"}
            pl={0}
            fontSize="12px"
            fontWeight="700"
          >
            {`${dayjs(row?.start_at).format("DD MMM YYYY")}, ${dayjs(
              row?.start_at
            ).format("hh:mm a")}`}
          </Text>
        );
      },
    },
    {
      Header: "To",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {`${dayjs(row?.end_at).format("DD MMM YYYY")}, ${dayjs(
              row?.end_at
            ).format("hh:mm a")}`}
          </Text>
        );
      },
    },
    {
      Header: "Duration",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row?.duration}
          </Text>
        );
      },
    },
    {
      Header: "Nopd Duration",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row?.nopd_duration}
          </Text>
        );
      },
    },
    {
      Header: "Approved Downtime Reason",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row?.breakup_reason || "-"}
          </Text>
        );
      },
    },
    {
      Header: "State",
      accessor: (row) => {
        return (
          <Text fontSize="12px" fontWeight="700" color={getColor(row?.type)}>
            {row?.type}
          </Text>
        );
      },
    },
    {
      Header: "Schedule Status",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row?.is_schedule}
          </Text>
        );
      },
    },
    {
      Header: "Blackout Status",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row?.blackout_status}
          </Text>
        );
      },
    },
  ];

  const columnsValue = [
    {
      Header: "From",
      accessor: (row) => {
        return (
          <Text
            color={"ndmsColors.blue.100"}
            pl={0}
            fontSize="12px"
            fontWeight="700"
          >
            {`${dayjs(row?.From).format("DD/MM/YYYY")}, ${dayjs(
              row?.From
            ).format("hh:mm a")}`}
          </Text>
        );
      },
    },
    {
      Header: "To",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {`${dayjs(row?.To).format("DD/MM/YYYY")}, ${dayjs(row?.To).format(
              "hh:mm a"
            )}`}
          </Text>
        );
      },
    },
    {
      Header: "Blackout Time",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row?.blackout_time}
          </Text>
        );
      },
    },
    {
      Header: "Blackout Type",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row?.blackout_type}
          </Text>
        );
      },
    },
  ];

  return (
    <Box pt="25px">
      {reportFilterType === "BlackoutTimeReport" ? (
        <DownTimeListTable
          tableData={blackoutReportData}
          columnsData={columnsValue}
          currentPage={currentPage}
          totalPage={totalPage}
        />
      ) : (
        <DownTimeListTable
          tableData={slaTimeLogData}
          columnsData={
            reportFilterType === "Scheduledowntimereport"
              ? columnSchedule
              : columnsData
          }
          currentPage={currentPage}
          totalPage={totalPage}
        />
      )}
    </Box>
  );
}

export default SlaTimeLogTable;
