import React, { useEffect, useState } from "react";
import Card from "components/card/Card.js";
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import { useForm, Controller } from "react-hook-form";
import TicketModuleHooks from "hooks/ticketModule/ticketModuleHooks";
import { useHistory, useParams } from "react-router-dom";
import LoadingForm from "components/loader/LoadingForm";

function EditTicketTypes() {
  // Chakra color mode
  const { id } = useParams();
  const textColor = useColorModeValue("navy.700", "white");
  const [editData, setEditData] = useState([]);
  let activeColor = useColorModeValue("gray.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const { isLoading, error, refetch, isFetching } =
    TicketModuleHooks.useTypeListWithId(id, setEditData);
  const history = useHistory();

  const { mutate: editType } = TicketModuleHooks.useEditType(id);
  const {
    handleSubmit,
    control,
    formState: { errors },

    setValue,
  } = useForm();

  useEffect(() => {
    if (id !== undefined) {
      refetch();
    }
  }, [id]);

  useEffect(() => {
    if (editData?.length > 0) {
      setValue("name", editData[0]?.name);
      setValue("slug", editData[0]?.slug);
    }
  }, [editData]);

  const onSubmit = (data) => {
    editType({
      name: data.name,
      slug: data.slug,
    });
  };

  if (isLoading || isFetching) return <LoadingForm />;

  const handleCancel = () => {
    history.push(`/admin/ticket-crud/ticket-crud-types`);
  };

  return (
    <>
      <Box w="100%" pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Card>
          <Flex
            maxW={{ base: "100%" }}
            w="100%"
            mx={{ base: "auto", lg: "0px" }}
            me="auto"
            h="100%"
            alignItems="start"
            justifyContent="center"
            mb={{ base: "30px", md: "30px" }}
            px={{ base: "25px", md: "0px" }}
            mt={{ base: "20px", md: "20px" }}
            flexDirection="column"
          >
            <Box me="auto">
              <Text
                mb="24px"
                ms="4px"
                color={activeColor}
                fontWeight="bold"
                fontSize="24px"
              >
                Edit Ticket Type
              </Text>
            </Box>
            <Flex
              direction="column"
              w="100%"
              maxW="100%"
              background="transparent"
              borderRadius="15px"
              mx={{ base: "auto", lg: "unset" }}
              me="auto"
              mb={{ base: "20px", md: "auto" }}
            >
              <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
                <Flex w="100%">
                  <Flex direction="column" width="100%" pl="10px">
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                    >
                      Name<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <div style={{ marginBottom: "24px" }}>
                      <Controller
                        name="name"
                        rules={{
                          required: true,
                          validate: (value) => {
                            return !!value?.trim();
                          },
                        }}
                        control={control}
                        render={({ field }) => (
                          <Input
                            fontSize="sm"
                            ms={{ base: "0px", md: "0px" }}
                            type="name"
                            placeholder="Enter name"
                            //mb="24px"
                            fontWeight="500"
                            size="lg"
                            {...field}
                          />
                        )}
                      />
                      {errors?.name && (
                        <span style={{ color: "red" }}>name is required</span>
                      )}
                    </div>
                  </Flex>
                  <Flex direction="column" width="100%" pl="10px">
                    <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      display="flex"
                    >
                      Slug<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <div style={{ marginBottom: "24px" }}>
                      <Controller
                        name="slug"
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                          <Input
                            fontSize="sm"
                            disabled={true}
                            defaultValue={editData[0]?.name}
                            placeholder="Enter Slug"
                            size="lg"
                            type="name"
                            {...field}
                          />
                        )}
                      />
                      {errors.slug && (
                        <span style={{ color: "red" }}>slug is required</span>
                      )}
                    </div>
                  </Flex>
                </Flex>

                <Flex width="40%" pl="10px">
                  <Button
                    fontSize="sm"
                    variant="brand"
                    fontWeight="500"
                    w="90%"
                    h="50"
                    mb="24px"
                    mr="20px"
                    type="submit"
                  >
                    Submit
                  </Button>
                  <Button
                    fontSize="sm"
                    variant="brand"
                    fontWeight="500"
                    w="90%"
                    h="50"
                    mb="24px"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Flex>
              </form>
            </Flex>
          </Flex>
        </Card>
      </Box>
    </>
  );
}

export default EditTicketTypes;
