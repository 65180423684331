import React from "react";
import noGroupImage from "assets/img/layout/noGroup.png";
import { Flex, Image, Text } from "@chakra-ui/react";

const EmptyData = () => {
  return (
    <Flex
      align="center"
      justify="center"
      mt="80px"
      cursor="pointer"
      mb="40px"
      //onClick={() => setShowAddForm(true)}
    >
      <Flex direction="column" justify="center" align="center">
        <Image src={noGroupImage} width="70px" alt="no-group" />
        <Text fontSize={"22px"} color="#2B3674" fontWeight="bold">
          You Don’t Have Downtime added
        </Text>
      </Flex>
    </Flex>
  );
};

export default EmptyData;
