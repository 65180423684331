import React, { useState, useMemo, useCallback } from "react";
import Chart from "react-apexcharts";
import { GrNetworkDrive } from "react-icons/gr";
import { Box, Text, Icon, Flex, Spinner } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import MyLineChart from "./lineChart";
import LineChart from "components/charts/LineAreaChart";
import { CalendarIcon } from "@chakra-ui/icons";
import PieChart from "components/charts/PieChart";
import { TableUpIcon } from "components/icons/Icons";
import GroupDashboardTable from "components/table/groupDashboardTable";
import useNodeFilter from "hooks/useNodeFilter";
import TotalGroupCard from "./totalGroupCard";
import useNodeTableData from "hooks/useNodeTableData";
import { useParams } from "react-router";

const GroupDashboard = () => {
  const [status, setStatus] = useState("online");
  const entityId = parseInt(useParams()?.id);
  const { isLoading, error, data: countData } = useNodeFilter(entityId);
  const {
    isLoading: isTableLoading,
    error: tableError,
    data: nodeTableData,
  } = useNodeTableData(entityId);
  const pieChartOptions = {
    labels: [
      "Schedule Down Devices",
      "Blackout",
      "Down Devices",
      "Online Devices",
    ],
    colors: ["#FAD285", "#F86689", "#58B0E1", "#93D8B7"],
    chart: {
      width: "150px",
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    hover: { mode: null },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              label: "Downtime",
              color: "navy blue",
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },
    series: [8, 4, 2, 10],
    fill: {
      colors:
        status === "offline"
          ? ["#F86689"]
          : ["#FAD285", "#F86689", "#58B0E1", "#93D8B7"],
    },
    tooltip: {
      enabled: true,
      theme: "dark",
    },
  };

  const data = [
    {
      id: 1,
      device_name: "computer",
      device_type: "computer",
      ip: "99.11.ac.20",
      status: "online",
      last_down_time: "06:30pm",
    },
    {
      id: 2,
      device_name: "computer",
      device_type: "computer",
      ip: "99.11.ac.20",
      status: "offline",
      last_down_time: "06:30pm",
    },
    {
      id: 3,
      device_name: "computer",
      device_type: "computer",
      ip: "99.11.ac.20",
      status: "online",
      last_down_time: "06:30pm",
    },
    {
      id: 4,
      device_name: "computer",
      device_type: "computer",
      ip: "99.11.ac.20",
      status: "offline",
      last_down_time: "06:30pm",
    },
    {
      id: 5,
      device_name: "computer",
      device_type: "computer",
      ip: "99.11.ac.20",
      status: "online",
      last_down_time: "06:30pm",
    },
  ];

  const columnsData = [
    {
      Header: "ID",
      accessor: (row) => {
        return (
          <Text color={"#2B3674"} fontSize="sm" fontWeight="700">
            {row.node_id}
          </Text>
        );
      },
    },
    {
      Header: "Device Name",
      accessor: (row) => {
        return (
          <Text color={"#2B3674"} fontSize="sm" fontWeight="700">
            {row.node_name}
          </Text>
        );
      },
    },
    {
      Header: "Device Type",
      accessor: (row) => {
        return (
          <Text color={"#2B3674"} fontSize="sm" fontWeight="700">
            {row.node_type_name}
          </Text>
        );
      },
    },
    {
      Header: "I.P.",
      accessor: (row) => {
        return (
          <Text color={"#2B3674"} fontSize="sm" fontWeight="700">
            {row.ip_address}
          </Text>
        );
      },
    },
    {
      Header: "Status",
      accessor: (row) => {
        return (
          <Text
            color={row.status === "0" ? "#F86689" : "#2B3674"}
            fontSize="sm"
            fontWeight="700"
          >
            {row.status === "0" ? "offline" : "online"}
          </Text>
        );
      },
    },

    // {
    //   Header: "Last Down Time",
    //   accessor: (row) => {
    //     return (
    //       <Text color={"#2B3674"} fontSize="sm" fontWeight="700">
    //         6:24 Pm
    //       </Text>
    //     );
    //   },
    // },
    // {
    //   // Build our expander column
    //   id: "expander", // Make sure it has an ID
    //   Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
    //     <span {...getToggleAllRowsExpandedProps}>
    //       {isAllRowsExpanded ? null : <TableUpIcon />}
    //     </span>
    //   ),
    //   Cell: ({ row }) => (
    //     // Use Cell to render an expander for each row.
    //     // We can use the getToggleRowExpandedProps prop-getter
    //     // to build the expander.
    //     <span {...row.getToggleRowExpandedProps()}>
    //       {row.isExpanded ? null : <TableUpIcon />}
    //     </span>
    //   )
    // },
    {
      Header: " ",
      accessor: (row) => {
        return <TableUpIcon />;
      },
    },
  ];

  const renderRowSubComponent = () => {
    return (
      <Card bg="rgba(67, 24, 255, 0.02)" width="700px">
        <Flex width="600px">
          <Text color="#2B3674" fontSize="12px" fontWeight="bold">
            1
          </Text>
          <Text color="#2B3674" fontSize="15px" fontWeight="bold">
            Computer
          </Text>
          <Text color="#2B3674" fontSize="12px" fontWeight="bold">
            99.11.ac.20
          </Text>
          <Text color="#2B3674" fontSize="12px" fontWeight="bold">
            Online
          </Text>
        </Flex>
      </Card>
    );
  };

  const pieChartData = [8, 4, 2, 10];
  return (
    <Box pt={{ base: "130px", md: "100px", xl: "100px" }} pb="50px">
      <Card bg="rgba(67, 24, 255, 0.02)" height={"800px"}>
        <Flex position="relative">
          {status === "online" && (
            <Card
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="#93D8B7"
              width="max-content"
              height="76px"
              mr="10px"
            >
              <Text color="#2B3674" fontSize="20px" fontWeight="bold">
                Online
              </Text>
            </Card>
          )}
          {status === "offline" && (
            <Card
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="#F86689"
              width="max-content"
              height="76px"
              mr="10px"
            >
              <Flex align="center" justify="center" direction="column">
                <Text color="white" fontSize="20px" fontWeight="bold">
                  Blackout
                </Text>
                <Text color="white" fontSize="16px" fontWeight="bold">
                  Since 5:12 Pm
                </Text>
              </Flex>
            </Card>
          )}
          {status === "scheduled" && (
            <Card
              display="flex"
              alignItems="center"
              justifyContent="center"
              bg="#FAD285"
              width="max-content"
              height="76px"
              mr="10px"
            >
              <Flex align="center" justify="center" direction="column">
                <Text color="#2B3674" fontSize="20px" fontWeight="bold">
                  Schedule Down Time
                </Text>
                <Text color="#2B3674" fontSize="16px" fontWeight="bold">
                  Till, 10:15am
                </Text>
              </Flex>
            </Card>
          )}
          <Card
            width="max-content"
            height="76px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            mr="10px"
          >
            <Flex align="center" justify="center" direction="column">
              <Text color="#4318FF" fontSize="16px" fontWeight="bold">
                Last Down Time
              </Text>
              <Text color="#A3AED0" fontSize="16px" fontWeight="bold">
                5:12 Pm
              </Text>
            </Flex>
          </Card>
          <Card
            width="max-content"
            height="76px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            mr="10px"
          >
            <Flex align="center" justify="center" direction="column">
              <Text color="#4318FF" fontSize="16px" fontWeight="bold">
                Last Up Time
              </Text>
              <Text color="#A3AED0" fontSize="16px" fontWeight="bold">
                6:12 Pm
              </Text>
            </Flex>
          </Card>
          <Card
            width="max-content"
            height="76px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            mr="10px"
          >
            <Flex align="center" justify="center" direction="column">
              <Text color="#4318FF" fontSize="16px" fontWeight="bold">
                Total Downtime
              </Text>
              <Text color="#A3AED0" fontSize="16px" fontWeight="bold">
                1hr
              </Text>
            </Flex>
          </Card>
          <Card
            width="169px"
            bg="#4318FF"
            height="48px"
            display="flex"
            alignItems="center"
            justifyContent="center"
            position="absolute"
            right="10px"
            borderRadius="16px"
          >
            <Flex align="center" justify="center">
              <CalendarIcon color="white" />
              <Text color="white" fontSize="16px" pl="10px" fontWeight="bold">
                Thu, 24 June
              </Text>
            </Flex>
          </Card>
        </Flex>
        <Flex mt="20px">
          <Card width="303px" height="max-content" mr="20px">
            <Flex justify="space-between">
              <Text color="#4318FF" fontSize="16px" fontWeight="bold">
                Critical Devices
              </Text>
              <Text color="#4318FF" fontSize="12px" fontWeight="bold">
                View All
              </Text>
            </Flex>
            <Flex direction="column" pt="10px">
              {[...Array(6)].map((e, i) => (
                <Box bg={i % 2 === 0 ? "#F4F7FE" : "white"}>
                  <Text
                    color="#2B3674"
                    py="5px"
                    pl="8px"
                    fontSize="12px"
                    fontWeight="bold"
                  >
                    Computer: IP: 99.22.3ac
                  </Text>
                </Box>
              ))}
            </Flex>
          </Card>
          <Card
            p="20px"
            align="center"
            height="max-content"
            direction="column"
            w="max-content"
          >
            <Chart
              height="170px"
              w="100%"
              type="donut"
              series={pieChartData}
              options={pieChartOptions}
            />
            <Flex align="center" justify="space-between" px="20px">
              <Flex align="center">
                <Box
                  h="10px"
                  w="15px"
                  bg="#FAD285"
                  borderRadius="15px"
                  me="4px"
                />
                <Text fontSize="xs" mb="5px">
                  Schedule Down Devices
                </Text>
              </Flex>

              {/* <VSeparator mx={{ base: "60px", xl: "60px", "2xl": "60px" }} /> */}

              <Flex align="center">
                <Box
                  h="10px"
                  w="15px"
                  bg="#F86689"
                  borderRadius="15px"
                  me="4px"
                />
                <Text fontSize="xs" mb="5px">
                  Blackout
                </Text>
              </Flex>
            </Flex>
            <Flex align="center" justify="space-between" px="20px">
              <Flex align="center">
                <Box
                  h="10px"
                  w="15px"
                  bg="#58B0E1"
                  borderRadius="15px"
                  me="4px"
                />
                <Text fontSize="xs" mb="5px">
                  Down Devicer
                </Text>
              </Flex>

              {/* <VSeparator mx={{ base: "60px", xl: "60px", "2xl": "60px" }} /> */}

              <Flex align="center">
                <Box
                  h="10px"
                  w="15px"
                  bg="#93D8B7"
                  borderRadius="15px"
                  me="4px"
                />
                <Text fontSize="xs" mb="5px">
                  Online Devices
                </Text>
              </Flex>
            </Flex>
          </Card>

          <MyLineChart />
        </Flex>
        <Card mt="20px" borderRadius="15px">
          <Flex py="20px" align="center">
            <Text fontSize="18px" fontWeight="bold" color="#2B3674">
              Total Device : {countData?.data?.total_nodes_count}
            </Text>
            {countData?.data?.data?.map((data, index) => (
              <Flex direction="column" key={index} align="center" pl="25px">
                <Flex align="center">
                  <Icon color="#2B3674" as={GrNetworkDrive} w="17px" h="17px" />
                  <Text
                    fontSize="14px"
                    fontWeight="bold"
                    color="#2B3674"
                    pl="5px"
                  >
                    {data?.node_type_name} : {data?.total}
                  </Text>
                </Flex>

                <Text
                  fontSize="12px"
                  pt="3px"
                  fontWeight="bold"
                  color="#A3AED0"
                >
                  Online : {data?.online}
                </Text>
                <Text fontSize="12px" fontWeight="bold" color="#F86689">
                  Offline : {data?.offline}
                </Text>
              </Flex>
            ))}
          </Flex>
        </Card>
        <Card direction="column" w="100%" mt="20px" px="0px">
          {isTableLoading ? (
            <Card w="100%" h="200px" px="0px">
              <Flex
                direction="column"
                mt="40px"
                align="center"
                justify="center"
              >
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                />
              </Flex>
            </Card>
          ) : (
            <>
              {nodeTableData?.data?.length > 0 ? (
                <GroupDashboardTable
                  tableData={nodeTableData?.data}
                  renderRowSubComponent={renderRowSubComponent}
                />
              ) : (
                <Text>No data available</Text>
              )}
            </>
          )}
        </Card>
      </Card>
    </Box>
  );
};

export default GroupDashboard;
