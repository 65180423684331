import { QUERY_KEY, MUTATION_KEY } from "constants/globalConstants";
import CrudApiService from "apiService/crudApiService";
import { useMutation, useQueryClient, useQuery } from "react-query";
import toast from "react-hot-toast";

const useClientConfigList = () => {
  return useQuery(
    QUERY_KEY.GET_CLIENT_CONFIG_LIST,
    () => CrudApiService.getClientConfigList(),
    { retry: false }
  );
};

const useCreateClientConfig = (setShowAddForm) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.CREATE_CLIENT_CONFIG],
    (data) => CrudApiService.createClientConfig(data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(QUERY_KEY.GET_CLIENT_CONFIG_LIST);
        setShowAddForm(false);
        toast.success("Config created successfully");
        localStorage.setItem(data?.data?.key, data?.data?.value);
        document.title = data?.data?.value || "NDMS";
        window.location.reload();
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

const useDeleteClientConfig = (onClose) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.DELETE_CLIENT_CONFIG],
    (id) => CrudApiService.deleteClientConfig(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.GET_CLIENT_CONFIG_LIST);
        onClose();
        toast.success("Config deleted successfully");
        window.location.reload();
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

const useUpdateClientConfig = (setShowEditForm) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.UPDATE_CLIENT_CONFIG],
    (data) => CrudApiService.editClientConfig(data),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(QUERY_KEY.GET_CLIENT_CONFIG_LIST);
        setShowEditForm(false);
        toast.success("Config updated successfully");
        localStorage.setItem(data?.data?.key, data?.data?.value);
        window.location.reload();
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

const ClientConfigHooks = {
  useClientConfigList,
  useCreateClientConfig,
  useDeleteClientConfig,
  useUpdateClientConfig,
};

export default ClientConfigHooks;
