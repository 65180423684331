import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Box,
  Flex,
  Text,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";
import "./downloadModal.css";
import { Link } from "react-router-dom";
import useGenerateCsvReport from "hooks/slaReport/useGenerateCsvReport";
import useGenerateBlackoutReport from "hooks/slaReport/useGenerateBlackout";
import useGenerateScheduleReport from "hooks/slaReport/useGenerateScheduleReport";
import dayjs from "dayjs";
import useGenerateSummaryReport from "hooks/slaReport/useGenerateSummaryReportcsv";
import useTicketReportDetail from "hooks/slaReport/useTicketReportDetails";

const DownloadModal = (props) => {
  const {
    isOpen,
    onClose,
    type,
    entity,
    priority,
    selectedTicketType,
    ticketReportData,
    entityTypeName,
    entityTypeId,
    nodeTypeId,
    nodeTypeName,
    nodeId,
    nodeIdName,
    startDate,
    endDate,
    uptimeChecked,
    scheduleChecked,
    effectiveChecked,
    showTimeChecked,
    reportFilterType,
    slaPerformanceMetrics,
  } = props;
  const [csvReport, setCsvReport] = useState();
  const [enabled, setEnabled] = useState(false);
  const [blackoutEnabled, setBlackoutEnabled] = useState(false);
  const [scheduleEnabled, setScheduleEnabled] = useState(false);
  const [summaryEnabled, setSummaryEnabled] = useState(false);
  const [ticketEnabled, setTicketEnabled] = useState(false);

  // const { mutate: csvReportData, isLoading } = useGenerateCsvReport(
  //   setCsvReport,
  //   onClose
  // );
  const {
    isLoading: isReportLoading,
    error,
    data: slaReportData,
    refetch,
    isFetching: isReportFetching,
  } = useGenerateCsvReport(
    enabled,
    entityTypeId,
    entityTypeName,
    nodeTypeId,
    nodeTypeName,
    nodeId,
    nodeIdName,
    dayjs(startDate).format("YYYY-MM-DD"),
    dayjs(endDate).format("YYYY-MM-DD"),
    uptimeChecked,
    scheduleChecked,
    effectiveChecked,
    showTimeChecked,
    onClose,
    setEnabled
  );
  const {
    isLoading: isScheduleLoading,
    data: scheduleReportData,
    refetch: scheduleRefetch,
    isFetching: isScheduleFetching,
  } = useGenerateScheduleReport(
    scheduleEnabled,
    entityTypeId,
    entityTypeName,
    nodeTypeId,
    nodeTypeName,
    nodeId,
    nodeIdName,
    dayjs(startDate).format("YYYY-MM-DD"),
    dayjs(endDate).format("YYYY-MM-DD"),
    uptimeChecked,
    scheduleChecked,
    effectiveChecked,
    showTimeChecked,
    onClose,
    setScheduleEnabled
  );
  const {
    isLoading: isBlackoutLoading,
    data: blackoutReportData,
    refetch: blackoutRefetch,
    isFetching: isBlackoutFetching,
  } = useGenerateBlackoutReport(
    blackoutEnabled,
    entityTypeId,
    entityTypeName,
    nodeTypeId,
    nodeTypeName,
    nodeId,
    nodeIdName,
    dayjs(startDate).format("YYYY-MM-DD"),
    dayjs(endDate).format("YYYY-MM-DD"),
    uptimeChecked,
    scheduleChecked,
    effectiveChecked,
    showTimeChecked,
    onClose,
    setBlackoutEnabled
  );

  const {
    data: summaryReportData,
    isLoading: issummaryReportLoading,
    refetch: summaryRefetch,
    isFetching: issummaryReportFetching,
  } = useGenerateSummaryReport(
    summaryEnabled,
    dayjs(startDate).format("YYYY-MM-DD"),
    dayjs(endDate).format("YYYY-MM-DD"),
    onClose,
    setSummaryEnabled
  );
  const { refetch: ticketCsvRefetch } = useTicketReportDetail(
    ticketEnabled,
    setTicketEnabled,
    type,
    entity,
    priority,
    dayjs(startDate).format("YYYY-MM-DD"),
    dayjs(endDate).format("YYYY-MM-DD"),
    "",
    {},
    {},
    onClose
  );

  useEffect(() => {
    if (enabled) {
      refetch(
        ticketEnabled,
        setTicketEnabled,
        type,
        entity,
        priority,
        dayjs(startDate).format("YYYY-MM-DD"),
        dayjs(endDate).format("YYYY-MM-DD"),
        "",
        {},
        {},
        onClose
      );
    }
  }, [enabled]);

  useEffect(() => {
    if (blackoutEnabled) {
      blackoutRefetch();
    }
  }, [blackoutEnabled]);

  useEffect(() => {
    if (summaryEnabled) {
      summaryRefetch();
    }
  }, [summaryEnabled]);

  useEffect(() => {
    if (scheduleEnabled) {
      scheduleRefetch();
    }
  }, [scheduleEnabled]);

  useEffect(() => {
    if (ticketEnabled) {
      ticketCsvRefetch();
    }
  }, [ticketEnabled]);

  const handleCsvDownload = () => {
    if (reportFilterType === "BlackoutTimeReport") {
      setBlackoutEnabled(true);
    } else if (reportFilterType === "Scheduledowntimereport") {
      setScheduleEnabled(true);
    } else if (reportFilterType === "NodeTypeSummary") {
      setSummaryEnabled(true);
    } else if (reportFilterType === "TicketReport") {
      setTicketEnabled(true);
    } else {
      setEnabled(true);
    }
  };

  useEffect(() => {
    csvReport && window.open(csvReport?.data, "_blank", "noopener,noreferrer");
  }, [csvReport]);

  const handleModalClose = () => {
    if (isOpen) {
      onClose();
      setTimeout(() => window.print(), 1000);
    } else {
      window.print();
    }
  };

  const handlePdfDownload = () => {
    onClose();
    setTimeout(() => handleModalClose(), 1000);
  };

  return (
    <Box borderRadius="3xl">
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        blockScrollOnMount={false}
        size="sm"
        isCentered
      >
        <ModalOverlay />
        <ModalContent borderRadius="20px" className="noPrint">
          <ModalHeader>
            <Flex justify="center" direction="column" align="center" mt="30px">
              <Text fontSize="30px">Select Export Option</Text>
              <Flex mt="20px">
                <Button
                  bg="#4318FF"
                  _hover={{ bg: "#2B3674" }}
                  mx={3}
                  mt={2}
                  mb={10}
                  size="lg"
                  onClick={handlePdfDownload}
                >
                  <Text color={"#FFFFFF"}>PDF</Text>
                </Button>
                <Link
                  to={{
                    pathname: "/report/pdf-report",
                    state: {
                      data:
                        slaPerformanceMetrics ||
                        summaryReportData ||
                        ticketReportData,
                      otherData: {
                        entityTypeName,
                        startDate,
                        endDate,
                        nodeTypeName,
                        type,
                        entity,
                        priority,
                        selectedTicketType,
                      },
                      type: reportFilterType,
                    },
                  }}
                >
                  <Button
                    bg="#4318FF"
                    _hover={{ bg: "#2B3674" }}
                    mx={1}
                    mt={2}
                    mb={10}
                    size="lg"
                    px={4}
                    onClick={onClose}
                    // onClick={() =>
                    //   history.push({
                    //     pathname: "/report/pdf-report",
                    //     state: { data: slaPerformanceMetrics },
                    //   })
                    // }
                  >
                    <Text color={"#FFFFFF"}>PDF II</Text>
                  </Button>
                </Link>
                <Button
                  variant="outline"
                  colorScheme="#2B3674"
                  _hover={{ bg: "#F0F8FF" }}
                  mx={3}
                  mt={2}
                  size="lg"
                  mb={10}
                  isLoading={
                    isReportLoading ||
                    isScheduleLoading ||
                    isBlackoutLoading ||
                    isReportFetching ||
                    isScheduleFetching ||
                    isBlackoutFetching ||
                    issummaryReportLoading ||
                    issummaryReportFetching
                  }
                  onClick={handleCsvDownload}
                >
                  <Text color={"#2B3674"}>Excel</Text>
                </Button>
              </Flex>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default DownloadModal;
