import React from "react";
import { Box, Text } from "@chakra-ui/layout";
import { Flex, InputGroup, InputRightElement, Input } from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { CalendarIcon } from "@chakra-ui/icons";
import dayjs from "dayjs";

function EndDateFilter(props) {
  const { setEndDate, endDate } = props;

  return (
    <Box mt="26px" mr="20px">
      <Flex alignItems="center">
        <Flex>
          <Text color="ndmsColors.gray.2100" fontSize="14px" fontWeight="bold">
            End Date
          </Text>
        </Flex>
        <Flex
          alignItems="center"
          fontSize="13px"
          width="198px"
          height="2.5rem"
          ml="14px"
          id="user_type"
          size="lg"
          mr="10px"
          color="#2B3674"
          fontWeight="bold"
        >
          <DatePicker
            wrapperClassName="date-picker"
            selected={endDate}
            dateFormat="MMMM d, yyyy"
            onChange={(date) => setEndDate(date)}
            customInput={
              <InputGroup>
                <Input
                  placeholder="From"
                  value={dayjs(endDate).format("DD MMM, YYYY")}
                />
                <InputRightElement
                  children={<CalendarIcon color="#4318FF" />}
                />
              </InputGroup>
            }
          />
        </Flex>
      </Flex>
    </Box>
  );
}

export default EndDateFilter;
