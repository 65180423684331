import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
} from "@chakra-ui/accordion";
import Icon from "@chakra-ui/icon";
import { Box, Flex, Grid, GridItem, Text } from "@chakra-ui/layout";
import React, { useEffect, useState } from "react";
import "../../../assets/css/grid.css";
import { MdOutlineBrowserNotSupported } from "react-icons/md";
import { GetEmptyBox } from "utils/emptyBox";

function AccordionLiveDashboard(props) {
  const { filteredData, getValue } = props;
  const [hiddenGroupLength, setHiddenGroupLength] = useState();

  const newData =
    filteredData?.length > 0 &&
    filteredData?.filter((items) => items?.node_details?.length === 0);

  useEffect(() => {
    setHiddenGroupLength(newData?.length);
  }, [newData]);

  return (
    <>
      {hiddenGroupLength > 0 ? (
        <>
          {filteredData?.length === hiddenGroupLength ? (
            <Flex
              direction="column"
              justify="center"
              align="center"
              ml="300px"
              mt="50px"
              mb="40px"
              width="400px"
            >
              <Icon
                as={MdOutlineBrowserNotSupported}
                width="70px"
                height="70px"
                color="#828282"
              />
              <Text fontSize={"18px"} color="#828282" fontWeight="bold">
                Hold on client is not install yet!!
              </Text>
            </Flex>
          ) : (
            ""
          )}
          <Accordion allowToggle ml="10px" p="0px" mt="20px">
            <AccordionItem p="0px" border="none">
              <h1>
                <AccordionButton _focus={{ boxShadow: "none" }}>
                  <Flex justifyContent="center" alignItems="center">
                    <Box
                      textAlign="left"
                      py="5px"
                      color="#828282"
                      fontSize="16px"
                      fontWeight="bold"
                      mr="10px"
                    >
                      Hidden ({hiddenGroupLength})
                    </Box>
                    <AccordionIcon mr="10px" color="#828282" />
                    <Box borderTop="1px dotted #828282" minWidth="817px"></Box>
                  </Flex>
                </AccordionButton>
              </h1>
              <AccordionPanel p="0px" pb={4}>
                <Grid templateColumns="repeat(3, 1fr)" gap={0}>
                  {filteredData?.map(
                    (value, idx) =>
                      value.node_details.length === 0 && (
                        <>
                          <GridItem
                            key={idx}
                            className={`${
                              hiddenGroupLength === 2 ? "element-count2" : ""
                            } grid-item`}
                            p="45px 44px 45px 44px"
                          >
                            <Box
                              color="#2B3674"
                              fontSize="18px"
                              fontWeight="bold"
                            >
                              {" "}
                              {value.name}
                            </Box>
                            <Box
                              color="#AEAEAE"
                              fontSize="12px"
                              fontWeight="bold"
                            >
                              {" "}
                              Total Nodes : {getValue(value?.node_details)}
                            </Box>
                          </GridItem>
                        </>
                      )
                  )}
                  <GetEmptyBox object={hiddenGroupLength} />
                </Grid>
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </>
      ) : (
        ""
      )}
    </>
  );
}

export default AccordionLiveDashboard;
