import axios from "axios";
import Config from "config";
import request from "utils";

const MY_URL = "https://3rpgscjcvc.execute-api.ap-south-1.amazonaws.com/api/";
const user_permissions = [
  "customer.create",
  "customer.delete",
  "customer.update",
  "customer.view",
  "entity.view",
  "entity.create",
  "entity.delete",
  "entity.update",
  "entity.layout.create",
  "entity.layout.view",
  "entity.downtime.view",
  "entity.downtime_list.view",
  "node_type.view",
  "node_type.create",
  "node_type.update",
  "node_type.delete",
  "user.view",
  "role.view",
  "permissions.view",
  "user.create",
  "role.create",
  "permissions.create",
  "user.delete",
  "role.delete",
  "permissions.delete",
  "user.update",
  "role.update",
  "permissions.update",
  "template.view",
  "template.update",
  "template.delete",
  "template.create",
  "entity_dashboard.view",
  "group_dashboard.view",
  "entity.layout.update",
  "entity.layout.delete",
  "node.view",
  "node.create",
  "node.update",
  "node.delete",
  "schedule_downtime.create",
  "schedule_downtime.update",
  "schedule_downtime.delete",
  "schedule_downtime.view",
  "live_dashboard.view",
  "group_from_template.create",
  "nodes.export",
  "nodes.import",
  "entity.license.create",
  "entity.clientapp.create",
  "entity.clientapp.update",
  "entity.clientapp.view",
  "entity.clientapp.delete",
  "node_dashboard.view",
  "node_details.view",
  "node.downtime.create_downtime_breakup",
  "node.downtime.view_downtime_breakup",
  "node.downtime.update_downtime_breakup",
  "node.downtime.review_downtime_breakup",
  "sla_report.view",
  "schedule_report.view",
  "blackout_report.view",
  "entity_report.view",
  "ticketing.ticket.view",
  "ticketing.ticket.create",
  "ticketing.ticket.assign_users",
  "ticketing.ticket.close",
  "ticketing.ticket.update_priority",
  "ticketing.ticket.task_view",
  "ticketing.ticket.comment_create",
  "ticketing.dashboard",
  "ticketing.ticket.update_status",
  "ticketing.ticket.update_status",
  "ticketing.ticket_status.delete",
  "ticketing.ticket_status.update",
  "ticketing.status_reason.create",
  "ticketing.status_reason.delete",
  "ticketing.status_reason.update",
  "ticketing.ticket_status.create",
  "ticketing.ticket_tab.delete",
  "ticketing.ticket_tab.update",
  "ticketing.ticket_tab.create",
  "ticketing.ticket_type.delete",
  "ticketing.ticket_type.update",
  "ticketing.ticket_type.create",
  "ticketing.ticket_tab.view",
  "ticketing.ticket_status.view",
  "ticketing.ticket_type.view",
  "ticketing.status_reason.view",
  "template_node.delete",
  "template_node.update",
  "template_node.create",
  "entity.clientapp.download_install_script",
  "entity.clientapp.onboard_client",
  "entity.clientapp.check_license",
  "ticketing.ticket_tab.list_view",
  "TestPermission",
  "template_node.view",
  "config.delete",
  "config.create",
  "config.update",
  "config.view",
];

const loginUser = async (apiData) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/login`,
    apiData
  );
  data.data.user_detail.client_config.forEach((item) =>
    localStorage.setItem(item.key, item.value)
  );
  localStorage.setItem("client_logo", data.data.user_detail.client_logo);
  localStorage.setItem("token", data.data.access_token);
  localStorage.setItem("refresh_token", data.data.refresh_token);
  localStorage.setItem("email", data.data.user_detail.user_email);
  localStorage.setItem("user", data.data.user_detail.username);

  data.data.user_detail.is_superuser
    ? localStorage.setItem("permissions", JSON.stringify(user_permissions))
    : localStorage.setItem(
        "permissions",
        JSON.stringify(data.data.user_detail.permission)
      );
  localStorage.setItem("is_superuser", data.data.user_detail.is_superuser);
  localStorage.setItem("is_supervisor", data.data.user_detail.is_supervisor);
  localStorage.setItem(
    "handles_tickets",
    data.data.user_detail.handles_tickets
  );
  localStorage.setItem("client_name", data.data.user_detail.client_name);
  return data;
};

const getClientList = async (pageSize, currentPage) => {
  const { data } = await request.get(
    pageSize === undefined && currentPage === undefined
      ? `${process.env.REACT_APP_BASE_URL}/client?pagination=false`
      : `${process.env.REACT_APP_BASE_URL}/client?page_size=${pageSize}&page=${currentPage}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const regenerateToken = async (apiData) => {
  const { data } = await request.post(
    `${process.env.REACT_APP_BASE_URL}/regenerate_token`,
    apiData
  );
  return data;
};

const getEntityList = async (status, pageSize, currentPage) => {
  const { data } = await request.get(
    status === ":status"
      ? `${process.env.REACT_APP_BASE_URL}/entity?page_size=${pageSize}&page=${currentPage}`
      : `${process.env.REACT_APP_BASE_URL}/entity/?status=${status}&page_size=${pageSize}&page=${currentPage}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getAllEntityList = async (status, pageSize, currentPage) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/entity?pagination=${false}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getEntityNameList = async () => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/get-entity-list`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getIndividualEntity = async (id) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/entity?entity_id=${id}`,
    { headers: { Authorization: localStorage.getItem("token") } }
  );
  return data;
};

const getGroup = async (id) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/group?entity_id=${id}`,
    { headers: { Authorization: localStorage.getItem("token") } }
  );
  return data;
};

const getUntraceableGroup = async (id) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/untraceable_group?entity_id=${id}`,
    { headers: { Authorization: localStorage.getItem("token") } }
  );
  return data;
};

const addClient = async (apiData) => {
  const { data } = await request.post(
    `${process.env.REACT_APP_BASE_URL}/client`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const addEntity = async (apiData) => {
  const { data } = await request.post(
    `${process.env.REACT_APP_BASE_URL}/entity`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const addGroup = async (apiData) => {
  const { data } = await request.post(
    `${process.env.REACT_APP_BASE_URL}/group`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const editClient = async (apiData) => {
  const { data } = await request.put(
    `${process.env.REACT_APP_BASE_URL}/client`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const deleteClient = async (id) => {
  const { data } = await request.delete(
    `${process.env.REACT_APP_BASE_URL}/client/${id}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const deleteEntity = async (id) => {
  const { data } = await request.delete(
    `${process.env.REACT_APP_BASE_URL}/entity/${id}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const generateLicense = async (apiData) => {
  const { data } = await request.post(
    `${process.env.REACT_APP_BASE_URL}/generate-license/`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const editEntity = async (apiData, id) => {
  const { data } = await request.put(
    `${process.env.REACT_APP_BASE_URL}/entity/${id}`,
    apiData,
    { headers: { Authorization: localStorage.getItem("token") } }
  );
  return data;
};

const getNodeType = async (pageSize, currentPage) => {
  if (localStorage.getItem("token")) {
    const { data } = await request.get(
      pageSize === undefined && currentPage === undefined
        ? `${process.env.REACT_APP_BASE_URL}/nodetype?pagination=false`
        : `${process.env.REACT_APP_BASE_URL}/nodetype?page_size=${pageSize}&page=${currentPage}`,
      {
        headers: { Authorization: localStorage.getItem("token") },
      }
    );
    return data;
  }
};

const addNodeType = async (apiData) => {
  const { data } = await request.post(
    `${process.env.REACT_APP_BASE_URL}/nodetype/`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const uploadImage = async (apiData, fileName) => {
  const { data } = await request.post(
    `${process.env.REACT_APP_BASE_URL}/upload_image?file_name=${fileName}`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const editNodeType = async (apiData, id) => {
  const { data } = await request.put(
    `${process.env.REACT_APP_BASE_URL}/nodetype/${id}`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const deleteNodeType = async (id) => {
  const { data } = await request.delete(
    `${process.env.REACT_APP_BASE_URL}/nodetype/${id}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const deleteGroup = async (id) => {
  const { data } = await request.delete(
    `${process.env.REACT_APP_BASE_URL}/group/${id}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getNode = async (id) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/node/?group_id=${id}`,
    { headers: { Authorization: localStorage.getItem("token") } }
  );
  return data;
};

const adduntraceableNode = async (apiData) => {
  const { data } = await request.post(
    `${process.env.REACT_APP_BASE_URL}/untraceable_node/`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const addNode = async (apiData) => {
  const { data } = await request.post(
    `${process.env.REACT_APP_BASE_URL}/node/`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const updateGroup = async (apiData, id) => {
  const { data } = await request.put(
    `${process.env.REACT_APP_BASE_URL}/group/${id}`,
    apiData,
    { headers: { Authorization: localStorage.getItem("token") } }
  );
  return data;
};

const forgotPassword = async (apiData) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/forgot-password`,
    apiData
  );
  return data;
};

const confirmPassword = async (apiData) => {
  const { data } = await axios.post(
    `${process.env.REACT_APP_BASE_URL}/confirm-forgot-password`,
    apiData
  );
  return data;
};

const deleteNode = async (id) => {
  const { data } = await request.delete(
    `${process.env.REACT_APP_BASE_URL}/node/${id}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const updateNode = async (apiData) => {
  const { data } = await request.put(
    `${process.env.REACT_APP_BASE_URL}/node`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const deleteTraceableNode = async (id) => {
  const { data } = await request.delete(
    `${process.env.REACT_APP_BASE_URL}/untraceable_node/${id}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};
const updateTraceableNode = async (apiData) => {
  const { data } = await request.put(
    `${process.env.REACT_APP_BASE_URL}/untraceable_node`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getTemplate = async (pageSize, currentPage) => {
  const { data } = await request.get(
    pageSize === undefined && currentPage === undefined
      ? `${process.env.REACT_APP_BASE_URL}/template?pagination=false`
      : `${process.env.REACT_APP_BASE_URL}/template?page_size=${pageSize}&page=${currentPage}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const addTemplate = async (apiData) => {
  const { data } = await request.post(
    `${process.env.REACT_APP_BASE_URL}/template`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const copyResource = async (apiData) => {
  const { data } = await request.post(
    `${process.env.REACT_APP_BASE_URL}/group-from-template`,
    apiData,
    { headers: { Authorization: localStorage.getItem("token") } }
  );
  return data;
};

const deleteTemplate = async (id) => {
  const { data } = await request.delete(
    `${process.env.REACT_APP_BASE_URL}/template/${id}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const updateTemplate = async (apiData, id) => {
  const { data } = await request.put(
    `${process.env.REACT_APP_BASE_URL}/template/${id}`,
    apiData,
    { headers: { Authorization: localStorage.getItem("token") } }
  );
  return data;
};

const updateTemplateNode = async (apiData) => {
  const { data } = await request.put(
    `${process.env.REACT_APP_BASE_URL}/template-node`,
    apiData,
    { headers: { Authorization: localStorage.getItem("token") } }
  );
  return data;
};

const addTemplateNode = async (apiData) => {
  const { data } = await request.post(
    `${process.env.REACT_APP_BASE_URL}/template-node/`,
    apiData,
    { headers: { Authorization: localStorage.getItem("token") } }
  );
  return data;
};

const deleteTemplateNode = async (id) => {
  const { data } = await request.delete(
    `${process.env.REACT_APP_BASE_URL}/template-node/${id}`,
    { headers: { Authorization: localStorage.getItem("token") } }
  );
  return data;
};

const saveForm = async (apiData) => {
  const { data } = await request.post(
    `${process.env.REACT_APP_BASE_URL}/client-form`,
    apiData,
    { headers: { Authorization: localStorage.getItem("token") } }
  );
  return data;
};

const getSavedForm = async () => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/client-form`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const deleteForm = async (id) => {
  const { data } = await request.delete(
    `${process.env.REACT_APP_BASE_URL}/client-form/${id}`,
    { headers: { Authorization: localStorage.getItem("token") } }
  );
  return data;
};

const updateForm = async (apiData) => {
  const { data } = await request.patch(
    `${process.env.REACT_APP_BASE_URL}/client-form`,
    apiData,
    { headers: { Authorization: localStorage.getItem("token") } }
  );
  return data;
};

const nodeFilter = async (id) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/node-filter/${id}?group_id=${3}`,
    { headers: { Authorization: localStorage.getItem("token") } }
  );
  return data;
};

const nodeEntityFilter = async (id) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/node-filter/${id}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const nodeTableData = async () => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/node-status-data/${17}?group_id=${3}`,
    { headers: { Authorization: localStorage.getItem("token") } }
  );
  return data;
};

const scheduleDowntime = async (apiData) => {
  const { data } = await request.post(
    `${process.env.REACT_APP_BASE_URL}/schedule-downtime`,
    apiData,
    { headers: { Authorization: localStorage.getItem("token") } }
  );
  return data;
};

const editScheduleDowntime = async (apiData) => {
  const { data } = await request.put(
    `${process.env.REACT_APP_BASE_URL}/schedule-downtime`,
    apiData,
    { headers: { Authorization: localStorage.getItem("token") } }
  );
  return data;
};

const getScheduleDowntime = async (id) => {
  if (id) {
    const { data } = await request.get(
      `${process.env.REACT_APP_BASE_URL}/schedule-downtime?entity_id=${id}`,
      { headers: { Authorization: localStorage.getItem("token") } }
    );
    return data;
  }
};

const getScheduleDowntimeForSingleDevice = async (entityId, id) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/schedule-downtime?entity_id=${entityId}&node_id=${id}`,
    { headers: { Authorization: localStorage.getItem("token") } }
  );
  return data;
};

const getGroupList = async (id) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/all_groups/${id}`,
    { headers: { Authorization: localStorage.getItem("token") } }
  );
  return data;
};

const getLiveDashboardNodeData = async (id, nodeTypeId) => {
  const { data } = await request.get(
    nodeTypeId
      ? `${process.env.REACT_APP_BASE_URL}/node_health_with_all_groups/${id}/?nodetype_id=${nodeTypeId}`
      : `${process.env.REACT_APP_BASE_URL}/node_health_with_all_groups/${id}`,
    { headers: { Authorization: localStorage.getItem("token") } }
  );
  return data;
};

const getEntityDashboardList = async (id) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/group-node-status-data/${id}`,
    { headers: { Authorization: localStorage.getItem("token") } }
  );
  return data;
};

const getEntityDashboardCard = async (id) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/group-node-filter/${id}`,
    { headers: { Authorization: localStorage.getItem("token") } }
  );
  return data;
};

const getEntityNodeFilter = async (id) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/entity-node-filter/${id}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const exportNodes = async (entityId) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/export_nodes?entity_id=${entityId}`,
    { headers: { Authorization: localStorage.getItem("token") } }
  );
  return data;
};

const importNodes = async (apiData, id) => {
  const { data } = await request.post(
    `${process.env.REACT_APP_BASE_URL}/import_nodes/${id}`,
    apiData,
    { headers: { Authorization: localStorage.getItem("token") } }
  );
  return data;
};

const nodeDetails = async (id, nodeId) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/node_details/${id}?node_id=${nodeId}`,
    { headers: { Authorization: localStorage.getItem("token") } }
  );
  return data;
};

const nodeDonutDetails = async (id, nodeId) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/node_trends_range_wise/${id}?node_id=${nodeId}`,
    { headers: { Authorization: localStorage.getItem("token") } }
  );
  return data;
};

const nodeBarDetails = async (id, nodeId) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/node_trends_hours_wise/${id}?node_id=${nodeId}`,
    { headers: { Authorization: localStorage.getItem("token") } }
  );
  return data;
};

const getScheduleDowntimeList = async (id, type, status) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/get-downtime-for-type?downtime_type=${type}&entity_id=${id}&status=${status}`,
    { headers: { Authorization: localStorage.getItem("token") } }
  );
  return data;
};

const ApiService = {
  loginUser,
  getClientList,
  getEntityList,
  regenerateToken,
  getIndividualEntity,
  addClient,
  getLiveDashboardNodeData,
  addEntity,
  deleteClient,
  deleteEntity,
  editClient,
  editEntity,
  getGroup,
  getUntraceableGroup,
  addGroup,
  getNodeType,
  uploadImage,
  deleteNodeType,
  addNodeType,
  editNodeType,
  deleteGroup,
  getEntityNameList,
  getNode,
  adduntraceableNode,
  updateTraceableNode,
  deleteTraceableNode,
  addNode,
  updateGroup,
  forgotPassword,
  confirmPassword,
  deleteNode,
  updateNode,
  getTemplate,
  addTemplate,
  generateLicense,
  copyResource,
  deleteTemplate,
  updateTemplate,
  updateTemplateNode,
  addTemplateNode,
  deleteTemplateNode,
  getSavedForm,
  saveForm,
  deleteForm,
  updateForm,
  nodeFilter,
  nodeEntityFilter,
  getAllEntityList,
  nodeTableData,
  scheduleDowntime,
  editScheduleDowntime,
  getScheduleDowntime,
  getScheduleDowntimeForSingleDevice,
  getGroupList,
  getEntityDashboardList,
  getEntityDashboardCard,
  exportNodes,
  importNodes,
  getEntityNodeFilter,
  nodeDetails,
  nodeDonutDetails,
  nodeBarDetails,
  getScheduleDowntimeList,
};

export default ApiService;
