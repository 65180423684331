import { QUERY_KEY } from "constants/globalConstants";
import UserService from "apiService/permissions";
import { useQuery } from "react-query";

const useGetPermissions = (pageSize, currentPage) => {
  return useQuery(
    QUERY_KEY.GET_PERMISSIONS,
    () => UserService.getPermission(pageSize, currentPage),
    { retry: false }
  );
};

export default useGetPermissions;
