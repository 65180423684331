import React, { useState, useEffect } from "react";
import Card from "components/card/Card.js";
import {
  Box,
  Flex,
  Icon,
  ListItem,
  Spinner,
  OrderedList,
  Button,
  Text,
} from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import EntityClientHooks from "hooks/entityClient/entityClientHooks";
import { FiDownload } from "react-icons/fi";
import { GenerateLicenseIcon } from "components/icons/Icons";

const InstallClient = () => {
  const entityId = useParams().id;
  const [enabled, setEnabled] = useState(false);
  const [licenseData, setLicenseData] = useState(null);
  const { mutate: generateLicense, isLoading } =
    EntityClientHooks.useCheckLicense(setLicenseData);
  const {
    data,
    isLoading: isDownloadLoading,
    error,
  } = EntityClientHooks.useDownloadInstallClient(enabled, setEnabled);

  const handleGenerateLicense = () => {
    generateLicense({ entity_id: entityId });
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card mt="20px">
        <OrderedList>
          <ListItem px="5px" py="5px">
            Open terminal
          </ListItem>
          <ListItem px="5px" py="5px">
            Do : curl -sSf
            "https://o89u2tozze.execute-api.ap-south-1.amazonaws.com/cp/url_for_install_script"
            | sh
          </ListItem>
          <ListItem px="5px" py="5px">
            Do : curl -sSf
            "https://o89u2tozze.execute-api.ap-south-1.amazonaws.com/cp/url_for_install_script"
            | sh -s entity_id .[Example Do : curl -sSf
            "https://o89u2tozze.execute-api.ap-south-1.amazonaws.com/cp/url_for_install_script"
            | sh -s 1]
          </ListItem>
          <ListItem px="5px" py="5px">
            Open adjacent terminal
          </ListItem>
          <ListItem px="5px" py="5px">
            Do : cd /opt/auriga1/applications/ndms-desktop
          </ListItem>
          <ListItem px="5px" py="5px">
            Do : sudo su ndmsdesktopuser
          </ListItem>
          <ListItem px="5px" py="5px">
            {isLoading ? (
              <Spinner color="ndmsColors.purple.200" />
            ) : (
              <>
                {licenseData ? (
                  <Flex direction="column">
                    <Text fontWeight="bold" fontSize="18px">
                      Env File :-
                    </Text>
                    {Object.entries(licenseData).map((data) => (
                      <Text>
                        {data[0]} : {data[1]}
                      </Text>
                    ))}
                    {/* <Button
                      size="xs"
                      onClick={handleCopy}
                      variant="brand"
                      borderRadius="6px"
                      width="max-content"
                    >
                      Copy
                    </Button> */}
                  </Flex>
                ) : (
                  <Flex
                    alignItems="center"
                    cursor="pointer"
                    onClick={handleGenerateLicense}
                  >
                    <GenerateLicenseIcon />
                    <Text
                      fontWeight="bold"
                      pl="5px"
                      color="#4318FF"
                      fontSize="15px"
                    >
                      Generate License
                    </Text>
                  </Flex>
                )}
              </>
            )}
          </ListItem>
          <ListItem px="5px" py="5px">
            Copy the content of env file
          </ListItem>
          <ListItem px="5px" py="5px">
            In terminal do : nano .env
          </ListItem>
          <ListItem px="5px" py="5px">
            Paste the contents
          </ListItem>
          <ListItem px="5px" py="5px">
            DO : source
            /opt/auriga1/applications/enviornments/ndms-desktop/bin/activate
          </ListItem>
          <ListItem px="5px" py="5px">
            In Terminal Do python . setup
          </ListItem>
          <ListItem px="5px" py="5px">
            Do : crontab -e
          </ListItem>
          <ListItem px="5px" py="5px">
            Paste the following lines
          </ListItem>
          <ListItem px="5px" py="5px">
            {`*/5 * * * * /opt/auriga1/applications/ndms-desktop/shell/ping-devices.sh >> /dev/null 2>&1
`}
          </ListItem>
          <ListItem px="5px" py="5px">
            {`*/15 * * * * /opt/auriga1/applications/ndms-desktop/shell/fetch-mappings.sh >> /dev/null 2>&1
`}
          </ListItem>
          <ListItem px="5px" py="5px">
            Do crontab -l to check if the lines you have pasted are present
          </ListItem>
        </OrderedList>
      </Card>
    </Box>
  );
};

export default InstallClient;
