import React from "react";
import Card from "components/card/Card.js";
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Input,
  Textarea,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useHistory } from "react-router";
import { useForm, Controller } from "react-hook-form";
import useUpdateGroupPermission from "hooks/groupPermissions/useUpdateGroupPermission";
import Select from "react-select";

const EditPermissionGroup = ({ setShowEditForm, editData }) => {
  const textColor = useColorModeValue("navy.700", "white");
  let activeColor = useColorModeValue("gray.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const buttonHover = useColorModeValue("brand.600", "brand.400");
  const { mutate: updateGroupPermission, isLoading } =
    useUpdateGroupPermission(setShowEditForm);
  const history = useHistory();
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    updateGroupPermission({
      name: data.name,
      slug: data.slug,
      description: data.description ? data.description : "",
      id: editData.id,
    });
  };

  const handleCancel = () => {
    history.push(`/admin/permission-group`);
    setShowEditForm(false);
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card>
        <Flex
          maxW={{ base: "100%", md: "max-content" }}
          w="100%"
          mx={{ base: "auto", lg: "0px" }}
          me="auto"
          h="100%"
          alignItems="start"
          justifyContent="center"
          mb={{ base: "30px", md: "30px" }}
          px={{ base: "25px", md: "0px" }}
          mt={{ base: "20px", md: "20px" }}
          flexDirection="column"
        >
          <Box me="auto">
            <Text
              mb="24px"
              ms="4px"
              color={activeColor}
              fontWeight="bold"
              fontSize="24px"
            >
              Edit Permissions
            </Text>
          </Box>
          <Flex
            zIndex="2"
            direction="column"
            w={{ base: "100%", md: "420px" }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: "auto", lg: "unset" }}
            me="auto"
            mb={{ base: "20px", md: "auto" }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Name<Text color={brandStars}>*</Text>
              </FormLabel>
              <div style={{ marginBottom: "24px" }}>
                <Controller
                  name="name"
                  rules={{
                    required: true,
                    validate: (value) => {
                      return !!value.trim();
                    },
                  }}
                  control={control}
                  defaultValue={editData?.name}
                  render={({ field }) => (
                    <Input
                      fontSize="sm"
                      ms={{ base: "0px", md: "0px" }}
                      type="name"
                      placeholder="Enter Name"
                      //mb="24px"
                      fontWeight="500"
                      size="lg"
                      {...field}
                    />
                  )}
                />
                {errors.name && (
                  <span style={{ color: "red" }}>name is required</span>
                )}
              </div>

              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Slug<Text color={brandStars}>*</Text>
              </FormLabel>
              <div style={{ marginBottom: "24px" }}>
                <Controller
                  name="slug"
                  rules={{
                    required: true,
                    validate: (value) => {
                      return !!value.trim();
                    },
                  }}
                  control={control}
                  defaultValue={editData?.slug}
                  render={({ field }) => (
                    <Input
                      fontSize="sm"
                      ms={{ base: "0px", md: "0px" }}
                      type="name"
                      placeholder="Enter Slug"
                      //mb="24px"
                      fontWeight="500"
                      size="lg"
                      {...field}
                    />
                  )}
                />
                {errors.slug && (
                  <span style={{ color: "red" }}>slug is required</span>
                )}
              </div>

              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Description
              </FormLabel>
              <div style={{ marginBottom: "24px" }}>
                <Controller
                  name="description"
                  rules={{
                    required: false,
                  }}
                  control={control}
                  defaultValue={editData?.description}
                  render={({ field }) => (
                    <Textarea placeholder="Enter Description" {...field} />
                  )}
                />
              </div>

              <Flex>
                <Button
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  w="90%"
                  h="50"
                  mb="24px"
                  mr="20px"
                  type="submit"
                  isLoading={isLoading}
                  _hover={{ bg: isLoading ? "blue" : buttonHover }}
                >
                  Submit
                </Button>
                <Button
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  w="90%"
                  h="50"
                  mb="24px"
                  onClick={handleCancel}
                >
                  Cancel
                </Button>
              </Flex>
            </form>
          </Flex>
        </Flex>
      </Card>
    </Box>
  );
};

export default EditPermissionGroup;
