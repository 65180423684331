import React, { useState, useEffect } from "react";

import { useDrop } from "react-dnd";
import useGroup from "hooks/useGroup";
import useNodeTypes from "hooks/useNodeTypes";
import NodeCard from "./nodeCard";
import GroupCard from "./groupCard";
import { useForm } from "react-hook-form";
import { Box, Button, Flex, useColorModeValue } from "@chakra-ui/react";
import useDeleteGroup from "hooks/useDeleteGroup";

function Node() {
  // Chakra color mode
  const { isLoading, error, data: groupData } = useGroup();
  const [showCard, setShowCard] = useState(false);
  const [groupName, setGroupName] = useState("");
  const [nodeName, setNodeName] = useState("");
  const [disableAddGroup, setDisableAddGroup] = useState(false);
  const [edit, setEdit] = useState(false);
  const [groups, setGroups] = useState([]);
  const [draggedObject, setDraggedObject] = useState([]);
  const [savedObject, setSavedObject] = useState([]);
  const [showZoom, setShowZoom] = useState(false);

  const { mutate: deleteGroup } = useDeleteGroup();
  const {
    isLoading: isNodeLoading,
    error: nodeError,
    data: nodeTypeData,
  } = useNodeTypes();

  useEffect(() => {
    if (groupData?.length > 0) {
      setGroups(groupData);
    }
  }, [groupData]);

  const [{ isOver }, dropRef] = useDrop({
    accept: "card",
    //drop: (item)=>setModalOpen(true),
    drop: (item) => {
      // setModalOpen(true)
      //setZoom(true)
      setNodeName(item.name);
      setDraggedObject((draggedObject) =>
        !draggedObject.includes(item) ? [...draggedObject, item] : draggedObject
      );
      setTimeout(() => setShowZoom(true), 1000);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      didDrop: monitor.didDrop(),
    }),
  });

  const addCard = () => {
    groupData?.length === 0 && setDisableAddGroup(true);
    // create a new array with the old values and the new random one
    const newCards = [...groups, Math.floor(Math.random() * 100)];

    setGroups(newCards);
    //setEdit(true)
  };

  return (
    <>
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <div
          style={{
            paddingLeft: "5px",
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <Flex>
            {nodeTypeData?.map((nodeType) => (
              <NodeCard draggable id={nodeType.id} name={nodeType.name} />
            ))}
          </Flex>
        </div>

        {groups.length > 0 &&
          groups?.map((group, index) => (
            <GroupCard
              groupName={group.name}
              setDisableAddGroup={setDisableAddGroup}
              index={groups?.length}
              groupId={group.id}
              nodeTypeData={nodeTypeData}
            />
          ))}
        <Button
          fontSize="sm"
          variant="brand"
          fontWeight="500"
          isDisabled={disableAddGroup}
          w="100%"
          h="50"
          mt="24px"
          mr="20px"
          onClick={() => addCard()}
        >
          Add Group
        </Button>
      </Box>
    </>
  );
}

export default Node;
