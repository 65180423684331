import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Textarea,
  Flex,
  FormLabel,
  Text,
  ModalCloseButton,
  Button,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import ConfirmationModal from "./confirmationModal";
import ApproveDowntimeHooks from "hooks/approvedDowntime/approveDowntimeHooks";

function StatusModal({
  onOpen,
  onClose,
  isOpen,
  id,
  entityStatusId,
  status,
  isNodeLevel,
}) {
  const {
    handleSubmit,
    control,
    resetField,
    formState: { errors },
  } = useForm();
  const textColor = useColorModeValue("navy.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [notes, setNotes] = useState(null);
  const {
    isOpen: confOpen,
    onClose: onConfClose,
    onOpen: onConfOpen,
  } = useDisclosure();

  const handleModalClose = () => {
    resetField("review_notes");
    onClose();
  };

  const { mutate: updateNopdStatus, isLoading } =
    ApproveDowntimeHooks.useUpdateNopdStatus(handleModalClose);

  const onSubmit = (data) => {
    setNotes(data.review_notes);
    onConfOpen();
  };

  const getBg = () => {
    switch (status) {
      case 1:
        return "green";
      case 2:
        return "red";
      default:
        return "green";
    }
  };

  const getText = () => {
    switch (status) {
      case 1:
        return "Approve";
      case 2:
        return "Disapprove";
      default:
        return "Approve";
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        blockScrollOnMount={false}
        onClose={handleModalClose}
        size="lg"
      >
        <ModalOverlay />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContent>
            <ModalHeader>
              <Flex direction="column" w="100%" mt="15px">
                <Flex direction="column" width="100%">
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    isRequired={true}
                    display="flex"
                  >
                    Review Notes<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <div style={{ marginBottom: "24px" }}>
                    <Controller
                      name="review_notes"
                      rules={{ required: status === 2 ? true : false }}
                      control={control}
                      render={({ field }) => (
                        <Textarea
                          fontSize="sm"
                          type="name"
                          placeholder="Enter review notes"
                          fontWeight="500"
                          size="lg"
                          {...field}
                        />
                      )}
                    />
                    {errors.review_notes?.type === "required" && (
                      <span style={{ color: "red" }}>
                        review notes is required
                      </span>
                    )}
                  </div>
                </Flex>
              </Flex>
            </ModalHeader>
            <Button
              bg={getBg()}
              _hover={{ bg: "#2B3674" }}
              mx={3}
              mb={10}
              type="submit"
            >
              <Text color={"#FFFFFF"}>{getText()}</Text>
            </Button>
            <ModalCloseButton />
          </ModalContent>
        </form>
      </Modal>
      <ConfirmationModal
        isOpen={confOpen}
        onOpen={onConfOpen}
        onClose={onConfClose}
        status={status}
        isNodeLevel={isNodeLevel}
        notes={notes}
        id={id}
        entityStatusId={entityStatusId}
        handleModalClose={handleModalClose}
      />
    </>
  );
}

export default StatusModal;
