import { MUTATION_KEY } from "constants/globalConstants";
import { useMutation } from "react-query";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import ApiService from "apiService";

const useLogin = () => {
  const history = useHistory();
  return useMutation(
    [MUTATION_KEY.LOGIN_USER],
    (data) => ApiService.loginUser(data),
    {
      onSuccess: (data) => {
        localStorage.getItem("is_superuser") === "true"
          ? history.push("/admin/user")
          : history.push("/admin/home");
        toast.success("Logged in successfully");
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

export default useLogin;
