import { QUERY_KEY, MUTATION_KEY } from "constants/globalConstants";
import ApiService from "apiService";
import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";

const useAddTemplateNode = () => {
  const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.ADD_TEMPLATE_NODE],
    (data) => ApiService.addTemplateNode(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.GET_TEMPLATE);
        //setShowCreateGroup(false)
        toast.success('Template node added successfully')
      },
      onError: (error) => {
        error.response.data.message ? toast.error(error.response.data.message) : toast.error('internal server error')
      },
    }
  );
};

export default useAddTemplateNode