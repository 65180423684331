import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Divider,
  Circle,
  Select,
  Flex,
  Badge,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import Chart from "react-apexcharts";
import ClientTrends from "./clientTrends";
import { useHistory } from "react-router";
import useClientNodesWorking from "hooks/clientDashboard/useClientNodesWorking";
import ClientDashboardLoader from "./clientDashboardLoader";
import EmptyCard from "components/card/EmptyCard";
import EmptyPieChart from "components/charts/emptyPieChart";
import useRegenerateToken from "hooks/useRegenerateToken";

import Map from "components/map";

const ClientDashboard = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [trendSort, setTrendSort] = useState("desc");
  const [minLimit, setMinLimit] = useState(0);
  const [maxLimit, setMaxLimit] = useState(5);
  const mapVisibility = localStorage.getItem("map_visibility");
  const {
    isLoading,
    error,
    data: nodeClientData,
    refetch,
    isFetching,
  } = useClientNodesWorking(5, currentPage, trendSort);
  const { mutate: regenerateToken } = useRegenerateToken(refetch);

  const defaultProps = {
    center: {
      lat: 25.445,
      lng: 81.8469,
    },
    zoom: 14,
  };

  //const bounds = new GoogleMapReact.LatLngBounds()

  useEffect(() => {
    refetch();
  }, [trendSort]);

  const history = useHistory();

  const pieChartOptions = (data) => {
    return {
      labels: ["Offline", "Online"],
      //colors: ["#219653"],
      chart: {
        width: "10px",
      },
      states: {
        hover: {
          filter: {
            type: "none",
          },
        },
      },
      legend: {
        show: false,
      },
      dataLabels: {
        enabled: false,
      },
      hover: { mode: null },
      plotOptions: {
        pie: {
          size: 200,
          donut: {
            size: "70%",
            labels: {
              show: true,
              name: {
                show: true,
                fontSize: "22px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 600,
                color: undefined,
                offsetY: -10,
                formatter: function (val) {
                  return val;
                },
              },
              value: {
                show: true,
                fontSize: "16px",
                fontFamily: "Helvetica, Arial, sans-serif",
                fontWeight: 400,
                color: undefined,
                offsetY: 16,
                formatter: function (val) {
                  return val;
                },
              },
              total: {
                show: true,
                label: `${data.health_average.toFixed(0)} %`,
                color: "#2B3674",
                total: 12,
                size: "12px",
                formatter: function () {
                  return `${data.healthy_nodes} / ${data.total_nodes}`;
                },
              },
            },
          },
        },
      },
      //series: [8, 4, 10, 2],
      fill: {
        colors:
          data.healthy_nodes >= data.total_nodes / 2
            ? ["#EAEDF2", "#3DCA78"]
            : ["#EAEDF2", "#DC5D5D"],
      },
      tooltip: {
        enabled: true,
        theme: "dark",
      },
    };
  };

  if (isLoading || isFetching) {
    return <ClientDashboardLoader />;
  }
  const pieChartData = (data) => {
    return [data.unhealthy_nodes, data.healthy_nodes];
  };

  const handlePageIncrease = () => {
    // if (currentPage === nodeClientData?.data[0]?.total_pages) {
    //   setCurrentPage(1);
    // } else {
    //   setCurrentPage(currentPage + 1);
    // }
    const total = nodeClientData?.data[0]?.result?.length;
    if (maxLimit >= total) {
      setMinLimit(0);
      setMaxLimit(5);
    } else {
      setMinLimit(minLimit + 5);
      setMaxLimit(maxLimit + 5);
    }
  };

  const handlePageDecrease = () => {
    // if (currentPage === 1) {
    //   setCurrentPage(nodeClientData?.data[0]?.total_pages);
    // } else {
    //   setCurrentPage(currentPage - 1);
    // }
    //const total = nodeClientData?.data[0]?.result?.length;
    if (minLimit <= 0) {
      setMinLimit(minLimit + 5);
      setMaxLimit(maxLimit + 5);
    } else {
      setMinLimit(minLimit - 5);
      setMaxLimit(maxLimit - 5);
    }
  };

  if (error && error.response.data.message === "Token is invalid !!") {
    regenerateToken({
      refresh_token: localStorage.getItem("refresh_token"),
      email: localStorage.getItem("email"),
    });
    return;
  }

  const handleTrendingChange = (e) => {
    setTrendSort(e.target.value);
  };

  return (
    <Box pt={{ base: "130px", md: "90px", xl: "90px" }} pb="50px">
      <Flex alignItems="center" width="100%">
        <Flex
          direction="column"
          pt="10px"
          width={mapVisibility?.toLowerCase() === "show" ? "25%" : "100%"}
        >
          <Text fontSize="24px" mb="20px" fontWeight="bold" color="#2B3674">
            Performance Trends
          </Text>
          <Flex
            direction={
              mapVisibility?.toLowerCase() === "show" ? "column" : "row"
            }
            pt="10px"
          >
            <Card
              //minWidth="280px"
              boxShadow="0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
              mb="20px"
              cursor="pointer"
              borderRadius="7px"
              mr={mapVisibility?.toLowerCase() !== "show" && "15px !important"}
              onClick={() => history.push("/admin/entities/:status")}
            >
              <Flex direction={"column"}>
                <Text fontSize="18px" fontWeight="bold" color="#7B818A">
                  Total Entities
                </Text>
                <Flex alignItems="center" justifyContent="space-between">
                  <Text fontSize="28px" fontWeight="bold">
                    {nodeClientData?.data[0]?.total_entities}
                  </Text>
                  <Circle size="20px" bg="white" color="black">
                    {">"}
                  </Circle>
                </Flex>
              </Flex>
            </Card>
            <Card
              //minWidth="280px"
              //mx="10px"
              boxShadow="0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
              borderRadius="7px"
              mb="20px"
              mr={mapVisibility?.toLowerCase() !== "show" && "15px !important"}
              cursor="pointer"
              bg="#EDFFF6"
              onClick={() => history.push("/admin/entities/synced")}
            >
              <Flex direction="column">
                <Text fontSize="18px" fontWeight="bold" color="#7B818A">
                  Syncing Entities
                </Text>
                <Flex alignItems="center" justifyContent="space-between">
                  <Text fontSize="28px" fontWeight="bold">
                    {nodeClientData?.data[0]?.synced_entities}
                  </Text>
                  <Circle size="25px" bg="white" color="black">
                    {">"}
                  </Circle>
                </Flex>
              </Flex>
            </Card>
            <Card
              //minWidth="280px"
              borderRadius="7px"
              mb="20px"
              boxShadow="0px 2px 1px -1px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)"
              cursor="pointer"
              bg="#FFEBF0"
              onClick={() => history.push("/admin/entities/unsynced")}
            >
              <Flex direction="column">
                <Text fontSize="18px" fontWeight="bold" color="#7B818A">
                  Non - Syncing Entities
                </Text>
                <Flex alignItems="center" justifyContent="space-between">
                  <Text fontSize="28px" fontWeight="bold">
                    {nodeClientData?.data[0]?.unsycned_entities}
                  </Text>
                  <Circle size="25px" bg="white" color="black">
                    {">"}
                  </Circle>
                </Flex>
              </Flex>
            </Card>
          </Flex>
        </Flex>
        {mapVisibility?.toLowerCase() === "show" && (
          <Flex direction="column" ml="20px" width="75%">
            <Text fontSize="24px" pt="15px" fontWeight="bold" color="#2B3674">
              All Entities
            </Text>
            <Box
              height="370px"
              mt="20px"
              borderRadius="13px !important"
              overflow="hidden"
            >
              <Map data={nodeClientData} />
            </Box>
          </Flex>
        )}
      </Flex>
      <Flex mt="30px" alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <Badge
            border="1px solid #F86689"
            borderRadius="13px"
            width="100px"
            textTransform="capitalize"
            bg="#FFEBF0"
            color="#F86689"
            height="24px"
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Circle mr="10px" size="8px" bg="#F86689" color="white" />
            Live
          </Badge>
          <Text fontSize="26px" pl="10px" fontWeight="bold" color="#2B3674">
            Entity Health
          </Text>
        </Flex>
        <Select
          bg="white"
          fontSize="15px"
          //mt="20px"
          width="200px"
          height="40px"
          id="user_type"
          size="lg"
          ml="10px"
          color="#2C3774"
          fontWeight="bold"
          value={trendSort}
          onChange={handleTrendingChange}
        >
          <option value="desc">Top 5</option>
          <option value="asc">Bottom 5</option>
        </Select>
      </Flex>
      <Card
        mt="10px"
        display="flex"
        direction="row"
        height="300px"
        position="relative"
      >
        {nodeClientData?.data[0]?.result?.length > 0 ? (
          <Flex height="400px" p={0} width="100%">
            {nodeClientData?.data[0]?.result?.length > 5 ? (
              <Text
                cursor="pointer"
                my="auto"
                onClick={handlePageDecrease}
                fontSize="30px"
              >
                {"<"}
              </Text>
            ) : null}
            {nodeClientData?.data[0]?.result
              ?.slice(minLimit, maxLimit)
              ?.map((data, i) => (
                <Flex
                  width={`${
                    nodeClientData?.data[0]?.result?.slice(minLimit, maxLimit)
                      ?.length * 10
                  }%`}
                >
                  <Flex direction="column" mx="10px">
                    {data?.total_nodes === 0 ? (
                      <EmptyPieChart height="190px" />
                    ) : (
                      <Chart
                        height="190px"
                        width="100%"
                        type="donut"
                        series={pieChartData(data)}
                        options={pieChartOptions(data)}
                      />
                    )}
                    <Flex
                      alignItems="flex-start"
                      onClick={() =>
                        history.push(
                          `/admin/entity-dashboard/${data.id}/${data.name}`
                        )
                      }
                      mx="10px"
                      justifyContent="space-between"
                    >
                      <Flex
                        direction="column"
                        cursor="pointer"
                        alignItems="center"
                        mx="auto"
                      >
                        <Text
                          noOfLines={2}
                          color={"ndmsColors.blue.100"}
                          fontWeight="bold"
                          textAlign="center"
                          fontSize="14px"
                        >
                          {data.name}
                        </Text>

                        <Text
                          noOfLines={2}
                          textAlign="center"
                          color="#7B818A"
                          //pl="20px"
                          fontSize="12px"
                        >
                          {`${data.Address.city}, ${data.Address.state}`}
                        </Text>
                      </Flex>
                      {/* <Circle
                        size="25px"
                        bg="ndmsColors.gray.1300"
                        color="ndmsColors.blue.100"
                      >
                        {">"}
                      </Circle> */}
                    </Flex>
                  </Flex>

                  {i < nodeClientData?.data[0]?.result?.length - 1 &&
                    nodeClientData?.data[0]?.result?.length > 1 &&
                    (i + 1) % 5 !== 0 && (
                      <Divider
                        bg="#E0E0E0"
                        border="1px solid #E0E0E0"
                        width="1px"
                        orientation="vertical"
                      />
                    )}
                </Flex>
              ))}
            {nodeClientData?.data[0]?.result?.length > 5 ? (
              <Text
                fontSize="30px"
                my="auto"
                onClick={handlePageIncrease}
                cursor="pointer"
                float="right"
                ml="auto"
              >
                {">"}
              </Text>
            ) : null}
          </Flex>
        ) : (
          <Box mt="60px">
            <EmptyCard />
          </Box>
        )}
      </Card>
      <ClientTrends />
    </Box>
  );
};

export default ClientDashboard;
