import { Box } from "@chakra-ui/layout";

export const GetEmptyBox = (props) => {
  const { object = {} } = props || {};
  return (
    <>
      {object > 3 &&
        3 - (object % 3) < 3 &&
        [...Array(3 - (object % 3))].map((data, index) => {
          return (
            <Box
              key={index + "val"}
              className={`grid-item ${
                index === 0 && 3 - (object % 3) !== 1
                  ? "empty-middle"
                  : "empty-end"
              }`}
              p="30px 44px 30px 44px"
            ></Box>
          );
        })}
    </>
  );
};
