import { QUERY_KEY, MUTATION_KEY } from "constants/globalConstants";
import ApiService from "apiService";
import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";

const useAddEntity = (setShowAddForm) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.ADD_ENTITY],
    (data) => ApiService.addEntity(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.GET_ENTITY_LIST);
        setShowAddForm(false)
        toast.success('Entity added successfully')
      },
      onError: (error) => {
        toast.error(error.response.data.message)
    },
    }
  );
};

export default useAddEntity