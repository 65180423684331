import React, { useEffect, useState } from "react";
import { Box, Text } from "@chakra-ui/layout";
import { Select } from "@chakra-ui/select";
import { Flex } from "@chakra-ui/react";

function LiveDashboardFilter(props) {
  const [selectedNodeType, setSelectedNodeType] = useState(null);

  const { nodeTypeList, setNodeTypeId,blackout } = props;

  const onHandleChange = (e) => {
    setSelectedNodeType(e.target.value);
  };

  useEffect(() => {
    setNodeTypeId(selectedNodeType);
  }, [selectedNodeType]);

  return (
    <Box mt="35px" ml="20px">
      <Flex alignItems="center">
        <Flex>
          <Text color="#828282" fontSize="16px" fontWeight="bold">
            Node Type:
          </Text>
        </Flex>
        <Flex alignItems="center">
          <Select
            bg="#F4F7FF"
            fontSize="15px"
            width="198px"
            height="2.5rem"
            ml="14px"
            id="user_type"
            size="lg"
            mr="10px"
            color="#2B3674"
            fontWeight="bold"
            value={selectedNodeType}
            onChange={onHandleChange}
            placeholder="All"
            disabled={blackout?true:false}
          >
            {nodeTypeList?.data[0]?.result?.map((list) => (
              <option key={list.id} value={list.id}>
                {list.name}
              </option>
            ))}
          </Select>
        </Flex>
      </Flex>
    </Box>
  );
}

export default LiveDashboardFilter;
