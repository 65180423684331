import React, { useEffect } from "react";
import {
  Box,
  Text,
  Flex,
  Divider,
  Button,
  Link,
  Badge,
  Select,
  Skeleton,
  useDisclosure,
} from "@chakra-ui/react";
import dayjs from "dayjs";
import TmsTimeLogs from "./timeLogs";
import { useParams } from "react-router";
import TmsFollowup from "./tmsFollowup";
import { getActiveSince } from "Permissions";
import Card from "components/card/Card.js";
import TicketModuleHooks from "hooks/ticketModule/ticketModuleHooks";
import MarkAsClosedModal from "components/modal/markAsClosedModal";
import { useForm, Controller } from "react-hook-form";
import UserAssignModal from "components/modal/userAssignModal";
import { getDashboardPermissions } from "Permissions";

const TicketSummary = () => {
  const ticketId = useParams()?.id;
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const { data, isLoading, error, refetch, isFetching } =
    TicketModuleHooks.useTicketDetails(ticketId);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isAssignOpen,
    onOpen: onAssignOpen,
    onClose: onAssignClose,
  } = useDisclosure();

  const {
    data: ticketStatusData,
    isLoading: ticketStatusLoading,
    error: ticketStatusError,
  } = TicketModuleHooks.useTicketStatus();

  const { mutate: updateAction, isLoading: actionLoading } =
    TicketModuleHooks.useUpdateAction(ticketId);

  useEffect(() => {
    refetch();
  }, [ticketId]);

  const sumData = {
    date: "Follow Up due today at 5:30 PM",
    id: 256,
    name: "One way at Bada Naya Gaanv, Bundi",
    created_by: "Johrawar Singh",
    created_at: "2022/11/10 15:00:00",
    assigned_to: "Jay Yadav",
    status: "low",
    active_since: "48 days",
    level: 1,
  };

  const generateStatusOptions = () => {
    return ticketStatusData?.data?.map((status) => {
      return <option value={status?.id}>{status?.name}</option>;
    });
  };

  const getPriorityColor = (priority) => {
    switch (priority) {
      case 1:
        return "#019F79";
      case 2:
        return "#F09205";
      case 3:
        return "#FA5E5E";
      default:
        return "#019F79";
    }
  };

  const getPriorityName = (priority) => {
    switch (priority) {
      case 1:
        return "LOW";
      case 2:
        return "MEDIUM";
      case 3:
        return "HIGH";
      default:
        return "LOW";
    }
  };

  const getDate = (date) => {
    return `${dayjs(date).format("DD MMM YYYY")} at ${dayjs(date).format(
      "hh:mm a"
    )}`;
  };

  const onSubmit = (data) => {
    updateAction({
      status: data.status,
      priority: data.priority,
    });
  };
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      {data?.data[0]?.is_closed ? null : (
        <Flex>
          {getDashboardPermissions("ticketing.ticket.assign_users") ? (
            <Button
              color="#4F46BA"
              borderRadius="6px"
              borderColor="#4F46BA"
              bg="white"
              variant="outline"
              onClick={() => onAssignOpen()}
            >
              {data?.data[0]?.assigned_to ? "Re-assign" : "Assign"}
            </Button>
          ) : null}

          {getDashboardPermissions("ticketing.ticket.close") ? (
            <Button
              variant="brand"
              borderRadius="6px"
              ml="10px"
              onClick={() => onOpen()}
            >
              Mark as Closed
            </Button>
          ) : null}
        </Flex>
      )}
      <Flex mt="20px">
        <Flex direction="column" width="70%">
          <Text fontSize="20px" fontWeight="bold">
            Ticket details
          </Text>
          {isLoading || isFetching ? (
            <Card mt="20px" height="420px">
              <Skeleton height="400px" />
            </Card>
          ) : (
            <Card
              mt="20px"
              pb="30px"
              //height="320px"
              width="100%"
              border="1px solid #EAEDF2"
              borderRadius="10px"
            >
              <Flex alignItems="center">
                <Badge
                  borderRadius="3px"
                  width="max-content"
                  variant="solid"
                  fontSize="12px"
                  mr="10px"
                  bg={"black"}
                >
                  <Text color="white">
                    {data?.data[0]?.ticket_status?.name}
                  </Text>
                </Badge>
                <Badge
                  borderRadius="3px"
                  width="max-content"
                  variant="solid"
                  fontSize="12px"
                  bg={getPriorityColor(data?.data[0]?.priority)}
                >
                  {getPriorityName(data?.data[0]?.priority)}
                </Badge>
              </Flex>
              <Flex py="5px">
                <Text
                  color="ndmsColors.gray.2200"
                  fontWeight="600"
                  fontSize="18px"
                >
                  #{data?.data[0]?.id}
                </Text>
                <Text pl="5px" fontWeight="600" fontSize="18px">
                  {data?.data[0]?.title}
                </Text>
              </Flex>
              <Flex alignItems="center">
                <Box border="1px solid #EAEDF2" borderRadius="10px" mr="10px">
                  <Text
                    color="ndmsColors.gray.2200"
                    fontWeight="500"
                    fontSize="14px"
                    px="10px"
                    py="5px"
                  >
                    {data?.data[0]?.ticket_type_name?.name}
                  </Text>
                </Box>

                {data?.data[0]?.node ? (
                  <Box border="1px solid #EAEDF2" borderRadius="10px" mr="10px">
                    {getDashboardPermissions("node_dashboard.view") ? (
                      <Link
                        href={`/admin/node-dashboard/${data?.data[0]?.entity_id}/${data?.data[0]?.entity?.name}/node_id=${data?.data[0]?.node_id}`}
                      >
                        <Text
                          color="ndmsColors.gray.2200"
                          fontWeight="500"
                          fontSize="14px"
                          px="10px"
                          py="5px"
                        >
                          {data?.data[0]?.node?.name}
                        </Text>
                      </Link>
                    ) : (
                      <Text
                        color="ndmsColors.gray.2200"
                        fontWeight="500"
                        fontSize="14px"
                        px="10px"
                        py="5px"
                      >
                        {data?.data[0]?.node?.name}
                      </Text>
                    )}
                  </Box>
                ) : null}
                {data?.data[0]?.entity_id ? (
                  <Box bg="#F4F4F4" mr="10px">
                    <Link
                      href={`/admin/entity-dashboard/${data?.data[0]?.entity_id}/${data?.data[0]?.entity?.name}`}
                    >
                      <Text
                        color="ndmsColors.gray.2200"
                        fontWeight="500"
                        fontSize="14px"
                        px="10px"
                        py="5px"
                      >
                        {data?.data[0]?.entity?.name}
                      </Text>
                    </Link>
                  </Box>
                ) : null}
                <Text
                  color="ndmsColors.gray.2200"
                  //ml="10px"
                  fontWeight="500"
                  fontSize="14px"
                >
                  {getActiveSince(data?.data[0]?.created_at)}
                </Text>
              </Flex>
              <Divider py="10px" />
              <Flex>
                <Flex direction="column" mt="10px">
                  <Text
                    color="ndmsColors.gray.2200"
                    fontWeight="500"
                    fontSize="12px"
                  >
                    Created by:
                  </Text>
                  <Text fontWeight="600" fontSize="14px">
                    {data?.data[0].created_by?.user_fname}{" "}
                    {data?.data[0].created_by?.user_lname}
                  </Text>
                  <Text
                    color="ndmsColors.gray.1900"
                    fontWeight="600"
                    fontSize="14px"
                  >
                    {getDate(data?.data[0]?.created_at)}
                  </Text>
                </Flex>
                <Box height="50px" mt="10px" mx="20px">
                  <Divider orientation="vertical" />
                </Box>
                {data?.data[0]?.assigned_to ? (
                  <Flex direction="column" mt="10px">
                    <Text
                      color="ndmsColors.gray.2200"
                      fontWeight="500"
                      fontSize="12px"
                    >
                      Assigned to:
                    </Text>
                    <Text fontWeight="600" fontSize="14px">
                      {data?.data[0].assigned_to?.user_fname}{" "}
                      {data?.data[0].assigned_to?.user_lname}
                    </Text>
                    <Text
                      color="ndmsColors.gray.1900"
                      fontWeight="600"
                      fontSize="14px"
                    >
                      {getDate(data?.data[0]?.assigned_at)}
                    </Text>
                  </Flex>
                ) : null}
              </Flex>
              {/* <Card
                bg="#F2F5FA"
                mt="10px"
                borderRadius="10px"
                px="10px"
                py="10px"
              >
                <Flex pb="10px">
                  <Box
                    border="1px solid #EAEDF2"
                    bg="white"
                    borderRadius="10px"
                  >
                    <Text
                      color="ndmsColors.gray.1900"
                      fontWeight="500"
                      fontSize="10px"
                      px="10px"
                      py="5px"
                    >
                      VIDS Ticket
                    </Text>
                  </Box>
                  <Box
                    border="1px solid #EAEDF2"
                    bg="white"
                    borderRadius="10px"
                    ml="10px"
                  >
                    <Text
                      color="ndmsColors.gray.1900"
                      fontWeight="500"
                      fontSize="10px"
                      px="10px"
                      py="5px"
                    >
                      Camera 3
                    </Text>
                  </Box>
                </Flex>
                <Flex>
                  <Flex justifyContent="space-between" width="100%">
                    <Flex>
                      <Text
                        color="ndmsColors.gray.2200"
                        fontWeight="600"
                        fontSize="14px"
                      >
                        #{sumData.id}
                      </Text>
                      <Text pl="5px" fontWeight="600" fontSize="14px">
                        {sumData.name}
                      </Text>
                    </Flex>
                    <Text mr="5px" fontSize="15px">
                      {">"}
                    </Text>
                  </Flex>
                </Flex>
                <Text
                  color="ndmsColors.gray.2200"
                  fontWeight="500"
                  fontSize="12px"
                  pb="10px"
                >
                  {`Created by: ${sumData.created_by} on ${getDate(
                    sumData.created_at
                  )}`}
                </Text>
              </Card> */}
              <Text fontSize="20px" fontWeight="bold" mt="20px">
                Notes
              </Text>
              <Text fontSize="12px" color="ndmsColors.gray.1900">
                {data?.data[0]?.note}
              </Text>
              {/* <Flex pt="10px">
                <Text fontSize="14px" fontWeight="500">
                  Karan Hadda
                </Text>
                <Text
                  fontSize="14px"
                  ml="10px"
                  fontWeight="500"
                  color="ndmsColors.gray.2200"
                >
                  (Senior supervisor)
                </Text>
              </Flex>
              <Text
                fontSize="12px"
                fontWeight="500"
                color="ndmsColors.gray.2200"
              >
                2 hours ago (Wed, 21 May’22 at 5:30 PM)
              </Text> */}
            </Card>
          )}
          <TmsTimeLogs
            ticketId={ticketId}
            isClosed={data?.data[0]?.is_closed}
          />
        </Flex>

        <Flex direction="column" ml="20px" width="30%">
          {data?.data[0]?.is_closed ? null : getDashboardPermissions(
              "ticketing.ticket.update_priority"
            ) || getDashboardPermissions("ticketing.ticket.update_status") ? (
            <>
              <Box mb="20px">
                <Text fontSize="20px" fontWeight="bold">
                  Action Items
                </Text>
                <Card
                  mt="20px"
                  pb="30px"
                  height="max-content"
                  width="100%"
                  border="1px solid #EAEDF2"
                  borderRadius="10px"
                >
                  <form onSubmit={handleSubmit(onSubmit)}>
                    {getDashboardPermissions(
                      "ticketing.ticket.update_priority"
                    ) ? (
                      <Flex direction="column" mt="10px">
                        <Text fontSize="14px" fontWeight="500">
                          Priority
                        </Text>
                        <Controller
                          name="priority"
                          rules={{ required: false }}
                          control={control}
                          render={({ field }) => (
                            <Select
                              bg="white"
                              fontSize="14px"
                              height="40px"
                              id="user_type"
                              size="lg"
                              color={
                                field.value === "Choose Priority" ||
                                field.value === null ||
                                field.value === undefined
                                  ? "ndmsColors.gray.2200"
                                  : "black"
                              }
                              //color="ndmsColors.gray.2200"
                              fontWeight={
                                field.value === "Choose Priority" ||
                                field.value === null ||
                                field.value === undefined
                                  ? "bold"
                                  : "400"
                              }
                              mt="5px"
                              //placeholder="Choose Priority"
                              {...field}
                            >
                              <option selected disabled hidden value={null}>
                                Choose Priority
                              </option>
                              <option value={1}>Low</option>
                              <option value={2}>Medium</option>
                              <option value={3}>High</option>
                            </Select>
                          )}
                        />
                        {errors.priority?.type === "" && (
                          <span style={{ color: "red" }}>
                            priority is required
                          </span>
                        )}
                      </Flex>
                    ) : null}
                    {getDashboardPermissions(
                      "ticketing.ticket.update_status"
                    ) ? (
                      <Flex direction="column" mt="10px">
                        <Text fontSize="14px" fontWeight="500">
                          Status
                        </Text>
                        <Controller
                          name="status"
                          rules={{ required: false }}
                          control={control}
                          render={({ field }) => (
                            <Select
                              bg="white"
                              fontSize="14px"
                              height="40px"
                              id="user_type"
                              size="lg"
                              color={
                                field.value === "Choose Status" ||
                                field.value === null ||
                                field.value === undefined
                                  ? "ndmsColors.gray.2200"
                                  : "black"
                              }
                              fontWeight={
                                field.value === "Choose Status" ||
                                field.value === null ||
                                field.value === undefined
                                  ? "bold"
                                  : "400"
                              }
                              mt="5px"
                              {...field}
                            >
                              <option selected disabled hidden value={null}>
                                Choose Status
                              </option>
                              {generateStatusOptions()}
                            </Select>
                          )}
                        />
                        {errors.status?.type === "required" && (
                          <span style={{ color: "red" }}>
                            status is required
                          </span>
                        )}
                      </Flex>
                    ) : null}

                    <Button
                      color="#4F46BA"
                      borderRadius="6px"
                      borderColor="#4F46BA"
                      bg="white"
                      type="submit"
                      mt="20px"
                      variant="outline"
                      isLoading={actionLoading}
                    >
                      Update
                    </Button>
                  </form>
                </Card>
              </Box>
            </>
          ) : null}
          <TmsFollowup
            ticketId={ticketId}
            isClosed={data?.data[0]?.is_closed}
          />
        </Flex>
      </Flex>
      <MarkAsClosedModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        ticketId={ticketId}
        data={data?.data[0]}
      />
      <UserAssignModal
        isOpen={isAssignOpen}
        onOpen={onAssignOpen}
        onClose={onAssignClose}
        ticketId={ticketId}
        assign={data?.data[0]?.assigned_to}
      />
    </Box>
  );
};

export default TicketSummary;
