import React from "react";
import { Box, Text, Button, Flex, Icon, Skeleton } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import "../../admin/layoutPlanning";
import { UpIcon, DownIcon } from "components/icons/Icons";
import { AddIcon, ArrowBackIcon } from "@chakra-ui/icons";
import { AddGroupMenu } from "components/icons/Icons";

const NodeLoader = () => {
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card bg="rgba(67, 24, 255, 0.02)" height="835px">
        <Flex align="center">
          <div style={{ cursor: "pointer" }}>
            <Icon as={ArrowBackIcon} w={6} h={6} />
          </div>

          <Text fontSize={"26px"} color="#2B3674" fontWeight="bold" ml="20px">
            Untitled Group
          </Text>
          <Button
            w="35px"
            h="30px"
            bg="#FFFFFF"
            borderRadius="7px"
            ml="20px"
            mr="10px"
          >
            {" "}
            <Icon as={AddIcon} w={3} h={3} color="#4318FF" />
          </Button>
          <AddGroupMenu />
        </Flex>
        <Flex pt="20px" style={{ flexWrap: "wrap" }}>
          {[...Array(9)].map((e, i) => (
            <Card width="310px" height="100px" mr="30px" mb="30px">
              <Skeleton height="60px" />
            </Card>
          ))}
        </Flex>
      </Card>
    </Box>
  );
};

export default NodeLoader;
