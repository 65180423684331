import { QUERY_KEY } from "constants/globalConstants";
import { useQuery } from "react-query";
import DashboardApiService from "apiService/dashboardApiService";

const useUntraceableNodeTypeList = (entityId, enableFlag) => {
  return useQuery(
    QUERY_KEY.GET_UNTRACABLE_NODE_TYPE_LIST,
    () => DashboardApiService.getUntraceableNodeTypeList(entityId),
    { retry: false, enabled: enableFlag }
  );
};

export default useUntraceableNodeTypeList;
