import { QUERY_KEY, MUTATION_KEY } from "constants/globalConstants";
import UserService from "apiService/permissions";
import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";

const useDeletePermission = (onClose) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.DELETE_PERMISSION],
    (id) => UserService.deletePermission(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.GET_PERMISSIONS);
        onClose()
        toast.success('Permission deleted successfully')
      },
      onError: (error) => {
        toast.error(error.response.data.message)
    },
    }
  );
};

export default useDeletePermission