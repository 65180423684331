import React from "react";
import Card from "components/card/Card.js";
import {
  Box,
  Button,
  Flex,
  FormLabel,
  RadioGroup,
  Stack,
  Radio,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import MasterConfigHooks from "hooks/masterConfig/masterConfigHooks";
import { useHistory } from "react-router-dom";

function AddConfiguration({ setShowAddForm, entityId, entityName }) {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  let activeColor = useColorModeValue("gray.700", "white");
  const buttonHover = useColorModeValue("brand.600", "brand.400");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const history = useHistory();
  const { mutate: submitConfig, isLoading } =
    MasterConfigHooks.useCreateMasterConfig(setShowAddForm);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    submitConfig({
      key: data.key,
      default_value: data.default_value,
      key_label: data.key_label,
      is_user_editable: parseInt(data.is_user_editable),
    });
  };

  const handleCancel = () => {
    history.push(`/admin/master-configuration`);
    setShowAddForm(false);
  };

  return (
    <>
      <Box w="100%" pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Card>
          <Flex
            maxW={{ base: "100%" }}
            w="100%"
            mx={{ base: "auto", lg: "0px" }}
            me="auto"
            h="100%"
            alignItems="start"
            justifyContent="center"
            mb={{ base: "30px", md: "30px" }}
            px={{ base: "25px", md: "0px" }}
            mt={{ base: "20px", md: "20px" }}
            flexDirection="column"
          >
            <Box me="auto">
              <Text
                mb="24px"
                ms="4px"
                color={activeColor}
                fontWeight="bold"
                fontSize="24px"
              >
                Add Configuration
              </Text>
            </Box>
            <Flex
              direction="column"
              w="100%"
              maxW="100%"
              background="transparent"
              borderRadius="15px"
              mx={{ base: "auto", lg: "unset" }}
              me="auto"
              mb={{ base: "20px", md: "auto" }}
            >
              <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
                <Flex w="100%">
                  <Flex direction="column" width="100%" pl="10px">
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                    >
                      Key<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <div style={{ marginBottom: "24px" }}>
                      <Controller
                        name="key"
                        rules={{
                          required: true,
                          validate: (value) => {
                            return !!value.trim();
                          },
                        }}
                        control={control}
                        render={({ field }) => (
                          <Input
                            fontSize="sm"
                            ms={{ base: "0px", md: "0px" }}
                            type="name"
                            placeholder="Enter key"
                            //mb="24px"
                            fontWeight="500"
                            size="lg"
                            {...field}
                          />
                        )}
                      />
                      {errors.key && (
                        <span style={{ color: "red" }}>key is required</span>
                      )}
                    </div>
                  </Flex>
                  <Flex direction="column" width="100%" pl="10px">
                    <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      display="flex"
                    >
                      Value<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <div style={{ marginBottom: "24px" }}>
                      <Controller
                        name="default_value"
                        rules={{
                          required: true,
                          validate: (value) => {
                            return !!value.toString().trim();
                          },
                        }}
                        control={control}
                        render={({ field }) => (
                          <Input
                            fontSize="sm"
                            placeholder="Enter Value"
                            size="lg"
                            type="name"
                            {...field}
                          />
                        )}
                      />
                      {errors.default_value && (
                        <span style={{ color: "red" }}>value is required</span>
                      )}
                    </div>
                  </Flex>
                </Flex>
                <Flex w="50%" alignItems="center">
                  <Flex direction="column" width="100%" pl="10px">
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                    >
                      Key Label<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <div style={{ marginBottom: "24px" }}>
                      <Controller
                        name="key_label"
                        rules={{
                          required: true,
                          validate: (value) => {
                            return !!value.trim();
                          },
                        }}
                        control={control}
                        render={({ field }) => (
                          <Input
                            fontSize="sm"
                            ms={{ base: "0px", md: "0px" }}
                            type="name"
                            placeholder="Enter Key Label"
                            //mb="24px"
                            fontWeight="500"
                            size="lg"
                            {...field}
                          />
                        )}
                      />
                      {errors.key_label && (
                        <span style={{ color: "red" }}>
                          key label is required
                        </span>
                      )}
                    </div>
                  </Flex>
                </Flex>
                <Flex direction="column" pt="10px" width="100%" pl="10px">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    //mb="8px"
                  >
                    Is User Editable<Text color={brandStars}>*</Text>
                  </FormLabel>

                  <div style={{ marginBottom: "24px" }}>
                    <Controller
                      name="is_user_editable"
                      rules={{ required: true }}
                      control={control}
                      render={({ field }) => (
                        <RadioGroup {...field}>
                          <Stack direction="row">
                            <Radio value="1" borderColor="#422AFB" pr="20px">
                              Yes
                            </Radio>
                            <Radio value="0" borderColor="#422AFB" pr="20px">
                              No
                            </Radio>
                          </Stack>
                        </RadioGroup>
                      )}
                    />
                    {errors.is_user_editable?.type === "required" && (
                      <span style={{ color: "red" }}>
                        is user editable is required
                      </span>
                    )}
                  </div>
                </Flex>

                <Flex width="40%" pt="20px" pl="10px">
                  <Button
                    fontSize="sm"
                    variant="brand"
                    fontWeight="500"
                    w="90%"
                    h="50"
                    mb="24px"
                    mr="20px"
                    _hover={{ bg: isLoading ? "blue" : buttonHover }}
                    isLoading={isLoading}
                    type="submit"
                  >
                    Submit
                  </Button>
                  <Button
                    fontSize="sm"
                    variant="brand"
                    fontWeight="500"
                    w="90%"
                    h="50"
                    mb="24px"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Flex>
              </form>
            </Flex>
          </Flex>
        </Card>
      </Box>
    </>
  );
}

export default AddConfiguration;
