import { Flex, Text } from "@chakra-ui/react";
import LiveDashboardCardLoader from "views/admin/liveDashboard/liveDashboardCardLoader";
import Card from "../../../components/card/Card";
import { useState } from "react";

const LiveDashboardCards = (props) => {
  const [activeState, setActiveState] = useState(0);

  const {
    entityNodeData,
    entityNodeError,
    entityNodeLoading,
    isFetchingNodeData,
    filterStatusData,
  } = props;
  if (entityNodeLoading) {
    return <LiveDashboardCardLoader />;
  }

  if (isFetchingNodeData) {
    return <LiveDashboardCardLoader />;
  }

  return (
    <Flex mt="20px" height="100px">
      <Card
        width="max-content"
        ml="20px"
        bg="#fff"
        borderRadius="15px"
        border={activeState === 0 && "3px solid black"}
        width="228px"
        alignItems="center"
        justifyContent="center"
        onClick={() => {
          setActiveState(0);
          filterStatusData();
        }}
        cursor="pointer"
      >
        <Flex direction="column" align="center">
          <Text fontSize="17px" pt="3px" color="#2B3674" fontWeight="bold">
            Total Nodes
          </Text>
          <Text fontSize="30px" pt="3px" color="#2B3674" fontWeight="bold">
            {entityNodeData?.data?.total_nodes_count}
          </Text>
        </Flex>
      </Card>
      <Card
        width="max-content"
        ml="20px"
        bg="#27AE60"
        borderRadius="15px"
        border={activeState === 1 && "3px solid black"}
        width="132px"
        alignItems="center"
        justifyContent="center"
        onClick={() => {
          setActiveState(1);
          filterStatusData("1");
        }}
        cursor="pointer"
      >
        <Flex direction="column" align="center">
          <Text fontSize="17px" pt="3px" color="white" fontWeight="bold">
            Online
          </Text>
          <Text fontSize="30px" pt="3px" color="white" fontWeight="bold">
            {entityNodeData?.data?.online}
          </Text>
        </Flex>
      </Card>
      <Card
        width="max-content"
        ml="20px"
        bg="#EB5757"
        border={activeState === 2 && "3px solid black"}
        borderRadius="15px"
        width="164px"
        alignItems="center"
        justifyContent="center"
        onClick={() => {
          setActiveState(2);
          filterStatusData("0");
        }}
        cursor="pointer"
      >
        <Flex direction="column" align="center">
          <Text fontSize="17px" pt="3px" color="white" fontWeight="bold">
            Offline
          </Text>
          <Text fontSize="30px" pt="3px" color="white" fontWeight="bold">
            {entityNodeData?.data?.offline}
          </Text>
        </Flex>
      </Card>
      <Card
        width="max-content"
        ml="20px"
        border={activeState === 3 && "3px solid black"}
        bg="#135CC9"
        borderRadius="15px"
        width="219px"
        alignItems="center"
        justifyContent="center"
        onClick={() => {
          setActiveState(3);
          filterStatusData("2");
        }}
        cursor="pointer"
      >
        <Flex direction="column" align="center">
          <Text fontSize="17px" pt="3px" color="white" fontWeight="bold">
            Schedule Down
          </Text>
          <Text fontSize="30px" pt="3px" color="white" fontWeight="bold">
            {entityNodeData?.data?.scheduled}
          </Text>
        </Flex>
      </Card>
    </Flex>
  );
};

export default LiveDashboardCards;
