import { QUERY_KEY, MUTATION_KEY } from "constants/globalConstants";
import ApiService from "apiService";
import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";

const useEditNodeType = (setShowEditForm, id) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.EDIT_NODE_TYPE],
    (data) => ApiService.editNodeType(data, id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.GET_NODE_TYPE);
        setShowEditForm(false)
        toast.success('Node type edited successfully')
      },
      onError: (error) => {
        toast.error(error.response.data.Message)
    },
    }
  );
};

export default useEditNodeType