import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import { Box, Text, Circle, Skeleton, Flex } from "@chakra-ui/react";
import EmptyPieChart from "components/charts/emptyPieChart";
import TmsMembersTable from "components/table/tmsMembersTable";
import EmptyCard from "components/card/EmptyCard";

const TicketDonut = ({
  data,
  tableTabData,
  label,
  otherLabel,
  totalLabel,
  value,
  total,
  viewTotal,
  tabIndex,
}) => {
  const colorUptime = "#F09205";
  const colorTotal = "#4F46BA";
  const [donutLoading, setDonutLoading] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setDonutLoading(true);
    }, 1000);
    setDonutLoading(false);
  }, [tabIndex]);

  const columnsTabData = [
    {
      Header: "Ticket Type",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.gray.1900"} fontSize="12px" fontWeight="500">
            {row.ticket_type}
          </Text>
        );
      },
    },
    {
      Header: "No. of Tickets",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.gray.1900"} fontSize="12px" fontWeight="500">
            {label === "Pending" ? row.assigned : row.unassigned}
          </Text>
        );
      },
    },
  ];

  const pieChartOptions = {
    labels: [otherLabel, label],
    colors: [colorTotal, colorUptime],
    chart: {
      width: "150px",
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    hover: { mode: null },
    plotOptions: {
      pie: {
        donut: {
          size: "75%",
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "12px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              color: undefined,
              offsetY: -10,
              formatter: function (val) {
                return val;
              },
            },
            value: {
              show: true,
              fontSize: "16px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
              color: undefined,
              offsetY: 16,
              formatter: function (val) {
                return val;
              },
            },
            total: {
              show: true,
              label: totalLabel,
              fontWeight: "600",
              color: "#7B818A",
              lineHeight: "18px",
              fontSize: "10px",
              formatter: function () {
                return viewTotal;
              },
            },
          },
        },
      },
    },

    series: [value, total],
    fill: {
      colors: [colorTotal, colorUptime],
    },
    tooltip: {
      enabled: false,
      theme: "dark",
    },
  };

  const pieChartData = [value, total];

  return (
    <>
      {data?.data?.ticket_type?.length > 0 ? (
        <>
          {!donutLoading ? (
            <Skeleton height="170px" width="100%" ml="10px" />
          ) : (
            <Box>
              <Flex alignItems="flex-start">
                {value === 0 ? (
                  <EmptyPieChart height="180px" />
                ) : (
                  <Chart
                    height="180px"
                    width="85%"
                    type="donut"
                    series={pieChartData}
                    options={pieChartOptions}
                  />
                )}

                <Flex direction="column" ml="10px" pt="10px">
                  <Flex alignItems="center">
                    <Circle size="10px" bg={colorUptime} />
                    <Text
                      pl="5px"
                      fontWeight="600"
                      fontSize="14px"
                      color="ndmsColors.gray.1900"
                    >
                      {label}
                    </Text>
                  </Flex>
                  <Flex alignItems="center" pt="5px">
                    <Circle size="10px" bg={colorTotal} />
                    <Text
                      pl="5px"
                      fontWeight="600"
                      fontSize="14px"
                      color="ndmsColors.gray.1900"
                    >
                      {otherLabel}
                    </Text>
                  </Flex>
                </Flex>
              </Flex>
            </Box>
          )}
          <Box pt="20px">
            <TmsMembersTable
              columnsData={columnsTabData}
              tableData={tableTabData}
            />
          </Box>
        </>
      ) : (
        <Flex alignItems="center" justifyContent="center" my="20px">
          <EmptyCard font="20px" size="70px" color="ndmsColors.gray.100" />
        </Flex>
      )}
    </>
  );
};

export default TicketDonut;
