import { QUERY_KEY } from "constants/globalConstants";
import ApiService from "apiService";
import { useQuery } from "react-query";
import { getDashboardPermissions } from "Permissions";

const useSavedForm = () => {
  return useQuery([QUERY_KEY.GET_SAVED_FORM], () => ApiService.getSavedForm(), {
    retry: false,
    enabled: getDashboardPermissions("client_form.view"),
  });
};

export default useSavedForm;
