import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Text,
  Button,
  Tooltip,
  Center,
  Divider,
  FormControl,
  Image,
  Flex,
  Input,
  Icon,
  useDisclosure,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { AddIcon, ArrowBackIcon } from "@chakra-ui/icons";
import NodeTable from "components/table/nodeTable";
import {
  AddGroupMenu,
  DeleteNodeIcon,
  DeleteNodeListIcon,
  GridIconDark,
  GridIconLight,
  ListIconDark,
  ListIconLight,
} from "components/icons/Icons";
import useNodeTypes from "hooks/useNodeTypes";
import { MdEdit } from "react-icons/md";
import useUpdateTemplateNode from "hooks/useUpdateTemplateNode";
import useGroup from "hooks/useGroup";
import useDeleteTemplateNode from "hooks/useDeleteTemplateNode";
import noGroupImage from "assets/img/layout/noGroup.png";
import { useForm, Controller } from "react-hook-form";
import TemplateModal from "components/modal/templateModal";
import useUpdateTemplate from "hooks/useUpdateTemplate";
import TemplateEditModal from "components/modal/templateEditModal";
import DeleteModal from "components/modal/deleteModal";

const TemplateDetails = ({ templateDetails, setShowTemplateDetails }) => {
  const {
    isLoading: isNodeLoading,
    error: nodeError,
    data: nodeTypeData,
  } = useNodeTypes();

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm();

  const outsideRef = useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const buttonHover = useColorModeValue("brand.600", "brand.400");
  const [toggleViewMode, setToggleViewMode] = useState(true);
  const {
    isOpen: isEditOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useDisclosure();
  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const [templateName, setTemplateName] = useState(templateDetails?.name);

  const { mutate: deleteTemplateNode } = useDeleteTemplateNode(onDeleteClose);
  const { mutate: updateTemplateNode, isLoading } = useUpdateTemplateNode();
  const { mutate: updateTemplate } = useUpdateTemplate(templateDetails?.id);
  const handleClickOutside = (e) => {
    if (!outsideRef.current.contains(e.target)) {
      setEdit(false);
      //setEditD(false)
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  const [edit, setEdit] = useState(false);
  const [editd, setEditD] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [templateData, setTemplateData] = useState(null);

  const handleDelete = (id) => {
    setDeleteId(id);
    onDeleteOpen();
  };

  const cData = [
    {
      Header: "Id",
      accessor: "id",
      showHeader: false,
      cell: (row) => {
        return row.id;
      },
    },
    {
      Header: "Device Name",
      accessor: "name",
      showHeader: true,
      cell: (row) => {
        return row.name;
      },
    },
    {
      Header: "Node type id",
      accessor: "node_type_id",
      showHeader: false,
      cell: (row) => {
        return row.node_type_id;
      },
    },
    {
      Header: "Node id",
      accessor: "template_id",
      showHeader: false,
      cell: (row) => {
        return row.template_id;
      },
    },
    {
      Header: "Action",
      accessor: "action",
      showHeader: true,
      Cell: ({ row }) => {
        return (
          <DeleteNodeListIcon
            style={{ cursor: "pointer" }}
            onClick={() => handleDelete(row.id)}
          />
        );
      },
    },
  ];

  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      setEdit(false);
      updateTemplate({ name: templateName });
    }
  };

  const onSubmit = (data) => {
    updateTemplateNode(data?.tag);
  };

  const handleEditTemplate = (data) => {
    setTemplateData(data);
    onEditOpen();
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card bg="rgba(67, 24, 255, 0.02)" height="625px" width="100%">
        <Flex align="center" position="relative">
          <div
            onClick={() => setShowTemplateDetails(false)}
            style={{ cursor: "pointer" }}
          >
            <Icon as={ArrowBackIcon} w={6} h={6} />
          </div>
          <Tooltip hasArrow label="Rename" bg="#2B3674">
            <div onClick={() => setEdit(true)} ref={outsideRef}>
              {edit ? (
                <form onKeyDown={onKeyDownHandler}>
                  <Input
                    type="text"
                    id="fname"
                    name="fname"
                    size="sm"
                    ml="20px"
                    value={templateName}
                    onChange={(event) => setTemplateName(event.target.value)}
                  />
                  {/* <input type="text" id="fname" name="fname" value={groupName} onChange={(event) => setGroupName(event.target.value)} /> */}
                </form>
              ) : (
                <Text
                  fontSize={"26px"}
                  color="#2B3674"
                  fontWeight="bold"
                  ml="20px"
                >
                  {templateName}
                </Text>
              )}
            </div>
          </Tooltip>
          <Button
            w="35px"
            h="30px"
            bg="#FFFFFF"
            borderRadius="7px"
            ml="20px"
            mr="10px"
            onClick={() => onOpen()}
          >
            {" "}
            <Icon as={AddIcon} w={3} h={3} color="#4318FF" />
          </Button>
          <AddGroupMenu />
          <Flex
            align="center"
            justify="space-around"
            height="40px"
            bg="white"
            width="80px"
            position="absolute"
            right="0"
            border="1px solid #DEDEDE"
            borderRadius="6px"
            cursor="pointer"
          >
            <Box
              pl="5px"
              pt="2px"
              onClick={() => setToggleViewMode(!toggleViewMode)}
            >
              {toggleViewMode ? <GridIconDark /> : <GridIconLight />}
            </Box>
            <Center height="40px">
              <Divider orientation="vertical" />
            </Center>
            <Box pr="10px" onClick={() => setToggleViewMode(!toggleViewMode)}>
              {toggleViewMode ? <ListIconLight /> : <ListIconDark />}
            </Box>
          </Flex>
        </Flex>
        {templateDetails?.template_nodes?.length > 0 ? (
          toggleViewMode ? (
            <Flex
              mt="20px"
              width="100%"
              style={{ flexWrap: "wrap" }}
              align={templateDetails?.template_nodes?.length === 0 && "center"}
              justify={
                templateDetails?.template_nodes?.length === 0 && "center"
              }
            >
              {templateDetails?.template_nodes?.map((node) => (
                <Card width="325px" height="98px" mr="15px" mt="15px">
                  <Flex align="center" justify="space-between">
                    <Text fontSize={"17px"} color="#2B3674" fontWeight="bold">
                      {node.name}
                    </Text>
                    <Flex align="center">
                      <div
                        style={{ paddingRight: "10px", cursor: "pointer" }}
                        onClick={() => handleEditTemplate(node)}
                      >
                        <Icon
                          color="secondaryGray.500"
                          as={MdEdit}
                          w="17px"
                          h="17px"
                        />
                      </div>
                      <DeleteNodeIcon
                        onClick={() => handleDelete(node.id)}
                        style={{ cursor: "pointer" }}
                      />
                    </Flex>
                  </Flex>
                </Card>
              ))}
            </Flex>
          ) : (
            <Card mt="30px">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Button
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  w="151px"
                  h="50"
                  mt="20px"
                  mb="24px"
                  mr="20px"
                  type="submit"
                  isLoading={isLoading}
                  _hover={{ bg: isLoading ? "blue" : buttonHover }}
                >
                  <Text fontSize={"16px"} color="#FFFFFF" fontWeight="bold">
                    Save
                  </Text>
                </Button>
                <FormControl>
                  <Controller
                    name="tag"
                    control={control}
                    render={({ field }) => (
                      <NodeTable
                        name="tag"
                        columnsData={cData}
                        tableData={templateDetails?.template_nodes}
                        register={register}
                        {...field}
                      />
                    )}
                  />
                </FormControl>
              </form>
            </Card>
          )
        ) : (
          <Flex align="center" justify="center" mt="200px">
            <Flex direction="column" justify="center" align="center">
              <Image src={noGroupImage} width="70px" alt="no-group" />
              <Text fontSize={"22px"} color="#2B3674" fontWeight="bold">
                You Don’t Have Any Device added for this group
              </Text>
            </Flex>
          </Flex>
        )}
      </Card>
      <TemplateModal
        onClose={onClose}
        onOpen={onOpen}
        templateData={templateDetails}
        data={nodeTypeData?.data[0]?.result}
        isOpen={isOpen}
      />
      <TemplateEditModal
        onClose={onEditClose}
        onOpen={onEditOpen}
        templateData={templateData}
        isOpen={isEditOpen}
      />
      <DeleteModal
        onOpen={onDeleteOpen}
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        id={deleteId}
        deleteItem={deleteTemplateNode}
      />
    </Box>
  );
};

export default TemplateDetails;
