import React, { useState } from "react";
import {
  Box,
  Tabs,
  TabList,
  Skeleton,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import NodeStatistics from "./nodeStatistics";
import NodeTicketCard from "./nodeTicketCard";
import TicketModuleHooks from "hooks/ticketModule/ticketModuleHooks";

const NodeDashboardTabs = ({
  status,
  entityId,
  nodeId,
  nodeName,
  nodeTypeId,
  nodeTypeName,
}) => {
  const {
    data: activeListData,
    isLoading: activeListLoading,
    error: activeError,
    refetch,
    isFetching: isActiveListFetching,
  } = TicketModuleHooks.useTicketTypesForNode(nodeId);

  const {
    data: userListData,
    isLoading,
    error,
    refetch: userRefetch,
  } = TicketModuleHooks.useTicketUserList();

  return (
    <Box mt="50px">
      <hr />
      <Tabs>
        <TabList>
          <Tab
            _selected={{
              color: "#4318FF",
              boxShadow: "none",
              borderBottom: "1px solid #4318FF",
            }}
            color="#828282"
            fontSize="18px"
            fontWeight="bold"
          >
            Statistics
          </Tab>

          <Tab
            _selected={{
              color: "#4318FF",
              boxShadow: "none",
              borderBottom: "1px solid #4318FF",
            }}
            color="#828282"
            fontSize="18px"
            fontWeight="bold"
          >
            Tickets
          </Tab>
        </TabList>

        <TabPanels>
          <TabPanel pl="0px">
            <NodeStatistics
              status={status}
              entityId={entityId}
              nodeId={nodeId}
              nodeName={nodeName}
              nodeTypeId={nodeTypeId}
              nodeTypeName={nodeTypeName}
            />
          </TabPanel>
          <TabPanel>
            <NodeTicketCard
              activeListData={activeListData}
              isLoading={activeListLoading}
              userListData={userListData}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default NodeDashboardTabs;
