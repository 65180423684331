import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Text,
  Icon,
  Select,
  Flex,
  Spinner,
  Skeleton,
  useDisclosure,
} from "@chakra-ui/react";
import { AddIcon, DownloadIcon } from "@chakra-ui/icons";
import Card from "components/card/Card.js";
import DailyView from "./dailyView";
import WeeklyView from "./weeklyView";
import OneTimeView from "./oneTimeView";
import MonthlyView from "./monthlyView";
import YearlyView from "./yearlyView";
import { useParams } from "react-router-dom";
import DowntimeDrawer from "components/drawer/DowntimeDrawer";
import useGetScheduleDowntimeList from "hooks/useGetScheduleDowntimeList";
import EditDowntimeDrawer from "components/drawer/EditDowntimeDrawer";
import useNodeTypes from "hooks/useNodeTypes";
import { getDashboardPermissions } from "Permissions";

const ScheduleDowntimeList = (props) => {
  const { tabType } = props;
  const [type, setType] = useState("daily");
  const [status, setStatus] = useState("is_active");
  const entityId = parseInt(useParams()?.id);
  const { data: nodeTypeData } = useNodeTypes();
  const btnRef = useRef(null);
  const {
    isOpen: isScheduleOpen,
    onOpen: onScheduleOpen,
    onClose: onScheduleClose,
  } = useDisclosure();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isScheduleEditOpen,
    onOpen: onScheduleEditOpen,
    onClose: onScheduleEditClose,
  } = useDisclosure();
  const { isLoading, error, data, refetch, isFetching } =
    useGetScheduleDowntimeList(entityId, type, status);

  useEffect(() => {
    refetch();
  }, [entityId, type, status]);

  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState({});

  if (isLoading || isFetching) {
    return (
      <Card mt="20px">
        <Skeleton height="300px" mt="10px" width="100%" />
      </Card>
    );
  }

  const getDesign = () => {
    switch (type) {
      case "daily":
        return <DailyView data={data} handleEdit={handleEdit} />;
      case "weekly":
        return <WeeklyView data={data} handleEdit={handleEdit} />;
      case "fixed":
        return <OneTimeView data={data} handleEdit={handleEdit} />;
      case "monthly":
        return <MonthlyView data={data} handleEdit={handleEdit} />;
      case "yearly":
        return <YearlyView data={data} handleEdit={handleEdit} />;
      default:
        return null;
    }
  };

  const handleEdit = (data) => {
    setEditData(data);
    onScheduleEditOpen();
  };

  return (
    <>
      <Box pt="10px" pb="50px" width="100%">
        <Card mt="20px" pb="100px" height="auto !important" overflow="auto">
          <Flex direction="column" justify="center">
            <Flex justifyContent="space-between">
              <Flex align="center">
                <Text
                  fontSize={"16px"}
                  //mb="5px"
                  color="#707EAE"
                >
                  Filters :
                </Text>
                <Select
                  bg="white"
                  fontSize="15px"
                  //mt="20px"
                  width="200px"
                  height="40px"
                  id="user_type"
                  size="lg"
                  ml="10px"
                  color="#2C3774"
                  fontWeight="bold"
                  defaultValue={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <option value="is_active">Active</option>
                  <option value="is_inactive">Inactive</option>
                  <option value="is_expired">Expired</option>
                </Select>
                <Select
                  bg="white"
                  fontSize="15px"
                  //mt="20px"
                  width="200px"
                  height="40px"
                  id="user_type"
                  size="lg"
                  color="#2C3774"
                  fontWeight="bold"
                  ml="10px"
                  defaultValue={type}
                  onChange={(e) => setType(e.target.value)}
                >
                  <option value="daily">Daily</option>
                  <option value="monthly">Monthly</option>
                  <option value="weekly">Weekly</option>
                  <option value="yearly">Yearly</option>
                  <option value="fixed">One Time</option>
                </Select>
              </Flex>
              {getDashboardPermissions("schedule_downtime.create") ? (
                <Card
                  width="max-content"
                  bg="#4318FF"
                  height="34px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="6px"
                  cursor="pointer"
                  m="0px"
                  ref={btnRef}
                  onClick={() => onScheduleOpen()}
                >
                  <Flex align="center" justify="center">
                    <Icon color="white" as={AddIcon} w="10px" h="10px" />
                    <Text
                      color="white"
                      fontSize="14px"
                      pt="2px"
                      pl="10px"
                      fontWeight="bold"
                    >
                      Add New
                    </Text>
                  </Flex>
                </Card>
              ) : null}
            </Flex>
          </Flex>
          <Flex direction="column">
            <Text mt="20px" color="#2B3674" fontSize="20px" fontWeight="bold">
              All Downtimes
            </Text>
            {loading ? (
              <Flex
                alignItem="center"
                height="200px"
                pt="80px"
                justifyContent="center"
              >
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="blue.500"
                  size="xl"
                />
              </Flex>
            ) : (
              getDesign()
            )}
          </Flex>
        </Card>
      </Box>
      <DowntimeDrawer
        entityId={entityId}
        onOpen={onScheduleOpen}
        onClose={onScheduleClose}
        isOpen={isScheduleOpen}
        btnRef={btnRef}
        nodeTypeData={nodeTypeData}
        data={[]}
      />
      <EditDowntimeDrawer
        entityId={entityId}
        onOpen={onScheduleEditOpen}
        onClose={onScheduleEditClose}
        isOpen={isScheduleEditOpen}
        nodeTypeData={nodeTypeData}
        data={[]}
        editData={editData}
      />
    </>
  );
};

export default ScheduleDowntimeList;
