import React, { useState, useEffect } from "react";
import Card from "components/card/Card.js";
import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Spinner,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { DeleteIcon } from "@chakra-ui/icons";
import useTemplate from "hooks/useTemplate";
import { MdEdit } from "react-icons/md";
import DownTimeListTable from "components/table/downTimeListTable";
import noGroupImage from "assets/img/layout/noGroup.png";
import { GrNetworkDrive } from "react-icons/gr";
import useNodeTypes from "hooks/useNodeTypes";
import TemplateDetails from "./templateDetails";
import DeleteModal from "components/modal/deleteModal";
import useDeleteTemplate from "hooks/useDeleteTemplate";

const Template = () => {
  const textColor = useColorModeValue("navy.700", "white");
  const [currentPage, setCurrentPage] = useState(1);
  const {
    isLoading: isNodeLoading,
    error: nodeError,
    data: nodeData,
  } = useNodeTypes();
  const [showTemplateDetails, setShowTemplateDetails] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [deleteId, setDeleteId] = useState(null);
  const [templateDetails, setTemplateDetails] = useState(null);
  const { mutate: deleteTemplate } = useDeleteTemplate(onClose);
  const handleTemplateDetails = (details) => {
    setTemplateDetails(details);
    setShowTemplateDetails(true);
  };

  const checkForDeviceNumber = (nodeName, templateNodes) => {
    if (templateNodes?.length && templateNodes?.length > 0) {
      let count = 0;

      for (let index = 0; index < templateNodes?.length; index++) {
        if (templateNodes[index]?.node_type_id === nodeName?.id) {
          count = count + 1;
        }
      }
      return count;
    }
  };
  const handleDelete = (id) => {
    setDeleteId(id);
    onOpen();
  };
  const columnsData = [
    {
      Header: "ID",
      accessor: (row) => {
        return (
          <Text
            color={"ndmsColors.purple.200"}
            fontSize="12px"
            fontWeight="700"
          >
            {row.id}
          </Text>
        );
      },
    },
    {
      Header: "NAME",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row.name}
          </Text>
        );
      },
    },
    {
      Header: "Devices",
      accessor: (row) => {
        return (
          <Flex wrap="wrap" maxWidth="300px">
            {nodeData?.data[0]?.result?.map((node) => (
              <>
                {checkForDeviceNumber(node, row?.template_nodes) > 0 ? (
                  <Flex mr="10px" align="center">
                    {/* <GrNetworkDrive color="red" /> */}
                    <Icon
                      color="secondaryGray.500"
                      as={GrNetworkDrive}
                      w="17px"
                      h="17px"
                      opacity="0.4"
                    />
                    <Box pl="5px">
                      <Text fontSize={"12px"} color="#8694BF" pl="2px">
                        {`${node?.name} : ${checkForDeviceNumber(
                          node,
                          row?.template_nodes
                        )}`}
                      </Text>
                    </Box>
                  </Flex>
                ) : null}
              </>
            ))}
          </Flex>
        );
      },
    },

    {
      Header: "ACTION",
      accessor: (row) => {
        return (
          <Flex align="center" justifyContent="center">
            <Flex mr="10px" onClick={() => handleTemplateDetails(row)}>
              <Icon
                color="ndmsColors.purple.200"
                as={MdEdit}
                w="17px"
                h="17px"
              />
            </Flex>
            <Flex onClick={() => handleDelete(row.id)}>
              <Icon
                as={DeleteIcon}
                width="17px"
                height="17px"
                color="ndmsColors.red.200"
              />{" "}
            </Flex>
          </Flex>
        );
      },
    },
  ];
  const { isLoading, error, data, refetch, isFetching } = useTemplate(
    20,
    currentPage
  );

  useEffect(() => {
    refetch();
  }, [currentPage]);

  if (isLoading || isFetching) {
    return (
      <Box pt={{ base: "130px", md: "100px", xl: "100px" }}>
        <Card w="100%" h="500px" px="0px">
          <Flex direction="column" mt="200px" align="center" justify="center">
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="blue.500"
              size="xl"
            />
          </Flex>
        </Card>
      </Box>
    );
  }

  return (
    <>
      {showTemplateDetails ? (
        <TemplateDetails
          templateDetails={templateDetails}
          setShowTemplateDetails={setShowTemplateDetails}
        />
      ) : (
        <Box pt={{ base: "130px", md: "100px", xl: "100px" }}>
          <Card
            direction="column"
            w="100%"
            px="0px"
            overflowX={{ sm: "scroll", lg: "hidden" }}
          >
            {data?.data[0]?.result?.length > 0 ? (
              <Flex p="20px" direction="column">
                <DownTimeListTable
                  tableData={data?.data[0]?.result}
                  columnsData={columnsData}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  totalPage={data?.data[0]?.total_pages}
                />
              </Flex>
            ) : (
              <Flex
                align="center"
                justify="center"
                mt="200px"
                cursor="pointer"
                mb="150px"
                //onClick={() => setShowAddForm(true)}
              >
                <Flex direction="column" justify="center" align="center">
                  <Image src={noGroupImage} width="70px" alt="no-group" />
                  <Text fontSize={"22px"} color="#2B3674" fontWeight="bold">
                    You Don’t Have Any Template
                  </Text>
                </Flex>
              </Flex>
            )}
          </Card>
        </Box>
      )}
      <DeleteModal
        onOpen={onOpen}
        isOpen={isOpen}
        onClose={onClose}
        id={deleteId}
        deleteItem={deleteTemplate}
      />
    </>
  );
};

export default Template;
