import React, { useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Input,
  InputGroup,
  InputRightElement,
  Flex,
  FormLabel,
  Text,
  ModalCloseButton,
  Button,
  //Select,
  useColorModeValue,
  Select,
} from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import ApproveDowntimeHooks from "hooks/approvedDowntime/approveDowntimeHooks";

function DowntimeApproveModal({
  isOpen,
  onClose,
  onOpen,
  breakupReasonData = {},
  entityId,
  modalData,
}) {
  const {
    handleSubmit,
    control,
    resetField,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm();
  const textColor = useColorModeValue("navy.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const handleModalClose = () => {
    resetField("start_at");
    resetField("end_at");
    resetField("notes");
    resetField("breakup_reason_id");
    clearErrors();
    onClose();
  };
  dayjs.extend(utc);

  const { mutate: createNopd, isLoading } =
    ApproveDowntimeHooks.useCreateNopdBreakup(handleModalClose);

  const { mutate: createEntityNopd, isLoading: isEntityNopdLoading } =
    ApproveDowntimeHooks.useCreateEntityNopdBreakup(handleModalClose);

  const onSubmit = (data) => {
    createNopd({
      downtime_range_id: modalData?.id,
      notes: data.notes,
      breakup_reason_id: data.breakup_reason_id,
      start_at: dayjs(data.start_at).format("YYYY-MM-DD HH:mm"),
      end_at: dayjs(data.end_at).format("YYYY-MM-DD HH:mm"),
    });
  };

  const onSubmit2 = (data) => {
    createEntityNopd({
      entity_id: entityId,
      notes: data.notes,
      breakup_reason_id: data.breakup_reason_id,
      start_at: dayjs(data.start_at).format("YYYY-MM-DD HH:mm"),
      end_at: dayjs(data.end_at).format("YYYY-MM-DD HH:mm"),
    });
  };
  const generateStateOptions = () => {
    return breakupReasonData?.map((state) => {
      return <option value={state.id}>{state.breakup_type}</option>;
    });
  };

  useEffect(() => {
    setValue("start_at", new Date(modalData?.start_at));
    setValue("end_at", new Date(modalData?.end_at));
  }, [modalData, isOpen]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        blockScrollOnMount={false}
        onClose={handleModalClose}
        size="lg"
      >
        <ModalOverlay />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContent>
            <ModalHeader>
              <Flex direction="column" w="100%" mt="15px">
                <Flex direction="column" width="100%">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Start Date<Text color={brandStars}>*</Text>
                  </FormLabel>

                  <div style={{ marginBottom: "10px" }}>
                    <Controller
                      name={`start_at`}
                      rules={{ required: false }}
                      control={control}
                      defaultValue={new Date(modalData?.start_at)}
                      render={({ field }) => (
                        <DatePicker
                          wrapperClassName="date-picker"
                          showTimeInput={field.value ? true : false}
                          preventOpenOnFocus={true}
                          shouldCloseOnSelect={false}
                          timeInputLabel="Time:"
                          customInput={
                            <InputGroup>
                              <Input
                                minWidth="300px"
                                placeholder="From"
                                defaultValue={modalData?.start_at}
                                value={
                                  field.value
                                    ? `${field?.value?.toLocaleDateString()}, ${field.value.toLocaleTimeString(
                                        "en-US"
                                      )}`
                                    : new Date(modalData?.start_at)
                                }
                              />
                              <InputRightElement
                                children={<CalendarIcon color="#4318FF" />}
                              />
                            </InputGroup>
                          }
                          onChange={(date) => {
                            field.onChange(date);
                          }}
                          selected={
                            field.value
                              ? field.value
                              : new Date(modalData?.start_at)
                          }
                          minDate={
                            modalData?.start_at !== "0"
                              ? new Date(modalData?.start_at)
                              : field.value
                          }
                          maxDate={
                            modalData?.end_at !== "0"
                              ? new Date(modalData?.end_at)
                              : new Date()
                          }
                          //   minTime={'5:00 PM'}
                          //   maxTime={'6:00 PM'}
                          dateFormat="MMMM d, yyyy h:mm aa"
                        />
                      )}
                    />
                    {errors.start_at?.type === "required" && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        start date is required
                      </span>
                    )}
                  </div>
                </Flex>
                <Flex direction="column" width="100%" pt="10px">
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    isRequired={true}
                    display="flex"
                  >
                    End Date<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <div style={{ marginBottom: "10px" }}>
                    <Controller
                      name={`end_at`}
                      rules={{ required: false }}
                      control={control}
                      defaultValue={new Date(modalData?.end_at)}
                      render={({ field }) => (
                        <DatePicker
                          wrapperClassName="date-picker"
                          showTimeInput={field.value ? true : false}
                          timeInputLabel="Time:"
                          selectMultiple={true}
                          shouldCloseOnSelect={false}
                          //selected={new Date(modalData?.start_at)}
                          customInput={
                            <InputGroup>
                              <Input
                                minWidth="300px"
                                placeholder="To"
                                value={
                                  field.value
                                    ? `${field?.value?.toLocaleDateString()}, ${field.value.toLocaleTimeString(
                                        "en-US"
                                      )}`
                                    : new Date(modalData?.end_at)
                                }
                              />
                              <InputRightElement
                                children={<CalendarIcon color="#4318FF" />}
                              />
                            </InputGroup>
                          }
                          onChange={(date) => {
                            field.onChange(date);
                          }}
                          selected={
                            field.value
                              ? field.value
                              : new Date(modalData?.end_at)
                          }
                          minDate={
                            modalData?.start_at !== "0"
                              ? new Date(modalData?.start_at)
                              : field.value
                          }
                          maxDate={
                            modalData?.end_at !== "0"
                              ? new Date(modalData?.end_at)
                              : new Date()
                          }
                          dateFormat="MMMM d, yyyy h:mm aa"
                        />
                      )}
                    />
                    {errors.end_at?.type === "required" && (
                      <span style={{ color: "red", fontSize: "14px" }}>
                        end date is required
                      </span>
                    )}
                  </div>
                </Flex>
                <Flex direction="column" width="100%" pt="10px">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="14px"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Reason<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <div style={{ marginBottom: "10px" }}>
                    <Controller
                      name="breakup_reason_id"
                      rules={{ required: true }}
                      control={control}
                      render={({ field }) => (
                        <Select
                          id="breakup_reason_id"
                          size="lg"
                          fontSize="14px"
                          color={
                            field.value === "Reason" ||
                            field.value === null ||
                            field.value === undefined
                              ? "ndmsColors.gray.2200"
                              : "black"
                          }
                          fontWeight={
                            field.value === "Reason" ||
                            field.value === null ||
                            field.value === undefined
                              ? "bold"
                              : "normal"
                          }
                          {...field}
                        >
                          <option
                            selected
                            disabled
                            hidden
                            value={null}
                          ></option>
                          {generateStateOptions()}
                        </Select>
                      )}
                    />
                    {errors.user_id?.type === "required" && (
                      <span style={{ color: "red" }}>
                        Please select breakup reason
                      </span>
                    )}
                  </div>
                </Flex>
                <Flex direction="column" width="100%" pt="10px">
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    isRequired={true}
                    display="flex"
                  >
                    Notes
                  </FormLabel>
                  <div style={{ marginBottom: "24px" }}>
                    <Controller
                      name={`notes`}
                      rules={{ required: false }}
                      control={control}
                      render={({ field }) => (
                        <Input
                          fontSize="sm"
                          placeholder="Enter Notes"
                          size="lg"
                          type="name"
                          {...field}
                        />
                      )}
                    />
                  </div>
                </Flex>
              </Flex>
            </ModalHeader>
            <Flex mx={5}>
              <Button
                bg="#4318FF"
                _hover={{ bg: "#2B3674" }}
                mt={2}
                mb={10}
                width="50%"
                isLoading={isLoading}
                type="submit"
              >
                <Text color={"#FFFFFF"}>Apply</Text>
              </Button>
              <Button
                bg="#4318FF"
                _hover={{ bg: "#2B3674" }}
                mt={2}
                ml={4}
                width="50%"
                mb={10}
                isLoading={isEntityNopdLoading}
                type="button"
                onClick={handleSubmit(onSubmit2)}
              >
                <Text color={"#FFFFFF"}>Apply to all</Text>
              </Button>
            </Flex>
            <ModalCloseButton />
          </ModalContent>
        </form>
      </Modal>
    </>
  );
}

export default DowntimeApproveModal;
