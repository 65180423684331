import React, { useState, useEffect } from "react";
import Card from "components/card/Card.js";
import DownTimeListTable from "components/table/downTimeListTable";
import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Tooltip,
  Text,
  useDisclosure,
  useColorModeValue,
} from "@chakra-ui/react";
import noGroupImage from "assets/img/layout/noGroup.png";
import LoadingForm from "components/loader/LoadingForm";
import { AddIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import AddClientForm from "./addClientForm";
import EditClientForm from "./editClientForm";
import useClientList from "hooks/useClientList";
import useDeleteClient from "hooks/useDeleteClient";
import { FaWpforms } from "react-icons/fa";
import FormBuilder from "./formBuilder";
import useSavedForm from "hooks/useSavedForm";
import { getDashboardPermissions } from "Permissions";
import DeleteModal from "components/modal/deleteModal";
//import FormBuilder from "./formBuilderclass"

function Client() {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const [editData, setEditData] = useState(null);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [showFormFields, setShowFormFields] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutate: deleteClient } = useDeleteClient(onClose);
  const [deleteId, setDeleteId] = useState(null);
  const { isLoading, error, data, refetch, isFetching } = useClientList(
    20,
    currentPage
  );
  const {
    isLoading: isFormLoading,
    error: formError,
    data: formData,
  } = useSavedForm();

  useEffect(() => {
    refetch();
  }, [currentPage]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showAddForm, showEditForm, showFormFields]);

  if (isLoading || isFetching) return <LoadingForm />;
  if (error)
    return (
      <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Flex align="center" justifyContent="flex-end">
          <Button
            bg="ndmsColors.purple.200"
            minWidth="104px"
            borderRadius="6px"
            mb="42px"
            _hover={{ bg: "ndmsColors.blue.400" }}
            _active={{ bg: "ndmsColors.blue.400" }}
            onClick={() => setShowAddForm(true)}
          >
            <Icon as={AddIcon} w={3} h={3} color="ndmsColors.black.100" />
            <Text ml="10px" color="ndmsColors.black.100" fontSize="14px">
              {" "}
              Add{" "}
            </Text>
          </Button>
        </Flex>
        <Card
          direction="column"
          w="100%"
          px="0px"
          overflowX={{ sm: "scroll", lg: "hidden" }}
        >
          <Flex
            style={{ cursor: "pointer" }}
            align="center"
            justify="center"
            mt="200px"
            mb="150px"
            onClick={() => setShowAddForm(true)}
          >
            <Flex direction="column" justify="center" align="center">
              <Image src={noGroupImage} width="70px" alt="no-group" />
              <Text fontSize={"22px"} color="#2B3674" fontWeight="bold">
                You Don’t Have Any Customer added
              </Text>
            </Flex>
          </Flex>
        </Card>
      </Box>
    );
  const handleDelete = (id) => {
    //deleteClient(id);
    setDeleteId(id);
    onOpen();
  };
  const handleEdit = (row) => {
    setEditData(row);
    setShowEditForm(true);
  };

  const columnsData = [
    {
      Header: "ID",
      accessor: (row) => {
        return (
          <Text
            color={"ndmsColors.purple.200"}
            fontSize="12px"
            fontWeight="700"
          >
            {row.id}
          </Text>
        );
      },
    },
    {
      Header: "NAME",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row.name}
          </Text>
        );
      },
    },
    {
      Header: "EMAIL",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row.email}
          </Text>
        );
      },
    },
    {
      Header: "SLUG",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row.slug}
          </Text>
        );
      },
    },
    {
      Header: "ACTION",
      accessor: (row) => {
        return (
          <Flex justifyContent="center" align="center">
            {getDashboardPermissions("customer.create") ? (
              <Tooltip hasArrow label="Edit" bg="ndmsColors.blue.100">
                <Flex mr="10px" onClick={() => handleEdit(row)}>
                  <Icon
                    as={EditIcon}
                    width="17px"
                    height="17px"
                    color="ndmsColors.purple.200"
                  />{" "}
                </Flex>
              </Tooltip>
            ) : null}
            {getDashboardPermissions("customer.delete") ? (
              <Tooltip hasArrow label="Delete" bg="ndmsColors.red.200">
                <Flex mr="10px" onClick={() => handleDelete(row.id)}>
                  <Icon
                    as={DeleteIcon}
                    width="17px"
                    height="17px"
                    color="ndmsColors.red.200"
                  />{" "}
                </Flex>
              </Tooltip>
            ) : null}
            {getDashboardPermissions("client_form.view") ? (
              <Tooltip hasArrow label="entity meta fields" bg="#2B3674">
                <Flex onClick={() => setShowFormFields(true)}>
                  <Icon
                    as={FaWpforms}
                    width="17px"
                    height="17px"
                    color="ndmsColors.purple.200"
                  />{" "}
                </Flex>
              </Tooltip>
            ) : null}
          </Flex>
        );
      },
    },
  ];

  const getFormData = () => {
    let modifiedFormData = [];
    if (formData?.data?.length > 0) {
      formData?.data?.map((data) => {
        const obj = {
          id: data.id,
          label: data.label,
          multiple: data.multiple,
          type: data.type,
          ...data.properties,
        };
        modifiedFormData.push(obj);
      });
    }
    return modifiedFormData;
  };

  return (
    <>
      {showAddForm && <AddClientForm setShowAddForm={setShowAddForm} />}
      {showEditForm && (
        <EditClientForm setShowEditForm={setShowEditForm} data={editData} />
      )}
      {showFormFields && (
        <FormBuilder
          setShowFormFields={setShowFormFields}
          formData={getFormData()}
        />
      )}
      {!showAddForm && !showEditForm && !showFormFields && (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
          <Flex align="center" justifyContent="flex-end">
            <Button
              bg="ndmsColors.purple.200"
              minWidth="104px"
              borderRadius="6px"
              mb="42px"
              _hover={{ bg: "ndmsColors.blue.400" }}
              _active={{ bg: "ndmsColors.blue.400" }}
              onClick={() => setShowAddForm(true)}
            >
              <Icon as={AddIcon} w={3} h={3} color="#FFF" />
              <Text ml="10px" color="#FFF" fontSize="14px">
                {" "}
                Add
              </Text>
            </Button>
          </Flex>
          <Card
            direction="column"
            w="100%"
            px="0px"
            overflowX={{ sm: "scroll", lg: "hidden" }}
          >
            {data?.data[0]?.result?.length > 0 ? (
              <Flex p="20px" direction="column">
                <DownTimeListTable
                  tableData={data?.data[0]?.result}
                  columnsData={columnsData}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                  totalPage={data?.data[0]?.total_pages}
                />
              </Flex>
            ) : (
              <Flex
                style={{ cursor: "pointer" }}
                align="center"
                justify="center"
                mt="200px"
                mb="150px"
                onClick={() => setShowAddForm(true)}
              >
                <Flex direction="column" justify="center" align="center">
                  <Image src={noGroupImage} width="70px" alt="no-group" />
                  <Text fontSize={"22px"} color="#2B3674" fontWeight="bold">
                    You Don’t Have Any Client
                  </Text>
                </Flex>
              </Flex>
            )}
          </Card>
        </Box>
      )}
      <DeleteModal
        onOpen={onOpen}
        isOpen={isOpen}
        onClose={onClose}
        id={deleteId}
        deleteItem={deleteClient}
      />
    </>
  );
}

export default Client;
