import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Box,
  Input,
  Icon,
  Flex,
  FormLabel,
  Text,
  ModalCloseButton,
  Button,
  //Select,
  useColorModeValue,
} from "@chakra-ui/react";

const DeleteModal = ({
  isOpen,
  onClose,
  onOpen,
  id,
  deleteItem,
  isLoading,
}) => {
  const handleDeleteItem = () => {
    deleteItem(id);
  };
  const handleModalClose = () => {
    onClose();
  };
  return (
    <Box borderRadius="3xl">
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        blockScrollOnMount={false}
        size="lg"
        isCentered
      >
        <ModalOverlay />
        <ModalContent borderRadius="20px">
          <ModalHeader>
            <Flex justify="center" direction="column" align="center" mt="30px">
              <Text fontSize="30px">Delete Item</Text>
              <Text color="#A3AED0">
                Are you sure you want to delete this item?
              </Text>
              <Flex mt="20px">
                <Button
                  bg="#4318FF"
                  _hover={{ bg: "#2B3674" }}
                  mx={3}
                  mt={2}
                  mb={10}
                  size="lg"
                  isLoading={isLoading}
                  onClick={handleDeleteItem}
                >
                  <Text color={"#FFFFFF"}>Delete</Text>
                </Button>
                <Button
                  variant="outline"
                  colorScheme="#2B3674"
                  _hover={{ bg: "#F0F8FF" }}
                  mx={3}
                  mt={2}
                  size="lg"
                  mb={10}
                  onClick={handleModalClose}
                >
                  <Text color={"#2B3674"}>Cancel</Text>
                </Button>
              </Flex>
            </Flex>
          </ModalHeader>

          <ModalCloseButton />
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default DeleteModal;
