import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Circle,
  Flex,
  InputGroup,
  Divider,
  Input,
  InputRightElement,
  Avatar,
  SkeletonCircle,
  Button,
  Skeleton,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import EmptyCard from "components/card/EmptyCard";
import "./tms.css";
import { InputIcon } from "components/icons/Icons";
import TicketModuleHooks from "hooks/ticketModule/ticketModuleHooks";
import { useForm, Controller } from "react-hook-form";
import { getDashboardPermissions, getDateAndTime } from "Permissions";

const TimeLogs = ({ ticketId, isClosed }) => {
  const {
    handleSubmit,
    control,
    reset,
    resetField,
    clearErrors,
    formState: { errors },
  } = useForm();
  const [showInput, setShowInput] = useState(false);
  const { data, isLoading, error, refetch, isFetching } =
    TicketModuleHooks.useTicketComments(ticketId);
  const { mutate: addComment, isLoading: isAddCommentLoading } =
    TicketModuleHooks.useCreateComment(ticketId, setShowInput);

  useEffect(() => {
    refetch();
  }, [ticketId]);

  const onCancel = () => {
    resetField("comment");
    setShowInput(true);
  };

  const onSubmit = (data) => {
    addComment({
      comment: data.comment,
    });
  };
  return (
    <Box mt="30px" mb="20px">
      <Text fontSize="20px" fontWeight="bold" mb="20px">
        Comments
      </Text>
      {isLoading || isFetching ? (
        <Card border="1px solid #EAEDF2" mt="10px" borderRadius="10px">
          {[...Array(3)]?.map((content, index) => (
            <Box>
              {index === 0 ? null : <Divider my="10px" />}
              <Flex>
                <SkeletonCircle size="10" />
                <Skeleton ml="20px" height="50px" width="100%" />
              </Flex>
            </Box>
          ))}
          <Skeleton height="40px" mt="20px" width="150px" />
        </Card>
      ) : (
        <Card border="1px solid #EAEDF2" mt="10px" borderRadius="10px">
          {data?.data?.length > 0 ? (
            <Box maxHeight="320px" overflowY="auto">
              {data?.data?.map((content, index) => (
                <Box>
                  {index === 0 ? null : <Divider my="10px" />}
                  <Flex>
                    <Avatar
                      name={content?.created_by?.user_fname}
                      size="sm"
                      mt="5px"
                    />
                    <Flex direction="column" pl="10px">
                      <Flex>
                        <Text
                          fontSize="14px"
                          color="black !important"
                          fontWeight="500"
                        >
                          {content?.created_by?.user_fname}{" "}
                          {content?.created_by?.user_lname}
                        </Text>
                        <Text
                          fontSize="14px"
                          ml="10px"
                          fontWeight="500"
                          color="ndmsColors.gray.2200"
                        >
                          ({content?.created_by?.user_role})
                        </Text>
                      </Flex>
                      <Text
                        fontSize="12px"
                        fontWeight="500"
                        color="ndmsColors.gray.2200"
                      >
                        {getDateAndTime(content?.created_at)}
                      </Text>
                      <Text fontSize="12px" pt="10px" color="black">
                        {content?.comment}
                      </Text>
                    </Flex>
                  </Flex>
                </Box>
              ))}
            </Box>
          ) : (
            <Flex height="250px" justifyContent="center">
              <EmptyCard label="No Comments added" font="16px" size="40px" />
            </Flex>
          )}

          <Box mt="20px" ml="5px">
            {showInput ? (
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box>
                  <Controller
                    name={`comment`}
                    rules={{
                      required: true,
                      validate: (value) => {
                        return !!value.trim();
                      },
                    }}
                    control={control}
                    render={({ field }) => (
                      <InputGroup>
                        <Input placeholder="Add a comment" {...field} />
                      </InputGroup>
                    )}
                  />
                  {errors.comment && (
                    <span style={{ color: "red", fontSize: "14px" }}>
                      comment is required
                    </span>
                  )}
                  <Flex mt={4}>
                    <Button
                      bg="#4318FF"
                      borderRadius="6px"
                      width="100px"
                      _hover={{ bg: "#2B3674" }}
                      mr={3}
                      size="sm"
                      type="submit"
                      isLoading={isAddCommentLoading}
                    >
                      <Text color={"#FFFFFF"}>Save</Text>
                    </Button>
                    <Button
                      variant="outline"
                      borderRadius="6px"
                      colorScheme="#2B3674"
                      _hover={{ bg: "#F0F8FF" }}
                      size="sm"
                      width="100px"
                      onClick={() => setShowInput(false)}
                    >
                      <Text color={"#2B3674"}>Cancel</Text>
                    </Button>
                  </Flex>
                </Box>
              </form>
            ) : (
              <>
                {isClosed ? null : (
                  <>
                    {getDashboardPermissions(
                      "ticketing.ticket.comment_create"
                    ) ? (
                      <Button
                        color="#4F46BA"
                        borderRadius="6px"
                        borderColor="#4F46BA"
                        bg="white"
                        size="md"
                        onClick={onCancel}
                        variant="outline"
                      >
                        + Add Comments
                      </Button>
                    ) : null}
                  </>
                )}
              </>
            )}
          </Box>
        </Card>
      )}
    </Box>
  );
};

export default TimeLogs;
