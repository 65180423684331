import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Text,
  Button,
  Tooltip,
  Flex,
  Input,
  Icon,
  useDisclosure,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import { AddIcon, ArrowBackIcon } from "@chakra-ui/icons";
import { AddGroupMenu, DeleteNodeIcon } from "components/icons/Icons";
import useNodeTypes from "hooks/useNodeTypes";
import { MdEdit } from "react-icons/md";
import NodeModal from "components/modal/nodeModal";
import NodeEditModal from "./nodeEditModal";
import useAddGroup from "hooks/useAddGroup";
import useGroup from "hooks/useGroup";
import NodeLoader from "./nodeLoader";

const CreateGroup = ({ setShowCreateGroup, entityId }) => {
  const {
    isLoading: isNodeLoading,
    error: nodeError,
    data: nodeTypeData,
  } = useNodeTypes();

  const outsideRef = useRef();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isNodeOpen,
    onOpen: onNodeOpen,
    onClose: onNodeClose,
  } = useDisclosure();
  const [groupName, setGroupName] = useState("Untitled Group");
  const [id, setId] = useState(null);
  const [disabled, setDisabled] = useState(true);
  const { isLoading, error, data: groupData } = useGroup(entityId);
  const handleClickOutside = (e) => {
    if (!outsideRef.current.contains(e.target)) {
      setEdit(false);
    }
  };
  useEffect(() => {
    if (id !== null) {
      setDisabled(false);
    }
  }, [id]);
  // useEffect(() => {
  //   if (groupData?.length > 0) {
  //     setGroupName(groupData[groupData?.length - 1]?.name);
  //   }
  // }, []);
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  const [edit, setEdit] = useState(true);
  const { mutate: addGroup } = useAddGroup(setId, setEdit, setShowCreateGroup);
  const [nodeData, setNodeData] = useState(null);

  if (isNodeLoading) {
    return <NodeLoader />;
  }

  const handleAddNodeType = (node) => {
    setNodeData(node);
    onNodeOpen();
  };

  const getGroup = (id) => {
    const group = groupData?.data?.filter((group) => group?.id === id);
    return group;
  };

  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      if (!groupName.replace(/\s/g, "").length) {
        addGroup({ name: "Untitled Group", order: 2, entity_id: entityId });
      } else {
        addGroup({ name: groupName, order: 2, entity_id: entityId });
      }
    }
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card bg="rgba(67, 24, 255, 0.02)" height="835px">
        <Flex align="center">
          <div
            onClick={() => setShowCreateGroup(false)}
            style={{ cursor: "pointer" }}
          >
            <Icon as={ArrowBackIcon} w={6} h={6} />
          </div>
          <Tooltip hasArrow label="Rename" bg="#2B3674">
            <div
              onClick={() => setEdit(true)}
              ref={outsideRef}
              style={{ marginRight: "10px" }}
            >
              {edit ? (
                <form onKeyDown={onKeyDownHandler}>
                  <Input
                    type="text"
                    id="fname"
                    name="fname"
                    size="sm"
                    ml="20px"
                    value={groupName}
                    onChange={(event) => setGroupName(event.target.value)}
                  />
                  {/* <input type="text" id="fname" name="fname" value={groupName} onChange={(event) => setGroupName(event.target.value)} /> */}
                </form>
              ) : (
                <Text
                  fontSize={"26px"}
                  color="#2B3674"
                  fontWeight="bold"
                  ml="20px"
                >
                  {groupName}
                </Text>
              )}
            </div>
          </Tooltip>
          <Button
            w="35px"
            h="30px"
            bg="#FFFFFF"
            borderRadius="7px"
            ml="20px"
            mr="10px"
            onClick={() => onOpen()}
            isDisabled={disabled}
          >
            {" "}
            <Icon as={AddIcon} w={3} h={3} color="#4318FF" />
          </Button>
          <AddGroupMenu />
        </Flex>
        <Flex mt="20px">
          {id &&
            getGroup(id)[0]?.node?.length > 0 &&
            getGroup(id)[0]?.node?.map((node) => (
              <Card width="325px" height="98px" mr="20px">
                <Flex align="center" justify="space-between">
                  <Text fontSize={"17px"} color="#2B3674" fontWeight="bold">
                    {node.name}
                  </Text>
                  <Flex align="center">
                    <div
                      style={{ paddingRight: "10px", cursor: "pointer" }}
                      onClick={() => handleAddNodeType(node)}
                    >
                      <Icon
                        color="secondaryGray.500"
                        as={MdEdit}
                        w="17px"
                        h="17px"
                      />
                    </div>
                    <DeleteNodeIcon style={{ cursor: "pointer" }} />
                  </Flex>
                </Flex>
                <Flex pt="10px">
                  <Text fontSize={"17px"} color="#A3AED0" fontWeight="bold">
                    {`ip Address : ${node.ip_address}`}
                  </Text>
                </Flex>
              </Card>
            ))}
        </Flex>
      </Card>
      <NodeModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        data={nodeTypeData?.data[0]?.result}
        groupData={groupData?.data}
        groupId={id}
        entityId={entityId}
        setShowCreateGroup={setShowCreateGroup}
      />
      <NodeEditModal
        isOpen={isNodeOpen}
        onOpen={onNodeOpen}
        onClose={onNodeClose}
        data={nodeTypeData?.data[0]?.result}
        nodeData={nodeData}
        groupData={groupData?.data}
        entityId={entityId}
        setShowCreateGroup={setShowCreateGroup}
      />
    </Box>
  );
};

export default CreateGroup;
