import { Flex, Image } from "@chakra-ui/react";
import dayjs from "dayjs";
import { useLocation } from "react-router-dom";
import ApexChart from "./chart";

const PdfReport = () => {
  const clientLogo = localStorage.getItem("client_logo");
  const location = useLocation();
  const data = location.state?.data;
  const entityData = location.state?.otherData;
  const type = location.state?.type;
  const ticketData = location.state?.otherData?.selectedTicketType;

  const nodePerformanceLogs = [];


  return (
    <>
      <div style={{ backgroundColor: "white" }}>
        <Flex justifyContent="flex-end" py="10px" pr="10px">
          <Image
            boxSize="100px"
            src={clientLogo}
            alt="No Logo"
            cursor="pointer"
          />
        </Flex>
        <div
          style={{
            color: "#f3f2f3",
            textAlign: "center",
            backgroundColor: "#6c96b6",
          }}
        >
          {type === "TicketReport" ? (
            <h2>Ticket Report - {ticketData?.name || "-"} </h2>
          ) : (
            <h2>SLA Report</h2>
          )}
        </div>
        {type === "TicketReport" ? (
          <div style={{ width: "100%", float: "right", marginBottom: "20px" }}>
            <table style={{ borderCollapse: "collapse", width: "100%" }}>
              <thead>
                <tr>
                  <th
                    style={{
                      backgroundColor: "#61848a",
                      color: "#f3f2f3",
                      border: "1px solid #dddddd",
                      padding: "8px",
                    }}
                  >
                    SLA Period
                  </th>
                  <th
                    style={{
                      backgroundColor: "#61848a",
                      color: "#f3f2f3",
                      border: "1px solid #dddddd",
                      padding: "8px",
                    }}
                  >
                    From
                  </th>
                  <td
                    style={{
                      color: "#f3f2f3",
                      backgroundColor: "#655f5f",
                      border: "1px solid #dddddd",
                      padding: "8px",
                    }}
                  >
                    {dayjs(entityData?.startDate)?.format("DD MMM YYYY")}, 12:00
                    Am
                  </td>
                  <th
                    style={{
                      color: "#f3f2f3",
                      backgroundColor: "#655f5f",
                      border: "1px solid #dddddd",
                      padding: "8px",
                    }}
                  >
                    To
                  </th>
                  <td
                    style={{
                      color: "#f3f2f3",
                      backgroundColor: "#655f5f",
                      border: "1px solid #dddddd",
                      padding: "8px",
                    }}
                  >
                    {dayjs(entityData?.endDate)?.format("DD MMM YYYY")}, 11:59
                    Pm
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td
                    scope="row"
                    colSpan={2}
                    style={{
                      backgroundColor: "#61848a",
                      color: "#f3f2f3",
                      textAlign: "center",
                      border: "1px solid #dddddd",
                      padding: "8px",
                    }}
                  >
                    <b>Resolved Ticket (%age) :</b>
                  </td>
                  <td
                    scope="row"
                    colSpan={3}
                    style={{
                      backgroundColor: "#538234",
                      color: "#f3f2f3",
                      textAlign: "center",
                      border: "1px solid #dddddd",
                      padding: "8px",
                    }}
                  >
                    {`${data?.data?.performance[0]?.resolved_percentage}%`}
                  </td>
                </tr>
                <tr>
                  <td
                    scope="row"
                    colSpan={2}
                    style={{
                      backgroundColor: "#76ac92",
                      color: "#f3f2f3",
                      border: "1px solid #dddddd",
                      padding: "8px",
                    }}
                  >
                    <b>Total Ticket:</b>
                  </td>
                  <td
                    scope="row"
                    colSpan={3}
                    style={{
                      color: "#f3f2f3",
                      backgroundColor: "#655f5f",
                      textAlign: "center",
                      border: "1px solid #dddddd",
                      padding: "8px",
                    }}
                  >
                    {data?.data?.performance[0]?.total_ticket}
                  </td>
                </tr>

                <tr>
                  <td
                    scope="row"
                    colSpan={2}
                    style={{
                      backgroundColor: "#76ac92",
                      color: "#f3f2f3",
                      border: "1px solid #dddddd",
                      padding: "8px",
                    }}
                  >
                    <b>Resolved Ticket :</b>
                  </td>
                  <td
                    scope="row"
                    colSpan={3}
                    style={{
                      color: "#f3f2f3",
                      backgroundColor: "#655f5f",
                      textAlign: "center",
                      border: "1px solid #dddddd",
                      padding: "8px",
                    }}
                  >
                    {data?.data?.performance[0]?.total_resolved_ticket}
                  </td>
                </tr>
                <tr>
                  <td
                    scope="row"
                    colSpan={2}
                    style={{
                      backgroundColor: "#76ac92",
                      color: "#f3f2f3",
                      border: "1px solid #dddddd",
                      padding: "8px",
                    }}
                  >
                    <b>Pending Ticket :</b>
                  </td>
                  <td
                    scope="row"
                    colSpan={3}
                    style={{
                      color: "#f3f2f3",
                      backgroundColor: "#655f5f",
                      textAlign: "center",
                      border: "1px solid #dddddd",
                      padding: "8px",
                    }}
                  >
                    {data?.data?.performance[0]?.total_pending_ticket}
                  </td>
                </tr>

                <tr>
                  <td
                    scope="row"
                    colSpan={2}
                    style={{
                      backgroundColor: "#61848a",
                      color: "#f3f2f3",
                      textAlign: "center",
                      border: "1px solid #dddddd",
                      padding: "8px",
                    }}
                  >
                    <b>Average Resolution Time(min) :</b>
                  </td>
                  <td
                    scope="row"
                    colSpan={3}
                    style={{
                      backgroundColor: "#538234",
                      color: "#f3f2f3",
                      textAlign: "center",
                      border: "1px solid #dddddd",
                      padding: "8px",
                    }}
                  >
                    {data?.data?.performance[0]?.average_resolution_time
                      ? `${data?.data?.performance[0]?.average_resolution_time} min`
                      : "-"}
                  </td>
                </tr>

                <tr>
                  <td
                    scope="row"
                    colSpan={2}
                    style={{
                      backgroundColor: "#76ac92",
                      color: "#f3f2f3",
                      border: "1px solid #dddddd",
                      padding: "8px",
                    }}
                  >
                    <b>High Priority Ticket:</b>
                  </td>
                  <td
                    scope="row"
                    colSpan={3}
                    style={{
                      color: "#f3f2f3",
                      backgroundColor: "#655f5f",
                      textAlign: "center",
                      border: "1px solid #dddddd",
                      padding: "8px",
                    }}
                  >
                    {data?.data?.performance[0]?.total_high_priority}
                  </td>
                </tr>
                <tr>
                  <td
                    scope="row"
                    colSpan={2}
                    style={{
                      backgroundColor: "#76ac92",
                      color: "#f3f2f3",
                      border: "1px solid #dddddd",
                      padding: "8px",
                    }}
                  >
                    <b>Medium Priority Ticket :</b>
                  </td>
                  <td
                    scope="row"
                    colSpan={3}
                    style={{
                      color: "#f3f2f3",
                      backgroundColor: "#655f5f",
                      textAlign: "center",
                      border: "1px solid #dddddd",
                      padding: "8px",
                    }}
                  >
                    {data?.data?.performance[0]?.total_medium_priority}
                  </td>
                </tr>

                <tr>
                  <td
                    scope="row"
                    colSpan={2}
                    style={{
                      backgroundColor: "#76ac92",
                      color: "#f3f2f3",
                      border: "1px solid #dddddd",
                      padding: "8px",
                    }}
                  >
                    <b>Low Priority Ticket :</b>
                  </td>
                  <td
                    scope="row"
                    colSpan={3}
                    style={{
                      color: "#f3f2f3",
                      backgroundColor: "#655f5f",
                      textAlign: "center",
                      border: "1px solid #dddddd",
                      padding: "8px",
                    }}
                  >
                    {data?.data?.performance[0]?.total_low_priority}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        ) : type === "NodeTypeSummary" ? (
          <div style={{ width: "100%", float: "right", marginBottom: "20px" }}>
            <table style={{ borderCollapse: "collapse", width: "100%" }}>
              <thead>
                <tr>
                  <th
                    style={{
                      backgroundColor: "#61848a",
                      color: "#f3f2f3",
                      border: "1px solid #dddddd",
                      padding: "8px",
                    }}
                  >
                    SLA Period
                  </th>
                  <th
                    style={{
                      backgroundColor: "#61848a",
                      color: "#f3f2f3",
                      border: "1px solid #dddddd",
                      padding: "8px",
                    }}
                  >
                    From
                  </th>
                  <td
                    style={{
                      color: "#f3f2f3",
                      backgroundColor: "#655f5f",
                      border: "1px solid #dddddd",
                      padding: "8px",
                    }}
                  >
                    {dayjs(entityData?.startDate)?.format("DD MMM YYYY")}, 12:00
                    Am
                  </td>
                  <th
                    style={{
                      color: "#f3f2f3",
                      backgroundColor: "#655f5f",
                      border: "1px solid #dddddd",
                      padding: "8px",
                    }}
                  >
                    To
                  </th>
                  <td
                    style={{
                      color: "#f3f2f3",
                      backgroundColor: "#655f5f",
                      border: "1px solid #dddddd",
                      padding: "8px",
                    }}
                  >
                    {dayjs(entityData?.endDate)?.format("DD MMM YYYY")}, 11:59
                    Pm
                  </td>
                </tr>
              </thead>
            </table>
          </div>
        ) : (
          <Flex justifyContent="space-between">
            <div
              style={{
                fontWeight: "bold",
                fontSize: "large",
                //backgroundColor: "#666666",
                color: "#f3f2f3",
                width: "29%",
                float: "left",
              }}
            >
              <div style={{ padding: "2px", backgroundColor: "#666666" }}>
                Entity- {entityData?.entityTypeName}
              </div>
              {entityData?.nodeTypeName ? (
                <div
                  style={{
                    padding: "2px",
                    backgroundColor: "#666666",
                    textTransform: "capitalize",
                  }}
                >
                  Device Category- {entityData?.nodeTypeName}
                </div>
              ) : null}
              <Flex alignItems="center" justifyContent="center" mt="40px">
                <ApexChart
                  upTime={
                    data?.data[0]?.entity_performance?.total_effective_uptime_percentage ||
                    data?.data[0]?.node_type_summary_performance
                      ?.total_effective_uptime_percentage
                  }
                />
              </Flex>
            </div>

            <div style={{ width: "70%", float: "right", marginBottom: "20px" }}>
              <table style={{ borderCollapse: "collapse", width: "100%" }}>
                <thead>
                  <tr>
                    <th
                      style={{
                        backgroundColor: "#61848a",
                        color: "#f3f2f3",
                        border: "1px solid #dddddd",
                        padding: "8px",
                      }}
                    >
                      SLA Period
                    </th>
                    <th
                      style={{
                        backgroundColor: "#61848a",
                        color: "#f3f2f3",
                        border: "1px solid #dddddd",
                        padding: "8px",
                      }}
                    >
                      From
                    </th>
                    <td
                      style={{
                        color: "#f3f2f3",
                        backgroundColor: "#655f5f",
                        border: "1px solid #dddddd",
                        padding: "8px",
                      }}
                    >
                      {dayjs(entityData?.startDate)?.format("DD MMM YYYY")},
                      12:00 Am
                    </td>
                    <th
                      style={{
                        color: "#f3f2f3",
                        backgroundColor: "#655f5f",
                        border: "1px solid #dddddd",
                        padding: "8px",
                      }}
                    >
                      To
                    </th>
                    <td
                      style={{
                        color: "#f3f2f3",
                        backgroundColor: "#655f5f",
                        border: "1px solid #dddddd",
                        padding: "8px",
                      }}
                    >
                      {dayjs(entityData?.endDate)?.format("DD MMM YYYY")}, 11:59
                      Pm
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td
                      scope="row"
                      colSpan={2}
                      style={{
                        backgroundColor: "#61848a",
                        color: "#f3f2f3",
                        textAlign: "center",
                        border: "1px solid #dddddd",
                        padding: "8px",
                      }}
                    >
                      <b>Effective Up Time (%age)</b>
                    </td>
                    <td
                      scope="row"
                      colSpan={3}
                      style={{
                        backgroundColor: "#538234",
                        color: "#f3f2f3",
                        textAlign: "center",
                        border: "1px solid #dddddd",
                        padding: "8px",
                      }}
                    >
                      {type === "NodeTypeSummary"
                        ? data?.data[0]?.node_type_summary_performance
                          ?.total_effective_uptime_percentage
                        : `${data?.data[0]?.entity_performance?.total_effective_uptime_percentage}`}
                      %
                    </td>
                  </tr>
                  {type == "NodeTypeSummary" ||
                    (type == "NodeType" && false) ? (
                    ""
                  ) : (
                    <>


                    </>
                  )}

                  <tr>
                    <td
                      scope="row"
                      colSpan={2}
                      style={{
                        backgroundColor: "#76ac92",
                        color: "#f3f2f3",
                        border: "1px solid #dddddd",
                        padding: "8px",
                      }}
                    >
                      <b>Total Up Time (%)</b>
                    </td>
                    <td
                      scope="row"
                      colSpan={3}
                      style={{
                        color: "#f3f2f3",
                        backgroundColor: "#655f5f",
                        textAlign: "center",
                        border: "1px solid #dddddd",
                        padding: "8px",
                      }}
                    >
                      {type == "NodeType"
                        ? `${data?.data[0]?.entity_performance?.uptime_percentage}%`
                        : `${data?.data[0]?.entity_performance?.uptime_percentage}%` ||
                        `${data?.data[0]?.node_type_summary_performance?.uptime_percentage}%`}
                    </td>
                  </tr>
                  <tr>
                    <td
                      scope="row"
                      colSpan={2}
                      style={{
                        backgroundColor: "#76ac92",
                        color: "#f3f2f3",
                        border: "1px solid #dddddd",
                        padding: "8px",
                      }}
                    >
                      <b>Total Schceduled Down Time(%)</b>
                    </td>
                    <td
                      scope="row"
                      colSpan={3}
                      style={{
                        color: "#f3f2f3",
                        backgroundColor: "#655f5f",
                        textAlign: "center",
                        border: "1px solid #dddddd",
                        padding: "8px",
                      }}
                    >
                      {type == "NodeType"
                        ? `${data?.data[0]?.entity_performance?.total_schedule_downtime_percentage}%`
                        : `${data?.data[0]?.entity_performance?.total_schedule_downtime_percentage}%` ||
                        `${data?.data[0]?.node_type_summary_performance?.total_schedule_downtime_percentage}%`}
                    </td>
                  </tr>
                  <tr>
                    <td
                      scope="row"
                      colSpan={2}
                      style={{
                        backgroundColor: "#76ac92",
                        color: "#f3f2f3",
                        border: "1px solid #dddddd",
                        padding: "8px",
                      }}
                    >
                      <b>Approved Down Time -NOPD (%)</b>
                    </td>
                    <td
                      scope="row"
                      colSpan={3}
                      style={{
                        color: "#f3f2f3",
                        backgroundColor: "#655f5f",
                        textAlign: "center",
                        border: "1px solid #dddddd",
                        padding: "8px",
                      }}
                    >
                      {type == "NodeType"
                        ? `${data?.data[0]?.entity_performance?.total_nopd_percentage}%`
                        : `${data?.data[0]?.entity_performance?.total_nopd_percentage}%` ||
                        `${data?.data[0]?.node_type_summary_performance?.total_nopd_percentage}%`}
                    </td>
                  </tr>
                  <tr>
                    <td
                      scope="row"
                      colSpan={2}
                      style={{
                        backgroundColor: "#76ac92",
                        color: "#f3f2f3",
                        border: "1px solid #dddddd",
                        padding: "8px",
                      }}
                    >
                      <b>Effective Down Time (%)</b>
                    </td>
                    <td
                      scope="row"
                      colSpan={3}
                      style={{
                        color: "#f3f2f3",
                        backgroundColor: "#655f5f",
                        textAlign: "center",
                        border: "1px solid #dddddd",
                        padding: "8px",
                      }}
                    >
                      {type == "NodeType"
                        ? `${data?.data[0]?.entity_performance?.total_effective_downtime_percentage}%`
                        : `${data?.data[0]?.entity_performance?.total_effective_downtime_percentage}%` ||
                        `${data?.data[0]?.node_type_summary_performance?.total_effective_downtime_percentage}%`}
                    </td>
                  </tr>
                  {/* <tr>
                    <td
                      scope="row"
                      colSpan={2}
                      style={{
                        backgroundColor: "#76ac92",
                        color: "#f3f2f3",
                        border: "1px solid #dddddd",
                        padding: "8px",
                      }}
                    >
                      <b>Up Time (%)</b>
                    </td>
                    <td
                      scope="row"
                      colSpan={3}
                      style={{
                        color: "#f3f2f3",
                        backgroundColor: "#655f5f",
                        textAlign: "center",
                        border: "1px solid #dddddd",
                        padding: "8px",
                      }}
                    >
                      {type == "NodeType"
                        ? `${data?.data[0]?.entity_performance?.uptime_percentage}%`
                        : `${data?.data[0]?.entity_performance?.uptime_percentage}%` ||
                        `${data?.data[0]?.node_type_summary_performance?.uptime_percentage}%`}
                    </td>
                  </tr> */}
                </tbody>
              </table>
            </div>
          </Flex>
        )}


        <br />
        <div style={{ marginTop: "20px" }}>
          {type === "TicketReport" ? (
            <table
              style={{
                width: "100%",
                borderCollapse: "collapse",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      backgroundColor: "#666666",
                      color: "#f3f2f3",
                      border: "1px solid #dddddd",
                      padding: "2px",
                    }}
                  >
                    ID
                  </th>
                  <th
                    style={{
                      backgroundColor: "#666666",
                      color: "#f3f2f3",
                      border: "1px solid #dddddd",
                      padding: "2px",
                    }}
                  >
                    Entity
                  </th>
                  <th
                    style={{
                      backgroundColor: "#666666",
                      color: "#f3f2f3",
                      border: "1px solid #dddddd",
                      padding: "8px",
                    }}
                  >
                    Node Type
                  </th>
                  <th
                    style={{
                      backgroundColor: "#666666",
                      color: "#f3f2f3",
                      border: "1px solid #dddddd",
                      padding: "8px",
                    }}
                  >
                    Node Name
                  </th>

                  <th
                    style={{
                      backgroundColor: "#666666",
                      color: "#f3f2f3",
                      border: "1px solid #dddddd",
                      padding: "8px",
                    }}
                  >
                    Status
                  </th>
                  <th
                    style={{
                      backgroundColor: "#666666",
                      color: "#f3f2f3",
                      border: "1px solid #dddddd",
                      padding: "8px",
                    }}
                  >
                    Created At
                  </th>
                  <th
                    style={{
                      backgroundColor: "#666666",
                      color: "#f3f2f3",
                      border: "1px solid #dddddd",
                      padding: "8px",
                    }}
                  >
                    Resolved On
                  </th>
                  <th
                    style={{
                      backgroundColor: "#666666",
                      color: "#f3f2f3",
                      border: "1px solid #dddddd",
                      padding: "8px",
                    }}
                  >
                    Resolved By
                  </th>
                  <th
                    style={{
                      backgroundColor: "#666666",
                      color: "#f3f2f3",
                      border: "1px solid #dddddd",
                      padding: "2px",
                    }}
                  >
                    Resolution
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.data?.ticket_data.length > 0 &&
                  data?.data?.ticket_data?.map((row, index) => (
                    <tr
                      style={{ backgroundColor: "#fff", color: "#000" }}
                      key={index}
                    >
                      <td
                        style={{
                          border: "1px solid #dddddd",
                          padding: "2px",
                          textAlign: "center",
                        }}
                      >
                        {row?.ticket_id}
                      </td>
                      <td
                        style={{
                          border: "1px solid #dddddd",
                          padding: "2px",
                          textAlign: "center",
                        }}
                      >
                        {row?.entity}
                      </td>
                      <td
                        style={{
                          border: "1px solid #dddddd",
                          padding: "2px",
                          textAlign: "center",
                        }}
                      >
                        {row?.node_type || "-"}
                      </td>
                      <td
                        style={{
                          border: "1px solid #dddddd",
                          padding: "2px",
                          textAlign: "center",
                        }}
                      >
                        {row?.device_name || "-"}
                      </td>

                      <td
                        style={{
                          border: "1px solid #dddddd",
                          padding: "2px",
                          textAlign: "center",
                        }}
                      >
                        {row?.status || "-"}
                      </td>

                      <td
                        style={{
                          border: "1px solid #dddddd",
                          padding: "2px",
                          textAlign: "center",
                        }}
                      >
                        {row.created_at || "-"}
                      </td>
                      <td
                        style={{
                          border: "1px solid #dddddd",
                          padding: "2px",
                          textAlign: "center",
                        }}
                      >
                        {row.end_at || "-"}
                      </td>
                      <td
                        style={{
                          border: "1px solid #dddddd",
                          padding: "2px",
                          textAlign: "center",
                        }}
                      >
                        {row?.resolved_by || "-"}
                      </td>
                      <td
                        style={{
                          border: "1px solid #dddddd",
                          padding: "2px",
                          textAlign: "center",
                        }}
                      >
                        {row?.resolution || "-"}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          ) : type === "NodeTypeSummary" ? (
            <table
              style={{
                borderCollapse: "collapse",
                width: "100%",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      backgroundColor: "#666666",
                      color: "#f3f2f3",
                      border: "1px solid #dddddd",
                      padding: "2px",
                    }}
                  >
                    Device
                  </th>

                  <th
                    style={{
                      backgroundColor: "#666666",
                      color: "#f3f2f3",
                      border: "1px solid #dddddd",
                      padding: "8px",
                    }}
                  >
                    UpTime
                  </th>
                  <th
                    style={{
                      backgroundColor: "#666666",
                      color: "#f3f2f3",
                      border: "1px solid #dddddd",
                      padding: "8px",
                    }}
                  >
                    Schedule Down Time
                  </th>
                  <th
                    style={{
                      backgroundColor: "#666666",
                      color: "#f3f2f3",
                      border: "1px solid #dddddd",
                      padding: "8px",
                    }}
                  >
                    Down Time NOPD/Maintenance
                  </th>
                  <th
                    style={{
                      backgroundColor: "#666666",
                      color: "#f3f2f3",
                      border: "1px solid #dddddd",
                      padding: "2px",
                    }}
                  >
                    Effective Down Time
                  </th>
                  <th
                    style={{
                      backgroundColor: "#666666",
                      color: "#f3f2f3",
                      border: "1px solid #dddddd",
                      padding: "2px",
                    }}
                  >
                    Effective Up Time
                  </th>

                </tr>
              </thead>
              <tbody>
              </tbody>
              {data?.data[0]?.summary_detail
                ? Object?.values(
                  data?.data[0]?.summary_detail
                )?.map((nodes, index) => (
                  <tr
                    style={{ backgroundColor: "#fff", color: "#000" }}
                    key={index}
                  >
                    <td
                      style={{
                        border: "1px solid #dddddd",
                        padding: "2px",
                        textAlign: "center",
                      }}
                    >
                      {nodes?.node_details?.node_name ||
                        nodes?.node_type_name}
                    </td>

                    <td
                      style={{
                        border: "1px solid #dddddd",
                        padding: "2px",
                        textAlign: "center",
                      }}
                    >
                      {nodes?.uptime_percentage || 0}
                    </td>
                    <td
                      style={{
                        border: "1px solid #dddddd",
                        padding: "2px",
                        textAlign: "center",
                      }}
                    >
                      {nodes?.schedule_downtime_percentage || 0}

                    </td>
                    <td
                      style={{
                        border: "1px solid #dddddd",
                        padding: "2px",
                        textAlign: "center",
                      }}
                    >
                      {nodes?.nopd_percentage || 0}

                    </td>
                    <td
                      style={{
                        border: "1px solid #dddddd",
                        padding: "2px",
                        textAlign: "center",
                      }}
                    >
                      {nodes?.effective_downtime_percentage || 0}
                    </td>

                    <td
                      style={{
                        border: "1px solid #dddddd",
                        padding: "2px",
                        textAlign: "center",
                      }}
                    >
                      {nodes?.effective_uptime_percentage || 0}
                    </td>
                  </tr>
                ))
                : ""}
            </table>
          ) : (
            <table
              style={{
                borderCollapse: "collapse",
                width: "100%",
              }}
            >
              <thead>
                <tr>
                  <th
                    style={{
                      backgroundColor: "#666666",
                      color: "#f3f2f3",
                      border: "1px solid #dddddd",
                      padding: "2px",
                    }}
                  >
                    Device
                  </th>
                  <th
                    style={{
                      backgroundColor: "#666666",
                      color: "#f3f2f3",
                      border: "1px solid #dddddd",
                      padding: "2px",
                    }}
                  >
                    Ip Address
                  </th>

                  <th
                    style={{
                      backgroundColor: "#666666",
                      color: "#f3f2f3",
                      border: "1px solid #dddddd",
                      padding: "8px",
                    }}
                  >
                    UpTime
                  </th>
                  <th
                    style={{
                      backgroundColor: "#666666",
                      color: "#f3f2f3",
                      border: "1px solid #dddddd",
                      padding: "8px",
                    }}
                  >
                    Schedule Down Time
                  </th>
                  <th
                    style={{
                      backgroundColor: "#666666",
                      color: "#f3f2f3",
                      border: "1px solid #dddddd",
                      padding: "8px",
                    }}
                  >
                    Down Time NOPD/Maintenance
                  </th>
                  <th
                    style={{
                      backgroundColor: "#666666",
                      color: "#f3f2f3",
                      border: "1px solid #dddddd",
                      padding: "2px",
                    }}
                  >
                    Effective Down Time
                  </th>
                  <th
                    style={{
                      backgroundColor: "#666666",
                      color: "#f3f2f3",
                      border: "1px solid #dddddd",
                      padding: "2px",
                    }}
                  >
                    Effective Up Time
                  </th>

                </tr>
              </thead>
              <tbody>
                {data?.data[0]?.node_performance ||
                  data?.data[0]?.summary_detail
                  ? Object?.values(
                    data?.data[0]?.node_performance ||
                    data?.data[0]?.summary_detail
                  )?.map((nodes, index) => {
                    if (nodes?.node_log?.length) nodePerformanceLogs.push(...nodes?.node_log);
                    return (
                      <tr
                        style={{ backgroundColor: "#fff", color: "#000" }}
                        key={index}
                      >
                        <td
                          style={{
                            border: "1px solid #dddddd",
                            padding: "2px",
                            textAlign: "center",
                          }}
                        >
                          {nodes?.node_details?.node_name ||
                            nodes?.node_type_name}
                        </td>
                        <td
                          style={{
                            border: "1px solid #dddddd",
                            padding: "2px",
                            textAlign: "center",
                          }}
                        >
                          {nodes?.node_details?.ip_address || "-"}
                        </td>

                        <td
                          style={{
                            border: "1px solid #dddddd",
                            padding: "2px",
                            textAlign: "center",
                          }}
                        >
                          {type == "NodeType"
                            ? `${nodes?.node_details?.uptime_percentage}%`
                            : `${nodes?.node_details?.uptime_percentage}%` ||
                            `${nodes?.uptime_percentage}%`}
                        </td>

                        <td
                          style={{
                            border: "1px solid #dddddd",
                            padding: "2px",
                            textAlign: "center",
                          }}
                        >
                          {type == "NodeType"
                            ? `${nodes?.node_details?.schedule_downtime_percentage}%`
                            : `${nodes?.node_details?.schedule_downtime_percentage}%` ||
                            `${nodes?.schedule_downtime_percentage}%`}
                        </td>
                        <td
                          style={{
                            border: "1px solid #dddddd",
                            padding: "2px",
                            textAlign: "center",
                          }}
                        >
                          {type == "NodeType"
                            ? `${nodes?.node_details?.nopd_percentage}%`
                            : `${nodes?.node_details?.nopd_percentage}%` ||
                            `${nodes?.nopd_percentage}%`}
                        </td>
                        <td
                          style={{
                            border: "1px solid #dddddd",
                            padding: "2px",
                            textAlign: "center",
                          }}
                        >
                          {type == "NodeType"
                            ? `${nodes?.node_details?.effective_downtime_percentage}%`
                            : `${nodes?.node_details?.effective_downtime_percentage}%` ||
                            `${nodes?.effective_downtime_percentage}%`}
                        </td>
                        <td
                          style={{
                            border: "1px solid #dddddd",
                            padding: "2px",
                            textAlign: "center",
                          }}
                        >
                          {type == "NodeType"
                            ? `${nodes?.node_details?.effective_uptime_percentage}%`
                            : `${nodes?.node_details?.effective_uptime_percentage}%` ||
                            `${nodes?.effective_uptime_percentage}%`}
                        </td>

                      </tr>
                    )
                  })
                  : ""}
                <tr></tr>
              </tbody>
            </table>
          )}
        </div>
        <br />

        <div className="node_logs">
          {(nodePerformanceLogs && nodePerformanceLogs?.length > 0) && (
            <table style={{ borderCollapse: "collapse", width: "100%" }}>
              <thead>
                <tr>
                  <th
                    style={{
                      backgroundColor: "#666666",
                      color: "#f3f2f3",
                      border: "1px solid #dddddd",
                      padding: "8px",
                      textAlign: "center",
                    }}
                  >
                    Device
                  </th>
                  <th
                    style={{
                      backgroundColor: "#666666",
                      color: "#f3f2f3",
                      border: "1px solid #dddddd",
                      padding: "8px",
                      textAlign: "center",
                    }}
                  >
                    From
                  </th>
                  <th
                    style={{
                      backgroundColor: "#666666",
                      color: "#f3f2f3",
                      border: "1px solid #dddddd",
                      padding: "8px",
                      textAlign: "center",
                    }}
                  >
                    To
                  </th>
                  <th
                    style={{
                      backgroundColor: "#666666",
                      color: "#f3f2f3",
                      border: "1px solid #dddddd",
                      padding: "8px",
                      textAlign: "center",
                    }}
                  >
                    Duration
                  </th>

                  <th
                    style={{
                      backgroundColor: "#666666",
                      color: "#f3f2f3",
                      border: "1px solid #dddddd",
                      padding: "8px",
                      textAlign: "center",
                    }}
                  >
                    Approved Downtime Duration
                  </th>
                  <th
                    style={{
                      backgroundColor: "#666666",
                      color: "#f3f2f3",
                      border: "1px solid #dddddd",
                      padding: "8px",
                      textAlign: "center",
                    }}
                  >
                    Approved Downtime Reason
                  </th>
                  <th
                    style={{
                      backgroundColor: "#666666",
                      color: "#f3f2f3",
                      border: "1px solid #dddddd",
                      padding: "8px",
                      textAlign: "center",
                    }}
                  >
                    Schedule
                  </th>
                  <th
                    style={{
                      backgroundColor: "#666666",
                      color: "#f3f2f3",
                      border: "1px solid #dddddd",
                      padding: "8px",
                      textAlign: "center",
                    }}
                  >
                    State
                  </th>
                </tr>
              </thead>
              <tbody>
                {nodePerformanceLogs?.map((node) => (
                  
                  <tr>
                    <td
                      style={{
                        border: "1px solid #dddddd",
                        padding: "8px",
                        backgroundColor: "#6e8fb8",
                        color: "#f3f2f3",
                        textAlign: "center",
                      }}
                    >
                      {node?.name}                      
                    </td>
                    <td
                      style={{
                        border: "1px solid #dddddd",
                        padding: "8px",
                        textAlign: "center",
                      }}
                    >
                      {`${dayjs(node?.start_at).format(
                        "DD MMM YYYY"
                      )}, ${dayjs(node?.start_at).format("hh:mm a")}`}
                    </td>
                    <td
                      style={{
                        border: "1px solid #dddddd",
                        padding: "8px",
                        textAlign: "center",
                      }}
                    >
                      {`${dayjs(node?.end_at).format(
                        "DD MMM YYYY"
                      )}, ${dayjs(node?.end_at).format("hh:mm a")}`}
                    </td>
                    <td
                      style={{
                        border: "1px solid #dddddd",
                        padding: "8px",
                        textAlign: "center",
                      }}
                    >
                      {node?.duration}
                    </td>
                    <td
                      style={{
                        border: "1px solid #dddddd",
                        padding: "8px",
                        textAlign: "center",
                      }}
                    >
                      {node?.nopd_duration}
                    </td>
                    <td
                      style={{
                        border: "1px solid #dddddd",
                        padding: "8px",
                        textAlign: "center",
                      }}
                    >
                      {node?.breakup_reason}
                    </td>

                    <td
                      style={{
                        border: "1px solid #dddddd",
                        padding: "8px",
                        backgroundColor:
                          node?.is_schedule === "NO"
                            ? "white"
                            : "#3026d6",
                        textAlign: "center",
                      }}
                    >
                      {node?.is_schedule}
                    </td>
                    <td
                      style={{
                        border: "1px solid #dddddd",
                        padding: "8px",
                        backgroundColor:
                          node?.type === "UP" ? "#61bd5a" : "#e67976",
                        textAlign: "center",
                      }}
                    >
                      {node?.type}
                    </td>

                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>
      </div>
    </>
  );
};
export default PdfReport;
