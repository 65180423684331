import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Select,
  Input,
  Flex,
  Box,
  FormLabel,
  Text,
  ModalCloseButton,
  useColorModeValue,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import TicketModuleHooks from "hooks/ticketModule/ticketModuleHooks";
import TmsConfirmationModal from "./tmsConfirmationModal";

const UserAssignModal = ({ onClose, isOpen, onOpen, ticketId, assign }) => {
  const {
    handleSubmit,
    control,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm();
  const textColor = useColorModeValue("navy.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const buttonHover = useColorModeValue("brand.600", "brand.400");
  const [assignTo, setAssignTo] = useState(null);

  const {
    isOpen: isConfOpen,
    onOpen: onConfOpen,
    onClose: onConfClose,
  } = useDisclosure();

  const handleModalClose = () => {
    reset();
    clearErrors();
    onClose();
    setAssignTo(null);
  };

  const {
    data,
    isLoading: isDataLoading,
    error,
  } = TicketModuleHooks.useTicketUserList();

  const { mutate: assignTicket, isLoading } =
    TicketModuleHooks.useAssignTicketSummary(
      ticketId,
      handleModalClose,
      onConfClose
    );

  const generateSelectOptions = () => {
    return data?.data?.map((data) => {
      return (
        <option
          value={data.id}
        >{`${data.user_fname} ${data.user_lname}`}</option>
      );
    });
  };

  const onConfirmation = () => {
    assignTicket({
      ticket_id: ticketId,
      assigned_to: assignTo,
    });
  };

  const onSubmit = (data) => {
    setAssignTo(parseInt(data.user_id, 10));
    onConfOpen();
  };
  return (
    <Box border="1px solid #EAEDF2" borderRadius="10px">
      <Modal
        isOpen={isOpen}
        onClose={handleModalClose}
        blockScrollOnMount={false}
        size="sm"
      >
        <ModalOverlay />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContent>
            <Flex mt="50px" pl="10px">
              <Flex direction="column" width="100%" pr="10px">
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="14px"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  Assign to<Text color={brandStars}>*</Text>
                </FormLabel>
                <div style={{ marginBottom: "10px" }}>
                  <Controller
                    name="user_id"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <Select
                        id="user_type"
                        size="lg"
                        fontSize="14px"
                        color={
                          field.value === "Choose User" ||
                          field.value === null ||
                          field.value === undefined
                            ? "ndmsColors.gray.2200"
                            : "black"
                        }
                        fontWeight={
                          field.value === "Choose User" ||
                          field.value === null ||
                          field.value === undefined
                            ? "bold"
                            : "normal"
                        }
                        {...field}
                      >
                        <option selected disabled hidden value={null}>
                          Choose User
                        </option>
                        {generateSelectOptions()}
                      </Select>
                    )}
                  />
                  {errors.user_id?.type === "required" && (
                    <span style={{ color: "red" }}>Please select an Agent</span>
                  )}
                </div>
              </Flex>
            </Flex>
            <Button
              bg="#4318FF"
              //size="sm"
              borderRadius="6px"
              variant="brand"
              ml={2}
              mt={2}
              mb={10}
              width="140px"
              isLoading={isLoading}
              _hover={{ bg: isLoading ? "blue" : buttonHover }}
              type="submit"
            >
              <Text color={"#FFFFFF"}>Assign</Text>
            </Button>
            <ModalCloseButton />
          </ModalContent>
        </form>
      </Modal>
      <TmsConfirmationModal
        isOpen={isConfOpen}
        onOpen={onConfOpen}
        onClose={onConfClose}
        onConfirmation={onConfirmation}
        isLoading={isLoading}
        text={`Are you sure you want to ${
          assign ? "re-assign" : "assign"
        } this ticket?`}
      />
    </Box>
  );
};

export default UserAssignModal;
