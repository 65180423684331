import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Input,
  InputGroup,
  InputRightElement,
  Flex,
  FormLabel,
  RadioGroup,
  Stack,
  Radio,
  Text,
  ModalCloseButton,
  Button,
  //Select,
  useColorModeValue,
} from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";
import { useForm, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import EntityClientHooks from "hooks/entityClient/entityClientHooks";

const OnBoardModal = ({ isOpen, onClose, onOpen, clientId }) => {
  const {
    handleSubmit,
    control,
    resetField,
    formState: { errors },
  } = useForm();
  const textColor = useColorModeValue("navy.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const buttonHover = useColorModeValue("brand.600", "brand.400");
  const { mutate: onBoardClient, isLoading: isScheduleLoading } =
    EntityClientHooks.useOnBoardClient(onClose);

  const onSubmit = (data) => {
    onBoardClient({
      status: parseInt(data.status),
      reason: data.reason,
      notes: data.notes,
      onboarded_at: data.onboarded_at,
      clientapp_id: clientId,
    });
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        blockScrollOnMount={false}
        onClose={onClose}
        size="lg"
      >
        <ModalOverlay />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContent>
            <ModalHeader>
              <Flex direction="column" w="100%" mt="15px">
                <Flex direction="column" width="100%" pt="10px">
                  <Flex direction="column" pt="10px" width="100%">
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      //mb="8px"
                    >
                      Status<Text color={brandStars}>*</Text>
                    </FormLabel>

                    <div style={{ marginBottom: "24px" }}>
                      <Controller
                        name="status"
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                          <RadioGroup {...field}>
                            <Stack direction="row">
                              <Radio value="1" borderColor="#422AFB" pr="20px">
                                Yes
                              </Radio>
                              <Radio value="0" borderColor="#422AFB" pr="20px">
                                No
                              </Radio>
                            </Stack>
                          </RadioGroup>
                        )}
                      />
                      {errors.status?.type === "required" && (
                        <span style={{ color: "red" }}>Status is required</span>
                      )}
                    </div>
                  </Flex>
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Reason
                  </FormLabel>
                  <div style={{ marginBottom: "24px" }}>
                    <Controller
                      name="reason"
                      control={control}
                      rules={{ required: false }}
                      //defaultValue={editData?.notes || ""}
                      render={({ field }) => (
                        <Input
                          fontSize="sm"
                          placeholder="Enter Reason"
                          size="lg"
                          type="name"
                          {...field}
                        />
                      )}
                    />
                  </div>
                </Flex>
                <Flex direction="column" width="100%" pt="10px">
                  <FormLabel
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    display="flex"
                  >
                    Notes
                  </FormLabel>
                  <div style={{ marginBottom: "24px" }}>
                    <Controller
                      name="notes"
                      control={control}
                      rules={{ required: false }}
                      //defaultValue={editData?.notes || ""}
                      render={({ field }) => (
                        <Input
                          fontSize="sm"
                          placeholder="Enter Notes"
                          size="lg"
                          type="name"
                          {...field}
                        />
                      )}
                    />
                  </div>
                </Flex>
              </Flex>
              <Flex direction="column" width="100%">
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  On Board at<Text color={brandStars}>*</Text>
                </FormLabel>
                <div style={{ marginBottom: "10px" }}>
                  <Controller
                    name={`onboarded_at`}
                    rules={{ required: false }}
                    control={control}
                    //defaultValue={getDefaultDate(editData?.start_at)}
                    render={({ field }) => (
                      <DatePicker
                        wrapperClassName="date-picker"
                        showTimeSelect
                        //selectMultiple={true}
                        customInput={
                          <InputGroup>
                            <Input
                              minWidth="300px"
                              placeholder="From"
                              value={
                                field.value
                                  ? `${field?.value?.toLocaleDateString()}, ${field.value.toLocaleTimeString(
                                      "en-US"
                                    )}`
                                  : ""
                              }
                            />
                            <InputRightElement
                              children={<CalendarIcon color="#4318FF" />}
                            />
                          </InputGroup>
                        }
                        onChange={(date) => {
                          field.onChange(date);
                        }}
                        selected={field.value}
                        dateFormat="MMMM d, yyyy h:mm aa"
                      />
                    )}
                  />
                  {errors.onboarded_at?.type === "required" && (
                    <span style={{ color: "red" }}>
                      on board at is required
                    </span>
                  )}
                </div>
              </Flex>
            </ModalHeader>
            <Button
              bg="#4318FF"
              mx={3}
              mt={2}
              mb={10}
              variant="brand"
              _hover={{ bg: isScheduleLoading ? "blue" : buttonHover }}
              type="submit"
              isLoading={isScheduleLoading}
            >
              <Text color={"#FFFFFF"}>Save</Text>
            </Button>
            <ModalCloseButton />
          </ModalContent>
        </form>
      </Modal>
    </>
  );
};

export default OnBoardModal;
