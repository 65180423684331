import React, { useState, useEffect } from "react";
import Card from "components/card/Card.js";
import DownTimeListTable from "components/table/downTimeListTable";
import MasterConfigHooks from "hooks/masterConfig/masterConfigHooks";
import LoadingForm from "components/loader/LoadingForm";
import noGroupImage from "assets/img/layout/noGroup.png";
import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Tooltip,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import AddConfiguration from "./addConfiguration";
import EditConfiguration from "./editConfiguration";
import { useHistory, useParams } from "react-router-dom";

import { MdEdit } from "react-icons/md";

import DeleteModal from "components/modal/deleteModal";
import { getDashboardPermissions } from "Permissions";

function MasterConfiguration() {
  // Chakra color mode
  const entityId = useParams().id;
  const [currentPage, setCurrentPage] = useState(1);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const { isLoading, error, data, refetch, isFetching } =
    MasterConfigHooks.useMasterConfigList();
  const [deleteId, setDeleteId] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();
  const { mutate: deleteMasterConfig } =
    MasterConfigHooks.useDeleteMasterConfig(onClose);
  const entityName = useParams().name;
  const [editData, setEditData] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showAddForm, showEditForm]);

  if (isLoading || isFetching) return <LoadingForm />;
  // if (error) return toast.error("internal server error");

  const handleDelete = (id) => {
    setDeleteId(id);
    onOpen();
    //deleteEntity(id);
  };
  const handleEntityEdit = (row) => {
    history.push(`/admin/master-configuration/edit`);
    setEditData(row);
    setShowEditForm(true);
  };
  const handleAdd = () => {
    history.push(`/admin/master-configuration/add`);
    setShowAddForm(true);
  };
  const columnsData = [
    {
      Header: "ID",
      accessor: (row) => {
        return (
          <Text
            color={"ndmsColors.purple.200"}
            fontSize="12px"
            fontWeight="700"
          >
            {row.id}
          </Text>
        );
      },
    },
    {
      Header: "Label",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row.key_label}
          </Text>
        );
      },
    },
    {
      Header: "Key",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row.key}
          </Text>
        );
      },
    },
    {
      Header: "Value",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row.default_value}
          </Text>
        );
      },
    },
    {
      Header: "   ",
      accessor: "------",
    },
    {
      Header: "   ",
      accessor: "=======",
    },
    {
      Header: "   ",
      accessor: "++++++++++",
    },
    {
      Header: "ACTION",
      accessor: (row) => {
        return (
          <Flex justifyContent="center" align="center">
            {getDashboardPermissions("config.update") ? (
              <Tooltip hasArrow label="Edit" bg="#2B3674">
                <Flex mr="10px" onClick={() => handleEntityEdit(row)}>
                  <Icon
                    color="ndmsColors.purple.200"
                    as={MdEdit}
                    w="17px"
                    h="17px"
                  />
                </Flex>
              </Tooltip>
            ) : null}
            {getDashboardPermissions("config.delete") ? (
              <Tooltip hasArrow label="Delete" bg="ndmsColors.red.200">
                <Flex mr="10px" onClick={() => handleDelete(row.id)}>
                  <Icon
                    as={DeleteIcon}
                    width="20px"
                    height="15px"
                    color="ndmsColors.red.200"
                  />{" "}
                </Flex>
              </Tooltip>
            ) : null}
          </Flex>
        );
      },
    },
  ];

  return (
    <>
      {showAddForm && (
        <AddConfiguration
          entityId={entityId}
          entityName={entityName}
          setShowAddForm={setShowAddForm}
        />
      )}
      {showEditForm && (
        <EditConfiguration
          setShowEditForm={setShowEditForm}
          editData={editData}
          entityId={entityId}
          entityName={entityName}
        />
      )}
      {!showAddForm && !showEditForm && (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
          <Flex align="center" justifyContent="flex-end">
            {getDashboardPermissions("config.create") ? (
              <Button
                bg="ndmsColors.purple.200"
                minWidth="104px"
                borderRadius="6px"
                mb="42px"
                _hover={{ bg: "ndmsColors.blue.400" }}
                _active={{ bg: "ndmsColors.blue.400" }}
                onClick={handleAdd}
              >
                <Icon as={AddIcon} w={3} h={3} color="#FFF" />
                <Text ml="10px" color="#FFF" fontSize="14px">
                  {" "}
                  Add
                </Text>
              </Button>
            ) : null}
          </Flex>
          <Card
            direction="column"
            w="100%"
            px="0px"
            overflowX={{ sm: "scroll", lg: "hidden" }}
          >
            {data?.data?.length > 0 ? (
              <Flex p="20px" direction="column">
                <DownTimeListTable
                  tableData={data?.data}
                  currentPage={1}
                  columnsData={columnsData}
                  setCurrentPage={setCurrentPage}
                  totalPage={1}
                />
              </Flex>
            ) : (
              <Flex
                align="center"
                justify="center"
                mt="200px"
                cursor="pointer"
                mb="150px"
                onClick={handleAdd}
              >
                <Flex direction="column" justify="center" align="center">
                  <Image src={noGroupImage} width="70px" alt="no-group" />
                  <Text fontSize={"22px"} color="#2B3674" fontWeight="bold">
                    You Don’t Have Any Configuration Added
                  </Text>
                </Flex>
              </Flex>
            )}
          </Card>
        </Box>
      )}
      <DeleteModal
        onOpen={onOpen}
        isOpen={isOpen}
        onClose={onClose}
        id={deleteId}
        deleteItem={deleteMasterConfig}
      />
    </>
  );
}

export default MasterConfiguration;
