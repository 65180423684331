import React, { useState, useEffect } from "react";
import {
  Box,
  Text,
  Select,
  Link,
  Circle,
  Skeleton,
  Flex,
  Divider,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import EntityDashboardHooks from "hooks/entityDashboard/entityDashboardHooks";
import EmptyCard from "components/card/EmptyCard";
import Chart from "react-apexcharts";
import { getDashboardPermissions } from "Permissions";

const EntityCardView = ({
  colorCode,
  toggleCardMode,
  entityId,
  entityName,
}) => {
  const [groupSort, setGroupSort] = useState("desc");
  const [nodeTypeSort, setNodeTypeSort] = useState("desc");
  const [nodesSort, setNodesSort] = useState("desc");
  const {
    isLoading,
    error,
    data,
    refetch: dataRefetch,
    isFetching: isDataFetching,
  } = EntityDashboardHooks.useEntityGroupPerformance(
    entityId,
    groupSort,
    "monthly"
  );
  const {
    isLoading: isNodeTypeLoading,
    data: nodeTypeData,
    refetch: nodeTypeRefetch,
    isFetching: isNodeTypeFetching,
  } = EntityDashboardHooks.useEntityNodetypePerformance(
    entityId,
    nodeTypeSort,
    "monthly"
  );
  const {
    isLoading: isNodesLoading,
    data: nodesData,
    refetch: nodeRefetch,
    isFetching: isNodesFetching,
  } = EntityDashboardHooks.useEntityNodePerformance(
    entityId,
    nodesSort,
    "monthly"
  );
  useEffect(() => {
    dataRefetch();
    nodeTypeRefetch();
    nodeRefetch();
  }, [entityId]);

  const getSplitString = (string) => {
    if (string) {
      var i = string.indexOf(" ");
      var partOne = string.slice(0, i + 10).trim();
      var partTwo = string.slice(i + 6, string.length).trim();
      return partOne;
    } else {
      return " ";
    }
  };

  const handleGroupChange = (e) => {
    setGroupSort(e.target.value);
  };

  const handleNodeTypeChange = (e) => {
    setNodeTypeSort(e.target.value);
  };

  const handleNodesChange = (e) => {
    setNodesSort(e.target.value);
  };

  useEffect(() => {
    dataRefetch();
  }, [groupSort]);

  useEffect(() => {
    nodeTypeRefetch();
  }, [nodeTypeSort]);

  useEffect(() => {
    nodeRefetch();
  }, [nodesSort]);

  const barChartDataGroup = [
    {
      name: "Uptime",
      data: data?.data?.group_health
        ?.slice(0, 5)
        ?.map((data) => data?.downtime_avg),
    },
  ];

  const barChartDataNodeType = [
    {
      name: "mttr",
      data: nodeTypeData?.data?.node_type_health
        ?.slice(0, 5)
        ?.map((data) => data?.downtime_avg),
    },
  ];

  const barChartDataNodes = [
    {
      name: "mtbf",
      data: nodesData?.data?.node_health
        ?.slice(0, 5)
        ?.map((data) => data?.downtime_avg),
    },
  ];

  const barChartOptionsGroup = {
    chart: {
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
      onDatasetHover: {
        style: {
          fontSize: "12px",
          fontFamily: undefined,
        },
      },
      theme: "dark",
    },
    xaxis: {
      categories: data?.data?.group_health
        ?.slice(0, 5)
        ?.map((data) => getSplitString(data?.name)),
      show: true,
      labels: {
        show: true,
        style: {
          colors: "#A3AED0",
          fontSize: "12px",
          fontWeight: "500",
        },
      },
      title: {
        text: "Groups",
        style: {
          color: "#9EA2A9",
          fontSize: "13px",
        },
      },
    },
    yaxis: {
      show: true,
      categories: ["0", "10", "20", "30", "40", "50"],
      color: "black",
      labels: {
        show: true,
        style: {
          colors: "#A3AED0",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      title: {
        text: "Percentage Uptime",
        style: {
          color: "#9EA2A9",
          fontSize: "13px",
        },
      },
    },

    grid: {
      borderColor: "rgba(163, 174, 208, 0.3)",
      show: true,
      yaxis: {
        lines: {
          show: false,
          opacity: 0.5,
        },
      },
      row: {
        opacity: 0.5,
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      type: "solid",
      colors: [colorCode[0]],
    },
    legend: {
      show: true,
    },
    colors: [colorCode],
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: "30px",
      },
    },
  };

  const barChartOptionsNodeType = {
    chart: {
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
      onDatasetHover: {
        style: {
          fontSize: "12px",
          fontFamily: undefined,
        },
      },
      theme: "dark",
    },
    xaxis: {
      categories: nodeTypeData?.data?.node_type_health
        ?.slice(0, 5)
        ?.map((data) => getSplitString(data?.name)),
      show: true,
      labels: {
        show: true,
        style: {
          colors: "#A3AED0",
          fontSize: "12px",
          fontWeight: "500",
        },
      },
      title: {
        text: "Node Type",
        style: {
          color: "#9EA2A9",
          fontSize: "13px",
        },
      },
    },
    yaxis: {
      show: true,
      categories: ["0", "10", "20", "30", "40", "50"],
      color: "black",
      labels: {
        show: true,
        style: {
          colors: "#A3AED0",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      title: {
        text: "Hours",
        style: {
          color: "#9EA2A9",
          fontSize: "13px",
        },
      },
    },

    grid: {
      borderColor: "rgba(163, 174, 208, 0.3)",
      show: true,
      yaxis: {
        lines: {
          show: false,
          opacity: 0.5,
        },
      },
      row: {
        opacity: 0.5,
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      type: "solid",
      colors: [colorCode[1]],
    },
    legend: {
      show: true,
    },
    colors: [colorCode],
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: "30px",
      },
    },
  };

  const barChartOptionsNodes = {
    chart: {
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
      onDatasetHover: {
        style: {
          fontSize: "12px",
          fontFamily: undefined,
        },
      },
      theme: "dark",
    },
    xaxis: {
      categories: nodesData?.data?.node_health
        ?.slice(0, 5)
        ?.map((data) => getSplitString(data?.name)),
      show: true,
      labels: {
        show: true,
        style: {
          colors: "#A3AED0",
          fontSize: "12px",
          fontWeight: "500",
        },
      },
      title: {
        text: "Nodes",
        style: {
          color: "#9EA2A9",
          fontSize: "13px",
        },
      },
    },
    yaxis: {
      show: true,
      categories: ["0", "10", "20", "30", "40", "50"],
      color: "black",
      labels: {
        show: true,
        style: {
          colors: "#A3AED0",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      title: {
        text: "Hours",
        style: {
          color: "#9EA2A9",
          fontSize: "13px",
        },
      },
    },

    grid: {
      borderColor: "rgba(163, 174, 208, 0.3)",
      show: true,
      yaxis: {
        lines: {
          show: false,
          opacity: 0.5,
        },
      },
      row: {
        opacity: 0.5,
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      type: "solid",
      colors: [colorCode[2]],
    },
    legend: {
      show: true,
    },

    colors: [colorCode],
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: "30px",
      },
    },
  };

  return (
    <Box mt="5px">
      <Flex width="100%" wrap="wrap">
        {isLoading || isDataFetching ? (
          <Card mt="20px" mr="10px" minHeight="380px" flexBasis="48%">
            <Skeleton minHeight="360px" flexBasis="40%" />
          </Card>
        ) : (
          <Card
            mt="20px"
            mr="10px"
            minHeight="380px"
            height="max-content"
            flexBasis="48%"
          >
            <Flex alignItems="center" justifyContent="space-between">
              <Text fontSize="18px" fontWeight="bold" color="#2B3674">
                Groups
              </Text>
              <Select
                bg="white"
                fontSize="15px"
                //mt="20px"
                width="200px"
                height="40px"
                id="user_type"
                size="lg"
                ml="10px"
                color="#2C3774"
                fontWeight="bold"
                value={groupSort}
                onChange={handleGroupChange}
              >
                <option value="desc">Top 5</option>
                <option value="asc">Bottom 5</option>
              </Select>
            </Flex>
            {data?.data?.group_health?.length > 0 ? (
              <>
                {toggleCardMode ? (
                  <>
                    {data?.data?.group_health
                      ?.slice(0, 5)
                      .map((data, index) => (
                        <Box key={data?.id}>
                          <Flex
                            mt="20px"
                            pt="10px"
                            justifyContent="space-between"
                          >
                            <Text fontWeight="500" fontSize="14px">
                              {data?.name}
                            </Text>

                            <Text
                              fontWeight="bold"
                              color={colorCode[0]}
                              fontSize="16px"
                            >
                              {data?.downtime_avg} %
                            </Text>
                          </Flex>
                          {index !== 4 ? <Divider pt="10px" /> : null}
                        </Box>
                      ))}
                  </>
                ) : (
                  <Box mt="20px">
                    <Chart
                      options={barChartOptionsGroup}
                      series={barChartDataGroup}
                      type="bar"
                      height="350px"
                      width="100%"
                    />
                  </Box>
                )}
              </>
            ) : (
              <Box mt="70px">
                <EmptyCard />
              </Box>
            )}
          </Card>
        )}
        {isNodeTypeLoading || isNodeTypeFetching ? (
          <Card mt="20px" minHeight="380px" flexBasis="48%">
            <Skeleton minHeight="360px" flexBasis="40%" />
          </Card>
        ) : (
          <Card
            mt="20px"
            minHeight="380px"
            height="max-content"
            flexBasis="48%"
            ml="20px"
          >
            <Flex alignItems="center" justifyContent="space-between">
              <Text fontSize="18px" fontWeight="bold" color="#2B3674">
                Node Type
              </Text>
              <Select
                bg="white"
                fontSize="15px"
                //mt="20px"
                width="200px"
                height="40px"
                id="user_type"
                size="lg"
                ml="10px"
                color="#2C3774"
                fontWeight="bold"
                value={nodeTypeSort}
                onChange={handleNodeTypeChange}
              >
                <option value="desc">Top 5</option>
                <option value="asc">Bottom 5</option>
              </Select>
            </Flex>
            {nodeTypeData?.data?.node_type_health?.length > 0 ? (
              <>
                {toggleCardMode ? (
                  <>
                    {nodeTypeData?.data?.node_type_health
                      ?.slice(0, 5)
                      .map((data, index) => (
                        <Box key={data?.id}>
                          <Flex
                            mt="20px"
                            pt="10px"
                            justifyContent="space-between"
                          >
                            <Text fontWeight="500" fontSize="14px">
                              {data?.name}
                            </Text>

                            <Text
                              fontWeight="bold"
                              color={colorCode[1]}
                              fontSize="16px"
                            >
                              {data?.downtime_avg} %
                            </Text>
                          </Flex>
                          {index !== 4 ? <Divider pt="10px" /> : null}
                        </Box>
                      ))}
                  </>
                ) : (
                  <Box mt="20px">
                    <Chart
                      options={barChartOptionsNodeType}
                      series={barChartDataNodeType}
                      type="bar"
                      height="350px"
                      width="100%"
                    />
                  </Box>
                )}
              </>
            ) : (
              <Box mt="70px">
                <EmptyCard />
              </Box>
            )}
          </Card>
        )}
        {isNodesLoading || isNodesFetching ? (
          <Card mt="20px" minHeight="380px" flexBasis="48%">
            <Skeleton minHeight="360px" flexBasis="40%" />
          </Card>
        ) : (
          <Card
            mt="20px"
            mr="20px"
            minHeight="380px"
            height="max-content"
            flexBasis="48%"
          >
            <Flex alignItems="center" justifyContent="space-between">
              <Text fontSize="18px" fontWeight="bold" color="#2B3674">
                Nodes
              </Text>
              <Select
                bg="white"
                fontSize="15px"
                //mt="20px"
                width="200px"
                height="40px"
                id="user_type"
                size="lg"
                ml="10px"
                color="#2C3774"
                fontWeight="bold"
                value={nodesSort}
                onChange={handleNodesChange}
              >
                <option value="desc">Top 5</option>
                <option value="asc">Bottom 5</option>
              </Select>
            </Flex>

            {nodesData?.data?.node_health?.length > 0 ? (
              <>
                {toggleCardMode ? (
                  <>
                    {nodesData?.data?.node_health
                      ?.slice(0, 5)
                      .map((data, index) => (
                        <Box key={data?.id}>
                          <Flex
                            mt="20px"
                            pt="10px"
                            justifyContent="space-between"
                          >
                            {getDashboardPermissions("node_dashboard.view") ? (
                              <Link
                                href={`/admin/node-dashboard/${entityId}/${entityName}/node_id=${data?.node_id}`}
                              >
                                <Flex alignItems="center">
                                  <Text
                                    fontWeight="500"
                                    fontSize="14px"
                                    pr="10px"
                                  >
                                    {data?.name}
                                  </Text>
                                  <Circle
                                    size="20px"
                                    bg="ndmsColors.gray.1300"
                                    color="ndmsColors.blue.100"
                                  >
                                    {">"}
                                  </Circle>
                                </Flex>
                              </Link>
                            ) : (
                              <Text fontWeight="500" fontSize="14px" pr="10px">
                                {data?.name}
                              </Text>
                            )}

                            <Text
                              fontWeight="bold"
                              color={colorCode[2]}
                              fontSize="16px"
                            >
                              {data?.downtime_avg} %
                            </Text>
                          </Flex>
                          {index !== 4 ? <Divider pt="10px" /> : null}
                        </Box>
                      ))}
                  </>
                ) : (
                  <Box mt="20px">
                    <Chart
                      options={barChartOptionsNodes}
                      series={barChartDataNodes}
                      type="bar"
                      height="350px"
                      width="100%"
                    />
                  </Box>
                )}
              </>
            ) : (
              <Box mt="70px">
                <EmptyCard />
              </Box>
            )}
          </Card>
        )}
      </Flex>
    </Box>
  );
};

export default EntityCardView;
