import {
  Modal,
  ModalOverlay,
  ModalContent,
  Icon,
  Flex,
  Text,
  ModalCloseButton,
  Button,
  useColorModeValue,
  //Select,
} from "@chakra-ui/react";
import { useState } from "react";
import { MdOutlineFileUpload } from "react-icons/md";
import useImageUpload from "hooks/useImageUpload";

function FileUploadModal({
  isOpen,
  onOpen,
  onClose,
  selectedUrl,
  setSelectedUrl,
  setFileId,
}) {
  const buttonHover = useColorModeValue("brand.600", "brand.400");
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const handleModalClose = () => {
    setSelectedFile();
    setIsFilePicked(false);
    onClose();
  };
  const { mutate: imageUpload, isLoading } = useImageUpload(
    setSelectedUrl,
    setFileId,
    selectedFile?.name,
    handleModalClose
  );

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("file", selectedFile);
    imageUpload(formData);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        blockScrollOnMount={false}
        onClose={handleModalClose}
        size="3xl"
      >
        <ModalOverlay />
        <ModalContent>
          <input
            id="file-upload"
            type="file"
            style={{ display: "none" }}
            onChange={changeHandler}
          />
          <label
            for="file-upload"
            class="custom-file-upload"
            style={{
              width: "725px",
              marginLeft: "20px",
              marginTop: "60px",
              border: "1px solid #ccc",
              borderRadius: "10px",
              display: "inline-block",
              padding: "12px 12px",
              cursor: "pointer",
            }}
          >
            <Flex align="center" justify="space-between">
              {isFilePicked ? selectedFile?.name : "File Upload"}{" "}
              <Icon as={MdOutlineFileUpload} w="24px" h="auto" />
            </Flex>
          </label>
          <Button
            bg="#4318FF"
            variant="brand"
            mx={3}
            mt={8}
            mb={10}
            onClick={handleSubmit}
            _hover={{ bg: isLoading ? "blue" : buttonHover }}
            isLoading={isLoading}
          >
            <Text color={"#FFFFFF"}>Submit</Text>
          </Button>
          <ModalCloseButton />
        </ModalContent>
      </Modal>
    </>
  );
}

export default FileUploadModal;
