import { Circle, Flex, Text } from "@chakra-ui/layout";
import EmptyPieChart from "components/charts/emptyPieChart";
import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";

function ScheduleDownChart(props) {
  const { slaPerformanceMetrics, reportFilterType } = props;
  const [remainingTime, setRemainingTime] = useState(100);
  const colorDowntime = "#135CC9";
  const colorNone = "DC5D5D";
  useEffect(() => {
    setRemainingTime(100 - slaPerformanceMetrics?.schedule_down_percentage);
  }, [slaPerformanceMetrics?.schedule_down_percentage]);

  const pieChartOptions = {
    labels: ["Schedule Downtime %", " Remaining Time %"],
    colors: [colorDowntime, colorNone],
    chart: {
      width: "150px",
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    hover: { mode: null },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              label: "Total Time (Hours)",
              color: "#2B3674",
              fontSize: "14px",
              formatter: function () {
                return slaPerformanceMetrics?.total_time;
              },
            },
          },
        },
      },
    },

    series: [
      slaPerformanceMetrics?.schedule_down_percentage != undefined &&
        slaPerformanceMetrics?.schedule_down_percentage,
      remainingTime,
    ],
    fill: {
      colors: [colorDowntime, colorNone],
    },
    tooltip: {
      enabled: true,
      theme: "dark",
    },
  };

  const pieChartData = [
    slaPerformanceMetrics?.schedule_down_percentage != undefined &&
      slaPerformanceMetrics?.schedule_down_percentage,
    remainingTime,
  ];

  return (
    <Flex alignItems="center" pb="38px" borderBottom="1px solid #DCE3EB">
      <Flex flexDirection="column" borderRight="1px solid #DCE3EB " pr="90px">
        <Flex alignItems="center" mt="10px">
          <Text fontSize="16px" color="ndmsColors.gray.2100" mr="10px">
            IP Address:
          </Text>
          <Text fontSize="16px" color="ndmsColors.blue.100">
            {slaPerformanceMetrics?.ip_address}
          </Text>
        </Flex>
        <Flex alignItems="center" mt="10px">
          <Text fontSize="16px" color="ndmsColors.gray.2100" mr="10px">
            Total Available Hours:
          </Text>
          <Text fontSize="16px" color="ndmsColors.blue.100">
            {slaPerformanceMetrics?.total_time}
          </Text>
        </Flex>
        <Flex alignItems="center" mt="10px">
          <Text fontSize="16px" color="ndmsColors.gray.2100" mr="10px">
            Scheduled Downtime (% age):
          </Text>
          <Text fontSize="16px" color="ndmsColors.blue.100">
            {slaPerformanceMetrics?.schedule_down_percentage} %
          </Text>
        </Flex>
      </Flex>
      <Flex ml="100px" alignItems="center">
        <Flex width="200px">
          {slaPerformanceMetrics?.total_time === 0 ? (
            <EmptyPieChart />
          ) : (
            <Chart
              height="220px"
              w="100%"
              type="donut"
              series={pieChartData}
              options={pieChartOptions}
            />
          )}
        </Flex>
        <Flex direction="column" ml="20px">
          <Flex color="ndmsColors.blue.100" fontWeight="bold" mb="20px">
            Time Duration in hours
          </Flex>
          <Flex>
            <Circle mt="5px" size="12px" bg={colorDowntime} />
            <Flex direction="column">
              <Text
                color="ndmsColors.gray.1900"
                fontWeight="bold"
                fontSize="xs"
                pl="5px"
                mb="5px"
              >
                Downtime
              </Text>
              <Text
                color="ndmsColors.blue.100"
                fontWeight="bold"
                fontSize="sm"
                pl="5px"
                mb="5px"
              >
                {slaPerformanceMetrics?.schedule_down_percentage}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default ScheduleDownChart;
