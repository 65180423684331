import { QUERY_KEY, MUTATION_KEY } from "constants/globalConstants";
import ApiService from "apiService";
import { useMutation, useQueryClient } from "react-query";
import toast from "react-hot-toast";

const useAddTemplate = (onClose, id) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.ADD_TEMPLATE],
    (data) => ApiService.addTemplate(data, id),
    {
      onSuccess: () => {
        toast.success('Template added successfully')
        queryClient.invalidateQueries(QUERY_KEY.GET_TEMPLATE);
        onClose()
      },
      onError: (error) => {
        toast.error(error.response.data.message)
    },
    }
  );
};

export default useAddTemplate