import React, { useState } from "react";
import Card from "components/card/Card.js";
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Image,
  Icon,
  Select,
  Input,
  Text,
  useDisclosure,
  useColorModeValue,
} from "@chakra-ui/react";
import Avatar1 from "assets/img/avatars/nologo.jpg";
import { useForm, Controller } from "react-hook-form";
import useEditClient from "hooks/useEditClient";
import { STATE_NAMES } from "constants/globalConstants";
import { MdEdit } from "react-icons/md";
import FileUploadModal from "./fileUploadModal";

function EditClientForm({ setShowEditForm, data }) {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  const entityId = data?.id;
  let activeColor = useColorModeValue("gray.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const buttonHover = useColorModeValue("brand.600", "brand.400");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { mutate: editClient, isLoading } = useEditClient(setShowEditForm);
  const [selectedUrl, setSelectedUrl] = useState(data?.documents?.file_url);
  const [fileId, setFileId] = useState(null);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const generateStateOptions = () => {
    return STATE_NAMES?.map((state) => {
      return <option value={state.value}>{state.label}</option>;
    });
  };

  const onSubmit = (data) => {
    editClient({
      name: data.name,
      email: data.email,
      logo_id: fileId,
      id: entityId,
      Address: {
        plot_no: data.plot_no,
        long: data?.long === "" || data?.long === null ? 0.0 : data?.long,
        directions: data.directions,
        lat: data?.lat === "" || data?.long === null ? 0.0 : data?.lat,
        city: data.city,
        pincode: data.pincode,
        state: data.state,
      },
    });
  };

  return (
    <>
      <Box w="100%" pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Card>
          <Flex
            maxW={{ base: "100%" }}
            w="100%"
            mx={{ base: "auto", lg: "0px" }}
            me="auto"
            h="100%"
            alignItems="start"
            justifyContent="center"
            mb={{ base: "30px", md: "30px" }}
            px={{ base: "25px", md: "0px" }}
            mt={{ base: "20px", md: "20px" }}
            flexDirection="column"
          >
            <Box me="auto">
              <Text
                mb="24px"
                ms="4px"
                color={activeColor}
                fontWeight="bold"
                fontSize="24px"
              >
                Edit Customer
              </Text>
            </Box>
            <Flex
              direction="column"
              w="100%"
              maxW="100%"
              background="transparent"
              borderRadius="15px"
              mx={{ base: "auto", lg: "unset" }}
              me="auto"
              mb={{ base: "20px", md: "auto" }}
            >
              <form onSubmit={handleSubmit(onSubmit)}>
                <Box position="relative" width="180px">
                  <Image
                    borderRadius="full"
                    boxSize="180px"
                    border="2px solid gray"
                    src={selectedUrl ? selectedUrl : Avatar1}
                    alt="No Logo"
                    cursor="pointer"
                    mb="20px"
                    onClick={() => onOpen()}
                  />
                  <Box position="absolute" zIndex={1} right={7} bottom={14}>
                    <Icon
                      as={MdEdit}
                      width="17px"
                      height="17px"
                      color="inherit"
                    />
                  </Box>
                </Box>
                <Flex w="100%">
                  <Flex direction="column" width="100%" pl="10px">
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                    >
                      Name<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <div style={{ marginBottom: "24px" }}>
                      <Controller
                        name="name"
                        rules={{
                          required: true,
                          maxLength: 50,
                          validate: (value) => {
                            return !!value.trim();
                          },
                        }}
                        control={control}
                        defaultValue={data?.name || ""}
                        render={({ field }) => (
                          <Input
                            fontSize="sm"
                            ms={{ base: "0px", md: "0px" }}
                            type="name"
                            placeholder="Enter your name"
                            //mb="24px"
                            fontWeight="500"
                            size="lg"
                            {...field}
                          />
                        )}
                      />
                      {errors.name?.type === "required" && (
                        <span style={{ color: "red" }}>name is required</span>
                      )}
                      {errors.name?.type === "validate" && (
                        <span style={{ color: "red" }}>name is required</span>
                      )}
                      {errors.name?.type === "maxLength" && (
                        <span style={{ color: "red" }}>
                          max length exceeded which is 50
                        </span>
                      )}
                    </div>
                  </Flex>
                  <Flex direction="column" width="100%" pl="10px">
                    <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      isRequired={true}
                      display="flex"
                    >
                      Email<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <div style={{ marginBottom: "24px" }}>
                      <Controller
                        name="email"
                        rules={{ required: true }}
                        control={control}
                        defaultValue={data?.email || ""}
                        render={({ field }) => (
                          <Input
                            fontSize="sm"
                            placeholder="Enter your email"
                            disabled={true}
                            size="lg"
                            type="email"
                            {...field}
                          />
                        )}
                      />
                      {errors.email?.type === "required" && (
                        <span style={{ color: "red" }}>email is required</span>
                      )}
                    </div>
                  </Flex>
                </Flex>
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="lg"
                  fontWeight="bold"
                  color={textColor}
                  mt="8px"
                  mb="16px"
                  ml="10px"
                >
                  Address
                </FormLabel>
                <Flex w="100%">
                  <Flex direction="column" width="100%" pl="10px">
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                    >
                      Plot No
                    </FormLabel>
                    <div style={{ marginBottom: "24px" }}>
                      <Controller
                        name="plot_no"
                        rules={{ required: false }}
                        control={control}
                        defaultValue={data?.Address?.plot_no || ""}
                        render={({ field }) => (
                          <Input
                            fontSize="sm"
                            ms={{ base: "0px", md: "0px" }}
                            type="name"
                            placeholder="Enter Plot Number"
                            //mb="24px"
                            fontWeight="500"
                            size="lg"
                            {...field}
                          />
                        )}
                      />
                    </div>
                  </Flex>
                  <Flex direction="column" width="100%" pl="10px">
                    <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      isRequired={false}
                      display="flex"
                    >
                      Direction
                    </FormLabel>
                    <div style={{ marginBottom: "24px" }}>
                      <Controller
                        name="directions"
                        rules={{ required: false }}
                        control={control}
                        defaultValue={data?.Address?.directions || ""}
                        render={({ field }) => (
                          <Input
                            fontSize="sm"
                            placeholder="Enter Direction"
                            size="lg"
                            type="name"
                            {...field}
                          />
                        )}
                      />
                    </div>
                  </Flex>
                </Flex>
                <Flex w="100%">
                  <Flex direction="column" width="100%" pl="10px">
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                    >
                      Latitude
                    </FormLabel>
                    <div style={{ marginBottom: "24px" }}>
                      <Controller
                        name="lat"
                        rules={{ required: false }}
                        control={control}
                        defaultValue={
                          data?.Address?.lat === 0 ? "" : data?.Address?.lat
                        }
                        render={({ field }) => (
                          <Input
                            fontSize="sm"
                            ms={{ base: "0px", md: "0px" }}
                            type="number"
                            placeholder="Enter Latitude"
                            //mb="24px"
                            fontWeight="500"
                            size="lg"
                            {...field}
                          />
                        )}
                      />
                    </div>
                  </Flex>
                  <Flex direction="column" width="100%" pl="10px">
                    <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      isRequired={false}
                      display="flex"
                    >
                      Longitude
                    </FormLabel>
                    <div style={{ marginBottom: "24px" }}>
                      <Controller
                        name="long"
                        rules={{ required: false }}
                        control={control}
                        defaultValue={
                          data?.Address?.long === 0 ? "" : data?.Address?.long
                        }
                        render={({ field }) => (
                          <Input
                            fontSize="sm"
                            placeholder="Enter Longitude"
                            size="lg"
                            type="number"
                            {...field}
                          />
                        )}
                      />
                    </div>
                  </Flex>
                </Flex>
                <Flex w="100%">
                  <Flex direction="column" width="100%" pl="10px">
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                    >
                      City<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <div style={{ marginBottom: "24px" }}>
                      <Controller
                        name="city"
                        rules={{
                          required: true,
                          validate: (value) => {
                            return !!value.trim();
                          },
                        }}
                        control={control}
                        defaultValue={data?.Address?.city || ""}
                        render={({ field }) => (
                          <Input
                            fontSize="sm"
                            ms={{ base: "0px", md: "0px" }}
                            type="name"
                            placeholder="Enter your City"
                            //mb="24px"
                            fontWeight="500"
                            size="lg"
                            {...field}
                          />
                        )}
                      />
                      {errors.city?.type === "required" && (
                        <span style={{ color: "red" }}>City is required</span>
                      )}
                      {errors.city?.type === "validate" && (
                        <span style={{ color: "red" }}>City is required</span>
                      )}
                    </div>
                  </Flex>
                  <Flex direction="column" width="100%" pl="10px">
                    <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      isRequired={true}
                      display="flex"
                    >
                      State<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <div style={{ marginBottom: "24px" }}>
                      <Controller
                        name="state"
                        rules={{ required: true }}
                        control={control}
                        defaultValue={data?.Address?.state || ""}
                        render={({ field }) => (
                          <Select
                            id="user_type"
                            size="lg"
                            fontSize="14px"
                            placeholder="Select State"
                            {...field}
                          >
                            {generateStateOptions()}
                          </Select>
                        )}
                      />
                      {errors.state?.type === "required" && (
                        <span style={{ color: "red" }}>state is required</span>
                      )}
                    </div>
                  </Flex>
                </Flex>
                <Flex w="50%">
                  <Flex direction="column" width="100%" pl="10px">
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                    >
                      Pin Code<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <div style={{ marginBottom: "24px" }}>
                      <Controller
                        name="pincode"
                        rules={{
                          required: true,
                          validate: (value) => {
                            return !!value.toString().trim();
                          },
                        }}
                        control={control}
                        defaultValue={data?.Address?.pincode || ""}
                        render={({ field }) => (
                          <Input
                            fontSize="sm"
                            ms={{ base: "0px", md: "0px" }}
                            type="text"
                            placeholder="Enter Pin Code"
                            //mb="24px"
                            fontWeight="500"
                            size="lg"
                            {...field}
                          />
                        )}
                      />
                      {errors.pincode?.type === "required" && (
                        <span style={{ color: "red" }}>
                          Pin Code is required
                        </span>
                      )}
                      {errors.pincode?.type === "validate" && (
                        <span style={{ color: "red" }}>
                          Pin Code is required
                        </span>
                      )}
                    </div>
                  </Flex>
                </Flex>
                <Flex w="40%" pl="10px">
                  <Button
                    fontSize="sm"
                    variant="brand"
                    fontWeight="500"
                    w="90%"
                    h="50"
                    mb="24px"
                    mr="20px"
                    _hover={{ bg: isLoading ? "blue" : buttonHover }}
                    isLoading={isLoading}
                    type="submit"
                  >
                    Submit
                  </Button>
                  <Button
                    fontSize="sm"
                    variant="brand"
                    fontWeight="500"
                    w="90%"
                    h="50"
                    mb="24px"
                    onClick={() => setShowEditForm(false)}
                  >
                    Cancel
                  </Button>
                </Flex>
              </form>
            </Flex>
          </Flex>
        </Card>
      </Box>
      <FileUploadModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        selectedUrl={selectedUrl}
        setSelectedUrl={setSelectedUrl}
        setFileId={setFileId}
      />
    </>
  );
}

export default EditClientForm;
