import React, { useEffect, useState } from "react";
import { Box, Text } from "@chakra-ui/layout";
import { Select } from "@chakra-ui/select";
import { Flex, Skeleton } from "@chakra-ui/react";

function NodeIdFilter(props) {
  const [selectedNodeTypeId, setSelectedNodeTypeId] = useState();
  const [disabled, setDisabled] = useState(true);

  const {
    setNodeId,
    nodeTypeData,
    setNodeIdName,
    nodeTypeId,
    isNodeLoading,
    isNodeFetching,
    entityTypeId,
  } = props;

  const onHandleChange = (e) => {
    setSelectedNodeTypeId(e.target.value);
  };
  const names = nodeTypeData?.data?.filter(
    (dat) => dat?.id == selectedNodeTypeId
  )[0];
  useEffect(() => {
    if (names?.name) setNodeIdName(names?.name);
  });

  useEffect(() => {
    nodeTypeId === null || nodeTypeId === 0 || nodeTypeId === "0"
      ? setDisabled(true)
      : setDisabled(false);
  }, [nodeTypeId, disabled, entityTypeId]);

  useEffect(() => {
    setNodeId(selectedNodeTypeId);
  }, [selectedNodeTypeId, setNodeId]);

  return (
    <Box mt="26px" mr="20px">
      <Flex alignItems="center">
        <Flex>
          <Text color="ndmsColors.gray.2100" fontSize="14px" fontWeight="bold">
            Node Id
          </Text>
        </Flex>
        {isNodeLoading || isNodeFetching ? (
          <Skeleton ml="10px" width="200px" height="40px" />
        ) : (
          <Flex alignItems="center">
            <Select
              fontSize="13px"
              width="198px"
              height="2.5rem"
              ml="14px"
              id="user_type"
              size="lg"
              mr="10px"
              color="#2B3674"
              disabled={disabled}
              fontWeight="bold"
              value={selectedNodeTypeId}
              onChange={onHandleChange}
            >
              <option value="all">All</option>
              {nodeTypeData?.data?.map((list) => (
                <option key={list?.id} value={list?.id}>
                  {list?.name}
                </option>
              ))}
            </Select>
          </Flex>
        )}
      </Flex>
    </Box>
  );
}

export default NodeIdFilter;
