import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Divider,
  Link,
  Skeleton,
  Select,
  Circle,
  Flex,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import useClientDowntime from "hooks/clientDashboard/useClientDowntime";
import useClientBreakdown from "hooks/clientDashboard/useClientBreakdown";
import Chart from "react-apexcharts";
import EmptyCard from "components/card/EmptyCard";

const DownTimeCardView = ({ colorCode, toggleCardMode, tabIndex }) => {
  const [downSort, setDownSort] = useState("desc");
  const [breakSort, setBreakSort] = useState("desc");
  const {
    isLoading,
    data: apiData,
    refetch,
    isFetching,
  } = useClientDowntime(downSort);
  const {
    isLoading: isBreakdownLoading,
    data: breakData,
    refetch: breakRefetch,
    isFetching: isBreakRefetching,
  } = useClientBreakdown(breakSort);

  const handleDownChange = (e) => {
    setDownSort(e.target.value);
  };

  const handleBreakChange = (e) => {
    setBreakSort(e.target.value);
  };

  useEffect(() => {
    refetch();
  }, [downSort]);

  useEffect(() => {
    breakRefetch();
  }, [breakSort]);

  useEffect(() => {
    refetch();
    breakRefetch();
  }, [tabIndex]);

  const barChartDataDowntime = [
    {
      name: "Downtime",
      data: apiData?.data?.slice(0, 5)?.map((data) => data.hours_down),
    },
  ];

  const barChartDataBreakdown = [
    {
      name: "Breakdown",
      data: breakData?.data?.slice(0, 5)?.map((data) => data.breakdown),
    },
  ];

  const getSplitString = (string) => {
    var i = string.indexOf(" ");
    var partOne = string.slice(0, i + 10).trim();
    var partTwo = string.slice(i + 6, string.length).trim();
    return partOne;
  };

  const barChartOptionsDowntime = {
    chart: {
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
      onDatasetHover: {
        style: {
          fontSize: "12px",
          fontFamily: undefined,
        },
      },
      theme: "dark",
    },
    xaxis: {
      categories: apiData?.data
        ?.slice(0, 5)
        ?.map((dat) => getSplitString(dat.name)),
      show: true,
      labels: {
        show: true,
        style: {
          colors: "#A3AED0",
          fontSize: "12px",
          fontWeight: "500",
        },
      },
      title: {
        text: "Entities",
        style: {
          color: "#9EA2A9",
          fontSize: "13px",
        },
      },
    },
    yaxis: {
      show: true,
      categories: ["0", "10", "20", "30", "40", "50", "60"],
      color: "black",
      labels: {
        show: true,
        style: {
          colors: "#A3AED0",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      title: {
        text: "Hours",
        style: {
          color: "#9EA2A9",
          fontSize: "13px",
        },
      },
    },

    grid: {
      borderColor: "rgba(163, 174, 208, 0.3)",
      show: true,
      yaxis: {
        lines: {
          show: false,
          opacity: 0.5,
        },
      },
      row: {
        opacity: 0.5,
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      type: "solid",
      colors: [colorCode[0]],
    },
    legend: {
      show: true,
    },
    colors: [colorCode],
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: "30px",
      },
    },
  };

  const barChartOptionsBreakdown = {
    chart: {
      stacked: false,
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
      onDatasetHover: {
        style: {
          fontSize: "12px",
          fontFamily: undefined,
        },
      },
      theme: "dark",
    },
    xaxis: {
      categories: breakData?.data
        ?.slice(0, 5)
        ?.map((dat) => getSplitString(dat.name)),
      show: true,
      labels: {
        show: true,
        style: {
          colors: "#A3AED0",
          fontSize: "12px",
          fontWeight: "500",
        },
      },
      title: {
        text: "Entities",
        style: {
          color: "#9EA2A9",
          fontSize: "13px",
        },
      },
    },
    yaxis: {
      show: true,
      categories: ["0", "10", "20", "30", "40", "50", "60"],
      color: "black",
      labels: {
        show: true,
        style: {
          colors: "#A3AED0",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      title: {
        text: "Hours",
        style: {
          color: "#9EA2A9",
          fontSize: "13px",
        },
      },
    },

    grid: {
      borderColor: "rgba(163, 174, 208, 0.3)",
      show: true,
      yaxis: {
        lines: {
          show: false,
          opacity: 0.5,
        },
      },
      row: {
        opacity: 0.5,
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      type: "solid",
      colors: [colorCode[1]],
    },
    legend: {
      show: true,
    },
    colors: [colorCode],
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: "30px",
      },
    },
  };
  return (
    <Box mt="10px">
      <Flex width="100%" wrap="wrap">
        {isLoading || isFetching ? (
          <Card mt="20px" mr="10px" minHeight="380px" flexBasis="48%">
            <Skeleton minHeight="360px" flexBasis="40%" />
          </Card>
        ) : (
          <Card mt="20px" mr="20px" minHeight="500px" flexBasis="48%">
            <Flex alignItems="center" justifyContent="space-between">
              <Text fontSize="18px" fontWeight="bold" color="#2B3674">
                Total Downtime Hours
              </Text>
              <Select
                bg="white"
                fontSize="15px"
                //mt="20px"
                width="200px"
                height="40px"
                id="user_type"
                size="lg"
                ml="10px"
                color="#2C3774"
                fontWeight="bold"
                value={downSort}
                onChange={handleDownChange}
              >
                <option value="desc">Top 5</option>
                <option value="asc">Bottom 5</option>
              </Select>
            </Flex>
            {apiData?.data?.length > 0 ? (
              <>
                {toggleCardMode ? (
                  <>
                    {apiData?.data?.slice(0, 5)?.map((data, index) => (
                      <Box key={data?.id}>
                        <Flex
                          mt="20px"
                          pt="10px"
                          justifyContent="space-between"
                        >
                          <Flex direction="column">
                            <Link
                              href={`/admin/entity-dashboard/${data?.id}/${data?.name}`}
                            >
                              <Flex alignItems="center">
                                <Text
                                  color={"ndmsColors.blue.100"}
                                  fontWeight="500"
                                  fontSize="14px"
                                  pr="10px"
                                >
                                  {data?.name}
                                </Text>
                                <Circle
                                  size="20px"
                                  bg="ndmsColors.gray.1300"
                                  color="ndmsColors.blue.100"
                                >
                                  {">"}
                                </Circle>
                              </Flex>
                            </Link>
                            <Text
                              fontWeight="500"
                              color="#7B818A"
                              fontSize="12px"
                            >
                              {data?.Address?.city}
                            </Text>
                          </Flex>
                          <Text
                            fontWeight="bold"
                            color={colorCode[0]}
                            fontSize="16px"
                          >
                            {data?.hours_down} hrs
                          </Text>
                        </Flex>
                        {index !== 4 ? <Divider pt="10px" /> : null}
                      </Box>
                    ))}
                  </>
                ) : (
                  <Box mt="40px">
                    <Chart
                      options={barChartOptionsDowntime}
                      series={barChartDataDowntime}
                      type="bar"
                      height="350px"
                      width="100%"
                    />
                  </Box>
                )}
              </>
            ) : (
              <Box mt="30%">
                <EmptyCard />
              </Box>
            )}
          </Card>
        )}

        {isBreakdownLoading || isBreakRefetching ? (
          <Card mt="20px" ml="10px" minHeight="380px" flexBasis="48%">
            <Skeleton minHeight="360px" flexBasis="40%" />
          </Card>
        ) : (
          <Card mt="20px" ml="10px" minHeight="500px" flexBasis="48%">
            <Flex alignItems="center" justifyContent="space-between">
              <Text fontSize="18px" fontWeight="bold" color="#2B3674">
                Total Breakdowns
              </Text>
              <Select
                bg="white"
                fontSize="15px"
                //mt="20px"
                width="200px"
                height="40px"
                id="user_type"
                size="lg"
                ml="10px"
                color="#2C3774"
                fontWeight="bold"
                value={breakSort}
                onChange={handleBreakChange}
              >
                <option value="desc">Top 5</option>
                <option value="asc">Bottom 5</option>
              </Select>
            </Flex>
            {apiData?.data?.length > 0 ? (
              <>
                {toggleCardMode ? (
                  <>
                    {breakData?.data?.slice(0, 5)?.map((data, index) => (
                      <Box key={data?.id}>
                        <Flex
                          mt="20px"
                          pt="10px"
                          justifyContent="space-between"
                        >
                          <Flex direction="column">
                            <Link
                              href={`/admin/entity-dashboard/${data?.id}/${data?.name}`}
                            >
                              <Flex alignItems="center">
                                <Text
                                  color={"ndmsColors.blue.100"}
                                  fontWeight="500"
                                  fontSize="14px"
                                  pr="10px"
                                >
                                  {data?.name}
                                </Text>
                                <Circle
                                  size="20px"
                                  bg="ndmsColors.gray.1300"
                                  color="ndmsColors.blue.100"
                                >
                                  {">"}
                                </Circle>
                              </Flex>
                            </Link>
                            <Text
                              fontWeight="500"
                              color="#7B818A"
                              fontSize="12px"
                            >
                              {data?.Address?.city}
                            </Text>
                          </Flex>
                          <Text
                            fontWeight="bold"
                            color={colorCode[1]}
                            fontSize="16px"
                          >
                            {data?.breakdown}
                          </Text>
                        </Flex>
                        {index !== 4 ? <Divider pt="10px" /> : null}
                      </Box>
                    ))}
                  </>
                ) : (
                  <Box mt="40px">
                    <Chart
                      options={barChartOptionsBreakdown}
                      series={barChartDataBreakdown}
                      type="bar"
                      height="350px"
                      width="100%"
                    />
                  </Box>
                )}
              </>
            ) : (
              <Box mt="30%">
                <EmptyCard />
              </Box>
            )}
          </Card>
        )}
      </Flex>
    </Box>
  );
};

export default DownTimeCardView;
