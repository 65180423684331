import {
  Modal,
  ModalOverlay,
  ModalContent,
  Icon,
  Flex,
  Text,
  ModalCloseButton,
  Button,
  //Select,
} from "@chakra-ui/react";
import { useState } from "react";
import { MdOutlineFileUpload } from "react-icons/md";
import useImportNodes from "hooks/useImportNodes";

function FileUploadModal({ isOpen, onOpen, onClose, entityId }) {
  const [selectedFile, setSelectedFile] = useState();
  const [isFilePicked, setIsFilePicked] = useState(false);
  const { mutate: importNode, isLoading } = useImportNodes(entityId, onClose);

  const changeHandler = (event) => {
    setSelectedFile(event.target.files[0]);
    setIsFilePicked(true);
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("files", selectedFile);
    importNode(formData);
  };

  const handleModalClose = () => {
    setSelectedFile();
    setIsFilePicked(false);
    onClose();
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        blockScrollOnMount={false}
        onClose={handleModalClose}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent>
          <input
            id="file-upload"
            type="file"
            style={{ display: "none" }}
            onChange={changeHandler}
          />
          <label
            for="file-upload"
            class="custom-file-upload"
            style={{
              width: "94%",
              marginLeft: "20px",
              marginTop: "60px",
              border: "1px solid #ccc",
              borderRadius: "10px",
              display: "inline-block",
              padding: "12px 12px",
              cursor: "pointer",
            }}
          >
            <Flex align="center" justify="space-between">
              {isFilePicked ? selectedFile?.name : "File Upload"}{" "}
              <Icon as={MdOutlineFileUpload} w="24px" h="auto" />
            </Flex>
          </label>
          <Button
            _hover={{ bg: isLoading ? "blue" : "#4318FF" }}
            bg="#4318FF"
            variant="brand"
            ml={5}
            mt={8}
            mb={10}
            isLoading={isLoading}
            width="120px"
            onClick={handleSubmit}
          >
            <Text color={"#FFFFFF"}>Submit</Text>
          </Button>
          <ModalCloseButton />
        </ModalContent>
      </Modal>
    </>
  );
}

export default FileUploadModal;
