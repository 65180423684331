import axios from "axios";
import Config from "config";
import request from "utils";

const clientNodesWorking = async (trendSort) => {
  if (localStorage.getItem("token")) {
    const { data } = await request.get(
      `${process.env.REACT_APP_BASE_URL}/client_dashboard_nodes_working?pagination=false&order_by=${trendSort}`,
      {
        headers: { Authorization: localStorage.getItem("token") },
      }
    );
    return data;
  }
};

const getUptimePercentage = async (order) => {
  if (localStorage.getItem("token")) {
    const { data } = await request.get(
      `${process.env.REACT_APP_BASE_URL}/client_dashboard_uptime_percentage?page=True&page_size=5&order_by=${order}`,
      {
        headers: { Authorization: localStorage.getItem("token") },
      }
    );
    return data;
  }
};

const getDowntime = async (order) => {
  if (localStorage.getItem("token")) {
    const { data } = await request.get(
      `${process.env.REACT_APP_BASE_URL}/client_dashboard_downtime?page=True&page_size=5&order_by=${order}`,
      {
        headers: { Authorization: localStorage.getItem("token") },
      }
    );
    return data;
  }
};

const getBreakdown = async (order) => {
  if (localStorage.getItem("token")) {
    const { data } = await request.get(
      `${process.env.REACT_APP_BASE_URL}/client_dashboard_breakdown?page=True&page_size=5&order_by=${order}`,
      {
        headers: { Authorization: localStorage.getItem("token") },
      }
    );
    return data;
  }
};

const getMttr = async (order) => {
  if (localStorage.getItem("token")) {
    const { data } = await request.get(
      `${process.env.REACT_APP_BASE_URL}/mean_time_to_repair?page=True&page_size=5&order_by=${order}`,
      {
        headers: { Authorization: localStorage.getItem("token") },
      }
    );
    return data;
  }
};

const getMtbf = async (order) => {
  if (localStorage.getItem("token")) {
    const { data } = await request.get(
      `${process.env.REACT_APP_BASE_URL}/mean_time_between_failure?page=True&page_size=5&order_by=${order}`,
      {
        headers: { Authorization: localStorage.getItem("token") },
      }
    );
    return data;
  }
};

const getTicketUserList = async () => {
  if (localStorage.getItem("token")) {
    const { data } = await request.get(
      `${process.env.REACT_APP_BASE_URL}/ticketing/user?assigned=${true}`,
      {
        headers: { Authorization: localStorage.getItem("token") },
      }
    );
    return data;
  }
};

const getTicketCreatedByList = async () => {
  if (localStorage.getItem("token")) {
    const { data } = await request.get(
      `${process.env.REACT_APP_BASE_URL}/ticketing/user?assigned=${false}`,
      {
        headers: { Authorization: localStorage.getItem("token") },
      }
    );
    return data;
  }
};

const getTicketTypes = async (queryString, otherString) => {
  if (localStorage.getItem("token")) {
    const { data } = await request.get(
      otherString?.length > 0
        ? `${process.env.REACT_APP_BASE_URL}/ticketing/ticket?${queryString}${otherString}`
        : `${process.env.REACT_APP_BASE_URL}/ticketing/ticket?${queryString}`,
      {
        headers: { Authorization: localStorage.getItem("token") },
      }
    );
    return data;
  }
};

const getTicketTypesForNode = async (id) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/ticketing/ticket?node_id=${id}&ticket_type=node_down`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const assignTicket = async (id, apiData) => {
  const { data } = await request.put(
    `${process.env.REACT_APP_BASE_URL}/ticketing/ticket/${id}/assign`,
    apiData,
    { headers: { Authorization: localStorage.getItem("token") } }
  );
  return data;
};

const createTicket = async (apiData) => {
  const { data } = await request.post(
    `${process.env.REACT_APP_BASE_URL}/ticketing/ticket`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getTicketCount = async () => {
  if (localStorage.getItem("token")) {
    const { data } = await request.get(
      `${process.env.REACT_APP_BASE_URL}/ticketing/ticket/count`,
      {
        headers: { Authorization: localStorage.getItem("token") },
      }
    );
    return data;
  }
};

const getAllTicketStatus = async () => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/ticketing/ticket/status`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getAllTicketStatusWithId = async (id) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/ticketing/ticket/status/${id}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getTicketStatus = async () => {
  const { data } = await request.get(
    `${
      process.env.REACT_APP_BASE_URL
    }/ticketing/ticket/status?is_closed=${false}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getTicketStatusClosed = async () => {
  const { data } = await request.get(
    `${
      process.env.REACT_APP_BASE_URL
    }/ticketing/ticket/status?is_closed=${true}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getTicketTaskCount = async () => {
  if (localStorage.getItem("token")) {
    const { data } = await request.get(
      `${process.env.REACT_APP_BASE_URL}/ticketing/ticket/task/count`,
      {
        headers: { Authorization: localStorage.getItem("token") },
      }
    );
    return data;
  }
};

const createTask = async (id, apiData) => {
  const { data } = await request.post(
    `${process.env.REACT_APP_BASE_URL}/ticketing/ticket/${id}/task`,
    apiData,
    { headers: { Authorization: localStorage.getItem("token") } }
  );
  return data;
};

const getTicketTasks = async (id) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/ticketing/ticket/${id}/task`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getTicketComments = async (id) => {
  if (localStorage.getItem("token")) {
    const { data } = await request.get(
      `${process.env.REACT_APP_BASE_URL}/ticketing/ticket/${id}/comment`,
      {
        headers: { Authorization: localStorage.getItem("token") },
      }
    );
    return data;
  }
};

const getTicketDetails = async (id) => {
  if (localStorage.getItem("token")) {
    const { data } = await request.get(
      `${process.env.REACT_APP_BASE_URL}/ticketing/ticket/${id}`,
      {
        headers: { Authorization: localStorage.getItem("token") },
      }
    );
    return data;
  }
};

const addTicketComments = async (id, apiData) => {
  const { data } = await request.post(
    `${process.env.REACT_APP_BASE_URL}/ticketing/ticket/${id}/comment`,
    apiData,
    { headers: { Authorization: localStorage.getItem("token") } }
  );
  return data;
};

const getNodeList = async (id) => {
  if (localStorage.getItem("token")) {
    const { data } = await request.get(
      `${process.env.REACT_APP_BASE_URL}/node_list/${id}`,
      {
        headers: { Authorization: localStorage.getItem("token") },
      }
    );
    return data;
  }
};

const getTicketCategory = async () => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/ticketing/ticket/category`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getTicketMemberList = async (id) => {
  if (localStorage.getItem("token")) {
    const { data } = await request.get(
      `${process.env.REACT_APP_BASE_URL}/ticketing/ticket/team-member`,
      {
        headers: { Authorization: localStorage.getItem("token") },
      }
    );
    return data;
  }
};

const getTicketTrends = async () => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/ticketing/ticket/trend`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getTypeList = async () => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/ticketing/ticket/type`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getTypeListWithId = async (id) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/ticketing/ticket/type/${id}`,

    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getTicketReasonWithId = async (id) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/ticketing/ticket/reason/${id}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getTicketTabs = async () => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/ticketing/ticket/tabs`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getTicketTabsCrud = async () => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/ticketing/ticket/tab-list`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getTicketTabWithId = async (id) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/ticketing/ticket/tabs/${id}`,

    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const completeTask = async (id, taskId) => {
  const { data } = await request.put(
    `${process.env.REACT_APP_BASE_URL}/ticketing/ticket/${id}/task/${taskId}/complete`,
    {
      id: 1,
      status: 1,
    },
    { headers: { Authorization: localStorage.getItem("token") } }
  );
  return data;
};

const cancelTask = async (id, taskId) => {
  const { data } = await request.put(
    `${process.env.REACT_APP_BASE_URL}/ticketing/ticket/${id}/task/${taskId}/cancel`,
    {
      id: 1,
      status: 1,
    },
    { headers: { Authorization: localStorage.getItem("token") } }
  );
  return data;
};

const updateAction = async (ticketId, apiData) => {
  const { data } = await request.put(
    `${process.env.REACT_APP_BASE_URL}/ticketing/ticket/${ticketId}/priority`,
    apiData,
    { headers: { Authorization: localStorage.getItem("token") } }
  );
  return data;
};

const markAsClosed = async (ticketId, apiData) => {
  const { data } = await request.put(
    `${process.env.REACT_APP_BASE_URL}/ticketing/ticket/${ticketId}/close`,
    apiData,
    { headers: { Authorization: localStorage.getItem("token") } }
  );
  return data;
};

const getTicketReason = async (id) => {
  const { data } = await request.get(
    id === undefined
      ? `${process.env.REACT_APP_BASE_URL}/ticketing/ticket/reason`
      : `${process.env.REACT_APP_BASE_URL}/ticketing/ticket/reason?status_id=${id}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const addTicketType = async (apiData) => {
  const { data } = await request.post(
    `${process.env.REACT_APP_BASE_URL}/ticketing/ticket/type`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const deleteTicketType = async (id) => {
  const { data } = await request.delete(
    `${process.env.REACT_APP_BASE_URL}/ticketing/ticket/type/${id}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const addTicketStatus = async (apiData) => {
  const { data } = await request.post(
    `${process.env.REACT_APP_BASE_URL}/ticketing/ticket/status`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const deleteTicketStatus = async (id) => {
  const { data } = await request.delete(
    `${process.env.REACT_APP_BASE_URL}/ticketing/ticket/status/${id}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const addTicketReason = async (apiData) => {
  const { data } = await request.post(
    `${process.env.REACT_APP_BASE_URL}/ticketing/ticket/reason`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const deleteTicketReason = async (id) => {
  const { data } = await request.delete(
    `${process.env.REACT_APP_BASE_URL}/ticketing/ticket/reason/${id}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const addTicketTabs = async (apiData) => {
  const { data } = await request.post(
    `${process.env.REACT_APP_BASE_URL}/ticketing/ticket/tabs`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const deleteTicketTabs = async (id) => {
  const { data } = await request.delete(
    `${process.env.REACT_APP_BASE_URL}/ticketing/ticket/tabs/${id}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const editTicketType = async (apiData, id) => {
  const { data } = await request.put(
    `${process.env.REACT_APP_BASE_URL}/ticketing/ticket/type/${id}`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const editTicketStatus = async (apiData, id) => {
  const { data } = await request.put(
    `${process.env.REACT_APP_BASE_URL}/ticketing/ticket/status/${id}`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const editTicketReason = async (apiData, id) => {
  const { data } = await request.put(
    `${process.env.REACT_APP_BASE_URL}/ticketing/ticket/reason/${id}`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const editTicketTab = async (apiData, id) => {
  const { data } = await request.put(
    `${process.env.REACT_APP_BASE_URL}/ticketing/ticket/tabs/${id}`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const DashboardService = {
  clientNodesWorking,
  getUptimePercentage,
  getDowntime,
  getBreakdown,
  getMttr,
  getMtbf,
  getTicketUserList,
  getTicketTypes,
  assignTicket,
  getTicketCategory,
  createTicket,
  getTicketCount,
  getTicketTaskCount,
  getTicketDetails,
  getTicketStatus,
  getTicketComments,
  createTask,
  getTicketTabs,
  editTicketStatus,
  getNodeList,
  getTicketTasks,
  addTicketComments,
  getTicketTabsCrud,
  getTicketReasonWithId,
  getTicketMemberList,
  getTicketTrends,
  getTicketStatusClosed,
  editTicketReason,
  editTicketTab,
  completeTask,
  cancelTask,
  getTypeList,
  updateAction,
  markAsClosed,
  getTicketCreatedByList,
  getTicketTypesForNode,
  getTicketReason,
  getTypeListWithId,
  addTicketType,
  deleteTicketType,
  addTicketStatus,
  deleteTicketStatus,
  getAllTicketStatus,
  addTicketReason,
  getAllTicketStatusWithId,
  deleteTicketReason,
  getTicketTabWithId,
  addTicketTabs,
  deleteTicketTabs,
  editTicketType,
};

export default DashboardService;
