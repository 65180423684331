import React, { useState } from "react";
import { AddIcon } from "@chakra-ui/icons";
import { StarIcon } from "@chakra-ui/icons";
import Select from "react-select";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Input,
  Icon,
  Box,
  Flex,
  FormLabel,
  Text,
  ModalCloseButton,
  Button,
  //Select,
  useColorModeValue,
  Checkbox,
} from "@chakra-ui/react";
import { AiOutlineClose } from "react-icons/ai";
import { useForm, Controller } from "react-hook-form";
import { CctvIcon } from "components/icons/Icons";
import useAddNode from "hooks/useAddNode";
import useUntraceableNode from "hooks/useUntraceableNode";

function NodeModal({
  isOpen,
  onClose,
  onOpen,
  data,
  groupData,
  groupId,
  entityId,
  setShowCreateGroup,
  traceableDevices,
  setTraceableDevice,
}) {
  const {
    handleSubmit,
    control,
    resetField,
    reset,
    formState: { errors },
  } = useForm();
  const textColor = useColorModeValue("navy.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const buttonHover = useColorModeValue("brand.600", "brand.400");
  const [selectedNodes, setSelectedNodes] = useState([]);
  const [traceableChecked, setTraceableChecked] = useState(traceableDevices);
  const [formValues, setFormValues] = useState([]);

  const handleModalClose = () => {
    onClose();
    setSelectedNodes([]);
    setFormValues([]);
    resetField("name");
    resetField("ip_address");
    resetField("device_id");
    reset();
  };

  const { mutate: addNode, isLoading: isAddNodeLoading } =
    useAddNode(handleModalClose);

  const { mutate: addUntraceableNode, isLoading: isAddUntraceableNodeLoading } =
    useUntraceableNode(handleModalClose);

  const generateSelectOptions = () => {
    return data?.map((data) => {
      return <option value={data.id}>{data.name}</option>;
    });
  };

  let addFormFields = (id, index) => {
    setFormValues([
      ...formValues,
      {
        name: "",
        ip_address: "",
        device_id: "",
        node_type_id: "",
        _id: id,
      },
    ]);
  };

  const handleChange = (e) => {
    if (selectedNodes?.length < e.length) {
      e.map((element) =>
        setFormValues([
          ...formValues,
          {
            name: "",
            ip_address: "",
            device_id: "",
            node_type_id: "",
            _id: element.id,
          },
        ])
      );
    } else {
      setFormValues((current) =>
        current.filter((element) => {
          return element._id === e[0].id;
        })
      );
    }
    setSelectedNodes(e);
  };

  const removeFormFields = (name, index) => {
    setFormValues((current) =>
      current.filter((element, elementIndex) => {
        return elementIndex !== index;
      })
    );
    resetField(`${name}[${index}].name`);
    resetField(`${name}[${index}].ip_address`);
    resetField(`${name}[${index}].device_id`);
  };

  const onSubmit = (data) => {
    const org = Object.keys(data).reduce(function (res, v) {
      return res.concat(data[v]);
    }, []);
    const mainData = org.filter(Boolean);
    if (traceableChecked) {
      addNode(mainData);
    } else {
      const untraceableData = mainData.map((obj) => {
        const { ip_address, ...rest } = obj;
        return rest;
      });
      addUntraceableNode(untraceableData);
    }
    setTraceableDevice(traceableChecked);
  };
  return (
    <>
      <Modal
        isOpen={isOpen}
        blockScrollOnMount={false}
        onClose={handleModalClose}
        size="3xl"
      >
        <ModalOverlay />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContent>
            <ModalHeader>
              <Flex direction="row" justify="space-between">
                <Flex direction="column" width="50%">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Select Device<Text color={brandStars}>*</Text>
                  </FormLabel>
                  <div style={{ marginBottom: "10px" }}>
                    <Select
                      id="user_type"
                      size="lg"
                      placeholder="Select Device"
                      value={selectedNodes}
                      options={data}
                      getOptionLabel={(data) => data["name"]}
                      getOptionValue={(data) => data["id"]}
                      isMulti
                      onChange={handleChange}
                    >
                      {generateSelectOptions()}
                    </Select>
                    {errors.name?.type === "required" && (
                      <span style={{ color: "red" }}>device is required</span>
                    )}
                  </div>
                </Flex>
                <Flex direction="column" align="center">
                  <Flex direction="row" align="center">
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="md"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                    >
                      Traceable Devices
                    </FormLabel>
                    <Checkbox
                      onChange={() => setTraceableChecked(!traceableChecked)}
                      pl="10px"
                      width="max-content"
                      defaultChecked={traceableDevices}
                      borderColor="#422AFB"
                      mr="50px"
                    />
                  </Flex>
                  <Flex direction="row" align="start">
                    <Icon
                      as={StarIcon}
                      w="5px"
                      h="5px"
                      color="red !important"
                    />
                    <span style={{ color: "red", fontSize: "12px" }}>
                      {traceableChecked
                        ? "To add untraceable device , uncheck it"
                        : "To add traceable device , check it"}
                    </span>
                  </Flex>
                </Flex>
              </Flex>
              {selectedNodes?.length > 0 &&
                selectedNodes?.map((selNode, selIndex) => (
                  <>
                    <Flex align="center">
                      <CctvIcon />
                      <Text color={"#1B2559"} mt="15px" ml="10px">
                        {selNode?.name}
                      </Text>
                    </Flex>
                    {formValues.map((element, index) => {
                      return element?._id === selNode?.id ? (
                        <>
                          <Flex
                            w="100%"
                            mt="15px"
                            alignItems="center"
                            justifyContent="center"
                          >
                            <Flex direction="column" width="100%" mb="15px">
                              <FormLabel
                                display="flex"
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                mb="8px"
                              >
                                Name<Text color={brandStars}>*</Text>
                              </FormLabel>
                              <div style={{ marginBottom: "10px" }}>
                                <Controller
                                  name={`${element.name}[${index}].name`}
                                  rules={{ required: true }}
                                  control={control}
                                  render={({ field }) => (
                                    <Input
                                      fontSize="sm"
                                      ms={{ base: "0px", md: "0px" }}
                                      type="name"
                                      placeholder="Enter Name"
                                      //mb="24px"
                                      fontWeight="500"
                                      size="lg"
                                      {...field}
                                    />
                                  )}
                                />
                                {errors.name?.type === "required" && (
                                  <span style={{ color: "red" }}>
                                    name is required
                                  </span>
                                )}
                              </div>
                            </Flex>
                            {traceableChecked && (
                              <Flex direction="column" width="100%" pl="10px">
                                <FormLabel
                                  ms="4px"
                                  fontSize="sm"
                                  fontWeight="500"
                                  color={textColor}
                                  isRequired={true}
                                  display="flex"
                                >
                                  Ip Address<Text color={brandStars}>*</Text>
                                </FormLabel>
                                <div style={{ marginBottom: "24px" }}>
                                  <Controller
                                    name={`${element.name}[${index}].ip_address`}
                                    rules={{ required: true }}
                                    control={control}
                                    render={({ field }) => (
                                      <Input
                                        fontSize="sm"
                                        placeholder="Enter Ip Address"
                                        size="lg"
                                        type="name"
                                        {...field}
                                      />
                                    )}
                                  />
                                  {errors.ip_address?.type === "required" && (
                                    <span style={{ color: "red" }}>
                                      ip address is required
                                    </span>
                                  )}
                                </div>
                              </Flex>
                            )}
                            <Flex direction="column" width="100%" pl="10px">
                              <FormLabel
                                ms="4px"
                                fontSize="sm"
                                fontWeight="500"
                                color={textColor}
                                isRequired={true}
                                display="flex"
                              >
                                Device Id<Text color={brandStars}>*</Text>
                              </FormLabel>
                              <div style={{ marginBottom: "24px" }}>
                                <Controller
                                  name={`${element.name}[${index}].device_id`}
                                  rules={{ required: true }}
                                  control={control}
                                  render={({ field }) => (
                                    <Input
                                      fontSize="sm"
                                      placeholder="Enter Device Id"
                                      size="lg"
                                      type="name"
                                      {...field}
                                    />
                                  )}
                                />
                                {errors.device_id?.type === "required" && (
                                  <span style={{ color: "red" }}>
                                    device id is required
                                  </span>
                                )}
                              </div>
                              <div
                                style={{
                                  marginBottom: "24px",
                                  display: "none",
                                }}
                              >
                                <Controller
                                  name={`${element.name}[${index}].node_type_id`}
                                  rules={{ required: true }}
                                  control={control}
                                  defaultValue={selNode.id}
                                  render={({ field }) => (
                                    <Input
                                      fontSize="sm"
                                      placeholder="Enter node Id"
                                      size="lg"
                                      type="name"
                                      {...field}
                                    />
                                  )}
                                />
                              </div>
                              <div
                                style={{
                                  marginBottom: "24px",
                                  display: "none",
                                }}
                              >
                                <Controller
                                  name={`${element.name}[${index}].group_id`}
                                  control={control}
                                  defaultValue={groupId}
                                  render={({ field }) => (
                                    <Input
                                      fontSize="sm"
                                      placeholder="Enter group Id"
                                      size="lg"
                                      type="name"
                                      {...field}
                                    />
                                  )}
                                />
                              </div>
                              <div
                                style={{
                                  marginBottom: "24px",
                                  display: "none",
                                }}
                              >
                                <Controller
                                  name={`${element.name}[${index}].entity_id`}
                                  control={control}
                                  defaultValue={entityId}
                                  render={({ field }) => (
                                    <Input
                                      fontSize="sm"
                                      placeholder="Enter entity Id"
                                      size="lg"
                                      type="name"
                                      {...field}
                                    />
                                  )}
                                />
                              </div>
                              <div
                                style={{
                                  marginBottom: "24px",
                                  display: "none",
                                }}
                              >
                                <Controller
                                  name={`${element.name}[${index}].order`}
                                  control={control}
                                  defaultValue={index + 1}
                                  render={({ field }) => (
                                    <Input
                                      fontSize="sm"
                                      placeholder="Enter order"
                                      size="lg"
                                      type="name"
                                      {...field}
                                    />
                                  )}
                                />
                              </div>
                            </Flex>
                            <Box
                              ml="10px"
                              onClick={() =>
                                removeFormFields(element.name, index)
                              }
                            >
                              <Icon
                                as={AiOutlineClose}
                                width="15px"
                                height="15px"
                                color="inherit"
                              />
                            </Box>
                          </Flex>
                        </>
                      ) : null;
                    })}

                    <Button
                      bg="#F4F7FE"
                      width="140px"
                      onClick={() => addFormFields(selNode.id, selIndex)}
                    >
                      <Icon as={AddIcon} w={3} h={3} color="#4318FF" />
                      <Text color={"#4318FF"} pl="5px">
                        Add More
                      </Text>
                    </Button>
                  </>
                ))}
            </ModalHeader>
            <Flex ml="25px">
              <Button
                bg="#4318FF"
                _hover={{
                  bg:
                    isAddNodeLoading || isAddUntraceableNodeLoading
                      ? "blue"
                      : buttonHover,
                }}
                mt={2}
                width="140px"
                variant="brand"
                mb={10}
                isLoading={isAddNodeLoading || isAddUntraceableNodeLoading}
                type="submit"
              >
                <Text color={"#FFFFFF"}>Save</Text>
              </Button>
              <Button
                variant="outline"
                colorScheme="#2B3674"
                _hover={{ bg: "#F0F8FF" }}
                mx={3}
                mt={2}
                width="140px"
                mb={10}
                onClick={handleModalClose}
              >
                <Text color={"#2B3674"}>Cancel</Text>
              </Button>
            </Flex>
            <ModalCloseButton />
          </ModalContent>
        </form>
      </Modal>
    </>
  );
}

export default NodeModal;
