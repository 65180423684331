import { ChatIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  Icon,
  Link,
  Select,
  Text,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import { getDashboardPermissions } from "Permissions";
import Card from "components/card/Card.js";
import EmptyCard from "components/card/EmptyCard";
import EditDowntimeApproveModal from "components/modal/editDowntimeApproveModal";
import StatusModal from "components/modal/statusModal";
import DownTimeListTable from "components/table/downTimeListTable";
import dayjs from "dayjs";
import ApproveDowntimeHooks from "hooks/approvedDowntime/approveDowntimeHooks";
import useEntityList from "hooks/entityList/useEntityList";
import useNodeTypes from "hooks/useNodeTypes";
import { useEffect, useState } from "react";
import { BsCheckCircleFill } from "react-icons/bs";
import { MdCancel, MdEdit } from "react-icons/md";
import ApprovedDowntimeLoader from "./approvedDowntimeLoader";

const NodeApprovedDowntimes = ({ breakupReasonData }) => {
  const { data: entityData, refetch: entityRefetch } = useEntityList(
    ":status",
    "all",
    1
  );
  const { data: nodeTypeData, refetch: nodeTypeRefetch } = useNodeTypes();
  const [editData, setEditData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [status, setStatus] = useState("all");
  const [id, setId] = useState(null);
  const [entityId, setEntityId] = useState("all");
  const [nodeTypeId, setNodeTypeId] = useState("all");
  const [actionStatus, setActionStatus] = useState(null);
  const { isLoading, error, data, refetch, isFetching } =
    ApproveDowntimeHooks.useNopdBreakupList(
      20,
      currentPage,
      status,
      entityId,
      nodeTypeId
    );
  const {
    isOpen: isStatusOpen,
    onOpen: onStatusOpen,
    onClose: onStatusClose,
  } = useDisclosure();

  useEffect(() => {
    entityRefetch();
    nodeTypeRefetch();
    refetch();
  }, []);

  useEffect(() => {
    refetch();
  }, [currentPage, status, entityId, nodeTypeId]);

  if (isLoading || isFetching) {
    return <ApprovedDowntimeLoader show={true} />;
  }

  const generatenEntityOptions = () => {
    return entityData?.data?.map((entity) => {
      return <option value={entity.id}>{entity.name}</option>;
    });
  };

  const generatenNodeTypeOptions = () => {
    return nodeTypeData?.data[0]?.result?.map((nodeType) => {
      return <option value={nodeType.id}>{nodeType.name}</option>;
    });
  };

  const handleEdit = (row) => {
    setEditData(row);
    onOpen();
  };

  const handleStatus = (row, num) => {
    setId(row.id);
    setActionStatus(num);
    onStatusOpen();
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 0:
        return "Pending";
      case 1:
        return "Approved";
      case 2:
        return "Disapproved";
      default:
        return "pending";
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 0:
        return "orange";
      case 1:
        return "green";
      case 2:
        return "red";
      default:
        return "orange";
    }
  };

  const getNotes = (row) => {
    if (getDashboardPermissions("node.downtime.review_downtime_breakup")) {
      return row.notes === null || row.notes?.trim().length === 0 ? (
        <Text cursor="default" fontWeight="bold">
          -
        </Text>
      ) : (
        <Tooltip hasArrow label={row.notes} bg="#2B3674">
          <Text
            noOfLines={2}
            // color={"ndmsColors.blue.100"}
            fontSize="12px"
            fontWeight="700"
          >
            {row.notes}
          </Text>
        </Tooltip>
      );
    } else {
      return row.review_notes === null ||
        row.review_notes?.trim().length === 0 ? (
        <Text cursor="default" fontWeight="bold">
          -
        </Text>
      ) : (
        <Tooltip hasArrow label={row.review_notes} bg="#2B3674">
          <Text
            noOfLines={2}
            // color={"ndmsColors.blue.100"}
            fontSize="12px"
            fontWeight="700"
          >
            {row.review_notes}
          </Text>
        </Tooltip>
      );
    }
  };

  const columnsData = [
    {
      Header: "START AT",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {dayjs(row.start_at).format("DD/MM/YYYY")},{" "}
            {dayjs(row.start_at).format("hh:mm a")}
          </Text>
        );
      },
    },
    {
      Header: "END AT",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {dayjs(row.end_at).format("DD/MM/YYYY")},{" "}
            {dayjs(row.end_at).format("hh:mm a")}
          </Text>
        );
      },
    },
    {
      Header: "ENTITY",
      accessor: (row) => {
        return (
          <Link
            href={`/admin/entity-dashboard/${row.entity_id}/${row.entity_name}`}
          >
            <Text
              color={"ndmsColors.purple.200"}
              fontSize="12px"
              fontWeight="700"
            >
              {row.entity_name}
            </Text>
          </Link>
        );
      },
    },
    {
      Header: "NODE TYPE",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row.node_type_name}
          </Text>
        );
      },
    },
    {
      Header: "NODE",
      accessor: (row) => {
        return (
          <>
            {getDashboardPermissions("node_dashboard.view") ? (
              <Link
                href={`/admin/node-dashboard/${row.entity_id}/${row.entity_name}/node_id=${row.node_id}`}
              >
                <Text
                  color={"ndmsColors.purple.200"}
                  fontSize="12px"
                  fontWeight="700"
                >
                  {row.node_name}
                </Text>
              </Link>
            ) : (
              <Text
                color={"ndmsColors.purple.200"}
                fontSize="12px"
                fontWeight="700"
              >
                {row.node_name}
              </Text>
            )}
          </>
        );
      },
    },
    {
      Header: "APPROVED DOWNTIME REASON",
      accessor: (row) => {
        return (
          <Flex alignItems="center" gap="2">
            {row?.breakup_reason?.breakup_type ? (
              <>
                <Text
                  color={"ndmsColors.blue.100"}
                  fontSize="12px"
                  fontWeight="700"
                >
                  {row?.breakup_reason?.breakup_type}
                </Text>
                <Tooltip hasArrow label={getNotes(row)}>
                  <ChatIcon color="ndmsColors.blue.100" />
                  {/* <Icon
                          color="green"
                          as={BsFillChatSquareTextFill}
                          w="17px"
                          h="17px"
                        /> */}
                </Tooltip>
              </>
            ) : (
              "-"
            )}
          </Flex>
        );
      },
    },
    // {
    //   Header: getDashboardPermissions("node.downtime.review_downtime_breakup")
    //     ? "NOTES"
    //     : "REVIEW NOTES",
    //   accessor: (row) => {
    //     return getNotes(row);
    //   },
    // },

    {
      Header: "STATUS",
      accessor: (row) => {
        return (
          <Text
            color={getStatusColor(row.status)}
            fontSize="12px"
            fontWeight="700"
          >
            {getStatusLabel(row.status)}
          </Text>
        );
      },
    },
    {
      Header: "ACTION",
      accessor: (row) => {
        return (
          <>
            {row.status === 0 ? (
              <Flex>
                {getDashboardPermissions(
                  "node.downtime.review_downtime_breakup"
                ) ? (
                  <Flex
                    justifyContent="center"
                    align="center"
                    onClick={() => handleStatus(row, 1)}
                  >
                    <Tooltip hasArrow label="Approve" bg="green">
                      <Flex mr="10px">
                        <Icon
                          color="green"
                          as={BsCheckCircleFill}
                          w="17px"
                          h="17px"
                        />
                      </Flex>
                    </Tooltip>
                  </Flex>
                ) : null}
                {getDashboardPermissions(
                  "node.downtime.review_downtime_breakup"
                ) ? (
                  <Flex
                    justifyContent="center"
                    align="center"
                    onClick={() => handleStatus(row, 2)}
                  >
                    <Tooltip hasArrow label="Disapprove" bg="red">
                      <Flex mr="10px">
                        <Icon color="red" as={MdCancel} w="20px" h="20px" />
                      </Flex>
                    </Tooltip>
                  </Flex>
                ) : null}
                {getDashboardPermissions(
                  "node.downtime.update_downtime_breakup"
                ) ? (
                  <Flex
                    justifyContent="center"
                    align="center"
                    onClick={() => handleEdit(row)}
                  >
                    <Tooltip hasArrow label="Edit" bg="#2B3674">
                      <Flex mr="10px">
                        <Icon
                          color="ndmsColors.purple.200"
                          as={MdEdit}
                          w="17px"
                          h="17px"
                        />
                      </Flex>
                    </Tooltip>
                  </Flex>
                ) : null}
              </Flex>
            ) : (
              <Text cursor="default" fontWeight="bold">
                -
              </Text>
            )}
          </>
        );
      },
    },
  ];

  return (
    <Box pt="30px">
      <Flex justifyContent="space-between" mb="40px">
        <Flex align="center">
          <Text
            fontSize={"16px"}
            //mb="5px"
            color="#707EAE"
          >
            Status :
          </Text>
          <Select
            bg="white"
            fontSize="15px"
            //mt="20px"
            width="200px"
            height="40px"
            id="user_type"
            size="lg"
            ml="10px"
            color="#2C3774"
            fontWeight="bold"
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          >
            <option value="all">All</option>
            <option value={0}>Pending</option>
            <option value={1}>Approved</option>
            <option value={2}>Disapproved</option>
          </Select>
          <Text fontSize={"16px"} ml="15px" color="#707EAE">
            Entity :
          </Text>
          <Select
            bg="white"
            fontSize="15px"
            //mt="20px"
            width="200px"
            height="40px"
            id="user_type"
            size="lg"
            color="#2C3774"
            fontWeight="bold"
            ml="10px"
            defaultValue={entityId}
            onChange={(e) => setEntityId(e.target.value)}
          >
            <option value="all">All</option>
            {generatenEntityOptions()}
          </Select>
          <Text fontSize={"16px"} ml="15px" color="#707EAE">
            Node Type :
          </Text>
          <Select
            bg="white"
            fontSize="15px"
            //mt="20px"
            width="200px"
            height="40px"
            id="user_type"
            size="lg"
            ml="10px"
            color="#2C3774"
            fontWeight="bold"
            defaultValue={nodeTypeId}
            onChange={(e) => setNodeTypeId(e.target.value)}
          >
            <option value="all">All</option>
            {generatenNodeTypeOptions()}
          </Select>
        </Flex>
      </Flex>
      <Card>
        {data?.data[0]?.result?.length > 0 ? (
          <DownTimeListTable
            tableData={data?.data[0]?.result}
            currentPage={currentPage}
            totalPage={data?.data[0]?.total_pages}
            columnsData={columnsData}
            setCurrentPage={setCurrentPage}
          />
        ) : (
          <Box my="100px">
            <EmptyCard />
          </Box>
        )}
      </Card>
      <EditDowntimeApproveModal
        onOpen={onOpen}
        onClose={onClose}
        breakupReasonData={breakupReasonData}
        isOpen={isOpen}
        isNodeLevel={true}
        editData={editData}
      />
      <StatusModal
        onOpen={onStatusOpen}
        onClose={onStatusClose}
        isOpen={isStatusOpen}
        id={id}
        status={actionStatus}
        isNodeLevel={true}
      />
    </Box>
  );
};

export default NodeApprovedDowntimes;
