import { QUERY_KEY, MUTATION_KEY } from "constants/globalConstants";
import DashboardApiService from "apiService/dashboardApiService";
import { useMutation, useQuery } from "react-query";
import toast from "react-hot-toast";
//import { useQuery } from "@chakra-ui/media-query";

const useGenerateCsvReport = (
  enabled,
  entityTypeId,
  entityTypeName,
  nodeTypeId,
  nodeTypeName,
  nodeId,
  nodeIdName,
  startDate,
  endDate,
  uptimeChecked,
  scheduleChecked,
  effectiveChecked,
  showTimeChecked,
  handleModalClose,
  setEnabled
) => {
  return useQuery(
    QUERY_KEY.GET_CSV_REPORT,
    () =>
      DashboardApiService.getCsvReport(
        entityTypeId,
        entityTypeName,
        nodeTypeId,
        nodeTypeName,
        nodeId,
        nodeIdName,
        startDate,
        endDate,
        uptimeChecked,
        scheduleChecked,
        effectiveChecked,
        showTimeChecked
      ),
    {
      retry: false,
      enabled: enabled,
      onSuccess: (res) => {
        enabled && window.open(res?.data, "_blank", "noopener,noreferrer");
        setEnabled(false);
        handleModalClose();
      },
      onError: (err) => {
        toast.error(err.response.data.message);
        setEnabled(false);
      },
    }
  );
};
export default useGenerateCsvReport;
