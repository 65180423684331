import React from "react";
import { Box, Flex, Skeleton } from "@chakra-ui/react";
import "../../admin/layoutPlanning";
import { Grid, GridItem } from "@chakra-ui/react";

const arrayLength = 9;
const LiveDashboardGroupCardsLoader = () => {
  return (
    <Grid templateColumns="repeat(3, 1fr)" gap={0} mt="40px">
      {[...Array(arrayLength)].map((e, i) => (
        <GridItem
          key={i}
          className={`${arrayLength === 2 ? "element-count2" : ""} grid-item`}
          p="45px 44px 45px 44px"
        >
          {" "}
          <Flex justifyContent="space-between" mb={2}>
            <Flex direction="column">
              <Skeleton width="280px" height="100px" />
            </Flex>
          </Flex>
        </GridItem>
      ))}

      {arrayLength > 3 &&
        3 - (arrayLength % 3) < 3 &&
        [...Array(3 - (arrayLength % 3))].map((data, index) => (
          <Box
            key={index}
            className={`grid-item ${
              index === 0 && 3 - (arrayLength % 3) !== 1
                ? "empty-middle"
                : "empty-end"
            }`}
            p="30px 44px 30spx 44px"
          ></Box>
        ))}
    </Grid>
  );
};

export default LiveDashboardGroupCardsLoader;
