import { QUERY_KEY } from "constants/globalConstants";
import DashboardApiService from "apiService/dashboardApiService";
import { useQuery } from "react-query";

const useBreakUpReasonList = () => {
  return useQuery(
    QUERY_KEY.GET_BREAKUP_REASON_LIST,
    () => DashboardApiService.getBreakupReasonListing(),
    { retry: false }
  );
};

export default useBreakUpReasonList;
