import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Box,
  Input,
  Flex,
  Text,
  ModalCloseButton,
  Button,
  //Select,
} from "@chakra-ui/react";
import useGenerateLicense from "hooks/useGenerateLicense";

const LicenseModal = ({ isOpen, onClose, onOpen, id, entityName }) => {
  const [showLicense, setShowLicense] = useState(false);
  const [generatedLicense, setGeneratedLicense] = useState("");
  const { mutate: generateLicense, isLoading } = useGenerateLicense(
    setShowLicense,
    setGeneratedLicense
  );
  const handleGenerateLicense = () => {
    generateLicense({ entity_id: id });
  };
  const handleModalClose = () => {
    onClose();
    setShowLicense(false);
    setGeneratedLicense("");
  };
  return (
    <Box borderRadius="3xl">
      <Modal
        isOpen={isOpen}
        onClose={handleModalClose}
        blockScrollOnMount={false}
        size="lg"
        isCentered
      >
        <ModalOverlay />
        <ModalContent borderRadius="20px">
          <ModalHeader>
            {showLicense ? (
              <Flex
                justify="center"
                direction="column"
                align="center"
                mt="30px"
              >
                <Text fontSize="20px" mb="10px">
                  License key for {entityName}{" "}
                </Text>
                <Input
                  fontSize="sm"
                  ms={{ base: "0px", md: "0px" }}
                  type="name"
                  //mb="24px"
                  fontWeight="500"
                  value={generatedLicense}
                  size="lg"
                />
                <Flex mt="20px">
                  <Button
                    variant="outline"
                    colorScheme="#2B3674"
                    _hover={{ bg: "#F0F8FF" }}
                    mx={3}
                    mt={2}
                    size="lg"
                    mb={10}
                    onClick={handleModalClose}
                  >
                    <Text color={"#2B3674"}>Close</Text>
                  </Button>
                </Flex>
              </Flex>
            ) : (
              <Flex
                justify="center"
                direction="column"
                align="center"
                mt="30px"
              >
                <Text fontSize="30px">Get License</Text>
                <Text color="#A3AED0">
                  Are you sure you want to generate new license?
                </Text>
                <Flex mt="20px">
                  <Button
                    bg="#4318FF"
                    _hover={{ bg: isLoading ? "blue" : "#2B3674" }}
                    mx={3}
                    mt={2}
                    mb={10}
                    size="lg"
                    variant="brand"
                    isLoading={isLoading}
                    onClick={handleGenerateLicense}
                  >
                    <Text color={"#FFFFFF"}>Ok</Text>
                  </Button>
                  <Button
                    variant="outline"
                    colorScheme="#2B3674"
                    _hover={{ bg: "#F0F8FF" }}
                    mx={3}
                    mt={2}
                    size="lg"
                    mb={10}
                    onClick={handleModalClose}
                  >
                    <Text color={"#2B3674"}>Cancel</Text>
                  </Button>
                </Flex>
              </Flex>
            )}
          </ModalHeader>

          <ModalCloseButton />
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default LicenseModal;
