import { QUERY_KEY } from "constants/globalConstants";
import { useQuery } from "react-query";
import DashboardApiService from "apiService/dashboardApiService";

const useNodeTypeId = (entityId, id,enableFlag) => {
  return useQuery(
    QUERY_KEY.GET_NODE_TYPE_ID,
    () => DashboardApiService.getNodeTypeId(entityId, id),
    { retry: false,enabled:enableFlag }
  );
};

export default useNodeTypeId;
