import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Text,
  Button,
  Tooltip,
  Center,
  Divider,
  FormControl,
  Image,
  Flex,
  Input,
  Icon,
  useDisclosure,
  useColorModeValue,
} from "@chakra-ui/react";
import { useHistory } from "react-router";
import Card from "components/card/Card.js";
import { AddIcon, ArrowBackIcon } from "@chakra-ui/icons";
import NodeTable from "components/table/nodeTable";
import { BiDevices } from "react-icons/bi";
import useUpdateGroup from "hooks/useUpdateGroup";
import {
  AddGroupMenu,
  DeleteNodeIcon,
  DeleteNodeListIcon,
  GridIconDark,
  GridIconLight,
  ListIconDark,
  ListIconLight,
} from "components/icons/Icons";
import useNodeTypes from "hooks/useNodeTypes";
import { MdEdit } from "react-icons/md";
import NodeModal from "components/modal/nodeModal";
import NodeEditModal from "./nodeEditModal";
import useUpdateNode from "hooks/useUpdateNode";
import useGroup from "hooks/useGroup";
import NodeLoader from "./nodeLoader";
import useDeleteNode from "hooks/useDeleteNode";
import noGroupImage from "assets/img/layout/noGroup.png";
import { useForm, Controller } from "react-hook-form";
import DeleteModal from "components/modal/deleteModal";
import { useDeleteUntraceableNode } from "hooks/useUntraceableNode";

const CardDetails = ({
  individualGroupData,
  setShowCardDetails,
  setIndividualGroupData,
  entityId,
  traceableDevices = true,
}) => {
  const {
    isLoading: isNodeLoading,
    error: nodeError,
    data: nodeTypeData,
  } = useNodeTypes();

  const { data: groupData } = useGroup(entityId);
  const buttonHover = useColorModeValue("brand.600", "brand.400");

  const oneGroup = groupData?.data?.filter(
    (group) => group?.id === individualGroupData?.id
  )[0];

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm();

  const outsideRef = useRef();
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [toggleViewMode, setToggleViewMode] = useState(true);
  const {
    isOpen: isNodeOpen,
    onOpen: onNodeOpen,
    onClose: onNodeClose,
  } = useDisclosure();
  const {
    isOpen: isDelOpen,
    onOpen: onDelOpen,
    onClose: onDelClose,
  } = useDisclosure();
  const {
    isOpen: isScheduleOpen,
    onOpen: onScheduleOpen,
    onClose: onScheduleClose,
  } = useDisclosure();
  const [groupName, setGroupName] = useState(oneGroup?.name);
  const [groupOrder, setGroupOrder] = useState(oneGroup?.order);
  const { mutate: deleteNode } = useDeleteNode(onDelClose);
  const { mutate: deleteTraceableNode } = useDeleteUntraceableNode(onDelClose);
  const { mutate: updateNode, isLoading: isUpdateLoading } = useUpdateNode();
  const { mutate: updateGroup } = useUpdateGroup(oneGroup?.id);
  const [deleteId, setDeleteId] = useState(null);
  const handleClickOutside = (e) => {
    if (!outsideRef.current.contains(e.target)) {
      setEdit(false);
      //setEditD(false)
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });

  const [edit, setEdit] = useState(false);
  const [nodeData, setNodeData] = useState(null);

  if (isNodeLoading) {
    return <NodeLoader />;
  }

  const handleAddNodeType = (node) => {
    setNodeData(node);
    onNodeOpen();
  };

  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      setEdit(false);
      updateGroup({ name: groupName, order: groupOrder, entity_id: entityId });
    }
  };

  const handleDelete = (id) => {
    setDeleteId(id);
    onDelOpen();
  };

  const getDeviceType = (id) => {
    return nodeTypeData?.data[0]?.result?.filter((node) => node.id === id)[0]
      ?.name;
  };

  const cData = [
    {
      Header: "Device Id",
      accessor: "device_id",
      showHeader: true,
      cell: (row) => {
        return row.device_id;
      },
    },
    {
      Header: "Device Type",
      showHeader: true,
      accessor: (row) => {
        return (
          <Text fontSize="sm" color="black" fontWeight="200">
            {getDeviceType(row.node_type_id)}
          </Text>
        );
      },
    },
    {
      Header: "Device Name",
      accessor: "name",
      showHeader: true,
      cell: (row) => {
        return row.name;
      },
    },
    {
      Header: "Ip Address",
      accessor: "ip_address",
      showHeader: true,
      cell: (row) => {
        return row.ip_address;
      },
    },
    {
      Header: "Node type id",
      accessor: "node_type_id",
      showHeader: false,
      cell: (row) => {
        return row.node_type_id;
      },
    },
    {
      Header: "Node id",
      accessor: "id",
      showHeader: false,
      cell: (row) => {
        return row.id;
      },
    },
    {
      Header: "Action",
      showHeader: true,
      accessor: (row) => {
        return (
          <Flex>
            <DeleteNodeListIcon
              style={{ cursor: "pointer" }}
              onClick={() => handleDelete(row.id)}
            />
            {/* <Box pl="10px" cursor="pointer" onClick={() => handleDownTime(row)}>
              <Icon
                as={SiClockify}
                width="20px"
                height="15px"
                color="#A3AED0"
              />
            </Box> */}
          </Flex>
        );
      },
    },
  ];

  const onSubmit = (data) => {
    data.tag.forEach((object, index) => {
      object.group_id = individualGroupData?.id;
      object.order = index + 1;
      object.entity_id = entityId;
    });

    updateNode(data.tag);
  };
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card bg="rgba(67, 24, 255, 0.02)" height="max-content" width="100%">
        <Flex align="center" position="relative">
          <div
            onClick={() => setShowCardDetails(false)}
            style={{ cursor: "pointer" }}
          >
            <Icon as={ArrowBackIcon} w={6} h={6} />
          </div>
          <div onClick={() => setEdit(true)} ref={outsideRef}>
            {edit ? (
              <form onKeyDown={onKeyDownHandler}>
                <Flex align="center" justify="space-between">
                  <Flex ml="20px" direction="column" mr="10px">
                    <Text fontSize={"15px"} color="#8f9bba" fontWeight="bold">
                      Name
                    </Text>
                    <Input
                      type="text"
                      id="fname"
                      name="fname"
                      size="sm"
                      mr="10px"
                      value={groupName}
                      onChange={(event) => setGroupName(event.target.value)}
                    />
                  </Flex>
                  <Flex direction="column">
                    <Text fontSize={"15px"} color="#8f9bba" fontWeight="bold">
                      Order
                    </Text>
                    <Input
                      type="text"
                      id="forder"
                      name="forder"
                      size="sm"
                      value={groupOrder}
                      onChange={(event) => setGroupOrder(event.target.value)}
                    />
                  </Flex>
                </Flex>
                {/* <input type="text" id="fname" name="fname" value={groupName} onChange={(event) => setGroupName(event.target.value)} /> */}
              </form>
            ) : (
              <Flex align="center" justify="space-between">
                <Tooltip hasArrow label="Rename" bg="#2B3674">
                  <Flex align="center" justify="space-between">
                    <Text
                      fontSize={"26px"}
                      mr="5px"
                      ml="22px"
                      color="#8f9bba"
                      fontWeight="bold"
                    >
                      Name :
                    </Text>
                    <Text fontSize={"26px"} color="#2B3674" fontWeight="bold">
                      {oneGroup?.name}
                    </Text>
                  </Flex>
                </Tooltip>
                <Tooltip hasArrow label="Order" bg="#2B3674">
                  <Flex ml="15px" align="center" justify="space-between">
                    <Text
                      fontSize={"22px"}
                      mr="5px"
                      color="#8f9bba"
                      fontWeight="bold"
                    >
                      Order :
                    </Text>
                    <Text fontSize={"26px"} color="#2B3674" fontWeight="bold">
                      {oneGroup?.order}
                    </Text>
                  </Flex>
                </Tooltip>
              </Flex>
            )}
          </div>
          <Button
            w="35px"
            h="30px"
            bg="#FFFFFF"
            borderRadius="7px"
            ml="20px"
            mr="10px"
            onClick={() => onOpen()}
          >
            {" "}
            <Icon as={AddIcon} w={3} h={3} color="#4318FF" />
          </Button>

          <Flex
            align="center"
            justify="space-around"
            height="40px"
            bg="white"
            width="80px"
            position="absolute"
            right="0"
            border="1px solid #DEDEDE"
            borderRadius="6px"
            cursor="pointer"
          >
            <Box
              pl="5px"
              pt="2px"
              onClick={() => setToggleViewMode(!toggleViewMode)}
            >
              {toggleViewMode ? <GridIconDark /> : <GridIconLight />}
            </Box>
            <Center height="40px">
              <Divider orientation="vertical" />
            </Center>
            <Box pr="10px" onClick={() => setToggleViewMode(!toggleViewMode)}>
              {toggleViewMode ? <ListIconLight /> : <ListIconDark />}
            </Box>
          </Flex>
        </Flex>
        {oneGroup?.node?.length > 0 ||
        oneGroup?.untraceable_node?.length > 0 ? (
          toggleViewMode ? (
            <Flex
              mt="20px"
              width="100%"
              style={{ flexWrap: "wrap" }}
              align={
                oneGroup?.node?.length === 0 ||
                oneGroup?.untraceable_node?.length > 0
                  ? "center"
                  : ""
              }
              justify={
                oneGroup?.node?.length === 0 ||
                oneGroup?.untraceable_node?.length > 0
                  ? "center"
                  : ""
              }
            >
              {oneGroup?.node?.map((node) => (
                <Card width="30%" height="max-content" mr="15px" mt="15px">
                  <Flex align="center" justify="space-between">
                    <Text fontSize={"15px"} color="#2B3674" fontWeight="bold">
                      {node.name}
                    </Text>
                    <Flex align="center">
                      {/* <Box
                        pr="10px"
                        cursor="pointer"
                        onClick={() =>
                          history.push(
                            `/admin/node-dashboard/${entityId}/node_id=${node.id}`
                          )
                        }
                      >
                        <Icon
                          as={BiDevices}
                          width="20px"
                          height="25px"
                          color="#A3AED0"
                        />
                      </Box> */}
                      {/* <Box
                        pr="10px"
                        cursor="pointer"
                        onClick={() => handleDownTime(node)}
                      >
                        <Icon
                          as={SiClockify}
                          width="20px"
                          height="15px"
                          color="#A3AED0"
                        />
                      </Box> */}

                      <div
                        style={{ paddingRight: "10px", cursor: "pointer" }}
                        onClick={() => handleAddNodeType(node)}
                      >
                        <Icon
                          color="secondaryGray.500"
                          as={MdEdit}
                          w="17px"
                          h="17px"
                        />
                      </div>
                      <DeleteNodeIcon
                        onClick={() => handleDelete(node.id)}
                        style={{ cursor: "pointer" }}
                      />
                    </Flex>
                  </Flex>
                  <Flex pt="10px">
                    <Tooltip hasArrow label="Device Id" bg="#A3AED0">
                      <Text
                        fontSize={"13px"}
                        color="#A3AED0"
                        fontWeight="500"
                        pr="10px"
                      >
                        {node.device_id}
                      </Text>
                    </Tooltip>
                    <Text
                      fontSize={"15px"}
                      color="#A3AED0"
                      fontWeight="bold"
                      pr="10px"
                    >
                      {"|"}
                    </Text>
                    <Tooltip hasArrow label="Ip Address" bg="#A3AED0">
                      <Text
                        fontSize={"13px"}
                        color="#A3AED0"
                        fontWeight="500"
                        pr="10px"
                      >
                        {node.ip_address}
                      </Text>
                    </Tooltip>
                    <Text
                      fontSize={"15px"}
                      color="#A3AED0"
                      fontWeight="bold"
                      pr="10px"
                    >
                      {"|"}
                    </Text>
                    <Tooltip hasArrow label="Device Type" bg="#A3AED0">
                      <Text
                        fontSize={"13px"}
                        color="#A3AED0"
                        fontWeight="500"
                        pr="10px"
                      >
                        {getDeviceType(node.node_type_id)}
                      </Text>
                    </Tooltip>
                  </Flex>
                </Card>
              ))}
              {oneGroup?.untraceable_node?.map((node) => (
                <Card width="30%" height="max-content" mr="15px" mt="15px">
                  <Flex align="center" justify="space-between">
                    <Text fontSize={"15px"} color="#2B3674" fontWeight="bold">
                      {node.name}
                    </Text>
                    <Flex align="center">
                      {/* <Box
                        pr="10px"
                        cursor="pointer"
                        onClick={() =>
                          history.push(
                            `/admin/node-dashboard/${entityId}/node_id=${node.id}`
                          )
                        }
                      >
                        <Icon
                          as={BiDevices}
                          width="20px"
                          height="25px"
                          color="#A3AED0"
                        />
                      </Box> */}
                      {/* <Box
                        pr="10px"
                        cursor="pointer"
                        onClick={() => handleDownTime(node)}
                      >
                        <Icon
                          as={SiClockify}
                          width="20px"
                          height="15px"
                          color="#A3AED0"
                        />
                      </Box> */}

                      <div
                        style={{ paddingRight: "10px", cursor: "pointer" }}
                        onClick={() => handleAddNodeType(node)}
                      >
                        <Icon
                          color="secondaryGray.500"
                          as={MdEdit}
                          w="17px"
                          h="17px"
                        />
                      </div>
                      <DeleteNodeIcon
                        onClick={() => handleDelete(node.id)}
                        style={{ cursor: "pointer" }}
                      />
                    </Flex>
                  </Flex>
                  <Flex pt="10px">
                    <Tooltip hasArrow label="Device Id" bg="#A3AED0">
                      <Text
                        fontSize={"13px"}
                        color="#A3AED0"
                        fontWeight="500"
                        pr="10px"
                      >
                        {node.device_id}
                      </Text>
                    </Tooltip>
                    <Text
                      fontSize={"15px"}
                      color="#A3AED0"
                      fontWeight="bold"
                      pr="10px"
                    >
                      {"|"}
                    </Text>
                    {/* <Tooltip hasArrow label="Ip Address" bg="#A3AED0">
                      <Text
                        fontSize={"13px"}
                        color="#A3AED0"
                        fontWeight="500"
                        pr="10px"
                      >
                        {node.ip_address}
                      </Text>
                    </Tooltip>
                    <Text
                      fontSize={"15px"}
                      color="#A3AED0"
                      fontWeight="bold"
                      pr="10px"
                    >
                      {"|"}
                    </Text> */}
                    <Tooltip hasArrow label="Device Type" bg="#A3AED0">
                      <Text
                        fontSize={"13px"}
                        color="#A3AED0"
                        fontWeight="500"
                        pr="10px"
                      >
                        {getDeviceType(node.node_type_id)}
                      </Text>
                    </Tooltip>
                  </Flex>
                </Card>
              ))}
            </Flex>
          ) : (
            <Card mt="30px">
              <form onSubmit={handleSubmit(onSubmit)}>
                <Button
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  w="151px"
                  h="50"
                  mt="20px"
                  mb="24px"
                  mr="20px"
                  type="submit"
                  isLoading={isUpdateLoading}
                  _hover={{ bg: isUpdateLoading ? "blue" : buttonHover }}
                >
                  <Text fontSize={"16px"} color="#FFFFFF" fontWeight="bold">
                    Save
                  </Text>
                </Button>
                <FormControl>
                  <Controller
                    name="tag"
                    control={control}
                    render={({ field }) => (
                      <NodeTable
                        name="tag"
                        columnsData={cData}
                        tableData={oneGroup?.node || oneGroup?.untraceable_node}
                        register={register}
                        {...field}
                      />
                    )}
                  />
                </FormControl>
              </form>
            </Card>
          )
        ) : (
          <Flex align="center" justify="center" mt="200px">
            <Flex direction="column" justify="center" align="center">
              <Image src={noGroupImage} width="70px" alt="no-group" />
              <Text fontSize={"22px"} color="#2B3674" fontWeight="bold">
                You Don’t Have Any Device added for this group
              </Text>
            </Flex>
          </Flex>
        )}
      </Card>
      <NodeModal
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        data={nodeTypeData?.data[0]?.result}
        groupData={individualGroupData}
        groupId={individualGroupData?.id}
        setShowCreateGroup={setShowCardDetails}
        entityId={entityId}
      />
      <NodeEditModal
        isOpen={isNodeOpen}
        onOpen={onNodeOpen}
        onClose={onNodeClose}
        data={nodeTypeData?.data[0]?.result}
        nodeData={nodeData}
        traceableDevices={traceableDevices}
        groupData={oneGroup}
        setShowCardDetails={setShowCardDetails}
        entityId={entityId}
      />
      <DeleteModal
        isOpen={isDelOpen}
        onOpen={onDelOpen}
        onClose={onDelClose}
        id={deleteId}
        deleteItem={traceableDevices ? deleteNode : deleteTraceableNode}
      />
    </Box>
  );
};

export default CardDetails;
