import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Text,
  Button,
  Tooltip,
  Input,
  FormControl,
  Icon,
  useDisclosure,
  Flex,
  useColorModeValue,
} from "@chakra-ui/react";
import { DeleteNodeListIcon } from "components/icons/Icons";
import { MdEdit } from "react-icons/md";
import Card from "components/card/Card.js";
import useUpdateGroup from "hooks/useUpdateGroup";
import useUpdateNode from "hooks/useUpdateNode";
import useDeleteGroup from "hooks/useDeleteGroup";
import useDeleteNode from "hooks/useDeleteNode";
import NodeTable from "components/table/nodeTable";
import { useForm, Controller } from "react-hook-form";
import { AddIcon } from "@chakra-ui/icons";
import NodeModal from "components/modal/nodeModal";
import DeleteModal from "components/modal/deleteModal";
import { useDeleteUntraceableNode } from "hooks/useUntraceableNode";

const ListGroup = ({
  group,
  index,
  nodeData,
  entityId,
  traceableDevices = true,
  setIndividualGroupData,
}) => {
  const [edit, setEdit] = useState(false);
  const [groupName, setGroupName] = useState(group?.name);
  const outsideRef = useRef();
  const { mutate: updateGroup } = useUpdateGroup(group.id);
  const { mutate: updateNode, isLoading } = useUpdateNode();
  const [deleteId, setDeleteId] = useState(null);
  const { mutate: deleteGroup } = useDeleteGroup();

  const buttonHover = useColorModeValue("brand.600", "brand.400");

  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm();

  const {
    isOpen: isNodeOpen,
    onOpen: onNodeOpen,
    onClose: onNodeClose,
  } = useDisclosure();

  const {
    isOpen: isDeleteOpen,
    onOpen: onDeleteOpen,
    onClose: onDeleteClose,
  } = useDisclosure();
  const { mutate: deleteNode } = useDeleteNode(onDeleteClose);
  const { mutate: deleteTraceableNode } =
    useDeleteUntraceableNode(onDeleteClose);

  const handleClickOutside = (e) => {
    if (!outsideRef.current.contains(e.target)) {
      setEdit(false);
    }
  };
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  });
  const onKeyDownHandler = (e) => {
    if (e.keyCode === 13) {
      setEdit(false);
      updateGroup({ name: groupName, order: index + 1, entity_id: entityId });
    }
  };

  const getDeviceType = (id) => {
    return nodeData?.filter((node) => node.id === id)[0]?.name;
  };

  const handleDelete = (id) => {
    setDeleteId(id);
    onDeleteOpen();
    //deleteEntity(id);
  };

  const cData = [
    {
      Header: "Id",
      accessor: "id",
      showHeader: false,
      cell: (row) => {
        return row.id;
      },
    },
    {
      Header: "Device Id",
      accessor: "device_id",
      showHeader: true,
      cell: (row) => {
        return row.device_id;
      },
    },
    {
      Header: "Device Type",
      showHeader: true,
      accessor: (row) => {
        return (
          <Text fontSize="sm" color="black" fontWeight="200">
            {getDeviceType(row.node_type_id)}
          </Text>
        );
      },
    },
    {
      Header: "Device Name",
      accessor: "name",
      showHeader: true,
      cell: (row) => {
        return row?.name;
      },
    },
    {
      Header: "Ip Address",
      accessor: "ip_address",
      showHeader: true,
      cell: (row) => {
        return row.ip_address;
      },
    },
    {
      Header: "Action",
      showHeader: true,
      accessor: (row) => {
        return (
          <DeleteNodeListIcon
            style={{ cursor: "pointer" }}
            onClick={() => handleDelete(row.id)}
          />
        );
      },
    },
  ];

  const onSubmit = (data) => {
    data.tag.forEach((object, index) => {
      object.group_id = group?.id;
      object.order = index + 1;
      object.entity_id = entityId;
    });
    updateNode(data.tag);
  };
  return (
    <Box pt="30px" ml="10px">
      <Flex direction="column" pt="10px">
        <Flex>
          <Tooltip hasArrow label="Rename" bg="#2B3674">
            <Box
              onClick={() => setEdit(true)}
              ref={outsideRef}
              width="max-content"
            >
              {edit ? (
                <form onKeyDown={onKeyDownHandler}>
                  <Input
                    type="text"
                    id="fname"
                    name="fname"
                    size="sm"
                    value={groupName}
                    onChange={(event) => setGroupName(event.target.value)}
                  />
                  {/* <input type="text" id="fname" name="fname" value={groupName} onChange={(event) => setGroupName(event.target.value)} /> */}
                </form>
              ) : (
                <Text
                  fontSize={"20px"}
                  pl="10px"
                  color="#2B3674"
                  fontWeight="bold"
                >
                  {groupName}
                </Text>
              )}
            </Box>
          </Tooltip>
          <Button
            w="25px"
            h="30px"
            bg="white"
            borderRadius="7px"
            ml="10px"
            onClick={() => onNodeOpen()}
          >
            {" "}
            <Icon as={AddIcon} w={3} h={3} color="#4318FF" />
          </Button>
        </Flex>
        <Card mt="10px">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Button
              fontSize="sm"
              variant="brand"
              fontWeight="500"
              w="151px"
              h="50"
              mt="20px"
              mb="24px"
              mr="20px"
              type="submit"
              isLoading={isLoading}
              _hover={{ bg: isLoading ? "blue" : buttonHover }}
            >
              <Text fontSize={"16px"} color="#FFFFFF" fontWeight="bold">
                Save
              </Text>
            </Button>
            <FormControl>
              <Controller
                name="tag"
                control={control}
                render={({ field }) => (
                  <NodeTable
                    name="tag"
                    columnsData={cData}
                    tableData={group?.node || group?.untraceable_node}
                    register={register}
                    {...field}
                  />
                )}
              />
            </FormControl>
          </form>
        </Card>
      </Flex>
      <NodeModal
        isOpen={isNodeOpen}
        onOpen={onNodeOpen}
        onClose={onNodeClose}
        data={nodeData}
        nodeTypeName={"mi"}
        groupData={group}
        groupId={group?.id}
        setShowCreateGroup={false}
        entityId={entityId}
      />
      <DeleteModal
        onOpen={onDeleteOpen}
        isOpen={isDeleteOpen}
        onClose={onDeleteClose}
        id={deleteId}
        deleteItem={traceableDevices ? deleteNode : deleteTraceableNode}
        // deleteItem={deleteNode}
      />
    </Box>
  );
};

export default ListGroup;
