import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  Select,
  Input,
  Flex,
  FormLabel,
  Text,
  ModalCloseButton,
  useColorModeValue,
  Button,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import useCopyGroup from "hooks/useCopyGroup";
import { NO_OF_COPY } from "constants/globalConstants";

const CopyGroupModal = ({ onClose, isOpen, onOpen, group, entityId }) => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const { mutate: copyGroup, isLoading } = useCopyGroup(onClose);
  const textColor = useColorModeValue("navy.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const buttonHover = useColorModeValue("brand.600", "brand.400");
  const generateCopyOptions = () => {
    return NO_OF_COPY?.map((copy) => {
      return <option value={copy.value}>{copy.label}</option>;
    });
  };
  const onSubmit = (data) => {
    copyGroup({ id: group?.id, count: data.count, type: "Group_request" });
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        blockScrollOnMount={false}
        size="sm"
      >
        <ModalOverlay />
        <form onSubmit={handleSubmit(onSubmit)}>
          <ModalContent>
            <Flex mt="50px" pl="10px">
              <Flex direction="column" width="100%" pr="10px">
                <FormLabel
                  display="flex"
                  ms="4px"
                  fontSize="sm"
                  fontWeight="500"
                  color={textColor}
                  mb="8px"
                >
                  No of Copy<Text color={brandStars}>*</Text>
                </FormLabel>
                <div style={{ marginBottom: "10px" }}>
                  <Controller
                    name="count"
                    rules={{ required: true }}
                    control={control}
                    render={({ field }) => (
                      <Select
                        id="user_type"
                        size="lg"
                        fontSize="16px"
                        placeholder="Select No of Copy"
                        {...field}
                      >
                        {generateCopyOptions()}
                      </Select>
                    )}
                  />
                  {errors.name?.type === "required" && (
                    <span style={{ color: "red" }}>device is required</span>
                  )}
                </div>
              </Flex>
            </Flex>
            <Button
              bg="#4318FF"
              variant="brand"
              mx={3}
              mt={2}
              mb={10}
              width="140px"
              isLoading={isLoading}
              _hover={{ bg: isLoading ? "blue" : buttonHover }}
              type="submit"
            >
              <Text color={"#FFFFFF"}>Copy Group</Text>
            </Button>
            <ModalCloseButton />
          </ModalContent>
        </form>
      </Modal>
    </>
  );
};

export default CopyGroupModal;
