import { QUERY_KEY, MUTATION_KEY } from "constants/globalConstants";
import CrudApiService from "apiService/crudApiService";
import { useMutation, useQueryClient, useQuery } from "react-query";
import toast from "react-hot-toast";

const useEntityClientList = (id, pageSize, currentPage) => {
  return useQuery(
    QUERY_KEY.GET_ENTITY_CLIENT_LIST,
    () => CrudApiService.getEntityClientList(id, pageSize, currentPage),
    { retry: false }
  );
};

const useDownloadInstallClient = (enabled, setEnabled) => {
  return useQuery(
    QUERY_KEY.GET_DOWNLOAD_INSTALL_CLIENT,
    () => CrudApiService.downloadInstallClient(),
    {
      retry: false,
      enabled: enabled,
      onSuccess: (res) => {
        setEnabled(false);
      },
      onError: (err) => {
        toast.error(err.response.data.message);
        setEnabled(false);
      },
    }
  );
};

const useCreateEntityClient = (setShowAddForm) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.CREATE_ENTITY_CLIENT],
    (data) => CrudApiService.addEntityClient(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.GET_ENTITY_CLIENT_LIST);
        setShowAddForm(false);
        toast.success("Client added successfully");
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

const useDeleteEntityClient = (onClose) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.DELETE_ENTITY_CLIENT],
    (id) => CrudApiService.deleteEntityClient(id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.GET_ENTITY_CLIENT_LIST);
        onClose();
        toast.success("Client deactivated successfully");
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

const useUpdateEntityClient = (setShowEditForm) => {
  const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.UPDATE_ENTITY_CLIENT],
    (data) => CrudApiService.editEntityClient(data),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(QUERY_KEY.GET_ENTITY_CLIENT_LIST);
        setShowEditForm(false);
        toast.success("Client updated successfully");
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

const useOnBoardClient = (onClose) => {
  //const queryClient = useQueryClient();
  return useMutation(
    [MUTATION_KEY.ONBOARD_CLIENT],
    (data) => CrudApiService.onBoardClient(data),
    {
      onSuccess: () => {
        //queryClient.invalidateQueries(QUERY_KEY.GET_ENTITY_CLIENT_LIST);
        onClose();
        toast.success("Client onboarded successfully");
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

const useCheckLicense = (setLicenseData) => {
  return useMutation(
    [MUTATION_KEY.CHECK_LICENSE],
    (data) => CrudApiService.checkLicense(data),
    {
      onSuccess: (data) => {
        setLicenseData(data.data.sample_env);
        toast.success("License generated successfully");
      },
      onError: (error) => {
        toast.error(error.response.data.message);
      },
    }
  );
};

const EntityClientHooks = {
  useEntityClientList,
  useDownloadInstallClient,
  useCreateEntityClient,
  useDeleteEntityClient,
  useUpdateEntityClient,
  useOnBoardClient,
  useCheckLicense,
};

export default EntityClientHooks;
