import request from "utils";

const getEntityDashboardDetails = async (id, data_type) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/entity_dashboard_detail/${id}?data_type=${data_type}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getEntityNodetypePerformance = async (id, order_by, data_type) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/entity_nodetype_performance/${id}?order_by=${order_by}&data_type=${data_type}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getEntityMetaData = async (id) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/entity_metadata/${id}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getEntityGroupPerformance = async (id, order_by, data_type) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/entity_group_performance/${id}?order_by=${order_by}&data_type=${data_type}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getEntityNodePerformance = async (id, order_by, data_type) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/entity_node_performance/${id}?order_by=${order_by}&data_type=${data_type}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getNodeMttrMtbf = async (id, nodeId) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/node_mttr_mtbf/${id}?node_id=${nodeId}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getNodeLogRange = async (id, nodeId, pageSize, currentPage) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/node_log_range/${id}?node_id=${nodeId}&page_size=${pageSize}&page=${currentPage}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getBreakupReasonListing = async () => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/breakup-reason`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getNodeTypeId = async (entityId, id) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/node_list/${entityId}?node_type_id=${id}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getSlaPerformanceReport = async (
  entityTypeId,
  entityTypeName,
  nodeTypeId,
  nodeTypeName,
  nodeId,
  nodeIdName,
  startDate,
  endDate,
  uptimeChecked,
  scheduleChecked,
  effectiveChecked,
  showTimeChecked,
  setSlaPerformanceMetrics,
  breakupReasonID,
  showBreakupChecked
) => {
  const { data } = await request.get(
    entityTypeId !== "0"
      ? `${
          process.env.REACT_APP_BASE_URL
        }/sla_performance_detail?entity_id=${entityTypeId}&entity_name=${entityTypeName}&node_type_id=${nodeTypeId}&node_type_name=${nodeTypeName}&node_id=${nodeId}&node_name=${nodeIdName}&start_at=${startDate}&end_at=${endDate}&uptime=${uptimeChecked}&schedule_downtime=${scheduleChecked}&effective_downtime=${effectiveChecked}&show_time_logs=${showTimeChecked}&breakup_reason_id=${
          showBreakupChecked ? breakupReasonID : ""
        }&show_breakup_logs=${showBreakupChecked}`
      : `${
          process.env.REACT_APP_BASE_URL
        }/sla_performance_detail?node_type_id=${nodeTypeId}&node_type_name=${nodeTypeName}&start_at=${startDate}&end_at=${endDate}&uptime=${uptimeChecked}&schedule_downtime=${scheduleChecked}&effective_downtime=${effectiveChecked}&show_time_logs=${showTimeChecked}&breakup_reason_id=${
          showBreakupChecked ? breakupReasonID : ""
        }&show_breakup_logs=${showBreakupChecked}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  setSlaPerformanceMetrics(data);
  return data;
};

const getBlackoutReport = async (
  entityTypeId,
  entityTypeName,
  startDate,
  endDate,
  showTimeChecked
) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/get_blackout_detail?entity_id=${entityTypeId}&entity_name=${entityTypeName}&start_at=${startDate}&end_at=${endDate}&show_time_logs=${showTimeChecked}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getSummaryReport = async (startDate, endDate) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/node-summarize-report?start_at=${startDate}&end_at=${endDate}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getTicketReport = async (
  type_id,
  entityId,
  priority,
  startDate,
  endDate,
  jsonResponse = "0"
) => {
  const { data } = await request.get(
    `${
      process.env.REACT_APP_BASE_URL
    }/ticket_report?ticket_type=${type_id}&entity_id=${entityId}&priority=${priority}&start_at=${startDate}&end_at=${endDate}&json_response=${
      jsonResponse === 1 ? 1 : 0
    }`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getSummaryReportCsv = async (startDate, endDate) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/node-summarize-sla-csv?start_at=${startDate}&end_at=${endDate}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );

  return data;
};

const getScheduleDowntimeReport = async (
  entityTypeId,
  entityTypeName,
  nodeTypeId,
  nodeTypeName,
  nodeId,
  nodeIdName,
  startDate,
  endDate,
  scheduleChecked,
  showTimeChecked
) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/get_schedule_downtime_detail?entity_id=${entityTypeId}&entity_name=${entityTypeName}&node_type_id=${nodeTypeId}&node_type_name=${nodeTypeName}&node_id=${nodeId}&node_name=${nodeIdName}&start_at=${startDate}&end_at=${endDate}&schedule_downtime=${scheduleChecked}&show_time_logs=${showTimeChecked}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const postSlaPerformanceReport = async (apiData) => {
  const { data } = await request.post(
    `${process.env.REACT_APP_BASE_URL}/sla_performance_detail/`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getNodeTypeList = async (entityId) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/node_type_list/${entityId}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getUntraceableNodeTypeList = async (entityId) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/untraceable_node_list/${entityId}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getSlaTimeLog = async (entityId, id) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/sla_timelogs/${entityId}?node_type_id=${id}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const postCsvReport = async (apiData) => {
  const { data } = await request.post(
    `${process.env.REACT_APP_BASE_URL}/generate_sla_csv/`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getCsvReport = async (
  entityTypeId,
  entityTypeName,
  nodeTypeId,
  nodeTypeName,
  nodeId,
  nodeIdName,
  startDate,
  endDate,
  uptimeChecked,
  scheduleChecked,
  effectiveChecked,
  showTimeChecked
) => {
  const { data } = await request.get(
    entityTypeId !== "0"
      ? `${process.env.REACT_APP_BASE_URL}/generate_sla_csv?entity_id=${entityTypeId}&entity_name=${entityTypeName}&node_type_id=${nodeTypeId}&node_type_name=${nodeTypeName}&node_id=${nodeId}&node_name=${nodeIdName}&start_at=${startDate}&end_at=${endDate}&uptime=${uptimeChecked}&schedule_downtime=${scheduleChecked}&effective_downtime=${effectiveChecked}&show_time_logs=${showTimeChecked}`
      : `${process.env.REACT_APP_BASE_URL}/generate_sla_csv?node_type_id=${nodeTypeId}&node_type_name=${nodeTypeName}&start_at=${startDate}&end_at=${endDate}&uptime=${uptimeChecked}&schedule_downtime=${scheduleChecked}&effective_downtime=${effectiveChecked}&show_time_logs=${showTimeChecked}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  //setSlaPerformanceMetrics(data)
  return data;
};

const getScheduleCsvReport = async (
  entityTypeId,
  entityTypeName,
  nodeTypeId,
  nodeTypeName,
  nodeId,
  nodeIdName,
  startDate,
  endDate,
  uptimeChecked,
  scheduleChecked,
  effectiveChecked,
  showTimeChecked
) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/generate_schedule_report?entity_id=${entityTypeId}&entity_name=${entityTypeName}&node_type_id=${nodeTypeId}&node_type_name=${nodeTypeName}&node_id=${nodeId}&node_name=${nodeIdName}&start_at=${startDate}&end_at=${endDate}&uptime=${uptimeChecked}&schedule_downtime=${scheduleChecked}&effective_downtime=${effectiveChecked}&show_time_logs=${showTimeChecked}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  //setSlaPerformanceMetrics(data)
  return data;
};

const postBlackoutDetail = async (apiData) => {
  const { data } = await request.post(
    `${process.env.REACT_APP_BASE_URL}/get_blackout_detail/`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const postScheduleDownTimeDetail = async (apiData) => {
  const { data } = await request.post(
    `${process.env.REACT_APP_BASE_URL}/get_schedule_downtime_detail/`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const postBlackoutReport = async (apiData) => {
  const { data } = await request.post(
    `${process.env.REACT_APP_BASE_URL}/generate_blackout_report/`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getBlackoutCsvReport = async (
  entityTypeId,
  entityTypeName,
  nodeTypeId,
  nodeTypeName,
  nodeId,
  nodeIdName,
  startDate,
  endDate,
  uptimeChecked,
  scheduleChecked,
  effectiveChecked,
  showTimeChecked
) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/generate_blackout_report?entity_id=${entityTypeId}&entity_name=${entityTypeName}&node_type_id=${nodeTypeId}&node_type_name=${nodeTypeName}&node_id=${nodeId}&node_name=${nodeIdName}&start_at=${startDate}&end_at=${endDate}&uptime=${uptimeChecked}&schedule_downtime=${scheduleChecked}&effective_downtime=${effectiveChecked}&show_time_logs=${showTimeChecked}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  //setSlaPerformanceMetrics(data)
  return data;
};

const postSchehduleDowntimeReport = async (apiData) => {
  const { data } = await request.post(
    `${process.env.REACT_APP_BASE_URL}/generate_schedule_report/`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getNopdBreakupList = async (
  pageSize,
  currentPage,
  status,
  entityId,
  nodeTypeId
) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/nopd_breakup_list?page_size=${pageSize}&page=${currentPage}&status=${status}&entity_id=${entityId}&node_type_id=${nodeTypeId}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const getEntityNopdBreakupList = async (
  pageSize,
  currentPage,
  status,
  entityId
) => {
  const { data } = await request.get(
    `${process.env.REACT_APP_BASE_URL}/entity_nopd_breakup_list?page_size=${pageSize}&page=${currentPage}&status=${status}&entity_id=${entityId}`,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const createNopdBreakupList = async (apiData) => {
  const { data } = await request.post(
    `${process.env.REACT_APP_BASE_URL}/create_nopd_breakup`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const createEntityNopdBreakup = async (apiData) => {
  const { data } = await request.post(
    `${process.env.REACT_APP_BASE_URL}/create_entity_nopd_breakup`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const updateNopdBreakupList = async (apiData) => {
  const { data } = await request.post(
    `${process.env.REACT_APP_BASE_URL}/update_nopd_breakup`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const updateEntityNopdBreakupList = async (apiData) => {
  const { data } = await request.post(
    `${process.env.REACT_APP_BASE_URL}/update_entity_nopd_breakup`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const updateNopdBreakupStatus = async (apiData) => {
  const { data } = await request.put(
    `${process.env.REACT_APP_BASE_URL}/update_nopd_breakup_status`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const updateEntityNopdBreakupStatus = async (apiData) => {
  const { data } = await request.put(
    `${process.env.REACT_APP_BASE_URL}/update_entity_nopd_breakup_status`,
    apiData,
    {
      headers: { Authorization: localStorage.getItem("token") },
    }
  );
  return data;
};

const DashboardApiService = {
  getEntityDashboardDetails,
  getEntityMetaData,
  getEntityNodetypePerformance,
  getEntityNodePerformance,
  getEntityGroupPerformance,
  getNodeMttrMtbf,
  getNodeLogRange,
  getBreakupReasonListing,
  getNodeTypeId,
  getSlaPerformanceReport,
  postSlaPerformanceReport,
  getNodeTypeList,
  getUntraceableNodeTypeList,
  getSlaTimeLog,
  getBlackoutReport,
  getSummaryReport,
  getTicketReport,
  getSummaryReportCsv,
  getScheduleDowntimeReport,
  postCsvReport,
  getCsvReport,
  getEntityNopdBreakupList,
  postBlackoutDetail,
  createEntityNopdBreakup,
  postScheduleDownTimeDetail,
  getScheduleCsvReport,
  getBlackoutCsvReport,
  postBlackoutReport,
  postSchehduleDowntimeReport,
  updateEntityNopdBreakupList,
  getNopdBreakupList,
  createNopdBreakupList,
  updateNopdBreakupList,
  updateNopdBreakupStatus,
  updateEntityNopdBreakupStatus,
};

export default DashboardApiService;
