import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

export const getLayoutPermissions = (user) => {
  switch (user) {
    case "superAdmin":
      return "full permissions";
    case "admin":
      return "grid permissions";
    case "customer":
      return "list permissions";
    default:
      return "full permissions";
  }
};

export const getDashboardPermissions = (permissionName) => {
  if (permissionName === "ticketing.dashboard.show") {
    if (localStorage.getItem("permissions")?.includes("ticketing.dashboard")) {
      return true;
    } else {
      if (
        localStorage.getItem("permissions")?.includes("ticketing.ticket.view")
      ) {
        return true;
      } else {
        return false;
      }
    }
  } else if (permissionName === "report_view.all") {
    if (
      localStorage.getItem("permissions")?.includes("entity_report.view") ||
      localStorage.getItem("permissions")?.includes("schedule_report.view") ||
      localStorage.getItem("permissions")?.includes("blackout_report.view")
    ) {
      return true;
    } else {
      return false;
    }
  } else if (permissionName === "configuration_view.all") {
    if (
      localStorage.getItem("permissions")?.includes("customer.view") ||
      localStorage.getItem("permissions")?.includes("schedule_report.view") ||
      localStorage.getItem("permissions")?.includes("template.view") ||
      localStorage.getItem("permissions")?.includes("entity.view") ||
      localStorage
        .getItem("permissions")
        ?.includes("node.downtime.view_downtime_breakup") ||
      localStorage.getItem("permissions")?.includes("config.view") ||
      localStorage.getItem("permissions")?.includes("config_store.view")
    ) {
      return true;
    } else {
      return false;
    }
  } else {
    return localStorage.getItem("permissions")?.includes(permissionName);
  }
};

export const getDate = (date) => {
  return date ? dayjs(date).format("DD MMM YYYY") : "";
};

export const getDateAndTime = (date) => {
  return `${dayjs(date).format("DD MMM YYYY")} at ${dayjs(date).format(
    "hh:mm a"
  )}`;
};

export const getApiDate = (date) => {
  return date ? dayjs(date).format("YYYY-MM-DD") : "";
};

export const getActiveSince = (date) => {
  return `Active since ${dayjs(date).fromNow()}`;
};

export const getOverdueSince = (date) => {
  return `Overdue since ${dayjs(date).fromNow()}`;
};
