import { QUERY_KEY } from "constants/globalConstants";
import UserService from "apiService/permissions";
import { useQuery } from "react-query";

const useUserList = (pageSize, currentPage) => {
  return useQuery(
    QUERY_KEY.GET_USER_LIST,
    () => UserService.getUserList(pageSize, currentPage),
    { retry: false }
  );
};

export default useUserList;
