import { mode } from "@chakra-ui/theme-tools";
export const globalStyles = {
  colors: {
    brand: {
      100: "#E9E3FF",
      200: "#422AFB",
      300: "#422AFB",
      400: "#7551FF",
      500: "#422AFB",
      600: "#3311DB",
      700: "#02044A",
      800: "#190793",
      900: "#11047A",
    },
    brandScheme: {
      100: "#E9E3FF",
      200: "#7551FF",
      300: "#7551FF",
      400: "#7551FF",
      500: "#422AFB",
      600: "#3311DB",
      700: "#02044A",
      800: "#190793",
      900: "#02044A",
    },
    brandTabs: {
      100: "#E9E3FF",
      200: "#422AFB",
      300: "#422AFB",
      400: "#422AFB",
      500: "#422AFB",
      600: "#3311DB",
      700: "#02044A",
      800: "#190793",
      900: "#02044A",
    },
    secondaryGray: {
      100: "#E0E5F2",
      200: "#E1E9F8",
      300: "#F4F7FE",
      400: "#E9EDF7",
      500: "#8F9BBA",
      600: "#A3AED0",
      700: "#707EAE",
      800: "#707EAE",
      900: "#1B2559",
    },
    red: {
      100: "#FEEFEE",
      200: "#FF0000",
      500: "#EE5D50",
      600: "#E31A1A",
    },
    blue: {
      50: "#EFF4FB",
      500: "#3965FF",
    },
    orange: {
      100: "#FFF6DA",
      500: "#FFB547",
    },
    green: {
      100: "#E6FAF5",
      500: "#01B574",
    },
    navy: {
      50: "#d0dcfb",
      100: "#aac0fe",
      200: "#a3b9f8",
      300: "#728fea",
      400: "#3652ba",
      500: "#1b3bbb",
      600: "#24388a",
      700: "#1B254B",
      800: "#111c44",
      900: "#0b1437",
    },
    gray: {
      100: "#FAFCFE",
    },
    ndmsColors: {
      gray: {
        50: "#E1E9F8",
        90: "#EAEDF2",
        100: "#8F9BBA",
        150: "#718096",
        200: "#A0AEC0",
        250: "E0E0E0",
        300: "#AEAEAE",
        350: "#F7F7F7",
        400: "#CBD5E0",
        450: "#DCE3EB",
        500: "#E2E8F0",
        600: "#878cbd",
        700: "#828282",
        800: "#2D3748",
        900: "#1A202C",
        1000: "#A3AED0",
        1100: "#E5E5E5",
        1200: "#1B2559",
        1300: "#F4F7FE",
        1400: "#FAFCFE",
        1500: "#E9EDF7",
        1600: "#E0E5F2",
        1700: "#333333",
        1800: "#343B46",
        1900: "#7B818A",
        2000: "#8B8B8B",
        2100: "#707EAE",
        2200: "#9EA2A9",
        2300: "#EEEFFA",
      },
      purple: {
        50: "#E3DAFF",
        100: "#7551FF",
        200: "#4318FF",
        300: "#6A53FF",
        400: "#F2EFFF",
        500: "#8694BF",
        600: "#2500BC",
        700: "#8F9BC3",
      },
      black: {
        100: "#FFF",
        200: "#141824",
      },
      red: {
        100: "#EB5757",
        200: "#F86689",
        300: "#EE5D50",
        400: "#FBD6D6",
      },
      orange: {
        50: "#FAD285",
        100: "#FFF6DA",
        500: "#FFB547",
      },
      green: {
        50: "#93D8B7",
        60: "#CDEECA",
        100: "#27AE60",
        200: "#E6FAF5",
        300: "#01B574",
        400: "#219653",
      },
      blue: {
        50: "#EFF4FB",
        100: "#2B3674",
        200: "#2b6cb0",
        300: "#422AFB",
        400: "#3311DB",
        500: "#1B254B",
        600: "#3965FF",
        700: "#F0F8FF",
        800: "#E6ECFA",
        900: "#5E37FF",
        1000: "#2F80ED",
      },
      skyBlue: {
        50: "#FBFCFF",
        100: "#6AD2FF",
        200: "#39B8FF",
        300: "#58B0E1",
        400: "#179DBA",
      },
      navy: {
        100: "#0b1437",
        200: "#111c44",
        300: "#02044A",
        400: "#11047A",
        500: "#190793",
      },
    },
  },
  weights: {
    light: 200,
    normal: 400,
    medium: 500,
    bold: 600,
    bolder: 700,
  },
  styles: {
    global: (props) => ({
      body: {
        overflowX: "hidden",
        bg: mode("#F4F7FE", "#0b1437")(props),
        fontFamily: "DM Sans",
        letterSpacing: "-0.5px",
      },
      input: {
        color: "#2D3748",
      },
      html: {
        fontFamily: "DM Sans",
      },
    }),
  },
};
