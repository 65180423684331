import React, { useEffect, useState } from "react";
import Card from "components/card/Card.js";
import {
  Box,
  Button,
  Flex,
  FormLabel,
  RadioGroup,
  Radio,
  Stack,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import TicketModuleHooks from "hooks/ticketModule/ticketModuleHooks";
import { useHistory, useParams } from "react-router-dom";
import LoadingForm from "components/loader/LoadingForm";

function EditTicketStatus() {
  // Chakra color mode
  const { id } = useParams();
  const textColor = useColorModeValue("navy.700", "white");
  const [editData, setEditData] = useState([]);
  let activeColor = useColorModeValue("gray.700", "white");
  const buttonHover = useColorModeValue("brand.600", "brand.400");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const { isLoading, error, refetch, isFetching } =
    TicketModuleHooks.useAllTicketStatusWithId(id, setEditData);
  const history = useHistory();

  const { mutate: editStatus } = TicketModuleHooks.useEditStatus(id);
  const {
    handleSubmit,
    control,
    formState: { errors },

    setValue,
  } = useForm();

  useEffect(() => {
    if (id !== undefined) {
      refetch();
    }
  }, [id]);

  useEffect(() => {
    if (editData?.length > 0) {
      setValue("name", editData[0]?.name);
      setValue("slug", editData[0]?.slug);
      editData[0]?.is_a_close_status !== null &&
        setValue(
          "is_a_close_status",
          editData[0]?.is_a_close_status ? "true" : "false"
        );
    }
  }, [editData]);

  const onSubmit = (data) => {
    editStatus({
      name: data.name,
      slug: data.slug,
      is_a_close_status: data.is_a_close_status === "true" ? true : false,
    });
  };

  if (isLoading || isFetching) return <LoadingForm />;

  const handleCancel = () => {
    history.push(`/admin/ticket-crud/ticket-status`);
  };

  return (
    <>
      <Box w="100%" pt={{ base: "130px", md: "80px", xl: "80px" }}>
        <Card>
          <Flex
            maxW={{ base: "100%" }}
            w="100%"
            mx={{ base: "auto", lg: "0px" }}
            me="auto"
            h="100%"
            alignItems="start"
            justifyContent="center"
            mb={{ base: "30px", md: "30px" }}
            px={{ base: "25px", md: "0px" }}
            mt={{ base: "20px", md: "20px" }}
            flexDirection="column"
          >
            <Box me="auto">
              <Text
                mb="24px"
                ms="4px"
                color={activeColor}
                fontWeight="bold"
                fontSize="24px"
              >
                Edit Ticket Status
              </Text>
            </Box>
            <Flex
              direction="column"
              w="100%"
              maxW="100%"
              background="transparent"
              borderRadius="15px"
              mx={{ base: "auto", lg: "unset" }}
              me="auto"
              mb={{ base: "20px", md: "auto" }}
            >
              <form onSubmit={handleSubmit(onSubmit)} style={{ width: "100%" }}>
                <Flex w="100%">
                  <Flex direction="column" width="100%" pl="10px">
                    <FormLabel
                      display="flex"
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      mb="8px"
                    >
                      Name<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <div style={{ marginBottom: "24px" }}>
                      <Controller
                        name="name"
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                          <Input
                            fontSize="sm"
                            ms={{ base: "0px", md: "0px" }}
                            type="name"
                            placeholder="Enter name"
                            //mb="24px"
                            fontWeight="500"
                            size="lg"
                            {...field}
                          />
                        )}
                      />
                      {errors.name?.type === "required" && (
                        <span style={{ color: "red" }}>name is required</span>
                      )}
                    </div>
                  </Flex>
                  <Flex direction="column" width="100%" pl="10px">
                    <FormLabel
                      ms="4px"
                      fontSize="sm"
                      fontWeight="500"
                      color={textColor}
                      display="flex"
                    >
                      Slug<Text color={brandStars}>*</Text>
                    </FormLabel>
                    <div style={{ marginBottom: "24px" }}>
                      <Controller
                        name="slug"
                        rules={{ required: true }}
                        control={control}
                        render={({ field }) => (
                          <Input
                            fontSize="sm"
                            placeholder="Enter Slug"
                            disabled={true}
                            size="lg"
                            type="name"
                            {...field}
                          />
                        )}
                      />
                      {errors.slug?.type === "required" && (
                        <span style={{ color: "red" }}>slug is required</span>
                      )}
                    </div>
                  </Flex>
                </Flex>

                <Flex direction="column" width="100%" pl="10px">
                  <FormLabel
                    display="flex"
                    ms="4px"
                    fontSize="sm"
                    fontWeight="500"
                    color={textColor}
                    mb="8px"
                  >
                    Is Closed
                  </FormLabel>

                  <div style={{ marginBottom: "24px" }}>
                    <Controller
                      name="is_a_close_status"
                      rules={{ required: false }}
                      control={control}
                      render={({ field }) => (
                        <RadioGroup {...field}>
                          <Stack direction="row">
                            <Radio
                              value={"true"}
                              borderColor="#422AFB"
                              pr="20px"
                            >
                              Yes
                            </Radio>
                            <Radio
                              value={"false"}
                              borderColor="#422AFB"
                              pr="20px"
                            >
                              No
                            </Radio>
                          </Stack>
                        </RadioGroup>
                      )}
                    />
                  </div>
                </Flex>

                <Flex width="40%" pl="10px">
                  <Button
                    fontSize="sm"
                    variant="brand"
                    fontWeight="500"
                    w="90%"
                    h="50"
                    mb="24px"
                    mr="20px"
                    type="submit"
                    isLoading={isLoading}
                    _hover={{ bg: isLoading ? "blue" : buttonHover }}
                  >
                    Submit
                  </Button>
                  <Button
                    fontSize="sm"
                    variant="brand"
                    fontWeight="500"
                    w="90%"
                    h="50"
                    mb="24px"
                    onClick={handleCancel}
                  >
                    Cancel
                  </Button>
                </Flex>
              </form>
            </Flex>
          </Flex>
        </Card>
      </Box>
    </>
  );
}

export default EditTicketStatus;
