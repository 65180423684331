import React, { useState, useRef, useEffect } from "react";
import Chart from "react-apexcharts";
import Card from "components/card/Card.js";
import { getDashboardPermissions } from "Permissions";
import {
  Box,
  Text,
  Icon,
  Flex,
  Tooltip,
  Spinner,
  Skeleton,
  useDisclosure,
} from "@chakra-ui/react";
import NodeKpiData from "./nodeKpiData";
import DownTimeListTable from "components/table/downTimeListTable";
import useNodeBarDetails from "hooks/nodeDashboard/useNodeBarDetails";
import useNodeDonutDetails from "hooks/nodeDashboard/useNodeDonutDetails";
import useNodeLogDetails from "hooks/nodeDashboard/useNodeLogDetails";
import useBreakUpReasonList from "hooks/useBreakUpReasonList";
import useNodeMttrMtbfDetails from "hooks/nodeDashboard/useNodeMttrMtbfDetails";
import EmptyCard from "components/card/EmptyCard";
import { BsCheckCircleFill, BsChevronDoubleDown } from "react-icons/bs";
import EmptyPieChart from "components/charts/emptyPieChart";
import { DownloadIcon } from "@chakra-ui/icons";
import dayjs from "dayjs";
import { InfoIcon } from "@chakra-ui/icons";
import DowntimeApproveModal from "components/modal/downtimeApproveModal";
import HistoryDownloadModal from "components/modal/historyDownloadModal";

const NodeStatistics = ({
  status,
  entityId,
  nodeId,
  nodeName,
  nodeTypeId,
  nodeTypeName,
}) => {
  const {
    isLoading: barLoading,
    error: barError,
    data: barData,
    refetch: barRefetch,
    isFetching: isBarFetching,
  } = useNodeBarDetails(entityId, nodeId);

  const {
    isLoading,
    error,
    data: donutData,
    refetch: donutRefetch,
    isFetching: isDonutFetching,
  } = useNodeDonutDetails(entityId, nodeId);
  const [currentPage, setCurrentPage] = useState(1);
  const {
    isLoading: isMttrMtbfLoading,
    error: mttrmtbfError,
    data: mttrMtbfData,
    refetch: mttrMtbfRefetch,
    isFetching: isMttrMtbfFetching,
  } = useNodeMttrMtbfDetails(entityId, nodeId);
  const {
    isLoading: isLogLoading,
    error: logError,
    data: logData,
    refetch: logRefetch,
    isFetching: isLogFetching,
  } = useNodeLogDetails(entityId, nodeId, 10, currentPage);
  const { data: breakupReasonData } = useBreakUpReasonList();
  const finalRef = useRef(null);
  const colorUptime = status === "online" ? "#27ae60" : "#8B8B8B";
  const colorBlackout = "#8B8B8B";
  const colorDowntime = status === "online" ? "#eb5757" : "#8B8B8B";
  const colorScheduleDowntime = status === "online" ? "#135cc9" : "#8B8B8B";
  const [modalData, setModalData] = useState(null);
  useEffect(() => {
    donutRefetch();
    barRefetch();
    mttrMtbfRefetch();
    logRefetch();
  }, [entityId, nodeId]);

  useEffect(() => {
    logRefetch();
  }, [currentPage]);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isExportOpen,
    onOpen: onExportOpen,
    onClose: onExportClose,
  } = useDisclosure();
  // if (isLoading || isDonutFetching) {
  //   return (
  //     <Card width="35%" height="360px">
  //       <Skeleton height="340px" width="300px" />
  //     </Card>
  //   );
  // }

  const pieChartOptions = {
    labels: ["Uptime", "Downtime", "Schedule Downtime", "Blackout"],
    colors: [colorUptime, colorDowntime, colorScheduleDowntime, colorBlackout],
    chart: {
      width: "250px",
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    hover: { mode: null },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              label: "Total Downtime",
              color: "navy blue",
              total: 32,
              formatter: function () {
                return (
                  donutData?.data?.total_blackout +
                  donutData?.data?.total_downtime +
                  donutData?.data?.total_schedule
                );
              },
            },
          },
        },
      },
    },
    series: [
      donutData?.data?.total_uptime,
      donutData?.data?.total_downtime,
      donutData?.data?.total_schedule,
      donutData?.data?.total_blackout,
    ],
    fill: {
      colors: [
        colorUptime,
        colorDowntime,
        colorScheduleDowntime,
        colorBlackout,
      ],
    },
    tooltip: {
      enabled: true,
      theme: "dark",
    },
  };

  const barChartDataConsumption = [
    {
      name: "Blackout",
      data: barData?.data
        ? Object.values(barData.data)
            .flat()
            ?.map((item) => item.blackout)
        : [],
    },

    {
      name: "Downtime",
      data: barData?.data
        ? Object.values(barData.data)
            .flat()
            ?.map((item) => item.downtime)
        : [],
    },
    {
      name: "Uptime",
      data: barData?.data
        ? Object.values(barData.data)
            .flat()
            ?.map((item) => item.uptime)
        : [],
    },
  ];

  const barChartOptionsConsumption = {
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: undefined,
      },
      onDatasetHover: {
        style: {
          fontSize: "12px",
          fontFamily: undefined,
        },
      },
      theme: "dark",
    },
    xaxis: {
      categories: barData?.data
        ? Object.values(barData.data)
            .flat()
            ?.map((item) => item.hour)
        : [],
      show: true,
      labels: {
        show: true,
        style: {
          colors: "#A3AED0",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      title: {
        text: "Time",
        style: {
          color: "#9EA2A9",
          fontSize: "13px",
        },
      },
    },
    yaxis: {
      show: true,
      categories: ["0", "10", "20", "30", "40", "50", "60"],
      color: "black",
      labels: {
        show: true,
        style: {
          colors: "#A3AED0",
          fontSize: "14px",
          fontWeight: "500",
        },
      },
      title: {
        text: "Minutes",
        style: {
          color: "#9EA2A9",
          fontSize: "13px",
        },
      },
    },

    grid: {
      borderColor: "rgba(163, 174, 208, 0.3)",
      show: true,
      yaxis: {
        lines: {
          show: false,
          opacity: 0.5,
        },
      },
      row: {
        opacity: 0.5,
      },
      xaxis: {
        lines: {
          show: false,
        },
      },
    },
    fill: {
      type: "solid",
      colors: [
        colorBlackout,
        colorDowntime,
        colorUptime,
        colorScheduleDowntime,
      ],
    },
    legend: {
      show: true,
    },
    colors: [colorBlackout, colorDowntime, colorUptime, colorScheduleDowntime],
    dataLabels: {
      enabled: false,
    },
    plotOptions: {
      bar: {
        borderRadius: 10,
        columnWidth: "30px",
      },
    },
  };

  const handleApproveDowntime = (row) => {
    setModalData(row);
    onOpen();
  };

  const getStatusLabel = (status) => {
    switch (status) {
      case 0:
        return "Pending";
      case 1:
        return "Approved";
      case 2:
        return "Disapproved";
      default:
        return "pending";
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 0:
        return "orange";
      case 1:
        return "green";
      case 2:
        return "red";
      default:
        return "orange";
    }
  };

  const columns = [
    {
      Header: "Sr no",
      accessor: (row) => {
        return (
          <Text color={"#2B3674"} fontSize="sm" fontWeight="700">
            {row.index}
          </Text>
        );
      },
    },
    {
      Header: "Type",
      accessor: (row) => {
        return (
          <Text
            textTransform="capitalize"
            color={"#2B3674"}
            fontSize="sm"
            fontWeight="700"
          >
            {row.type}
          </Text>
        );
      },
    },
    {
      Header: "Start Time",
      accessor: (row) => {
        return (
          <Text color={"#2B3674"} fontSize="sm" fontWeight="700">
            {row.start_at === "0"
              ? "-"
              : `${dayjs(row.start_at).format("DD/MM/YYYY")}, ${dayjs(
                  row.start_at
                ).format("hh:mm a")}`}
          </Text>
        );
      },
    },
    {
      Header: "End Time",
      accessor: (row) => {
        return (
          <Text color={"#2B3674"} fontSize="sm" fontWeight="700">
            {row.end_at === "0"
              ? "-"
              : `${dayjs(row.end_at).format("DD/MM/YYYY")}, ${dayjs(
                  row.end_at
                ).format("hh:mm a")}`}
          </Text>
        );
      },
    },
    {
      Header: "Approved Downtime",
      accessor: (row) => {
        return (
          <>
            {row.breakup_status !== null ? (
              <Tooltip
                hasArrow
                label={getStatusLabel(row.breakup_status)}
                bg={getStatusColor(row.breakup_status)}
              >
                <Text
                  color={getStatusColor(row.breakup_status)}
                  fontSize="sm"
                  fontWeight="700"
                >
                  {row.breakup_duration}
                </Text>
              </Tooltip>
            ) : (
              <Text fontWeight="bold" cursor="default">
                -
              </Text>
            )}
          </>
        );
      },
    },
    {
      Header: "Schedule Status",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row.schedule_status}
          </Text>
        );
      },
    },
    {
      Header: "Blackout Status",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row.blackout_status}
          </Text>
        );
      },
    },
    {
      Header: "Duration",
      accessor: (row) => {
        return (
          <Text color={"#2B3674"} fontSize="sm" fontWeight="700">
            {row.duration}
          </Text>
        );
      },
    },
    {
      Header: "Action",
      accessor: (row) => {
        return (
          <>
            {row.type === "downtime" &&
            row.is_nopd_create &&
            getDashboardPermissions("node.downtime.create_downtime_breakup") ? (
              <Tooltip hasArrow label="Define Approved Downtime" bg="#2B3674">
                <Flex onClick={() => handleApproveDowntime(row)}>
                  <Icon
                    as={BsChevronDoubleDown}
                    width="20px"
                    height="15px"
                    color="ndmsColors.purple.200"
                  />
                </Flex>
              </Tooltip>
            ) : (
              <Text fontWeight="bold" cursor="default">
                -
              </Text>
            )}
          </>
        );
      },
    },
  ];

  const tableStyle = {
    display: "block",
    overflowX: "auto",
    whiteSpace: "nowrap",
  };

  const pieChartData = [
    donutData?.data?.total_uptime,
    donutData?.data?.total_downtime,
    donutData?.data?.total_schedule ? donutData?.data?.total_schedule : 0,
    donutData?.data?.total_blackout,
  ];

  const getTotal = () => {
    return (
      donutData?.data?.total_uptime +
      donutData?.data?.total_downtime +
      donutData?.data?.total_schedule
    );
  };
  return (
    <>
      <Box>
        <Text
          pl="5px"
          color="#2B3674"
          fontWeight="bold"
          pb="10px"
          fontSize="18px"
        >
          Node Statistics
        </Text>
        <Flex>
          <Card width="35%" height="360px">
            <Text fontWeight="bold" fontSize="16px" color="#2B3674">
              Node trends for last 24 hours
            </Text>
            {getTotal() === 0 ? (
              <EmptyPieChart />
            ) : (
              <Chart
                height="80%"
                w="100%"
                type="donut"
                series={pieChartData}
                options={pieChartOptions}
              />
            )}
            <Flex align="center" justify="space-between" px="20px">
              <Flex align="center">
                <Box
                  h="10px"
                  w="15px"
                  bg={colorScheduleDowntime}
                  borderRadius="15px"
                  me="4px"
                />
                <Text fontSize="xs" mb="5px">
                  Schedule Down Devices
                </Text>
              </Flex>

              <Flex align="center">
                <Box
                  h="10px"
                  w="15px"
                  bg={colorDowntime}
                  borderRadius="15px"
                  me="4px"
                />
                <Text fontSize="xs" mb="5px">
                  Downtime
                </Text>
              </Flex>
            </Flex>
            <Flex align="center" justify="space-between" px="20px">
              <Flex align="center">
                <Box
                  h="10px"
                  w="15px"
                  bg={colorUptime}
                  borderRadius="15px"
                  me="4px"
                />
                <Text fontSize="xs" mb="5px">
                  Uptime
                </Text>
              </Flex>
              <Flex align="center">
                <Box
                  h="10px"
                  w="15px"
                  bg={colorBlackout}
                  borderRadius="15px"
                  me="4px"
                />
                <Text fontSize="xs" mb="5px">
                  Blackout Time
                </Text>
              </Flex>
            </Flex>
          </Card>
          <Card width="100%" ml="10px" height="360px">
            <Text fontWeight="bold" fontSize="16px" color="#2B3674">
              Node trends for last 24 hours
            </Text>
            <Chart
              options={barChartOptionsConsumption}
              series={barChartDataConsumption}
              type="bar"
              height="90%"
              width="100%"
            />
          </Card>
        </Flex>
      </Box>

      <Box mt="20px">
        <Flex alignItems="center" pb="10px">
          <Text
            pl="5px"
            pr="10px"
            color="#2B3674"
            fontWeight="bold"
            fontSize="18px"
          >
            Node KPI's
          </Text>
          <Tooltip
            hasArrow
            label="MTTR and MTBF are based on last 24 hours"
            fontSize="md"
            bg="#2B3674"
          >
            <InfoIcon color="#2B3674" />
          </Tooltip>
        </Flex>
        {isMttrMtbfLoading || isMttrMtbfFetching ? (
          <Card width="100%" height="100px">
            <Skeleton height="90px" width="100%" />
          </Card>
        ) : (
          <NodeKpiData data={mttrMtbfData?.data} />
        )}
      </Box>

      <Box mt="20px">
        <Flex alignItems="center" ref={finalRef} justifyContent="space-between">
          <Text
            pl="5px"
            color="#2B3674"
            fontWeight="bold"
            pb="10px"
            fontSize="18px"
          >
            Downtimes
          </Text>
          <Flex
            alignItems="center"
            color="ndmsColors.purple.200"
            fill="ndmsColors.purple.200"
            cursor="pointer"
            mb="7px"
            mr="10px"
            // disabled={!showReport}
            onClick={onExportOpen}
          >
            <Icon
              as={DownloadIcon}
              w="17px"
              h="20px"
              //ml="30px"
              fill="#4318FF !important
          "
            />
            <Box fontWeight="bold" pl="5px" color="#4318FF" fontSize="15px">
              {" "}
              Export{" "}
            </Box>
          </Flex>
        </Flex>
        {isLogLoading || isLogFetching ? (
          <Card w="100%" h="300px" px="0px">
            <Flex direction="column" mt="80px" align="center" justify="center">
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
              />
            </Flex>
          </Card>
        ) : logData?.data[0]?.result?.length > 0 ? (
          <Card width="100%">
            <Flex direction="column">
              <DownTimeListTable
                tableData={logData?.data[0]?.result}
                currentPage={currentPage}
                columnsData={columns}
                setCurrentPage={setCurrentPage}
                totalPage={logData?.data[0]?.total_pages}
                tableStyle={tableStyle}
              />
            </Flex>
          </Card>
        ) : (
          <Card height="300px">
            <Box mt="50px">
              <EmptyCard />
            </Box>
          </Card>
        )}
      </Box>
      <DowntimeApproveModal
        onOpen={onOpen}
        onClose={onClose}
        breakupReasonData={breakupReasonData?.data}
        isOpen={isOpen}
        entityId={entityId}
        modalData={modalData}
      />
      <HistoryDownloadModal
        isOpen={isExportOpen}
        onOpen={onExportOpen}
        onClose={onExportClose}
        nodeName={nodeName}
        nodeTypeId={nodeTypeId}
        nodeTypeName={nodeTypeName}
        finalRef={finalRef}
      />
    </>
  );
};

export default NodeStatistics;
