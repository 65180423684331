import { Circle, Flex, Text } from "@chakra-ui/layout";
import EmptyPieChart from "components/charts/emptyPieChart";
import React from "react";
import Chart from "react-apexcharts";

function SlaChart(props) {
  const { slaPerformanceMetrics, reportFilterType } = props;
  const colorUptime = "#3DCA78";
  const colorBlackout = "#135CC9";
  const colorDowntime = "#DC5D5D";
  const colorSchedule = "#F0AF4C";
  const pieChartOptions = {
    labels: [
      "Uptime",
      "Schedule Downtime",
      "Effective Downtime",
      "NOPD Downtime",
    ],
    colors: [colorUptime, colorBlackout, colorDowntime, colorSchedule],
    chart: {
      width: "150px",
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    hover: { mode: null },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "22px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              color: undefined,
              offsetY: -10,
              formatter: function (val) {
                return val;
              },
            },
            value: {
              show: true,
              fontSize: "16px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 400,
              color: undefined,
              offsetY: 16,
              formatter: function (val) {
                return val;
              },
            },
            total: {
              show: true,
              label: "Total Time (Hours)",
              color: "#2B3674",
              fontSize: "14px",
              formatter: function () {
                return slaPerformanceMetrics?.total_time;
              },
            },
          },
        },
      },
    },

    series: [
      slaPerformanceMetrics?.uptime_calc !== undefined &&
        slaPerformanceMetrics?.uptime_calc,
      slaPerformanceMetrics?.schedule_downtime_calc !== undefined &&
        slaPerformanceMetrics?.schedule_downtime_calc,
      slaPerformanceMetrics?.downtime_calc !== undefined &&
        slaPerformanceMetrics?.downtime_calc,
      // slaPerformanceMetrics?.nopd_downtime,
    ],
    fill: {
      colors: [colorUptime, colorBlackout, colorDowntime, colorSchedule],
    },
    tooltip: {
      enabled: true,
      theme: "dark",
    },
  };

  const pieChartData = [
    slaPerformanceMetrics?.uptime_calc !== undefined &&
      slaPerformanceMetrics?.uptime_calc,
    slaPerformanceMetrics?.schedule_downtime_calc !== undefined &&
      slaPerformanceMetrics?.schedule_downtime_calc,
    slaPerformanceMetrics?.downtime_calc !== undefined &&
      slaPerformanceMetrics?.downtime_calc,
    // slaPerformanceMetrics?.nopd_downtime,
  ];

  return (
    <Flex alignItems="center" pb="38px" borderBottom="1px solid #DCE3EB">
      <Flex flexDirection="column" borderRight="1px solid #DCE3EB " pr="90px">
        <Flex alignItems="center" mt="10px">
          <Text fontSize="16px" color="ndmsColors.gray.2100" mr="10px">
            IP Address:
          </Text>
          <Text fontSize="16px" color="ndmsColors.blue.100">
            {slaPerformanceMetrics?.ip_address}
          </Text>
        </Flex>
        {/* {reportFilterType === "NodeType" ? (
          ""
        ) : (
          <Flex alignItems="center" mt="10px">
            <Text fontSize="16px" color="ndmsColors.gray.2100" mr="10px">
              Total Available Hours:
            </Text>
            <Text fontSize="16px" color="ndmsColors.blue.100">
              {slaPerformanceMetrics?.total_time}
            </Text>
          </Flex>
        )} */}
        <Flex alignItems="center" mt="10px">
          <Text fontSize="16px" color="ndmsColors.gray.2100" mr="10px">
            Total Downtime {reportFilterType === "NodeType" ? "" : "(%)"}:
          </Text>
          <Text fontSize="16px" color="ndmsColors.blue.100">
            {reportFilterType === "NodeType"
              ? `${slaPerformanceMetrics?.downtime_percentage}%`
              : `${slaPerformanceMetrics?.downtime_percentage}%`}
          </Text>
        </Flex>
        <Flex alignItems="center" mt="10px">
          <Text fontSize="16px" color="ndmsColors.gray.2100" mr="10px">
            Scheduled Downtime{" "}
            {reportFilterType === "NodeType" ? "" : "(%)"}:
          </Text>
          <Text fontSize="16px" color="ndmsColors.blue.100">
            {reportFilterType === "NodeType"
              ? `${slaPerformanceMetrics?.schedule_downtime_percentage}%`
              : `${slaPerformanceMetrics?.schedule_downtime_percentage}%`}
          </Text>
        </Flex>
        
        <Flex alignItems="center" mt="10px">
          <Text fontSize="16px" color="ndmsColors.gray.2100" mr="10px">
            Total NOPD Duration{" "}
            {reportFilterType === "NodeType" ? "" : "(%)"}:
          </Text>
          <Text fontSize="16px" color="ndmsColors.blue.100">
            {reportFilterType === "NodeType"
              ? `${slaPerformanceMetrics?.nopd_percentage}%`
              : `${slaPerformanceMetrics?.nopd_percentage}%`}
          </Text>
        </Flex>
        
        <Flex alignItems="center" mt="10px">
          <Text fontSize="16px" color="ndmsColors.gray.2100" mr="10px">
            Total UP time (%age):
          </Text>
          <Text fontSize="16px" color="ndmsColors.blue.100">
            {slaPerformanceMetrics?.uptime_percentage} %
          </Text>
        </Flex>

        <Flex alignItems="center" mt="10px">
          <Text fontSize="16px" color="ndmsColors.gray.2100" mr="10px">
            Effective UP time (%age):
          </Text>
          <Text fontSize="16px" color="ndmsColors.green.100">
            {slaPerformanceMetrics?.effective_uptime_percentage} %
          </Text>
        </Flex>

        <Flex alignItems="center" mt="10px">
          <Text fontSize="16px" color="ndmsColors.gray.2100" mr="10px">
            Effective Downtime{" "}
            {reportFilterType === "NodeType" ? "" : "(%)"}:
          </Text>
          <Text fontSize="16px" color="ndmsColors.blue.100">
            {reportFilterType === "NodeType"
              ? `${slaPerformanceMetrics?.effective_downtime_percentage}%`
              : `${slaPerformanceMetrics?.effective_downtime_percentage}%`}
          </Text>
        </Flex>
        
      </Flex>
      <Flex ml="100px" alignItems="center">
        <Flex width="200px">
          {slaPerformanceMetrics?.total_time === 0 ? (
            <EmptyPieChart />
          ) : (
            <Chart
              height="220px"
              w="100%"
              type="donut"
              series={pieChartData}
              options={pieChartOptions}
            />
          )}
        </Flex>
        <Flex direction="column" ml="20px">
          <Flex color="ndmsColors.blue.100" fontWeight="bold" mb="20px">
            Time Duration in hours
          </Flex>
          <Flex>
            <Circle mt="5px" size="12px" bg={colorUptime} />
            <Flex direction="column">
              <Text
                color="ndmsColors.gray.1900"
                fontWeight="bold"
                fontSize="xs"
                pl="5px"
                mb="5px"
              >
                Uptime
              </Text>
              <Text
                color="ndmsColors.blue.100"
                fontWeight="bold"
                fontSize="sm"
                pl="5px"
                mb="5px"
              >
                {slaPerformanceMetrics?.uptime}
              </Text>
            </Flex>
          </Flex>
          <Flex>
            <Circle mt="5px" size="12px" bg={colorBlackout} />
            <Flex direction="column">
              <Text
                color="ndmsColors.gray.1900"
                fontWeight="bold"
                fontSize="xs"
                pl="5px"
                mb="5px"
              >
                Schedule Downtime
              </Text>
              <Text
                color="ndmsColors.blue.100"
                fontWeight="bold"
                fontSize="sm"
                pl="5px"
                mb="5px"
              >
                {slaPerformanceMetrics?.schedule_downtime}
              </Text>
            </Flex>
          </Flex>
          <Flex>
            <Circle mt="5px" size="12px" bg={colorDowntime} />
            <Flex direction="column">
              <Text
                color="ndmsColors.gray.1900"
                fontWeight="bold"
                fontSize="xs"
                pl="5px"
                mb="5px"
              >
                Downtime
              </Text>
              <Text
                color="ndmsColors.blue.100"
                fontWeight="bold"
                fontSize="sm"
                pl="5px"
                mb="5px"
              >
                {slaPerformanceMetrics?.downtime}
              </Text>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default SlaChart;
