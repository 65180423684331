/* eslint-disable */
import {
  Flex,
  Progress,
  Table,
  Box,
  Tbody,
  Icon,
  Center,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import React, { useState, useEffect, useMemo } from "react";
import { TableUpIcon } from "components/icons/Icons";
import {
  AiOutlineSortDescending,
  AiOutlineSortAscending,
} from "react-icons/ai";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { BsArrowDownUp } from "react-icons/bs";

export default function DownTimeListTable({
  tableData,
  columnsData,
  currentPage,
  totalPage,
  setCurrentPage,
  hidePagination,
  tableStyle,
}) {
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const [minLimit, setMinLimit] = useState(0);
  const [maxLimit, setMaxLimit] = useState(10);

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    tableInstance;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("secondaryGray.500", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  useEffect(() => {
    const minL = ((currentPage - 1) / 10).toString().split(".")[0] * 10;

    setMinLimit(minL);
    setMaxLimit(minL + 10);
  }, [tableData]);

  const handlePageIncrease = () => {
    setCurrentPage(currentPage + 1);
  };

  const handlePageDecrease = () => {
    setCurrentPage(currentPage - 1);
  };

  const handleLimitDecrease = () => {
    setMinLimit(minLimit - 10);
    setMaxLimit(maxLimit - 10);
  };

  const handleLimitIncrease = () => {
    setMinLimit(minLimit + 10);
    setMaxLimit(maxLimit + 10);
  };

  return (
    <>
      <Table
        {...getTableProps()}
        variant="simple"
        color="gray.500"
        //style={tableStyle}
      >
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  color="#2B3674"
                  //pe="10px"
                  //pr="10px"
                  key={index}
                  //style={{position: index === 2 && 'absolute', right: index === 2 && 0}}
                  borderColor={borderColor}
                >
                  <Flex
                    //justify="space-evenly"
                    //pr="30px"
                    align="start"
                    alignItems="center"
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color="#2B3674"
                    textTransform="capitalize"
                  >
                    <Flex alignItems="center">
                      <Text pr="5px">{column.render("Header")}</Text>
                      {true ? null : (
                        <Icon
                          as={
                            column.isSortedDesc
                              ? AiOutlineSortDescending
                              : AiOutlineSortAscending
                          }
                          width="18px"
                          height="18px"
                          color="inherit"
                        />
                      )}
                    </Flex>
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        <Tbody {...getTableBodyProps()}>
          {rows.map((row, index) => {
            prepareRow(row);
            return (
              <Tr
                {...row.getRowProps()}
                key={index}
                bg={index % 2 === 0 ? "#F4F7FE" : "white"}
              >
                {row.cells.map((cell, cellIndex) => {
                  return (
                    <Td
                      {...cell.getCellProps()}
                      key={cellIndex}
                      pt="10px"
                      pb="10px"
                      fontSize={{ sm: "14px" }}
                      cursor={
                        columns[cellIndex].Header.toLowerCase() === "action"
                          ? "pointer"
                          : "default"
                      }
                      //minW={{ sm: "150px", md: "100px", lg: "auto" }}
                      borderColor="transparent"
                    >
                      <Flex align="center">
                        {columns[cellIndex].Header === "Sr no" ? (
                          <Text
                            color={"#2B3674"}
                            fontSize="sm"
                            fontWeight="700"
                          >
                            {currentPage === 1
                              ? index + 1
                              : index - 9 + currentPage * 10}
                          </Text>
                        ) : (
                          cell.render("Cell")
                        )}
                      </Flex>
                    </Td>
                  );
                })}
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      {hidePagination ? null : (
        <Flex pr="40px" mt="24px" alignItems="center" justifyContent="flex-end">
          {minLimit === 0 ? null : (
            <Text
              color="ndmsColors.blue.100"
              cursor="pointer"
              fontSize="20px"
              onClick={handleLimitDecrease}
            >
              {"<"}
            </Text>
          )}
          {[...Array(totalPage)]
            ?.slice(minLimit, maxLimit)
            ?.map((page, index) => (
              <Box
                key={index}
                cursor="pointer"
                border="1px solid #2B3674"
                mx="5px"
                px="5px"
                bg={
                  currentPage === index + minLimit + 1
                    ? "ndmsColors.blue.100"
                    : "white"
                }
                onClick={() => setCurrentPage(index + minLimit + 1)}
              >
                <Text
                  color={
                    currentPage === index + minLimit + 1
                      ? "white"
                      : "ndmsColors.blue.100"
                  }
                  fontSize="15px"
                >
                  {index + minLimit + 1}
                </Text>
              </Box>
            ))}
          {maxLimit >= totalPage ? null : (
            <Text
              color="ndmsColors.blue.100"
              cursor="pointer"
              fontSize="20px"
              onClick={handleLimitIncrease}
            >
              {">"}
            </Text>
          )}
          <Text
            color="ndmsColors.blue.100"
            pr="20px"
            ml="20px"
            cursor="pointer"
            fontSize="15px"
          >
            Pages : {currentPage} of {totalPage}
          </Text>
          {totalPage > 1 ? (
            <>
              {currentPage > 1 ? (
                <Text
                  color="ndmsColors.blue.100"
                  cursor="pointer"
                  fontSize="20px"
                  onClick={handlePageDecrease}
                >
                  {"<"}
                </Text>
              ) : null}
              <Text color="ndmsColors.blue.100" fontSize="20px" px="10px">
                {currentPage}
              </Text>
              {currentPage < totalPage ? (
                <Text
                  color="ndmsColors.blue.100"
                  cursor="pointer"
                  fontSize="20px"
                  onClick={handlePageIncrease}
                >
                  {">"}
                </Text>
              ) : null}
            </>
          ) : null}
        </Flex>
      )}
    </>
  );
}
