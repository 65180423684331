import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  Box,
  Flex,
  Text,
  ModalCloseButton,
  Button,
} from "@chakra-ui/react";

const DeactivateModal = ({ isOpen, onClose, onOpen, id, deleteItem }) => {
  const handleDeleteItem = () => {
    deleteItem(id);
  };
  const handleModalClose = () => {
    onClose();
  };
  return (
    <Box borderRadius="3xl">
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        blockScrollOnMount={false}
        size="lg"
        isCentered
      >
        <ModalOverlay />
        <ModalContent borderRadius="20px">
          <ModalHeader>
            <Flex justify="center" direction="column" align="center" mt="30px">
              <Text fontSize="30px">Deactivate Client</Text>
              <Text color="#A3AED0">
                Are you sure you want to deactivate this client?
              </Text>
              <Text fontWeight="normal" fontSize="16px">
                Data will stop coming, if you deactivate this client
              </Text>
              <Flex mt="10px">
                <Button
                  bg="#4318FF"
                  _hover={{ bg: "#2B3674" }}
                  mx={3}
                  mt={2}
                  mb={10}
                  size="lg"
                  onClick={handleDeleteItem}
                >
                  <Text color={"#FFFFFF"}>Yes</Text>
                </Button>
                <Button
                  variant="outline"
                  colorScheme="#2B3674"
                  _hover={{ bg: "#F0F8FF" }}
                  mx={3}
                  mt={2}
                  size="lg"
                  mb={10}
                  onClick={handleModalClose}
                >
                  <Text color={"#2B3674"}>No</Text>
                </Button>
              </Flex>
            </Flex>
          </ModalHeader>

          <ModalCloseButton />
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default DeactivateModal;
