import React, { useState, useEffect } from "react";
import Card from "components/card/Card.js";
import DownTimeListTable from "components/table/downTimeListTable";
import useAllEntityList from "hooks/entityList/useAllEntityList";
import LoadingForm from "components/loader/LoadingForm";
import noGroupImage from "assets/img/layout/noGroup.png";
import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Tooltip,
  Link,
  Text,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import { AiOutlineBars } from "react-icons/ai";
import { MdLocationOn } from "react-icons/md";
import { AddIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import AddEntityForm from "./addEntityForm";
import EditEntityForm from "./editEntityForm";
import useDeleteEntity from "hooks/entityList/useDeleteEntity";
import useNodeTypes from "hooks/useNodeTypes";
import { useHistory } from "react-router-dom";
import toast from "react-hot-toast";
import { MdDashboard, MdEdit } from "react-icons/md";
import { BsListOl } from "react-icons/bs";
import DeleteModal from "components/modal/deleteModal";
import LicenseModal from "components/modal/licenseModal";
import DowntimeDrawer from "components/drawer/DowntimeDrawer";
import { getDashboardPermissions } from "Permissions";
import { useParams } from "react-router-dom";
import MapView from "./mapView";
import dayjs from "dayjs";

function Entity() {
  const mapVisibility = localStorage.getItem("map_visibility");
  // Chakra color mode
  const entityStatus = useParams()?.status;
  const [entityId, setEntityId] = useState(null);
  const [downtimeEntityId, setDowntimeEntityId] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const { isLoading, error, data, refetch, isFetching } = useAllEntityList();
  const [deleteId, setDeleteId] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const history = useHistory();
  const { mutate: deleteEntity } = useDeleteEntity(onClose);
  const [entityName, setEntityName] = useState("");
  const [editData, setEditData] = useState(null);
  const [nodeTypeData, setNodeTypeData] = useState(null);
  const [toggleCardMode, setToggleCardMode] = useState(
    mapVisibility.toLowerCase() === "show" ? false : true
  );

  const handleToggleMode = () => {
    if (toggleCardMode) {
      setToggleCardMode(false);
    } else {
      setToggleCardMode(true);
    }
  };

  const {
    isOpen: isScheduleOpen,
    onOpen: onScheduleOpen,
    onClose: onScheduleClose,
  } = useDisclosure();
  const {
    isOpen: isLicenseOpen,
    onOpen: onLicenseOpen,
    onClose: onLicenseClose,
  } = useDisclosure();
  const {
    isLoading: isNodeLoading,
    error: nodeError,
    data: deviceData,
  } = useNodeTypes();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showAddForm, showEditForm]);

  useEffect(() => {
    refetch();
  }, [currentPage]);

  if (isLoading || isFetching) return <LoadingForm />;
  if (error) return toast.error("internal server error");

  const handleDelete = (id) => {
    setDeleteId(id);
    onOpen();
    //deleteEntity(id);
  };
  const handleEntityDashboard = (row) => {
    history.push(`/admin/entity-dashboard/${row.id}/${row.name}`);
  };
  const handleGroupDashboard = (id) => {
    history.push(`/admin/group-dashboard/${id}`);
  };
  const handleEntityClientList = (row) => {
    history.push(`/admin/entity-client-list/${row.id}/${row.name}`);
  };
  const handleEntityEdit = (row) => {
    setEditData(row);
    setShowEditForm(true);
  };

  const columnsData = [
    {
      Header: "NAME",
      accessor: (row) => {
        return (
          <Link href={`/admin/entity-dashboard/${row.id}/${row.name}`}>
            <Text
              color={"ndmsColors.purple.200"}
              //color={"ndmsColors.blue.100"}
              fontSize="12px"
              fontWeight="700"
            >
              {row.name}
            </Text>
          </Link>
        );
      },
    },
    {
      Header: "LOCATION",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {`${row.Address.city}, ${row.Address.state}`}
          </Text>
        );
      },
    },
    {
      Header: "Total Nodes",
      accessor: (row) => {
        return (
          <Text color={"#2B3674"} fontSize="12px" fontWeight="700">
            {row?.total_nodes}
          </Text>
        );
      },
    },
    {
      Header: "Active Nodes",
      accessor: (row) => {
        return (
          <Text color={"#3DCA78"} fontSize="12px" fontWeight="700">
            {row.healthy_nodes}
          </Text>
        );
      },
    },

    {
      Header: "Type",
      accessor: (row) => {
        return (
          <Text
            color={row.status === "syncing" ? "#3DCA78" : "#E23A63"}
            fontSize="12px"
            fontWeight="700"
          >
            {row.status}
          </Text>
        );
      },
    },
    {
      Header: "Lat",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row.Address.lat}
          </Text>
        );
      },
    },
    {
      Header: "Long",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row.Address.long}
          </Text>
        );
      },
    },
    {
      Header: "Last Synced",
      accessor: (row) => {
        return row?.last_sync_at ? (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {`${dayjs(row?.last_sync_at).format("DD MMM YYYY")},
                      ${dayjs(row?.last_sync_at).format("hh:mm a")}`}
          </Text>
        ) : (
          "-"
        );
      },
    },
  ];

  return (
    <>
      {showAddForm && <AddEntityForm setShowAddForm={setShowAddForm} />}
      {showEditForm && (
        <EditEntityForm
          setShowEditForm={setShowEditForm}
          editData={editData}
          id={entityId}
        />
      )}
      {!showAddForm && !showEditForm && (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
          <Flex alignItems="center" justifyContent="flex-end" mb="42px">
            <Box
              bg={toggleCardMode ? "#4318FF" : "#EAEDF2"}
              width="38px"
              height="38px"
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="6px"
              cursor="pointer"
              onClick={handleToggleMode}
              mr="10px"
            >
              <Icon
                as={AiOutlineBars}
                width="20px"
                height="20px"
                color={toggleCardMode ? "white" : "#9EA2A9"}
              />
            </Box>
            {!mapVisibility.toLowerCase() === "show" && (
              <Box
                bg={toggleCardMode ? "#EAEDF2" : "#4318FF"}
                width="38px"
                height="38px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                cursor="pointer"
                onClick={handleToggleMode}
                borderRadius="6px"
                mr="10px"
              >
                <Icon
                  as={MdLocationOn}
                  width="20px"
                  height="20px"
                  color={toggleCardMode ? "#9EA2A9" : "white"}
                />
              </Box>
            )}
          </Flex>
          {data?.data[0]?.result?.length > 0 ? (
            <>
              {toggleCardMode ? (
                <Card
                  direction="column"
                  w="100%"
                  px="0px"
                  overflowX={{ sm: "scroll", lg: "hidden" }}
                >
                  <Flex direction="column" p="20px">
                    <DownTimeListTable
                      tableData={data?.data[0]?.result}
                      columnsData={columnsData}
                      currentPage={currentPage}
                      totalPage={data?.data[0]?.total_pages}
                      setCurrentPage={setCurrentPage}
                      hidePagination={true}
                    />
                  </Flex>
                </Card>
              ) : (
                <MapView data={data} />
              )}
            </>
          ) : (
            <Flex
              align="center"
              justify="center"
              mt="200px"
              cursor="pointer"
              mb="150px"
              onClick={() => setShowAddForm(true)}
            >
              <Flex direction="column" justify="center" align="center">
                <Image src={noGroupImage} width="70px" alt="no-group" />
                <Text fontSize={"22px"} color="#2B3674" fontWeight="bold">
                  You Don’t Have Any Entity
                </Text>
              </Flex>
            </Flex>
          )}
        </Box>
      )}
      <DeleteModal
        onOpen={onOpen}
        isOpen={isOpen}
        onClose={onClose}
        id={deleteId}
        deleteItem={deleteEntity}
      />
      <LicenseModal
        onOpen={onLicenseOpen}
        isOpen={isLicenseOpen}
        onClose={onLicenseClose}
        id={entityId}
        entityName={entityName}
      />
      <DowntimeDrawer
        entityId={downtimeEntityId}
        onOpen={onScheduleOpen}
        onClose={onScheduleClose}
        isOpen={isScheduleOpen}
        nodeTypeData={nodeTypeData}
        data={deviceData?.data[0]?.result}
      />
    </>
  );
}

export default Entity;
