import React from "react";
import { Icon } from "@chakra-ui/react";
import { MdDashboard, MdSpaceDashboard, MdHome, MdLock } from "react-icons/md";
import EntityClientList from "views/admin/entityClientList";
import { CgTemplate, CgProfile } from "react-icons/cg";
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import { BsFillPersonLinesFill, BsChevronDoubleDown } from "react-icons/bs";
import { FaCog } from "react-icons/fa";
import { RiBuilding3Line } from "react-icons/ri";
import { MdDevices } from "react-icons/md";
import { VscUnfold } from "react-icons/vsc";
import { TiThLarge } from "react-icons/ti";
import { HiDocumentReport, HiTicket } from "react-icons/hi";
import Client from "views/admin/client";
import Entity from "views/admin/entity";
import User from "views/admin/user";
import Role from "views/admin/role";
import Permissions from "views/admin/permissions";
import Node from "views/admin/node";
import LayoutPlanning from "views/admin/layoutPlanning";
import NodeTypes from "views/admin/nodeTypes";
import Template from "views/admin/template";
import ForgotPassword from "views/auth/signIn/forgotPassword";
import GroupDashboard from "views/admin/groupDashboard";
import NodeDashboard from "views/admin/nodeDashboard";
import EntityDashboard from "views/admin/entityDashboard";
import LiveDashboard from "views/admin/liveDashboard";
import ScheduleDowntimeList from "views/admin/scheduleDowntimeList.js";
import PermissionGroup from "views/admin/permissionGroup";
import ClientDashboard from "views/admin/clientDashboard";
import { getDashboardPermissions } from "Permissions";
import ClientDashboardList from "views/admin/clientDashboardList/clientDashboardList";
import NonSyncClientDashboardList from "views/admin/clientDashboardList/nonSyncClientDashboardLIst";
import SignInCentered from "views/auth/signIn";
import SlaReport from "views/admin/slaReport";
import ApprovedDowntimes from "views/admin/approvedDowntimes";
import MasterConfiguration from "views/admin/configurtaion/masterConfiguration";
import InstallClient from "views/admin/entityClientList/installClient";
import ClientConfiguration from "views/admin/configurtaion/clientConfiguration";
import PdfReport from "views/report";
import Tms from "views/admin/tms";
import TicketTypes from "views/admin/ticketCruds/ticketTypes";
import TicketStatus from "views/admin/ticketCruds/ticketStatus";
import TicketDetails from "views/admin/tms/ticketDetails";
import TicketSummary from "views/admin/tms/ticketSummary";
import EntityDataList from "views/admin/entity/entityDataList";
import AddTicketTypes from "views/admin/ticketCruds/ticketTypes/addTicketTypes";
import EditTicketTypes from "views/admin/ticketCruds/ticketTypes/editTicketType";
import AddTicketStatus from "views/admin/ticketCruds/ticketStatus/addTicketStatus";
import TicketReason from "views/admin/ticketCruds/ticketReason";
import AddTicketReason from "views/admin/ticketCruds/ticketReason/addTicketReason";
import TicketTabs from "views/admin/ticketCruds/ticketCrudsTabs";
import AddTicketTabs from "views/admin/ticketCruds/ticketCrudsTabs/addTicketTabs";
import EditTicketStatus from "views/admin/ticketCruds/ticketStatus/editTicketStatus";
import EditTicketReason from "views/admin/ticketCruds/ticketReason/editTicketReason";
import EditTicketTabs from "views/admin/ticketCruds/ticketCrudsTabs/editTicketTabs";

const routes = [
  {
    name: "Home",
    show: true,
    layout: "/admin",
    path: "/home",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: ClientDashboard,
    showInSidebar: getDashboardPermissions("client_dashboard.view"),
    permission: "client_dashboard.view",
    crumbs: [{ name: "Home", url: "/admin/home" }],
  },
  {
    name: "Customer",
    show: false,
    layout: "/admin",
    icon: <Icon as={CgProfile} width="20px" height="20px" color="inherit" />,
    path: "/customer",
    component: Client,
    showInSidebar: false,
    permission: "customer.view",
    crumbs: [{ name: "Customer", url: "/admin/customer" }],
  },
  {
    name: "Node Type",
    show: true,
    layout: "/admin",
    icon: <Icon as={MdDevices} width="20px" height="20px" color="inherit" />,
    path: "/node-type",
    component: NodeTypes,
    showInSidebar: false,
    permission: "node_type.view",
    crumbs: [{ name: "Node Type", url: "/admin/node-type" }],
  },
  {
    name: "Node Dashboard",
    show: true,
    layout: "/admin",
    icon: <Icon as={CgProfile} width="20px" height="20px" color="inherit" />,
    path: "/node-dashboard/:id/:name/node_id=:nodeId",
    component: NodeDashboard,
    showInSidebar: false,
    crumbs: [
      { name: "Entity", url: "/admin/entity" },
      {
        name: "Node Dashboard",
        url: `/admin/node-dashboard/:id/:name/node_id=:nodeId`,
      },
    ],
  },
  {
    name: "Permission Group",
    show: true,
    layout: "/admin",
    icon: <Icon as={TiThLarge} width="20px" height="20px" color="inherit" />,
    path: "/permission-group",
    component: PermissionGroup,
    showInSidebar: getDashboardPermissions("permissions.group.view"),
    permission: "permissions.group.view",
    crumbs: [{ name: "Permission Group", url: "/admin/permission-group" }],
  },
  {
    name: "Permissions",
    show: true,
    layout: "/admin",
    icon: (
      <Icon
        as={BsFillPersonLinesFill}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    path: "/permissions",
    component: Permissions,
    showInSidebar: getDashboardPermissions("permissions.view"),
    permission: "permissions.view",
    crumbs: [{ name: "Permissions", url: "/admin/permissions" }],
  },
  {
    name: "Role",
    show: true,
    layout: "/admin",
    icon: <Icon as={VscUnfold} width="20px" height="20px" color="inherit" />,
    path: "/role",
    component: Role,
    showInSidebar: getDashboardPermissions("role.view"),
    permission: "role.view",
    crumbs: [{ name: "Role", url: "/admin/role" }],
  },
  {
    name: "User",
    show: true,
    layout: "/admin",
    icon: (
      <Icon
        as={AiOutlineUsergroupAdd}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    path: "/user",
    component: User,
    showInSidebar: getDashboardPermissions("user.view"),
    permission: "user.view",
    crumbs: [{ name: "User", url: "/admin/user" }],
  },
  {
    name: "Entity",
    show: true,
    layout: "/admin",
    icon: (
      <Icon as={RiBuilding3Line} width="20px" height="20px" color="inherit" />
    ),
    path: "/entity/:status",
    component: Entity,
    showInSidebar: getDashboardPermissions("entity.view"),
    permission: "entity.view",
    crumbs: [{ name: "Entity", url: "/admin/entity" }],
  },
  {
    name: "Entities",
    show: true,
    layout: "/admin",
    icon: (
      <Icon as={RiBuilding3Line} width="20px" height="20px" color="inherit" />
    ),
    path: "/entities/:status",
    component: EntityDataList,
    showInSidebar: getDashboardPermissions("entity.view"),
    permission: "entity.view",
    crumbs: [{ name: "Entity", url: "/admin/entity" }],
  },
  {
    name: "Layout Planning",
    show: false,
    layout: "/admin",
    icon: <Icon as={MdDashboard} width="20px" height="20px" color="inherit" />,
    path: "/layout-planning/:id/:name",
    component: LayoutPlanning,
    showInSidebar: false,
    crumbs: [
      { name: "Entity", url: "/admin/entity" },
      { name: "Layout Planning", url: "/admin/layout-planning/:id/:name" },
    ],
  },
  {
    name: "Install Client",
    show: false,
    layout: "/admin",
    icon: <Icon as={MdDashboard} width="20px" height="20px" color="inherit" />,
    path: "/install-client/:id/:name",
    component: InstallClient,
    showInSidebar: false,
  },
  {
    name: "Schedule Downtime",
    show: false,
    layout: "/admin",
    icon: <Icon as={MdDashboard} width="20px" height="20px" color="inherit" />,
    path: "/schedule-downtime/:id",
    component: ScheduleDowntimeList,
    showInSidebar: getDashboardPermissions("entity.schedule_downtime.view"),
    permission: "entity.schedule_downtime.view",
  },
  {
    name: "Node",
    show: true,
    layout: "/admin",
    icon: <Icon as={MdDashboard} width="20px" height="20px" color="inherit" />,
    path: "/test",
    component: Node,
    showInSidebar: getDashboardPermissions("entity.node.view"),
    permission: "entity.node.view",
  },
  {
    name: "Node Group Templates",
    show: true,
    layout: "/admin",
    icon: <Icon as={CgTemplate} width="20px" height="20px" color="inherit" />,
    path: "/template",
    component: Template,
    showInSidebar: false,
    permission: "template.view",
    crumbs: [{ name: "Node Group Templates", url: "/admin/template" }],
  },
  {
    name: "Entity Dashboard",
    show: true,
    layout: "/admin",
    icon: (
      <Icon as={MdSpaceDashboard} width="20px" height="20px" color="inherit" />
    ),
    path: "/entity-dashboard/:id/:name",
    component: EntityDashboard,
    showInSidebar: false,
    crumbs: [
      { name: "Entity", url: "/admin/entity" },
      { name: "Entity Dashboard", url: "/admin/entity-dashboard/:id/:name" },
    ],
  },
  {
    name: "Entity Client List",
    show: true,
    layout: "/admin",
    icon: (
      <Icon as={MdSpaceDashboard} width="20px" height="20px" color="inherit" />
    ),
    path: "/entity-client-list/:id/:name",
    component: EntityClientList,
    showInSidebar: false,
    crumbs: [
      { name: "Entity", url: "/admin/entity" },
      {
        name: "Entity Client List",
        url: "/admin/entity-client-list/:id/:name",
      },
    ],
  },
  {
    name: "Entity Client List",
    show: true,
    layout: "/admin",
    icon: (
      <Icon as={MdSpaceDashboard} width="20px" height="20px" color="inherit" />
    ),
    path: "/entity-client-list/:id/:name",
    component: EntityClientList,
    showInSidebar: false,
  },
  {
    name: "Group Dashboard",
    show: true,
    layout: "/admin",
    icon: <Icon as={MdDashboard} width="20px" height="20px" color="inherit" />,
    path: "/group-dashboard/:id",
    component: GroupDashboard,
    showInSidebar: false,
  },
  {
    name: "Live Dashboard",
    show: true,
    layout: "/admin",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    path: "/live-dashboard/:id/:name",
    component: LiveDashboard,
    showInSidebar: false,
  },
  {
    name: "Syncing Entities",
    show: true,
    layout: "/admin",
    icon: <Icon as={CgTemplate} width="20px" height="20px" color="inherit" />,
    path: "/sync-entity-list",
    component: ClientDashboardList,
    showInSidebar: false,
    crumbs: [
      { name: "Entity", url: "/admin/entity" },
      { name: "Entity Dashboard", url: "/admin/entity-dashboard/:id/:name" },
      { name: "Syncing Entities", url: "/admin/sync-entity-list" },
    ],
  },
  {
    name: "Non-Syncing Entities",
    show: true,
    layout: "/admin",
    icon: <Icon as={CgTemplate} width="20px" height="20px" color="inherit" />,
    path: "/nonSync-entity-list",
    component: NonSyncClientDashboardList,
    showInSidebar: false,
    crumbs: [
      { name: "Entity", url: "/admin/entity" },
      { name: "Entity Dashboard", url: "/admin/entity-dashboard/:id/:name" },
      { name: "Non-Syncing Entities", url: "/admin/nonSync-entity-list" },
    ],
  },

  {
    //name: "Reports",
    name: "Approved Downtimes",
    show: true,
    layout: "/admin",
    icon: (
      <Icon
        as={BsChevronDoubleDown}
        width="20px"
        height="20px"
        color="inherit"
      />
    ),
    path: "/approved-downtimes",
    component: ApprovedDowntimes,
    showInSidebar: getDashboardPermissions(
      "node.downtime.view_downtime_breakup"
    ),
    permission: "node.downtime.view_downtime_breakup",
  },
  {
    name: "Ticket Management System",
    show: true,
    layout: "/admin",
    icon: <Icon as={HiTicket} width="20px" height="20px" color="inherit" />,
    path: "/ticket-management-system",
    component: Tms,
    showInSidebar: true,
    permission: "ticketing.dashboard.show",
    crumbs: [{ name: "Role", url: "/admin/role" }],
  },
  {
    name: "Ticket Management System",
    show: true,
    layout: "/admin",
    icon: (
      <Icon as={MdSpaceDashboard} width="20px" height="20px" color="inherit" />
    ),
    path: "/ticket-types",
    component: TicketDetails,
    showInSidebar: false,
    //permission: "ticketing.ticket.view",
    crumbs: [
      { name: "Entity", url: "/admin/entity" },
      {
        name: "Entity Client List",
        url: "/admin/entity-client-list/:id/:name",
      },
    ],
  },
  {
    name: "Ticket Management System",
    show: true,
    layout: "/admin",
    icon: (
      <Icon as={MdSpaceDashboard} width="20px" height="20px" color="inherit" />
    ),
    path: "/ticket-details/:id",
    component: TicketSummary,
    showInSidebar: false,
    //permission: "ticketing.ticket.view",
    // crumbs: [
    //   { name: "Entity", url: "/admin/entity" },
    //   {
    //     name: "Entity Client List",
    //     url: "/admin/entity-client-list/:id/:name",
    //   },
    // ],
  },
  {
    name: "Settings",
    show: true,
    layout: "/admin",
    icon: <Icon as={VscUnfold} width="20px" height="20px" color="inherit" />,
    path: "/master-configuration",
    component: MasterConfiguration,
    showInSidebar: true,
    permission: "config.view",
    crumbs: [{ name: "Role", url: "/admin/role" }],
  },
  {
    name: "Settings",
    show: true,
    layout: "/admin",
    icon: <Icon as={VscUnfold} width="20px" height="20px" color="inherit" />,
    path: "/client-configuration",
    component: ClientConfiguration,
    showInSidebar: true,
    permission: "config_store.view",
    crumbs: [{ name: "Role", url: "/admin/role" }],
  },
  {
    name: "Reports",
    show: true,
    layout: "/admin",
    icon: (
      <Icon as={HiDocumentReport} width="20px" height="20px" color="inherit" />
    ),
    path: "/sla-report/:reports",
    component: SlaReport,
    showInSidebar: getDashboardPermissions("report_view.all"),
    permission: "report_view.all",
  },

  {
    name: "Ticket Types",
    show: true,
    layout: "/admin",
    path: "/ticket-crud/ticket-crud-types/add",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: AddTicketTypes,
    showInSidebar: getDashboardPermissions("client_dashboard.view"),
    //permission: "client_dashboard.view",
    crumbs: [{ name: "Home", url: "/admin/home" }],
  },
  {
    name: "Ticket Types",
    show: true,
    layout: "/admin",
    path: "/ticket-crud/ticket-crud-types/edit/:id",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: EditTicketTypes,
    showInSidebar: getDashboardPermissions("client_dashboard.view"),
    //permission: "client_dashboard.view",
    crumbs: [{ name: "Home", url: "/admin/home" }],
  },
  {
    name: "Ticket Types",
    show: true,
    layout: "/admin",
    path: "/ticket-crud/ticket-crud-types",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: TicketTypes,
    showInSidebar: getDashboardPermissions("client_dashboard.view"),
    //permission: "client_dashboard.view",
    crumbs: [{ name: "Home", url: "/admin/home" }],
  },
  {
    name: "Ticket Status",
    show: true,
    layout: "/admin",
    path: "/ticket-crud/ticket-status/add",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: AddTicketStatus,
    showInSidebar: getDashboardPermissions("client_dashboard.view"),
    //permission: "client_dashboard.view",
    crumbs: [{ name: "Home", url: "/admin/home" }],
  },
  {
    name: "Ticket Status",
    show: true,
    layout: "/admin",
    path: "/ticket-crud/ticket-status/edit/:id",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: EditTicketStatus,
    showInSidebar: getDashboardPermissions("client_dashboard.view"),
    //permission: "client_dashboard.view",
    crumbs: [{ name: "Home", url: "/admin/home" }],
  },
  {
    name: "Ticket Status",
    show: true,
    layout: "/admin",
    path: "/ticket-crud/ticket-status",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: TicketStatus,
    showInSidebar: getDashboardPermissions("client_dashboard.view"),
    //permission: "client_dashboard.view",
    crumbs: [{ name: "Home", url: "/admin/home" }],
  },
  {
    name: "Ticket Reason",
    show: true,
    layout: "/admin",
    path: "/ticket-crud/ticket-reason/add",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: AddTicketReason,
    showInSidebar: getDashboardPermissions("client_dashboard.view"),
    //permission: "client_dashboard.view",
    crumbs: [{ name: "Home", url: "/admin/home" }],
  },
  {
    name: "Ticket Reason",
    show: true,
    layout: "/admin",
    path: "/ticket-crud/ticket-reason/edit/:id",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: EditTicketReason,
    showInSidebar: getDashboardPermissions("client_dashboard.view"),
    //permission: "client_dashboard.view",
    crumbs: [{ name: "Home", url: "/admin/home" }],
  },
  {
    name: "Ticket Reason",
    show: true,
    layout: "/admin",
    path: "/ticket-crud/ticket-reason",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: TicketReason,
    showInSidebar: getDashboardPermissions("client_dashboard.view"),
    //permission: "client_dashboard.view",
    crumbs: [{ name: "Home", url: "/admin/home" }],
  },

  {
    name: "Ticket Tabs",
    show: true,
    layout: "/admin",
    path: "/ticket-crud/ticket-tabs/add",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: AddTicketTabs,
    showInSidebar: getDashboardPermissions("client_dashboard.view"),
    //permission: "client_dashboard.view",
    crumbs: [{ name: "Home", url: "/admin/home" }],
  },
  {
    name: "Ticket Tabs",
    show: true,
    layout: "/admin",
    path: "/ticket-crud/ticket-tabs/edit/:id",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: EditTicketTabs,
    showInSidebar: getDashboardPermissions("client_dashboard.view"),
    //permission: "client_dashboard.view",
    crumbs: [{ name: "Home", url: "/admin/home" }],
  },
  {
    name: "Ticket Tabs",
    show: true,
    layout: "/admin",
    path: "/ticket-crud/ticket-tabs",
    icon: <Icon as={MdHome} width="20px" height="20px" color="inherit" />,
    component: TicketTabs,
    showInSidebar: getDashboardPermissions("client_dashboard.view"),
    //permission: "client_dashboard.view",
    crumbs: [{ name: "Home", url: "/admin/home" }],
  },

  {
    name: "Configurations",
    show: true,
    layout: "/admin",
    icon: <Icon as={FaCog} width="20px" height="20px" color="inherit" />,
    //path: "/sla-report/:reports",
    component: SlaReport,
    showInSidebar: getDashboardPermissions("configuration_view.all"),
    permission: "configuration_view.all",
  },
  // {
  //   name: "404",
  //   show: true,
  //   layout: "*",
  //   icon: <Icon as={MdDashboard} width="20px" height="20px" color="inherit" />,
  //   path: "*",
  //   component: PageNotFound,
  //   showInSidebar: true,
  // },

  // {
  //   name: "Profile",
  //   layout: "/admin",
  //   path: "/profile",
  //   icon: <Icon as={MdPerson} width='20px' height='20px' color='inherit' />,
  //   component: Profile,
  // },
  {
    name: "Sign In",
    show: true,
    layout: "/auth",
    path: "/sign-in",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: SignInCentered,
    showInSidebar: false,
  },
  {
    name: "Pdf Report",
    show: true,
    layout: "/report",
    path: "/pdf-report",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: PdfReport,
    showInSidebar: false,
  },
  {
    name: "Forgot Password",
    show: true,
    layout: "/auth",
    path: "/forgot-password",
    icon: <Icon as={MdLock} width="20px" height="20px" color="inherit" />,
    component: ForgotPassword,
    showInSidebar: false,
  },
];

export default routes;
