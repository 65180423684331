import React, { useState, useEffect } from "react";
import Card from "components/card/Card.js";
import DownTimeListTable from "components/table/downTimeListTable";
import EntityClientHooks from "hooks/entityClient/entityClientHooks";
import LoadingForm from "components/loader/LoadingForm";
import noGroupImage from "assets/img/layout/noGroup.png";
import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Tooltip,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import AddClientList from "./addClientList";
import EditClientList from "./editClientList";
import { useHistory, useParams } from "react-router-dom";
import { RiClapperboardLine } from "react-icons/ri";
import { MdEdit, MdOutlineCancelPresentation } from "react-icons/md";
import OnBoardModal from "components/modal/onBoardModal";
import DeactivateModal from "components/modal/deactivateModal";
import { getDashboardPermissions } from "Permissions";

function EntityClientList() {
  // Chakra color mode
  const entityId = useParams().id;
  const [currentPage, setCurrentPage] = useState(1);
  const [showEditForm, setShowEditForm] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const { isLoading, error, data, refetch, isFetching } =
    EntityClientHooks.useEntityClientList(entityId, 20, currentPage);
  const [deleteId, setDeleteId] = useState(null);
  const [clientId, setClientId] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOnboradOpen,
    onOpen: onOnboardOpen,
    onClose: onOnboardClose,
  } = useDisclosure();
  const history = useHistory();
  const { mutate: deleteEntityClient } =
    EntityClientHooks.useDeleteEntityClient(onClose);
  const entityName = useParams().name;
  const [editData, setEditData] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showAddForm, showEditForm]);

  useEffect(() => {
    refetch();
  }, [entityId, currentPage]);

  if (isLoading || isFetching) return <LoadingForm />;
  // if (error) return toast.error("internal server error");

  const handleDelete = (id) => {
    setDeleteId(id);
    onOpen();
    //deleteEntity(id);
  };
  const handleEntityEdit = (row) => {
    history.push(
      `/admin/entity-client-list/${entityId}/${entityName}/edit/${row.id}`
    );
    setEditData(row);
    setShowEditForm(true);
  };
  const handleAdd = () => {
    history.push(`/admin/entity-client-list/${entityId}/${entityName}/add`);
    setShowAddForm(true);
  };

  const handleOnboard = (id) => {
    setClientId(id);
    onOnboardOpen();
  };
  const columnsData = [
    {
      Header: "ID",
      accessor: (row) => {
        return (
          <Text
            color={"ndmsColors.purple.200"}
            fontSize="12px"
            fontWeight="700"
          >
            {row.id}
          </Text>
        );
      },
    },
    {
      Header: "APP NAME",
      accessor: (row) => {
        return (
          <Text color={"ndmsColors.blue.100"} fontSize="12px" fontWeight="700">
            {row.name}
          </Text>
        );
      },
    },
    {
      Header: "   ",
      accessor: "   ",
    },
    {
      Header: "   ",
      accessor: "------",
    },
    {
      Header: "   ",
      accessor: "=======",
    },
    {
      Header: "   ",
      accessor: "++++++++++",
    },
    {
      Header: "ACTION",
      accessor: (row) => {
        return (
          <Flex justifyContent="center" align="center">
            {/* {getDashboardPermissions("entity.update") ? (
              <Tooltip hasArrow label="Edit" bg="#2B3674">
                <Flex mr="10px" onClick={() => handleEntityEdit(row)}>
                  <Icon
                    color="ndmsColors.purple.200"
                    as={MdEdit}
                    w="17px"
                    h="17px"
                  />
                </Flex>
              </Tooltip>
            ) : null} */}
            {getDashboardPermissions("entity.delete") ? (
              <>
                {row.deactive === 1 ? (
                  <Tooltip hasArrow label="Client Deactivated" bg="red">
                    <Flex mr="10px" cursor="default">
                      <Icon
                        as={MdOutlineCancelPresentation}
                        width="20px"
                        height="20px"
                        color="red"
                      />{" "}
                    </Flex>
                  </Tooltip>
                ) : (
                  <Tooltip hasArrow label="Deactivate Client" bg="#2B3674">
                    <Flex mr="10px" onClick={() => handleDelete(row.id)}>
                      <Icon
                        as={MdOutlineCancelPresentation}
                        width="20px"
                        height="20px"
                        color="ndmsColors.purple.200"
                      />{" "}
                    </Flex>
                  </Tooltip>
                )}
              </>
            ) : null}
            {getDashboardPermissions("entity.delete") ? (
              <>
                {row.deactive === 1 ? null : (
                  <Tooltip hasArrow label="Onboard Client" bg="#2B3674">
                    <Flex mr="10px" onClick={() => handleOnboard(row.id)}>
                      <Icon
                        as={RiClapperboardLine}
                        width="20px"
                        height="20px"
                        color="ndmsColors.purple.200"
                      />{" "}
                    </Flex>
                  </Tooltip>
                )}
              </>
            ) : null}
          </Flex>
        );
      },
    },
  ];

  return (
    <>
      {showAddForm && (
        <AddClientList
          entityId={entityId}
          entityName={entityName}
          setShowAddForm={setShowAddForm}
        />
      )}
      {showEditForm && (
        <EditClientList
          setShowEditForm={setShowEditForm}
          editData={editData}
          entityId={entityId}
          entityName={entityName}
        />
      )}
      {!showAddForm && !showEditForm && (
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
          <Flex align="center" justifyContent="flex-end">
            <Button
              bg="ndmsColors.purple.200"
              minWidth="104px"
              borderRadius="6px"
              mb="42px"
              _hover={{ bg: "ndmsColors.blue.400" }}
              _active={{ bg: "ndmsColors.blue.400" }}
              onClick={() => history.push(`/admin/install-client/${entityId}/${entityName}`)}
              mr="10px"
            >
              <Text ml="10px" color="#FFF" fontSize="14px">
                {" "}
                Install Client
              </Text>
            </Button>
            {/* <Button
              bg="ndmsColors.purple.200"
              minWidth="104px"
              borderRadius="6px"
              mb="42px"
              _hover={{ bg: "ndmsColors.blue.400" }}
              _active={{ bg: "ndmsColors.blue.400" }}
              onClick={handleAdd}
            >
              <Icon as={AddIcon} w={3} h={3} color="#FFF" />
              <Text ml="10px" color="#FFF" fontSize="14px">
                {" "}
                Add
              </Text>
            </Button> */}
          </Flex>
          <Card
            direction="column"
            w="100%"
            px="0px"
            overflowX={{ sm: "scroll", lg: "hidden" }}
          >
            {data?.data[0]?.result?.length > 0 ? (
              <Flex p="20px" direction="column">
                <DownTimeListTable
                  tableData={data?.data[0]?.result}
                  currentPage={currentPage}
                  columnsData={columnsData}
                  setCurrentPage={setCurrentPage}
                  totalPage={data?.data[0]?.total_pages}
                />
              </Flex>
            ) : (
              <Flex
                align="center"
                justify="center"
                mt="200px"
                cursor="pointer"
                mb="150px"
                //onClick={handleAdd}
              >
                <Flex direction="column" justify="center" align="center">
                  <Image src={noGroupImage} width="70px" alt="no-group" />
                  <Text fontSize={"22px"} color="#2B3674" fontWeight="bold">
                    You Don’t Have Any App Added for this Entity
                  </Text>
                </Flex>
              </Flex>
            )}
          </Card>
        </Box>
      )}
      <DeactivateModal
        onOpen={onOpen}
        isOpen={isOpen}
        onClose={onClose}
        id={deleteId}
        deleteItem={deleteEntityClient}
      />
      <OnBoardModal
        onOpen={onOnboardOpen}
        isOpen={isOnboradOpen}
        onClose={onOnboardClose}
        clientId={clientId}
      />
    </>
  );
}

export default EntityClientList;
