import React from "react";
import Card from "components/card/Card.js";
import {
  Box,
  Button,
  Flex,
  FormLabel,
  Input,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import { useForm, Controller } from "react-hook-form";
import useAddNodeType from "hooks/useAddNodeType";

function AddNodeTypeForm({ setShowAddForm }) {
  // Chakra color mode
  const textColor = useColorModeValue("navy.700", "white");
  let activeColor = useColorModeValue("gray.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const buttonHover = useColorModeValue("brand.600", "brand.400");
  const { mutate: submitNodeType, isLoading } = useAddNodeType(setShowAddForm);
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();
  const onSubmit = (data) => {
    submitNodeType({ name: data.name });
  };
  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Card>
        <Flex
          maxW={{ base: "100%", md: "max-content" }}
          w="100%"
          mx={{ base: "auto", lg: "0px" }}
          me="auto"
          h="100%"
          alignItems="start"
          justifyContent="center"
          mb={{ base: "30px", md: "30px" }}
          px={{ base: "25px", md: "0px" }}
          mt={{ base: "20px", md: "20px" }}
          flexDirection="column"
        >
          <Box me="auto">
            <Text
              mb="24px"
              ms="4px"
              color={activeColor}
              fontWeight="bold"
              fontSize="24px"
            >
              Add Node
            </Text>
          </Box>
          <Flex
            zIndex="2"
            direction="column"
            w={{ base: "100%", md: "420px" }}
            maxW="100%"
            background="transparent"
            borderRadius="15px"
            mx={{ base: "auto", lg: "unset" }}
            me="auto"
            mb={{ base: "20px", md: "auto" }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <FormLabel
                display="flex"
                ms="4px"
                fontSize="sm"
                fontWeight="500"
                color={textColor}
                mb="8px"
              >
                Name<Text color={brandStars}>*</Text>
              </FormLabel>
              <div style={{ marginBottom: "24px" }}>
                <Controller
                  name="name"
                  rules={{
                    required: true,
                    validate: (value) => {
                      return !!value.trim();
                    },
                  }}
                  control={control}
                  render={({ field }) => (
                    <Input
                      fontSize="sm"
                      ms={{ base: "0px", md: "0px" }}
                      type="name"
                      placeholder="Enter your name"
                      //mb="24px"
                      fontWeight="500"
                      size="lg"
                      {...field}
                    />
                  )}
                />
                {errors.name?.type === "required" && (
                  <span style={{ color: "red" }}>name is required</span>
                )}
                {errors.name?.type === "validate" && (
                  <span style={{ color: "red" }}>name is required</span>
                )}
              </div>

              <Flex>
                <Button
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  w="90%"
                  h="50"
                  mb="24px"
                  mr="20px"
                  type="submit"
                  isLoading={isLoading}
                  _hover={{ bg: isLoading ? "blue" : buttonHover }}
                >
                  Submit
                </Button>
                <Button
                  fontSize="sm"
                  variant="brand"
                  fontWeight="500"
                  w="90%"
                  h="50"
                  mb="24px"
                  onClick={() => setShowAddForm(false)}
                >
                  Cancel
                </Button>
              </Flex>
            </form>
          </Flex>
        </Flex>
      </Card>
    </Box>
  );
}

export default AddNodeTypeForm;
