import React from "react";
import { Text, Flex, Skeleton } from "@chakra-ui/react";
import Card from "components/card/Card";

const LiveDashboardCardLoader = () => {
  return (
    <Flex mt="20px" height="100px">
      <Card
        width="max-content"
        ml="20px"
        borderRadius="15px"
        width="228px"
        alignItems="center"
        justifyContent="center"
      >
        <Skeleton height="60px" width="200px" />
      </Card>
      <Card
        width="max-content"
        ml="20px"
        borderRadius="15px"
        width="132px"
        alignItems="center"
        justifyContent="center"
      >
        <Skeleton height="60px" width="100px" />
      </Card>
      <Card
        width="max-content"
        ml="20px"
        borderRadius="15px"
        width="164px"
        alignItems="center"
        justifyContent="center"
      >
        <Skeleton height="60px" width="120px" />
      </Card>
      <Card
        width="max-content"
        ml="20px"
        borderRadius="15px"
        width="219px"
        alignItems="center"
        justifyContent="center"
      >
        <Skeleton height="60px" width="170px" />
      </Card>
    </Flex>
  );
};

export default LiveDashboardCardLoader;
