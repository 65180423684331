import React, { useEffect, useRef } from "react";
import { Box, Text, Circle, Select, Skeleton, Flex } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import Chart from "react-apexcharts";
import EntityPerformanceTrend from "./entityPerformanceTrend";
import EntityDashboardHooks from "hooks/entityDashboard/entityDashboardHooks";
import EmptyPieChart from "components/charts/emptyPieChart";
import EmptyCard from "components/card/EmptyCard";
import { DownloadIcon } from "@chakra-ui/icons";
import { Button } from "@chakra-ui/button";
import Icon from "@chakra-ui/icon";
import { useDisclosure } from "@chakra-ui/hooks";

const EntityHistory = ({ entityId, entityName }) => {
  const colorSchedule = "#135CC9";
  const colorBlackout = "#7F7E95";
  const colorDowntime = "#DC5D5D";
  const colorUptime = "#3DCA78";
  const {
    isLoading,
    error,
    data: detailData,
    refetch,
    isFetching,
  } = EntityDashboardHooks.useEntityDashboardDetails(entityId, "monthly");

  useEffect(() => {
    refetch();
  }, [entityId]);
  const pieChartOptions = {
    labels: ["Schedule Downtime", "Blackout", "Down Devices", "Online Devices"],
    colors: [colorSchedule, colorBlackout, colorDowntime, colorUptime],
    chart: {
      width: "150px",
    },
    states: {
      hover: {
        filter: {
          type: "none",
        },
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    hover: { mode: null },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: false,
            total: {
              show: true,
              label: "Downtime",
              color: "navy blue",
              formatter: function (w) {
                return w.globals.seriesTotals.reduce((a, b) => {
                  return a + b;
                }, 0);
              },
            },
          },
        },
      },
    },
    series: [
      detailData?.data?.schedule_down_percent,
      detailData?.data?.blackout_percent,
      detailData?.data?.downtime_percent,
      detailData?.data?.uptime_percent,
    ],
    fill: {
      colors: [colorSchedule, colorBlackout, colorDowntime, colorUptime],
    },
    tooltip: {
      enabled: true,
      theme: "dark",
    },
  };

  const pieChartData = [
    detailData?.data?.schedule_down_percent,
    detailData?.data?.blackout_percent,
    detailData?.data?.downtime_percent,
    detailData?.data?.uptime_percent,
  ];

  if (isLoading || isFetching) {
    return (
      <Card width="100%" height="500px">
        <Skeleton width="100%" height="450px" />
      </Card>
    );
  }
  const getTotal = () => {
    return (
      detailData?.data?.schedule_down_percent +
      detailData?.data?.blackout_percent +
      detailData?.data?.downtime_percent +
      detailData?.data?.uptime_percent
    );
  };

  return (
    <>
      <Box mt="20px">
        <Flex alignItems="center" justifyContent="space-between">
          <Flex alignItems="center">
            <Text fontSize="26px" fontWeight="bold" color="ndmsColors.blue.100">
              Overview
            </Text>
          </Flex>
          <Select
            bg="white"
            fontSize="15px"
            //mt="20px"
            width="200px"
            height="40px"
            id="user_type"
            size="lg"
            ml="10px"
            color="#2C3774"
            fontWeight="bold"
            // value={mtbfSort}
            // onChange={handleMtbfChange}
          >
            <option value="top-mtbf">Last 30 days</option>
            {/* <option value="bottom-mtbf">Bottom 5</option> */}
          </Select>
        </Flex>
        <Flex mt="20px">
          <Flex direction="column" width="30%">
            <Card borderRadius="10px">
              <Flex direction="column">
                <Text
                  fontSize="18px"
                  fontWeight="bold"
                  color="ndmsColors.gray.1900"
                >
                  Uptime Required
                </Text>
                <Text
                  fontSize="26px"
                  fontWeight="bold"
                  color="ndmsColors.blue.100"
                >
                  98%
                </Text>
              </Flex>
            </Card>
            <Card bg="ndmsColors.green.60" my="10px" borderRadius="10px">
              <Flex direction="column">
                <Text
                  fontSize="18px"
                  fontWeight="bold"
                  color="ndmsColors.gray.1900"
                >
                  Uptime Achieved
                </Text>
                <Text
                  fontSize="26px"
                  fontWeight="bold"
                  color="ndmsColors.blue.100"
                >
                  {detailData?.data?.uptime_percent} %
                </Text>
              </Flex>
            </Card>
            <Card bg="ndmsColors.red.400" borderRadius="10px">
              <Flex direction="column">
                <Text
                  fontSize="18px"
                  fontWeight="bold"
                  color="ndmsColors.gray.1900"
                >
                  Downtime
                </Text>
                <Text
                  fontSize="26px"
                  fontWeight="bold"
                  color="ndmsColors.blue.100"
                >
                  {detailData?.data?.downtime_percent} %
                </Text>
              </Flex>
            </Card>
          </Flex>
          <Card ml="10px" height="340px" width="40%">
            <Flex align="center" mt="40px" justifyContent="space-between">
              <Flex
                direction="column"
                alignItems="center"
                justifyContent="center"
              >
                <Box width="200px">
                  {getTotal() === 0 ? (
                    <EmptyPieChart />
                  ) : (
                    <Chart
                      height="220px"
                      w="100%"
                      type="donut"
                      series={pieChartData}
                      options={pieChartOptions}
                    />
                  )}
                </Box>
                <Text
                  color="ndmsColors.gray.1900"
                  fontWeight="bold"
                  fontSize="16px"
                >
                  Overall performance
                </Text>
              </Flex>
              <Flex direction="column" ml="20px">
                <Flex>
                  <Circle mt="5px" size="12px" bg={colorUptime} />
                  <Flex direction="column">
                    <Text
                      color="ndmsColors.gray.1900"
                      fontWeight="bold"
                      fontSize="xs"
                      pl="5px"
                      mb="5px"
                    >
                      Uptime
                    </Text>
                    <Text
                      color="ndmsColors.blue.100"
                      fontWeight="bold"
                      fontSize="sm"
                      pl="5px"
                      mb="5px"
                    >
                      {detailData?.data?.uptime_percent}%
                    </Text>
                  </Flex>
                </Flex>
                <Flex>
                  <Circle mt="5px" size="12px" bg={colorSchedule} />
                  <Flex direction="column">
                    <Text
                      color="ndmsColors.gray.1900"
                      fontWeight="bold"
                      fontSize="xs"
                      pl="5px"
                      mb="5px"
                    >
                      Schedule Downtime
                    </Text>
                    <Text
                      color="ndmsColors.blue.100"
                      fontWeight="bold"
                      fontSize="sm"
                      pl="5px"
                      mb="5px"
                    >
                      {detailData?.data?.schedule_down_percent}%
                    </Text>
                  </Flex>
                </Flex>
                <Flex>
                  <Circle mt="5px" size="12px" bg={colorDowntime} />
                  <Flex direction="column">
                    <Text
                      color="ndmsColors.gray.1900"
                      fontWeight="bold"
                      fontSize="xs"
                      pl="5px"
                      mb="5px"
                    >
                      Downtime
                    </Text>
                    <Text
                      color="ndmsColors.blue.100"
                      fontWeight="bold"
                      fontSize="sm"
                      pl="5px"
                      mb="5px"
                    >
                      {detailData?.data?.downtime_percent}%
                    </Text>
                  </Flex>
                </Flex>
                {/* <Flex>
                <Circle mt="5px" size="12px" bg={colorBlackout} />
                <Flex direction="column">
                  <Text
                    color="ndmsColors.gray.1900"
                    fontWeight="bold"
                    fontSize="xs"
                    pl="5px"
                    mb="5px"
                  >
                    Blackout
                  </Text>
                  <Text
                    color="ndmsColors.blue.100"
                    fontWeight="bold"
                    fontSize="sm"
                    pl="5px"
                    mb="5px"
                  >
                    {detailData?.data?.blackout_percent}%
                  </Text>
                </Flex>
              </Flex> */}
              </Flex>
            </Flex>
          </Card>
          <Flex direction="column" pl="10px" width="30%">
            <Card mb="10px" borderRadius="10px">
              <Flex direction="column">
                <Text
                  fontSize="18px"
                  fontWeight="bold"
                  color="ndmsColors.gray.1900"
                >
                  MTTR
                </Text>
                <Text
                  fontSize="26px"
                  fontWeight="bold"
                  color="ndmsColors.blue.100"
                >
                  {detailData?.data?.total_mttr.toFixed(2)} hours
                </Text>
              </Flex>
            </Card>
            <Card borderRadius="10px">
              <Flex direction="column">
                <Text
                  fontSize="18px"
                  fontWeight="bold"
                  color="ndmsColors.gray.1900"
                >
                  MTBF
                </Text>
                <Text
                  fontSize="26px"
                  fontWeight="bold"
                  color="ndmsColors.blue.100"
                >
                  {detailData?.data?.total_mtbf.toFixed(2)} hours
                </Text>
              </Flex>
            </Card>
          </Flex>
        </Flex>
        <EntityPerformanceTrend entityId={entityId} entityName={entityName} />
      </Box>
    </>
  );
};

export default EntityHistory;
