import { MUTATION_KEY } from "constants/globalConstants";
import ApiService from "apiService";
import { useMutation } from "react-query";
import toast from "react-hot-toast";

const useGenerateLicense = (setShowLicense, setGeneratedLicense) => {
  return useMutation(
    [MUTATION_KEY.GENERATE_LICENSE],
    (data) => ApiService.generateLicense(data),
    {
      onSuccess: (data) => {
        toast.success('License generated successfully')
        setShowLicense(true)
        setGeneratedLicense(data.data.license_key)
      },
      onError: (error) => {
        toast.error(error.response.data.message)
    },
    }
  );
};

export default useGenerateLicense;