import React, { useState } from "react";
import {
  Box,
  Text,
  Flex,
  FormLabel,
  Select,
  Divider,
  Circle,
  Button,
  Badge,
  useDisclosure,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import dayjs from "dayjs";
import { useHistory } from "react-router";
import UserAssignModal from "components/modal/userAssignModal";
import TicketModuleHooks from "hooks/ticketModule/ticketModuleHooks";
//import TabLoader from "./tabLoader";
import TabLoader from "../tms/tabLoader";
import { getActiveSince, getDashboardPermissions } from "Permissions";
import EmptyCard from "components/card/EmptyCard";
import { useForm, Controller } from "react-hook-form";
import TmsConfirmationModal from "components/modal/tmsConfirmationModal";

const NodeTicketCard = ({ activeListData, isLoading, userListData }) => {
  const {
    handleSubmit,
    control,
    reset,
    resetField,
    clearErrors,
    formState: { errors },
  } = useForm();

  const {
    isOpen: isConfOpen,
    onOpen: onConfOpen,
    onClose: onConfClose,
  } = useDisclosure();

  const [assignTo, setAssignTo] = useState(null);

  const history = useHistory();
  const [showAssignUi, setShowAssignUi] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const textColor = useColorModeValue("navy.700", "white");
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const buttonHover = useColorModeValue("brand.600", "brand.400");

  const { mutate: assignTicket, isLoading: isAssignLoading } =
    TicketModuleHooks.useAssignTicketOnNode(
      showAssignUi,
      setShowAssignUi,
      onConfClose
    );

  if (isLoading) {
    return <TabLoader />;
  }

  const generateSelectOptions = () => {
    return userListData?.data?.map((data) => {
      return (
        <option
          value={data.id}
        >{`${data.user_fname} ${data.user_lname}`}</option>
      );
    });
  };

  const getBgColor = (status) => {
    switch (status) {
      case 1:
        return "#019F79";
      case 2:
        return "#F09205";
      case 3:
        return "#FA5E5E";
      default:
        return "#019F79";
    }
  };

  const getPriorityName = (status) => {
    switch (status) {
      case 1:
        return "LOW";
      case 2:
        return "MEDIUM";
      case 3:
        return "HIGH";
      default:
        return "LOW";
    }
  };

  const getDate = (date) => {
    return `${dayjs(date).format("DD MMM YYYY")} at ${dayjs(date).format(
      "hh:mm a"
    )}`;
  };

  const onCancel = () => {
    setShowAssignUi(null);
    resetField("user_id");
    clearErrors();
  };

  const getCardDesign = (data) => {
    switch (data?.ticket?.ticket_status?.name) {
      case "Resolved":
        return (
          <>
            <Text
              color="ndmsColors.gray.2200"
              fontWeight="500"
              mt="10px"
              fontSize="12px"
            >
              {`Resolved by : ${data?.ticket?.status_updated_by?.user_fname} ${
                data?.ticket?.status_updated_by?.user_lname
              } on ${getDate(data?.ticket?.status_updated_at)}`}
            </Text>

            <Text
              color="ndmsColors.gray.2200"
              mt="10px"
              fontWeight="500"
              fontSize="12px"
            >
              {`Resolution : ${data?.ticket?.reason?.reason}`}
            </Text>
          </>
        );
      case "Cancelled":
        return (
          <>
            <Text
              color="ndmsColors.gray.2200"
              fontWeight="500"
              mt="10px"
              fontSize="12px"
            >
              {`Cancelled by : ${data?.ticket?.status_updated_by?.user_fname} ${
                data?.ticket?.status_updated_by?.user_lname
              } on ${getDate(data?.ticket?.status_updated_at)}`}
            </Text>

            <Text
              color="ndmsColors.gray.2200"
              mt="10px"
              fontWeight="500"
              fontSize="12px"
            >
              {`Reason : ${data?.ticket?.reason?.reason}`}
            </Text>
          </>
        );
      default:
        return (
          <>
            <Flex alignItems="center" mt="10px">
              <Text
                color="ndmsColors.gray.2200"
                fontWeight="500"
                fontSize="12px"
              >
                {`Created by: ${data.ticket.created_by.user_fname} ${
                  data.ticket.created_by.user_lname
                } on ${getDate(data.ticket.created_at)}`}
              </Text>
              {data.ticket.assigned_to ? (
                <>
                  <Circle size="5px" bg="ndmsColors.gray.2200" mx="10px" />
                  <Text
                    color="ndmsColors.gray.2200"
                    fontWeight="500"
                    fontSize="12px"
                  >
                    {`Assigned to: ${data?.ticket.assigned_to?.user_fname} ${data?.ticket.assigned_to?.user_lname}`}
                  </Text>
                </>
              ) : null}
            </Flex>
            {data.ticket.assigned_to ? (
              <Flex pt="10px" alignItems="center">
                <Badge
                  borderRadius="3px"
                  width="max-content"
                  variant="solid"
                  fontSize="12px"
                  bg={getBgColor(data.ticket.priority)}
                >
                  {getPriorityName(data.ticket.priority)}
                </Badge>

                <Text
                  color="ndmsColors.gray.2200"
                  ml="10px"
                  fontWeight="500"
                  fontSize="12px"
                >
                  {getActiveSince(data.ticket.created_at)}
                </Text>
              </Flex>
            ) : (
              <>
                <Flex
                  pt="5px"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Badge
                    borderRadius="3px"
                    width="max-content"
                    variant="solid"
                    fontSize="12px"
                    bg={getBgColor(data?.ticket?.priority)}
                  >
                    {getPriorityName(data?.ticket?.priority)}
                  </Badge>
                  {showAssignUi === data?.ticket?.id ? null : (
                    <>
                      {getDashboardPermissions(
                        "ticketing.ticket.assign_users"
                      ) ? (
                        <Button
                          size="sm"
                          variant="brand"
                          borderRadius="6px"
                          onClick={() => setShowAssignUi(data?.ticket?.id)}
                        >
                          + Assign
                        </Button>
                      ) : null}
                    </>
                  )}
                </Flex>
                {showAssignUi === data?.ticket?.id ? (
                  <Box mt="20px">
                    <Divider />
                    <Flex>
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <Flex mt="10px">
                          <Flex direction="column" width="100%" pr="10px">
                            <FormLabel
                              display="flex"
                              ms="4px"
                              fontSize="sm"
                              fontWeight="500"
                              color={textColor}
                              mb="8px"
                            >
                              Assign to<Text color={brandStars}>*</Text>
                            </FormLabel>
                            <Flex alignItems="center">
                              <div style={{ marginBottom: "10px" }}>
                                <Controller
                                  name="user_id"
                                  rules={{ required: true }}
                                  control={control}
                                  render={({ field }) => (
                                    <Select
                                      id="user_type"
                                      size="lg"
                                      minWidth="350px"
                                      fontSize="14px"
                                      placeholder="Choose Agent"
                                      {...field}
                                    >
                                      {generateSelectOptions()}
                                    </Select>
                                  )}
                                />
                                {errors.user_id?.type === "required" && (
                                  <span style={{ color: "red" }}>
                                    Please select an Agent
                                  </span>
                                )}
                              </div>
                              <Button
                                bg="#4318FF"
                                size="sm"
                                borderRadius="6px"
                                variant="brand"
                                mx={3}
                                mb={2}
                                //mb={10}
                                width="100px"
                                isLoading={isLoading}
                                _hover={{
                                  bg: isLoading ? "blue" : buttonHover,
                                }}
                                type="submit"
                              >
                                <Text color={"#FFFFFF"}>Assign</Text>
                              </Button>
                              <Button
                                size="sm"
                                borderRadius="6px"
                                variant="outline"
                                mb={2}
                                width="100px"
                                onClick={onCancel}
                              >
                                <Text color={"#4318FF"}>Cancel</Text>
                              </Button>
                            </Flex>
                          </Flex>
                        </Flex>
                      </form>
                    </Flex>
                  </Box>
                ) : null}
              </>
            )}
          </>
        );
    }
  };

  const onConfirmation = () => {
    assignTicket({
      assigned_to: assignTo,
    });
  };

  const onSubmit = (data) => {
    setAssignTo(parseInt(data.user_id, 10));
    onConfOpen();
  };

  return (
    <Box>
      {activeListData?.data[0]?.result?.list?.length > 0 ? (
        <>
          {activeListData?.data[0]?.result?.list?.map((data) => (
            <Card border="1px solid #EAEDF2" borderRadius="10px" mb="10px">
              <Text color="#179DBA" fontWeight="500" fontSize="12px">
                {data.date}
              </Text>

              {true ? (
                <Flex>
                  <Box borderRadius="12px" bg="#EFFCFF" px="8px" py="3px">
                    <Text color="#5FB0C1" fontWeight="600" fontSize="12px">
                      Today’s task : {data.tasks_due_today_count}
                    </Text>
                  </Box>
                  <Box
                    borderRadius="12px"
                    bg="#FFF6F8"
                    px="8px"
                    py="3px"
                    ml="5px"
                  >
                    <Text color="#E27E97" fontWeight="600" fontSize="12px">
                      Overdue task : {data.tasks_overdue_count}
                    </Text>
                  </Box>
                  {data.total_due_task ? (
                    <Box
                      borderRadius="12px"
                      bg="#F5F5F5"
                      px="8px"
                      py="3px"
                      ml="5px"
                    >
                      <Text fontWeight="600" fontSize="12px">
                        Total Due task : {data.total_due_task}
                      </Text>
                    </Box>
                  ) : (
                    <Box
                      borderRadius="12px"
                      bg="#F5F5F5"
                      px="8px"
                      py="3px"
                      ml="5px"
                    >
                      <Text fontWeight="600" fontSize="12px">
                        Total Due task : 0
                      </Text>
                    </Box>
                  )}
                </Flex>
              ) : null}
              <Flex
                py="5px"
                cursor="pointer"
                width="max-content"
                overflow="hidden"
                testOverflow="ellipsis"
                display="contents"
                lineHeight="17px"
                onClick={() =>
                  history.push(`/admin/ticket-details/${data.ticket.id}`)
                }
              >
                <Flex mt="10px">
                  <Text
                    color="ndmsColors.gray.2200"
                    fontWeight="600"
                    fontSize="14px"
                  >
                    #{data.ticket.id}
                  </Text>
                  <Text pl="5px" fontWeight="600" fontSize="14px">
                    {data.title}
                  </Text>
                </Flex>
              </Flex>
              {getCardDesign(data)}
            </Card>
          ))}
          <UserAssignModal
            onOpen={onOpen}
            isOpen={isOpen}
            onClose={onClose}
            data={userListData}
          />
        </>
      ) : (
        <Flex alignItems="center" height="70vh" justifyContent="center">
          <EmptyCard font="25px" size="100px" color="ndmsColors.gray.100" />
        </Flex>
      )}
      <TmsConfirmationModal
        isOpen={isConfOpen}
        onOpen={onConfOpen}
        onClose={onConfClose}
        onConfirmation={onConfirmation}
        isLoading={isAssignLoading}
        text={`Are you sure you want to assign this ticket?`}
      />
    </Box>
  );
};

export default NodeTicketCard;
