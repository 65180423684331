import { CalendarIcon } from "@chakra-ui/icons";
import { Box, Text } from "@chakra-ui/layout";
import { Flex, Input, InputGroup, InputRightElement } from "@chakra-ui/react";
import dayjs from "dayjs";
import DatePicker from "react-datepicker";

function StartDateFilter(props) {
  const { setStartDate, startDate } = props;

  return (
    <Box mt="26px" mr="20px">
      <Flex alignItems="center">
        <Flex>
          <Text color="ndmsColors.gray.2100" fontSize="14px" fontWeight="bold">
            Start Date
          </Text>
        </Flex>
        <Flex
          alignItems="center"
          fontSize="13px"
          width="198px"
          height="2.5rem"
          ml="14px"
          id="user_type"
          size="lg"
          mr="10px"
          color="#2B3674"
          fontWeight="bold"
        >
          <DatePicker
            wrapperClassName="date-picker"
            selected={startDate}
            dateFormat="MMMM d, yyyy"
            onChange={(date) => setStartDate(date)}
            customInput={
              <InputGroup>
                <Input
                  // minWidth="335px"
                  placeholder="From"
                  value={dayjs(startDate).format("DD MMM, YYYY")}
                />
                <InputRightElement
                  children={<CalendarIcon color="#4318FF" />}
                />
              </InputGroup>
            }
          />
        </Flex>
      </Flex>
    </Box>
  );
}

export default StartDateFilter;
