import React from "react";
import { MdOutlineBrowserNotSupported } from "react-icons/md";
import { Flex, Icon, Text } from "@chakra-ui/react";

const EmptyCard = ({ label, font, size, color }) => {
  return (
    <Flex direction="column" justify="center" align="center">
      <Icon
        as={MdOutlineBrowserNotSupported}
        width={size ? size : "70px"}
        height={size ? size : "70px"}
        color={color ? color : "ndmsColors.blue.100"}
      />
      <Text
        fontSize={font ? font : "22px"}
        color={color ? color : "ndmsColors.blue.100"}
        fontWeight="bold"
      >
        {label ? label : "No data available"}
      </Text>
    </Flex>
  );
};

export default EmptyCard;
