import React, { useState, useEffect } from "react";
import TmsFilters from "./tmsFilters";
import {
  Box,
  Flex,
  Tabs,
  TabList,
  Skeleton,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import { useLocation } from "react-router";
import TmsActiveCard from "./tmsActiveCard";
import TicketModuleHooks from "hooks/ticketModule/ticketModuleHooks";

const TicketDetails = () => {
  const location = useLocation();
  const [otherString, setOtherString] = useState("");
  const [tabArrayData, setTabArrayData] = useState([]);
  const [loadTab, setLoadTab] = useState(false);

  const type = location.state?.data;
  const {
    data: userListData,
    isLoading,
    error,
    refetch: userRefetch,
  } = TicketModuleHooks.useTicketUserList();
  const {
    data: tabsData,
    isLoading: isTabsLoading,
    error: tabsError,
    refetch,
    isFetching,
  } = TicketModuleHooks.useTicketTabs(setTabArrayData);

  useEffect(() => {
    userRefetch();
    refetch();
  }, [window.location.pathname]);

  const [tabIndex, setTabIndex] = useState(
    location.state?.data ? location.state?.data : 0
  );
  const [actCount, setActCount] = useState(null);
  const [unCount, setUnCount] = useState(null);
  const [recall, setRecall] = useState(false);

  const getStatus = (name) => {
    switch (name) {
      case "Active":
        return "pending";
      case "Unassigned":
        return "pending";
      case "Resolved":
        return "resolved";
      case "Cancelled":
        return "canceled";
      default:
        return "pending";
    }
  };

  useEffect(() => {
    getStatus();
    //refetch();
  }, [tabIndex]);

  const onApply = () => {
    setRecall(!recall);
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <Flex>
        <Box width="75%">
          <Tabs
            defaultIndex={type ? type : 0}
            pt="0px"
            mr="20px"
            index={tabIndex}
            onChange={(index) => setTabIndex(index)}
          >
            <TabList borderBottom="none" flexWrap="wrap">
              {isTabsLoading || isFetching || loadTab
                ? [...Array(4)]?.map((tab, index) => (
                    <Box
                      p="20px"
                      minWidth="130px"
                      fontSize="18px"
                      backgroundColor="white"
                      textAlign="start"
                      borderRadius="8px"
                      fontWeight="500"
                      mr="10px"
                    >
                      <Skeleton width="100%" height="16px" />
                    </Box>
                  ))
                : tabArrayData?.data
                    ?.sort((a, b) => {
                      return a.show_order - b.show_order;
                    })
                    ?.map((tab) => (
                      <Tab
                        _selected={{
                          color: "ndmsColors.blue.100",
                          boxShadow: "none",
                          zIndex: 0,
                          backgroundColor: "#E8E7FF",
                          borderBottom: "3px solid #4318FF",
                        }}
                        py="20px"
                        my="5px"
                        maxWidth="145px"
                        //width="max-content"
                        fontSize="16px"
                        flexGrow={1}
                        backgroundColor="white"
                        textAlign="start"
                        borderRadius="8px"
                        fontWeight="500"
                        mr="10px"
                      >
                        {tab?.label}
                      </Tab>
                    ))}
            </TabList>

            <TabPanels>
              {tabArrayData?.data?.map((tab, index) => (
                <TabPanel pl="0px">
                  {tabIndex === index && tabArrayData?.data?.length > 0 ? (
                    <TmsActiveCard
                      tabIndex={tabIndex}
                      tabArrayData={tabArrayData}
                      isTabsLoading={isTabsLoading}
                      isFetching={isFetching}
                      //tabName={getTabName(tabIndex)}
                      params={tab?.param}
                      status={tab?.param?.status}
                      assigned={tab?.param?.assigned}
                      index={index}
                      show={true}
                      userListData={userListData}
                      setActCount={setActCount}
                      otherString={otherString}
                      recall={recall}
                      setTabIndex={setTabIndex}
                      setLoadTab={setLoadTab}
                    />
                  ) : null}
                </TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </Box>
        <TmsFilters
          data={userListData}
          otherString={otherString}
          setOtherString={setOtherString}
          onApply={onApply}
        />
      </Flex>
    </Box>
  );
};

export default TicketDetails;
