import React from "react";
import { useDrag } from "react-dnd";
import { Text } from "@chakra-ui/react";
import Card from "components/card/Card.js";
import useNode from "hooks/useNode";

const NodeSavedCard = ({ name, data, nodeName }) => {
  const { isLoading, error, data: nodeData } = useNode();

  return (
    <div>
      <Card
        borderWidth="1px"
        borderRadius="lg"
        draggable
        style={{
          width: "200px",
          marginRight: "10px",
          boxShadow: "0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)",
          display: "flex",
          //alignItems: "center",
          //marginRight: "15px",
          zIndex: 1,
        }}
      >
        <Text fontWeight="bold" fontSize="14px">
          {nodeName?.name}
        </Text>
        <Text fontWeight="bold" fontSize="14px">
          name: {data.name}
        </Text>
        <Text fontWeight="bold" fontSize="14px">
          ip Address : {data.ip_address}
        </Text>
      </Card>
    </div>
  );
};

export default NodeSavedCard;
