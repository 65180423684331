import React, { useState } from "react";
import {
  Box,
  Text,
  Icon,
  Flex,
  Tabs,
  Select,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from "@chakra-ui/react";
import { RiBarChartFill } from "react-icons/ri";
import { AiOutlineBars } from "react-icons/ai";
import CardView from "./cardView";
import DownTimeCardView from "./downTimeCardView";

const ClientTrends = () => {
  const [toggleCardMode, setToggleCardMode] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);

  const handleToggleMode = () => {
    if (toggleCardMode) {
      setToggleCardMode(false);
    } else {
      setToggleCardMode(true);
    }
  };

  const performColorCode = ["#26AFCD", "#763BC0", "#446FC4"];
  const downColorCode = ["#CD9426", "#CD26C6"];

  return (
    <Box mt="30px">
      <Flex justifyContent="space-between">
        <Text fontSize="26px" fontWeight="bold" color="#2B3674">
          Trends
        </Text>

        <Flex>
          <Select
            bg="white"
            fontSize="15px"
            //mt="20px"
            width="200px"
            height="40px"
            id="user_type"
            size="lg"
            ml="10px"
            color="#2C3774"
            fontWeight="bold"
            //value={status}
            //onChange={(e) => setStatus(e.target.value)}
          >
            {/* <option value="active">Today</option>
            <option value="inactive">Last 7 days</option> */}
            <option value="expired">Last 30 days</option>
          </Select>
          <Box
            bg={toggleCardMode ? "#4318FF" : "#EAEDF2"}
            width="38px"
            height="38px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            borderRadius="6px"
            cursor="pointer"
            onClick={handleToggleMode}
            mx="10px"
          >
            <Icon
              as={AiOutlineBars}
              width="20px"
              height="20px"
              color={toggleCardMode ? "white" : "#9EA2A9"}
            />
          </Box>
          <Box
            bg={toggleCardMode ? "#EAEDF2" : "#4318FF"}
            width="38px"
            height="38px"
            display="flex"
            justifyContent="center"
            alignItems="center"
            cursor="pointer"
            onClick={handleToggleMode}
            borderRadius="6px"
          >
            <Icon
              as={RiBarChartFill}
              width="20px"
              height="20px"
              color={toggleCardMode ? "#9EA2A9" : "white"}
            />
          </Box>
        </Flex>
      </Flex>
      <Tabs mt="20px" width="100%" onChange={(index) => setTabIndex(index)}>
        <TabList>
          <Tab
            _selected={{
              color: "#4318FF",
              boxShadow: "none",
              borderBottom: "1px solid #4318FF",
            }}
            color="#828282"
            fontSize="18px"
            fontWeight="bold"
          >
            Performance
          </Tab>

          <Tab
            _selected={{
              color: "#4318FF",
              boxShadow: "none",
              borderBottom: "1px solid #4318FF",
            }}
            color="#828282"
            fontSize="18px"
            fontWeight="bold"
          >
            Downtime
          </Tab>
        </TabList>

        <TabPanels width="100% !important">
          <TabPanel pl="0px" pr="0px" width="100%">
            <CardView
              colorCode={performColorCode}
              toggleCardMode={toggleCardMode}
              tabIndex={tabIndex}
            />
          </TabPanel>
          <TabPanel px="0px">
            <DownTimeCardView
              colorCode={downColorCode}
              toggleCardMode={toggleCardMode}
              tabIndex={tabIndex}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

export default ClientTrends;
